<div class="container-fluid d-sm-block padding-0">  <!-- d-none -->
  <form [formGroup]="formGroup" class="row position-relative margin-0 " (ngSubmit)="actionSubmitRicerca()">
    <div class="col-12 col-sm input-search destinazione-col"
         *ngIf="!isVetrinaPrime">
      <!-- col-sm col-md-auto input-search destinazione-col --><!-- d-flex align-items-center justify-content-center -->
      <mat-form-field class="text-box mt-15px full-width" floatLabel="always">
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>room</mat-icon>
        <mat-label>Destinazione</mat-label>
        <mat-chip-list class="chip-container" #chipDestinazioniList aria-label="Destinazione">
          <mat-chip
            *ngFor="let destinazione of modelSearch.destinazione.chipListDestinazioni"
            selectable="false"
            [disabled]="fromMacro && !fromRisultati"
            (click)="actionFocusDestinazione()"
            (removed)="removeChipDestinazione(destinazione)">
            <span
              [ngClass]="{'text-dest-ellipsis':modelSearch.destinazione.chipListDestinazioni.length > 1}">{{destinazione.nome}}</span>
            <mat-icon matChipRemove *ngIf="(fromRisultati || (!fromRisultati && !fromMacro) || (fromMacro && fromRisultati))">cancel</mat-icon>
          </mat-chip>
          <input [hidden]="modelSearch.destinazione.chipListDestinazioni.length > 0"
                 placeholder="Dove vuoi andare?"
                 formControlName="destinazione"
                 autocomplete="off"
                 disabled
                 [matChipInputFor]="chipDestinazioniList"> <!-- (click)="actionFocusDestinazione($event)" -->
          <div class="input-wrapper" (click)="actionFocusDestinazione($event)"></div>
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm input-search aeroporto-col">
      <!-- d-flex align-items-center justify-content-center border-r-1px -->
      <borsaviaggi-loader class="loader-data-partenza" [show]="luogoPartenzaLoading" diameter="30"
                          typeMode="piker"></borsaviaggi-loader>
      <mat-form-field class="text-box mt-15px full-width"
                      #triggerPopoverPartenzaDa="popoverTrigger"
                      [popoverTriggerFor]="daDovePopover"
                      floatLabel="always">
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>flight_takeoff</mat-icon>
        <mat-label>Partenza da</mat-label>
        <mat-chip-list class="chip-container"
                       #chipPartenzaDaList aria-label="Partenza Da">
          <mat-chip
            *ngFor="let partenzaDa of modelSearch.partenzaDa.chipList"
            selectable="false"
            [removable]="!formDisabled || !fromMacro"
            (removed)="removeChipPartenzaDa(partenzaDa)">
            {{partenzaDa.nome}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [hidden]="modelSearch.partenzaDa.chipList.length > 0"
                 placeholder="Da dove?"
                 formControlName="partenzaDa"
                 autocomplete="off"
                 disabled
                 [matChipInputFor]="chipPartenzaDaList">
          <div class="input-wrapper" (click)="togglePopoverPartenzaDa(triggerPopoverPartenzaDa)"></div>
        </mat-chip-list>


        <borsaviaggi-popover #daDovePopover="borsaviaggiPopover"
                             popoverPositionY="above"
                             popoverPositionX="before"
                             popoverCloseOnClick="false"
                             popoverTriggerOn="null"
                             [popoverOverlapTrigger]="false">


          <mat-card class="popover-partenza-da pr-5px pl-5px">
            <mat-card-content class="container-fluid pr-5px pl-5px">
              <div class="row overflow-hidden">
                <div class="col-12 row-dett-camere" *ngFor="let itemPartenza of modelSearch.partenzaDa.list">
                  <mat-checkbox
                    class="example-margin"
                    (change)="toggleCheckBoxPartenzaDa($event,itemPartenza)"
                    [checked]="itemPartenza.checked"
                    [disabled]="itemPartenza.disabled">
                    <mat-icon *ngIf="itemPartenza.isAeroporto" class="mr-5px vertical-align-sub">flight</mat-icon>
                    <mat-icon *ngIf="itemPartenza.isPorto" class="mr-5px vertical-align-sub">directions_boat</mat-icon>
                    <mat-icon *ngIf="itemPartenza.isBus" class="mr-5px vertical-align-sub">directions_bus</mat-icon>
                    <mat-icon *ngIf="itemPartenza.isSoloSoggiorno" class="mr-5px vertical-align-sub">directions_car
                    </mat-icon>
                    {{itemPartenza.nome}}
                  </mat-checkbox>
                </div>
              </div>

            </mat-card-content>
            <mat-card-actions align="center" class="partenza-card">
              <button class="background-orange-borsaviaggi color-white"
                      [disabled]="disabledConfirmPartenzaDa"
                      (click)="actionConfirmLuogoPartenza(triggerPopoverPartenzaDa)"
                      mat-raised-button>
                Conferma
              </button>
            </mat-card-actions>
          </mat-card>


        </borsaviaggi-popover>

      </mat-form-field>
    </div>
    <div class="col-12 col-sm input-search partenza-col " (click)="actionFocusDataPartenza()">
      <!-- d-flex align-items-center justify-content-center border-r-1px -->
      <borsaviaggi-loader class="loader-data-partenza" [show]="dataPartenzaLoading" diameter="30"
                          typeMode="piker"></borsaviaggi-loader>
      <mat-form-field class="text-box mt-15px full-width" floatLabel="always" (click)="actionFocusDataPartenza()">
        <mat-label>Data partenza</mat-label>
        <input matInput formControlName="dataPartenza"

               [matDatepickerFilter]="partenzaFilter"
               disabled
               [hidden]="formGroup.get('dataPartenza').value"
               placeholder="Quando vuoi partire?" [matDatepicker]="pickerDataPartenze"
               autocomplete="off"
               [max]="maxDate"
               [min]="dateStart"
        >
        <span
          (click)="actionFocusDataPartenza()"
          [hidden]="!formGroup.get('dataPartenza').value">{{formGroup.get('dataPartenza').value | date:'dd/MM/yyyy'}}</span>
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>today</mat-icon>
        <mat-datepicker
          panelClass="search-vacanza-piker"
          [startAt]="dateStart"
          [disabled]="!formGroup.get('destinazione').valid"
          (closed)="pikerDataClose()"
          class="data-partenza" touchUi [dateClass]="partenzaDateCssCustom"
          [calendarHeaderComponent]="customCalendarHeader"
          #pickerDataPartenze></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-12 col-sm-2 col-md-2 col-lg-2 padding-0"
         [ngClass]="{'ricerca-container': filterDestinations}">
      <!-- col-button-ricerca padding-0 -->
      <button mat-raised-button type="submit"
              [ngClass]="{'button-disabled':!formGroup.valid}"
              class="ricerca-btn-col background-orange-borsaviaggi color-white {{cssSearchBtn}}">
        <!-- mat-icon-no-color -->
        <mat-icon role="img"
                  class="color-white fs-1_4rem" aria-hidden="true">search
        </mat-icon>
        <span [ngClass]="{'fs-1-6rem':isMobileDevice}"
              class="fs-1_2rem">Ricerca</span>
      </button>
    </div>
  </form>
</div>
