import {Inject, Injectable, Optional} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable()
export class UniversalInterceptor implements HttpInterceptor {

  constructor(@Optional() @Inject('serverUrl') protected serverUrl: string) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const serverReq = this.serverUrl && req.url.indexOf("http") === -1 ? req.clone({
      url: `${this.serverUrl}${req.url}`
    }) : req;
    return next.handle(serverReq);

  }

}
