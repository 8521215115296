import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

export class DatiPersonaliValidator {

  static formAccount(configData?): FormGroup {
    configData = configData || {
      nome: "",
      cognome: "",
      dataNascita: "",
      idComune: "",
      idRegione: "",
      sesso: "",
      email: "",
      telefono: "",
      cellulare: "",
      indirizzo: "",
      codiceFiscale: "",
      password:"",
      cap:""
    };
    return new FormBuilder().group({
      nome: new FormControl(configData.nome, [Validators.required]),
      cognome: new FormControl(configData.cognome, [Validators.required]),
      dataNascita: new FormControl(configData.dataNascita, [Validators.required]),
      idComune: new FormControl(configData.idComune, [Validators.required]),
      idRegione: new FormControl(configData.idRegione, [Validators.required]),
      email: new FormControl(configData.email, [Validators.required]),
      telefono: new FormControl(configData.telefono, [Validators.required]),
      cellulare: new FormControl(configData.cellulare, [Validators.required]),
      sesso: new FormControl(configData.sesso, Validators.compose([
        Validators.required
      ])),
      indirizzo: new FormControl(configData.indirizzo, Validators.compose([
        Validators.required
      ])),
      cap: new FormControl(configData.cap, Validators.compose([
        Validators.required
      ])),
      codiceFiscale: new FormControl(configData.codiceFiscale, Validators.compose([
        Validators.required, Validators.pattern(/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/)
      ])),
      password: new FormControl(configData.password, []),
      riceviNewsletter: new FormControl(configData.riceviNewsletter, [])
    });
  }

  public static formRichiestaInfoValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cellulare: new FormControl('', Validators.compose([ Validators.required])),
      email: new FormControl('', Validators.compose([
        Validators.email,
      ])),
      privacy: new FormControl(false, Validators.requiredTrue)
    });




  public static formRichiestaInfoErrorMessage: Object = {
    'email': [
      {type: 'email', message: "L' email inserita non è valida"}
    ],
    'cognome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'nome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cellulare': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'privacy': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ]
  };



}
