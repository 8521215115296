<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center background-white">
  <!--<a href="javascript:void(0);" (click)="getPrimeOrRegister()" [class.disabled]="userData && userData.userInfo.isPrime">
    <img class="mb-15px prime-banner" src="../../../assets/img/prime/Prime-Banner.jpg" alt="prime banner">
  </a>-->
  <div class="row mb-15px">
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 text-align-left prime-desc-container order-xl-12">
      <h1 class="titolo-prime">DIVENTA PRIME ADESSO...<br>&Egrave; PRATICAMENTE GRATIS!</h1>
      <p class="prime-desc mt-3">
        Se <b>attivi il PRIME adesso</b> e prenoti la tua
        vacanza almeno <b>45 giorni dopo</b> dalla data
        di attivazione, <b>riceverai uno sconto</b> sulla
        pratica <b>di 19€</b> e se sei al primo viaggio con
        noi riceverai un <b>buono sconto di 50€</b>
        applicabile alla prima prenotazione
      </p>
      <div class="w-100 mt-4 mb-15px text-align-left" [hidden]="userData && userData.userInfo.isPrime">
        <button mat-raised-button class="button-attiva font-weight-bold" (click)="getPrimeOrRegister()">
          <div class="fs-28px attiva">Attiva ORA<br><span class="fs-18px">il tuo PRIME con 19€</span></div>
        </button>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xl-5 order-xl-1">
      <img class="prime-banner" src="../../../assets/img/prime/Img-Prime-sito.jpg" alt="prime banner"/>
    </div>
  </div>
  <mat-card class="vantaggi-card mb-15px">
    <div class="vantaggi-container">
      <div class="fs-24px fw-500">Con PRIME accedi a Vantaggi esclusivi!</div>
      <div class="vantaggi-descr fs-16px">Borsaviaggi PRIME è il nuovo Programma Fedeltà in abbonamento che ti fa risparmiare
        sulle tue Vacanze per un anno intero, già dalla prima prenotazione. Scegli le offerte
        con il logo PRIME e scopri i diversi vantaggi che puoi trovare su ogni viaggio!</div>
    </div>
    <div class="row">
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/Prime-Icona-Spendi-Meno.png" alt="spendi meno icona">
        <div class="benefit-title fs-16px fw-500">Spendi Meno</div>
        <div class="benefit-descr fs-12px">Accedi subito ad un prezzo scontato valido per tutti i tuoi compagni di viaggio</div>
      </div>
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/Prime-Icona-Zero-Pensieri.png" alt="icona viaggio protetto">
        <div class="benefit-title fs-16px fw-500">Viaggio Protetto</div>
        <div class="benefit-descr fs-12px">Trovi inclusa gratuitamente il Service+ Borsaviaggi con Polizza Annullamento e Medico Bagaglio - incluso Covid-19 sia per annullamento che prolungamento soggiorno</div>
      </div>
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/Prime-Icona-Parcheggio.png" alt="parcheggio icona">
        <div class="benefit-title fs-16px fw-500">Parcheggio in Aeroporto</div>
        <div class="benefit-descr fs-12px">Per la durata del tuo viaggio hai incluso il parcheggio Auto in Aeroporto</div>
      </div>
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/icona-cashback.png" alt="icona cashback">
        <div class="benefit-title fs-16px fw-500">Fedeltà che ti Premia</div>
        <div class="benefit-descr fs-12px">Ad ogni viaggio per te un Buono fino a 200€ che potrai usare o regalare a chi vorrai</div>
      </div>
    </div>
  </mat-card>
  <div class="row mb-15px center-image">
    <div class="col-md-4">
      <img src="../../../assets/img/prime/Prime-Banner-PrimeDays.png" alt="prime days icona">
    </div>
    <div class="col-md vantaggio-extra fs-24px txt-center">Con i PRIME DAYS avrai accesso a giornate con offerte e vantaggi dedicati ai soli clienti PRIME</div>
  </div>
  <mat-card class="vantaggi-card mb-15px">
    <div class="vantaggi-container">
      <div class="fs-24px fw-500">Con PRIME accedi ad un Nuovo Livello di Servizio!</div>
      <div class="vantaggi-descr fs-16px">Avrai un Consulente Personale che disegna il viaggio dei tuoi sogni,
        un Assistente attivo 7 giorni su 7 che ti segue anche in Vacanza e molto altro!
      </div>
    </div>
    <div class="row">
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
        <div class="benefit-title fs-16px fw-500">Very Important Traveller</div>
        <div class="benefit-descr fs-12px">Sarai riconosciuto con priorità dal tuo Consulente di fiducia</div>
      </div>
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/Prime-Icona-Preventivi.png" alt="icona preventivi sartoriali">
        <div class="benefit-title fs-16px fw-500">Preventivi Sartoriali</div>
        <div class="benefit-descr fs-12px">Potrai richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura</div>
      </div>
      <a class="col mb-10px" href="prezzo-piu-basso">
        <img src="../../../assets/img/prime/Prime-Icona-Garanzia-Prezzo.png" alt="icona garanzia prezzo">
        <div class="benefit-title fs-16px  bv-dark-color fw-500">Garanzia Prezzo Migliore</div>
        <div class="benefit-descr fs-12px  bv-dark-color">Se hai un prezzo più economico ti adeguiamo il preventivo</div>
      </a>
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/Prime-Icona-Assistenza.png" alt="icona assistenza viaggi">
        <div class="benefit-title fs-16px fw-500">Assistenza Viaggio</div>
        <div class="benefit-descr fs-12px">Un Assistente online 7 giorni su 7 che ti segue in vacanza, dalla partenza al rientro</div>
      </div>
      <div class="col mb-10px">
        <img src="../../../assets/img/prime/icona-cashback-gialla.png" alt="icona sconti">
        <div class="benefit-title fs-16px fw-500">Fedeltà che ti Premia</div>
        <div class="benefit-descr fs-12px">Ad ogni viaggio per te un Buono fino a 200€ che potrai usare o regalare a chi vorrai</div>
      </div>
    </div>
  </mat-card>
  <!--<div class="row center-image">
    <div class="col-md-8 mb-15px center-image">
      <img class="freccia mr-15px" src="../../../assets/img/prime/Prime-Freccia.png">
      <div class="mr-0">
        <div class="fs-24px red text-align-center">DIVENTA PRIME ADESSO...È PRATICAMENTE GRATIS!</div>
        <div class="fs-14px text-align-center">Se attivi il PRIME in anticipo e prenoti la tua vacanza almeno 45 giorni
          dopo dalla
          data di attivazione, <span class="red">riceverai uno sconto sulla pratica di 19€ e se sei al primo viaggio con
            noi riceverai un buono sconto di 50€ applicabile alla prima prenotazione</span></div>
      </div>
    </div>
    <div class="col-md mb-15px" [hidden]="userData && userData.userInfo.isPrime">
      <button mat-raised-button class="button-attiva" (click)="getPrimeOrRegister()">
        <div class="fs-24px attiva">Attiva ORA <span class="fs-16px">il tuo PRIME con 19€</span></div>
      </button>
    </div>
  </div>-->
  <mat-card class="facile-prime">
    <div class="fs-24px fw-500 mb-8px">Diventare PRIME è facile!</div>
    <div class="fs-16px mb-8px">Attiva subito il PRIME per avere il massimo dei vantaggi!
      In alternativa potrai usufruire dei vantaggi PRIME direttamente alla prenotazione,
      aggiungendo al carrello l'Abbonamento annuale al costo di 19€</div>
    <div class="row">
      <div class="col-sm border-right">
        <div class="fs-24px fw-500"><mat-icon color="warn">shopping_cart</mat-icon>PRENOTI SUL PORTALE ONLINE?</div>
        <div>
          <ol>
            <li>
              <div class="fs-16px mb-8px">Ricerca la tua vacanza</div>
              <div class="fs-12px mb-15px">Consulta le migliaia di partenze presenti sul portale</div>
            </li>
            <li>
              <div class="fs-16px mb-8px">Scegli l'OFFERTA che ti piace</div>
              <div class="fs-12px mb-15px">Avrai a disposizione molte offerte PRIME, con vantaggi e sconti immediati ed offerte Miglior Prezzo a cui
                potrai aggiungere i plus di servizio PRIME</div>
            </li>
            <li>
              <div class="fs-16px mb-8px">Al check-out trovi il costo PRIME</div>
              <div class="fs-12px mb-15px">Per garantirti tutti i vantaggi ti verrà addebitato l'abbonamento PRIME annuale di 19€</div>
            </li>
          </ol>
        </div>
      </div>
      <div class="col-sm">
        <div class="fs-24px fw-500"><mat-icon color="warn">phone_in_talk</mat-icon>PRENOTI TRAMITE CALL CENTER?</div>
        <ul>
          <li class="mb-15px">Alla richiesta telefonica, se stai prenotando una OFFERTA PRIME i nostri consulenti ti indicheranno
            tutti i vantaggi inclusi e qualora tu voglia mantenere tutti i servizi ti verrà addebitato il costo
            di abbonamento PRIME annuale di 19€.</li>
          <li class="mb-15px">Sulle Offerte Miglior Prezzo ti verrà proposto l'abbonamento PRIME e ti verranno indicati tutti i
            vantaggi Fedeltà inclusi.</li>
        </ul>
      </div>
    </div>
  </mat-card>
</div>
