export class NavigationsUtilsService {

  private static lastState;

  public static setLastState(paramState) {
    NavigationsUtilsService.lastState = paramState;
  }

  public static getLastState() {
    return NavigationsUtilsService.lastState;
  }

  public static parseParams (querystring) {
    querystring = querystring.indexOf("?") !== -1 ? querystring.substr(querystring.indexOf("?")) :querystring ;
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj = {};

    // iterate over all keys
    for (const key of Object(params).keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  };
}
