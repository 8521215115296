import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {HomeService} from "../home.service";

@Injectable({
  providedIn: 'root'
})
export class VetrinaPrimeResolveService {
  constructor(private homeSrv: HomeService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let param = {

    };

    return this.homeSrv.getVetrinaOffertePrime(param)
    .catch(err => {
      return err;
    });

    // return this.destSrv.getOffertaByCode(dataParam)
    //   .catch(err => {
    //     return err;
    //   });

  }


}
