import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {ContainerDestinazioniService} from "../container-destinazioni.service";
import {SEO_PAGE} from "../../../constants/seo.const";

@Injectable({
  providedIn: 'root'
})
export class ContainerDettaglioDestinazioniResolveService {
  constructor(private cDestSrv: ContainerDestinazioniService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let dataParam = {
      "idDestinazione": routeSnap.params.idDestinazione,
      "idLuogo": routeSnap.params.fDataType == "lp" ? routeSnap.params.fDataId : 0,
      "isNazione": routeSnap.params.infoN == "n",
      "isItalia": routeSnap.params.isItalia == "i",
      "mese": routeSnap.params.fDataType == "m" ? routeSnap.params.fDataId : 0,
      "pageNumber": 1,
      "pageSize": 10
    };
    if (routeSnap.routeConfig.path && routeSnap.routeConfig.path.indexOf(SEO_PAGE.DESTINAZIONE_TOP_KEYWORD) !== -1) {
      dataParam["idDestinazioneTop"] = routeSnap.params.idSelezione;
    }
    return this.cDestSrv.getOfferteDestinazione(dataParam)
      .catch(err => {
        return err;
      });
  }

}
