import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderState} from "./loader.state.interface";

export enum LOADER_TYPE {
  PIKER = "piker",
  FULL_SCREEN = "fullScreen",
  SEARCH_VACANZA = "searchVacanza",
  SEARCH_CROCIERA = "searchCrociera"
}

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {
  }

  show() {
    this.loaderSubject.next(<LoaderState>{show: true});
  }

  hide() {
    this.loaderSubject.next(<LoaderState>{show: false});
  }

  setSearchVacanza() {
    this.loaderSubject.next(<LoaderState>{typeMode: LOADER_TYPE.SEARCH_VACANZA, show: false, loadPage: false});
  }

  showSearchVacanza() {
    this.loaderSubject.next(<LoaderState>{typeMode: LOADER_TYPE.SEARCH_VACANZA, show: true, loadPage: false});
  }

  hideSearchVacanza() {
    this.loaderSubject.next(<LoaderState>{typeMode: LOADER_TYPE.SEARCH_VACANZA, show: false, loadPage: false});
  }

  showFullScreen() {
    this.loaderSubject.next(<LoaderState>{typeMode: LOADER_TYPE.FULL_SCREEN, show: true, loadPage: true});
  }

  hideFullScreen() {
    this.loaderSubject.next(<LoaderState>{typeMode: LOADER_TYPE.FULL_SCREEN, show: false, loadPage: false});
  }
}
