import {ModalInterface} from "../interface/modal.interface";

export class ErrorModalDto implements ModalInterface<ErrorModalDto>{

  public title: string;
  public body_message: string;
  public button_text: string;

  constructor() {
    this.title        = "Error";
    this.body_message = "Internal server error. Retry please.";
    this.button_text  = "Chiudi";
  }


}
