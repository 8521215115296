import {Injectable} from "@angular/core";

@Injectable()
export class LocalStorageService {

  constructor(){

  }

  public setStorage(param: any){
    const storage = this.getStorage();
    if(storage){
      this.removeStorage();
    }
    localStorage.setItem('item',JSON.stringify(param));
  }

  public getStorage(){
    return JSON.parse(localStorage.getItem('item'));
  }

  private removeStorage(){
    localStorage.removeItem('item');
  }
}
