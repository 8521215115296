import {Component, OnInit} from '@angular/core';
import {LoaderService} from "../../../../components/loader/loader.service";
import {ModalService} from "../../../../services/common/modal.service";
import {UserService} from "../../../../services/gate/user.service";
import {AuthService} from "../../../../services/common/auth.service";


interface Newsletter {
  idTipoNewsLetter: number,
  newsLetter: string,
  isActive: boolean
}

@Component({
  selector: 'borsaviaggi-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  public datiUtente;
  public newsLetterPromo: Newsletter[] = [];
  public newsLetterPreferenze: Newsletter[] = [];

  constructor(private userSrv: UserService,
              private modalSrv: ModalService,
              private loaderSrv: LoaderService,
              private authSrv: AuthService) {
    this.datiUtente = this.authSrv.getUser();
  }

  ngOnInit(): void {
    this.getNewsLetter();
  }

  private getNewsLetter() {
    let newsLetterList: Newsletter[] = [];
    this.userSrv.getNewsLetterUtente().then((response) => {
      if (response && response.body.status === 0) {
        newsLetterList = response.body.data;
        //first 3 elements are 'Promo'
        for (let i = 0; i < 3; i++) {
          this.newsLetterPromo.push(newsLetterList[i]);
        }
        //last elements are 'Preferenze'
        for (let i = 3; i < newsLetterList.length; i++) {
          this.newsLetterPreferenze.push(newsLetterList[i]);
        }

      }
    }, (error) => {

    })
  }

  public updateNewsletterUtente() {
    let newsLetterArray: Newsletter[] = [];
    this.newsLetterPromo.forEach(function (item) {
      newsLetterArray.push(item)
    });
    this.newsLetterPreferenze.forEach(function (item) {
      newsLetterArray.push(item)
    });

    let requestObj = {
      email: this.datiUtente.email,
      listaNewsletter: newsLetterArray
    };

    this.loaderSrv.show();
    this.userSrv.updateNewsletterUtente(requestObj).then((response) => {
      if (response && response.body.status === 0) {
        this.modalSrv.openFeedbackModal({success: response.body.message});
      } else {
        this.modalSrv.openErrorDialog(response.body.message);
      }
    }, (error) => {

    }).finally(() => {
      this.loaderSrv.hide();
    });
  }

}
