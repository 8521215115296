import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable({
  providedIn: 'root'
})
export class VerificaTransazioneService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getVerifica(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/verificaTransazione", param);
  }

  public getPreventivo(idPreventivo): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/partenza/getPreventivo/" + idPreventivo);
  }

  public getPartenza(idPartenza): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getPartenza", idPartenza);
  }
}
