import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from "../../../model/user.dto";
import {AuthService} from "../../../services/common/auth.service";
import {Subscription} from "rxjs";
import * as moment from "moment";


@Component({
  selector: 'bv-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss']
})
export class CheckoutFormComponent implements OnInit {

  @Input() public camere: any;
  public dataCheckout: any;
  public userData: User;
  public arrSubscriber: Array<Subscription>;
  public listSesso: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private authSrv: AuthService
  ) {
    if (this.route.snapshot.data.dataCheckout.body) {
      this.dataCheckout = this.route.snapshot.data.dataCheckout.body.data;
      this.listSesso = [
        {value: "M", label: "Uomo"},
        {value: "F", label: "Donna"}
      ];
    }
  }

  ngOnInit() {
    this.userData = this.authSrv.getUser();
    if (this.camere) {
      this.camere.forEach((camera) => {
        camera.forms = this.createDynamicOspitiForm(camera)
      })
    }
    if (this.userData) {
      this.populateFormByUtente(this.userData.userInfo);
    }


    this.arrSubscriber = [
      this.authSrv.userObservable().subscribe(
        (result) => {
          if (result) {
            this.userData = this.authSrv.getUser();
            this.populateFormByUtente(this.userData.userInfo);
          }
        }
      )
    ]
  }


  public populateFormByUtente(user) {
    let formIntestatario = this.camere[0].forms[0];
    formIntestatario.get('nome-1-1').setValue(user.nome);
    formIntestatario.get('cognome-1-1').setValue(user.cognome);
    formIntestatario.get('dataNascita-1-1').setValue(moment(user.dataNascita).format('YYYY-MM-DD'));
    formIntestatario.get('sesso-1-1').setValue(user.sesso);
    formIntestatario.get('cellulare-1-1').setValue(user.cellulare);
    formIntestatario.get('email-1-1').setValue(user.email);
  }


  public createDynamicOspitiForm(camera) {
    let ospitiForm = [];
    camera.ospiti.forEach((ospite, i) => {
      let nome = 'nome' + '-' + camera.numCamera + '-' + ospite;
      let cognome = 'cognome' + '-' + camera.numCamera + '-' + ospite;
      let dataNascita = 'dataNascita' + '-' + camera.numCamera + '-' + ospite;
      let sesso = 'sesso' + '-' + camera.numCamera + '-' + ospite;
      let cellulare = 'cellulare' + '-' + camera.numCamera + '-' + ospite;
      let riceviNewsletter = 'riceviNewsletter' + '-' + camera.numCamera + '-' + ospite;
      let privacy = 'privacy' + '-' + camera.numCamera + '-' + ospite;
      let email = 'email' + '-' + camera.numCamera + '-' + ospite;
      let buonoSconto = 'buonoSconto' + '-' + camera.numCamera + '-' + ospite;
      if (camera.numCamera === "1" && ospite === 1) {
        let intestatarioForm: FormGroup =
          new FormBuilder().group({
            hideRequired: false,
            [nome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [cognome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [dataNascita]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [sesso]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [cellulare]: new FormControl('', Validators.compose([Validators.required])),
            [riceviNewsletter]: new FormControl('', Validators.compose([])),
            [privacy]: new FormControl('', Validators.compose([])),
            [email]: new FormControl('', Validators.compose([
              Validators.email,
              Validators.required
            ])),
            [buonoSconto]: new FormControl('', Validators.compose([]))
          });
        ospitiForm.push(intestatarioForm);
      } else {
        let formOspite: FormGroup =
          new FormBuilder().group({
            hideRequired: false,
            [nome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [cognome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [dataNascita]: new FormControl('', Validators.compose([
              Validators.required
            ]))
          });
        ospitiForm.push(formOspite);
      }
    });
    return ospitiForm;
  }

}
