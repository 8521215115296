import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {SearchEngineValidator} from "../../../services/validator/search-engine.validator";
import {ModalService} from "../../../services/common/modal.service";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDatepicker} from "@angular/material/datepicker";
import {Subscription} from "rxjs";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MESE_FORMATS} from "../../../services/common/dateTime.service";
import {ResponseCheckerService} from "../../../services/common/response.checker.service";
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from "../../loader/loader.service";
import {SearchEngineService} from "../search-engine.service";
import {SEO_OFFERTE} from "../../../constants/seo.const";


@Component({
  selector: 'borsaviaggi-search-crociere',
  templateUrl: './search-crociere.component.html',
  styleUrls: ['./search-crociere.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MESE_FORMATS},
  ],
})


export class SearchCrociereComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public formDisabled: boolean;
  private onLineOffLineSubscription: Subscription[] = new Array<Subscription>();
  public modelSearch: {
    date: { disponibili: Array<any> },
    destinazione: { list: Array<any> },
    portoDiPartenza: { list: Array<any> },
    compagniaCrociera: { list: Array<any> }
  };
  public showSearchForm: boolean = true;
  @ViewChild('pickerDataPartenze') pickerDataPartenze: MatDatepicker<any>;

  constructor(private modalSrv: ModalService,
              private loaderSrv: LoaderService,
              private searchEngineSrv: SearchEngineService,
              private activatedRoute: ActivatedRoute,
              private destinazioniSrv: DestinazioneService,
              private router: Router) {
    this.formGroup = SearchEngineValidator.formCrociere();
    this.modelSearch = {
      date: {disponibili: []},
      destinazione: {list: []},
      portoDiPartenza: {list: []},
      compagniaCrociera: {list: []}
    };
    this.destinazioniSrv.getCrociereDestinazioni().then(
      (response) => {
        if (ResponseCheckerService.isSuccessResponse(response.body)) {
          this.modelSearch.destinazione.list = response.body.data;
          if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.from === 'crociera') {
            this.formDisabled = true;
            let currDestinazione = this.modelSearch.destinazione.list.find(dest => dest.idDestinazioneCrociere === parseInt(this.activatedRoute.snapshot.queryParams.idDestinazione));
            this.formGroup.get('destinazione').setValue(currDestinazione);
            let param = {
              "data": "",
              "idLuogoPartenza": 0,
              "idCompagnia": 0,
              "idDestinazione": currDestinazione.idDestinazioneCrociere

            };
            this.destinazioniSrv.getCrociereDatePartenza(param).then(
              (resp) => {
                this.modelSearch.date.disponibili = resp.body.data;
                if (this.activatedRoute.snapshot.queryParams.data) {
                  this.formGroup.get('dataPartenza').setValue(this.activatedRoute.snapshot.queryParams.data);
                }
                if (this.activatedRoute.snapshot.queryParams.idPorto) {
                  this.formGroup.get('portoDiPartenza').setValue(parseInt(this.activatedRoute.snapshot.queryParams.idPorto));
                }
                if (this.activatedRoute.snapshot.queryParams.idCompagnia) {
                  this.formGroup.get('compagniaCrociera').setValue(parseInt(this.activatedRoute.snapshot.queryParams.idCompagnia));
                }
                else {
                  this.formGroup.get('compagniaCrociera').setValue(0);
                }
              }
            )
          }
        } else {
          console.error(ResponseCheckerService.getErrorMessage(response.body));
        }
      },
      (error) => {
        this.modelSearch.destinazione.list = [];
        console.log("Errore caricamento destinazioni crociere", error);
      }
    );
  }

  ngOnInit() {
    this.initListnerFormControl();
  }

  private initListnerFormControl() {

    this.onLineOffLineSubscription = [
      this.formGroup.get('destinazione').valueChanges.subscribe(
        (value) => {
          if (value) {
            this.formGroup.get('dataPartenza').setValue(null);
            this.formGroup.get('portoDiPartenza').setValue(null);
            this.formGroup.get('compagniaCrociera').setValue(null);
            this.formGroup.get('dataPartenza').enable();
            let param = {
              "data": "",
              "idLuogoPartenza": 0,
              "idCompagnia": 0,
              "idDestinazione": value.idDestinazioneCrociere

            };

            this.destinazioniSrv.getCrociereDatePartenza(param).then(
              (resp) => {
                this.modelSearch.date.disponibili = resp.body.data;
              }
            )
          }
        }
      ),
      this.formGroup.get('dataPartenza').valueChanges.subscribe(
        (value) => {
          if (value) {
            let param = {
              data: value,
              idLuogoPartenza: 0,
              idCompagnia: this.formGroup.get(''),
              idDestinazione: this.formGroup.get('destinazione').value.idDestinazioneCrociere
            };
            this.destinazioniSrv.getHomeCrocierePorti(param).then(
              (resp) => {
                if (resp.body) {
                  this.modelSearch.portoDiPartenza.list = resp.body.data;
                }
              },
              (error) => {
                this.modelSearch.portoDiPartenza.list = [];
                console.log("Errore caricamento porti di partenza", error);
              }
            );
          }
        }
      ),
      this.formGroup.get('portoDiPartenza').valueChanges.subscribe(
        (value) => {
          if (value) {
            let param = {
              data: this.formGroup.get('dataPartenza').value,
              idLuogoPartenza: value,
              idCompagnia: 0,
              idDestinazione: this.formGroup.get('destinazione').value.idDestinazioneCrociere
            };
            this.destinazioniSrv.getCrociereCompagnie(param).then(
              (resp) => {
                if (resp.body) {
                  this.modelSearch.compagniaCrociera.list = resp.body.data;
                }
              },
              (error) => {
                this.modelSearch.compagniaCrociera.list = [];
                console.log("Errore caricamento compagnia Crociera", error);
              }
            );
          }
        }
      )
    ];
  }


  public displayDestinazione(destinazione): string {
    return destinazione.nomeDestinazioneCrociere;
  }


  public actionSubmitRicercaCrociere() {
    let objParam = {
      destinazione: this.formGroup.get('destinazione').value,
      dataPartenza: this.formGroup.get('dataPartenza').value,
      portoDiPartenza: this.formGroup.get('portoDiPartenza').value,
      compagniaCrociera: this.formGroup.get('compagniaCrociera').value,
    };
    let queryParams = this.destinazioniSrv.generateUrlRisultatiCrociere(objParam);
    this.formDisabled = true;
    this.searchEngineSrv.start();
    this.router.navigate([SEO_OFFERTE.RISULTATI_CROCIERE_KEYWORD], {queryParams: queryParams});
  }

  ngOnDestroy() {
    this.onLineOffLineSubscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
    // unsubscribe to ensure no memory leaks
  }
}
