import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from "../../services/common/modal.service";

@Component({
  selector: 'borsaviaggi-mostra-mappa',
  templateUrl: './mostra-mappa.component.html',
  styleUrls: ['./mostra-mappa.component.scss']
})
export class MostraMappaComponent implements OnInit {
  @Input() luogo;
  @Input() nazione;

  constructor(public modalSrv:ModalService) { }

  ngOnInit() {
  }

  public openMapsModal() {
    let paramData = {
      luogo: this.luogo,
      nazione:this.nazione
    };
    this.modalSrv.openMapsModal(paramData);
  }

}
