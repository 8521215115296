import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import {ResultCardConfig} from "../../model/search-results/resultCardConfig.model";
import {UserService} from "../../services/gate/user.service";
import * as moment from "moment";
import {Router} from "@angular/router";
import {ImageUtilService} from "../../services/common/image.util.service";
import {UtilsStringService} from "../../services/common/utils-string.service";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalService} from "../../services/common/modal.service";


@Component({
  selector: 'result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss']
})

export class ResultCardComponent implements OnInit, OnChanges {

  @Input() config: ResultCardConfig;
  @Input() data: any;
  @Output() preferitoCancellato = new EventEmitter<boolean>();
  @Input() cardReduce: any;
  public isAreaPersonale: boolean;
  public isNotAreaPersonale: any;
  public photoNumber: number;
  public linkContinua;
  public reduceCard;
  public photoArray;
  public isMobileDevice;
  public webPSupport;

  @Input() public configObj;


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
    this.linkContinua = this.getLinkContinua();
  }


  constructor(public userSrv: UserService,
              private deviceService: DeviceDetectorService,
              public route: Router,
              public modalSrv: ModalService) {
    moment.locale('it');
    this.isMobileDevice = this.deviceService.isMobile();
    this.webPSupport = ImageUtilService.supportWebP();
  }

  ngOnInit() {
    this.init();
  }

  private getLinkContinua() {
    let ret;
    if (this.data.idPartenzaCrociera) {
      ret = '/crociera/' + UtilsStringService.removeSpecialChar(this.data.compagnia) + '/' + UtilsStringService.removeSpecialChar(this.data.title) + '/' +
        UtilsStringService.removeSpecialChar(this.data.luogoPartenza) + '/' + this.getDataPUrl(this.data.dataPartenza) + '/c/' + this.data.idPartenzaCrociera;
    } else if (this.data.tipoPartenza === "crociera") {
      ret = '/crociera/nave/itinerario' + UtilsStringService.removeSpecialChar(this.data.compagnia) + '/' + UtilsStringService.removeSpecialChar(this.data.title) + '/' +
        UtilsStringService.removeSpecialChar(this.data.luogoPartenza) + '/' + this.getDataPUrl(this.data.dataPartenza) + '/c/' + this.data.idPartenza;
    } else if (this.data.idPartenza) {
      ret = '/offerta/' + UtilsStringService.removeSpecialChar(this.data.nazione || "nazione") + '/' + UtilsStringService.removeSpecialChar(this.data.destinazione || "destinazione") + '/' +
        UtilsStringService.removeSpecialChar(this.data.nomeStruttura || "struttura") + '/' + UtilsStringService.removeSpecialChar(this.data.luogoPartenza || "partenza-da") + '/' + this.getDataPUrl(this.data.dataPartenza)
        + '/p/' + this.data.idPartenza
    }
    return ret;
  }

  public init() {
    this.isAreaPersonale = this.config.isListaPrenotazioni || this.config.isListaDesideri;
    this.isNotAreaPersonale = !this.config.isListaDesideri && !this.config.isListaPrenotazioni;
    this.photoNumber = 0;
    this.linkContinua = this.getLinkContinua();
    this.data.nameIconDeparturePlace = null;

    if (this.data.iconaLuogoPartenza === 'aereo') {
      this.data.nameIconDeparturePlace = 'flight';
    }
    if (this.data.iconaLuogoPartenza === 'macchina') {
      this.data.nameIconDeparturePlace = 'directions_car';
    }
    if (this.data.iconaLuogoPartenza === 'nave') {
      this.data.nameIconDeparturePlace = 'directions_boat';
    }
    if (this.data.iconaLuogoPartenza === 'bus') {
      this.data.nameIconDeparturePlace = 'directions_bus';
    }

    if (this.config.isListaDesideri) {
      let dataDestinazioneArr = this.data.titolo.split('-');
      this.data.destinazione = dataDestinazioneArr[1];
      this.data.titolo = dataDestinazioneArr[0];
      this.photoArray = [{foto: this.data.urlFoto, fotoWebp: this.data.urlFotoWebp}];
    } else {
      this.photoArray = this.data.photos;
    }
  }


  public getDataPUrl(paramDate) {
    return moment(paramDate).format('DD-MMMM');
  }

  public parseFullDate(paramDate) {
    return moment(paramDate).format('DD MMMM YYYY');
  }

  public deleteDesideri() {
    let dataParam = {idPartenza: this.data.idPartenza};
    let service = this.data.tipoPartenza === "crociera" ? (param) => this.userSrv.deleteListaDesideriCrocieraUtente(param)
      : (param) => this.userSrv.deleteListaDesideriUtente(param);

    service(dataParam).then(
      (response) => {
        if (response && response.body && response.body.data) {
          this.preferitoCancellato.emit(true);
        } else {
          console.log("Errore nella cancellazione dei preferiti!");
        }
      },
      (error) => {
        console.log(error);

      }
    );
  }

  public openMapsModal(nomeStruttura, nomeDestinazione) {
    let paramData = {
      luogo: nomeStruttura,
      nazione: nomeDestinazione
    };
    this.modalSrv.openMapsModal(paramData);
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['cardReduce'];
    if (change) {
      this.reduceCard = change.currentValue;
    }
  }

}
