<div class="background">
  <div class="breadcrumb-container">
    <div class="container">
      <div class="row no-gutters breadcrumb-row">
        <div class="col-4 breadcrumb first"
             [ngClass]="{'selected': checkoutStep === 'step1'}">
          <div class="title">Personalizza</div>
          <div class="description">Personalizza la tua crociera</div>
          <div class="arrow outline"
               [ngClass]="{'selected': checkoutStep === 'step1'}"></div>
          <div class="arrow" [ngClass]="{'selected': checkoutStep === 'step1'}">
          </div>
        </div>
        <div class="col-4 breadcrumb indented"
             [ngClass]="{'selected': checkoutStep === 'step2'}">
          <div class="title">Richiesta preventivo</div>
          <div class="description">Richiedi il preventivo gratuito per la tua crociera</div>
          <div class="arrow outline"
               [ngClass]="{'selected': checkoutStep === 'step2'}"></div>
          <div class="arrow" [ngClass]="{'selected': checkoutStep === 'step2'}">
          </div>
        </div>
        <div class="col-4 breadcrumb indented"
             [ngClass]="{'selected': checkoutStep === 'step3'}">
          <div class="title">Ordine completato</div>
          <div class="description">La tua crociera ti aspetta</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-block d-md-none" [hidden]="checkoutStep === 'step3'" *ngIf="isMobileDevice">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-xl-4">
        <div class="background-white text-align-left mb-30px" *ngIf="isMobileDevice">
          <a [routerLink]="urlPartenza">
            <button class="prenota" mat-raised-button>
              <mat-icon matPrefix>chevron_left</mat-icon>
              Torna alla Partenza
            </button>
          </a>
        </div>
        <div class="top-box">
          <div class="top-section-wrapper" *ngIf="!dataResponse.isCrocieraLive">
            <div class="price-wrapper dati-preventivo">
              <div class="price-description">
                Riepilogo richiesta
              </div>
              <mat-accordion *ngIf="dataResponse.cabineSelezionate.length > 0">
                <mat-expansion-panel class="bg-black dark-blue cabin-price-container"
                                     *ngFor="let cabina of cabineSelezionateTopBox; let isFirst = first"
                                     [expanded]="isFirst">
                  <mat-expansion-panel-header class="cabin-price">
                    <mat-panel-title>
                    </mat-panel-title>
                    <mat-panel-description>
                      <b class="color-white"> Cabina {{cabina.idCabina}}</b>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row color-white mt-10px">
                    <div class="col-12">
                      <span class="fs-1_3rem">{{getCategoriaCabina(cabina)}}</span>
                    </div>
                  </div>
                  <div class="row color-white fs-small">
                    <div class="col-6 text-align-left">Adulti:</div>
                    <div class="col-6 text-align-right">{{cabina.numAdulti}}</div>
                  </div>
                  <div class="row color-white fs-small" *ngIf="cabina.etaBambini.length > 0">
                    <div class="col-6 text-align-left">Bambini:</div>
                    <div class="col-6 text-align-right">{{cabina.etaBambini.length}}</div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="mt-2rem">
                <div class="row d-flex prime-abbonamento"
                     *ngIf="showPrime && (!userData || (userData && !userData.userInfo.isPrime))">
                  <div class="col-6 key text-align-left">Abbonamento <span class="prime-text">PRIME</span></div>
                  <div class="col-6 value text-align-right">+{{prezzoPrime}}€</div>
                </div>
              </div>
            </div>
          </div>
          <mat-accordion *ngIf="dataResponse.isCrocieraLive">
            <mat-expansion-panel class="background-sconto background-blue-borsaviaggi"
                                 (opened)="toggleAccoridionRiepilogo(true)" (closed)="toggleAccoridionRiepilogo(false)">
              <mat-expansion-panel-header class="specific-class h-60px">
                <mat-panel-title class="d-flex justify-content-center align-items-center">
                  <div class="fs-1-6rem bold color-white">Riepilogo dei costi:</div>
                  <div class="fs-2_4rem bold color-text-orange-borsaviaggi ml-10px">
                    <span>{{(costoTotale + (showPrezzoTotaleConAssicurazione ? insurancePrice : 0) + (userData ? (userData.userInfo.isPrime ? 0 : (showPrime ? 19 : 0)) : (showPrime ? 19 : 0)) - sconto) | euroFormat}}</span>
                    <span class="fs-1-6rem ml-2px">€</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="bold fs-1-6rem color-white text-align-center mt-1rem">Sistemazione:</div>
              <div class="top-box-live">
                <div class="top-section-wrapper">
                  <div class="col-12">
                    <ul class="sistemazioni-list">
                      <li *ngFor="let cabina of dataResponse.cabineSelezionate" class="list-elements">
                        <p class="key fs-1_1rem mb-8px">
                          {{cabina.nomeCabina}} - <span class="value">{{cabina.nomeTariffaCabina}}</span>
                        </p>
                        <p>
                          <span class="value">{{cabina.numAdulti + cabina.numBambini}}
                            <span *ngIf="(cabina.numAdulti + cabina.numBambini) === 1">passeggero</span>
                            <span *ngIf="(cabina.numAdulti + cabina.numBambini) > 1">passeggeri</span>
                          </span>
                        </p>
                        <p>
                          Età: {{cabina.etaAdulti}}<span *ngIf="cabina.etaBambini.length > 0">,{{cabina.etaBambini}}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content row no-gutters">
                    <div class="detail col-12"
                         *ngIf="showPrime && (!userData || (userData && !userData.userInfo.isPrime))">
                      <div class="key">Abbonamento <span class="prime-text">PRIME</span></div>
                      <div class="value">+{{prezzoPrime}}€</div>
                    </div>
                    <div class="detail col-12" *ngIf="showPrezzoTotaleConAssicurazione">
                      <div class="key">Annullamento <span class="success-color">SERVICE+</span></div>
                      <div class="value ">
                        +{{ insurancePrice | euroFormat }}€
                      </div>
                    </div>
                    <div class="detail col-12" *ngIf="sconto">
                      <div class="key">Buono Sconto</div>
                      <div class="value ">
                        -{{sconto}}€
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="tab-wrapper">
            <div class="tab">
              <div class="tab-content row no-gutters">
                <div class="riepilogo title text-align-center col-12">
                  Riepilogo Crociera
                </div>
                <div class="detail col-12">
                  <div class="key">Porto di partenza</div>
                  <!-- <div class="value">Civitavecchia</div> -->
                  <div class="value">{{dataResponse.porto}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Partenza il</div>
                  <!-- <div class="value">27 febbraio 2020</div> -->
                  <div class="value">{{dataResponse.dataPartenza}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Rientro il</div>
                  <!-- <div class="value">09 marzo 2020</div> -->
                  <div class="value">{{dataResponse.dataRientro}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Durata</div>
                  <!-- <div class="value">3 giorni/ 2 notti</div> -->
                  <div class="value">{{dataResponse.giorni}}&nbsp;giorni/{{dataResponse.notti}}&nbsp;notti</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Nave</div>
                  <!-- <div class="value">3 giorni/ 2 notti</div> -->
                  <div class="value">{{dataResponse.nomeNave}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Codice Crociera</div>
                  <!-- <div class="value">3 giorni/ 2 notti</div> -->
                  <div class="value">C{{dataResponse.idCrociera}}</div>
                </div>
                <div class="detail col-12" *ngIf="dataResponse.isVoloIncluso">
                  <div class="key"
                       *ngIf="dataResponse.isVoloIncluso">Volo
                  </div>
                  <!-- <div class="value">VOLO NON INCLUSO</div> -->
                  <div *ngIf="dataResponse.isVoloIncluso" class="value">VOLO INCLUSO</div>
                  <!--<div *ngIf="!dataResponse.isVoloIncluso" class="value">VOLO NON INCLUSO</div>-->
                </div>
                <ng-container *ngIf="dataResponse.isVoloIncluso && dataResponse.voloSelezionato">
                  <div class="riepilogo title text-align-center col-12">
                    Volo
                  </div>
                  <div class="detail col-12">
                    <div class="key">Tipo</div>
                    <div *ngIf="dataResponse.voloSelezionato && dataResponse.voloSelezionato.tipoVolo"
                         class="value">{{dataResponse.voloSelezionato.tipoVolo}}</div>
                  </div>
                  <div class="detail col-12">
                    <div class="key">Aeroporto di partenza</div>
                    <div *ngIf="dataResponse.voloSelezionato && dataResponse.voloSelezionato.tipoVolo"
                         class="value">{{dataResponse.voloSelezionato.nome}}</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-8 col-xl-8">
        <!-- ----------------------------------------------- -->
        <!-- STEP 1 - Personalizza il tuo viaggio -->
        <div *ngIf="checkoutStep === 'step1'" class="checkout-card">
          <div class="background-white text-align-left mt-20px" *ngIf="!isMobileDevice">
            <a [routerLink]="urlPartenza">
              <button class="prenota" mat-raised-button>
                <mat-icon matPrefix>chevron_left</mat-icon>
                Torna alla Partenza
              </button>
            </a>
          </div>
          <form [formGroup]="formBuonoSconto">
            <div class="mt-20px mb-2em description-container main-sconto">
              <div class="main-title-container description-services col-12">
                <div class="title">
                  Hai un codice sconto?
                </div>
              </div>
              <div class="info-container row no-gutters">
                <div class="dettagli-form display-flex col-12 pb-1rem">
                  <div class="d-flex align-items-center pr-1rem" *ngIf="!isMobileDevice">
                    <img class="buono-sconto" src="../../../assets/img/icons/img-buonoSconto.png" alt="buono sconto">
                  </div>
                  <div class="col overflow-hidden">
                    <div class="text">
                      Inserisci il codice e verifica se è applicabile all'offerta!
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="row-mobile">
                          <div class="d-flex align-items-center" *ngIf="isMobileDevice">
                            <img class="buono-sconto-mobile" src="../../../assets/img/icons/img-buonoSconto.png"
                                 alt="buono sconto">
                          </div>
                          <mat-form-field class="sconto-field" name="select" appearance="outline">
                            <mat-label>Buono sconto</mat-label>
                            <input [disabled]="sconto > 0" matInput id="buonoSconto" placeholder="Buono sconto"
                                   type="text" formControlName="buonoSconto">
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="col-12 col-sm-4 col-md-4 col-lg-4 text-align-center">
                        <div class="button-style-side" (click)="applicaSconto()">Applica</div>
                      </div>
                    </div>
                    <div *ngIf="erroreSconto" class="text mb-1rem red">
                      <b>{{erroreSconto}}</b>
                    </div>
                    <div *ngIf="sconto" class="text mb-1rem">
                      Sconto: <b>{{sconto}}€</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="big-title bold text-align-center color-text-blue-borsaviaggi mt-1rem mb-1rem">
            Personalizza il tuo viaggio
          </div>
          <!-- ASSICURAZIONE -->
          <div class="description-container" [hidden]="assicurazionePrime && showPrezzoTotaleConAssicurazione">
            <div class="main-title-container description-services col-12">
              <div class="flex-auto" *ngIf="isMobileDevice">
                <img class="prime-mobile" src="../../../assets/img/icons/Banner-Service-Partenza-quadrato.png"
                     alt="prime mobile">
              </div>
              <div class="title" *ngIf="!isMobileDevice">
                <div class="checkbox-wrapper">
                  <mat-checkbox (change)="onChangeCheckboxAssicurazione($event)"
                                [disabled]="assicurazionePrime && showPrezzoTotaleConAssicurazione" [checked]="showPrezzoTotaleConAssicurazione"></mat-checkbox>
                </div>
                Prenotazione SuperProtetta - Cruise Service+
              </div>
              <div class="title" *ngIf="isMobileDevice">
                Aggiungi Cruise <span class="service-text">Service +</span>
              </div>
              <div class="codice-p" *ngIf="dataResponse">
                + {{ insurancePrice | euroFormat }} € <span *ngIf="!isMobileDevice" class="fs-10px">(a cabina)</span>
              </div>
              <div class="pt-1rem pb-1rem float-right" *ngIf="isMobileDevice">
                <mat-checkbox (change)="onChangeCheckboxAssicurazione($event)"
                              [disabled]="assicurazionePrime && showPrezzoTotaleConAssicurazione" [checked]="showPrezzoTotaleConAssicurazione"></mat-checkbox>
              </div>
            </div>
            <mat-accordion class="service"
                           *ngIf="isMobileDevice">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="fs-1_2rem bold l-height text-align-center">Vacanza Protetta - Cruise
                      <span class="service-text ml-2px">Service +</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="text fs-1_1rem l-height">
                  Con la Cruise Service+ ti proponiamo un servizio eccezionale ad un costo esclusivo!
                  In sostituzione della semplice Polizza Medico/Bagaglio della Compagnia, ottieni il nostro pacchetto di servizi
                  inclusivi di Help Desk Medico,  Assicurazione Medico/Bagaglio e l'esclusiva Polizza Annullamento che consente
                  il rimborso della tua vacanza in tutti i casi di necessità di annullamento certificabili - incluso Covid-19 sia per annullamento che prolungamento soggiorno.
                </div>
                <div class="row no-gutters mt-1em">
                  <div class="col-12 col-sm-6">
                    <a href="assets/pdf/Set_informativo_Amitravel_Ed_2_24.06.2022.pdf"
                       title="Assicurazione condizioni EuropAssistance"
                       class="fs-1_1rem bold cursor-pointer mt-05rem full-width" target="_blank">>> Leggi le
                      Condizioni</a>
                  </div>
                  <div class="col-12 col-sm-6 text-align-right">
                    <img class="partner-icon" src="{{linkIconaPartnerAssicurativo}}"
                         alt="Partner Assicurazione">
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <div class="info-container row no-gutters">
              <div class="col-12" *ngIf="!isMobileDevice">
                <img class="banner" src="../../../assets/img/icons/Banner-Service-Crociera-Covid.png"
                     alt="proteggi vacanza">
              </div>
              <div class="dettagli d-none d-sm-block col-12">
                <div class="text">
                  Con la Cruise Service+ ti proponiamo un servizio eccezionale ad un costo esclusivo!
                  In sostituzione della semplice Polizza Medico/Bagaglio della Compagnia, ottieni il nostro pacchetto di servizi
                  inclusivi di Help Desk Medico,  Assicurazione Medico/Bagaglio e l'esclusiva Polizza Annullamento che consente
                  il rimborso della tua vacanza in tutti i casi di necessità di annullamento certificabili - incluso Covid-19 sia per annullamento che prolungamento soggiorno.
                </div>
                <div class="row no-gutters mt-1em">
                  <div class="col-12 col-sm-6">
                    <a href="{{linkEstrattoPolizza}}"
                       title="Assicurazione condizioni EuropAssistance"
                       class="condizioni text bold cursor-pointer mt-05rem full-width" target="_blank">>> Leggi le
                      Condizioni</a>
                  </div>
                  <div class="col-12 col-sm-6 text-align-right">
                    <img class="partner-icon" src="{{linkIconaPartnerAssicurativo}}"
                         alt="Partner Assicurazione">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PRIME -->
          <div class="description-container">
            <div class="main-title-container col-12 description-services"
                 *ngIf="!userData || userData && (userData && !userData.userInfo.isPrime)">
              <div class="flex-auto" *ngIf="isMobileDevice">
                <img class="prime-mobile" src="../../../../assets/img/icons/Prime-Banner-quadrato.png"
                     alt="prime mobile">
              </div>
              <div class="title">
                <div class="checkbox-wrapper" *ngIf="!isMobileDevice">
                  <mat-checkbox (change)="onChangeCheckboxPrime($event)" [value]="showPrime"></mat-checkbox>
                </div>
                Aggiungi <span class="prime-text">PRIME</span>
              </div>
              <div class="codice-p">+ {{prezzoPrime}} €</div>
              <div class="checkbox-wrapper pt-1rem pb-1rem float-right"
                   *ngIf="isMobileDevice">
                <mat-checkbox (change)="onChangeCheckboxPrime($event)" [value]="showPrime"></mat-checkbox>
              </div>
            </div>

            <!-- Accordion Prime -->
            <mat-accordion class="prime"
                           *ngIf="isMobileDevice">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="d-flex justify-content-center">
                    <div class="fs-1_2rem bold">
                      {{(userData && userData.userInfo && userData.userInfo.isPrime) ?
                      'Per te che sei un utente ' : 'Vantaggi esclusivi '}}
                      <span class="prime-text fs-1_2rem bold">PRIME</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="text l-height">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Assistenza.png"
                               alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold fs-1_1rem">Assistenza in Viaggio 7/7</span>
                          <br/><span class="fs-1rem">Un Assistente online 7 giorni su 7 che ti segue in vacanza, dalla partenza al rientro</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold fs-1_1rem">Very Important Traveller</span>
                          <br/><span class="fs-1rem">{{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                          'Sei ricunosciuto con priorità dal tuo Consulente di fiducia' : 'Sarai riconosciuto con priorità dal tuo Consulente di fiducia'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Preventivi.png"
                               alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold fs-1_1rem">Preventivi Sartoriali</span>
                          <br/><span class="fs-1rem">
                            {{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                          'Puoi richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura' : 'Potrai richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura'}}
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Sconti.png" alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold fs-1_1rem">Fedeltà che ti Premia</span>
                          <br/><span class="fs-1rem">Prenotando questa Offerta per te un Buono di <span
                          class="red bold">{{destSrv.getImportoBuonoSconto(costoTotale)}}€</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="info-container row no-gutters"
                 *ngIf="!isMobileDevice">
              <div class="col-12">
                <img class="banner" src="../../../assets/img/icons/Banner-Prime-Partenza.png" alt="banner prime">
              </div>
              <div class="dettagli d-none d-sm-block col-12">
                <div class="title">{{(userData && userData.userInfo && userData.userInfo.isPrime) ?
                  'Per te che sei un utente ' : 'Vantaggi esclusivi '}}
                  <span class="prime-text">PRIME</span></div>
                <!-- Se non hai PRIME -->
                <div class="text">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Assistenza.png"
                               alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold">Assistenza in Viaggio 7/7</span>
                          <br/><span>Un Assistente online 7 giorni su 7 che ti segue in vacanza, dalla partenza al rientro</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold">Very Important Traveller</span>
                          <br/><span>{{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                          'Sei ricunosciuto con priorità dal tuo Consulente di fiducia' : 'Sarai riconosciuto con priorità dal tuo Consulente di fiducia'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Preventivi.png"
                               alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold">Preventivi Sartoriali</span>
                          <br/><span>
                            {{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                          'Puoi richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura' : 'Potrai richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura'}}
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="row mt-05rem mb-05rem">
                        <div class="col-4">
                          <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Sconti.png" alt="icona vit">
                        </div>
                        <div class="col-8">
                          <span class="bold">Fedeltà che ti Premia</span>
                          <br/><span>Prenotando questa Offerta per te un Buono di <span
                          class="red bold">{{destSrv.getImportoBuonoSconto(costoTotale)}}€</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END OF: Se non hai PRIME -->

              </div>
            </div>
          </div>
          <div class="info-container mb-15px row no-gutters" *ngIf="!userData">
            <div class="dettagli">
              <bv-gia-cliente-prime-box></bv-gia-cliente-prime-box>
            </div>
          </div>
          <div class="col-12">
            <div (click)="nextStepCheckout('step2')" class="btn-main-centered">
              <div class="btn-container">Procedi
                <mat-icon>navigate_next</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 2 - Preventivo Crociera -->
        <div *ngIf="checkoutStep === 'step2'" class="description-container">
          <div class="big-title color-text-blue-borsaviaggi" *ngIf="!isMobileDevice">
            Prenota il tuo viaggio
          </div>
          <div class="info-container bb-container row no-gutters mt-1em">
            <div class="dettagli col-12"> <!-- *ngIf="showAllForms" -->
              <!-- DETTAGLI SISTEMAZIONE -->
              <!--div *ngIf="cabineDaPrenotare && dataResponse.isVoloIncluso" class="row pt-15px">
                <div class="col-12">
                  <span class="fs-1_2rem bold p-0 m-0">Dettagli sistemazione</span>
                </div>
              </div-->
              <form [formGroup]="formCheckoutCrociera">
                <div *ngFor="let cabina of cabineDaPrenotareForm; let i = index">
                  <div class="bv-form-main-title-container col-12">
                    <div class="title">Cabina {{cabina.numCabina}}</div>
                  </div>
                  <div *ngFor="let ospite of cabina.ospiti; let j = index;">
                    <div class="dettagli-form">
                      <div class="title-form">
                        <span *ngIf="ospite === 1 && cabina.numCabina === 1">{{ospite}}) Intestatario Pratica</span>
                        <span class="ospite" *ngIf="cabina.numCabina !== 1 || (cabina.numCabina === 1 && ospite !== 1)">{{ospite}}) Ospite</span>
                      </div>
                    </div>
                    <div class="dettagli-form">
                      <form [formGroup]="cabina.forms[0]" *ngIf="ospite === 1 && cabina.numCabina === 1">
                        <!-- INTESTATARIO -->
                        <div class="form-group">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input required matInput placeholder="Nome" type="text"
                                       formControlName="nome-{{cabina.numCabina}}-{{ospite}}"
                                >
                                <bv-form-error
                                  [formControlParam]="cabina.forms[0].get('nome-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Cognome</mat-label>
                                <input required matInput placeholder="Cognome" type="text"
                                       formControlName="cognome-{{cabina.numCabina}}-{{ospite}}">
                                <bv-form-error
                                  [formControlParam]="cabina.forms[0].get('cognome-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline" (click)="dataDiNascita.open()">
                                <mat-label>Data di nascita</mat-label>
                                <input required matInput formControlName="dataNascita-{{cabina.numCabina}}-{{ospite}}"
                                       placeholder="Data di nascita" [matDatepicker]="dataDiNascita"
                                       autocomplete="off">
                                <mat-datepicker
                                  #dataDiNascita></mat-datepicker>
                                <bv-form-error
                                  [formControlParam]="cabina.forms[0].get('dataNascita-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Sesso</mat-label>
                                <mat-select required
                                            placeholder="Sesso"
                                            formControlName="sesso-{{cabina.numCabina}}-{{ospite}}">
                                  <mat-option *ngFor="let sesso of listSesso" [value]="sesso.value">
                                    {{sesso.label}}
                                  </mat-option>
                                </mat-select>
                                <bv-form-error
                                  [formControlParam]="cabina.forms[0].get('sesso-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Cellulare</mat-label>
                                <input required matInput placeholder="Cellulare" type="text"
                                       formControlName="cellulare-{{cabina.numCabina}}-{{ospite}}">
                                <bv-form-error
                                  [formControlParam]="cabina.forms[0].get('cellulare-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input required matInput placeholder="Email" type="text"
                                       formControlName="email-{{cabina.numCabina}}-{{ospite}}">
                                <bv-form-error
                                  [formControlParam]="cabina.forms[0].get('email-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <!-- END OF: INTESTATARIO -->
                      </form>
                      <form [formGroup]="cabina.forms[j]"
                            class="ospite-form"
                            *ngIf="cabina.numCabina !== 1 || (cabina.numCabina === 1 && ospite !== 1)">
                        <!-- NON INTESTATARIO -->
                        <div class="form-group">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input required matInput placeholder="Nome" type="text"
                                       formControlName="nome-{{cabina.numCabina}}-{{ospite}}">
                                <bv-form-error
                                  [formControlParam]="cabina.forms[j].get('nome-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Cognome</mat-label>
                                <input required matInput placeholder="Cognome" type="text"
                                       formControlName="cognome-{{cabina.numCabina}}-{{ospite}}">
                                <bv-form-error
                                  [formControlParam]="cabina.forms[j].get('cognome-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                              <mat-form-field class="full-width" appearance="outline" (click)="dataDiNascita.open()">
                                <mat-label>Data di nascita</mat-label>
                                <input required matInput formControlName="dataNascita-{{cabina.numCabina}}-{{ospite}}"
                                       placeholder="Data di nascita" [matDatepicker]="dataDiNascita"
                                       autocomplete="off">
                                <mat-datepicker
                                  #dataDiNascita></mat-datepicker>
                                <bv-form-error
                                  [formControlParam]="cabina.forms[j].get('dataNascita-'+cabina.numCabina+'-'+ospite)"></bv-form-error>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <!-- END OF: NON INTESTATARIO -->
                      </form>
                    </div>
                  </div>
                </div>
                <!-- AEROPORTO -->
                <div *ngIf="dataResponse.isVoloIncluso" class="row pt-15px">
                  <div class="col-12">
                    <span class="fs-1_2rem bold p-0 m-0">Scegli l'aeroporto</span>
                    <hr>
                  </div>
                  <div *ngIf="dataResponse.voli" class="col-12">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Aeroporto</mat-label>
                      <mat-select required placeholder="Aeroporto" formControlName="volo">
                        <mat-option *ngFor="let volo of dataResponse.voli" [value]="volo.idVolo">
                          {{volo.tipoVolo + ' ' + volo.nome + ' - ' + volo.costo + ' €'}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="dataResponse.volo" class="col-12">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Aeroporto</mat-label>
                      <mat-select required placeholder="Aeroporto" formControlName="volo">
                        <mat-option [value]="dataResponse.volo.idVolo">
                          {{dataResponse.volo.tipoVolo + ' ' + dataResponse.volo.nome + ' - ' + dataResponse.volo.costo + ' €'}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>
              <div class="dettagli-form">
                <div class="row">
                  <div class="col-12">
                    <div class="title-form">Segnalaci tue particolari necessità</div>
                  </div>
                  <form [formGroup]="formNote" class="col-12 pb-0">
                    <mat-form-field class="col-12 pb-0" appearance="outline">
                      <mat-label>Note</mat-label>
                      <textarea matInput class="h-8rem w-100" placeholder="Note" formControlName="note"></textarea>
                    </mat-form-field>
                  </form>
                  <form [formGroup]="formPrivacy" class="col-12 mt-4">
                    <mat-checkbox formControlName="privacy" class="left full-width" [checked]="true"> Ho letto ed accetto
                      l'<a routerLink="/informativa-privacy">Informativa Privacy</a> in merito al trattamento dei miei dati personali
                    </mat-checkbox>
                    <mat-checkbox formControlName="riceviNewsletter" class="left mb-20px">Consenso per l'invio di materiale
                      promozionale (facoltativo)
                    </mat-checkbox>
                  </form>
                </div>
              </div>
              <!-- END OF: INTESTATARIO -->
            </div>
          </div>
          <!--<div class="col-12 mt-1rem mb-1rem">
            <div (click)="onClickPrenotaCrociera()" class="btn-main-centered">Procedi</div>
          </div>-->
          <div class="col-12">
            <div class="btn-wrapper">
              <div (click)="previousStepCheckout()" class="btn-main back-btn">
                <div class="btn-container">
                  <mat-icon>navigate_before</mat-icon>
                  Indietro
                </div>
              </div>
              <div (click)="onClickPrenotaCrociera()" class="btn-main privacy-next"
                   [ngClass]="{'disabled': disableNext}">
                <div class="btn-container">Procedi
                  <mat-icon>navigate_next</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF: STEP 2 - PRENOTA -->
      <!-- STEP 3 - Ordine Completato -->
      <div *ngIf="checkoutStep === 'step3'" class="checkout-card">
        <div class="description-container">
          <div class="info-container row no-gutters step-3">
            <div class="col-12 col-md-6 col-xl-6 d-flex align-items-center mt-10px">
              <img class="col-12 ordine-completato" src="../../../assets/img/icons/ordine-completato.png"
                   alt="ordine completato">
            </div>
            <div class="col-6 col-sm-12 col-md-6 col-xl-6 dettagli bt-grey-default bb-grey-default txt-step3 col-12">
              <img class="icon-success" src="../../../assets/img/icons/success-tick.svg" alt="success">
              <div class="title-success">Grazie per aver completato la tua richiesta!</div>
              <span class="display-block fs-1rem text-center">Un nostro consulente prenderà rapidamente in carico la tua richiesta.
                      Ti contatterà per concludere la prenotazione, illustrandoti le modalità di pagamento disponibili per la conferma del viaggio.*</span>
              <span class="display-block text-center fs-10px mt-10px">*Servizio attivo in orario operativo - lun/ven: 9:00 - 19:00 / sab: 9:30/13:00</span>
              <div class="btn-main-home">
                <a href="/" class="btn-container">Torna in Home
                  <mat-icon>house</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{'riepilogo-fixed': desktopRiepilogo, 'fixed-element': stopFixed}"
           class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="!isMobileDevice && checkoutStep !== 'step3'">
        <div class="top-box">
          <div class="top-section-wrapper">
            <div class="price-wrapper dati-preventivo" *ngIf="!dataResponse.isCrocieraLive">
              <div class="price-description">
                Riepilogo richiesta
              </div>
              <mat-accordion *ngIf="dataResponse.cabineSelezionate.length > 0">
                <mat-expansion-panel class="bg-black dark-blue"
                                     *ngFor="let cabina of cabineSelezionateTopBox; first as isFirst"
                                     [expanded]="isFirst">
                  <mat-expansion-panel-header class="cabin-price">
                    <mat-panel-title>
                    </mat-panel-title>
                    <mat-panel-description>
                      <b class="color-white"> Cabina {{cabina.idCabina}}</b>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row color-white fs-small mt-10px">
                    <div class="col-12">
                      <span>{{getCategoriaCabina(cabina)}}</span>
                    </div>
                  </div>
                  <div class="row color-white fs-small">
                    <div class="col-6">Adulti:</div>
                    <div class="col-6">{{cabina.numAdulti}}</div>
                  </div>
                  <div class="row color-white fs-small" *ngIf="cabina.etaBambini.length > 0">
                    <div class="col-6">Bambini:</div>
                    <div class="col-6 display-inline">{{cabina.etaBambini.length}}
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="mt-2rem">
                <div class="row d-flex justify-content-center align-items-baseline prime-abbonamento"
                     *ngIf="showPrime && (!userData || (userData && !userData.userInfo.isPrime))">
                  <div class="key">Abbonamento <span class="prime-text">PRIME</span></div>
                  <div class="value ml-8px">+{{prezzoPrime}}€</div>
                </div>
              </div>
              <div class="mt-2rem">
                <div class="row d-flex justify-content-center align-items-baseline prime-abbonamento"
                     *ngIf="showPrezzoTotaleConAssicurazione">
                  <div class="key">Annullamento <span class="success-color">SERVICE+</span></div>
                  <div class="value ml-8px">+{{ insurancePrice | euroFormat }}€</div>
                </div>
              </div>
              <div class="mt-2rem">
                <div class="row d-flex justify-content-center align-items-baseline prime-abbonamento"
                     *ngIf="sconto">
                  <div class="key">Buono sconto</div>
                  <div class="value ml-8px">-{{sconto}}€</div>
                </div>
              </div>
            </div>
            <div class="price-wrapper dati-preventivo" *ngIf="dataResponse.isCrocieraLive">
              <div class="price-description fs-1-4rem font-weight-bold">
                Riepilogo dei Costi:
              </div>
              <div class="price color-text-orange-borsaviaggi">
                <span>{{(costoTotale + (showPrezzoTotaleConAssicurazione ? insurancePrice : 0) + (userData ? (userData.userInfo.isPrime ? 0 : (showPrime ? 19 : 0)) : (showPrime ? 19 : 0)) - sconto) | euroFormat}}</span>
                <span class="fs-1-6rem">€</span>
              </div>
              <div class="fw-800 fs-1_4rem">Sistemazione:</div>
              <div class="col-12">
                <ul class="sistemazioni-list">
                  <li *ngFor="let cabina of dataResponse.cabineSelezionate" class="list-elements">
                    <p class="key fs-1_1rem mb-8px">
                      {{cabina.nomeCabina}} - <span class="value">{{cabina.nomeTariffaCabina}}</span>
                    </p>
                    <p>
                      <span class="value">{{cabina.numAdulti + cabina.numBambini}}
                        <span *ngIf="(cabina.numAdulti + cabina.numBambini) === 1">passeggero</span>
                        <span *ngIf="(cabina.numAdulti + cabina.numBambini) > 1">passeggeri</span>
                      </span>
                    </p>
                    <p>
                      Età: {{cabina.etaAdulti}}<span *ngIf="cabina.etaBambini.length > 0">,{{cabina.etaBambini}}</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div class="tab-content row no-gutters">
                <div class="detail col-12"
                     *ngIf="showPrime && (!userData || (userData && !userData.userInfo.isPrime))">
                  <div class="key">Abbonamento <span class="prime-text">PRIME</span></div>
                  <div class="value">+{{prezzoPrime}}€</div>
                </div>
                <div class="detail col-12" *ngIf="showPrezzoTotaleConAssicurazione">
                  <div class="key">Annullamento <span class="success-color">SERVICE+</span></div>
                  <div class="value ">
                    +{{ insurancePrice | euroFormat }}€
                  </div>
                </div>
                <div class="detail col-12" *ngIf="sconto">
                  <div class="key">Buono Sconto</div>
                  <div class="value ">
                    -{{sconto}}€
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-wrapper">
            <div class="tab">
              <div class="tab-content row no-gutters">
                <div class="title text-align-center">
                  Riepilogo Crociera
                </div>
                <div class="detail col-12">
                  <div class="key">Porto di partenza</div>
                  <!-- <div class="value">Civitavecchia</div> -->
                  <div class="value">{{dataResponse.porto}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Partenza il</div>
                  <!-- <div class="value">27 febbraio 2020</div> -->
                  <div class="value">{{dataResponse.dataPartenza}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Rientro il</div>
                  <!-- <div class="value">09 marzo 2020</div> -->
                  <div class="value">{{dataResponse.dataRientro}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Durata</div>
                  <!-- <div class="value">3 giorni/ 2 notti</div> -->
                  <div class="value">{{dataResponse.giorni}}&nbsp;giorni/{{dataResponse.notti}}&nbsp;notti</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Nave</div>
                  <!-- <div class="value">3 giorni/ 2 notti</div> -->
                  <div class="value">{{dataResponse.nomeNave}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Codice Crociera</div>
                  <!-- <div class="value">3 giorni/ 2 notti</div> -->
                  <div class="value">C{{dataResponse.idCrociera}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key"
                       *ngIf="dataResponse.isVoloIncluso">Volo
                  </div>
                  <!-- <div class="value">VOLO NON INCLUSO</div> -->
                  <div *ngIf="dataResponse.isVoloIncluso" class="value">VOLO INCLUSO</div>
                  <!--<div *ngIf="!dataResponse.isVoloIncluso" class="value">VOLO NON INCLUSO</div>-->
                </div>
                <ng-container *ngIf="dataResponse.isVoloIncluso && dataResponse.voloSelezionato">
                  <div class="title">
                    Volo
                  </div>
                  <div class="detail col-12">
                    <div class="key">Tipo</div>
                    <div *ngIf="dataResponse.voloSelezionato && dataResponse.voloSelezionato.tipoVolo"
                         class="value">{{dataResponse.voloSelezionato.tipoVolo}}</div>
                  </div>
                  <div class="detail col-12">
                    <div class="key">Aeroporto di partenza</div>
                    <div *ngIf="dataResponse.voloSelezionato && dataResponse.voloSelezionato.tipoVolo"
                         class="value">{{dataResponse.voloSelezionato.nome}}</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
