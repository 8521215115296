import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";
import {MacroBoxInterface} from "../../components/macro-box/config.interface";
import {MacroBoxEnum} from "../../components/macro-box/config-layout.enum";
import {SEO_OFFERTE, SEO_PAGE} from "../../constants/seo.const";
import {ImageUtilService} from "../common/image.util.service";

@Injectable({
  providedIn: 'root'
})
export class MacroDestinazioneService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getNostraSelezione(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getNostraSelezione", param);
  }

  public getDestinazioniNostraSelezione(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getDestinazioniNostraSelezione", param);
  }

  public getAeroportiNostraSelezione(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getAeroportiNostraSelezione", param);
  }

  public getDestinazioneTop(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/offerta/getDestinazioneTop/" + param);
  }

  public searchPartenzeNostreSelezioni(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/searchPartenzeNostreSelezioni", param);

  }

  public getPartenzeNostraSelezione(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getPartenzeNostraSelezione", param);
  }

  public static macroDestinazioniBoxConfigParser(paramData, macroTitle, idSelection, isMacroDestTop): Array<MacroBoxInterface> {
    let ret = [];
    paramData.map(
      (currItem) => {
        let tempObj = {
          boxConfigType: MacroBoxEnum.MACRO_VERTICAL_BOX,
          imgBackground: ImageUtilService.supportWebP() ? currItem.urlFotoWebp : currItem.urlFoto,
          idDestinazione: currItem.idDestinazione,
          titolo: currItem.nomeDestinazione,
          sottoTitolo: null,
          btnText: null,
          nomePortale: null,
          btnUrl: isMacroDestTop ? "/" + SEO_PAGE.DESTINAZIONE_TOP_KEYWORD + "/" + idSelection + "/" + macroTitle + "/" + currItem.idDestinazione + "/" + (currItem.isNazione ? "n" : "p") + "/dettaglio" :
            '/' + macroTitle + '/' + idSelection + '/' + SEO_OFFERTE.OFFERTA_MACRO_KEYWORD + '/' + currItem.nomeDestinazione + "/" + currItem.idDestinazione
        };
        ret.push(tempObj);
      }
    );

    return ret;
  }
}
