<div class="container border-r-2px mt-20px pt-10px  text-align-justify background-white">
  <div class="row">
    <div class="col text-align-center">
      <h1 class="color-text-blue-borsaviaggi">
        Informativa sulla Privacy
      </h1>
    </div>
  </div>
  <div class="row mt-15px mb-20px">
    <div class="col">
      <p>
        Per finalità connesse alla fornitura del Servizio, Borsaviaggi.it della Borsaviaggi S.r.l. [in seguito Borsaviaggi.it] - con sede a Roma, Via del Serafico, 185 - tratta i dati inviati dall'Utente, o comunque acquisiti in sede di esecuzione del Servizio.
        Il trattamento dei dati avviene con procedure idonee a tutelare la riservatezza dell'Utente e consiste nella loro raccolta, registrazione, organizzazione, conservazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, diffusione, cancellazione, distruzione degli stessi comprese la combinazione di due o più delle attività suddette.
      </p>
      <p>Il trattamento dei dati ha finalità connesse o strumentali al Servizio fornito da Borsaviaggi.it, e precisamente:</p>
      <ul class="mt-15px">
        <li>raccogliere dati ed informazioni in via generale e particolare sugli orientamenti e le preferenze dell'Utente; </li>
        <li>inviare informazioni ed offerte commerciali, anche di terzi;</li>
        <li>inviare materiale pubblicitario e informativo;</li>
        <li>effettuare comunicazioni commerciali, anche interattive;</li>
        <li>compiere attività dirette di vendita o di collocamento di prodotti o servizi;</li>
        <li>elaborare studi e ricerche statistiche su vendite, clienti e altre informazioni, ed eventualmente comunicare le stesse a terze parti;</li>
      </ul>
      <p>
        Il trattamento dei dati dell'Utente per le finalità sopraindicate avrà luogo prevalentemente con modalità automatizzate ed informatizzate, sempre nel rispetto delle regole di riservatezza e di sicurezza previste dalla legge. I dati saranno conservati per i termini di legge presso Borsaviaggi.it e trattati da parte di dipendenti e/o professionisti da questa incaricati, i quali svolgono le suddette attività sotto la sua diretta supervisione e responsabilità.
        A tal fine, i dati comunicati dall'Utente potranno essere trasmessi a soggetti esterni che svolgono funzioni strettamente connesse e strumentali all'operatività del Servizio.
      </p>
      <p>
        Il conferimento del consenso al trattamento dei propri dati personali da parte dell'Utente è facoltativo.
        Un eventuale rifiuto ad inserirli nella pagina dedicata alla registrazione rende, però, impossibile l´utilizzo del Servizio.
      </p>
      <p>
        Ai sensi dell'Articolo 7 del d.lg. 196/2003 (già 675/96) l'Utente può, secondo le modalità e nei limiti previsti dalla vigente normativa, richiedere la conferma dell'esistenza di dati personali
        che lo riguardano, riceverne comunicazione intelligibile, avere informazioni circa la logica e le finalità del trattamento, richiederne l'aggiornamento,
        la rettifica, l'integrazione, richiedere la cancellazione, la trasformazione in forma anonima, il blocco dei dati trattati in violazione di legge, ivi compresi quelli non più necessari al perseguimento degli scopi per i quali sono stati raccolti, nonché, più in generale, esercitare tutti i diritti che gli sono riconosciuti dalle vigenti disposizioni di legge
        anche scrivendo all'indirizzo info@borsaviaggi.it
      </p>
    </div>
  </div>
</div>
