import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../../components/loader/loader.service";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {Meta, Title} from "@angular/platform-browser";
import {ImageUtilService} from "../../../services/common/image.util.service";
import {SEO_OFFERTE, SEO_PAGE} from "../../../constants/seo.const";
import {isPlatformBrowser} from "@angular/common";

interface DepartureInfo {
  id: string,
  isAereoporto: string,
  isBus: string,
  isPorto: string,
  isSoloSoggiorno: string,
  name: string,
  type: string
}

@Component({
  templateUrl: './dettaglio-destinazione.component.html',
  styleUrls: ['./dettaglio-destinazione.component.scss']
})
export class DettaglioDestinazioneComponent implements OnInit {
  public displayedColumns: string[] = [
    'partenzaDa',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'prezzo',
    'actions'
  ];
  public dataResponse;
  public photos: any;
  public elencoTutteOfferte: any;
  public destinazioneInOfferte: any;
  public configObj: any;
  public filterForm: FormGroup;
  public nazione = "";
  public idDestinazione = "";
  public idTo = "";
  public objDataSource: any;
  public infoPartner = {
    nomeTo: "",
    title: "",
    description: "",
    imageUrl: ""
  };
  public routerParams: any;
  public dateRange = [
    {value: '3', text: '+/- 3 gg'},
    {value: '7', text: '+/- 7 gg'}
  ];

  public destinations: string;
  public luoghiPartenzaDisponibili: any;
  public optionsForMSelect: DepartureInfo[]; //option objects for multi-select
  public selectedOptions: DepartureInfo[]; //list of selected transport modes
  public selectedOptionsView: any; //list of transport names to view in the select
  public navigationSubscription;
  public filteredDestinations;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loaderSrv: LoaderService,
    private titleService: Title,
    private metaTagService: Meta,
    private destSrv: DestinazioneService,
    @Inject(PLATFORM_ID) private platformId: string) {

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      //if NavigationEnd event, re-initialize the component
      if (e instanceof NavigationEnd) {
        this.initComponent();
      }
    });
  }

  private initComponent() {
    this.loadRouterParams();

    if (isPlatformBrowser(this.platformId)) {
      this.loadDestinazione();
    }

    let title = this.infoPartner.nomeTo + " - Offerte viaggi - " + this.nazione;
    this.titleService.setTitle(title);
    this.metaTagService.updateTag(
      {name: 'description', content: this.infoPartner.description},
    );

    this.metaTagService.updateTag(
      {name: 'title', content: title},
    );

    this.metaTagService.updateTag(
      {property: 'og:title', content: title},
    );

    this.metaTagService.updateTag(
      {property: 'og:description', content: this.infoPartner.description},
    );
    //this.loadDestinazione();
  }

  private loadRouterParams() {
    this.routerParams = this.route.snapshot.params;
    this.nazione = this.capitalize(this.routerParams.title);
    this.idDestinazione = this.routerParams.idDest;
    this.idTo = this.routerParams.idTo;
  }

  private loadDestinazione() {
    this.loaderSrv.show();

    this.elencoTutteOfferte = this.route.snapshot.data.dataPartner.body.data.elencoTutteOfferte;
    this.destinazioneInOfferte = this.route.snapshot.data.dataPartner.body.data.destinazioneInOfferte;
    this.filteredDestinations = [{
      idDestinazione: this.destinazioneInOfferte.length > 1 ? this.routerParams.idDest
        : this.destinazioneInOfferte[0].idDestinazione,
      nomeDestinazione: this.destinazioneInOfferte.length > 1 ? this.routerParams.title[0].toUpperCase()
        + this.routerParams.title.slice(1) :
        this.destinazioneInOfferte[0].nomeDestinazione,
      isNazione: this.destinazioneInOfferte.length > 1
    }];
    this.infoPartner = {
      nomeTo: this.route.snapshot.data.dataPartner.body.data.nomeTo,
      title: this.route.snapshot.data.dataPartner.body.data.title,
      description: this.route.snapshot.data.dataPartner.body.data.description,
      imageUrl: this.route.snapshot.data.dataPartner.body.data.imageUrl
    };

    this.makeDataForHtmlComponent();
    /*let dataParam = {
      "idDestinazione": this.idDestinazione,
      "idTo": this.idTo
    };

    this.loaderSrv.show();
    this.destSrv.getDestinazione(dataParam).then(
      (response) => {
        if (response && response.body.status === 0) {
          this.elencoTutteOfferte = response.body.data.elencoTutteOfferte;
          this.destinazioneInOfferte = response.body.data.destinazioneInOfferte;
          this.filteredDestinations = [{
            idDestinazione: this.destinazioneInOfferte.length > 1 ? this.routerParams.idDest
              : this.destinazioneInOfferte[0].idDestinazione,
            nomeDestinazione: this.destinazioneInOfferte.length > 1 ? this.routerParams.title[0].toUpperCase()
              + this.routerParams.title.slice(1) :
              this.destinazioneInOfferte[0].nomeDestinazione,
            isNazione: this.destinazioneInOfferte.length > 1
          }];
          this.infoPartner = {
            nomeTo: response.body.data.nomeTo,
            title: response.body.data.title,
            description: response.body.data.description,
            imageUrl: response.body.data.imageUrl
          };
          let title = this.infoPartner.nomeTo + " - Offerte viaggi - " + this.nazione;
          this.titleService.setTitle(title);
          this.metaTagService.updateTag(
            {name: 'description', content: this.infoPartner.description},
          );

          this.metaTagService.updateTag(
            {name: 'title', content: title},
          );

          this.metaTagService.updateTag(
            {property: 'og:title', content: title},
          );

          this.metaTagService.updateTag(
            {property: 'og:description', content: this.infoPartner.description},
          );
          this.makeDataForHtmlComponent();
        } else {
          console.log("Errore: " + response.body.status + " - " + response.body.message);
        }
      },
      (error) => {
        console.log("Errore chiamata a ws getDestinazione", error);
      }
    ).finally(() => {
      this.loaderSrv.hide();
    });*/
  }

  private makeDataForHtmlComponent() {
    this.objDataSource = [];
    this.elencoTutteOfferte.forEach(offerta => {
      let infoOfferta = {
        destinazione: offerta.destinazione,
        idOfferta: offerta.idOfferta,
        idStruttura: offerta.idStruttura,
        nomeStruttura: offerta.nomeStruttura,
        titolo: offerta.titolo
      };

      let images = ImageUtilService.supportWebP() ? offerta.fotosWebp : offerta.fotos;
      let listaPartenze = [];

      offerta.partenzeOfferta.forEach(partenza => {
        let infoPartenza = {
          costoTotaleMinimo: partenza.costoTotaleMinimo,
          al: partenza.al,
          dal: partenza.dal,
          giorniNotti: partenza.giorni + "/" + partenza.notti,
          idLuogoPartenza: partenza.idLuogoPartenza,
          idOfferta: partenza.idOfferta,
          luogoPartenza: partenza.luogoPartenza,
          trattamento: partenza.trattamento,
          url: ""
        };
        infoPartenza.url = this.makePartenzaUrl(infoOfferta, infoPartenza);
        listaPartenze.push(infoPartenza);
      });

      let tabellaPartenze = new MatTableDataSource(listaPartenze);

      let fullDataObj = {
        infoOfferta,
        images,
        tabellaPartenze
      };
      this.objDataSource.push(fullDataObj);
    });

    this.destinations = this.destinazioneInOfferte.map(function (val) {
      return val.idDestinazione;
    }).join(',');

    this.optionsForMSelect = [];
  }

  ngOnInit() {
    this.filterForm = new FormGroup({
      data: new FormControl('', Validators.required),
      tolerance: new FormControl(this.dateRange[0].value, Validators.required),
      transportMode: new FormControl({value: '', disabled: true}, Validators.required)
    });
  }


  private makePartenzaUrl(infoOfferta, infoPartenza) {
    let url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    url += this.nazione + "/";
    url += infoOfferta.destinazione + "/";
    url += infoOfferta.idStruttura + "/";
    url += "g" + "/";
    url += infoOfferta.idOfferta + "/";
    url += infoPartenza.idLuogoPartenza;

    return url.toLowerCase();
  }

  public makeFilterUrl(data) {
    let url = "/" + SEO_PAGE.DETTAGLIO_DESTINAZIONE + "/";
    url += this.infoPartner.nomeTo + "/";
    url += data.nomeDestinazione + "/";
    url += this.routerParams.idTo + "/";
    url += data.idDestinazione + "/";
    return url.toLowerCase();
  }

  public getDepartureIcon(obj) {
    let icon_name;
    if (obj.isAeroporto) {
      icon_name = 'flight';
    } else if (obj.isBus) {
      icon_name = 'directions_bus';
    } else if (obj.isPorto) {
      icon_name = 'directions_boat';
    } else if (obj.isSoloSoggiorno) {
      icon_name = 'directions_car';
    } else {
      icon_name = '';
    }
    return icon_name;
  }

  private capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  public buildMSelectOptions(data) {
    let optionsArray = [];
    if (data.length > 0) {
      this.filterForm.get('transportMode').enable();
    }
    data.forEach(elem => {
      let optionsForSelect = <DepartureInfo>{
        id: elem.idLuogoPartenza,
        name: elem.nome,
        type: this.getDepartureIcon(elem)
      };
      optionsArray.push(optionsForSelect);
    });

    this.optionsForMSelect = optionsArray;
  }

  ngOnDestroy() {
    // router.events is global, unsubscribe handler on component destruction
    // to avoid continuing to run initComponent() method on every navigationEnd event in the application
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }


}
