import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import {UtilityService} from "../../services/gate/utility.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalService} from "../../services/common/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../components/loader/loader.service";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'borsaviaggi-travel-expert',
  templateUrl: './travel-expert.component.html',
  styleUrls: ['./travel-expert.component.scss']
})
export class TravelExpertComponent implements OnInit, AfterViewInit {
  panelOpenState = false;
  public statusConferma: boolean = false;
  public isMobileDevice: boolean;
  public mobileStickyHeader: boolean;
  public formGroup: FormGroup;
  public buttonEnabled;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.mobileStickyHeader = scrollOffset >= 100;
  }

  constructor(private utilitySrv: UtilityService,
              public route: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              public router: Router,
              private modalSrv: ModalService,
              private loaderSrv: LoaderService) {

    let status = this.route.snapshot.queryParamMap.get('status');

    this.formGroup = new FormGroup({
      'nome': new FormControl('', Validators.required),
      'cognome': new FormControl('', Validators.required),
      'email': new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      'cellulare': new FormControl('', Validators.required),
      'descrizioneViaggio': new FormControl('', Validators.required),
      'budget': new FormControl('', Validators.required),
      'oraRicontatto': new FormControl('', Validators.required),
      'privacy': new FormControl('', Validators.required),
      'riceviNewsletter': new FormControl('')
    });

    this.formGroup.get('privacy').valueChanges.subscribe((val) => {
      if (val) {
        this.formGroup.get('riceviNewsletter').setValue(true);
      }
    });

    this.isMobileDevice = this.deviceService.isMobile();
    this.statusConferma = status == 'conferma';
  }

  ngOnInit(): void {
    //this.formGroup.get('privacy').setValue(true);
  }

  ngAfterViewInit() {
    this.formGroup.get('privacy').valueChanges.subscribe((val) => {
      this.buttonEnabled = val;
    });
  }

  public registraRichiesta() {
    if (this.formGroup.valid) {
      this.loaderSrv.show();

      let param = {
        "nome": this.formGroup.controls['nome'].value,
        "cognome": this.formGroup.controls['cognome'].value,
        "email": this.formGroup.controls['email'].value,
        "cellulare": this.formGroup.controls['cellulare'].value,
        "descrizioneViaggio": this.formGroup.controls['descrizioneViaggio'].value,
        "budget": this.formGroup.controls['budget'].value,
        "oraRicontatto": this.formGroup.controls['oraRicontatto'].value,
        "riceviNewsletter": this.formGroup.controls["riceviNewsletter"].value
      };

      this.utilitySrv.registrazioneTravelExpert(param).then(
        (response) => {
          if (response && response.body && (response.body.status === 0 || response.body.status === 1)) {
            this.formGroup.reset();

            this.loaderSrv.hide();
            this.modalSrv.openFeedbackModal({success: 'La richiesta è stata inviata con successo.'});
          } else {
            this.formGroup.reset();

            this.modalSrv.openErrorDialog(response.body.message);
          }
        },
        (error) => {
          this.modalSrv.openErrorDialog(error);
        }
      );
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(
      () =>
        this.router.navigate([uri], {queryParams: {status: 'conferma'}})
    );
  }
}
