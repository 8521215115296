<div *ngIf="show" [ngClass]="{
  'fullscreen':fullScreen,
  'piker-loader':typeMode === 'piker',
  'fullscreen-loader': typeMode === 'fullScreen',
  'search-loader': typeMode === 'searchCrociera' || typeMode === 'searchVacanza'}">
  <div class="spinner-container" [ngClass]="typeMode">
    <!-- add gif loader -->
<!--      <img src="../../../assets/img/loader.gif" alt="loader" >-->
    <mat-progress-spinner
      *ngIf="typeMode != 'searchVacanza' && typeMode != 'searchCrociera'"
      #spinner
      class="example-margin"
      [color]="color"
      [diameter]="diameter"
      mode="indeterminate"
    ></mat-progress-spinner>
    <mat-card class="search-vacanza" *ngIf="typeMode == 'searchVacanza'">
      <img class="borsaviaggi-logo-img" src="../../../assets/img/logo-borsaviaggi.png" alt="Borsaviaggi logo">
      <img src="../../../assets/img/aereo-logo.gif" alt="aereo">
      <h2 class="font-weight-light text-vacanze">Qualche secondo d'attesa...</h2>
      <h2 class="bold text-vacanze">Stiamo selezionando la tua vacanza<br>perfetta tra centinaia di soluzioni!</h2>
    </mat-card>
    <mat-card class="search-vacanza" *ngIf="typeMode == 'searchCrociera'">
      <img src="../../../assets/img/aereo-logo.gif" alt="aereo">
      <h2 class="font-weight-light text-crociere">Qualche secondo d'attesa...</h2>
      <h2 class="bold text-crociere">Stiamo selezionando la tua crociera<br>perfetta tra centinaia di soluzioni!</h2>
    </mat-card>
  </div>
</div>
