import {Component, HostListener, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {Meta, Title} from "@angular/platform-browser";
import * as moment from "moment";
import {ModalService} from "../../../services/common/modal.service";
import {ImageUtilService} from "../../../services/common/image.util.service";
import {MacroDestinazioneService} from "../../../services/gate/macro-destinazione.service";
import {ContainerDestinazioniService} from "../../../services/gate/container-destinazioni.service";
import {SEO_OFFERTE, SEO_PAGE} from "../../../constants/seo.const";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";


interface DepartureInfo {
  id: string,
  isAeroporto: string,
  isBus: string,
  isPorto: string,
  isSoloSoggiorno: string,
  name: string,
  type: string
}

@Component({
  selector: 'borsaviaggi-dettaglio-destinazione-nostra-selezione',
  templateUrl: './dettaglio-destinazione-nostra-selezione.component.html',
  styleUrls: ['./dettaglio-destinazione-nostra-selezione.component.scss']
})
export class DettaglioDestinazioneNostraSelezioneComponent implements OnInit {

  public displayedColumns: string[] = [
    'partenzaDa',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'prezzo',
    'actions'
  ];
  public filteredColumns: string[] = [
    'partenzaDa',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'icons',
    'prime',
    'prezzo',
    'actions'
  ];
  public dataResponse;
  public photos: any;
  public elencoTutteOfferte: any;
  public destinazioneInOfferte: any;
  public configObj: any;
  public filterForm: FormGroup;
  public objDataSource: any;
  public infoPartner: any;
  public routerParams: any;
  public titleImageUrl;
  public title;
  public titleDescription;
  public dateRange = [
    {value: '3', text: '+/- 3 gg'},
    {value: '7', text: '+/- 7 gg'}
  ];
  public destinations: string;
  public luoghiPartenzaDisponibili: any;
  public optionsForMSelect: DepartureInfo[]; //option objects for multi-select
  public selectedOptions: DepartureInfo[]; //list of selected transport modes
  public selectedOptionsView: any; //list of transport names to view in the select
  public filterDestinations;
  public navigationSubscription;
  public routeParams;
  public routerUrls;
  public routerData;
  public titleDestinazioniTop;
  public isMobileDevice: boolean;
  public disableFilterButton: boolean;
  public listaMesi;
  public currentPage: number;
  public loadingValues;
  public dataTable;
  public filteredValues;
  public searchEngineDestinations;
  public lastScrollOffset;
  public ricercaItalia: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let scrollElementScroll = document.querySelector('mat-drawer-container').scrollHeight - 1000;
    if (this.objDataSource.length < this.routerData.totaleOfferte &&
      this.lastScrollOffset < scrollElementScroll
      && scrollOffset > scrollElementScroll
      && !this.loadingValues) {
      this.lastScrollOffset = scrollOffset;
      this.currentPage++;
      this.loadMoreOfferte();
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    private metaTagService: Meta,
    private destSrv: DestinazioneService,
    private cDestSrv: ContainerDestinazioniService,
    private modalSrv: ModalService,
    private macroDestSrv: MacroDestinazioneService) {
    this.currentPage = 1;
    this.lastScrollOffset = 0;
    this.isMobileDevice = this.deviceService.isMobile();
    this.disableFilterButton = true;
    this.loadingValues = false;
    this.routerParams = this.route.snapshot.params;
    this.routerData = this.route.snapshot.data.dataPartner.body.data;
    this.routerUrls = this.route.snapshot.url;
    this.titleDestinazioniTop = this.route.snapshot.url[2].path;
    this.titleDestinazioniTop = this.titleDestinazioniTop.replace(/-/g, ' ');
    this.filterDestinations = [...new Set(this.route.snapshot.data.dataPartner.body.data.destinazioneInOfferte)];
    if (this.route.snapshot.data.dataPartner.body && this.route.snapshot.data.dataPartner.body.data) {
      this.elencoTutteOfferte = this.routerData.elencoTutteOfferte;
      this.listaMesi = this.routerData.listaMesi;
      this.destinazioneInOfferte = this.routerData.destinazioneInOfferte;
      this.titleImageUrl = this.routerData.urlIconaSelezione;
      this.titleImageUrl = this.titleImageUrl ? this.titleImageUrl : this.routerData.urlFotoDestinazione;
      this.title = this.routerData.titoloSelezione;
      this.titleDescription = this.routerData.descrizioneSelezione;

      this.ricercaItalia = this.destinazioneInOfferte[0].isItalia;
    }
    this.routeParams = this.route.snapshot.url;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {

      if (e instanceof NavigationEnd) {
        if (this.route.snapshot.data.dataPartner.body.data.nomeDestinazione) {
          this.title = this.titleDestinazioniTop + " " + this.route.snapshot.data.dataPartner.body.data.nomeDestinazione;
        }
        this.titleService.setTitle(this.title.toUpperCase());
        this.metaTagService.updateTag(
          {name: 'title', content: this.title.toUpperCase()},
        );
        this.metaTagService.updateTag(
          {name: 'description', content: this.titleDescription || this.titleDestinazioniTop},
        );

        this.initComponent();
      }
    });
  }


  ngOnInit() {
    this.filterForm = new FormGroup({
      data: new FormControl('', Validators.required),
      transportMode: new FormControl({value: '', disabled: true}, Validators.required)
    });
    this.filterForm.get('transportMode').valueChanges.subscribe((val) => {
      this.disableFilterButton = !(val && val.length);
    })
  }

  private initComponent() {
    this.loadRouterParams();
    this.loadDestinazione();
    this.searchEngineDestinations = [{
      idDestinazione: this.routerParams.idDestinazione,
      idSelezione: this.routerParams.idSelezione,
      nomeDestinazione: this.filterDestinations.length > 1 ? this.routerData.nomeDestinazione :
        this.filterDestinations[0].nomeDestinazione,
      isNazione: this.routerParams.infoN == 'n'
    }];
  }


  private makeObjOfferta(offerta, filtered?) {
    let infoOfferta = {
      destinazione: offerta.destinazione,
      idOfferta: offerta.idOfferta,
      idTo: offerta.idTo,
      idStruttura: offerta.idStruttura,
      nomeStruttura: offerta.nomeStruttura,
      titolo: offerta.titolo
    };

    let images = ImageUtilService.supportWebP() ? ImageUtilService.ordinaFotoArray(offerta.fotosWebp) : ImageUtilService.ordinaFotoArray(offerta.fotos);
    let listaPartenze = [];

    offerta.partenzeOfferta.forEach(partenza => {
      let infoPartenza = {
        costoTotaleMinimo: partenza.costoTotaleMinimo,
        al: partenza.al,
        dal: partenza.dal,
        giorniNotti: [partenza.giorni + "/" + partenza.notti],
        idLuogoPartenza: partenza.idLuogoPartenza,
        idOfferta: partenza.idOfferta,
        luogoPartenza: partenza.luogoPartenza,
        trattamento: partenza.trattamento,
        url: ""
      };

      let offertaWithUrl = listaPartenze.find(e => e.luogoPartenza === infoPartenza.luogoPartenza);
      if(offertaWithUrl && offertaWithUrl.costoTotaleMinimo < infoPartenza.costoTotaleMinimo){
        listaPartenze.splice(listaPartenze.indexOf(offertaWithUrl), 1);
        infoPartenza.giorniNotti = infoPartenza.giorniNotti.concat(offertaWithUrl.giorniNotti);

        infoPartenza.giorniNotti.sort((a, b) => {
          let aSplit = a.split("/");
          let bSplit = b.split("/");
          return parseInt(aSplit[0]) - parseInt(bSplit[0]);
        });

        if(offertaWithUrl.dal < infoPartenza.dal){
          infoPartenza.dal = offertaWithUrl.dal;
        }

        if(offertaWithUrl.al > infoPartenza.al){
          infoPartenza.al = offertaWithUrl.al;
        }

        infoPartenza.costoTotaleMinimo = offertaWithUrl.costoTotaleMinimo;
      }

      if (filtered) {
        let aeroportoValue = this.filterForm.get('transportMode').value;
        let dateValue = this.filterForm.get('data').value.split('-');
        let aeroportiValue = aeroportoValue.filter((val) => {
          return val.name.toUpperCase() == partenza.luogoPartenza
        });
        if (dateValue[0] == moment(partenza.dal).format('MM')
          && dateValue[1] == moment(partenza.dal).format('YYYY')
          && aeroportiValue.length) {
          infoPartenza.url = this.makePartenzaUrl(infoOfferta, infoPartenza);
          listaPartenze.push(infoPartenza);
        }
      } else {
        infoPartenza.url = this.makePartenzaUrl(infoOfferta, infoPartenza);
        listaPartenze.push(infoPartenza);
      }
    });
    if (listaPartenze.length) {
      listaPartenze.sort((a, b) => {
        if (a.costoTotaleMin === b.costoTotaleMin) {
          return 0;
        } else {
          return a.costoTotaleMin < b.costoTotaleMin ? -1 : 1;
        }
      });

      listaPartenze.forEach((currPartenza, index) => {
        currPartenza.giorniNotti = currPartenza.giorniNotti.join(" - ");
      });

      let tabellaPartenze = new MatTableDataSource(listaPartenze);

      return {
        infoOfferta,
        images,
        tabellaPartenze
      };
    }
  }


  private loadDestinazione(filtered?) {
    this.objDataSource = [];

    this.elencoTutteOfferte.forEach(offerta => {
      this.objDataSource.push(this.makeObjOfferta(offerta, filtered));
    });

    if (this.destinazioneInOfferte) {
      this.destinations = this.destinazioneInOfferte.map(function (val) {
        return val.idDestinazione;
      }).join(',');
    }
    if (filtered) {
      setTimeout(() => {
        this.loadingValues = false;
      }, 2000);
    }
  }

  private loadRouterParams() {
    this.routerParams = this.route.snapshot.params;
    if (this.route.snapshot.data.dataPartner.body && this.route.snapshot.data.dataPartner.body.data) {
      this.elencoTutteOfferte = this.route.snapshot.data.dataPartner.body.data.elencoTutteOfferte;
    }
    this.filterDestinations = [...new Set(this.route.snapshot.data.dataPartner.body.data.destinazioneInOfferte)];
  }

  private makePartenzaUrl(infoOfferta, infoPartenza) {
    let url = "";
    if (infoOfferta.idStruttura == 0) { //no structures
      url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
      url += infoOfferta.destinazione + "/";
      url += "g" + "/";
      url += infoOfferta.idOfferta + "/";
      url += infoPartenza.idLuogoPartenza;
    } else { //url with structure
      url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
      url += infoOfferta.destinazione + "/";
      url += infoOfferta.idStruttura + "/";
      url += "g" + "/";
      url += infoOfferta.idOfferta + "/";
      url += infoPartenza.idLuogoPartenza;
    }

    return url.toLowerCase();
  }

  private makePartenzaUrlFiltered(dataPartenza) {
    let ret = "";
    //VACANZA
    ret = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    ret += dataPartenza.nazione + "/";
    ret += dataPartenza.destinazione + "/";
    ret += dataPartenza.struttura + "/";
    ret += dataPartenza.luogoPartenza + "/";
    ret += dataPartenza.dataPartenza + "/";
    ret += "p" + "/";
    ret += dataPartenza.idPartenza;

    return ret.toLowerCase().replace(/\s/g, "-");
  }

  public makeFilterUrlDestinations(data) {
    let destinazioniTop = this.routerUrls[0].path == 'destinazioni-top';
    let title = destinazioniTop ? 'destinazioni-top' :
      this.routerParams["titolo-macro"] || this.routerParams["nomeDestinazione"];
    let url = "/";
    url += title.toUpperCase() + "/";
    url += this.routerParams.idSelezione + "/";
    url += destinazioniTop ? "" : "macro/";
    url += this.routerParams.nomeDestinazione + "/";
    url += data.idDestinazione;
    url += destinazioniTop ? "/d/dettaglio" : "";
    url += data.nomeDestinazione ? "/" + data.nomeDestinazione.replace(/\s/g, "-") : "";
    return url.toLowerCase();
  }

  public getAirports() {
    let monthYear = this.filterForm.controls['data'].value;
    let monthYearArray = monthYear.split("-");
    let year = monthYearArray[1];
    let month = monthYearArray[0];
    let destinations = "d" + this.filterDestinations.map((val) => {
      return val.idDestinazione
    }).toString().replace(/,/g, ",d");

    let params = {
      "anno": year,
      "idSelezione": this.routerParams.idSelezione,
      "destinazioni": destinations,
      "mese": month,
      "tipo": this.routerData.tipo
    };
    this.macroDestSrv.getAeroportiNostraSelezione(params).then(
      (response) => {
        this.resetSelectFields();
        if (response && response.body.status === 0) {
          this.luoghiPartenzaDisponibili = response.body.data.listaAeroporti;
          this.buildMSelectOptions(this.luoghiPartenzaDisponibili);
          this.filterForm.get('transportMode').enable();
        }
      },
      (error) => {
        console.error("Errore in caricamento dati", error);
      }
    );
  }

  private resetSelectFields() {
    this.optionsForMSelect = [];
    this.selectedOptions = [];
    this.selectedOptionsView = [];

    this.filterForm.get('transportMode').setValue('');
    this.filterForm.get('transportMode').disable();
  }

  private buildMSelectOptions(data) {
    let optionsArray = [];
    data.filter(d => d.isAeroporto == true).map(function (val) { //build mat icon string for airport options
      let optionsForSelect = <DepartureInfo>{
        name: val.nome,
        type: 'flight',
        id: val.idLuogoPartenza,
        isAeroporto: val.isAeroporto
      };
      optionsArray.push(optionsForSelect);
    });

    data.filter(d => d.isBus == true).map(function (val) { //build mat icon string for bus options
      let optionsForSelect = <DepartureInfo>{
        name: val.nome,
        type: 'directions_bus',
        id: val.idLuogoPartenza,
        isBus: val.isBus
      };
      optionsArray.push(optionsForSelect);
    });

    data.filter(d => d.isPorto == true).map(function (val) { //build mat icon string for port options
      let optionsForSelect = <DepartureInfo>{
        name: val.nome,
        type: 'directions_boat',
        id: val.idLuogoPartenza,
        isPorto: val.isPorto
      };
      optionsArray.push(optionsForSelect);
    });

    data.filter(d => d.isSoloSoggiorno == true).map(function (val) { //build mat icon string for own vehicle options
      let optionsForSelect = <DepartureInfo>{
        name: val.nome,
        type: 'directions_car',
        id: val.idLuogoPartenza,
        isSoloSoggiorno: val.isSoloSoggiorno
      };
      optionsArray.push(optionsForSelect);
    });

    this.optionsForMSelect = optionsArray;
  }

  public setSelectTextbox() {
    if (this.selectedOptions) {
      this.selectedOptionsView = [this.selectedOptions['name']];
    }
  }


  public filter() {
    this.loadFilteredDestinations();
  }

  public loadFilteredDestinations() {
    this.loadingValues = true;
    let aeroportoValue = this.filterForm.get('transportMode').value;
    let dateValue = this.filterForm.get('data').value.split('-');

    let destinationValues = this.filterDestinations.map((val) => {
      return {idDestinazione: val.idDestinazione, isNazione: false};
    });
    let dataParam = {
      anno: dateValue[1],
      idSelezione: this.routerParams.idSelezione,
      listaDestinazioni: destinationValues,
      listaLuoghiPartenza: [aeroportoValue.id],
      mese: dateValue[0],
      tipologia: this.routerData.tipo
    };
    this.macroDestSrv.searchPartenzeNostreSelezioni(dataParam).then(
      (resolve) => {
        let tableData = resolve.body.data;
        let listPartenzeOffertaWithUrls = [];

        if (tableData) {
          tableData.forEach((strutturaData) => {
            listPartenzeOffertaWithUrls = [];
            strutturaData.titolo = strutturaData.listaPartenze.length > 0 ? strutturaData.listaPartenze[0].titolo : strutturaData.struttura;
            strutturaData.listaPartenze.forEach((partenza) => {
              partenza['url'] = this.makePartenzaUrlFiltered(partenza);
              listPartenzeOffertaWithUrls.push(partenza)
            });
            strutturaData.listaPartenze = new MatTableDataSource(listPartenzeOffertaWithUrls);
          })
        }
        this.dataTable = tableData;
        this.filteredValues = true;

      }
    ).finally(
      () => {
        this.loadingValues = false;
      }
    )
  }

  private loadMoreOfferte() {
    this.loadingValues = true;
    let dataParam = {
      "idDestinazione": this.routerParams.idDestinazione,
      "idLuogo": this.routerParams.fDataType == "lp" ? this.routerParams.fDataId : 0,
      "isNazione": this.routerParams.infoN == "n",
      "isItalia": this.routerParams.isItalia == "i",
      "mese": this.routerParams.fDataType == "m" ? this.routerParams.fDataId : 0,
      "pageNumber": this.currentPage,
      "pageSize": 10
    };
    if (this.route.routeConfig.path && this.route.routeConfig.path.indexOf(SEO_PAGE.DESTINAZIONE_TOP_KEYWORD) !== -1) {
      dataParam["idDestinazioneTop"] = this.routerParams.idSelezione;
    }
    this.cDestSrv.getOfferteDestinazione(dataParam).then(
      (dataResp) => {
        if (dataResp.body && dataResp.body.data) {
          dataResp.body.data.elencoTutteOfferte.map(
            (currOfferta) => {
              this.objDataSource.push(this.makeObjOfferta(currOfferta));
            }
          );
        }
      }
    ).finally(
      () => {
        this.loadingValues = false;
      }
    )
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
