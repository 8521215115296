import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SuggerimentiService} from "../../services/gate/suggerimenti.service";
import {Subject} from "rxjs";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {ModalService} from "../../services/common/modal.service";

@Component({
  selector: 'borsaviaggi-suggerimenti',
  templateUrl: './suggerimenti.component.html',
  styleUrls: ['./suggerimenti.component.scss']
})
export class SuggerimentiComponent implements OnInit {

  public qualitaPrezzo: number;
  public consulenti: number;
  public grafica: number;
  public facilitaConsultazione: number;
  public velocitaCaricamento: number;
  public formGroup: FormGroup;
  public loading;
  resetRating: Subject<any> = new Subject();

  constructor(private suggerimentiService: SuggerimentiService,
              private titleService: Title,
              private modalSrv: ModalService,
              public route: ActivatedRoute,
              private metaTagService: Meta) {
    this.route.data.subscribe(data => {
      this.titleService.setTitle(data.seo_title);
      this.metaTagService.updateTag(
        {name: 'description', content: data.seo_description},
      );
    });
  }

  ngOnInit() {


    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.email),
      messaggio: new FormControl('')
    });
    this.qualitaPrezzo = 0;
    this.grafica = 0;
    this.facilitaConsultazione = 0;
    this.velocitaCaricamento = 0;
    this.consulenti = 0;
  }

  cambioPunteggioQualitaPrezzo(event: number) {
    this.qualitaPrezzo = event;
  }

  cambioPunteggioConsulenti(event: number) {
    this.consulenti = event;
  }

  cambioPunteggioGrafica(event: number) {
    this.grafica = event;
  }

  cambioPunteggioConsultazione(event: number) {
    this.facilitaConsultazione = event;
  }

  cambioPunteggioCaricamento(event: number) {
    this.velocitaCaricamento = event;
  }

  send(formDataVaild) {
    if (formDataVaild) {
      let obj = {
        "qualitaPrezzo": this.qualitaPrezzo,
        "consulenti": this.consulenti,
        "grafica": this.grafica,
        "facilitaConsultazione": this.facilitaConsultazione,
        "velocitaCaricamento": this.velocitaCaricamento,
        "messaggio": this.formGroup.get("messaggio").value,
        "email": this.formGroup.get("email").value
      };

      this.suggerimentiService.sendRating(obj).then(
        (response) => {
          let mess = response.body.status === 0 ? "Valutazioni inviate con successo" : response.body.message;
          this.modalSrv.openFeedbackModal({success: mess});
        },
        (error) => {
          this.modalSrv.openErrorDialog();
        }
      ).finally(
        () => {
          this.formGroup.reset();
          this.formGroup.markAsPristine();
          this.formGroup.markAsUntouched();
          this.qualitaPrezzo = 0;
          this.facilitaConsultazione = 0;
          this.grafica = 0;
          this.velocitaCaricamento = 0;
          this.consulenti = 0;
          this.loading = false;
          this.resetRating.next(0);
        }
      );
    }

  }


}
