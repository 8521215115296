import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {CartaRegaloService} from "../carta-regalo.service";

@Injectable({
  providedIn: 'root'
})
export class CartaRegaloResolveService {
  constructor(private regaloSrv: CartaRegaloService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    return this.regaloSrv.getGiftCard()
      .catch(err => {
        return err;
      });
  }
}
