import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {RicercaCrociereService} from '../ricerca-crociere.service';

@Injectable({
  providedIn: 'root'
})
export class RicercaCrociereResolveService {
  constructor(private ricercaCrociereSrv: RicercaCrociereService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let req = routeSnap.queryParams;
    let reqObj = {
      data: req && req.data || null,
      idPorto: req && req.idPorto || null,
      idCompagnia: req && req.idCompagnia || null,
      idDestinazione: req && req.idDestinazione || null
    };

    return this.ricercaCrociereSrv.ricercaCrociere(reqObj)
      .catch(err => {
        return err;
      });
  }

}
