<borsaviaggi-loader [show]="showLoader" typeMode='fullScreen'></borsaviaggi-loader>
<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center background-white">
  <h1 class="mb-1rem color-text-blue-borsaviaggi">Cosa dicono di noi i nostri clienti</h1>
  <div class="row justify-content-around" *ngIf="votiPiattaforme">
    <div class="col-md-4 indice-container">
      <h2 class="mt-10px">Indice di Soddisfazione</h2>
      <div class="indice-circle">
        <div class="indice">{{votiPiattaforme.percentualeMediaTotale}}%</div>
      </div>
      <p class="mt-10px fs-1_2rem">Sulla base di {{votiPiattaforme.recensioniTotali}} recensioni
        su {{votiPiattaforme.numeroPortali}} portali</p>
    </div>
    <div class="col-md-5 Valutazione Media mt-15px">
      <h2>Valutazione Media</h2>
      <div *ngFor="let portali of votiPiattaforme.portali | keyvalue" class="row">
        <div class="col text-align-left fs-1_1rem">{{portali.key}}</div>
        <div class="col text-align-right fs-1_1rem">{{portali.value}}%</div>
      </div>
    </div>
  </div>
  <div class="mt-1rem">
    <div class="row">
      <mat-form-field class="col col-md-3">
        <mat-label>Ordina per:</mat-label>
        <mat-select [(ngModel)]="selectedValue" (selectionChange)="orderPageData(selectedValue, paginator)">
          <mat-option value="recente">Data più recente</mat-option>
          <mat-option value="meno-recente">Data meno recente</mat-option>
          <mat-option value="positivo">Valutazioni positive per prime</mat-option>
          <mat-option value="negativo">Valutazioni negative per prime</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-paginator class="col"
                     [length]="length"
                     #paginator
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     (page)="setPageData($event)">
      </mat-paginator>
    </div>
  </div>

  <div *ngFor="let pageValues of pageData">
    <borsaviaggi-recensioni-card [recensioniData]="pageValues"></borsaviaggi-recensioni-card>
  </div>
</div>
