import {Pipe, PipeTransform} from "@angular/core";

/**
 * @Pipe({name: 'status'})
 * Sorts an array.
 *
 */

@Pipe({name: "euroFormat"})
export class EuroFormatterPipe implements PipeTransform {
  transform(importo: string | number): string {
    importo = parseInt(<string>importo);
    return new Intl.NumberFormat().format(importo);
  }
}
