import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorModalDto} from "../../../model/errorModal.dto";
import {ModalInterface} from "../../../interface/modal.interface";

@Component({
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  public errorData = new ErrorModalDto();

  constructor(public dialogRef: MatDialogRef<ErrorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<ErrorModalComponent>) {

    if (Object.keys(data).length) {
      for (let key in data) {
        this.errorData[key] = data[key] || this.errorData[key];
      }
    }
  }

  ngOnInit() {
  }

}
