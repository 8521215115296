import {Component, HostListener, OnInit} from '@angular/core';
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'borsaviaggi-qui-dietro',
  templateUrl: './qui-dietro.component.html',
  styleUrls: ['./qui-dietro.component.scss']
})

export class QuiDietroComponent implements OnInit {
  public isMobileDevice: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(private deviceService: DeviceDetectorService) {
    this.isMobileDevice = this.deviceService.isMobile();
  }

  ngOnInit() {
  }

}
