import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderByPipe'
})
export class OrderByPipe implements PipeTransform {

  transform(array: Array<string>, args: string, order?: string): Array<string> {

    if (!array || !array.length || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      let tempA = args ? args.indexOf(".") !== -1 ? this.getNestedValue(a, args) : a[args] : a;
      let tempB = args ? args.indexOf(".") !== -1 ? this.getNestedValue(b, args) : b[args] : b;
      tempA = tempA && tempA.toLowerCase ? tempA.toLowerCase() : tempA;
      tempB = tempB && tempB.toLowerCase ? tempB.toLowerCase() : tempB;
      if (tempA < tempB) {
        if (order && order === 'desc') {
          return 1;
        } else {
          return -1;
        }
      } else if (tempA > tempB) {
        if (order && order === 'desc') {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 0;
      }

    });
    return array;
  }

  private getNestedValue(obj, key) {
    let ret = key.split(".").reduce(function (result, key) {
      return result ? result[key] : null;
    }, obj);
    return ret || obj;
  }
}
