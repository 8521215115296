<div class="modal-wrapper">
  <div mat-dialog-close class="float-right">
   <!-- <i class="material-icons">close</i>-->
  </div>
  <mat-dialog-content align="center">
    <div>
      <h2>La partenza che hai selezionato è in<br>fase di riprogrammazione.</h2>
      <img class="w-300" src="../../../../assets/img/page-not-found/not-available.svg" alt="non disponibile">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="blue-button" mat-button (click)="navigateG()">
      <span>Guarda altre partenze</span>
    </button>
  </mat-dialog-actions>
  <mat-dialog-content align="center">
    <div class="mt-30px">
      <h3>Se necessiti di assistenza o vuoi un preventivo rapido su questa struttura, contatta i nostri consulenti in chat o chiamaci allo 06.5587667.</h3>
    </div>
  </mat-dialog-content>
</div>
