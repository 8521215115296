import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {
  public shareButton;
  @Input() title;
  @Input() idPartenza;
  public shareLink: any;

  constructor() {
  }

  ngOnInit() {
    this.shareButton = document.querySelectorAll("button.shareButton");
    this.shareLink = encodeURIComponent(window.location.toString())
  }


  public clickEvent() {
    for (let i = 0; i < this.shareButton.length; i++) {
      this.shareButton[i].classList.toggle("open");
      this.shareButton[0].classList.remove("sent");
    }
  }

}

