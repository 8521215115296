import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-readmore',
  templateUrl: './readmore.component.html',
  styleUrls: ['./readmore.component.scss']
})
export class ReadmoreComponent implements OnInit {
  @Input() content;
  public hideDescription = true;
  

  constructor() {

  }

  ngOnInit() {
  }

  public readMore() {
    this.hideDescription = false;
  }

  public readLess(targetView?) {
    this.hideDescription = true;
    if(targetView) targetView.scrollIntoView();
  }

}
