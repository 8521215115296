import {Component, OnInit} from '@angular/core';
import {UtilsStringService} from "../../../services/common/utils-string.service";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, Router} from "@angular/router";
import {DettaglioZService} from "../../../services/gate/dettaglio-z.service";
import {LoaderService} from "../../../components/loader/loader.service";
import {NgxGalleryOptions} from "../../../components/gallery/ngx-gallery-options.model";
import {NgxGalleryImage} from "../../../components/gallery/ngx-gallery-image.model";
import {ImageUtilService} from "../../../services/common/image.util.service";
import {Meta, Title} from "@angular/platform-browser";
import {SEO_OFFERTE} from "../../../constants/seo.const";

@Component({
  templateUrl: './dettaglio-struttura-page.component.html',
  styleUrls: ['./dettaglio-struttura-page.component.scss']
})
export class DettaglioStrutturaPage implements OnInit {
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public utilsStr: UtilsStringService;
  public displayedColumns: string[] = [
    'partenzaDa',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'prime',
    'prezzo',
    'actions'
  ];
  public dataResponse;
  public photoNumber: number;
  public photos: any;
  public listaPartenzeOfferta: any;
  public configObj: any;
  public listPartenzeOffertaWithUrls: any;
  public dettaglioStruttura: any;
  public x = 0;
  public strutturaData;
  public dataStruttura;
  public dataDettaglioZ;
  public showErrorDettaglioZ;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private strutturaSrv: DettaglioZService,
    private loaderSrv: LoaderService) {
    this.loaderSrv.show();
    this.dataStruttura = this.route.snapshot.data.dataStrutturaPartner;
    this.dataDettaglioZ = this.route.snapshot.data.dataPartner;
    let pageData = this.route.snapshot.data.dataStrutturaPartner || this.route.snapshot.data.dataPartner;
    if (this.dataDettaglioZ) {
      this.strutturaSrv.getOfferteDestinazioni(this.dataDettaglioZ.body.data.idDestinazione).then(
        (result) => {
          this.listaPartenzeOfferta = result.body.data.listaDestinazioni;
          this.getOfferteDestinazioni();
          this.showErrorDettaglioZ = !(result.body.data.listaDestinazioni || result.body.data.listaDestinazioni.length);
        }
      ).finally(() => {
        this.loaderSrv.hide();
      });

    }
    if (pageData.body && pageData.body.data) {
      this.dettaglioStruttura = this.dataResponse = this.dataStruttura ?
        this.dataStruttura.body.data : this.dataDettaglioZ.body.data;

      if (this.dataStruttura) {
        this.listaPartenzeOfferta = this.dataStruttura.body.data.listaOfferteStruttura;
        this.getOfferteDestinazioni();
      }

      this.galleryImages = [];
      this.photoNumber = 0;
      this.setGalleryOptions();
      this.populateGallery(this.dettaglioStruttura.listaStrutturaFoto || this.dettaglioStruttura.listaImmagini);
      this.photos = this.dettaglioStruttura.listaStrutturaFoto || this.dettaglioStruttura.listaImmagini;
      this.utilsStr = UtilsStringService;
      this.listPartenzeOffertaWithUrls = [];
    }
    this.getStrutturaData();
  }

  ngOnInit(): void {
    let titolo = this.dettaglioStruttura.nome + " " + this.dettaglioStruttura.nomeLocalitaPadre + " " + this.dettaglioStruttura.nomeLocalita;
    this.titleService.setTitle(titolo);
    this.metaTagService.updateTag(
      {name: 'title', content: titolo},
    );
    this.metaTagService.updateTag(
      {property: 'og:title', content: titolo},
    );
    let urlPhoto = window.location.origin + "/assets/img/logo-borsaviaggi.png";
    if (this.dettaglioStruttura.listaStrutturaFoto && this.dettaglioStruttura.listaStrutturaFoto.length > 0) {
      urlPhoto = <string>this.dettaglioStruttura.listaStrutturaFoto[0].urlFoto
    }
    this.metaTagService.updateTag(
      {property: 'og:image', content: urlPhoto}
    );
    let desc = this.dettaglioStruttura.descrizione || this.dettaglioStruttura.lblTesto;
    if (desc) {
      this.metaTagService.updateTag(
        {property: 'og:description', content: desc.replace(/<[^>]+>/g, '').substring(0, 158)},
      );
      this.metaTagService.updateTag(
        {name: 'description', content: desc.replace(/<[^>]+>/g, '').substring(0, 158)},
      );
    }

  }

  private makePartenzaUrl(g) {
    let url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    url += g.nazione + "/";
    url += g.destinazione + "/";
    url += UtilsStringService.removeSpecialChar(g.struttura) + "/";
    url += "g" + "/";
    url += g.idOfferta + '/';
    url += g.idLuogoPartenza + '';
    return url.toLowerCase().replace(/\s/g, "-");
  }

  private setGalleryOptions() {
    this.galleryOptions = [
      {
        "imageAutoPlay": true,
        "previewCloseOnEsc": true,
        "previewZoom": true,
        "imageAutoPlayPauseOnHover": true,
        "previewAutoPlay": true,
        "previewAutoPlayPauseOnHover": true,
        "previewFullscreen": true,
        "previewForceFullscreen": true,
        "image": false,
        thumbnails: true,
        preview: true,
        width: '100%',
        height: '26vh',
        imageInfinityMove: true
      },
      {
        "breakpoint": 768,
        "width": "100%",
        "thumbnailsColumns": 1,
        imageSwipe: true,
        thumbnailsSwipe: true,
        previewCloseOnClick: true,
        "thumbnailsRemainingCount": false,
        "image": false
      },
      {"breakpoint": 1200, "width": "100%", "height": "200px", "thumbnailsColumns": 3},
    ]
  }

  private getOfferteDestinazioni() {
    this.listaPartenzeOfferta.forEach(offerta => {
      this.listPartenzeOffertaWithUrls = [];

      offerta.listaPartenze.forEach((partenza) => {
        let partenzaObj = {
          nazione: this.dettaglioStruttura.nomeLocalitaPadre || this.dettaglioStruttura.nazione,
          destinazione: this.dettaglioStruttura.nomeLocalita || offerta.nomeDestinazione,
          struttura: this.dettaglioStruttura.nome || offerta.nomeStruttura,
          luogoPartenza: partenza.luogoPartenza,
          idLuogoPartenza: partenza.idLuogoPartenza,
          dataPartenza: partenza.dal,
          dataPartenzadal: partenza.dal,
          dataPartenzaal: partenza.al,
          idOfferta: partenza.idOfferta,
          costoTotaleMin: partenza.costoTotaleMin || partenza.costoTotale,
          trattamento: partenza.trattamento,
          isPartenzaPrime: partenza.isPartenzaPrime,
          giorniNotti: [partenza.giorni + "/" + partenza.notti],
          url: ""
        };
        partenzaObj.url = this.makePartenzaUrl(partenzaObj);
        let offertaWithUrl = this.listPartenzeOffertaWithUrls.find(e => e.luogoPartenza === partenzaObj.luogoPartenza);
        if(offertaWithUrl && offertaWithUrl.costoTotaleMin < partenzaObj.costoTotaleMin){
          this.listPartenzeOffertaWithUrls.splice(this.listPartenzeOffertaWithUrls.indexOf(offertaWithUrl), 1);
          partenzaObj.giorniNotti = partenzaObj.giorniNotti.concat(offertaWithUrl.giorniNotti);

          partenzaObj.giorniNotti.sort((a, b) => {
            let aSplit = a.split("/");
            let bSplit = b.split("/");
            return parseInt(aSplit[0]) - parseInt(bSplit[0]);
          });

          if(offertaWithUrl.dataPartenzadal < partenzaObj.dataPartenzadal){
            partenzaObj.dataPartenzadal = offertaWithUrl.dataPartenzadal;
          }

          if(offertaWithUrl.dataPartenzaal > partenzaObj.dataPartenzaal){
            partenzaObj.dataPartenzaal = offertaWithUrl.dataPartenzaal;
          }

          partenzaObj.costoTotaleMin = offertaWithUrl.costoTotaleMin;
        }
        this.listPartenzeOffertaWithUrls.push(partenzaObj);
      });
      let noPrice = offerta.listaPartenze.every(currP => !currP.costoTotaleMin);
      let noPrime = offerta.listaPartenze.every(currP => !currP.isPartenzaPrime);
      if (noPrice) {
        this.displayedColumns.map((currHader, index) => {
          if (currHader === "prezzo") {
            this.displayedColumns.splice(index, 1);
          }
        });
      }
      if (noPrime) {
        this.displayedColumns.map((currHader, index) => {
          if (currHader === "prime") {
            this.displayedColumns.splice(index, 1);
          }
        });
      }

      this.listPartenzeOffertaWithUrls.sort((a, b) => {
        if (a.costoTotaleMin === b.costoTotaleMin) {
          return 0;
        } else {
          return a.costoTotaleMin < b.costoTotaleMin ? -1 : 1;
        }
      });

      this.listPartenzeOffertaWithUrls.forEach((currPartenza, index) => {
        currPartenza.giorniNotti = currPartenza.giorniNotti.join(" - ");
      });

      offerta.listaPartenze = new MatTableDataSource(this.listPartenzeOffertaWithUrls);

    });
  }

  private populateGallery(listaFoto) {
    listaFoto.map((currFoto) => {
      this.galleryImages.push(
        {
          small: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
          medium: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
          big: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
          description: currFoto.nomeFoto
        }
      );
    });
  }

  public getStrutturaData() {
    let resolveInfo = this.dataDettaglioZ || this.dataStruttura;
    let dataStruttura = resolveInfo.body.data;
    this.strutturaData = {
      'nome': dataStruttura.lblTitolo || dataStruttura.nomeDestinazione || dataStruttura.nome,
      'nomeLocalita': dataStruttura.nomeLocalita || dataStruttura.nomeDestinazione,
      'nomeLocalitaPadre': dataStruttura.nazione || dataStruttura.nomeLocalitaPadre,
      'sottotitolo': dataStruttura.lblSottoTitolo,
      'descrizione': dataStruttura.descrizione || (dataStruttura.lblSottoTitolo + '<br><br>' + dataStruttura.lblTesto),
    }
  }


}
