<div class="header-modal">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <!--  <mat-icon matPrefix>error</mat-icon>-->
  <h2 mat-dialog-title>{{errorData.title}}</h2>
</div>
<mat-dialog-content>
  <span>{{errorData.body_message}}</span>
</mat-dialog-content>
