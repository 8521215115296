import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit, QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {SEO_PAGE} from "../../constants/seo.const";
import { MatCarousel, MatCarouselSlide } from "@ngmodule/material-carousel";

@Component({
  selector: 'borsaviaggi-carosello',
  templateUrl: './carosello.component.html',
  styleUrls: ['./carosello.component.scss']
})
export class CaroselloComponent implements OnInit, OnChanges {
  @Input() listPartner;
  public seoUrl: string;
  public dynamicStyle: any;
  private counter: number;
  private mouseDownTriggered = false;
  private startX = 0;
  private startX1 = 0;
  private sliderContainer: any;
  private innerSlider: any;
  private innerSlider1: any;
  private outerContainer: any;

  constructor() {
    this.counter = 0;
    this.seoUrl = SEO_PAGE.DETTAGLIO_PARTNER;
  }

  ngOnInit() {
    this.sliderContainer = document.querySelector(".carousel");
    this.outerContainer = this.sliderContainer.getBoundingClientRect();
    this.startAnimation();

  }

  isTouchDevice() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }
  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event']) onGrab(event) {
    if(event.which !== 1 && event.which !== 0) return;
    this.innerSlider = document.querySelector(".car-1");
    this.innerSlider1 = document.querySelector(".car-2");
    this.mouseDownTriggered = true;
    this.startX =
      (!this.isTouchDevice()
        ? event.clientX
        : event.touches[0].clientX - this.outerContainer.left) - this.innerSlider.offsetLeft;

    this.startX1 =
      (!this.isTouchDevice()
        ? event.clientX
        : event.touches[0].clientX - this.outerContainer.left) - this.innerSlider1.offsetLeft;

    this.sliderContainer.style.cursor = "grabbing";
  }
  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event']) onRelease(event) {
    this.mouseDownTriggered = false;
    this.sliderContainer.style.cursor = "grab";
  }
  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event']) onDrag(event) {
    if (this.mouseDownTriggered) {
      event.preventDefault();
      const x =
        (!this.isTouchDevice()
          ? event.clientX
          : event.touches[0].clientX - this.outerContainer.left);
      this.innerSlider.style.left = `${x - this.startX}px`;
      this.innerSlider1.style.left = `${x - this.startX}px`;
    }
  }

  private startAnimation() {
    if (this.listPartner) {
      this.dynamicStyle = {'width': this.listPartner.length * 100 + "px"};
    }
  }

  public makeUrlSeoHref(partner) {
    let ret = SEO_PAGE.DETTAGLIO_PARTNER + "/";
    ret += partner.nomeTourOperator + "/";
    ret += partner.title + "/";
    ret += partner.idPartner + "/";
    return ret.toLowerCase().replace(/\s/g, "-");

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listPartner && changes.listPartner.currentValue
      && changes.listPartner.currentValue.length > 0 && !changes.listPartner.firstChange) {
      this.startAnimation();
    }
  }
}
