import {Pipe, PipeTransform} from "@angular/core";

/**
 * @Pipe({name: 'status'})
 * Sorts an array.
 *
 */

@Pipe({name: "somma"})
export class SommaPipe implements PipeTransform {
  transform(array: any[], field: string): number {
    let ret = 0;
    if (array && Array.isArray(array) && array.length > 0) {
      ret = array.reduce((accumulator, currentValue) => {
        return accumulator + currentValue[field]
      }, 0);
    }
    return ret;
  }
}
