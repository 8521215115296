import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class RatingService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getRating(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/rating/getVotiPiattaforme");
  }

}
