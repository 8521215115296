import {Component, OnDestroy, OnInit} from '@angular/core';


@Component({
  selector: 'search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})

export class SearchFiltersComponent implements OnInit, OnDestroy {


  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
