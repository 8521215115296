import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ErrorModalDto} from "../../../model/errorModal.dto";
import {ModalInterface} from "../../../interface/modal.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from '../../loader/loader.service';
import {UtilityService} from '../../../services/gate/utility.service';
import {CheckoutValidator} from '../../../services/validator/checkout.validator';
import {DatiPersonaliValidator} from '../../../services/validator/dati-personali.validator';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './modifica-preventivo-crociera-modal.component.html',
  styleUrls: ['./modifica-preventivo-crociera-modal.component.scss']
})
export class ModificaPreventivoCrocieraModalComponent implements OnInit {
  public errorData = new ErrorModalDto();
  public dettaglioData: any;
  public loading: boolean;
  public displayedColumns: string[] = [
    'codicePartenza',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'icons',
    'prime',
    'prezzo',
    'listaDesideri',
    'actions'
  ];
  public dataResponse;
  public dataSource = new MatTableDataSource();
  public photos: any;
  public listaPartenzeOfferta: any;
  public configObj: any;
  public dettaglioOfferta: any;
  public validation_messages: any = DatiPersonaliValidator.formRichiestaInfoErrorMessage;
  public showPreventivo: boolean;
  /** Preventivo Crociera */
  public formPreventivoCrociera: FormGroup = CheckoutValidator.formPreventivoCrociera;
  public listSesso: any;
  public checkoutStep: any;
  public items: any;
  public cabineDaPrenotare: any = null;
  public cabinePrenotabiliArr: any;
  public numBambiniArr: any;
  public numBambiniArrSelectedArr: Array<any> = [];
  public numAdultiArr: any;
  public numAdultiArrSelectedArr: Array<any> = [];
  public etaAdultiArr: any;
  public etaBambiniArr: any;
  public listaRegioni: Array<any>;
  public showFormComune: boolean;
  public showAltrePartenze: boolean;
  public cabine: boolean;
  public showDettagliCrociera: boolean;
  public cabineDaPrenotareNum: any;
  public cabinaPrezzoMinimo;
  public selectedBambini;
  public adultiSelectedNum;

  constructor(public dialogRef: MatDialogRef<ModificaPreventivoCrocieraModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<ModificaPreventivoCrocieraModalComponent>,
              private elementRef: ElementRef,
              public loaderSrv: LoaderService,
              private utilitySrv: UtilityService) {
    this.selectedBambini = [];
    this.adultiSelectedNum = [1, 2];
    if (Object.keys(data).length) {
      for (let key in data) {
        this.errorData[key] = data[key] || this.errorData[key];
      }
    }
    this.dettaglioData = this.data.dataParam;
  }

  ngOnInit() {
    this.checkoutStep = 'riepilogo';
    this.showDettagliCrociera = true;
    this.showFormComune = false;
    this.showAltrePartenze = false;
    this.showPreventivo = false;
    this.listSesso = this.getListSesso();
    this.cabineDaPrenotareNum = 1;
    this.etaAdultiArr = this.getObjectsArray(18, 100);
    this.numAdultiArr = this.getObjectsArray(1, 4);
    this.onChangeCabineDaPrenotare(this.cabineDaPrenotareNum);
    this.cabinePrenotabiliArr = this.getObjectsArray(1, 4);
    this.numBambiniArr = this.getObjectsArray(0, 2);
    this.etaBambiniArr = this.getObjectsArray(0, 17);
    this.items = this.getObjectsArray(1, 4);
    this.getRegioni();
    this.calculateMinCabinPrice();
    this.formPreventivoCrociera.controls['idTipoCabina_1'].setValue(this.cabinaPrezzoMinimo.idCategoria);
  }

  public calculateMinCabinPrice() {
    let cabineArray = this.dettaglioData.dataResponse.cabine;
    let cabinaPrezzoArray = [];
    let prezzoMinimo;
    for (let i = 0; i < cabineArray.length; i++) {
      cabinaPrezzoArray.push(cabineArray[i].prezzo);
    }
    prezzoMinimo = Math.min(...cabinaPrezzoArray);
    for (let i = 0; i < cabineArray.length; i++) {
      if (cabineArray[i].prezzo == prezzoMinimo) {
        this.cabinaPrezzoMinimo = cabineArray[i];
      }
    }
  }

  public onChangeCabineDaPrenotare(cabineDaPrenotareNum) {
    this.cabineDaPrenotare = this.getObjectsArray(1, cabineDaPrenotareNum);
    this.initSelectedAdultiEBambiniArrays(cabineDaPrenotareNum);
    this.selectedBambini = Array(cabineDaPrenotareNum);
    let filler;
    for (let i = 0; i < cabineDaPrenotareNum; i++) {
      this.formPreventivoCrociera.get('idTipoCabina_' + (i + 1)) ?
        filler = '' : this.formPreventivoCrociera.addControl('idTipoCabina_' + (i + 1), new FormControl(this.cabinaPrezzoMinimo ? this.cabinaPrezzoMinimo.idCategoria : '', Validators.required));
      this.formPreventivoCrociera.get('numAdulti_' + (i + 1)) ? filler = '' : this.formPreventivoCrociera.addControl('numAdulti_' + (i + 1), new FormControl(2, Validators.required));
      this.formPreventivoCrociera.get('numBambini_' + (i + 1)) ? filler = '' : this.formPreventivoCrociera.addControl('numBambini_' + (i + 1), new FormControl(0, Validators.required));
      this.formPreventivoCrociera.get('etaBambiniUno_' + (i + 1)) ? filler = '' : this.formPreventivoCrociera.addControl('etaBambiniUno_' + (i + 1), new FormControl(0, Validators.required));
      this.formPreventivoCrociera.get('etaBambiniDue_' + (i + 1)) ? filler = '' : this.formPreventivoCrociera.addControl('etaBambiniDue_' + (i + 1), new FormControl(0, Validators.required));
      for (let j = 1; j <= 4; j++) {
        this.formPreventivoCrociera.get('etaAdulto' + j + '_' + (i + 1)) ? filler = '' : this.formPreventivoCrociera.addControl('etaAdulto' + j + '_' + (i + 1), new FormControl(''));
      }
      this.onChangeNumeroBambini(i);
      this.onChangeNumeroAdulti(i);
    }
  }

  public initSelectedAdultiEBambiniArrays(cabineDaPrenotareNum) {
    this.numAdultiArrSelectedArr = [];
    this.numBambiniArrSelectedArr = [];
    for (let i = 0; i < cabineDaPrenotareNum; i++) {
      this.numAdultiArrSelectedArr.push([]);
      this.numBambiniArrSelectedArr.push([]);
    }
  }

  private getRegioni() {
    this.utilitySrv.getRegioni().then(
      (response) => {
        if (response && response.body.data) {
          this.listaRegioni = response.body.data;
        } else {
          console.log("Errore reperimento regioni")
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }

  public onChangeNumeroBambini(index) {
    this.selectedBambini[index] = this.formPreventivoCrociera.get('numBambini_' + (index + 1)).value;
    if(this.cabineDaPrenotareNum === 1){
      setTimeout(
        () => {
          let scrollOffset = this.elementRef.nativeElement.querySelector('mat-dialog-content').offsetHeight;
          this.elementRef.nativeElement.querySelector('mat-dialog-content').scrollTo(0, scrollOffset);
        }, 0
      );
    }

  }

  public getObjectsArray(first, last) {
    let arr = [];
    for (let i = first; i < last + 1; i++) {
      arr.push({
        value: i,
        viewValue: i + ''
      });
    }
    return arr;
  }

  public getListSesso() {
    return [
      {value: "M", label: "Uomo"},
      {value: "F", label: "Donna"}
    ]
  }

  public salvaModificheAndCloseModal() {
    if (this.formPreventivoCrociera.valid) {
      let datiModal = {
        savePreventivoRequestObj: this.getSavePreventivoRequestObj()
      };
      this.dialogRef.close(datiModal);
    } else {
      this.formPreventivoCrociera.markAllAsTouched();
      this.formPreventivoCrociera.markAsDirty();
    }

  }

  public getSavePreventivoRequestObj() {
    let idVoloRichiesto = this.formPreventivoCrociera.controls['volo'].value + '' === '' ? null : this.formPreventivoCrociera.controls['volo'].value + '';
    return {
      idCrociera: this.dettaglioData.dataResponse.idCrociera + '',
      isVoloIncluso: this.dettaglioData.dataResponse.isVoloIncluso,
      idVoloRichiesto: idVoloRichiesto,
      cabineSelezionate: this.getCabineArr()
    }
  }

  public getCabineArr() {
    let cabineArr = [];
    for (let i = 0; i < this.cabineDaPrenotare.length; i++) {
      let idTipoCabinaKey = "idTipoCabina_" + (i + 1);
      let numAdultiKey = "numAdulti_" + (i + 1);
      let numBambiniKey = "numBambini_" + (i + 1);
      let idTipoCabina = this.formPreventivoCrociera.controls[idTipoCabinaKey].value;
      let numAdulti = this.formPreventivoCrociera.controls[numAdultiKey].value;
      let numBambini = this.formPreventivoCrociera.controls[numBambiniKey].value;
      let etaBambini = [];
      let etaAdulti = [];
      if (this.selectedBambini[i] > 0) {
        etaBambini.push(this.formPreventivoCrociera.get('etaBambiniUno_' + (i + 1)).value);
      }
      if (this.selectedBambini[i] > 1) {
        etaBambini.push(this.formPreventivoCrociera.get('etaBambiniDue_' + (i + 1)).value);
      }
      for (let j = 1; j <= numAdulti; j++) {
        etaAdulti.push(this.formPreventivoCrociera.get('etaAdulto' + j + '_' + (i + 1)).value);
      }
      let idCabina = i + 1;
      let cabina = {
        idCabina: idCabina,
        idTipoCabina: idTipoCabina,
        numAdulti: numAdulti,
        numBambini: numBambini,
        etaBambini: etaBambini,
        etaAdulti: etaAdulti
      };
      cabineArr.push(cabina);
    }
    return cabineArr
  }

  public onChangeNumeroAdulti(index: number) {
    this.adultiSelectedNum = [];
    let numAdultiSelected = this.formPreventivoCrociera.get('numAdulti_' + (index + 1)).value;
    for (let i = 1; i <= 4; i++) {
      if (i > numAdultiSelected && this.formPreventivoCrociera.controls['etaAdulto' + i + '_' + (index + 1)]) {
        this.formPreventivoCrociera.removeControl('etaAdulto' + i + '_' + (index + 1));
      } else if (i <= numAdultiSelected && !this.formPreventivoCrociera.controls['etaAdulto' + i + '_' + (index + 1)]) {
        this.formPreventivoCrociera.addControl('etaAdulto' + i + '_' + (index + 1), new FormControl("", [Validators.required]));
      }
    }
    for (let i = 1; i <= numAdultiSelected; i++) {
      this.adultiSelectedNum.push(i);
    }
  }
}
