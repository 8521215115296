import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";
import {Injectable} from "@angular/core";

@Injectable()
export class SuggerimentiService {

  constructor(private genericHttpService: GenericGateService) {
  }
  public sendRating(param): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/form/suggerimenti", param);
  }
}
