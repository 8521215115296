import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class NavigationService {
  private visibleSubject = new Subject<boolean>();
  private openSubject = new Subject<boolean>();
  private reloadMenuSubject = new Subject<any>();
  public openState = this.openSubject.asObservable();
  public visibleState = this.visibleSubject.asObservable();
  public reloadMenuObservr= this.reloadMenuSubject.asObservable();

  constructor() {

  }

  public open() {
    this.openSubject.next(true);
  }

  public close() {
    this.openSubject.next(false);
  }

  public show() {
    this.visibleSubject.next(true);
  }

  public hide() {
    this.visibleSubject.next(false);
  }

  public reloadMenu() {
    this.reloadMenuSubject.next(false);
  }

}
