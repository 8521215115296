<div class="home-page" [ngClass]="{'webP':webPSupported}">
  <div class="row d-block d-md-none no-gutters" (click)="toggleShowSearchEngine()">
    <div class="col-12 mobile-search-btn mr-05rem">
      <mat-icon *ngIf="!showSearchEngine">search</mat-icon>
      <mat-icon *ngIf="showSearchEngine">cancel</mat-icon>
      <span *ngIf="!showSearchEngine">Cerca la tua Vacanza</span>
      <span *ngIf="showSearchEngine">Chiudi ricerca</span>
    </div>
  </div>
  <div *ngIf="showSearchEngine" class="d-md-none motore-ricerca-wrapper">
    <borsaviaggi-search-engine></borsaviaggi-search-engine>
  </div>
  <div class="d-none d-md-block motore-ricerca-wrapper">
    <borsaviaggi-search-engine></borsaviaggi-search-engine>
  </div>
  <div class="gallery-wrapper full-width" [ngClass]="{'gallery-wrapper-mobile':isMobileDevice}">
    <ngx-gallery *ngIf="isRenderInBrowser"
                 (change)="actionChangeImage($event)"
                 (click)="actionImageClick($event)"
                 [options]="gallery.options"
                 [images]="gallery.images"
    ></ngx-gallery>
  </div>
  <div class="full-width banner-partner col-12 d-none d-sm-block d-md-block d-lg-block" *ngIf="!isMobileDevice">
    <div class="container">
      <div class="row">
        <borsaviaggi-carosello [listPartner]="listPartner"></borsaviaggi-carosello>
      </div>
    </div>
  </div>
  <div class="full-width top-offerte mt-20px">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <mat-tab-group (selectedTabChange)="changeTab()">
            <mat-tab *ngFor="let itemTab of listTabOfferteTop; let i = index" [attr.data-index]="i">
              <ng-template mat-tab-label cdkStepLabel>
                <div class="d-flex align-items-center justify-content-center text-mobile"
                     (click)="loadSelezione(itemTab)">
                  <img class="img-tab-offerte mr-5px"
                       alt="offerte vacanze {{itemTab.nome}}"
                       [src]="isMobileDevice ? itemTab.urlImmagineMobile : itemTab.urlImmagine">
                  <span class="align-middle fs-1_3rem">{{itemTab.nome | titlecase}}</span>
                </div>
              </ng-template>

              <div class="row" *ngIf="listOfferteMap[itemTab.idTipologia]">
                <borsaviaggi-box-offerta-top
                  class="col-lg-3 col-md-4 col-sm-6 d-none d-sm-block d-md-block d-lg-block mb-20px "
                  *ngFor="let boxOfferta of listOfferteMap[itemTab.idTipologia] | keyvalue"
                  [configObj]="boxOfferta.value">
                </borsaviaggi-box-offerta-top>
                <swiper *ngIf="isMobileDevice" [config]="configSwiper.topItems">
                  <borsaviaggi-box-offerta-top class="col-auto"
                                               *ngFor="let boxOfferta of listOfferteMap[itemTab.idTipologia] | keyvalue"
                                               [configObj]="boxOfferta.value">

                  </borsaviaggi-box-offerta-top>
                </swiper>
              </div>
              <borsaviaggi-loader typeMode="piker" [hidden]="listOfferteMap[itemTab.idTipologia]"
                                  [show]="true"></borsaviaggi-loader>

            </mat-tab>
          </mat-tab-group>

        </div>
      </div>
    </div>
  </div>
  <div class="full-width box-promo mt-30px macro-box-bv" *ngIf="!isMobileDevice">
    <div class="container">
      <div class="row">
        <borsaviaggi-macro-box [configObj]="macroBoxConfig.listaDiNozze"
                               class="col-sm-12 col-md-4 col-lg-4 mb-10px"></borsaviaggi-macro-box>
        <borsaviaggi-macro-box [configObj]="macroBoxConfig.superTravelExpert"
                               class="col-sm-12 col-md-4 col-lg-4 mb-10px"></borsaviaggi-macro-box>
        <borsaviaggi-macro-box [configObj]="macroBoxConfig.utilizzoCashback"
                               class="col-sm-12 col-md-4 col-lg-4 mb-10px tuo-cashback"></borsaviaggi-macro-box>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isMobileDevice">
    <div class="full-width banner-partner ml-0 mr-0 p-0 col-12">
      <div class="container p-0">
        <div class="row">
          <borsaviaggi-carosello [listPartner]="listPartner"></borsaviaggi-carosello>
        </div>
      </div>
    </div>
    <!-- SLIDER I NOSTRI SERVIZI -->
    <div class="col-12 mt-30px no-gutters padding-0">
      <h1 class="text-align-center mb-30px">I NOSTRI SERVIZI</h1>
      <swiper [config]="configSwiper.servizi">
        <borsaviaggi-macro-box [configObj]="macroBoxConfig.utilizzoCashback"></borsaviaggi-macro-box>
        <borsaviaggi-macro-box [configObj]="macroBoxConfig.superTravelExpert"></borsaviaggi-macro-box>
        <borsaviaggi-macro-box [configObj]="macroBoxConfig.listaDiNozze"></borsaviaggi-macro-box>
      </swiper>
    </div>
  </ng-container>

  <div class="full-worsaviaggi-macro-box [ch box-promo mt-40px">
    <div class="container">
      <h1 class="text-align-center mb-30px">LE NOSTRE SELEZIONI</h1>
      <div class="row">
        <borsaviaggi-macro-box *ngFor="let boxSelezioneItem of listSelezioniMacro"
                               [configObj]="boxSelezioneItem"
                               class="pl-0 pr-0 col-lg-3 col-md-4 col-sm-6 mb-20px d-flex align-items-center justify-content-center"></borsaviaggi-macro-box>
      </div>
    </div>
  </div>
  <div class="full-width parallax-1 mt-40px"
       [ngClass]="{'no-parallax':userAgent.browser?.toLowerCase() == 'safari' && isMobileDevice}">
    <div class="banner" *ngIf="!isMobileDevice">
      <borsaviaggi-links-banner class="home-links"></borsaviaggi-links-banner>
    </div>
  </div>

  <div class="full-width box-news-letter">
    <div class="container">
      <div class="row p-20px-0">

        <div class="col-lg-3  col-sm-2 d-flex align-items-center justify-content-center" lazy-load-images>
          <img class="icon-newsletter" data-src="assets/img/home/news-letter-icon.svg" alt="Vacanze news letter">
        </div>

        <div class="col-lg-4  col-sm-5 d-flex align-items-center justify-content-center no-gutters padding-0">
          <span class="fs-1_4rem fw-700 ">Ricevi in anteprima sconti sui viaggi</span>
        </div>
        <div class="col-lg-5  col-sm-5  d-flex align-items-center justify-content-center">
          <form [formGroup]="formGroupNewsLetter" class="position-relative">
            <div class="d-flex flex-column col-12 mt-1em flex-wrap h-100">
              <mat-form-field appearance="outline" class="padding-0 mr-1em">
                <mat-label class=" height-mail">Inserisci la tua Email</mat-label>
                <input matInput placeholder="Email" autocomplete="off" formControlName="email">
                <mat-icon *ngIf="!loadingSendIscrizioneNL"
                          matSuffix
                          [ngClass]="{'error-color':!formGroupNewsLetter.get('email').valid, 'success-color':formGroupNewsLetter.get('email').valid}"
                          [hidden]="!formGroupNewsLetter.get('email').valid && formGroupNewsLetter.untouched">

                  {{ formGroupNewsLetter.get('email').valid ? 'sentiment_very_satisfied' : 'mood_bad' }}
                </mat-icon>
                <mat-error *ngIf="formGroupNewsLetter.get('email').invalid">
                  {{formGroupNewsLetterError.getErrorMessage(formGroupNewsLetter.get('email'))}}
                </mat-error>

              </mat-form-field>
              <mat-checkbox formControlName="privacy" class="fs-12px left full-width mt-1em" [checked]="true">
                Ho letto ed accetto l'<a routerLink="/informativa-privacy">Informativa Privacy</a>
              </mat-checkbox>
            </div>

            <div class="d-flex col-12">
              <borsaviaggi-promise-btn
                class="align-self-center mt-1em"
                type="submit"
                [loading]="loadingSendIscrizioneNL"
                buttonText="Registrati"
                (buttonClick)="actionSubmitNewsLetter(formGroupNewsLetter.valid)"
                [disabled]="!formGroupNewsLetter.valid"
                buttonClass="no-gutters padding-0 background-blue-borsaviaggi color-white w-100">
              </borsaviaggi-promise-btn>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isMobileDevice">
    <mat-accordion *ngIf="isMobileDevice">
      <mat-expansion-panel class="valori-mobile">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>I NOSTRI VALORI</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container p-0 col-12">
          <div class="row m-0 background-grey">
            <div class="col-12 pl-3rem pt-3 pb-2 d-flex justify-content-start border-top-2px-white">
              <div class="inline-block mr-15px" lazy-load-images>
                <img class="width-32px filter-invert" data-src="../../../assets/img/home/operatore-valori-blu.svg"
                     alt="operatore viaggi icona">
              </div>
              <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                <div class="fs-1_2rem txt-color-borsaviaggi-blue">DA 30 ANNI</div>
                <div class="fs-1rem txt-color-borsaviaggi-blue">CONSULENTE ONLINE</div>
              </div>
            </div>
          </div>
          <div class="row m-0 background-grey">
            <div class="col-12 pr-3rem pt-3 pb-2 d-flex justify-content-end border-top-2px-white">
              <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                <div class="fs-1_2rem text-align-right txt-color-borsaviaggi-blue">GARANZIA</div>
                <div class="fs-1rem text-align-right txt-color-borsaviaggi-blue">PREZZO PIÙ BASSO</div>
              </div>
              <div class="inline-block ml-15px">
                <img class="width-32px filter-invert" src="../../../assets/img/home/garanzia-valori-blu.svg"
                     alt="garanzia viaggi icona">
              </div>
            </div>
          </div>
          <div class="row m-0 background-grey">
            <div class="col-12 pl-3rem pt-3 pb-2 d-flex justify-content-start border-top-2px-white">
              <div class="inline-block mr-15px">
                <img class="width-32px filter-invert" src="../../../assets/img/home/assistenza-valori-blu.svg"
                     alt="assistenza viaggi icona">
              </div>
              <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                <div class="fs-1_2rem txt-color-borsaviaggi-blue">ASSISTENZA</div>
                <div class="fs-1rem txt-color-borsaviaggi-blue">PRE-DURANTE-POST VENDITA</div>
              </div>
            </div>
          </div>
          <div class="row m-0 background-grey">
            <div class="col-12 pr-3rem pt-3 pb-2 d-flex justify-content-end border-top-2px-white">
              <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                <div class="fs-1_2rem text-align-right txt-color-borsaviaggi-blue">+ di 100.000</div>
                <div class="fs-1rem text-align-right txt-color-borsaviaggi-blue">CLIENTI CI HANNO SCELTO</div>
              </div>
              <div class="inline-block ml-15px">
                <img class="width-32px filter-invert" src="../../../assets/img/home/clienti-valori-blu.svg"
                     alt="clienti viaggi icona">
              </div>
            </div>
          </div>
          <div class="row m-0 background-grey">
            <div class="col-12 pl-3rem pt-3 pb-2 d-flex justify-content-start border-top-2px-white">
              <div class="inline-block mr-15px">
                <img class="width-32px filter-invert" src="../../../assets/img/home/1-portale-valori-blu.svg"
                     alt="1° portale viaggi icona">
              </div>
              <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                <div class="fs-1_2rem txt-color-borsaviaggi-blue">1° PORTALE</div>
                <div class="fs-1rem txt-color-borsaviaggi-blue">VIAGGI VACANZE IN ITALIA</div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel class="valori-mobile">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>LASTMINUTE</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container p-0 col-12">
          <div class="row m-0 background-grey cursor-pointer">
            <a routerLink="/hotel-italia" class="full-width" title="Lastminute hotel in Italia">
              <div class="col-12 pl-3rem pt-3 pb-2 d-flex justify-content-start border-top-2px-white">
                <div class="inline-block mr-15px">
                  <img class="width-32px filter-invert fill-opaque" src="../../../assets/img/home/italy.svg"
                       alt="italia icona">
                </div>
                <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                  <div class="fs-1_2rem txt-color-borsaviaggi-blue">Hotel in Italia</div>
                  <div class="fs-1rem">I migliori hotel Last Minute in Italia</div>
                </div>
              </div>
            </a>
          </div>
          <div class="row m-0 background-grey cursor-pointer">
            <a routerLink="/hotel-mondo" class="full-width" title="Lastminute hotel nel mondo">
              <div class="col-12 pr-3rem pt-3 pb-2 d-flex justify-content-end border-top-2px-white">
                <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                  <div class="fs-1_2rem text-align-right txt-color-borsaviaggi-blue">Hotel nel Mondo</div>
                  <div class="fs-1rem text-align-right">I migliori hotel Last Minute nel mondo</div>
                </div>
                <div class="inline-block ml-15px">
                  <img class="width-32px filter-invert fill-opaque" src="../../../assets/img/home/planet-earth.svg"
                       alt="italia icona">
                </div>
              </div>
            </a>
          </div>
          <div class="row m-0 background-grey cursor-pointer">
            <a routerLink="/viaggi-italia" class="full-width" title="Lastminute viaggi in Italia">
              <div class="col-12 pl-3rem pt-3 pb-2 d-flex justify-content-start border-top-2px-white">
                <div class="inline-block mr-15px">
                  <img class="width-32px filter-invert fill-opaque" src="../../../assets/img/home/italy.svg"
                       alt="italia icona">
                </div>
                <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                  <div class="fs-1_2rem txt-color-borsaviaggi-blue">Destinazioni Italia</div>
                  <div class="fs-1rem">Offerte Last Minute in Italia</div>
                </div>
              </div>
            </a>
          </div>
          <div class="row m-0 background-grey cursor-pointer">
            <a routerLink="/viaggi-mondo" class="full-width" title="Lastminute viaggi nel mondo">
              <div class="col-12 pr-3rem pt-3 pb-2 d-flex justify-content-end border-top-2px-white">
                <div class="inline-block lh-18px fw-400 vertical-align-text-top font-weight-bold">
                  <div class="fs-1_2rem text-align-right txt-color-borsaviaggi-blue">Destinazioni Mondo</div>
                  <div class="fs-1rem text-align-right">Offerte Last Minute nel mondo</div>
                </div>
                <div class="inline-block ml-15px">
                  <img class="width-32px filter-invert fill-opaque" src="../../../assets/img/home/planet-earth.svg"
                       alt="clienti viaggi icona">
                </div>
              </div>
            </a>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

</div>
