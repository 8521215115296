import {Component, OnInit} from '@angular/core';
import {SEO_GLOBAL_KEYWORD} from "../../constants/seo.const";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'borsaviaggi-come-prenotare',
  templateUrl: './come-prenotare.component.html',
  styleUrls: ['./come-prenotare.component.scss']
})
export class ComePrenotareComponent implements OnInit {

  constructor(private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle(SEO_GLOBAL_KEYWORD.COME_PRENOTARE_PAGE);
  }

}
