<div class="banner-footer" [hidden]="isMobileDevice" *ngIf="!hideNavigationDesktop">
  <borsaviaggi-links-banner class="home-links"></borsaviaggi-links-banner>
</div>

<div class="container border-top-2px-white">
  <div class="row p-10px-0 white padding-xs">
    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
      <div class="title-container mb-10px">
        <h3>Borsaviaggi S.R.L.</h3></div>
      <div class="item-container border-blue">
        <span class="display-block">H.L.A. Comunicazione Turistica</span>
        <a
          title="Borsaviaggi indirizzo google"
          href="https://www.google.com/maps/place/Borsaviaggi.it/@41.833022,12.4889903,17z/data=!3m1!4b1!4m5!3m4!1s0x13258a9f52b192f9:0x64e1066a597af0d7!8m2!3d41.833022!4d12.491179"
          target="_blank">Via del Serafico 185</a>
        <span class="display-block">00142 - Roma</span>
        <span class="display-block">P.IVA 08842781000</span>
        <span class="display-block"><a title="Note Legali" class="cursor-pointer" (click)="openDialogNoteLegali()">Note Legali</a>
          - <a title="Contatti" class="cursor-pointer" (click)="openDialogContatti()">Contatti</a></span>

        <span class="display-block"><a title="Privacy" routerLink="privacy" target="_blank">Privacy Policy</a></span>
        <span class="display-block mt-15px"><a title="Telefono" href="tel:+39065587667">Tel. 065587667</a></span>
        <a
          title="Borsaviaggi indirizzo email"
          href="mailto:info@borsaviaggi.it" class="display-block">Email:
          info@borsaviaggi.it</a>
      </div>
    </div>
    <div class="col-3 d-none d-sm-block d-md-block d-lg-block">
      <div class="title-container  mb-10px">
        <h3>La Nostra Azienda</h3>
      </div>
      <div class="item-container">
        <a routerLink="/qui-dietro-ci-siamo-noi"
           class="display-block fw-600  mb-5px"
           target="_blank" title="Offerte vacanze Qui dietro ci siamo Noi">
          Qui dietro ci siamo Noi
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/scrivi-al-direttore" target="_blank" title="Scrivi al Direttore">
          Scrivi al Direttore
        </a>
        <a
          class="display-block fw-600  mb-5px"
          href="https://www.iconsulentidiviaggio.it/" target="_blank" title="Diventa consulete di viaggio">
          Diventa Consulente
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/lavora-con-noi" target="_blank" title="Lavora con noi">
          Lavora con Noi
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/suggerimenti" target="_blank" title="Suggerimenti">
          Suggerimenti
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/rassegna-stampa" target="_blank" title="Rassegna Stampa">
          Rassegna Stampa
        </a>
      </div>
    </div>
    <div class="col-3 d-none d-sm-block d-md-block d-lg-block">
      <div class="title-container  mb-10px">
        <h3>Prenota con Noi</h3>
      </div>
      <div class="item-container ">
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/come-prenotare" target="_blank" title="Ccme prenotare con Borsaviaggi">
          Come Prenotare
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/condizioni-generali-contratto" target="_blank"
          title="Condizioni generali del contratto borsaviaggi">
          Termini e Condizioni
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/prime" target="_blank" title="Iscriviti a Prime">
          Diventa Prime
        </a>
        <a
          class="display-block fw-600  mb-5px"
          routerLink="/prezzo-piu-basso" target="_blank" title="Offerte lastsecond al prezzo piu basso">
          Garanzia Prezzo Più Basso
        </a>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-center" *ngIf="!isMobileDevice">
      <div id="lcqualitybadge"></div>
    </div>
  </div>
</div>

