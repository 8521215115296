import {Component, Input, OnInit} from '@angular/core';
import {SEO_OFFERTE} from "../../constants/seo.const";
import {ImageUtilService} from "../../services/common/image.util.service";
import {UtilsStringService} from "../../services/common/utils-string.service";

@Component({
  selector: 'borsaviaggi-box-offerta-top',
  templateUrl: './box-offerta-top.component.html',
  styleUrls: ['./box-offerta-top.component.scss']
})
export class BoxOffertaTopComponent implements OnInit {

  @Input() public configObj;

  public urlHref: string;
  public titleHref: string;

  constructor() {
  }

  ngOnInit() {
    this.urlHref = this.makeUrlSeoHref();
    this.titleHref = this.configObj.nazione + " " + this.configObj.destinazione + " " + this.configObj.struttura;
    if (ImageUtilService.supportWebP() && this.configObj.linkFotoWebp) {
      this.configObj.linkFoto = this.configObj.linkFotoWebp;
    }
    // console.log('this.configObj', this.configObj);
  }


  private makeUrlSeoHref() {
    let ret = "";
    if (this.configObj.idG !== -1 || this.configObj.idP !== -1) {
      //VACANZA
      ret = SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
      ret += this.configObj.nazione + "/";
      ret += this.configObj.destinazione + "/";
      ret += UtilsStringService.removeSpecialChar(this.configObj.struttura) + "/";
      if (this.configObj.idP !== -1) {
        ret += this.configObj.luoghi + "/";
        ret += this.configObj.date + "/";
        ret += "p" + "/";
        ret += this.configObj.idP;
      } else {
        ret += "g" + "/";
        ret += this.configObj.idG;
      }
    } else if (this.configObj.isCrociera) {
      //CROCIERA
      ret = this.configObj.url;
      /*ret = SEO_OFFERTE.CROCIERA_CATEGORIA_KEYWORD + "/";
      ret += this.configObj.nomeCompagniaCrociera + "/";
      if (this.configObj.idC !== -1) {
        ret += UtilsStringService.removeSpecialChar(this.configObj.struttura) + "/";
        ret += this.configObj.luoghi + "/";
        ret += this.configObj.date + "/";
        ret += "c" + "/";
        ret += this.configObj.idC;
      } else {
        ret += UtilsStringService.removeSpecialChar(this.configObj.struttura) + "/";
        ret += "r" + "/";
        ret += this.configObj.idR;
      }*/
    }
    return ret.toLowerCase().replace(/\s/g, "-");
  }
}
