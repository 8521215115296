<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px background-white">
  <img class="mb-15px cancellazione-banner" src="../../../assets/img/cancellazione-banner-landing.jpg" alt="banner cancellazione gratuita">
  <h3 class="my-3">Cancellazione Gratuita – prenota ora e puoi annullare a Zero Penali</h3>
  <p>Prenota entro il 31 Gennaio 2021... e se cambi idea non perdi nulla!</p>
  <p>Per garantirti la massima serenità nella prenotazione della tua prossima vacanza, ti presentiamo un'ampia selezione di offerte in cui puoi trovare <b>gratuitamente</b> la promozione <b>Cancellazione Gratuita</b>.</p>
  <p>Sarai libero di <b>annullare</b>, in caso di necessità, <b>senza addebito di penali*</b> la tua vacanza fino a <b>14 / 8 giorni prima</b> della data di <b>partenza</b> in base al prodotto scelto.</p>
  <p>La promo riguarda pacchetti <b>volo charter + soggiorno</b> in Italia e nel Mondo ed offerte <b>solo soggiorno</b> in Italia, per pratiche con <b>minimo 2 partecipanti</b>, ed è <b>valida</b> per <b>prenotazioni</b> entro il <b>31 Gennaio 2021</b> con <b>partenza</b> entro il <b>31 Ottobre 2021</b>.</p>
  <p class="my-3 font-italic font-small">
    * E' previsto il rimborso integrale della pratica ad esclusione della sola quota d'iscrizione.
  </p>
  <p>Trovi riportate in sintesi le regole di cancellazione dedicate ad ogni offerta all'interno delle singole partenze incluse nella campagna ed evidenziate con il bollino Cancellazione Gratuita. Per le condizioni estese si rimanda a quanto pubblicato sul sito del singolo Tour Operator.</p>
  <p>Quindi cosa aspetti? <b>Prenota subito la tua vacanza su Borsaviaggi.it!</b></p>
</div>
