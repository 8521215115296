import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

export class PasswordValidator {

  public static formPasswordValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      password: new FormControl('', Validators.compose([
        Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[_.,\-+*!#@?])([a-zA-Z0-9_.,\-+*!#@?]{8,25})$/)
      ]))
    });

}
