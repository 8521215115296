import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'bv-info-row',
  templateUrl: './info-row.component.html',
  styleUrls: ['./info-row.component.scss']
})
export class InfoRowComponent implements OnInit {

  @Input("label") label: any;
  @Input("value") value: any;
  @Input("icon") icon: any;

  constructor() {

  }

  ngOnInit() {
  
  }
  
}
