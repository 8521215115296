<div class="macro-wrapper" [ngSwitch]="configObj.boxConfigType">

  <div *ngSwitchCase="layoutConfigEnum.BIG_TEXT_V1"
       class="box-macro-v1 text-v1" [ngClass]="configObj.cssClass">
    <h2 class="item-title">{{configObj.titolo}}</h2>
    <div class="item-sub-title">
      <span class="vertical">{{configObj.sottoTitolo}}</span>
    </div>
    <div class="image-cover" [ngStyle]="{'background-image':'url('+configObj.imgBackground+')'}"></div>
    <a class="btn btn-default button-info" *ngIf="!configObj.btnUrlLink"
       [routerLink]="configObj.btnUrl">{{configObj.btnText}}</a>
    <a class="btn btn-default button-info" *ngIf="configObj.btnUrlLink" [href]="configObj.btnUrlLink"
       target="_blank">{{configObj.btnText}}</a>
  </div>

  <div *ngSwitchCase="layoutConfigEnum.BIG_SCONTO_V1"
       class="box-macro-v1 sconto">
    <h2 class="item-title v2">{{configObj.titolo}}</h2>
    <div class="item-sub-title">
      <div class="text">
        <span class="vertical">Per te uno sconto</span>
      </div>
      <p class="inline-block">
        <span class="valore-sconto">{{configObj.sottoTitolo}}</span>
      </p>
      <!--<div class="position-offer">{{configObj.nomePortale}}</div>-->
    </div>
    <div class="image-cover" [ngStyle]="{'background-image':'url('+configObj.imgBackground+')'}"></div>
    <a class="btn btn-default button-info" *ngIf="!configObj.btnUrlLink"
       [routerLink]="configObj.btnUrl">{{configObj.btnText}}</a>
    <a class="btn btn-default button-info" *ngIf="configObj.btnUrlLink" [href]="configObj.btnUrlLink"
       target="_blank">{{configObj.btnText}}</a>
  </div>

  <div *ngSwitchCase="layoutConfigEnum.BIG_TEXT_V2"
       class="box-macro-v1 text-v2" [ngClass]="configObj.cssClass">
    <div class="box-inner">
      <h2 class="item-title v2">{{configObj.titolo}}</h2>
      <div *ngIf="configObj.valoreCashback">
        <span class="cashback"> € {{configObj.valoreCashback}}</span>
      </div>
      <div class="item-sub-title">
        <div class="text">
          <span class="vertical">{{configObj.sottoTitolo}}</span>
        </div>
        <!-- <div class="position-offer">{{configObj.nomePortale}}</div>-->
      </div>
      <div class="image-cover" [ngStyle]="{'background-image':'url('+configObj.imgBackground+')'}"></div>
      <a class="btn btn-default button-info" *ngIf="!configObj.btnUrlLink"
         [routerLink]="configObj.btnUrl">{{configObj.btnText}}</a>
      <a class="btn btn-default button-info" *ngIf="configObj.btnUrlLink" [href]="configObj.btnUrlLink"
         target="_blank">{{configObj.btnText}}</a>
    </div>
  </div>

  <ng-container *ngSwitchCase="layoutConfigEnum.MACRO_VERTICAL_BOX">
    <a *ngIf="configObj.btnUrl"
       [routerLink]="configObj.btnUrl"
       [title]="configObj.titoloHref || configObj.titolo"
       (mouseenter)="configObj.hover = true"
       (mouseleave)="configObj.hover = false"
       lazy-load-images
       class="box-macro-vertical text-v1"
       [ngClass]="{'hover':configObj.hover,'animation-enabled':configObj.sottoTitolo}">
      <img [src]="configObj.imgBackground" [alt]="configObj.titolo">
      <div class="content-overlay titolo col d-flex align-items-center justify-content-center">
        <span class="title">{{configObj.titolo | titlecase}}</span>
      </div>
      <div class="content-overlay sottotitolo col d-flex align-items-center justify-content-center">
        <span>{{configObj.sottoTitolo   }}</span>
      </div>

    </a>
    <a *ngIf="configObj.btnUrlLink"
       [href]="configObj.btnUrlLink"
       [title]="configObj.titoloHref || configObj.titolo"
       (mouseenter)="configObj.hover = true"
       (mouseleave)="configObj.hover = false"
       class="box-macro-vertical text-v1"
       lazy-load-images
       [ngClass]="{'hover':configObj.hover,'animation-enabled':configObj.sottoTitolo}">
      <img [attr.data-src]="configObj.imgBackground" [alt]="configObj.titolo">
      <div class="content-overlay titolo col d-flex align-items-center justify-content-center">
        <span class="title">{{configObj.titolo | titlecase}}</span>
      </div>
      <div class="content-overlay sottotitolo col d-flex align-items-center justify-content-center">
        <span>{{configObj.sottoTitolo   }}</span>
      </div>

    </a>
    <div *ngIf="!configObj.btnUrlLink && !configObj.btnUrl"
       (mouseenter)="configObj.hover = true"
       (mouseleave)="configObj.hover = false"
       class="box-macro-vertical text-v1 cursor-pointer"
       [ngClass]="{'hover':configObj.hover,'animation-enabled':configObj.sottoTitolo}">
      <img [src]="configObj.imgBackground" [alt]="configObj.titolo">
      <div class="content-overlay titolo col d-flex align-items-center justify-content-center">
        <span class="title">{{configObj.titolo | titlecase}}</span>
      </div>
      <div class="content-overlay sottotitolo col d-flex align-items-center justify-content-center">
        <span>{{configObj.sottoTitolo   }}</span>
      </div>

    </div>
  </ng-container>

</div>
