import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CacheService} from "../../../services/common/cache.service";
import {ModalInterface} from "../../../interface/modal.interface";
import {Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {LoginValidator} from "../../../services/validator/login.validator";
import {UserService} from "../../../services/gate/user.service";
import {AuthService} from "../../../services/common/auth.service";
import {ModalService} from "../../../services/common/modal.service";
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'borsaviaggi-recupero-password-modal',
  templateUrl: './recupero-password-modal.component.html',
  styleUrls: ['./recupero-password-modal.component.scss']
})
export class RecuperoPasswordModalComponent implements OnInit {
  public recuperoData;
  public recuperoForm = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  public loading: boolean = false;
  public errorMessage;
  public utenteData;

  constructor(public dialogRef: MatDialogRef<RecuperoPasswordModalComponent>,
              private cacheSrv: CacheService,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<RecuperoPasswordModalComponent>,
              private router: Router,
              private userSrv: UserService,
              private authSrv: AuthService,
              private dialog: MatDialog,
              private modalSrv: ModalService) { }

  ngOnInit(): void {
  }

  public closeModal() {
    this.dialogRef.close(this.utenteData);
  }

  public recupero(valid) {
    if (valid) {
      this.loading = true;
      let dataParam = {
        email: this.recuperoForm.value,
      };
      this.userSrv.recuperaPassword(dataParam).then(
        (response) => {
          if (response && response.body) {
            this.loading = false;
            if (response.body.status == 2) {
              this.dialogRef.close();
              this.modalSrv.openFeedbackModal({error: "L'utente non risulta essere registrato."});
            } else if (response.body.status == 0) {
              this.dialogRef.close();
              this.modalSrv.openFeedbackModal({success: "Email inviata con successo."});
            } else {
              this.errorMessage = "Si è verifcato un errore";
            }
          } else {
            this.errorMessage = "Si è verifcato un errore";
            this.loading = false;
            this.modalSrv.openErrorDialog();
          }
        },
        (error) => {
          this.errorMessage = error;
          this.loading = false;
          this.modalSrv.openErrorDialog();
        }
      ).finally(
        () => {
          this.recuperoForm.reset();
          this.recuperoForm.markAsPristine();
          this.recuperoForm.markAsUntouched();
        }
      );
    }
    else {
      for (let formControlName in this.recuperoForm) {
        this.recuperoForm.get(formControlName).markAsTouched();
        this.recuperoForm.get(formControlName).markAsDirty();
      }
    }
  }
}
