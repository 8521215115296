import {Injectable} from '@angular/core';
import {UserService} from "../user.service";

@Injectable({
  providedIn: 'root'
})
export class ListaPrenotazioniResolveService {
  constructor(private userSer: UserService) {
  }

  resolve() {
    return this.userSer.getListaPrenotazioni()
      .catch(err => {
        return err;
      });
  }
}
