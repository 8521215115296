import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/gate/user.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-attiva-profilo',
  templateUrl: './attiva-profilo.component.html',
  styleUrls: ['./attiva-profilo.component.scss']
})
export class AttivaProfiloComponent implements OnInit {
  public detailsData;

  constructor(private userSrv: UserService,
              public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    let idUtente =  this.route.snapshot.params.id;
    this.getConfermaRegistrazione(idUtente);
  }

  private getConfermaRegistrazione(idUtente) {
    this.userSrv.confermaRegistrazione(idUtente).then(
      (response) => {
        this.detailsData = response.body;
    }, (error) => {
        console.error("Errore in caricamento dati", error);
    });
  }
}
