<div class="container border-r-2px mt-20px pt-10px  text-align-justify background-white mb-20px pb-20px">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi mt-5px mb-5px text-align-center">Prenotare su Borsaviaggi.it</h1>
      <p class="sotto-titolo">Individuato il pacchetto che ti interessa, potrai procedere con la richiesta di
        prenotazione
        nelle seguenti modalità:</p>
    </div>
  </div>
  <div class="row">
    <div class="col sezione">
      <div class="mb-10px">
        <img class="immagine-call" src="assets/img/come-prenotare/call-center.png" alt="immagine call center">
      </div>
      <h1 class="color-text-blue-borsaviaggi mb-5px">Chiama e Prenota</h1>
      <div>
        Potrai telefonare al <span class="accorgimenti">CALL CENTER</span> [<span
        class="marcato">06.55.87.667 - int. 1</span>] e richiedere <span
        class="accorgimenti"> ai nostri Consulenti</span>
        la scheda di prenotazione che ti sarà inviata via posta elettronica, con:
        <ul>
          <li>il preventivo di viaggio richiesto</li>
          <li>la descrizione delle fasi di prenotazione</li>
        </ul>
      </div>
      <p>A questo punto se desideri procedere subito con la richiesta di prenotazione dovrai seguire la procedura online
        e
        reinviare entro 24h la scheda di prenotazione firmata tramite fax al numero
        <span class="marcato">06.55.30.23.27</span> o scannerizzarla ed inviarla via e-mail al consulente che ha seguito
        la
        richiesta.
      </p>
      <div class="accorgimenti">
        <span>IMPORTANTE!</span>
        <p>PRIMA di chiamare ricorda di prendere nota del [codice offerta] o del [codice partenza] legato al pacchetto
          che
          ti interessa e di comunicarlo poi al consulente che risponderà al telefono, cosi da velocizzare la gestione
          della tua richiesta.</p>
      </div>
      <h4 class="marcato">Una volta avviata la prenotazione, sia tramite Chiamata Telefonica che Prenotazione online con
        Assistenza telefonica, i passi successivi saranno:</h4>
      <ol>
        <li>attendere la riconferma telefonica o via e-mail della prenotazione da parte dei nostri Consulenti</li>
        <li>una volta ottenuta la riconferma, potrai effettuare il pagamento del saldo a mezzo bonifico bancario, carta
          di
          credito o carta ricaricabile seguendo le indicazioni del tuo consulente
        </li>
        <li>invio dei documenti di viaggio (biglietti e voucher) o del foglio di convocazione a mezzo posta
          elettronica.*
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col sezione">
      <h1 class="color-text-blue-borsaviaggi mb-5px">Compilazione e Pagamento OnLine</h1>
      <p>
        <span class="accorgimenti">Con questo sistema blocchi subito l’offerta che ti interessa.</span> Compila la il
        modulo online e scegli uno dei seguenti metodi di pagamento.
      </p>
      <div class="row">
        <div class="col-md-2 col-sm-12 img-container">
          <img class="carta-credito" alt="immagine come prenotare rapido carta di credito"
               src="assets/img/come-prenotare/icona-carta.png">
        </div>
        <div class="col-md-10 col-sm-12">
          <h3 class="color-text-blue-borsaviaggi mb-5px">RAPIDO - Pagamento con Carta di Credito</h3>
          <p>
            <span
              class="accorgimenti mb-5px display-block">Blocca rapidamente l'offerta con la transazione immediata.</span>
            L'importo di sarà sospeso a garanzia della tua prenotazione.
            Conclusa l'operazione procederemo con la conferma immediata dei servizi,
            all’incasso dell’importo transato e ti contatteremo tempestivamente per la finalizzazione della pratica.
          </p>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 col-sm-12 img-container">
          <img src="assets/img/come-prenotare/icona-carta-lock.png" alt="immagine come prenotare carta di credito"
               class="immagine-icona">
        </div>
        <div class="col-md-10 col-sm-12">

          <h3 class="color-text-blue-borsaviaggi mb-5px">EASY FAST - Carta di Credito a Garanzia / Pagamento Bonifico
            Bancario</h3>
          <p>
            <span class="accorgimenti  mb-5px display-block">Blocca rapidamente l'offerta inserendo Carta a Garanzia e procedi con il bonifico bancario.</span>
            L'importo di sarà sospeso a sola garanzia della tua prenotazione;
            tale importo verrà istantaneamente sbloccato all'incasso del bonifico bancario
            che dovrà pervenire entro massimo 24h. Conclusa l'operazione procederemo con la conferma
            immediata dei servizi e ti contatteremo tempestivamente per la finalizzazione della pratica.
          </p>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-2 col-sm-12 img-container">
          <img src="assets/img/come-prenotare/icona-bonifico.png" alt="immagine come prenotare bonifico"
               class="immagine-icona">
        </div>
        <div class="col-md-10 col-sm-12">
          <h3 class="color-text-blue-borsaviaggi mb-5px">EASY - Bonifico Bancario</h3>
          <p>
            <span
              class="accorgimenti mb-5px display-block">Procedi con la richiesta ed effettua il bonifico bancario.</span>
            Potrai scaricare il contratto di viaggio con le relative istruzioni per effettuare il bonifico
            bancario per finalizzare la richiesta di prenotazione. Ti contatteremo immediatamente per bloccare i posti
            e completare la tua pratica.
          </p>
        </div>
      </div>
      <div class="mt-10px">
        <p>
          Qualora i posti richiesti non fossero più disponibili il consulente provvederà al ristorno immediato
          dell'importo e riceverai una e-mail da Banca Sella di annullamento dell'operazione.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p>
        * NB: Per esigenza delle compagnie di viaggio e dei vettori aerei, i documenti (voucher e/o foglio di
        convocazione)
        potranno essere recapitati fino al giorno antecedente la partenza. Per la chiarezza che contraddistingue il
        nostro
        servizio vi preghiamo di prenderne buona nota.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col text-align-center">
      <p>
        <a routerLink="/termini-condizioni"> Clicca qui </a> per leggere le condizioni generali del contratto.
      </p>
    </div>
  </div>
</div>

