import {Component, Inject, OnInit} from '@angular/core';
import {ModalInterface} from "../../../interface/modal.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'borsaviaggi-modal-destinazioni-vprime',
  templateUrl: './modal-destinazioni-vprime.component.html',
  styleUrls: ['./modal-destinazioni-vprime.component.scss']
})
export class ModalDestinazioniVprimeComponent implements OnInit {
  public destinazioneVprime = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalInterface<ModalDestinazioniVprimeComponent>,
              public dialogRef: MatDialogRef<ModalDestinazioniVprimeComponent>) {
    if (Object.keys(data).length) {
      this.destinazioneVprime.push(data.dataParam);
    }
  }

  ngOnInit() {
  }

}
