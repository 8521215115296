import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {NavigationService} from "../navigation-component/navigation.service";
import {AuthService} from "../../services/common/auth.service";
import {User} from "../../model/user.dto";
import {RatingService} from "../../services/gate/rating.service";
import {ModalService} from "../../services/common/modal.service";
import {ResponseCheckerService} from "../../services/common/response.checker.service";
import {UserService} from "../../services/gate/user.service";
import {PreferitiService} from "../preferiti/preferiti.service";
import {LoginSidenavService} from "../login-sidenav/loginSidenav.service";
import {MatSidenav} from "@angular/material/sidenav";
import {UtilityService} from "../../services/gate/utility.service";
import {repeat} from "rxjs/operators";
import {BannerManagerService} from "../../services/common/banner-manager.service";


@Component({
  selector: 'borsaviaggi-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
  @Input("showMobileMenu") public showMobileMenu: boolean = false;
  @Input('sideNavLoginCtrl') private sideNavLoginCtrl: MatSidenav;
  @Output() toggleMenuFunction: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isOpenNavigationMenu: boolean;
  public hideNavigationDesktop: boolean;
  public user: User;
  public totaleCashback: any;
  public percentageRating: number;
  private suberscriberAuth: Subscription;
  private suberscriberPreferiti: Subscription;
  private bannerSubscribe: Subscription;
  public listaDesideri;
  public promozioneAttiva: boolean = false;
  public isPrimeDays: boolean = false;
  public bannerPromozione;
  public bannerMobilePromozione;
  public showPromozioneAttiva: boolean = true;
  public nomePromozione;

  constructor(private router: Router,
              private navServ: NavigationService,
              private ratingSrv: RatingService,
              private preferitiServ: PreferitiService,
              public userSrv: UserService,
              public authSrv: AuthService,
              private modalSrv: ModalService,
              private loginSideSrv: LoginSidenavService,
              public utilitySrv: UtilityService,
              private route: ActivatedRoute,
              private bannerSrv: BannerManagerService) {

    this.bannerSubscribe = this.bannerSrv.fuoriTuttoVisibilityObservable().subscribe(
      (showParam) => {
        this.showPromozioneAttiva = showParam;
      }
    );

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.isOpenNavigationMenu = false;
      }
    });
    this.navServ.visibleState.subscribe((state: boolean) => {
      this.isOpenNavigationMenu = state;
    });
    this.user = this.authSrv.getUser();
    this.totaleCashback = this.getTotaleCashback();
    this.percentageRating = 94;
    this.getRatingRq();
  }

  ngOnInit() {
    this.suberscriberAuth = this.authSrv.userObservable().subscribe(
      (dataUserLogged) => {
        if (dataUserLogged) {
          this.user = this.authSrv.getUser();
          this.totaleCashback = this.getTotaleCashback();
          this.getListaDesideriUtente();
        } else {
          this.authSrv.clearUser();
          this.user = this.authSrv.getUser()
        }
      }
    );
    this.suberscriberPreferiti = this.preferitiServ.preferitiState.subscribe(() => {
      this.getListaDesideriUtente();
    });
    this.getListaDesideriUtente();

    this.utilitySrv.getPromozioneAttiva().then(
      (response) => {
        if (response && response.body.data) {
          this.promozioneAttiva = response.body.data.promozioneAttiva;
          this.bannerPromozione = response.body.data.banner;
          this.bannerMobilePromozione = response.body.data.bannerMobile;
          this.nomePromozione = response.body.data.nomePromozione;
          this.isPrimeDays = response.body.data.isPrimeDays;
        } else {
          console.log("Errore reperimento promozione attiva")
        }
      },
      (error) => {
        console.log(error)
      });
  }

  private getRatingRq() {
    this.ratingSrv.getRating().then(
      (response) => {
        if (response && ResponseCheckerService.isSuccessResponse(response.body)) {
          this.percentageRating = response.body.data.percentualeMediaTotale;
        }
      },
      (error) => {
        console.error("Errore reperimento rating", error);
      }
    )
  }

  public openSideNav() {
    this.isOpenNavigationMenu = true;
    this.sideNavLoginCtrl.open();
    this.toggleMenuFunction.emit(true);
  }

  public closeSideNav(){
    this.sideNavLoginCtrl.close().then(
      ()=>{
        this.isOpenNavigationMenu = false;
      }
    );
    this.toggleMenuFunction.emit(false);
  }

  public actionLogin() {
    this.loginSideSrv.open();
  }

  public actionGoToAreaPersonale() {
    this.router.navigate(['/area-personale']);
  }

  public getTotaleCashback() {
    if (this.user) {
      let totaleCashback = 0;
      if (this.user.listaBuoniSconto.length) {
        this.user.listaBuoniSconto.forEach((element) => {
          totaleCashback = totaleCashback + element.ImportoSconto
        });
      }
      return totaleCashback
    }
  }

  public getListaDesideriUtente() {
    let dataParam = {};
    this.listaDesideri = [];
    this.userSrv.getListaDesideriUtente(dataParam).then(
      (response) => {
        if (response && response.body && response.body.data) {
          this.listaDesideri = response.body.data

        } else {
          console.log("La tua lista preferiti è vuota!");
        }
      },
      (error) => {
        console.log(error);

      }
    );
  }

  ngOnDestroy() {
    this.suberscriberAuth.unsubscribe();
    this.suberscriberPreferiti.unsubscribe();
    this.bannerSubscribe.unsubscribe();
  }
}
