import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class SearchEngineService {
  private searchStartSubject = new Subject<boolean>();
  public  searchState = this.searchStartSubject.asObservable();

  constructor() {
  }

  start() {
    this.searchStartSubject.next(true);
  }

  end() {
    this.searchStartSubject.next(true);
  }
}
