import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'borsaviaggi-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input('currentRating') private rating: number;
  @Input('starCount') private starCount: number;
  @Input('color') public color: string;
  @Output() private ratingUpdated = new EventEmitter();
  @Input() resetRating:Subject<any>;

  public ratingArr = [];

  constructor() {
  }


  ngOnInit() {
    this.color = this.color || StarRatingColor.primary;
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.resetRating.subscribe(event => {
      this.rating = 0;
    });
  }

  onClick(rating: number) {
    this.rating = rating;
    this.ratingUpdated.emit(rating);
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }



}

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}
