import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {PrimeService} from "../../services/gate/prime.service";
import {LoginValidator} from "../../services/validator/login.validator";
import {UtilityService} from "../../services/gate/utility.service";
import {AuthService} from "../../services/common/auth.service";
import {User} from "../../model/user.dto";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'borsaviaggi-prime-pagamento',
  templateUrl: './prime-pagamento.component.html',
  styleUrls: ['./prime-pagamento.component.scss']
})
export class PrimePagamentoComponent implements OnInit {
  public formGroup: FormGroup = LoginValidator.formRegisterPrimeValidator;
  public validation_messages: any = LoginValidator.formRegisterPrimeMessage;
  public listaComuni = [];
  public listRegioni = [];
  public userData: User;
  public urlParams;

  constructor(private primeSrv: PrimeService,
              private utilitySrv: UtilityService,
              private authSrv: AuthService,
              private route: ActivatedRoute) {
    if (this.route.snapshot.queryParams) {
      this.urlParams = this.route.snapshot.queryParams
    }

    this.formGroup.get('tipoPagamento').setValue(true);
    this.authSrv.userObservable().subscribe(
      (dataUserLogged) => {
        if (dataUserLogged) {
          this.userData = this.authSrv.getUser();
          this.populateFormByUtente(this.userData.userInfo);
        } else {
          this.authSrv.clearUser();
          this.userData = this.authSrv.getUser();
          this.populateFormByUtente(this.userData.userInfo);
        }
      }
    );
  }

  ngOnInit(): void {
   this.userData = this.authSrv.getUser();
    if (this.userData) {
      this.populateFormByUtente(this.userData.userInfo);
      if (this.userData.userInfo.idRegione) {
        this.getComuniByRegione(this.userData.userInfo.idRegione);
      }
    }
    this.getRegioni();
  }

  public getRegioni() {
    this.utilitySrv.getRegioni().then(
      (response) => {
        if (response && response.body.data) {
          this.listRegioni = response.body.data
        } else {
          console.log("Errore reperimento regioni");
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private getComuniByRegione(idRegione) {
    this.utilitySrv.getComuniByRegione({idRegione: idRegione}).then(
      (response) => {
        if (response && response.body.data) {
          this.listaComuni = response.body.data
        } else {
          console.log("Errore reperimento comuni")
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  public changeRegioneEvent(event) {
    this.listaComuni = [];
    this.getComuniByRegione(event.value)
  }

  public register() {
    if (this.formGroup.valid) {
      let param = {
        "buyerNominativo": this.formGroup.controls['nominativo'].value,
        "buyerEmail": this.formGroup.controls['email'].value,
        "cap": this.formGroup.controls['cap'].value,
        "causale": "Prova Prime",
        "codFiscale": this.formGroup.controls['codiceFiscale'].value,
        "cognome": this.formGroup.controls['cognome'].value,
        "from": this.urlParams && this.urlParams.from ? 2 : 1,
        "idComune": this.formGroup.controls['idComune'].value,
        "indirizzo": this.formGroup.controls['indirizzo'].value,
        "isCarta": this.formGroup.controls['tipoPagamento'].value,
        "nome": this.formGroup.controls['nome'].value,
        "idGuid": this.urlParams ? this.urlParams.utente : ''
      };
      this.primeSrv.getAttivazionePrime(param).then(
        (response) => {
          window.location.href = response.body.data.location;
        }
      )
    } else {
      for (let formControlName in this.formGroup.controls) {
        this.formGroup.get(formControlName).markAsTouched();
        this.formGroup.get(formControlName).markAsDirty();
      }
    }
  }

  public populateFormByUtente(user) {
    let formIntestatario = this.formGroup;
    formIntestatario.get('nome').setValue(user.nome);
    formIntestatario.get('cognome').setValue(user.cognome);
    formIntestatario.get('indirizzo').setValue(user.indirizzo);
    formIntestatario.get('email').setValue(user.email);
    formIntestatario.get('cap').setValue(user.cap);
    formIntestatario.get('codiceFiscale').setValue(user.codiceFiscale);
    formIntestatario.get('idRegione').setValue(user.idRegione);
    formIntestatario.get('idComune').setValue(user.idComune);
  }
}
