import { Component, Inject, OnInit } from '@angular/core';
import {ModalInterface} from "../../../interface/modal.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-contatti-modal',
  templateUrl: './contatti-modal.component.html',
  styleUrls: ['./contatti-modal.component.scss']
})
export class ContattiModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ContattiModalComponent>,
              private route: Router,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<ContattiModalComponent>) { }

  ngOnInit(): void {
  }

}
