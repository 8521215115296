import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'borsaviaggi-promise-btn',
  templateUrl: './promiseBtn.component.html',
  styleUrls: ['./promiseBtn.component.css']
})
export class PromiseButtonComponent implements OnInit {
  @Input() buttonClass:string;
  @Input() loading:string;
  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() buttonText:string;
  @Input() disabled: boolean;
  constructor() {
  }

  public setLoadingState(paramState){
    this.loading = paramState;
  }

  public actionClick(){
    if(this.buttonClick){
      this.buttonClick.emit();
    }
  }

  ngOnInit() {

  }

}
