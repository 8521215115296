import {
  Component,
  HostListener,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewChildren,
  AfterViewInit,
  Inject, PLATFORM_ID
} from '@angular/core';
import {UtilsStringService} from "../../../services/common/utils-string.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/common/auth.service";
import {ResponseCheckerService} from "../../../services/common/response.checker.service";
import {FormGroup} from "@angular/forms";
import {DatiPersonaliValidator} from "../../../services/validator/dati-personali.validator";
import {SEO_OFFERTE} from "../../../constants/seo.const";
import {CacheService} from "../../../services/common/cache.service";
import {NgxGalleryOptions} from "../../../components/gallery/ngx-gallery-options.model";
import {NgxGalleryImage} from "../../../components/gallery/ngx-gallery-image.model";
import * as moment from "moment";
import {ReadmoreComponent} from '../../../components/readmore/readmore.component';
import {Meta, Title} from "@angular/platform-browser";
import {NavigationsUtilsService} from "../../../services/common/navigation.utils.service";
import {ImageUtilService} from "../../../services/common/image.util.service";
import {ModalService} from "../../../services/common/modal.service";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subscription, interval} from 'rxjs';
import {ToolbarComponent} from "../../../components/toolbar/toolbar.component";
import {BannerManagerService} from "../../../services/common/banner-manager.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'borsaviaggi-dettaglio-vacanza-p-page',
  templateUrl: './dettaglio-vacanza-p-page.component.html',
  styleUrls: ['./dettaglio-vacanza-p-page.component.scss']
})
export class DettaglioVacanzaPPage implements OnInit, OnDestroy {

  @ViewChildren(ReadmoreComponent) readMoreComponent: ReadmoreComponent;

  public galleryOptions: NgxGalleryOptions[];
  public galleryImagesStruttura: NgxGalleryImage[];
  public galleryImagesDestinazione: NgxGalleryImage[];
  public utilsStr: UtilsStringService;

  public dataResponse;
  public urlRisultatiRicerca;
  public isMobileDevice: boolean;
  public dataSource: MatTableDataSource<any>;
  private paginator: MatPaginator;
  public photoNumber: number;
  public photos: any;
  public dataP: any;
  public dataDescPartenza: any;
  public splittedTitle: any;
  public photosType: any;
  /** struttura o destinazione */
  public listaFotoDestinazione: any;
  public listaFotoStruttura: any;
  public risparmioPrime: number;
  public idPartenza;
  public userData;
  public preventivoRq;
  public totaleSupplementi = 0;
  public idCameraSingolaSelected: any;
  public formGroup: FormGroup = DatiPersonaliValidator.formRichiestaInfoValidator;
  public validation_messages: any = DatiPersonaliValidator.formRichiestaInfoErrorMessage;
  public formGroupCamere;
  public loadingCamere: boolean;
  public totaleLiveDef;
  public costiLive;
  public isFlashExpanded: boolean = false;
  public prezziCamere;
  public displayedColumns = ['compagnia', 'partenza', 'arrivo'];
  public showStructureTab = false;
  public showItineraryTab = false;
  public txtAGarantito;
  public txtPrezzoDinamico;
  public importoBuonScontoPrime: number;
  public partenzaNonDisp: boolean = false;
  public showTiServeAiuto: boolean = true;
  public mobileStickyHeader: boolean;
  public prezzoLoading: boolean;
  public openShare: boolean;
  public costiLoco: string;
  public quotaComprende: string;
  public noteQuotaComprende: string;
  public bambiniGratisTxt: string;
  public dataBannerCampagne: Array<any>;
  public nameIconDeparturePlace: string;
  public promozioneAttiva: boolean = false;

  private subscription: Subscription;

  public dataFinePromozione: Date;
  public milliSecondsInASecond: number = 1000;
  public hoursInADay: number = 24;
  public minutesInAnHour: number = 60;
  public SecondsInAMinute: number = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  @ViewChild(MatPaginator)
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.mobileStickyHeader = scrollOffset >= 100;
  }


  constructor(private router: Router,
              private route: ActivatedRoute,
              private destinazioneSrv: DestinazioneService,
              private modalSrv: ModalService,
              public authSrv: AuthService,
              @Inject(PLATFORM_ID) private  platformId: string,
              private titleService: Title,
              private deviceService: DeviceDetectorService,
              private metaTagService: Meta,
              public cacheSrv: CacheService,
              private bannerSrv: BannerManagerService) {

    if (this.route.snapshot.data.dataP.body && this.route.snapshot.data.dataP.body.data) {
      this.isMobileDevice = this.deviceService.isMobile();
      this.photosType = 'Destinazione';
      this.galleryImagesStruttura = [];
      this.galleryImagesDestinazione = [];
      this.photoNumber = 0;
      this.dataResponse = this.dataP = this.route.snapshot.data.dataP.body.data;
      this.promozioneAttiva = this.route.snapshot.data.dataP.body.data.layoutPartenza.isDivImgFuoriTuttoVisible;
      this.dataFinePromozione = this.route.snapshot.data.dataP.body.data.layoutPartenza.dataFinePromozione;
      this.dataBannerCampagne = this.filterBannerCampagneFormat(this.dataResponse.dettaglioPartenza.listaBannerCampagne);
      this.idPartenza = this.route.snapshot.params.idP;
      this.dataDescPartenza = this.route.snapshot.data.dataPDesc.body.data;
      this.splittedTitle = this.splitTitleData(this.dataResponse.dettaglioPartenza.title);
      this.listaFotoDestinazione = this.dataDescPartenza.listaFotoDestinazione;
      this.listaFotoStruttura = this.dataDescPartenza.listaFotoStruttura;
      this.cacheSrv.setCacheData('partenza-' + this.idPartenza, this.route.snapshot.data.dataP);
      this.risparmioPrime = this.dataP.serviziPrime ? this.destinazioneSrv.getSommaVantaggiPrime(this.dataResponse) : 0;
      this.importoBuonScontoPrime = this.destinazioneSrv.getImportoBuonoSconto(this.destinazioneSrv.convertLblTotale(this.dataResponse.dettaglioPartenza.lblTotaleConPrime));
      this.setGalleryOptions();
      this.populateGallery(this.listaFotoDestinazione, this.galleryImagesStruttura);
      this.populateGallery(this.listaFotoStruttura, this.galleryImagesDestinazione);
      this.utilsStr = UtilsStringService;
      this.dataSource = new MatTableDataSource(this.dataResponse);
      this.loadingCamere = false;
      this.totaleLiveDef = 0;
      this.prezziCamere = [];
      this.txtAGarantito = "Borsaviaggi attraverso la sottoscrizione di un fondo garanzia tutela il suo cliente con massima affidabilità. In caso di insolvenza o di fallimento dell'agenzia intermediaria o del tour operator, il fondo a cui partecipiamo garantirà il rimborso del prezzo versato per il Viaggio ed il rimpatrio del viaggiatore nel caso di Viaggi all'Estero.";
      this.txtPrezzoDinamico = "Le offerte e i prezzi pubblicati del gruppo Alpitour sono soggetti a continua variazione poichè in costante aggiornamento di minuto in minuto. I nostri operatori al momento della richiesta ti indicheranno preventivo esatto con la garanzia del miglior prezzo disponibile."
      this.costiLoco = this.dataResponse.dettaglioPartenza.costiLoco;
      this.bambiniGratisTxt = this.dataResponse.dettaglioPartenza.bambiniGratisTxt;
      this.quotaComprende = this.dataResponse.dettaglioPartenza.quotaComprende;
      this.noteQuotaComprende = this.dataResponse.dettaglioPartenza.noteQuotaComprende;

      if (this.dataResponse.dettaglioPartenza.isHPromoFlashAlpitour && this.isMobileDevice) {
        document.querySelector('html').scroll({top: 0, left: 0, behavior: 'smooth'});
        setTimeout(
          () => {
            this.modalSrv.openPromoFlashModal(this.dataResponse.dettaglioPartenza.lblScontoAlpitour);
          }, 2000
        )
      }

      if (this.dataDescPartenza.descrizioneStruttura != null) {
        this.showStructureTab = true;
        this.photosType = 'Struttura';
      }
      if (!(this.dataDescPartenza.itinerario == "" || this.dataDescPartenza.itinerario == null)) {
        this.showItineraryTab = true;
      }
      this.listaFotoStruttura = this.dataDescPartenza.listaFotoStruttura;
      this.userData = this.authSrv.getUser();
      this.preventivoRq = {
        costiCamere: [],
        camereLive: [],
        idPartenza: this.idPartenza,
        numCamere: 1,
        scontoFlash: this.dataResponse.dettaglioPartenza.isHPromoFlashAlpitour ? this.dataResponse.dettaglioPartenza.lblScontoAlpitour : 0
      };

      if (this.promozioneAttiva) {
        this.bannerSrv.showHideBanner(false);
      }

    } else {
      this.modalSrv.openErrorDialog("Si è verificato un errore, ci scusiamo per l'incoveniente.");
    }
    moment.locale('it');
    if (moment(this.dataResponse.dettaglioPartenza.lblDataPartenza, ["LL"]).format() < moment().format()) {
      let dataParamModal = {
        urlG: this.makeUrlSeoHref()
      };
      this.modalSrv.openInfoModal(dataParamModal);
    }
    if (NavigationsUtilsService.getLastState() && NavigationsUtilsService.getLastState().indexOf('/risultati?') !== -1) {
      this.urlRisultatiRicerca = NavigationsUtilsService.parseParams(NavigationsUtilsService.getLastState());
    }

    if (this.dataResponse.dettaglioPartenza.isLuogoAereoporto) {
      this.nameIconDeparturePlace = 'flight';
    }

    if (this.dataResponse.dettaglioPartenza.isLuogoBus) {
      this.nameIconDeparturePlace = 'bus';
    }

    if (this.dataResponse.dettaglioPartenza.isLuogoPorto) {
      this.nameIconDeparturePlace = 'yacht';
    }

    if (this.dataResponse.dettaglioPartenza.isLuogoSoloSoggiorno) {
      this.nameIconDeparturePlace = 'directions_car';
    }
  }

  public splitTitleData(titleString) {
    let strings = titleString.trim().split('-');
    return {
      nomeStrutturaOfferta: strings[0],
      destinazione: strings[1],
      nazione: strings[2],
      luogoPartenza: strings[2]
    };
  }

  public getCheckoutUrl(idPreventivo) {
    return this.router.url + /preventivo/ + idPreventivo + '/checkout';
  }

  private getTimeDifference() {
    this.timeDifference = new Date(this.dataFinePromozione).getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute));
    //this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    //this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnInit() {
    if (this.dataResponse && this.dataResponse.dettaglioPartenza) {
      this.titleService.setTitle(this.dataResponse.dettaglioPartenza.title);
      this.metaTagService.updateTag(
        {name: 'title', content: this.dataResponse.dettaglioPartenza.title},
      );
      this.metaTagService.updateTag(
        {property: 'og:title', content: this.dataResponse.dettaglioPartenza.title},
      );
      let imgOg = this.galleryImagesDestinazione && this.galleryImagesDestinazione.length > 0 ? this.galleryImagesDestinazione[0].big :
        this.galleryImagesStruttura && this.galleryImagesStruttura.length > 0 ? this.galleryImagesStruttura[0].big : window.location.origin + "/assets/img/logo-borsaviaggi.png";
      this.metaTagService.updateTag(
        {property: 'og:image', content: <string>imgOg}
      );
      this.metaTagService.updateTag(
        {property: 'og:description', content: this.dataResponse.dettaglioPartenza.metaDescription},
      );
      this.metaTagService.updateTag(
        {name: 'description', content: this.dataResponse.dettaglioPartenza.metaDescription},
      );
      if (this.dataResponse.dettaglioPartenza.isHPartenzaLive && this.dataResponse.dettaglioPartenza.isHPartenzaValida) {
        this.getCostiPartenza();
      } else if (!this.dataResponse.dettaglioPartenza.isHPartenzaValida) {
        let dataParamModal = {
          urlG: this.makeUrlSeoHref()
        };
        this.modalSrv.openInfoModal(dataParamModal);
      }
      if (this.dataResponse.voli && this.dataResponse.voli.compagniaAndata && this.dataResponse.dettaglioPartenza.isLuogoAereoporto) {
        this.buildDataSource(this.dataResponse.voli)
      }
      setTimeout(() => {
        this.isFlashExpanded = true;
      }, 2000);

      if (this.promozioneAttiva && isPlatformBrowser(this.platformId)) {
        this.subscription = interval(1000)
          .subscribe(x => {
            this.getTimeDifference();
          });
      }
    } else {
      this.modalSrv.openErrorDialog("Si è verificato un errore, ci scusiamo per l'incoveniente.");
    }
  }

  private buildDataSource(voli) {
    let ds = [];
    ds.push({
      compagnia: voli.compagniaAndata,
      luogoP: voli.luogoPartenzaAndata,
      dataP: voli.dataPartenzaAndata,
      scaloP: voli.scaloAndata,
      luogoAr: voli.luogoArrivoAndata,
      dataAr: voli.dataArrivoAndata
    });
    ds.push({
      compagnia: voli.compagniaRitorno,
      luogoP: voli.luogoPartenzaRitorno,
      dataP: voli.dataPartenzaRientro,
      scaloP: voli.scaloRientro,
      luogoAr: voli.luogoArrivoRitorno,
      dataAr: voli.dataArrivoRientro
    });

    this.dataSource = new MatTableDataSource(ds)
  }

  private filterBannerCampagneFormat(dataCampagne) {
    return dataCampagne ? dataCampagne.filter(
      (bannerCampagna) => {
        return ImageUtilService.supportWebP() ? bannerCampagna.isWebp : !bannerCampagna.isWebp
      }
    ) : [];
  }

  public toggleAccordionPrime(opened) {
    if (this.isMobileDevice && opened) {
      document.querySelector('body').classList.add("overflow-hidden");
    } else if (this.isMobileDevice) {
      document.querySelector('body').classList.remove("overflow-hidden");
    }
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = opened ? 'none' : 'block';
    }
  }

  private setGalleryOptions() {
    this.galleryOptions = [
      {
        "imageAutoPlay": true,
        "previewZoom": true,
        "previewCloseOnEsc": true,
        "imageAutoPlayPauseOnHover": true,
        "previewAutoPlay": true,
        "previewAutoPlayPauseOnHover": true,
        thumbnails: true,
        preview: true,
        width: '100%',
        height: '18vh',
        imageInfinityMove: true,
        "thumbnailsRemainingCount": true,
        "image": false,
      },
      {
        "breakpoint": 768,
        "width": "100%",
        "thumbnailsColumns": 1,
        imageSwipe: true,
        thumbnailsSwipe: true,
        previewCloseOnClick: true,
        "thumbnailsRemainingCount": false,
        "image": false
      },
      {
        "breakpoint": 992,
        "width": "100%",
        "height": "200px",
        imageSwipe: true,
        thumbnailsSwipe: true,
        previewCloseOnClick: true,
        "thumbnailsColumns": 2
      },
      {"breakpoint": 1200, "width": "100%", "height": "200px", previewCloseOnClick: true, "thumbnailsColumns": 3},

    ]
  }

  private populateGallery(listaFoto, galleryImgArr) {
    if (listaFoto && galleryImgArr) {
      listaFoto.map((currFoto) => {
        galleryImgArr.push(
          {
            small: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
            medium: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
            big: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
            description: currFoto.titolo
          }
        );
      });
    }
  }

  public makeUrlSeoHref() {
    //VACANZA
    let ret = SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    ret += this.splittedTitle.nazione + "/";
    ret += this.splittedTitle.destinazione + "/";
    ret += this.splittedTitle.nomeStrutturaOfferta + "/";

    ret += "g" + "/";
    ret += this.dataResponse.layoutPartenza.ucDettaglioOfferta1IdOfferta;


    return ret.toLowerCase().replace(/\s/g, "-");
  }

  public getTipologieCamera(callback?) {
    let dataParam = {
      "camereLive": [{
        "adulti": 2,
        "bambini": 0,
        "progressivo": 1
      }],
      "costoTotaleLive": "",
      "costoTotaleLiveOriginale": "",
      "idPartenza": this.idPartenza,
      "luogoPartenza": this.dataResponse.dettaglioPartenza.luogoNome,
      "isPartenzaPrime": this.dataResponse.dettaglioPartenza.isPartenzaPrime,
      "notti": this.dataResponse.dettaglioPartenza.nottiPartenza
    };

    this.destinazioneSrv.getCamereLive(dataParam).then(
      (response) => {
        if (ResponseCheckerService.isSuccessResponse(response.body)) {
          this.preventivoRq.camereLive.push({
            "adulti": 2,
            "bambini": 0,
            id: 1,
            supplementiTipoCamera: []
          });
          let def = [];
          response.body.data.listaCamerePartenzaLive[0].listaSupplementiTipoCameraLive.forEach((supp) => {
            if (supp.codiceCamera === this.costiLive.codiceCameraLive) {
              def.push(supp)
            }
          });
          this.preventivoRq.camereLive[0].supplementiTipoCamera = def;
          this.loadingCamere = false;

        } else {
          console.error(ResponseCheckerService.getErrorMessage(response.body));
          this.loadingCamere = false;
        }
      },
      (error) => {
        console.error("Errore Verifica disponibilità: " + error);
        this.loadingCamere = false;

      }
    ).finally(
      () => {
        if (callback) {
          callback();
        }
      }
    )
  }

  public getCostiPartenza() {
    this.loadingCamere = true;
    this.prezzoLoading = true;
    let dataParamModal = {
      urlG: this.makeUrlSeoHref()
    };
    this.destinazioneSrv.getCostiPartenzaLive(this.idPartenza).then(
      (response) => {
        if (ResponseCheckerService.isSuccessResponse(response.body)) {
          this.costiLive = response.body.data;
          this.totaleLiveDef = response.body.data.isPartenzaPrime ? (response.body.data.costoTotaleConPrime * 2) : (response.body.data.costoTotaleLive * 2);
          this.totaleLiveDef === 0 ? this.partenzaNonDisp = true : this.getTipologieCamera(() => {
            this.prezzoLoading = false;
          });
          this.importoBuonScontoPrime = this.destinazioneSrv.getImportoBuonoSconto(this.totaleLiveDef);
          if (this.partenzaNonDisp) {
            this.modalSrv.openInfoModal(dataParamModal);
          }
        } else {
          console.error(ResponseCheckerService.getErrorMessage(response.body));
          this.partenzaNonDisp = true;
          this.modalSrv.openInfoModal(dataParamModal);
        }
      },
      (error) => {
        console.error("Errore Recupero costi: " + error);
        this.partenzaNonDisp = true;
        this.modalSrv.openInfoModal(dataParamModal);

      }
    )
  }

  public openModificaOccupazioneModal() {
    if (this.costiLive || !this.dataResponse.dettaglioPartenza.isHPartenzaLive) {
      let paramData = this;
      this.modalSrv.openModificaOccupazioneModal(paramData, '', (data) => {
        if (data && data.dataParam) {
          this.idCameraSingolaSelected = data.dataParam.idCameraSingolaSelected;
          this.totaleSupplementi = data.totaleSupplementi;
          this.preventivoRq = data.dataParam;
          this.formGroupCamere = data.formGroup;
          this.preventivoRq.costiCamere = this.dataResponse.dettaglioPartenza.isHPartenzaLive ? data.dataParam.camereLive : data.dataParam.costiCamere;
          this.risparmioPrime = this.destinazioneSrv.getSommaVantaggiPrime(this.dataResponse, this.preventivoRq.costiCamere, this.calcolaPrezzoTotale());
          this.importoBuonScontoPrime = this.destinazioneSrv.getImportoBuonoSconto(this.calcolaPrezzoTotale());
          if (this.dataResponse.dettaglioPartenza.lblScontoAlpitour && this.dataResponse.dettaglioPartenza.isHPromoFlashAlpitour) {
            this.dataResponse.dettaglioPartenza.lblScontoAlpitour = this.destinazioneSrv.calcolaScontoFlash(this.totaleSupplementi);
            this.preventivoRq.scontoFlash = this.dataResponse.dettaglioPartenza.lblScontoAlpitour;
          }
        }
      });
    }
  }

  public savePreventivo() {
    if (!this.loadingCamere) {
      this.destinazioneSrv.savePreventivo(this.preventivoRq).then(
        (response) => {
          if (ResponseCheckerService.isSuccessResponse(response.body)) {
            this.router.navigate([this.getCheckoutUrl(response.body.data.idPreventivoOnLine)]);

          } else {
            console.error(ResponseCheckerService.getErrorMessage(response.body));
          }

        },
        (error) => {
          console.error("Errore Salvataggio preventivo: " + error);
        }
      )
    }
  }


  public setTabContext(event) {
    this.photosType = event.tab.textLabel === 'Informazioni Volo' ? 'Destinazione' : event.tab.textLabel;
  }

  public onTabClick() {
    console.log('onTabClick --> readMore()');
    this.readMoreComponent.readMore()
  }

  public calcolaPrezzoTotale() {
    let prezzoNoSimbol = this.destinazioneSrv.convertLblTotale(this.dataResponse.dettaglioPartenza.lblTotaleConPrime);
    if (this.totaleSupplementi === 0) {
      return this.preventivoRq.costiCamere.length > 0 ? prezzoNoSimbol * this.preventivoRq.costiCamere.length : prezzoNoSimbol;
    } else {
      let totaleConSupplementi = 0;
      this.preventivoRq.costiCamere.forEach((camera) => {
        if (camera.supplementiTipoCamera.length > 0) {
          if (!camera.supplementiTipoCamera[0].isSingola) {
            totaleConSupplementi = totaleConSupplementi + prezzoNoSimbol
          }
          camera.supplementiTipoCamera.forEach((supplemento) => {
            totaleConSupplementi = supplemento.costoCamera ? supplemento.costoCamera : totaleConSupplementi + supplemento.costo
          })
        } else {
          totaleConSupplementi = totaleConSupplementi + prezzoNoSimbol
        }

      });
      return totaleConSupplementi
    }
  }

  public calcolaPrezzoAPersona(dettaglioP) {
    let prezzoTotNoSimbol = dettaglioP.lblTotaleConPrime.replace('€', '').replace('&euro;', '');
    return (parseInt(prezzoTotNoSimbol.replace('.', '')) + this.totaleSupplementi) / 2
  }


  public getPrezzoCamera(camera, isLive) {
    let prezzoNoSimbol = (this.dataResponse.dettaglioPartenza.lblTotaleConPrime.substring(0, this.dataResponse.dettaglioPartenza.lblTotaleConPrime.indexOf(',')));
    prezzoNoSimbol = parseInt(prezzoNoSimbol.replace('.', ''));
    if (camera.supplementiTipoCamera.length === 0) {
      return prezzoNoSimbol
    } else {
      if (!isLive) {
        let totaleCamera = 0;
        camera.supplementiTipoCamera.forEach((supplemento) => {
          totaleCamera = totaleCamera + supplemento.costo
        });
        return camera.supplementiTipoCamera[0].isSingola ? totaleCamera : totaleCamera + prezzoNoSimbol
      } else {
        return camera.supplementiTipoCamera[0].costoCamera
      }
    }
  }


  public richiediInfo(valid) {
    if (valid) {
      let dataParam = {
        "email": this.formGroup.controls['email'].value,
        "cognome": this.formGroup.controls['cognome'].value,
        "nome": this.formGroup.controls['nome'].value,
        "cellulare": this.formGroup.controls['cellulare'].value,
        "idPartenza": this.idPartenza,
        "isPartenzaLive": this.dataResponse.dettaglioPartenza.isHPartenzaLive,
        "costiCamere": this.preventivoRq.costiCamere,
        "camereLive": this.preventivoRq.camereLive,
        "isSecondaSettimana": this.preventivoRq.isSecondaSettimana
      };
      this.destinazioneSrv.contattaci(dataParam).then(
        (response) => {
          this.modalSrv.openFeedbackModal({success: "Messaggio inviato con successo"});
        },
        (error) => {
          this.modalSrv.openErrorDialog();
        }
      ).finally(
        () => {
          this.formGroup.reset();
          this.formGroup.markAsPristine();
          this.formGroup.markAsUntouched();
        }
      );
    } else {
      for (let formControlName in this.formGroup.controls) {
        this.formGroup.get(formControlName).markAsTouched();
        this.formGroup.get(formControlName).markAsDirty();
      }
    }
  }

  public toggleShowTiServeAiuto() {
    this.showTiServeAiuto = !this.showTiServeAiuto;
  }

  public onClickShare(event) {
    if (this.isMobileDevice) {
      this.openShare = !this.openShare;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
