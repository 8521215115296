import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DestinazioneService} from "../../../services/gate/destinazione.service";

interface DepartureInfo {
  id: string,
  name: string,
  type: string
}


@Component({
  selector: 'borsaviaggi-filtro-partenze',
  templateUrl: './filtro-partenze.component.html',
  styleUrls: ['./filtro-partenze.component.scss']
})
export class FiltroPartenzeComponent implements OnInit {
  @Input() objData;
  /*It is an object with the following properties : {
    destinations: comma separated strings, the list of destination ids
    callerInfo: a string with the name of the caller component
  };
  */

  public dateRange = [
    {value: '3', text: '+/- 3 gg'},
    {value: '7', text: '+/- 7 gg'}
  ];

  public filterForm = new FormGroup({
    data: new FormControl('', Validators.required),
    tolerance: new FormControl(this.dateRange[0].value, Validators.required),
    transportMode: new FormControl('', Validators.required)
  });

  public luoghiPartenzaDisponibili: any;
  public optionsForMSelect: DepartureInfo[]; //option objects for multi-select
  public selectedOptions: DepartureInfo[]; //list of selected transport modes
  public selectedOptionsView: any; //list of transport names to view in the select

  public sentiment: string = "";

  constructor(private destSrv: DestinazioneService)
  {

  }

  ngOnInit() {
    this.filterForm.get('transportMode').disable();
  }

  private resetSelectFields() {
    this.optionsForMSelect = [];
    this.selectedOptions = [];
    this.selectedOptionsView = [];

    this.filterForm.get('transportMode').setValue('');
    this.filterForm.get('transportMode').disable();
  }

  public buildMSelectOptions(data) {
    let optionsArray = [];
    if(data.length>0){
      this.sentiment = "sentiment_very_satisfied";
      this.filterForm.get('transportMode').enable();
    }
    else{
      this.sentiment = "sentiment_very_dissatisfied";
    }
    data.forEach(elem => {
      let optionsForSelect = <DepartureInfo>{
        id: elem.idLuogoPartenza,
        name: elem.nome,
        type: this.getDepartureIcon(elem)
      };
      optionsArray.push(optionsForSelect);
    });

    this.optionsForMSelect = optionsArray;
  }

  public getDepartureIcon(obj){
    let icon_name;
    if(obj.isAeroporto){
      icon_name = 'flight';
    }
    else if(obj.isBus){
      icon_name = 'directions_bus';
    }
    else if(obj.isPorto){
      icon_name = 'directions_boat';
    }
    else if(obj.isSoloSoggiorno){
      icon_name = 'directions_car';
    }
    else{
      icon_name = '';
    }
    return icon_name;
  }

  public setSelectTextbox() {
    this.selectedOptionsView = [];
    let nameList = [];
    this.selectedOptions.map(function (val) {
      nameList.push(val.name);
    });
    this.selectedOptionsView = nameList;
  }

  public isFilterHidden(): boolean {
    if (this.filterForm.get('transportMode').enabled && this.filterForm.valid) {
      return false;
    }
    return true;
  }

  public getAirports() {
    let tolerance = this.filterForm.get('tolerance').value;
    let serializedDate = "";
    if (this.filterForm.get('data').value) {
      let date = new Date(this.filterForm.get('data').value);
      serializedDate = date.toISOString();
      let request = {
        "dataPartenza": serializedDate,
        "destinazioni": this.objData.destinations,
        "idDestinazioneTop": 0,
        "tolleranza": tolerance
      };

      this.destSrv.getAeroportiDisponibili(request).then(
        (response) => {
          this.resetSelectFields();

          if (response && response.body.status === 0) {
            if (response.body.data.luoghiPartenzaDisponibili) { //available transports
              this.luoghiPartenzaDisponibili = response.body.data.luoghiPartenzaDisponibili;
              this.buildMSelectOptions(this.luoghiPartenzaDisponibili);
              this.sentiment = "sentiment_very_satisfied";
              this.filterForm.get('transportMode').enable();
            } else {
              console.log("Nessun aereoporto disponibile");
              this.sentiment = "sentiment_very_dissatisfied";
            }
          } else {
            this.sentiment = "sentiment_very_dissatisfied";
          }
        },
        (error) => {
          console.log("Errore chiamata a ws getAeroportiDisponibili", error);
        }
      )
    }

  }

  public filter() {
    let data = this.filterForm.get('data').value;
    let tolerance = this.filterForm.get('tolerance').value;
    //let selectedTranports = this.filterForm.get('transportMode').value; //name list
    let selectedTranports = this.selectedOptions; //object list
    //TODO service call
    console.log(this.selectedOptions);
  }

}
