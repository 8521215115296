<div class="background">
  <div class="container">
    <div class="search-results-bar">
      <div class="search-result"></div>
      <div class="search-result"></div>
      <div class="search-result"></div>
      <div class="search-result"></div>
      <div class="search-result"></div>
      <div class="search-result"></div>
      <div class="search-result-btn"></div>
    </div>
    <div class="g-card">
      <div class="title-container">
        <div class="main-section full-width">
          <div class="title-wrapper">
            <h1 class="title">{{strutturaData.nome | titlecase}}</h1> <!-- Wady Lahmi Azur Resort-->
            <div class="icon d-inline-block d-sm-none">
              <borsaviaggi-share-button></borsaviaggi-share-button>
            </div>
          </div>
          <div class="subtitle-wrapper">
            <h3 class="subtitle">{{strutturaData.nomeLocalita | titlecase}}
              ,&nbsp;{{strutturaData.nomeLocalitaPadre | titlecase}}</h3> <!-- Berenice, Egitto -->
            <borsaviaggi-mostra-mappa class="ml-15px display-inline-block"
                                      [luogo]="strutturaData.nome "
                                      [nazione]="strutturaData.nomeLocalita"></borsaviaggi-mostra-mappa>
          </div>
        </div>
        <div class="side-section">
          <div class="side-top-wrapper d-none d-sm-inline-block">
            <borsaviaggi-share-button></borsaviaggi-share-button>
          </div>
          <div class="side-bottom-wrapper d-none d-sm-inline-block">
            <div class="text">

            </div>
          </div>
        </div>
      </div>
      <div class="codice-offerta-xs d-block d-sm-none">

      </div>
      <div class="separator"></div>

      <div class="photo-container">

        <!-- GALLERY -->

        <div class="photo-wrapper">
          <div class="row">
            <div class="col-12 gallery-wrapper"> <!-- full-width-->
              <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
            </div>
          </div>
        </div>

        <!-- END OF: GALLERY -->

      </div>
      <div class="info-type-bar">
      </div>
      <div class="description-container overflow-auto">
        <div class="div-descrizione px-2 py-4" [innerHTML]="strutturaData.descrizione | safeHtml"></div>
        <!--borsaviaggi-readmore [content]="strutturaData.descrizione"></borsaviaggi-readmore-->
      </div>

      <br/><br/>
      <borsaviaggi-loader [show]="!listaPartenzeOfferta" typeMode="piker"></borsaviaggi-loader>
      <div class="text-align-center" *ngIf="showErrorDettaglioZ ||
        (dataStruttura && !dataStruttura.body.data.listaOfferteStruttura.length)">Siamo
        spiacenti. Al
        momento non abbiamo offerte attive su questa struttura.
        Scopri le migliori offerte Borsaviaggi sulla destinazione attuale utilizzando il motore.
      </div>

      <mat-accordion [multi]="true">
        <mat-expansion-panel *ngFor="let offerta of listaPartenzeOfferta" [expanded]="true">
          <mat-expansion-panel-header class="prenota2 right-aligned-header" [collapsedHeight]="''"
                                      [expandedHeight]="''">
            <mat-panel-title>
              <div class="mobile-font-1-2">{{offerta.titolo}}</div>
            </mat-panel-title>
            <mat-panel-description>
              <div class="d-none d-sm-inline-block mobile-font-1-2">Codice Offerta: G{{offerta.idOfferta}}</div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="mat-elevation-z8 bv-table overflow-auto">
            <table mat-table [dataSource]="offerta.listaPartenze">
              <ng-container matColumnDef="partenzaDa">
                <th mat-header-cell *matHeaderCellDef > Partenza Da</th>
                <td mat-cell *matCellDef="let element"> {{element.luogoPartenza | titlecase}} </td>
              </ng-container>
              <ng-container matColumnDef="dataPartenza">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Data</th>
                <td mat-cell *matCellDef="let element"
                    class="d-none d-sm-table-cell"> dal {{element.dataPartenzadal | date :'dd/MM/yyyy'}}
                  al {{element.dataPartenzaal | date :'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="giorniNotti">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Giorni/Notti1</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> {{element.giorniNotti}} </td>
              </ng-container>
              <ng-container matColumnDef="trattamento">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Trattamento</th>
                <td mat-cell *matCellDef="let element"
                    class="d-none d-sm-table-cell"> {{element.trattamento | titlecase}}</td>
              </ng-container>
              <ng-container matColumnDef="prezzo">
                <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                <td mat-cell *matCellDef="let element" class="bold ws-nowrap"> da {{element.costoTotaleMin}} €</td>
              </ng-container>
              <ng-container matColumnDef="prime">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Offerta</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                  <img alt="partenza prime"
                       *ngIf="element.isPartenzaPrime" src="../../../assets/img/borsaviaggi_prime.png" height="25"
                       width="100"/></td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <a [routerLink]="element.url"
                     [title]="'Offerta lastminute '+element.nazione+' '+ element.destinazione+' '+element.struttura">
                    <button class="prenota"
                            mat-raised-button>{{element.costoTotaleMin ? 'VAI' : 'Fai Preventivo'}}</button>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-left"></tr>
            </table>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>


</div>
