<div class="container border-r-2px mt-20px text-align-justify background-white pb-20px mb-20px">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi">Dai un voto a Borsaviaggi.it e aiutaci a migliorare il servizio!</h1>
    </div>
  </div>

  <div class="raiting-container">
    <div class="row mt-40px border-top border-bottom">
      <div class="col mt-15px">
          <span>Al rapporto QUALITÀ PREZZO delle OFFERTE: </span>
      </div>
      <div class="col">
        <borsaviaggi-rating starCount="5" [resetRating]="resetRating" (ratingUpdated)="cambioPunteggioQualitaPrezzo($event)"></borsaviaggi-rating>
      </div>
    </div>
    <div class="row mt-40px border-top border-bottom">
      <div class="col mt-15px">
        <span>Alla QUALITÀ del servizio dei nostri CONSULENTI IN LINEA: </span>
      </div>
      <div class="col">
        <borsaviaggi-rating starCount="5" [resetRating]="resetRating" (ratingUpdated)="cambioPunteggioConsulenti($event)"></borsaviaggi-rating>
      </div>
    </div>
    <div class="row mt-40px border-top border-bottom">
      <div class="col mt-15px">
        <span>Alla VESTE GRAFICA: </span>
      </div>
      <div class="col">
        <borsaviaggi-rating starCount="5" [resetRating]="resetRating" (ratingUpdated)="cambioPunteggioGrafica($event)"></borsaviaggi-rating>
      </div>
    </div>
    <div class="row mt-40px border-top border-bottom">
      <div class="col mt-15px">
        <span>Alla FACILITÀ di CONSULTAZIONE del sito: </span>
      </div>
      <div class="col">
        <borsaviaggi-rating starCount="5" [resetRating]="resetRating" (ratingUpdated)="cambioPunteggioConsultazione($event)"></borsaviaggi-rating>
      </div>
    </div>
    <div class="row mt-40px border-top border-bottom">
      <div class="col mt-15px">
        <span>alla VELOCITÀ di CARICAMENTO delle pagine: </span>
      </div>
      <div class="col">
        <borsaviaggi-rating starCount="5" [resetRating]="resetRating"  (ratingUpdated)="cambioPunteggioCaricamento($event)"></borsaviaggi-rating>
      </div>
    </div>
    <form [formGroup]="formGroup" class="mb-20px">
    <div class="row  mt-40px">
      <div class="col">
        <span>Nello spazio che segue puoi scrivere liberamente i commenti e i consigli che ritieni opportuno segnalarci (facoltativo) </span>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Messaggio</mat-label>
          <textarea matInput formControlName="messaggio"></textarea>
        </mat-form-field>
      </div>
    </div>
      <div class="row  mt-40px">
        <div class="col">
          <span>E-mail Mittente (facoltativa) </span>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" class="campi-input">
            <mat-label>La tua email</mat-label>
            <input type="email" matInput formControlName="email">
            <mat-error *ngIf="formGroup.get('email').invalid">
              <div>
                Email non valida
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-40px">
        <div class="col text-align-center">
          <borsaviaggi-promise-btn
            type="submit"
            [loading]="loading"
            buttonText="Invia"
            (buttonClick)="send(formGroup.valid)"
            [disabled]="(grafica == 0 || qualitaPrezzo == 0 || consulenti == 0 || facilitaConsultazione == 0
                              || velocitaCaricamento == 0) || !formGroup.valid"
            buttonClass=" no-gutters padding-0 background-blue-borsaviaggi color-white">
          </borsaviaggi-promise-btn>
        </div>
      </div>
    </form>
  </div>
</div>

