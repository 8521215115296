import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CacheService} from "../../services/common/cache.service";
import {CACHE_KEY} from "../../constants/const.service";
import {HomeService} from "../../services/gate/home.service";
import {MacroBoxEnum} from "../../components/macro-box/config-layout.enum";
import {ResponseCheckerService} from "../../services/common/response.checker.service";
import {Meta, Title} from "@angular/platform-browser";
import {SEO_PAGE} from "../../constants/seo.const";
import {ImageUtilService} from "../../services/common/image.util.service";

@Component({
  selector: 'borsaviaggi-dettaglio-partners',
  templateUrl: './dettaglio-partners.component.html',
  styleUrls: ['./dettaglio-partners.component.scss']
})
export class DettaglioPartnersComponent implements OnInit {
  public listaStrutturePartner;
  public listaDestinazioniPartner;
  public listPartners;
  public dettaglioPartner;

  constructor(private route: ActivatedRoute,
              private cacheSrv: CacheService,
              private homeSrv: HomeService,
              private titleService: Title,
              private metaTagService: Meta,
              private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    if (ResponseCheckerService.isSuccessResponse(this.route.snapshot.data.dataPartner.body)) {
      this.populatePartnerData(this.route.snapshot.data.dataPartner);
      this.listPartners = this.cacheSrv.getCacheData(CACHE_KEY.PARTNER_LIST)

    } else {
      //TODO gestire errore
    }


  }

  private pupolateSeoTag() {
    let title = this.dettaglioPartner.nomeTourOperator + " " + this.dettaglioPartner.title + " - Offerte viaggi Lastminute";
    this.titleService.setTitle(title);
    this.metaTagService.updateTag(
      {name: 'title', content: title},
    );
    this.metaTagService.updateTag(
      {property: 'og:title', content: title},
    );
    this.metaTagService.updateTag(
      {property: 'og:image', content: this.dettaglioPartner.urlFoto}
    );
    this.metaTagService.updateTag(
      {property: 'og:description', content: title + " " + this.dettaglioPartner.testo},
    );
    this.metaTagService.updateTag(
      {name: 'description', content: title + " " + this.dettaglioPartner.testo},
    );
  }

  public makeUrlSeoHref(partner, element, data_type) {
    if (data_type == "struttura") {
      let ret = SEO_PAGE.DETTAGLIO_STRUTTURA + "/";
      ret += partner.nomeTourOperator + "/";
      ret += element.nomeStruttura + "/";
      ret += Number(element.idStruttura) + "/";
      return ret.toLowerCase().replace(/\s/g, "-");
    } else if (data_type == "destinazione") {
      let ret = SEO_PAGE.DETTAGLIO_DESTINAZIONE + "/";
      ret += partner.nomeTourOperator + "/";
      ret += element.nome + "/";
      ret += partner.idTo + "/";
      ret += element.idDestinazione + "/";

      return ret.toLowerCase();
    } else {
      console.log("Invalid data type");
    }

  }

  ngOnInit() {
  }

  public getDettaglioPartner() {
    this.listPartners.forEach((element) => {
      if (element.idPartner.toString() === this.route.snapshot.params.idTo) {
        this.dettaglioPartner = element;
        this.pupolateSeoTag();
      }

    });
  }

  public populatePartnerData(data) {
    this.listaDestinazioniPartner = data.body.data.listaDestinazioniPartner;
    let struttureByNazione = data.body.data.listaStrutturePartner.reduce((r, a) => {
      r[a.nazione] = [...r[a.nazione] || [], a];
      return r;
    }, {});
    this.listaStrutturePartner = Object.values(struttureByNazione);

    if (this.listPartners) {
      this.getDettaglioPartner();
    } else {
      this.getHomeData()
    }
  }

  public mapDestinazioni(destinazione) {
    let configObj = {};
    configObj['titolo'] = destinazione.nome;
    configObj['titoloHref'] = this.dettaglioPartner.title + " " + destinazione.nome;
    configObj['boxConfigType'] = MacroBoxEnum.MACRO_VERTICAL_BOX;
    configObj['imgBackground'] = ImageUtilService.supportWebP() ? destinazione.urlFotoWebp : destinazione.urlFoto;
    configObj['btnUrl'] = "/" + this.makeUrlSeoHref(this.dettaglioPartner, destinazione, 'destinazione');

    return configObj

  }

  private getHomeData() {
    this.homeSrv.getHomeData().then(
      (response) => {
        if (response && response.body) {
          this.cacheSrv.setCacheData(CACHE_KEY.PARTNER_LIST, response.body.data.listaNostriPartner, 0);
          this.listPartners = response.body.data.listaNostriPartner;
          this.getDettaglioPartner()
        }
      },
      (errror) => {
        console.error("Errore caricamento home data", errror);
      }
    )
  }


}
