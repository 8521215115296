import {Component, OnDestroy, OnInit} from '@angular/core';
import {MacroDestinazioneService} from "../../services/gate/macro-destinazione.service";
import {MacroBoxInterface} from "../../components/macro-box/config.interface";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoaderService} from "../../components/loader/loader.service";
import {Meta, Title} from "@angular/platform-browser";
import {UtilsStringService} from "../../services/common/utils-string.service";
import {SEO_PAGE} from "../../constants/seo.const";
import {Subscription} from "rxjs";


@Component({
  selector: 'borsaviaggi-macro-destinazione-page',
  templateUrl: './macro-destinazione-page.component.html',
  styleUrls: ['./macro-destinazione-page.component.scss']
})

export class MacroDestinazionePageComponent implements OnInit, OnDestroy {
  public objectKeys = Object.keys;
  public dataResponse: any;
  public listSelezioniDestinazioni: Array<MacroBoxInterface>;
  public dataDestinazioni: any;
  public filterForm: FormGroup;
  public listSelezioniMesi: any;
  public idSelection;
  public destinations;
  public year;
  public month;
  public displayedColumns: any;
  public filterClick: any;
  public panelTitle;
  public modelSearch: {
    destinazione: { list: Array<any> },
    aeroporto: { list: Array<any> },
    nazioni: { list: Array<any> }
  };
  public showTable;
  public disableButton;
  public dataTable;
  public routerParams;

  public navigationSubscription: Subscription;

  constructor(private macroDestSrv: MacroDestinazioneService,
              private router: Router,
              private titleService: Title,
              private metaTagService: Meta,
              private loaderSrv: LoaderService,
              private route: ActivatedRoute) {

    this.displayedColumns = [
      'codicePartenza',
      'dataPartenza',
      'giorniNotti',
      'trattamento',
      'icons',
      'prime',
      'prezzo',
      'listaDesideri',
      'actions'
    ];
    this.disableButton = true;
    this.dataDestinazioni = [];
    this.listSelezioniDestinazioni = [];
    this.listSelezioniMesi = [];
    this.modelSearch = {
      destinazione: {list: []},
      aeroporto: {list: []},
      nazioni: {list: []}
    };
    this.filterForm = new FormGroup({
      data: new FormControl('', Validators.required),
      destinazione: new FormControl({disabled: true, value: ''}),
      aeroporto: new FormControl({disabled: true, value: ''})
    });
    this.populateSeotag();
    this.populateMacroDestinazioneData(this.route.snapshot.data.dataMacroCategoria);
  }

  ngOnInit() {

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.populateSeotag();
        this.populateMacroDestinazioneData(this.route.snapshot.data.dataMacroCategoria);
      }
    });
  }

  private populateSeotag() {
    if (this.route.snapshot.paramMap.get("titolo-macro")) {
      let seoTitle = UtilsStringService.toTitleCase(this.route.snapshot.paramMap.get("titolo-macro").replace(/-/g, " "));
      this.titleService.setTitle(seoTitle);
      this.metaTagService.updateTag(
        {name: 'title', content: seoTitle},
      );
      this.metaTagService.updateTag(
        {property: 'og:title', content: seoTitle},
      );

    } else {
      let resp = this.route.snapshot.data.dataMacroCategoria.body;
      this.titleService.setTitle(resp.data.titolo);
      this.metaTagService.updateTag(
        {name: 'title', content: resp.data.titolo},
      );
      this.metaTagService.updateTag(
        {property: 'og:title', content: resp.data.titolo},
      );
    }
    this.metaTagService.updateTag(
      {property: 'og:description', content: this.route.snapshot.data.dataMacroCategoria.body.data.descrizione},
    );
    this.metaTagService.updateTag(
      {name: 'description', content: this.route.snapshot.data.dataMacroCategoria.body.data.descrizione},
    );
    this.metaTagService.updateTag(
      {property: 'og:image', content: this.route.snapshot.data.dataMacroCategoria.body.data.urlIcona}
    );

  }

  public populateMacroDestinazioneData(dataResp) {
    this.routerParams = this.route.snapshot.params;
    this.idSelection = this.route.snapshot.paramMap.get("idSelezione");
    this.dataResponse = dataResp.body.data;
    this.dataDestinazioni = dataResp.body.data.listaDestinazioni;
    let titoloMacro = this.routerParams["titolo-macro"] || this.routerParams["nomeDestinazione"];
    let idSelezione = this.routerParams.idSelezione;
    let offertaSelezioneTop = this.router.routerState.snapshot.url.indexOf(SEO_PAGE.DESTINAZIONE_TOP_KEYWORD) !== -1;
    this.listSelezioniDestinazioni = MacroDestinazioneService.macroDestinazioniBoxConfigParser(this.dataDestinazioni, titoloMacro, idSelezione, offertaSelezioneTop);
    this.listSelezioniMesi = dataResp.body.data.listaMesi;
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
