<mat-card class="text-align-left recensioni-card mb-2rem">
  <mat-card-header class="recensioni-card-header">
    <div class="row full-width">
      <div class="col">
        {{recensioniData.autore ? recensioniData.autore : 'Anonimo'}} - {{recensioniData.eta}} anni
      </div>
      <div class="text-align-right col">Recensione inviata il {{recensioniData.data | date: 'dd/MM/yyyy'}}</div>
    </div>
  </mat-card-header>
  <mat-card-content class="recensioni-card-content mt-2rem">
    <div class="row">
      <div class="col-sm-8 commento">
        {{recensioniData.commento}}
      </div>
      <div class="col-sm-3 spinner-container">
        <div class="spinner-box">
          <mat-progress-spinner
            class="spinner"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="ratingPercent">
          </mat-progress-spinner>
        </div>
        <div class="rating-percent">{{ratingPercent}}%</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

