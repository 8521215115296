import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {LoaderService} from "../../../components/loader/loader.service";
import {ImageUtilService} from "../../../services/common/image.util.service";
import {Meta, Title} from "@angular/platform-browser";
import {ContainerDestinazioniService} from "../../../services/gate/container-destinazioni.service";
import {SEO_OFFERTE, SEO_PAGE} from "../../../constants/seo.const";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {isPlatformBrowser} from "@angular/common";


@Component({
  templateUrl: './dettaglio-destinazioni.component.html',
  styleUrls: ['./dettaglio-destinazioni.component.scss']
})
export class DettaglioDestinazioniComponent implements OnInit {

  //router params variables
  public routerParams: any;
  public nomeDestinazione = "";
  public nomeMese = "";
  public idDestinazione;
  public isNazione = false;
  public idLuogo;
  public idMese;
  public lastScrollOffset: number;
  public currentPage: number;
  public loadingValues: boolean;
  public urlParams;
  public elencoTutteOfferte: any;
  public objDataSource = [];
  public luoghiPartenzaFiltro: any;
  public navigationSubscription;
  public isMobileMode: boolean = false;
  public filteredDestinations: Array<any>;

  public displayedColumns: string[] = [
    'partenzaDa',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'prezzo',
    'actions'
  ];
  //grid filter variables
  public months = [
    {id: 1, text: 'Gennaio'},
    {id: 2, text: 'Febbraio'},
    {id: 3, text: 'Marzo'},
    {id: 4, text: 'Aprile'},
    {id: 5, text: 'Maggio'},
    {id: 6, text: 'Giugno'},
    {id: 7, text: 'Luglio'},
    {id: 8, text: 'Agosto'},
    {id: 9, text: 'Settembre'},
    {id: 10, text: 'Ottobre'},
    {id: 11, text: 'Novembre'},
    {id: 12, text: 'Dicembre'}
  ];
  public recurrence = [
    {id: 13, text: 'Natale'},
    {id: 14, text: 'Capodanno'},
    {id: 16, text: 'Pasqua'},
    {id: 17, text: 'Ponte del 25 Aprile'},
    {id: 18, text: 'Ponte del 1 Maggio'},
    {id: 19, text: 'Ponte del 2 Giugno'},
    {id: 15, text: 'Ferragosto'},
    {id: 21, text: 'San Valentino'},
    {id: 20, text: "Ponte dell'Immacolata"}
  ];

  public data = {
    title: 'OFFERTE LAST MINUTE',
    imgUrl: 'assets/img/viaggi-mondo.png',
    text: 'scopri le migliori offerte last minute'
  };

  public configData = {
    destinations: "",
    callerInfo: "DettaglioDestinazioniComponent"
  };


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let scrollElementScroll = document.querySelector('mat-drawer-container').scrollHeight - 1000;
    if (this.objDataSource.length < this.route.snapshot.data.dataDestinazioni.body.data.totaleOfferte &&
      this.lastScrollOffset < scrollElementScroll
      && scrollOffset > scrollElementScroll
      && !this.loadingValues) {
      this.lastScrollOffset = scrollOffset;
      this.currentPage++;
      this.loadMoreOfferte();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileMode = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private deviceService: DeviceDetectorService,
    private cDestSrv: ContainerDestinazioniService,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: string,
    private loaderSrv: LoaderService) {
    this.filteredDestinations = [];
    this.currentPage = 1;
    this.lastScrollOffset = 0;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      //if NavigationEnd event, re-initialize the component
      if (e instanceof NavigationEnd) {
        this.initComponent();
      }
    });

    this.urlParams = this.route.snapshot.url;
    for (let i = 0; i < this.route.snapshot.url.length; i++) {
      this.urlParams[i] = decodeURIComponent(this.route.snapshot.url[i].path);
    }

  }

  ngOnInit() {
    this.configData.destinations = this.idDestinazione;
    this.isMobileMode = this.deviceService.isMobile();
  }

  private initComponent() {
    this.loadRouterParams();
    if (isPlatformBrowser(this.platformId)) {
      this.loadDestinazioni();
    }

    let titolo = "Offerta Last minute - " + this.routerParams.nomeDestinazione;
    titolo = this.routerParams.fDataTxt ? titolo + " " + this.routerParams.fDataTxt : titolo;
    this.titleService.setTitle(titolo);
    this.metaTagService.updateTag(
      {property: 'og:title', content: titolo},
    );
    this.metaTagService.updateTag(
      {name: 'title', content: titolo},
    );

    this.metaTagService.updateTag(
      {property: 'og:image', content: window.location.origin + "/assets/img/logo-borsaviaggi.png"}
    );
    let desc = this.route.snapshot.data.dataDestinazioni.body.data.descrizioneDestinazione;
    if (desc) {
      this.metaTagService.updateTag(
        {property: 'og:description', content: desc.replace(/<[^>]+>/g, '').substring(0, 158)},
      );
      this.metaTagService.updateTag(
        {name: 'description', content: desc.replace(/<[^>]+>/g, '').substring(0, 158)},
      );
    }
  }

  private loadRouterParams() {
    this.routerParams = this.route.snapshot.params;
    this.nomeDestinazione = this.capitalize(this.routerParams.nomeDestinazione);
    this.idDestinazione = this.routerParams.idDestinazione;
    this.isNazione = this.routerParams.infoN == "n";
    this.idLuogo = this.routerParams.fDataType == "lp" ? this.routerParams.fDataId : 0;
    this.idMese = this.routerParams.fDataType == "m" ? this.routerParams.fDataId : 0
  }

  private loadDestinazioni() {
    this.loaderSrv.show();
    this.elencoTutteOfferte = this.route.snapshot.data.dataDestinazioni.body.data.elencoTutteOfferte;
    this.luoghiPartenzaFiltro = this.route.snapshot.data.dataDestinazioni.body.data.listaLuoghiOfferte;
    this.valorizzaDestinazioniMotoreRicerca(this.route.snapshot.data.dataDestinazioni.body.data.destinazioneInOfferte);
    this.makeDataForHtmlComponent();
  }

  private valorizzaDestinazioniMotoreRicerca(arrDestinazioni) {

    arrDestinazioni.map(
      (currDest) => {
        let objDest = {
          idDestinazione: currDest.idDestinazione,
          nomeDestinazione: currDest.nomeDestinazione,
          isNazione: false
        };
        this.filteredDestinations.push(objDest);
      });
  }

  private loadMoreOfferte() {
    this.loadingValues = true;
    let dataParam = {
      "idDestinazione": this.routerParams.idDestinazione,
      "idLuogo": this.routerParams.fDataType == "lp" ? this.routerParams.fDataId : 0,
      "isNazione": this.routerParams.infoN == "n",
      "isItalia": this.routerParams.isItalia == "i",
      "mese": this.routerParams.fDataType == "m" ? this.routerParams.fDataId : 0,
      "pageNumber": this.currentPage,
      "pageSize": 10
    };
    if (this.route.routeConfig.path && this.route.routeConfig.path.indexOf(SEO_PAGE.DESTINAZIONE_TOP_KEYWORD) !== -1) {
      dataParam["idDestinazioneTop"] = this.routerParams.idSelezione;
    }
    this.cDestSrv.getOfferteDestinazione(dataParam).then(
      (dataResp) => {
        if (dataResp.body && dataResp.body.data) {
          dataResp.body.data.elencoTutteOfferte.map(
            (currOfferta) => {
              this.objDataSource.push(this.makeObjOfferta(currOfferta));
            }
          );
        }
      }
    ).finally(
      () => {
        this.loadingValues = false;
      }
    )
  }

  private makeObjOfferta(offerta) {
    let infoOfferta = {
      destinazione: offerta.destinazione,
      idOfferta: offerta.idOfferta,
      idStruttura: offerta.idStruttura,
      nomeStruttura: offerta.nomeStruttura,
      titolo: offerta.titolo
    };

    let images = ImageUtilService.supportWebP() ? offerta.fotosWebp : offerta.fotos;
    let listaPartenze = [];

    offerta.partenzeOfferta.forEach(partenza => {
      let infoPartenza = {
        costoTotaleMinimo: partenza.costoTotaleMinimo,
        al: partenza.al,
        dal: partenza.dal,
        giorniNotti: partenza.giorni + "/" + partenza.notti,
        idLuogoPartenza: partenza.idLuogoPartenza,
        idOfferta: partenza.idOfferta,
        luogoPartenza: partenza.luogoPartenza,
        trattamento: partenza.trattamento,
        url: ""
      };
      infoPartenza.url = this.makePartenzaUrl(infoOfferta, infoPartenza);
      listaPartenze.push(infoPartenza);
    });

    let tabellaPartenze = new MatTableDataSource(listaPartenze);

    return {
      infoOfferta,
      images,
      tabellaPartenze
    };

  }

  private makeDataForHtmlComponent() {
    this.objDataSource = [];
    this.elencoTutteOfferte.forEach(offerta => {
      this.objDataSource.push(this.makeObjOfferta(offerta));
    });
  }

  private makePartenzaUrl(infoOfferta, infoPartenza) {
    let url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    url += this.nomeDestinazione + "/";
    url += "g" + "/";
    url += infoOfferta.idOfferta + "/";
    url += infoPartenza.idLuogoPartenza;

    return url.toLowerCase();
  }

  public makeFilterUrl(type, data) {
    let url = "";
    url += "/" + this.routerParams.nomeDestinazione + "/";
    url += type == 'fromDepLocFilter' ? data.Nome : data.text;
    url += "/OFFERTE-LAST-MINUTE/";
    url += this.routerParams.idDestinazione + "/";
    url += this.routerParams.infoN + "/";
    url += type == 'fromDepLocFilter' ? data.idLuogoPartenza : data.id;
    url += "/";
    url += type == 'fromDepLocFilter' ? 'lp' : 'm';
    url += this.routerParams.isItalia === 'i' ? '/i' : '/m';
    return url.toLowerCase();
  }


  public capitalize = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  };

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
