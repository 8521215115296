<div class="row no-gutters info-partenza-row">
  <div class="key-column text-align-right d-none d-md-inline-block col-md-5 col-lg-5 ">
    <div class="key">{{label}}</div>
  </div>
  <div class="key-column col-6 col-sm-12 d-md-none">
    <div class="key">{{label}}</div>
  </div>
  <div class="value-column col-6 col-sm-6 col-md-7 col-lg-7">
    <div class="value-wrapper">
      <span *ngIf="icon" class="icon">
        <img class="max-width-24px" src="assets/img/icons/{{icon}}.svg">
      </span>
      <div class="value">{{value}}</div>
    </div>
  </div>
</div>
