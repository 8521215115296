<div class="container border-r-2px mt-20px pt-10px  text-align-justify background-white mb-20px pb-20px">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi mt-5px mb-5px text-align-center">Questionario Post Vendita</h1>
    </div>
  </div>
  <div class="w-100" *ngIf="showQuestionario && !isCompilato && !statusOk">
    <form [formGroup]="serviziForm">
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          Ha conosciuto Borsaviaggi attraverso:
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="conosciutoTramite">
            <mat-radio-button value="Internet">Internet</mat-radio-button>
            <mat-radio-button value="Stampa">Stampa</mat-radio-button>
            <mat-radio-button value="Amici/Parenti">Amici/Parenti</mat-radio-button>
            <mat-radio-button value="Consulente">Consulente</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="w-100 mt-20px font-weight-bold titolo-gruppo">
        Esprima il suo giudizio complessivo sul servizio Borsaviaggi
      </div>
      <div class="row mt-10px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          1 - Qualità delle informazioni ed assistenza alla prenotazione<br />
          ricevuta dal Consulente che ha gestito la sua richiesta
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="qualitaPrenotazione">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          2 - Modalità di prenotazione e pagamento
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="modalitaPrenotazione">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          3 - Rapporto qualità / prezzo delle offerte
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="qualitaPrezzoOfferte">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          4 - Suggerimenti
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field class="col col-md-12" appearance="outline">
            <mat-label>Suggerimenti</mat-label>
            <textarea matInput formControlName="suggerimentiServizio"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="w-100 mt-20px font-weight-bold titolo-gruppo">
        Esprima il suo giudizio complessivo sul sito www.borsaviaggi.it
      </div>
      <div class="row mt-10px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          1 - Facilità di consultazione ed esposizione delle offerte
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="consultazioneOfferte">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          2 - Varietà e completezza delle offerte proposte
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="varietaOfferte">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          3 - Velocità di scaricamento pagine
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="velocitaPagine">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          4 - Veste grafica del sito
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-radio-group required aria-label="Seleziona un opzione" formControlName="graficaSito">
            <mat-radio-button value="5">Eccellente</mat-radio-button>
            <mat-radio-button value="4">Buono</mat-radio-button>
            <mat-radio-button value="3">Medio</mat-radio-button>
            <mat-radio-button value="2">Scarso</mat-radio-button>
            <mat-radio-button value="1">Pessimo</mat-radio-button>
            <mat-radio-button value="0">Non saprei</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-xs-12 col-md-6 font-weight-bold">
          5 - Suggerimenti
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field class="col col-md-12" appearance="outline">
            <mat-label>Suggerimenti</mat-label>
            <textarea matInput formControlName="suggerimentiSito"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="w-100" *ngIf="isVacanza">
      <form [formGroup]="vacanzaForm">
        <div class="w-100 mt-20px font-weight-bold titolo-gruppo">
          Esprima il suo giudizio complessivo sulla Vacanza acquistata
        </div>
        <div class="row mt-10px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            1 - Condizioni della struttura ricettiva
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="condizioniStruttura">
              <mat-radio-button value="5">Eccellente</mat-radio-button>
              <mat-radio-button value="4">Buono</mat-radio-button>
              <mat-radio-button value="3">Medio</mat-radio-button>
              <mat-radio-button value="2">Scarso</mat-radio-button>
              <mat-radio-button value="1">Pessimo</mat-radio-button>
              <mat-radio-button value="0">Non saprei</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            2 - Qualita' di cibi e cucina (se previsti)
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="qualitaCiboStruttura">
              <mat-radio-button value="5">Eccellente</mat-radio-button>
              <mat-radio-button value="4">Buono</mat-radio-button>
              <mat-radio-button value="3">Medio</mat-radio-button>
              <mat-radio-button value="2">Scarso</mat-radio-button>
              <mat-radio-button value="1">Pessimo</mat-radio-button>
              <mat-radio-button value="0">Non saprei</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            3 - Servizi generali messi a disposizione
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="serviziStruttura">
              <mat-radio-button value="5">Eccellente</mat-radio-button>
              <mat-radio-button value="4">Buono</mat-radio-button>
              <mat-radio-button value="3">Medio</mat-radio-button>
              <mat-radio-button value="2">Scarso</mat-radio-button>
              <mat-radio-button value="1">Pessimo</mat-radio-button>
              <mat-radio-button value="0">Non saprei</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            4 - Servizi di animazione ed intrattenimento (se previsti)
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="animazioneStruttura">
              <mat-radio-button value="5">Eccellente</mat-radio-button>
              <mat-radio-button value="4">Buono</mat-radio-button>
              <mat-radio-button value="3">Medio</mat-radio-button>
              <mat-radio-button value="2">Scarso</mat-radio-button>
              <mat-radio-button value="1">Pessimo</mat-radio-button>
              <mat-radio-button value="0">Non saprei</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            5 - Assistenza e servizi del Tour Operator organizzatore
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="assistenzaToStruttura">
              <mat-radio-button value="5">Eccellente</mat-radio-button>
              <mat-radio-button value="4">Buono</mat-radio-button>
              <mat-radio-button value="3">Medio</mat-radio-button>
              <mat-radio-button value="2">Scarso</mat-radio-button>
              <mat-radio-button value="1">Pessimo</mat-radio-button>
              <mat-radio-button value="0">Non saprei</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            6 - Suggerimenti
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-form-field class="col col-md-12" appearance="outline">
              <mat-label>Suggerimenti</mat-label>
              <textarea matInput formControlName="suggerimentiStruttura"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="w-100" *ngIf="isUtentePrime">
      <form [formGroup]="utentePrimeForm">
        <div class="w-100 mt-20px font-weight-bold titolo-gruppo">
          Esprima il suo giudizio sulla <a href="/prime" target="_blank">Prime</a>
        </div>
        <div class="row mt-10px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            1 - Reputi interessanti i vantaggi indicati&nbsp;&nbsp;<a href="/prime" target="_blank"><b>vedi i vantaggi&raquo;</b></a>
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="soddisfattoPrime">
              <mat-radio-button value="1">SI</mat-radio-button>
              <mat-radio-button value="0">NO</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            2 - Consiglierebbe il programma <a href="/prime" target="_blank">Prime</a> ad amici
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="consiglioPrime">
              <mat-radio-button value="1">SI</mat-radio-button>
              <mat-radio-button value="0">NO</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row mt-20px">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            3 - Suggerimenti
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-form-field class="col col-md-12" appearance="outline">
              <mat-label>Suggerimenti</mat-label>
              <textarea matInput formControlName="suggerimentiPrime"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="w-100" *ngIf="!isUtentePrime">
      <form [formGroup]="utenteNoPrimeForm">
        <div class="row mt-20px titolo-gruppo">
          <div class="col-xs-12 col-md-6 font-weight-bold">
            Alla luce dell'esperienza di acquisto ricevuta, è disposto<br />
            a valutare l'opportunità di aderire al programma fedeltà
          </div>
          <div class="col-xs-12 col-md-6">
            <mat-radio-group required aria-label="Seleziona un opzione" formControlName="considerazionePrime">
              <mat-radio-button value="1">SI</mat-radio-button>
              <mat-radio-button value="0">NO</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </div>
    <div class="row mt-40px">
      <div class="col text-align-center">
        <button mat-raised-button
          (click)="inserisci()"
          class="no-gutters padding-0 background-blue-borsaviaggi color-white">
          INVIA
        </button>
      </div>
    </div>
  </div>
  <div class="w-100 py-5rem" *ngIf="!showQuestionario && !isCompilato && !statusOk">
      <h3 class="titolo-no-accesso">Spiacente non può accedere a questa pagina</h3>
  </div>
  <div class="w-100 py-5rem" *ngIf="!showQuestionario && isCompilato && !statusOk">
    <h3 class="titolo-no-accesso">Il questionario è già stato compilato</h3>
  </div>
  <div class="w-100 py-5rem" *ngIf="statusOk">
    <h3 class="titolo-no-accesso">Grazie per averci dedicato un po' del tuo tempo per conoscerti meglio!<br />
      <br />
      Le informazioni che ci hai fornito serviranno per offrirti un servizio migliore.</h3>
    <br>
    <br>
    <div class="w-100 text-align-center">
      <a href="/">Torna alla HOME</a>
    </div>
  </div>
</div>
