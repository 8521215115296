import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

export class UserValidaror {


  static formNewsLetter(): FormGroup {

    return new FormBuilder().group({
      hideRequired: false,
      email: new FormControl('', [Validators.required, Validators.email]),
      privacy: new FormControl(false, Validators.requiredTrue)
    });
  }

  static getErrorMessage(fromControl) {
    let ret = "";
    if (fromControl.hasError('required')) {
      ret = 'Campo obbligatorio';
    } else if (fromControl.hasError('email')) {
      ret = 'Formato email non valido';
    }

    return ret;
  }
}
