import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CacheService} from "../../../services/common/cache.service";
import {ModalInterface} from "../../../interface/modal.interface";
import {Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {LoginValidator} from "../../../services/validator/login.validator";
import {UserService} from "../../../services/gate/user.service";
import {UtilityService} from "../../../services/gate/utility.service";
import {AuthService} from "../../../services/common/auth.service";
import {SuccessModalComponent} from "../success/success-modal.component";
import {ModalService} from "../../../services/common/modal.service";
import {User} from "../../../model/user.dto";
import {RecuperoPasswordModalComponent} from "../recupero-password-modal/recupero-password-modal.component";
import * as moment from "moment";
import {LoginSidenavService} from "../../login-sidenav/loginSidenav.service";

@Component({
  selector: 'borsaviaggi-registrazione-modal',
  templateUrl: './registrazione-modal.component.html',
  styleUrls: ['./registrazione-modal.component.scss']
})
export class RegistrazioneModalComponent implements OnInit {
  public loginData;
  public formGroup: FormGroup = LoginValidator.formSignUpValidator;
  public validation_messages: any = LoginValidator.formSignUpErrorMessage;
  public loading: boolean = false;
  public errorRegistrazione: boolean = false;
  public userExist: boolean = false;
  public successMessage;
  public listComuni = [];
  public listRegioni = [];
  public user: User;
  public isPrimePage: boolean = false;

  constructor(public dialogRef: MatDialogRef<RegistrazioneModalComponent>,
              private cacheSrv: CacheService,
              private modalSrv: ModalService,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<RegistrazioneModalComponent>,
              private router: Router,
              private userSrv: UserService,
              private utilitySrv: UtilityService,
              private dialog: MatDialog,
              private authSrv: AuthService,
              private loginSideSrv: LoginSidenavService) {

    moment.locale('it');

    if (data && Object.keys(data).length) {
      this.loginData = data;
    }
  }

  ngOnInit() {
    this.getRegioni();
    if(this.router.url == "/prime"){
      this.isPrimePage = true;
    }
  }

  public changeRegioneEvent(event) {
    this.listComuni = [];
    this.getComuni(event.value)
  }

  public getComuni(regione) {
    this.utilitySrv.getComuniByRegione({idRegione: regione}).then(
      (response) => {
        if (response && response.body.data) {
          this.listComuni = response.body.data
        } else {
          console.log("Errore reperimento comuni")
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  public getRegioni() {
    this.utilitySrv.getRegioni().then(
      (response) => {
        if (response && response.body.data) {
          this.listRegioni = response.body.data

        } else {
          console.log("Errore reperimento regioni")
        }
      },
      (error) => {
        console.log(error)

      }
    )
  }

  public autoSetNewsLetter(event) {
    if (event) {
      this.formGroup.get('riceviNewsletter').setValue(true)
    }
  }

  public signUp(valid) {
    if (valid) {
      this.loading = true;
      let dataParam = {
        email: this.formGroup.controls['email'].value,
        password: this.formGroup.controls['password'].value,
        "nome": this.formGroup.controls['nome'].value,
        "cognome": this.formGroup.controls['cognome'].value,
        "dataNascita": moment(this.formGroup.controls['dataNascita'].value).format('YYYY-MM-DD'),
        "sesso": this.formGroup.controls['sesso'].value,
        "idComune": this.formGroup.controls['idComune'].value,
        "indirizzo": this.formGroup.controls['indirizzo'].value,
        "cap": this.formGroup.controls['cap'].value,
        "cellulare": this.formGroup.controls['cellulare'].value,
        "codiceFiscale": this.formGroup.controls['codiceFiscale'].value,
        "telefono": this.formGroup.controls['telefono'].value,
        "riceviNewsletter": this.formGroup.controls['riceviNewsletter'].value
      };
      this.userSrv.new(dataParam).then(
        (response) => {
          if (response && response.body && response.body.status === 0) {
            if (this.data.dataParam.activatePrime) {
              document.location.href = 'https://www.borsaviaggi.it/Contratti/Pos.aspx?from=abbonamento-prime&IdUtente=' + response.body.idUtente;
            } else {
              this.loading = false;
              this.dialogRef.close();
              /*let activatePrimeLogin = this.data.dataParam && this.data.dataParam.activatePrime;
              this.modalSrv.openLoginModal({'verifyEmail': true, 'activatePrime': activatePrimeLogin},
                (data) => {
                  if (data)
                    this.user = data;
                  this.authSrv.setUser(this.user);
                });*/
              this.modalSrv.openFeedbackModal({success: response.body.message});
            }
          } else {
            this.errorRegistrazione = response.body.status == 1;
            this.userExist = response.body.status == 2;
            this.loading = false;
            //this.openFeedback({error: response.body.message});
          }
        },
        (error) => {
          this.loading = false;
          //this.openFeedback({error: error});
        }
      )
    } else {
      for (let formControlName in this.formGroup.controls) {
        this.formGroup.get(formControlName).markAsTouched();
        this.formGroup.get(formControlName).markAsDirty();
      }
    }
  }

  private openFeedback(esito?: { success?: any, error?: any, button_text?: string, button_icon?: string, additionalParams?: any },
                       callBackAction?,
                       paramWidth = '400px') {
    let dataModal: ModalInterface<SuccessModalComponent> = {
      title: "",
      body_message: "",
      dataParam: esito,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(SuccessModalComponent, {
      panelClass: ['centered-dialog', 'Esito'],
      disableClose: true,
      width: paramWidth,
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction(result);
      }
    });
  }

  public openLoginModal() {
    this.dialogRef.close();

    let activatePrime = this.data.dataParam && this.data.dataParam.activatePrime ?
      this.data.dataParam.activatePrime : false;
    this.loginSideSrv.open();
    /*this.modalSrv.openLoginModal({'activatePrime': activatePrime,banner:this.data.dataParam.banner}, (data) => {
      if (data)
        this.user = data;
      this.authSrv.setUser(this.user);
    });*/
  }
  public actionRecupero() {
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();

    this.dialogRef.close();
    this.openRecuperoModal();
  }

  private openRecuperoModal() {
    setTimeout(() => {
      this.hideShowChatElement(true);
    }, 500);

    let dialogRef = this.dialog.open(RecuperoPasswordModalComponent, {
      panelClass: ['centered-dialog', 'txt-center', 'max-width-94vw'],
      disableClose: false,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.hideShowChatElement(false);
    });
  }

  private hideShowChatElement(hide) {
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = hide ? 'none' : 'block';
    }
  }
}
