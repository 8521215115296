import {Injectable} from '@angular/core';
import {GeocodeService} from "../gate/geocodeService";

declare let google: any;
declare let AmCharts: any;
const _URL_API_GEOCODE = "maps.googleapis.com/maps/api/geocode/json?address=";

@Injectable()
export class GmapService {
  private gmap;

  constructor(
    private geocodeSrv: GeocodeService
  ) {
  }

  public setInstanceMap(map) {
    this.gmap = map;
  }

  public getInstanceMap() {
    return this.gmap;
  }

  public geocodeLocation(paramLocation, callback) {
    let counterResp = 0;
    paramLocation.map(
      (currLocation) => {
        let req = {
          location: _URL_API_GEOCODE + currLocation.nomeStruttura + " " + currLocation.nazione
        };
        if (!currLocation.latitudine && !currLocation.longitudine) {
          this.geocodeSrv.geocodeAddress(req.location).then(
            (response: any) => {
              if (response && response.latitudine && response.longitudine) {
                currLocation.latitudine = response.latitudine;
                currLocation.longitudine = response.longitudine;
              } else {
                currLocation.error = true;
              }
              if (counterResp === (paramLocation.length - 1)) {
                callback(paramLocation);
              }
            },
            (response) => {
              currLocation.error = true;
              if (counterResp === (paramLocation.length - 1)) {
                callback(paramLocation);
              }
            }).finally(
            () => {
              counterResp++;
            }
          )
        } else {
          counterResp++;
          if (counterResp === (paramLocation.length)) {
            currLocation.error = false;
            callback(paramLocation);
          }
        }

      }
    );

  }

  public timCoordsConverterToGoogle(paramObjCoord): { lat: number, lng: number } {
    let ret = {lat: 0, lng: 0};
    if (Object.keys(paramObjCoord).length > 0 && paramObjCoord.lat && paramObjCoord.lon) {
      let arrLat = paramObjCoord.lat.split((/[-]+/));
      let arrLon = paramObjCoord.lon.split((/[-]+/));
      let gradiLat = parseFloat(arrLat[0]);
      let minutiLat = parseFloat(arrLat[1]);
      let secondiLat = parseFloat(arrLat[2]);
      let gradiLon = parseFloat(arrLon[0]);
      let minutiLon = parseFloat(arrLon[1]);
      let secondiLon = parseFloat(arrLon[2]);
      let latG = gradiLat + ((secondiLat / 60) + minutiLat) / 60;
      let lonG = gradiLon + ((secondiLon / 60) + minutiLon) / 60;
      ret = {lat: latG, lng: lonG}
    } else {
      console.error("Parametri nulli o mancanti");
    }
    return ret;
  }

  public getDefaultConfigStreetView() {
    return {
      fullscreenControl: false,
      streetViewControlOptions: {position: 8},
      zoomControlOptions: {position: 8},
      streetViewControl: true,
      scrollwheel: true,
      minZoom: 5,
      maxZoom: 20,
      panControl: false,
      styles: [ //any style array defined in the google documentation you linked
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {visibility: "off"}
          ]
        }]
    }
  }

  public static getDefaultOptionsMap() {
    return {
      fullscreenControl: false,
      streetViewControlOptions: {position: 8},
      zoomControlOptions: {position: 8},
      streetViewControl: true,
      scrollwheel: true,
      minZoom: 5,
      maxZoom: 20,
      panControl: false,
      styles: [ //any style array defined in the google documentation you linked
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {visibility: "off"}
          ]
        }]
    }
  }

  public loadMap() {
    let mapProp = {
      center: new google.maps.LatLng(41.902770, 12.496418),
      zoom: 5,
      maxZoom: 18,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [{
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#444444"}]
      }, {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#f2f2f2"}]}, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{"visibility": "off"}]
      }, {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{"saturation": -100}, {"lightness": 45}]
      }, {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{"visibility": "simplified"}]
      }, {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
      }, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]}, {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{"color": "#46bcec"}, {"visibility": "on"}]
      }, {
        "featureType": "water",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#8737a5"}]
      }, {"featureType": "water", "elementType": "labels.text.stroke", "stylers": [{"color": "#8737a5"}]}]
    };

    this.gmap = new google.maps.Map(document.getElementById("gmap"), mapProp);
    //this.loadSvg(this.gmap )
    return this.gmap;
  }

  public hideMap() {
  }


  public loadSvg(gmap) {
    let ammap = AmCharts.makeChart("ammap", {
      "type": "map",
      "theme": "light",
      "dataProvider": {
        "map": "italyLow",
        "getAreasFromMap": true,
        "areas": [{
          // hide Corsica
          "id": "FR-H",
          "alpha": 0,
          "mouseEnabled": false
        }]
      },
      "areasSettings": {
        "autoZoom": true,
        "selectedColor": "#CC0000"
      }

    });
  }

}
