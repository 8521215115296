import {Location} from "@angular/common";
import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ErrorModalComponent} from "../../components/modal/error-modal/error-modal.component";
import {ResponseCheckerService} from "./response.checker.service";
import {ModalInterface} from "../../interface/modal.interface";

/**
 *
 * PromiseUtilConfig
 *
 *  @argument permissiveErrorDialog - Disables location.back() when user dismisses the error modal
 *  @argument noErrorDialog - Do not show error dialogs, even if there is an error
 *  @argument ignoreResponseObj - skips the ResponseCheckerService.isSuccessResponse(resolve) validity check
 *
 */

export class PromiseUtilConfig {
  /** Disables location.back() when user dismisses the error modal */
  public permissiveErrorDialog: boolean;
  /** Do not show error dialogs, even if there is an error */
  public noErrorDialog: boolean;
  /** skips the ResponseCheckerService.isSuccessResponse(resolve) validity check */
  public ignoreIsSuccessResponseCheck: boolean;

  constructor(obj?: any) {
    this.permissiveErrorDialog = obj && obj.permissiveErrorDialog || false;
    this.noErrorDialog = obj && obj.noErrorDialog || false;
    this.ignoreIsSuccessResponseCheck = obj && obj.ignoreIsSuccessResponseCheck || false;
  }
}

@Injectable()
export class PromiseUtilService {


  constructor(private location: Location,
              private dialog: MatDialog) {
  };

  public showError = (reject) => {
    this.showErrorDialog(reject);
  };

  public showErrorDialog(resolve, configObj?: PromiseUtilConfig) {
    if (configObj && !configObj.noErrorDialog) {
      const msg = ResponseCheckerService.getErrorMessage(resolve);
      this.openErrorDialog(msg, () => {
        if (configObj && !configObj.permissiveErrorDialog) this.location.back();
      });
    }
  }

  public checkResponseAndExecute = (resolve, fn, configObj?: PromiseUtilConfig) => {
    if (configObj && !configObj.ignoreIsSuccessResponseCheck) {
      if (ResponseCheckerService.isSuccessResponse(resolve)) {
        fn(resolve);
      } else {
        this.showErrorDialog(resolve, configObj);
      }
    } else {
      fn(resolve);
    }

  };

  public resolve = (promise: Promise<any>, fn, configObj?: PromiseUtilConfig) => {
    promise.then(res => this.checkResponseAndExecute(res, resolve => fn(resolve), configObj), this.showError);
  };


  public openErrorDialog(dataError?, callBackAction?) {
    let dataModal: ModalInterface<ErrorModalComponent> = {
      title: "Errore",
      body_message: dataError,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(ErrorModalComponent, {
      panelClass: 'centered-dialog',
      disableClose: true,
      width: '400px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction();
      }
    });
  }
}
