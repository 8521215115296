import {Component, Input, OnInit} from '@angular/core';
import {MacroBoxEnum} from "./config-layout.enum";
import {MacroBoxInterface} from "./config.interface";

@Component({
  selector: 'borsaviaggi-macro-box',
  templateUrl: './macro-box.component.html',
  styleUrls: ['./macro-box.component.scss']
})
export class MacroBoxComponent implements OnInit {

  @Input() configObj: MacroBoxInterface;

  public layoutConfigEnum = MacroBoxEnum;

  constructor() {
  }

  ngOnInit() {
  }


}
