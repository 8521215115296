import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {SEO_GLOBAL_KEYWORD} from "../../constants/seo.const";

@Component({
  selector: 'borsaviaggi-carte-regalo',
  templateUrl: './carte-regalo.component.html',
  styleUrls: ['./carte-regalo.component.scss']
})
export class CarteRegaloComponent implements OnInit {
  public giftCards;

  constructor(
    public router: Router,
    private titleService: Title,
    public route: ActivatedRoute) {
    this.giftCards = this.route.snapshot.data.dataPartner.body.data.listaCarteRegalo;
  }

  ngOnInit() {
    this.titleService.setTitle(SEO_GLOBAL_KEYWORD.CARTE_REGALO);
  }

}
