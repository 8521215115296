import {Component, Input, OnInit} from '@angular/core';
import {ConfigSearchEngine} from './search-engine.config';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'borsaviaggi-search-engine',
  templateUrl: './search-engine.component.html',
  styleUrls: ['./search-engine.component.scss']
})
export class SearchEngineComponent implements OnInit {
  @Input() public dataSearch;
  public config;
  public selectedTabIndex: number;
  public currentTab: any;

  constructor(private activeRoute: ActivatedRoute) {
    this.selectedTabIndex = ConfigSearchEngine.getConfig(this.dataSearch).selectedTabIndex;
    this.currentTab = ConfigSearchEngine.getConfig(this.dataSearch).currentTab;
  }

  ngOnInit() {
    this.config = ConfigSearchEngine.getConfig(this.dataSearch);
    console.log(ConfigSearchEngine.getConfig(this.dataSearch));
    if (this.activeRoute.snapshot.queryParams && this.activeRoute.snapshot.queryParams.from) {
      switch (this.activeRoute.snapshot.queryParams.from) {
        case 'vacanze':
          this.selectedTabIndex = 0;
          this.currentTab = 'vacanze';
          break;
        case 'italia':
          this.selectedTabIndex = 1;
          this.currentTab = 'italia';
          break;
        case 'crociera' :
          this.selectedTabIndex = 2;
          this.currentTab = 'crociera';
          break;
        case 'struttura' :
          this.selectedTabIndex = 3;
          this.currentTab = 'struttura';
          break;
      }
    }
  }

  public actionChangeTab(event) {
    switch (event.index) {
      case 0:
        this.currentTab = 'vacanze';
        break;
      case 1:
        this.currentTab = 'italia';
        break;
      case 2:
        this.currentTab = 'crociere';
        break;
      case 3:
        this.currentTab = 'struttura';
        break;
    }
  }
}
