<form [formGroup]="formGroup">
  <mat-form-field class="col-md-6" appearance="outline">
    <mat-label>Nome</mat-label>
    <input matInput placeholder="Nome" required name="nome" formControlName="nome" class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.nome"
                   [formControlParam]="formGroup.controls.nome"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline">
    <mat-label>Cognome</mat-label>
    <input matInput placeholder="Cognome" required name="cognome" formControlName="cognome" class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.cognome"
                   [formControlParam]="formGroup.controls.cognome"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline">
    <mat-label>Data di Nascita</mat-label>
    <input formControlName="dataNascita" required matInput [matDatepicker]="picker" class="mb-10px-impo">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <bv-form-error [formErrorMessage]="validation_messages.dataNascita"
                   [formControlParam]="formGroup.controls.dataNascita"></bv-form-error>
  </mat-form-field>

  <mat-form-field class="col-md-6" appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput placeholder="Email" required type="email" name="email" formControlName="email" class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.email"
                   [formControlParam]="formGroup.controls.email"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage">
    <mat-label>Cap</mat-label>
    <input matInput required placeholder="Cap" type="number" name="cap" formControlName="cap" class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.cap"
                   [formControlParam]="formGroup.controls.cap"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage">
    <mat-label>Indirizzo</mat-label>
    <input matInput required placeholder="Indirizzo" name="indirizzo" formControlName="indirizzo" class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.indirizzo"
                   [formControlParam]="formGroup.controls.indirizzo"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage && !!user">
    <mat-label>Password</mat-label>
    <input matInput required placeholder="Password" type="password" name="password" formControlName="password"
           class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.password"
                   [formControlParam]="formGroup.controls.password"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage">
    <mat-label>Codice Fiscale</mat-label>
    <input matInput required placeholder="CodiceFiscale" name="codiceFiscale" formControlName="codiceFiscale"
           class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.codiceFiscale"
                   [formControlParam]="formGroup.controls.codiceFiscale"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline">
    <mat-label>Cellulare</mat-label>
    <input required matInput placeholder="Cellulare" type="text" name="cellulare" formControlName="cellulare"
           class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.cellulare"
                   [formControlParam]="formGroup.controls.cellulare"></bv-form-error>
  </mat-form-field>
  <mat-form-field class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage">
    <mat-label>Telefono</mat-label>
    <input matInput placeholder="Telefono" type="number" name="telefono" formControlName="telefono" class="mb-8px-impo">
    <bv-form-error [formErrorMessage]="validation_messages.telefono"
                   [formControlParam]="formGroup.controls.telefono"></bv-form-error>
  </mat-form-field>
  <mat-form-field name="select" required class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage && !user">
    <mat-label>Sesso</mat-label>
    <mat-select required formControlName="sesso" class="mb-8px-impo">
      <mat-option value="M">M</mat-option>
      <mat-option value="F">F</mat-option>
    </mat-select>
    <bv-form-error [formErrorMessage]="validation_messages.sesso"
                   [formControlParam]="formGroup.controls.sesso"></bv-form-error>
  </mat-form-field>
  <div class="row pl-15px pr-15px" [hidden]="!fuoriTuttoPage">
    <mat-form-field name="regione" class="col-md-6" appearance="outline">
      <mat-label>Regione</mat-label>
      <mat-select required (selectionChange)="changeRegioneEvent($event)" formControlName="idRegione"
                  class="mb-8px-impo">
        <mat-option *ngFor="let option of listRegioni" [value]="option.idRegione">{{option.regione}}</mat-option>
      </mat-select>
      <bv-form-error [formErrorMessage]="validation_messages.idRegione"
                     [formControlParam]="formGroup.controls.idRegione"></bv-form-error>
    </mat-form-field>
    <div class="col-md-6">
      <bv-select
        *ngIf="listComuni"
        [data]="listComuni"
        disableClearBtn="true"
        formControlName="idComune"
        labelKey="Comune"
        ordered="Comune"
        placeholder="Comune"
        textAlign="left"
        valueKey="IdComune"
        required
      >
      </bv-select>
    </div>
  </div>
  <mat-form-field [hidden]="fuoriTuttoPage" name="select" class="col-md-6" appearance="outline">
    <mat-label>Regione</mat-label>
    <mat-select required (selectionChange)="changeRegioneEvent($event)" formControlName="idRegione" class="mb-8px-impo">
      <mat-option *ngFor="let option of listRegioni" [value]="option.idRegione">{{option.regione}}</mat-option>
    </mat-select>
    <bv-form-error [formErrorMessage]="validation_messages.idComune"
                   [formControlParam]="formGroup.controls.idComune"></bv-form-error>
  </mat-form-field>
  <div class="col-md-6" [hidden]="fuoriTuttoPage">
    <bv-select
      *ngIf="listComuni"
      [data]="listComuni"
      disableClearBtn="true"
      formControlName="idComune"
      labelKey="Comune"
      ordered="Comune"
      placeholder="Comune"
      textAlign="left"
      valueKey="IdComune"
      required
    >
    </bv-select>
  </div>
  <mat-form-field name="select" required class="col-md-6" appearance="outline" [hidden]="fuoriTuttoPage && user">
    <mat-label>Sesso</mat-label>
    <mat-select required formControlName="sesso" class="mb-8px-impo">
      <mat-option value="M">M</mat-option>
      <mat-option value="F">F</mat-option>
    </mat-select>
    <bv-form-error [formErrorMessage]="validation_messages.sesso"
                   [formControlParam]="formGroup.controls.sesso"></bv-form-error>
  </mat-form-field>
  <mat-checkbox formControlName="privacy" class="left full-width" [checked]="true"> Ho letto ed accetto l'Informativa
    Privacy in
    merito al
    trattamento dei miei
    dati personali
  </mat-checkbox>
  <mat-checkbox formControlName="riceviNewsletter" class="left">Consenso per l'invio di materiale promozionale
    (facoltativo)
  </mat-checkbox>
  <!--    <div [hidden]="!successMessage" class="col-12 green">{{successMessage}}</div>
      <div [hidden]="!errorMessage" class="col-12 red">{{errorMessage}}</div>-->
</form>
