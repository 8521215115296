<div class="d-sm-none mobile-title-text-container">

  <div class="mobile-background-container">
    <div class="mobile-title-container">
      <div class="mobile-image-container">
        <img class="mobile-image"
             [alt]="dettaglioPartner?.title"
             [src]="dettaglioPartner?.urlLogo">
      </div>
      <h1 class="text-align-center mobile-title">{{dettaglioPartner?.title}}</h1>
    </div>
    <div class="mobile-background-text">
      <h3 class="text-align-left d-sm-none mobile-testo">{{dettaglioPartner?.testo}}</h3>
    </div>
  </div>
</div>

<div *ngIf="dettaglioPartner"
     class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-justify background-white overflow-hidden">


  <div class="row mb-40px display-not-mobile">
    <div class="col-sm-3 align-items-center justify-content-center text-center">
      <img class="mt-2rem img-partner img-partner"
           [alt]="dettaglioPartner.title"
           [src]="dettaglioPartner.urlFoto">
    </div>
    <div class="col-sm-9 mt-4">
      <h1 class="text-align-left fs-h1">{{dettaglioPartner.title}}</h1>
      <h3 class="text-align-left d-block fs-h3">{{dettaglioPartner.testo}}</h3>
    </div>
  </div>

  <div class="container mobile-padding">
    <div class="row">
      <div class="destination-tabs col-12">
        <mat-tab-group>
          <mat-tab class="overflow-hidden">
            <ng-template mat-tab-label>
              <div class=" d-flex align-items-center justify-content-center">
                <span class="align-middle fs-1_1rem">Scegli per DESTINAZIONE</span>
              </div>
            </ng-template>
            <ng-template matTabContent>
              <div class="row macro-row mt-1em wd-100">
                <borsaviaggi-macro-box *ngFor="let boxDestinazioni of listaDestinazioniPartner"
                                       [configObj]="mapDestinazioni(boxDestinazioni)"
                                       class="pl-0 col-lg-3 col-md-4 col-sm-6 mb-20px d-flex align-items-center justify-content-center macro-box-destinazione">
                </borsaviaggi-macro-box>
              </div>
            </ng-template>

          </mat-tab>
          <mat-tab class="overflow-hidden">
            <ng-template mat-tab-label>
              <div class=" d-flex align-items-center justify-content-center">
                <span class="align-middle fs-1_1rem">Scegli per STRUTTURA</span>
              </div>
            </ng-template>
            <ng-template matTabContent>
              <div class="col-12 mobile-accordion row mt-1em wd-90em pb-10px no-gutters">
                <div class="col-md-6 mobile-accordion no-gutters">
                  <mat-accordion class="d-sm-none">
                    <mat-expansion-panel class="bg-black dark-blue mat-elevation-z8"
                                         *ngFor="let struttureNazione of listaStrutturePartner">
                      <mat-expansion-panel-header class="dark-blue">
                        <mat-panel-title class="color-white dark-blue">
                          <h4>{{struttureNazione[0].nazione}}</h4>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="mobile-link-container" *ngFor="let struttura of struttureNazione">
                        <a class="mb-1rem fs-1_2rem text-align-left"
                           [title]="dettaglioPartner.title+' '+struttura.nomeStruttura"
                           [routerLink]="'/'+makeUrlSeoHref(dettaglioPartner, struttura, 'struttura')">
                          <div>{{struttura.nomeStruttura | titlecase}}
                            <br></div>
                        </a>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <div class="wd-100 d-none d-sm-inline-block"
                       *ngFor="let struttureNazione of listaStrutturePartner; let even = even">

                    <div *ngIf="even"><h4>{{struttureNazione[0].nazione}}</h4>

                      <a
                        *ngFor="let struttura of struttureNazione"
                        [title]="dettaglioPartner.title+' '+struttura.nomeStruttura"
                        [routerLink]="'/'+makeUrlSeoHref(dettaglioPartner, struttura, 'struttura')">&#8226; {{struttura.nomeStruttura | titlecase}}
                        <br></a>

                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="wd-100 d-none d-sm-inline-block"
                       *ngFor="let struttureNazione of listaStrutturePartner;let odd = odd">
                    <div *ngIf="odd">
                      <h4>{{struttureNazione[0].nazione}}</h4>

                      <a
                        *ngFor="let struttura of struttureNazione"
                        [title]="dettaglioPartner.title+' '+struttura.nomeStruttura"
                        [routerLink]="'/'+makeUrlSeoHref(dettaglioPartner, struttura, 'struttura')">&#8226; {{struttura.nomeStruttura}}
                        <br>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
      <borsaviaggi-carosello class="pb-100px  mt-20px" [listPartner]="listPartners"></borsaviaggi-carosello>
    </div>
  </div>
</div>
