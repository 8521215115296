import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

export class LoginValidator {

  public static formRegisterPrimeValidator: FormGroup =
    new FormBuilder().group({
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      idComune: new FormControl('', Validators.compose([
        Validators.required
      ])),
      indirizzo: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cap: new FormControl('', Validators.compose([
        Validators.required
      ])),
      codiceFiscale: new FormControl('', Validators.compose([
        Validators.required, Validators.pattern(/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/)
      ])),
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ])),
      nominativo: new FormControl('', Validators.compose([
        Validators.required
      ])),
      tipoPagamento: new FormControl('', Validators.compose([
        Validators.required
      ])),
      idRegione: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

  public static formLoginValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

  public static formSignUpValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dataNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      sesso: new FormControl('', Validators.compose([
        Validators.required
      ])),
      idComune: new FormControl('', Validators.compose([
        Validators.required
      ])),
      idRegione: new FormControl('', Validators.compose([
        Validators.required
      ])),
      indirizzo: new FormControl(''),
      cap: new FormControl(''),
      cellulare: new FormControl('', Validators.compose([ Validators.required])),
      codiceFiscale: new FormControl('', Validators.compose([
       Validators.pattern(/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/)
      ])),
      telefono: new FormControl('', Validators.compose([])),
      riceviNewsletter: new FormControl('', Validators.compose([])),
      privacy: new FormControl('', Validators.compose([])),
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    public static formCheckoutValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dataNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      sesso: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cellulare: new FormControl('', Validators.compose([ Validators.required])),
      riceviNewsletter: new FormControl('', Validators.compose([])),
      privacy: new FormControl('', Validators.compose([])),
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ]))
    });




  public static formLoginErrorMessage: Object = {
    'email': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'email', message: "L' email inserita non è valida"}
    ],
    'password': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ]
  };

  public static formSignUpErrorMessage: Object = {
    'email': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'email', message: "L' email inserita non è valida"}
    ],
    'password': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'nome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cognome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'indirizzo': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cap': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'codiceFicale': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'pattern', message: 'Codice fiscale non valido!'}
    ],
    'dataNascita': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'idComune': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'sesso': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cellulare': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'telefono': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ]

  };

  public static formRegisterPrimeMessage: Object = {
    'email': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'email', message: "L' email inserita non è valida"}
    ],
    'nome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cognome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'indirizzo': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cap': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'codiceFicale': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'pattern', message: 'Codice fiscale non valido!'}
    ],
    'idComune': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'tipoPagamento': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'nominativo': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'idRegione': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ]
  };

}
