<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center background-white">
  <mat-card class="mb-15px">
    <div class="sweet-alert" *ngIf="dataVerifica.esito.result == 'OK' && statusVerifica == 0">
      <div class="sa-icon sa-success animate" style="display: block;"><span
        class="sa-line sa-tip animateSuccessTip"></span> <span class="sa-line sa-long animateSuccessLong"></span>
        <div class="sa-placeholder"></div>
        <div class="sa-fix"></div>
      </div>
      <div class="message-container text-align-center">
        <h2 class="verifica-message mt-15px">Il tuo acquisto è avvenuto con successo</h2>
      </div>
    </div>
    <div class="sweet-alert" *ngIf="dataVerifica.esito.result != 'OK' || statusVerifica != 0">
      <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
						<span class="sa-x-mark animateXMark"> <span
              class="sa-line sa-left"></span> <span class="sa-line sa-right"></span>
						</span>
      </div>
      <div class="message-error-container text-align-center">
        <h2 class="verifica-message mt-15px" *ngIf="!dataVerifica.esito.error">Errore inaspettato</h2>
        <h2 class="verifica-message mt-15px" *ngIf="dataVerifica.esito.error">{{dataVerifica.esito.error}}</h2>
      </div>
    </div>
  </mat-card>
  <mat-card *ngIf="dataVerifica.esito.result == 'OK' && statusVerifica == 0">
    <div class="mb-15px">
      <h1 class="riepilogo-title">Riepilogo Acquisto:</h1>
    </div>
    <div class="riepilogo-section" *ngIf=" dataVerifica && dataVerifica.esito.idTipologiaTransazione == 11">
      <div class="riepilogo">
        <div class="row">
          <p class="riepilogo-fields col-sm">Importo:</p>
          <p class="riepilogo-data col-sm">{{getRegaloValue()}} €</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Valore:</p>
          <p class="riepilogo-data col-sm">{{getRegaloValue()
          + dataVerifica.cartaRegalo.importoExtraValoreAcquisto}} €</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Id:</p>
          <p class="riepilogo-data col-sm">{{getIdValue()}}</p>
        </div>
      </div>
    </div>
    <div class="riepilogo-section" *ngIf="dataVerifica.esito.idTipologiaTransazione == 6 && dataPartenza">
      <div class="riepilogo">
        <div class="row">
          <p class="riepilogo-fields col-sm">Codice Partenza:</p>
          <p class="riepilogo-data col-sm">{{idPartenza}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Destinazione:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.nazione | titlecase}}
            - {{dataPartenza.destinazione | titlecase}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Struttura:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.nomeStrutturaOfferta | titlecase}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Da:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.luogoNome | titlecase}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Partenza il:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.lblDataPartenza}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Rientro il:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.lblDataRientro}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Soggiorno:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.lblGiorniNotti | titlecase}}</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Trattamento:</p>
          <p class="riepilogo-data col-sm">{{dataPartenza.lblTrattamento | titlecase}}</p>
        </div>
      </div>
    </div>
    <div class="riepilogo-section" *ngIf=" dataVerifica && dataVerifica.esito.idTipologiaTransazione == 1">
      <div class="riepilogo">
        <div class="row">
          <p class="riepilogo-fields col-sm">Prime:</p>
          <p class="riepilogo-data col-sm">Attivo</p>
        </div>
        <div class="row">
          <p class="riepilogo-fields col-sm">Data Scadenza:</p>
          <p class="riepilogo-data col-sm">{{dataVerifica.esito.dataScadenzaPrime | date: 'MM/dd/yy'}}</p>
        </div>
      </div>
    </div>
  </mat-card>
</div>
