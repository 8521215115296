import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {DestinazioneService} from "../destinazione.service";
import {CacheService} from "../../common/cache.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioPResolveService {
  constructor(private destSrv: DestinazioneService,
              private cacheSrv: CacheService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let dataParam = {
      "idPartenza": routeSnap.params.idP
    };
    let cachedP = this.cacheSrv.getCacheData('partenza-' + routeSnap.params.idP);
    if (cachedP) {
      return cachedP;
    } else {
      return this.destSrv.getPartenza(dataParam)
        .catch(err => {
          return err;
        });
    }


    // return this.destSrv.getOffertaByCode(dataParam)
    //   .catch(err => {
    //     return err;
    //   });

  }


}
