import {BrowserModule, ɵgetDOM} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, PLATFORM_ID} from '@angular/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from '../app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderComponent} from '../components/loader/loader.component';
import {NavigationComponent} from '../components/navigation-component/navigation.component';
import {LoginSidenavComponent} from '../components/login-sidenav/login-sidenav.component';
import {ToolbarComponent} from '../components/toolbar/toolbar.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AppConfig} from '../../app.config';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthService} from '../services/common/auth.service';
import localeIt from '@angular/common/locales/it';
import {GenericGateService} from '../services/common/genericGate.service';
import {LoaderService} from '../components/loader/loader.service';
import {NavigationService} from '../components/navigation-component/navigation.service';
import {LoginSidenavService} from '../components/login-sidenav/loginSidenav.service';
import {RatingService} from '../services/gate/rating.service';
import {HomePageComponent} from '../page/home-page/home-page.component';
import {SearchResultsComponent} from '../page/search-results/search-results.component';
import {SearchResultsCrociereComponent} from '../page/search-results-crociere/search-results-crociere.component';
import {MultimediaService} from '../services/gate/multimedia.service';
import {SearchVacanzeComponent} from '../components/search-egine/search-vacanze/search-vacanze.component';
import {SearchItaliaComponent} from '../components/search-egine/search-italia/search-italia.component';
import {SearchMacroComponent} from '../components/search-egine/search-macro/search-macro.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchEngineComponent} from '../components/search-egine/search-engine.component';
import {ModalService} from '../services/common/modal.service';
import {DestinazioneService} from '../services/gate/destinazione.service';
import {CacheService} from '../services/common/cache.service';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {CommonModule, DOCUMENT, isPlatformBrowser, registerLocaleData} from '@angular/common';
import {PopoverComponents} from '../components/popover-box/popover';
import {A11yModule} from '@angular/cdk/a11y';
import {PopoverTrigger} from '../components/popover-box/popover-trigger';
import {SearchCrociereComponent} from '../components/search-egine/search-crociere/search-crociere.component';
import {SearchStruttureComponent} from '../components/search-egine/search-strutture/search-strutture.component';
import {HomeService} from '../services/gate/home.service';
import {BoxOffertaTopComponent} from '../components/box-offerta-top/box-offerta-top.component';
import {ShareButtonComponent} from '../components/share-button/share-button.component';
import {PreferitiComponent} from '../components/preferiti/preferiti.component';
import {DettaglioVacanzaGPage} from '../page/vacanza/dettaglio-vacanza-g-page/dettaglio-vacanza-g-page.component';
import {DettaglioVacanzaPPage} from '../page/vacanza/dettaglio-vacanza-p-page/dettaglio-vacanza-p-page.component';
import {DettaglioCrocieraPage} from '../page/crociera/dettaglio-crociera-page/dettaglio-crociera-page.component';
import {ResultCardComponent} from '../components/result-card/result-card.component';
import {SearchFiltersComponent} from '../components/search-filters/search-filters.component';
import {ComePrenotareComponent} from '../page/come-prenotare/come-prenotare.component';
import {QuestionarioPostVenditaComponent} from '../page/questionario-post-vendita/questionario-post-vendita.component';
import {LampadaDesideriComponent} from '../page/lampada-desideri/lampada-desideri.component';
import {TerminiCondizioniComponent} from '../page/termini-condizioni/termini-condizioni.component';
import {ScriviDirettoreComponent} from '../page/scrivi-direttore/scrivi-direttore.component';
import {DirettoreService} from '../services/gate/direttore.service';
import {RatingComponent} from '../components/rating/rating.component';
import {InformativaPrivacyComponent} from '../page/informativa-privacy/informativa-privacy.component';
import {SuggerimentiComponent} from '../page/suggerimenti/suggerimenti.component';
import {SuggerimentiService} from '../services/gate/suggerimenti.service';
import {LavoraConNoiComponent} from '../page/lavora-con-noi/lavora-con-noi.component';
import {MacroBoxComponent} from '../components/macro-box/macro-box.component';
import {FooterComponent} from '../components/footer/footer.component';
import {InformativaDatiPersonaliComponent} from '../page/informativa-dati-personali/informativa-dati-personali.component';
import {LavoraConNoiService} from '../services/gate/lavora-con-noi.service';
import {LampadaDesideriService} from '../services/gate/lampada-desideri.service';
import {ContainerDestinazioniService} from '../services/gate/container-destinazioni.service';
import {CropimageComponent} from '../components/modal/cropimage/cropimage.component';
import {FormErrorComponent} from '../components/form-error/form-error.component';
import {PartenzeIconsComponent} from '../components/partenze-icons/partenze-icons.component';
import {UserService} from '../services/gate/user.service';
import {BvSelectComponent} from '../components/bv-select/bv-select.component';
import {OrderByPipe} from '../../pipe/orderby.pipe';
import {ArraySortPipe} from '../../pipe/arraySort.pipe';
import {FilterPipe} from '../../pipe/filter.string.pipe';
import {PromiseButtonComponent} from '../components/promise-button/promiseBtn.component';
import {PopoverTarget} from '../components/popover-box/popover-target';
import {ShareModule} from '@ngx-share/core';
import {SearchResultsService} from '../services/gate/search-results.service';
import {SearchResultsCrociereService} from '../services/gate/search-results-crociere.service';
import {RicercaVacanzeResolveService} from '../services/gate/resolve/ricerca-vacanze.resolve.service';
import {RicercaVacanzeService} from '../services/gate/ricerca-vacanze.service';
import {FormazioneComponent} from '../page/formazione/formazione.component';
import {CaroselloComponent} from '../components/carosello/carosello.component';
import {UniversalInterceptor} from '../services/interceptors/universal.interceptor';
import {RicercaCrociereService} from '../services/gate/ricerca-crociere.service';
import {UtilsStringService} from '../services/common/utils-string.service';
import {MacroDestinazionePageComponent} from '../page/macro-destinazione-page/macro-destinazione-page.component';
import {DettaglioDestinazioneNostraSelezioneComponent} from '../page/macro-destinazione-page/dettaglio-destinazione-nostra-selezione/dettaglio-destinazione-nostra-selezione.component';
import {MacroCategoriaResolveService} from '../services/gate/resolve/macro-categoria.resolve.service';
import {LayoutModule} from '@angular/cdk/layout';
import {AreaPersonaleComponent} from '../page/area-personale/area-personale.component';
import {ContainerDestinazioniComponent} from '../page/container-destinazioni/container-destinazioni.component';
import {DettaglioDestinazioniComponent} from '../page/container-destinazioni/dettaglio-destinazioni/dettaglio-destinazioni.component';
import {UploadImageProfileComponent} from '../components/upload-image-profile/upload-image-profile.component';
import {DatiPersonaliComponent} from '../page/area-personale/sezioni-area-personale/dati-personali/dati-personali.component';
import {ListaPrenotazioniComponent} from '../page/area-personale/sezioni-area-personale/lista-prenotazioni/lista-prenotazioni.component';
import {DatiPersonaliResolve} from '../services/gate/resolve/dati-personali.resolve';
import {HighlightDirective} from '../directives/highlightDirective.directive';
import {PromiseUtilService} from '../services/common/promiseUtil.service';
import {UtilityService} from '../services/gate/utility.service';
import {AuthInterceptor} from '../services/gate/interceptor/auth.interceptor';
import {LocalStorageService} from '../services/common/localStorage.service';
import {ListaDesideriComponent} from '../page/area-personale/sezioni-area-personale/lista-desideri/lista-desideri.component';
import {FilterNewsPipe} from '../../pipe/filterNews.pipe';
import {DettaglioGResolveService} from '../services/gate/resolve/dettaglio-g.resolve.service';
import {DettaglioPResolveService} from '../services/gate/resolve/dettaglio-p.resolve.service';
import {DettaglioGService} from '../services/gate/dettaglio-g.service';
import {DettaglioPService} from '../services/gate/dettaglio-p.service';
import {DettaglioPDescrizioneResolveService} from '../services/gate/resolve/dettaglio-p-descrizione.resolve.service';
import {DettaglioPartnersComponent} from '../page/dettaglio-partners/dettaglio-partners.component';
import {DettaglioDestinazioneComponent} from '../page/dettaglio-partners/dettaglio-destinazione/dettaglio-destinazione.component';
import {DettaglioPartnerResolveService} from '../services/gate/resolve/dettaglio-partner.resolve.service';
import {DettaglioStrutturaPartnerResolveService} from '../services/gate/resolve/dettaglio-struttura-partner.resolve.service';
import {DettaglioDestinazioneResolveService} from '../services/gate/resolve/dettaglio-destinazione.resolve.service';
import {DettaglioDestinazioneNostraSelezioneResolveService} from '../services/gate/resolve/dettaglio-destinazione-nostra-selezione.resolve.service';
import {ReadmoreComponent} from '../components/readmore/readmore.component';
import {FiltroPartenzeComponent} from '../components/search-filters/filtro-partenze/filtro-partenze.component';
import {DettaglioStrutturaPage} from '../page/dettaglio-partners/dettaglio-struttura-partners/dettaglio-struttura-page.component';
import {MatCarouselModule} from '@ngmodule/material-carousel';
import {CheckoutResolveService} from '../services/gate/resolve/checkout.resolve.service';
import {CheckoutPageComponent} from '../page/checkout/checkout-page.component';
import {InfoPartenzaRowComponent} from '../components/info-partenza-row/info-partenza-row.component';
import {PrimePageComponent} from '../page/prime-page/prime-page.component';
import {GiaClientePrimeBoxComponent} from '../components/gia-cliente-prime-box/gia-cliente-prime-box.component';
import {CheckoutFormComponent} from '../page/checkout/checkout-form/checkout-form.component';
import {Ng5SliderModule} from 'ng5-slider';
import {SommaPipe} from '../../pipe/somma.pipe';
import {QuiDietroComponent} from '../page/qui-dietro/qui-dietro.component';
import {LinksBannerComponent} from '../components/links-banner/links-banner.component';
import {EuroFormatterPipe} from '../../pipe/euro-formatter.pipe';
import {InfoRowComponent} from '../components/info-row/info-row.component';
import {CheckoutCrociereResolveService} from '../services/gate/resolve/checkout-crociere.resolve.service';
import {GoogleMapsComponent} from '../components/google-maps/google-maps.component';
import {GeocodeService} from '../services/gate/geocodeService';
import {MostraMappaComponent} from '../components/mostra-mappa/mostra-mappa.component';
import {VerificaTransazioneResolveService} from '../services/gate/resolve/verifica-transazione.resolve.service';
import {VerificaTransazioneService} from '../services/gate/verifica-transazione.service';
import {CartaRegaloResolveService} from '../services/gate/resolve/carta-regalo.resolve.service';
import {CarteRegaloComponent} from '../page/carte-regalo/carte-regalo.component';
import {DettaglioCartaRegaloComponent} from '../page/dettaglio-carta-regalo/dettaglio-carta-regalo.component';
import {VerificaTransazioneComponent} from '../page/verifica-transazione/verifica-transazione.component';
import {GiftCardComponent} from '../components/gift-card/gift-card.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {VetrinaPrimeComponent} from '../page/vetrina-prime/vetrina-prime.component';
import {ModalDestinazioniVprimeComponent} from '../components/modal/modal-destinazioni-vprime/modal-destinazioni-vprime.component';
import {DettaglioZResolveService} from '../services/gate/resolve/dettaglio-z.resolve.service';
import {DettaglioZService} from '../services/gate/dettaglio-z.service';
import {DestinazioniMondoResolveService} from '../services/gate/resolve/destinazioni-mondo.resolve.service';
import {DestinazioniItaliaResolveService} from '../services/gate/resolve/destinazioni-italia.resolve.service';
import {HotelMondoResolveService} from '../services/gate/resolve/hotel-mondo.resolve.service';
import {HotelItaliaResolveService} from '../services/gate/resolve/hotel-italia.resolve.service';
import {ModificaPreventivoCrocieraModalComponent} from '../components/modal/modifica-preventivo-crociera/modifica-preventivo-crociera-modal.component';
import {CheckoutPageCrociereComponent} from '../page/crociera/checkout-crociera/checkout-page-crociere.component';
import {DettaglioHotelComponent} from '../page/dettaglio-hotel/dettaglio-hotel.component';
import {NgxGalleryModule} from './ngx-gallery.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PrimePagamentoComponent} from '../page/prime-pagamento/prime-pagamento.component';
import {ListaPrenotazioniResolveService} from '../services/gate/resolve/lista-prenotazioni.resolve.service';
import {DestinazioneTopResolveService} from '../services/gate/resolve/destinazione-top.resolve.service';
import {MatDialogModule} from '@angular/material/dialog';
import {DestinazioniModalComponent} from '../components/modal/destinazione/destinazioni-modal.component';
import {LoginModalComponent} from '../components/modal/login-modal/login-modal.component';
import {SuccessModalComponent} from '../components/modal/success/success-modal.component';
import {ModificaOccupazioneModalComponent} from '../components/modal/modifica-occupazione-modal/modifica-occupazione-modal.component';
import {RegistrazioneModalComponent} from '../components/modal/registrazione-modal/registrazione-modal.component';
import {ErrorModalComponent} from '../components/modal/error-modal/error-modal.component';
import {MacroDestinazioneModalComponent} from '../components/modal/macro-destinazione-modal/macro-destinazione-modal.component';
import {ContattaciModalComponent} from '../components/modal/contattaci-modal/contattaci-modal.component';
import {PageNotFoundComponent} from '../page/page-not-found/page-not-found.component';
import {InfoModalComponent} from '../components/modal/info-modal/info-modal.component';
import {ComunicazioniModalComponent} from '../components/modal/comunicazioni-modal/comunicazioni-modal.component';
import {SessioneScadutaModalComponent} from '../components/modal/sessione-scaduta-modal/sessione-scaduta-modal.component';
import {CalcolaImportoVantaggioPrimePipe} from '../../pipe/calcola-importo-vantaggio-prime.pipe';
import {PrivacyPageComponent} from '../page/privacy-page/privacy-page.component';
import {FuoriTuttoComponent} from '../page/fuori-tutto/fuori-tutto.component';
import {RegistrazioneFormComponent} from '../components/registrazione-form/registrazione-form.component';
import {RecensioniPageComponent} from '../page/recensioni-page/recensioni-page.component';
import {RecensioniPageService} from '../services/gate/recensioni-page.service';
import {RecensioniCardComponent} from '../components/recensioni-card/recensioni-card.component';
import {HammerConfig} from '../services/common/hammer.gesture.config.service';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {AgmCoreModule} from '@agm/core';
import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import {PrezzoPiuBassoComponent} from '../page/prezzo-piu-basso/prezzo-piu-basso.component';
import {NewsletterComponent} from '../page/area-personale/sezioni-area-personale/newsletter/newsletter.component';
import {SearchEngineService} from '../components/search-egine/search-engine.service';
import {PromoFlashModalComponent} from '../components/modal/promo-flash-modal/promo-flash-modal.component';
import {PreferitiService} from '../components/preferiti/preferiti.service';
import {PartenzaNonDisponibileComponent} from '../components/modal/partenza-non-disponibile/partenza-non-disponibile.component';
import {LazyLoadImagesDirective} from '../directives/lazy-load-images.directive';
import {CancellazioneGratuitaComponent} from '../page/cancellazione-gratuita/cancellazione-gratuita.component';
import {ScaricabuonoComponent} from '../page/scaricabuono/scaricabuono.component';
import {BorsaviaggiDateAdapter} from '../services/common/dateAdapter.service';
import {ScalapayComponent} from "../page/scalapay/scalapay.component";
import {AttivaProfiloComponent} from '../page/attiva-profilo/attiva-profilo.component';
import {RecuperoPasswordModalComponent} from '../components/modal/recupero-password-modal/recupero-password-modal.component';
import {SafeHtmlPipe} from '../directives/safe-html.directive';
import {CampagnaService} from '../services/common/campagna.service';
import {RassegnaStampaComponent} from '../page/rassegna-stampa/rassegna-stampa.component';
import {LandingComponent} from '../page/landing/landing.component';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {UniversalDeviceDetectorService} from '../services/common/universal-device-detector.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ContattiModalComponent} from '../components/modal/contatti-modal/contatti-modal.component';
import {NoteLegaliModalComponent} from '../components/modal/note-legali-modal/note-legali-modal.component';
import {ModificaOccupazioneCrocieraModalComponent} from '../components/modal/modifica-occupazione-crociera-modal/modifica-occupazione-crociera-modal.component';
import {
  HammerModule,
  HAMMER_GESTURE_CONFIG, HAMMER_LOADER
} from '@angular/platform-browser';
import Hammer from '@egjs/hammerjs';
import {BannerManagerService} from '../services/common/banner-manager.service';
import {PrebootModule} from 'preboot';
import {TravelExpertComponent} from '../page/travel-expert/travel-expert.component';
import {GmapService} from '../services/common/gmap.service';
import {AggregaPuntiService} from '../services/common/aggregaPunti.service';
import {CalendarHeader} from "../components/calendar-header/calendarHeader.component";

registerLocaleData(localeIt);

export function initFactory(config: AppConfig, document: HTMLDocument, platformId: object) {
  // return () => config.load();
  return () => {
    if (isPlatformBrowser(platformId)) {
      const dom = ɵgetDOM();
      const styles: any[] = Array.prototype.slice.apply(window.document.querySelectorAll(`style[ng-transition]`));
      styles.forEach(el => {
        // Remove ng-transition attribute to prevent Angular appInitializerFactory
        // to remove server styles before preboot complete
        el.removeAttribute('ng-transition');
      });
      document.addEventListener('PrebootComplete', () => {
        // After preboot complete, remove the server scripts
        setTimeout(() => styles.forEach(el => dom.remove(el)));
      });
    }
    return config.load();
  };
}


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NavigationComponent,
    LoginSidenavComponent,
    ToolbarComponent,
    SearchVacanzeComponent,
    SearchItaliaComponent,
    SearchMacroComponent,
    SearchStruttureComponent,
    SearchCrociereComponent,
    BoxOffertaTopComponent,
    DestinazioniModalComponent,
    MacroBoxComponent,
    RegistrazioneFormComponent,
    DettaglioVacanzaGPage,
    DettaglioVacanzaPPage,
    DettaglioCrocieraPage,
    PrezzoPiuBassoComponent,
    PrimePagamentoComponent,
    RecensioniCardComponent,
    PopoverTrigger,
    PopoverComponents,
    RatingComponent,
    SearchEngineComponent,
    HomePageComponent,
    SearchResultsComponent,
    SearchResultsCrociereComponent,
    ResultCardComponent,
    SearchFiltersComponent,
    SearchResultsComponent,
    ComePrenotareComponent,
    QuestionarioPostVenditaComponent,
    LampadaDesideriComponent,
    TerminiCondizioniComponent,
    FooterComponent,
    ShareButtonComponent,
    HomePageComponent,
    AreaPersonaleComponent,
    ContainerDestinazioniComponent,
    DettaglioDestinazioniComponent,
    UploadImageProfileComponent,
    DatiPersonaliComponent,
    ListaPrenotazioniComponent,
    ListaDesideriComponent,
    HighlightDirective,
    LazyLoadImagesDirective,
    MacroDestinazionePageComponent,
    DettaglioDestinazioneNostraSelezioneComponent,
    PromiseButtonComponent,
    ShareButtonComponent,
    PreferitiComponent,
    RecensioniPageComponent,
    RassegnaStampaComponent,
    ScriviDirettoreComponent,
    InformativaPrivacyComponent,
    SuggerimentiComponent,
    LavoraConNoiComponent,
    DettaglioHotelComponent,
    InformativaDatiPersonaliComponent,
    PreferitiComponent,
    LoginModalComponent,
    CropimageComponent,
    FuoriTuttoComponent,
    FormErrorComponent,
    PartenzeIconsComponent,
    PromoFlashModalComponent,
    SuccessModalComponent,
    SessioneScadutaModalComponent,
    ModificaOccupazioneModalComponent,
    RegistrazioneModalComponent,
    PopoverTarget,
    ErrorModalComponent,
    BvSelectComponent,
    OrderByPipe,
    SafeHtmlPipe,
    CalcolaImportoVantaggioPrimePipe,
    ArraySortPipe,
    SommaPipe,
    FilterPipe,
    EuroFormatterPipe,
    FormazioneComponent,
    CaroselloComponent,
    FilterNewsPipe,
    DettaglioPartnersComponent,
    DettaglioDestinazioneComponent,
    DettaglioStrutturaPage,
    ReadmoreComponent,
    FiltroPartenzeComponent,
    CheckoutPageComponent,
    CheckoutFormComponent,
    CheckoutPageCrociereComponent,
    InfoPartenzaRowComponent,
    InfoRowComponent,
    PrimePageComponent,
    GiaClientePrimeBoxComponent,
    ContattaciModalComponent,
    GiaClientePrimeBoxComponent,
    MacroDestinazioneModalComponent,
    QuiDietroComponent,
    LinksBannerComponent,
    GoogleMapsComponent,
    MostraMappaComponent,
    CarteRegaloComponent,
    PrivacyPageComponent,
    DettaglioCartaRegaloComponent,
    VerificaTransazioneComponent,
    GiftCardComponent,
    LinksBannerComponent,
    VetrinaPrimeComponent,
    ModalDestinazioniVprimeComponent,
    LoginModalComponent,
    SuccessModalComponent,
    ModificaOccupazioneModalComponent,
    RegistrazioneModalComponent,
    ErrorModalComponent,
    ContattaciModalComponent,
    MacroDestinazioneModalComponent,
    DestinazioniModalComponent,
    ModificaPreventivoCrocieraModalComponent,
    PageNotFoundComponent,
    InfoModalComponent,
    ComunicazioniModalComponent,
    NewsletterComponent,
    PartenzaNonDisponibileComponent,
    CancellazioneGratuitaComponent,
    ScaricabuonoComponent,
    ScalapayComponent,
    AttivaProfiloComponent,
    RecuperoPasswordModalComponent,
    LandingComponent,
    ContattiModalComponent,
    NoteLegaliModalComponent,
    ModificaOccupazioneCrocieraModalComponent,
    TravelExpertComponent,
    CalendarHeader
  ],
  imports: [
    PrebootModule.withConfig({appRoot: 'borsaviaggi-fe'}),
    BrowserModule.withServerTransition({appId: 'borsaviaggi-fe'}),
    TransferHttpCacheModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSliderModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSliderModule,
    FormsModule,
    A11yModule,
    MatCardModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    HttpClientModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    NgxGalleryModule,
    MatInputModule,
    ScrollingModule,
    DragDropModule,
    MatTableModule,
    MatRadioModule,
    MatPaginatorModule,
    MatRadioModule,
    LayoutModule,
    MatMenuModule,
    ShareModule,
    ImageCropperModule,
    MatExpansionModule,
    MatCarouselModule,
    Ng5SliderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBMOeG_rV0to3P1DsphCsikNYO6qP0kE40',
      region: 'IT',
      language: 'it',
      libraries: ['places']
    }),
    AgmSnazzyInfoWindowModule,
    Ng5SliderModule,
    MatGridListModule,
    HammerModule,
    MatListModule,
    BrowserModule,
    SwiperModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule
  ],
  providers: [
    HttpClient,
    AppConfig,
    NavigationService,
    LoginSidenavService,
    LoaderService,
    ModalService,
    DestinazioneService,
    HomeService,
    SearchResultsService,
    RecensioniPageService,
    SearchResultsCrociereService,
    CampagnaService,
    GenericGateService,
    GmapService,
    CacheService,
    RatingService,
    MultimediaService,
    RicercaVacanzeService,
    RicercaCrociereService,
    AuthService,
    DatiPersonaliResolve,
    PromiseUtilService,
    UtilityService,
    LocalStorageService,
    RicercaVacanzeResolveService,
    MacroCategoriaResolveService,
    DettaglioGResolveService,
    DettaglioPResolveService,
    DettaglioPDescrizioneResolveService,
    DestinazioniMondoResolveService,
    DestinazioniItaliaResolveService,
    CheckoutResolveService,
    CheckoutPageCrociereComponent,
    RecensioniCardComponent,
    CheckoutCrociereResolveService,
    DettaglioGService,
    DettaglioPService,
    UserService,
    HammerConfig,
    ArraySortPipe,
    AggregaPuntiService,
    CalcolaImportoVantaggioPrimePipe,
    UtilsStringService,
    PromiseUtilService,
    DirettoreService,
    SuggerimentiService,
    LavoraConNoiService,
    LampadaDesideriService,
    ContainerDestinazioniService,
    FilterNewsPipe,
    GeocodeService,
    DettaglioPartnerResolveService,
    ListaPrenotazioniResolveService,
    DestinazioneTopResolveService,
    PreferitiService,
    DettaglioStrutturaPartnerResolveService,
    DettaglioDestinazioneResolveService,
    HotelMondoResolveService,
    SearchEngineService,
    HotelItaliaResolveService,
    DettaglioDestinazioneNostraSelezioneResolveService,
    VerificaTransazioneResolveService,
    DettaglioZResolveService,
    DettaglioZService,
    VerificaTransazioneService,
    CartaRegaloResolveService,
    CheckoutCrociereResolveService,
    BannerManagerService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: HAMMER_LOADER,
      useValue: () => import('@egjs/hammerjs')
        .then((m) => {
          (window as any).Hammer = m;
        })
    },

    {
      provide: DeviceDetectorService,
      useClass: UniversalDeviceDetectorService
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: DateAdapter, useClass: BorsaviaggiDateAdapter, deps: [MAT_DATE_LOCALE]},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalInterceptor,
      multi: true
    },
    {provide: APP_INITIALIZER, useFactory: initFactory, deps: [AppConfig, DOCUMENT, PLATFORM_ID], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
