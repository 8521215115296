<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center background-white">
  <div class="row mb-15px">
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xl-5" *ngIf="!isMobileDevice">
      <img class="prime-banner" src="../../../assets/img/img-super-travel-expert.jpg" alt="Banner Super Travel Expert"/>
      <h1 class="sottotitolo-prime mt-1em">Come funziona il servizio:</h1>
      <div class="row">
        <div class="col-3">
          <img src="../../../assets/img/icons/ico-expert-1.jpg" class="img-fluid" /></div>
        <div class="col-9 text-align-left pt-3">
          Compila Gratuitamente il form indicando i tuoi dati e
          fornendoci indicazione del viaggio che vuoi costruire.
        </div>
      </div>
      <div class="row mt-1em">
        <div class="col-3">
          <img src="../../../assets/img/icons/ico-expert-2.jpg" class="img-fluid" /></div>
        <div class="col-9 text-align-left pt-2">
          Sarai ricontattato dal nostro Super Travel Expert che
          ti fornirà una pre-consulenza gratuita in merito alla
          fattibilità della richiesta e le regole del servizio.
        </div>
      </div>
      <div class="row mt-1em">
        <div class="col-3">
          <img src="../../../assets/img/icons/ico-expert-3.jpg" class="img-fluid" /></div>
        <div class="col-9 text-align-left">
          Se vorrai passare alla fase di costruzione del viaggio
          con video-consulenza dedicata, ti verrà richiesto di
          aderire unicamente al nostro programma PRIME<br>
          [costo di 19€ - Per i clienti PRIME il servizio è gratuito]
        </div>
      </div>
      <div class="row mt-1em">
        <div class="col-3">
          <img src="../../../assets/img/icons/ico-expert-4.jpg" class="img-fluid" /></div>
        <div class="col-9 text-align-left pt-2">
          Il viaggio dei tuoi sogni diventa Realtà!
          ... e grazie a PRIME accumuli subito Sconti da spendere
          sul sito Borsaviaggi per la tua prossima vacanza!
        </div>
      </div>
      <div class="w-100 text-align-center mt-1em">
        <h1 class="sottotitolo-prime">Che aspetti?</h1>
        <span class="font-weight-bold">Richiedi subito la consulenza del nostro</span><br>
        <span class="font-weight-bold fs-18px">SUPER TRAVEL EXPERT!</span>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 text-align-left prime-desc-container">
      <h1 class="titolo-prime">COSTRUIAMO INSIEME IL VIAGGIO DEI TUOI SOGNI!</h1>
      <p class="prime-desc mt-3">
        <b>Sei un viaggiatore in cerca di esperienze personalizzate?</b><br>
        Richiedi la consulenza del nostro esperto... ti aiuterà a costruire il viaggio
        dei tuoi sogni, cucito sulle tue aspettative, totalmente su misura!<br><br>
        <span *ngIf="!isMobileDevice">Parlerai con un agente con esperienza, ma prima di tutto con un
        viaggiatore appassionato, che ha girato il mondo e che condividerà
          con te la sua esperienza, aiutandoti nella scelta del viaggio dei tuoi sogni.</span>
      </p>
      <p class="prime-desc" *ngIf="!isMobileDevice">
        <b>Per te un'assistenza a 360°!</b><br>
        Dopo aver valutato gratuitamente la fattibilità della tua richiesta, potrai
        fissare con lui una video-riunione di 45 minuti per definire assieme tutti i
        dettagli del viaggio fino alla definizione del preventivo di viaggio.
      </p>
      <p class="prime-desc" *ngIf="!isMobileDevice">
        <b>Un'esperienza di viaggio indimenticabile!</b><br>
        Durante il viaggio, il nostro esperto sara' il tuo assistente personale che
        potrai contattare direttamente per ogni esigenza in loco.
      </p>
      <mat-accordion *ngIf="isMobileDevice">
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="bg-accordion">
            <mat-panel-description class="color-white">
              Come funziona il servizio
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-3">
              <img src="../../../assets/img/icons/ico-expert-1.jpg" class="img-fluid" /></div>
            <div class="col-9 text-align-left pt-3 prime-desc">
              Compila Gratuitamente il form indicando i tuoi dati e
              fornendoci indicazione del viaggio che vuoi costruire.
            </div>
          </div>
          <div class="row mt-1em">
            <div class="col-3">
              <img src="../../../assets/img/icons/ico-expert-2.jpg" class="img-fluid" /></div>
            <div class="col-9 text-align-left pt-2 prime-desc">
              Sarai ricontattato dal nostro Super Travel Expert che
              ti fornirà una pre-consulenza gratuita in merito alla
              fattibilità della richiesta e le regole del servizio.
            </div>
          </div>
          <div class="row mt-1em">
            <div class="col-3">
              <img src="../../../assets/img/icons/ico-expert-3.jpg" class="img-fluid" /></div>
            <div class="col-9 text-align-left prime-desc">
              Se vorrai passare alla fase di costruzione del viaggio
              con video-consulenza dedicata, ti verrà richiesto di
              aderire unicamente al nostro programma PRIME<br>
              [costo di 19€ - Per i clienti PRIME il servizio è gratuito]
            </div>
          </div>
          <div class="row mt-1em">
            <div class="col-3">
              <img src="../../../assets/img/icons/ico-expert-4.jpg" class="img-fluid" /></div>
            <div class="col-9 text-align-left pt-2 prime-desc">
              Il viaggio dei tuoi sogni diventa Realtà!
              ... e grazie a PRIME accumuli subito Sconti da spendere
              sul sito Borsaviaggi per la tua prossima vacanza!
            </div>
          </div>
          <div class="w-100 text-align-center mt-1em">
            <h1 class="sottotitolo-prime">Che aspetti?</h1>
            <span class="font-weight-bold fs-14px">Richiedi subito la consulenza del nostro</span><br>
            <span class="font-weight-bold fs-18px">SUPER TRAVEL EXPERT!</span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="w-100 mt-4 mb-15px text-align-left">
        <form [formGroup]="formGroup">
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput required placeholder="Nome" name="nome" formControlName="nome">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cognome</mat-label>
            <input matInput required placeholder="Cognome" name="cognome" formControlName="cognome">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Email" name="email" formControlName="email">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cellulare</mat-label>
            <input matInput required placeholder="Cellulare" name="cellulare" formControlName="cellulare">
          </mat-form-field>
          <mat-form-field class="col col-md-12" appearance="outline">
            <mat-label>Descrivi il viaggio che vuoi costruire con il nostro esperto</mat-label>
            <textarea matInput required placeholder="Descrivi il viaggio che vuoi costruire con il nostro esperto" name="descrizioneViaggio" formControlName="descrizioneViaggio"></textarea>
          </mat-form-field>
          <!--mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Budget</mat-label>
            <input matInput required placeholder="Budget complessivo" name="budget" formControlName="budget">
          </mat-form-field-->
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Budget complessivo</mat-label>
            <input matInput type="number" placeholder="Budget complessivo" name="budget" formControlName="budget">
            <span matPrefix>€&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Orario in cui vuoi essere contattato</mat-label>
            <input matInput required placeholder="Orario in cui vuoi essere contattato" name="oraRicontatto" formControlName="oraRicontatto">
          </mat-form-field>
          <mat-checkbox formControlName="privacy" class="fs-12px left full-width" [checked]="true"> Ho letto ed accetto l'Informativa
            Privacy in
            merito al
            trattamento dei miei
            dati personali
          </mat-checkbox>
          <mat-checkbox formControlName="riceviNewsletter" class="fs-12px left full-width">Consenso per l'invio di materiale promozionale
            (facoltativo)
          </mat-checkbox>
          <div class="clearfix"></div>
          <button mat-raised-button class="button-attiva font-weight-bold mt-3" [disabled]="!buttonEnabled" (click)="registraRichiesta()">INVIA LA RICHIESTA</button>
        </form>
      </div>
    </div>
  </div>
</div>
