<div class="container border-r-2px mt-20px pt-10px text-align-justify background-white  ">
  <h2 class="color-text-blue-borsaviaggi  mb-5px text-align-center">CONDIZIONI GENERALI</h2><br>
  <div class="row  font-italic">
    <div class="col">
      <div>
        <a href="assets/pdf/Estratto_Assicurazione_Service_Aggiornata_2022.pdf" target="_blank">
          > Condizioni Polizza Annullamento e Medico Bagaglio Service+ con copertura Covid pre ed in loco - Europ Assistance - Attiva per prenotazioni a partire dal 20/12/2021
        </a>
      </div>
      <div>
        <a href="assets/pdf/Assicurazione-Annullamento-EuropAssistance-Covid.pdf" target="_blank">> Condizioni Polizza Annullamento e Medico Bagaglio
          Service+ con copertura Covid Europ Assistance - Attiva per prenotazioni confermate entro il 19/12/2021</a>
      </div>
      <div>
        <a href="assets/pdf/Set_Informativo_AMITRAVEL.pdf" target="_blank">
          > Condizioni Polizza Annullamento e Medico Bagaglio Service+ con copertura Covid pre ed in loco - AMI Assistance - Attiva per prenotazioni confermate entro il 30/04/2023
        </a>
      </div>
      <div>
        <a href="assets/pdf/Set_informativo_Amitravel_Ed_2_24.06.2022.pdf" target="_blank">
          > Condizioni Polizza Annullamento e Medico Bagaglio Service+ con copertura Covid pre ed in loco - AMI Assistance - Attiva per prenotazioni a partire dal 01/05/2023
        </a>
      </div>
      <!--div>
        <a href="assets/pdf/Assicurazione-Annullamento-EuropAssistance.pdf" target="_blank">> Condizioni Polizza Annullamento e Medico Bagaglio
          Service+ Europ Assistance - Attiva per prenotazioni confermate entro il 31/03/2021</a>
      </div>
      <div>
        <a href="assets/pdf/Assicurazione-Medico-Bagaglio-EuropAssistance.pdf" target="_blank">> Condizioni Polizza Medico Bagaglio
          Europ
          Assistance</a>
      </div-->
    </div>
  </div>
  <div class="row mt-40px mb-20px">
    <div class="col">
      <div>
        <h4 class="color-text-blue-borsaviaggi">PREMESSA</h4>
        <p>
          BORSAVIAGGI è un marchio ed un´organizzazione di distribuzione e vendita di servizi di viaggi e
          turismo, che opera con regolare autorizzazione amministrativa n° 104/07 per l´esercizio al dettaglio
          dell´attività conformemente alle norme della Provincia di Roma ed in ottemperanza agli obblighi di legge opera
          dietro copertura assicurativa per la responsabilità civile professionale stipulata con la compagnia UNIPOLSAI
          ASSICURAZIONI polizza num. 1/72929/319/102592197. Per il raggiungimento dello scopo sociale BORSAVIAGGI S.r.l.
          si impegna a negoziare al meglio con gli organizzatori e fornitori di servizi turistici, quei contratti per i
          quali
          il cliente gli ha conferito mandato di stipulare mediante la sottoscrizione della presente modulistica
          (denominata
          CONTRATTO).
        </p>
      </div>
      <div class="mt-40px">
        <h4 class="color-text-blue-borsaviaggi">1 - OGGETTO DEL CONTRATTO</h4>
        <p>
          Il presente CONTRATTO è il solo titolo giuridico regolante il rapporto fra il contraente principale (d´ora
          innanzi
          cliente) e BORSAVIAGGI S.r.l. Esso si configura come contratto di mandato (artt.1703-1730 cod. civ.),
          ulteriormente
          qualificabile come contratto di intermediazione di viaggio (C.C.V. art.1 n° 3 e 6, artt. 24-31) o di vendita
          di
          pacchetto turistico (decreto legislativo n. 206 del 6/9/2005 artt. 84/85 Cod.Cons.). In forza della stipula
          del
          presente CONTRATTO il cliente conferisce a BORSAVIAGGI S.r.l. mandato con rappresentanza a stipulare contratto
          in
          nome e per conto proprio, oltre che delle persone elencate nell´allegato MODULO A, avente ad oggetto il
          pacchetto
          turistico, il viaggio o soggiorno organizzato ovvero i singoli servizi per l´acquisto dei quali il contraente
          delega
          completamente BORSAVIAGGI S.r.l. anche relativamente al servizio di spedizione/consegna dei documenti
          definitivi
          (voucher e biglietti). Con la sottoscrizione del presente CONTRATTO, al contempo, il cliente autorizza
          BORSAVIAGGI
          S.r.l. a sostituire a sè l´agenzia (art.1717 cod. civ.) nell´esecuzione del mandato. Ai sensi dell´art.17
          della
          C.C.V. il contratto stipulato da BORSAVIAGGI S.r.l. con l´organizzatore/fornitore del servizio è considerato
          come se
          fosse concluso direttamente dal cliente.
        </p>
      </div>
      <div class="mt-40px">
        <h4 class="color-text-blue-borsaviaggi">2 - CONCLUSIONE DEL CONTRATTO</h4>
        <p>
          Il contratto di mandato all´acquisto
          dei servizi è concluso nel momento in cui il cliente sottoscrive il presente MODULO B e l´allegato MODULO A.
          La
          prenotazione del servizio si intende perfezionata, con conseguente obbligazione contrattuale a carico
          dell´organizzatore/fornitore, solo al momento della conferma scritta rilasciata da quest´ultimo.
        </p>
      </div>
      <div class="mt-40px">
        <h4 class="color-text-blue-borsaviaggi">3 - DOCUMENTAZIONE CONTRATTUALE</h4>
        <p>
          I contratti di cui all´art.1,1°e 2° comma, sono costituiti e documentati da:
          a) presente MODULO B riportante le condizioni generali del mandato all´acquisto, le specifiche dei servizi
          esclusivi
          offerti da BORSAVIAGGI S.r.l. alla propria clientela, il richiamo per specifica approvazione delle clausole
          del
          regolamento o condizioni generali di partecipazione proposte dall´organizzatore/fornitore e rientranti nelle
          previsioni degli artt.1341-1342 cod.civ.; - b) allegato denominato MODULO A riportante i dati tecnici del
          viaggio,
          le informazioni supplementari al cliente, le modalità di pagamento; - c) SCHEDA DI PRENOTAZIONE [foglio di
          convocazione, voucher o documento similare] rilasciato da Borsaviaggi S.r.l. nei giorni precedenti l´inizio
          del
          soggiorno/partenza.Il CONTRATTO, secondo il disposto di cui all´art. 2725 cod.civ., può essere provato solo
          esibendo
          la SCHEDA DI PRENOTAZIONE o, in caso di suo smarrimento, fornendo copia autentica della conforme denuncia
          presentata
          all´Autorità competente. Prima della partenza le informazioni al cliente devono essere completate attraverso
          la
          consegna dei seguenti documenti: - d) documenti di viaggio, di soggiorno o comunque relativi ai singoli
          servizi,
          quali biglietti di trasporto, vouchers alberghieri, vouchers per servizi vari. In alcuni casi e generalmente
          per i
          servizi singoli di trasporto, già all´atto della prenotazione, viene rilasciato in luogo della SCHEDA DI
          PRENOTAZIONE il titolo di viaggio definitivo del vettore o altro fornitore, che fa le veci dei documenti c),
          d). In
          questo caso il contratto si intende già perfezionato anche nei rapporti con il vettore o fornitore. Si avverte
          che
          lo smarrimento del titolo di viaggio da parte del cliente produce in genere l´impossibilità di usufruire del
          servizio.
        </p>
      </div>
      <div class="mt-40px">
        <h4 class="color-text-blue-borsaviaggi">4 - OBBLIGHI DEL CLIENTE</h4>
        <p>
          Il cliente è tenuto a versare all´agenzia
          le somme richieste per l´avallo e garanzia su eventuali penalità da annullamento, gli acconti e l´integrale
          pagamento di tutti i servizi acquisiti, entro termini previsti dai singoli contratti stipulati a suo nome con
          l´organizzatore/fornitore, così come ad adempiere a tutti gli obblighi da tali contratti previsti a suo
          carico. In
          caso di rinuncia al viaggio, al pacchetto o ad alcuno dei servizi acquistati, da esprimere nei modi e nei
          termini
          previsti dalle condizioni generali ovvero del regolamento dell´organizzatore/fornitore, il cliente avrà
          diritto ai
          rimborsi totali o parziali delle somme anticipate secondo le disposizioni del contratto stipulato a suo nome
          da
          BORSAVIAGGI S.r.l. Il presente MANDATO si presume oneroso nei rapporti tra cliente e BORSAVIAGGI S.r.l. ai
          sensi
          dell´art.1709 cod.civ., anche se il corrispettivo a carico del cliente può essere ridotto o azzerato in
          funzione dei
          compensi pagati a BORSAVIAGGI S.r.l. dai fornitori dei servizi. L´ammontare dell´eventuale corrispettivo a
          carico
          del cliente deve in ogni caso essere comunicato a BORSAVIAGGI S.r.l. prima della conclusione del mandato. In
          caso di
          modifica o annullamento anche parziale da parte del cliente la BORSAVIAGGI S.r.l. si riserva il diritto di
          trattenere per spese d´agenzia l´importo euro; 50,00 per persona. Ogni eventule aggiunta di servizi, costi e
          specifiche varie scritte dal cliente sul modulo sono da considerarsi nulle.
        </p>
      </div>
      <div class="mt-40px">
        <h4 class="color-text-blue-borsaviaggi">5 - RESPONSABILITÀ BORSAVIAGGI S.R.L.</h4>
        <p>
          La BORSAVIAGGI S.r.l. non
          sarà responsabile se non conformemente a quanto previsto a carico del mandatario, dell´intermediario e del
          venditore, rispettivamente dalle sovra richiamate norme degli artt.1703-1730 cod.civ., della C.V.V., del
          decreto
          legislativo n. 206 del 6/9/2005. In particolare la BORSAVIAGGI S.r.l. è responsabile per non aver eseguito il
          mandato con la diligenza professionale, ma non già per l´inadempimento totale o parziale
          dell´organizzatore/fornitore agli obblighi da essi stessi assunti per effetto della stipulazione del contratto
          con
          il mandatario del cliente. Nella fattispecie di cui all´ultima parte del precedente comma gli unici soggetti
          responsabili dei danni eventualmente arrecati al cliente, sono l´organizzatore e/o il fornitore del servizio.
          L´autorizzazione di cui all´art.1 comma 2 non implica esonero di responsabilità ai sensi dell´art.1717
          cod.civ.,
          restando BORSAVIAGGI S.r.l. obbligato nei confronti del cliente per i pregiudizi che questi avesse a subire in
          conseguenza di fatto imputabile alla BORSAVIAGGI S.r.l.
        </p>
      </div>
      <div class="mt-40px">
        <h4 class="color-text-blue-borsaviaggi">6 - DICHIARAZIONE DEL CLIENTE</h4>
        <p>
          Il cliente nel sottoscrivere il presente CONTRATTO dichiara, anche a nome e per conto delle persone di cui
          all´elenco incluso nell´allegato MODULO A:
          a) di essere stato informato che l´organizzatore/fornitore dei servizi turistici è NOME TOUR OPERATOR di aver
          acquisito piena conoscenza delle condizioni, che conseguentemente accetta, riportate oltre che nel presente
          MODULO B
          e nell´accluso MODULO A (di cui il paragrafo COME PRENOTARE è parte integrante), anche nei cataloghi
          dell´organizzatore/fornitore, opuscoli, pieghevoli e qualsivoglia altra documentazione in ordine al pacchetto
          turistico, al viaggio o soggiorno organizzato, ovvero ai singoli servizi; di conoscere le norme di espatrio e
          le
          formalità relative ai documenti di identità richiesti, informazioni verificabili attraverso l´ufficio
          consolare
          della nazione di destinazione o attraverso il sito del Ministero degli Affari Esteri all´indirizzo internet
          www.viaggiaresicuri.it/ - b) di dare atto che tutte le informazioni previste dalla C.V.V., dal decreto
          legislativo
          n. 206 del 6/9/2005 gli sono state ritualmente fornite, fatte salve quelle previste dagli artt. 87-88 del
          citato
          decreto, che gli verranno trasmesse prima della partenza unitamente ai titoli di viaggio; - c) Il contraente
          espressamente dichiara di aver ricevuto puntualmente da Borsaviaggi Srl le specifiche informazioni riguardanti
          le
          norme, i documenti necessari e le procedure formali di ingresso nel paese di transito e/o destinazione anche
          relative ai partecipanti di nazionalità diversa da quella italiana, sollevando Borsaviaggi srl da ogni
          responsabilità in caso di mancato imbarco dovuto ad irregolarità. Il contraente dichiara di essere stato
          avvisato
          che tali informazioni sono verificabili attraverso l´Ambasciata e/o l´ufficio consolare della nazione di
          destinazione o, attraverso il sito del Ministero degli Affari Esteri all´indirizzo internet
          www.viaggiaresicuri.it/
          - d) di essere consapevole che il contratto avente ad oggetto il pacchetto turistico, il viaggio organizzato
          ovvero
          i singoli servizi si intenderà concluso solo al momento di accettazione da parte dell´organizzatore/fornitore
          della
          proposta contrattuale ad esso stesso inoltrata, in forza del presente MANDATO, da BORSAVIAGGI S.r.l.; - e) di
          essere
          stato edotto che i contratti stipulati da BORSAVIAGGI S.r.l. con l´organizzatore/fornitore potranno contenere
          una
          clausola arbitrale o di composizione amichevole delle controversie; - f) di dare atto che titoli di credito o
          somme
          depositate come avallo e garanzia su eventuali annullamenti, prima della conferma scritta inviata dalla
          BORSAVIAGGI
          S.r.l., non sono da considerare in nessun caso caparra confirmatoria. - g) di obbligarsi a richiedere
          qualsiasi
          variazione o rimborso parziale o totale dei servizi acquistati solo dietro autorizzazione scritta della
          BORSAVIAGGI
          S.r.l. ed autorizza la BORSAVIAGGI S.r.l. a recuperare in via diretta, ove possibile attraverso i titoli di
          credito
          depositati, importi addebitati dai fornitori dei servizi, originati da mancato pagamento o variazioni e
          rimborsi
          parziali o totali avvenuti senza autorizzazione della BORSAVIAGGI S.r.l. stessa. - h) di essere stato
          informato
          sull´orario dei voli e sulla loro variabilità; e di dover contattare BORSAVIAGGI S.r.l. o il tour operator
          organizzatore del viaggio il giorno lavorativo precedente la partenza, per avere riconferma degli orari dei
          voli e
          di presentazione. - i) di essere stato informato che tutte le informazioni e le fotografie riguardanti le
          strutture
          pubblicate da BORSAVIAGGI S.r.l. sono atte a fornire un ulteriore servizio al clienti e non possono essere
          ritenute
          parte vincolante di vendita. Le informazioni sono fornite in completa buona fede e quindi BORSAVIAGGI S.r.l.
          non è
          da ritenersi responsabile in caso esse risultino non accurate o incomplete. - l) di essere stato edotto che
          non
          risulta applicabile a norma dell´art 55 lett.b) D.lgs 6/9/06 il diritto di recesso di cui all´art.64 e
          seguenti
          nonchè gli artt.52 e 53 ed il c. 1 art.54 - m) di essere stato informato che la normativa vigente prevede che
          l'orario dei voli di andata e/o ritorno può subire variazioni in partenza e/o arrivo entro limiti stabiliti;
          tali
          spostamenti di orario, se comprendono le ore precedenti/posteriori la mezzanotte, possono generare anche il
          cambio
          di giorno di partenza/arrivo di andata/ritorno.
        </p>
      </div>
    </div>
  </div>
</div>
