import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {DestinazioneService} from "../destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioGResolveService {
  constructor(private destSrv: DestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {

    let dataParam = {
      "idOfferta": routeSnap.params.idG,
      "pageNumber": 1,
      "pageSize": 20,
      "idLuogoPartenza": routeSnap.params.idLuogoPartenza
    };

    return this.destSrv.getG(dataParam)
      .catch(err => {
        return err;
      });
  }
}
