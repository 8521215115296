<div class="container">
  <div class="row">
    <div class="col-6 d-flex flex-column justify-content-center align-items-center">
      <div>
        <h1 class="text-align-center">Oops!</h1>
        <h2>Il contenuto che stai cercando non è più disponibile!</h2>
      </div>
      <div class="text-align-center mt-5">
        <ul class="link">
          <li>
            <a routerLink="">Home</a>
          </li>
          <li>
            <a routerLink="/come-prenotare">Come Prenotare</a>
          </li>
          <li>
            <a routerLink="/prime">Diventa Prime</a>
          </li>
          <li>
            <a routerLink="/prezzo-piu-basso">Garanzia Prezzo Più Basso</a>
          </li>
          <li>
            <a routerLink="/qui-dietro-ci-siamo-noi">Qui Dietro Ci Siamo Noi</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-6">
      <img src="assets/img/page-not-found/404-not-found.svg" alt="pagina non trovata">
    </div>
  </div>
</div>
