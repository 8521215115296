<mat-form-field appearance="outline" [ngClass]="classContainer"
                [ngStyle]="{'text-align': textAlign ? textAlign : 'left'}"
                class="hwd-select full-width">
  <mat-label>{{placeholder}}</mat-label>
  <div class="howdace-ms-spinner-container">
    <div class="howdace-ms-spinner-wrapper">
      <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
    </div>
  </div>
  <!-- Multi selection mode -->
  <mat-select multiple="{{multi}}" #matSelect
              [formControl]="control"
              [placeholder]="placeholder"
              (openedChange)="clearFilter()"
              [required]="required"
              panelClass="howdace-select"
              [matTooltip]="tooltipList.join('\n')"
              [matTooltipClass]="'my-tooltip'"
              [class.error]="errorState">
    <div>
      <!-- Search -->
      <mat-form-field [ngClass]="{'col-xs-10':multi,'col-xs-12':!multi}" class="form-field-item">
        <input type="text" matInput placeholder="Cerca"
               [(ngModel)]="filterText"
               autocomplete="off"
               (input)="filterOptions($event.target.value)">
      </mat-form-field>
      <!-- Add value -->
      <ng-container *ngIf="addMode">
        <mat-form-field class="form-add-value"> <!-- [ngClass]="{'col-sm-10':multi,'col-sm-12':!multi}" -->
          <!-- <button mat-icon-button></button> -->
          <input *ngIf="!typeNumber" type="text" [(ngModel)]="newValue" matInput placeholder="Aggiungi valore"
                 autocomplete="off">
          <input *ngIf="typeNumber" type="number" [(ngModel)]="newValue" matInput placeholder="Aggiungi valore numerico"
                 autocomplete="off">
        </mat-form-field>

        <span>
          <button mat-button matSuffix mat-mini-fab aria-label="Add" style="background-color: cadetblue"
                  (click)="asyncReq()">
            <mat-icon>add</mat-icon>
          </button>
        </span>
      </ng-container>
    </div>

    <div class="howdace-ms-option-wrapper" *ngIf="ordered">
      <mat-option *ngFor="let option of optionsListFiltered | sort: ordered"
                  [value]="option.value"
                  [hidden]="option.hidden"
                  (click)="populateTooltipValue(option.value)">
        {{option.label}}
      </mat-option>
    </div>
    <div class="howdace-ms-option-wrapper" *ngIf="!ordered">
      <mat-option *ngFor="let option of optionsListFiltered"
                  [value]="option.value"
                  [hidden]="option.hidden"
                  (click)="populateTooltipValue(option.value)">
        {{option.label}}
      </mat-option>
    </div>
  </mat-select>
  <button mat-button
          *ngIf="(!Array.isArray(control.value) && control.value !== null && control.value !== undefined && control.value !=='')
          || (Array.isArray(control.value)  && control.value.length > 0 ) && !disabled"
          matSuffix
          [hidden]="disabled || disableClearBtn"
          type="button"
          mat-icon-button aria-label="Clear"
          (click)="actionClearField($event)">
    <mat-icon>close</mat-icon>
  </button>
  <howdace-form-error [formControlParam]="control"></howdace-form-error>
</mat-form-field>
