import {Injectable} from "@angular/core";
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class UserService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public login(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/login", data);
  }

  public logout(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/logout");
  }

  public new(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/new", data);
  }

  public edit(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/edit", data);
  }

  public addListaDesideriUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/addListaDesideriUtente", data);
  }

  public addListaDesideriCrocieraUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/addListaDesideriCrocieraUtente", data);
  }

  public deleteListaDesideriUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/deleteListaDesideriUtente", data);
  }

  public deleteListaDesideriCrocieraUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/deleteListaDesideriCrocieraUtente", data);
  }

  public getListaDesideriUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/getListaDesideriUtente", data);
  }

  public getFotoUtente(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/user/getFotoUtente");
  }

  public putFotoUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/putFotoUtente", data);
  }


  public getUtente(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/getProfiloUtente");
  }

  public getNewsLetterUtente(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/getNewsletterUtente");
  }

  public updateNewsletterUtente(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/updateNewsletterUtente", data);
  }

  public getListaPrenotazioni(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/user/getListaPreventiviUtente");
  }

  public editPassword(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/editPassword", data);
  }

  public confermaRegistrazione(idUtente): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, '/user/confermaRegistrazione/' + idUtente);
  }

  public recuperaPassword(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, '/user/recuperaPassword', data);
  }
}
