import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-partenze-icons',
  templateUrl: './partenze-icons.component.html',
  styleUrls: ['./partenze-icons.component.scss']
})
export class PartenzeIconsComponent implements OnInit {
  @Input() partenza;
  public iconsObj = [
    {servizio: "isAnimali", icon: "../../../assets/img/icone-partenze/animali.svg", title: "Animali ammessi"},
    {servizio: "isBimbiGratis", icon: "../../../assets/img/icone-partenze/bimbi-1.svg", title: "Bimbi gratis"},
    {servizio: "isCapodanno", icon: "../../../assets/img/icone-partenze/capodanno.svg", title: "Capodanno"},
    {servizio: "isFamigliaNumerosa", icon: "../../../assets/img/icone-partenze/famiglia-xl.svg", title: "Famiglia numerosa"},
    {servizio: "isLastMinute", icon: "../../../assets/img/icone-partenze/last-minute.svg", title: "Last minute"},
    {servizio: "isNatale", icon: "../../../assets/img/icone-partenze/natale.svg", title: "Natale"},
    {servizio: "isNoFuel", icon: "../../../assets/img/icone-partenze/fuel.svg", title: "No fuel"},
    {servizio: "isNoTransfer", icon: "../../../assets/img/icone-partenze/transfer.svg", title: "No transfer"},
    {servizio: "isPrenotaPrima", icon: "../../../assets/img/icone-partenze/prenota-prima.svg", title: "Prenota prima"},
    {servizio: "isSingle", icon: "../../../assets/img/icone-partenze/single.svg", title: "Singola in Offerta"},
  ];

  constructor() {
  }

  ngOnInit() {

    if (this.partenza.iconaPromozione) {
      this.iconsObj.push({ servizio: "isPromozioneAttiva", icon: this.partenza.iconaPromozione, title: this.partenza.nomePromozione });
    }

  }
}
