<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px background-white">
  <h1 class="color-text-blue-borsaviaggi text-align-center mb-20px">Rassegna Stampa</h1>
  <div class="row">
    <div class="col-xs-5 col-sm-5 col-md-5 d-none d-sm-block">
      <img src="../../../assets/img/img-articoli.jpg" class="img-fluid" />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-7 fs-14px">
      <b>Gli articoli pubblicati in collaborazione
                con Borsaviaggi.it</b><br />
      Dal 1993 ad oggi oltre 80 testate si sono servite delle nostre informazioni producendo
      oltre 1000 pubblicazioni rintracciabili nel nostro database.<br />
      <br />
      <b>Collaborazione attiva: riservato a
                giornalisti ed operatori di comunicazione</b><br />
      Per promuovere attivamente nuove forme e flussi di comunicazione mettiamo gratuitamente
      a disposizione dei colleghi accreditati, un pacchetto di servizi redazionali e viaggi
      atti a favorire l'approfondimento, la specializzazione e lo scambio professionale
      nel settore della comunicazione turistica. Invitiamo i colleghi interessati a contattare
      il nostro responsabile per ottenere ulteriori informazioni sulle modalità di adesione
    </div>
  </div>
  <div class="row mt-2rem">
    <div *ngFor="let articolo of listaArticoli" class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      {{articolo.data}} - <a href="{{articolo.link}}" *ngIf="articolo.link.length > 0" target="_blank">{{articolo.descrizione}}</a>
      <a href="https://cdn.borsaviaggi.it/upload_foto/rassegna/{{articolo.fileName}}" *ngIf="articolo.fileName.length > 0" target="_blank">{{articolo.descrizione}}</a>
    </div>
  </div>
</div>
