import {Component, Inject, OnInit} from '@angular/core';
import {ModalInterface} from "../../../interface/modal.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InfoModalComponent>,
              private route: Router,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<InfoModalComponent>) {
  }

  ngOnInit(): void {
  }

  public navigateG() {
    if (this.data && this.data.dataParam && this.data.dataParam.urlG) {
      this.dialogRef.close();
      this.route.navigate([this.data.dataParam.urlG]);
    }
  }
}
