/**
 * Throws an exception for the case when popover trigger doesn't have a valid popover instance
 */
export function throwPopoverMissingError() {
  throw Error(`popover-trigger: must pass in an popover instance.

    Example:
      <borsaviaggi-popover #popover="opover"></borsaviaggi-popover>
      <button [popoverTriggerFor]="popover"></button>`);
}

/**
 * Throws an exception for the case when popover's PopoverPositionX value isn't valid.
 * In other words, it doesn't match 'before' or 'after'.
 */
export function throwPopoverInvalidPositionX() {
  throw Error(`PopoverPositionX value must be either 'before' or after'.
      Example: <borsaviaggi-popover popoverPositionX="before" #popover="popover"></borsaviaggi-popover>`);
}

/**
 * Throws an exception for the case when popover's popoverPositionY value isn't valid.
 * In other words, it doesn't match 'above' or 'below'.
 */
export function throwPopoverInvalidPositionY() {
  throw Error(`PopoverPositionY value must be either 'above' or below'.
      Example: <borsaviaggi-popover popoverPositionY="above" #popover="popover"></borsaviaggi-popover>`);
}
