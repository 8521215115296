import {Component, OnInit} from '@angular/core';
import {ContainerDestinazioniService} from "../../services/gate/container-destinazioni.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'borsaviaggi-container-destinazioni',
  templateUrl: './container-destinazioni.component.html',
  styleUrls: ['./container-destinazioni.component.scss']
})
export class ContainerDestinazioniComponent implements OnInit {

  public listaNazioniViaggiMondo: any;
  public data: any;
  public nationCount: number;
  public twoColumnsThreshold: number;
  public pathHotel;

  constructor(private cDestService: ContainerDestinazioniService, private route: ActivatedRoute) {
    this.pathHotel = this.route.snapshot.url[0].path;
    let textStart = 'Scopri le offerte e i viaggi last minute per le migliori ';
    this.data = {
      imgUrl: 'assets/img/viaggi-mondo.png'
    };
    switch (this.route.snapshot.url[0].path) {
      case 'viaggi-italia':
        this.data.title = 'Destinazioni in Italia';
        this.data.text = textStart + 'destinazioni in italia';
        break;
      case 'viaggi-mondo':
        this.data.title = 'Destinazioni nel Mondo';
        this.data.text = textStart + 'destinazioni nel mondo';
        break;
      case 'hotel-mondo':
        this.data.title = 'Hotel nel Mondo';
        this.data.text = textStart + 'hotel nel mondo';
        break;
      case 'hotel-italia':
        this.data.title = 'Hotel in Italia';
        this.data.text = textStart + 'hotel in italia';
    }
  }

  ngOnInit() {
    this.getDestinazioni();

  }

  public getPathHotel() {
    let ret;
    switch (this.route.snapshot.url[0].path) {
      case 'hotel-italia':
        ret = "OFFERTE-HOTEL-ITALIA/";
        break;
      case 'hotel-mondo':
        ret = "OFFERTE-HOTEL-MONDO/";
        break;
      default:
        ret = "OFFERTE-LAST-MINUTE/";
    }
    return ret;
  }


  public makeUrlSeoHrefForNation(n) {
    let ret = this.getPathHotel();
    ret += n.nazione + "/";
    ret += Number(n.idNazioneBv ? n.idNazioneBv : n.idNazione) + "/";
    ret += "n";
    ret += this.route.snapshot.url[0].path === "viaggi-italia" ? "/i" : "/m";
    return ret.toLowerCase();
  }

  public makeUrlSeoHrefForDest(dest) {
    let ret = this.getPathHotel();
    ret += dest.destinazione.toLowerCase().replace(/\s/g, "-") + "/";
    ret += (dest.idDestinazioneBv ? Number(dest.idDestinazioneBv) : Number(dest.idDestinazione)) + "/";
    ret += "d";
    ret += this.route.snapshot.url[0].path === "viaggi-italia" ? "/i" : "/m";

    return ret.toLowerCase();
  }

  private getDestinazioni() {
    this.listaNazioniViaggiMondo = this.route.snapshot.data.dataResolve.body.data.listaNazioniViaggiMondo;
    this.getCountNazioni();
  }

  public getCountNazioni() {
    let counter = 0;
    this.listaNazioniViaggiMondo.forEach((nazione) => {
      counter++;
    });
    //console.log("Numero nazioni: " + counter);
    this.nationCount = counter;
    this.twoColumnsThreshold = Math.ceil(this.nationCount / 2);
  }

}
