import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/common/auth.service';
import {ModalService} from '../../services/common/modal.service';
import {User} from '../../model/user.dto';
import {LoginSidenavService} from "../login-sidenav/loginSidenav.service";


@Component({
  selector: 'bv-gia-cliente-prime-box',
  templateUrl: './gia-cliente-prime-box.component.html',
  styleUrls: ['./gia-cliente-prime-box.component.scss']
})
export class GiaClientePrimeBoxComponent implements OnInit {

  public image: any;
  public user: User;

  constructor(private modalSrv: ModalService,
              public authSrv: AuthService,
              private loginSideSrv: LoginSidenavService) {
    this.user = this.authSrv.getUser();

  }

  ngOnInit() {
    this.image = "../../../assets/img/icons/cliente-prime.png";
  }


  public actionLogin() {
    this.loginSideSrv.open();
    /*this.modalSrv.openLoginModal({}, (data) => {
      if (data)
        this.user = data;
      this.authSrv.setUser(this.user);
    });*/
  }
}
