import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class RicercaVacanzeService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public ricercaVacanze(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/searchOfferteVacanze", param);
  }


}
