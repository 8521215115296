import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";
import {MacroBoxEnum} from "../../components/macro-box/config-layout.enum";
import {MacroBoxInterface} from "../../components/macro-box/config.interface";
import {UtilsStringService} from "../common/utils-string.service";
import {SEO_OFFERTE} from "../../constants/seo.const";
import {ImageUtilService} from "../common/image.util.service";

@Injectable()
export class HomeService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getHomeData(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/offerta/getHomeElements");
  }

  public getDettaglioTipologiaOfferta(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getTipologiaTopItems", param);
  }

  public iscrizioneNewsletter(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/user/iscrizioneNewsletter", param);
  }

  public getVetrinaOffertePrime(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/banner/getVetrinaOffertePrime", param);
  }

  public static macroBoxConfig() {
    return {
      listaDiNozze: {
        boxConfigType: MacroBoxEnum.BIG_TEXT_V1,
        imgBackground: ImageUtilService.supportWebP() ? "assets/img/home/lista-nozze-macro.webp" : "assets/img/home/lista-nozze-macro.png",
        titolo: "LISTA DI NOZZE",
        sottoTitolo: "su Borsaviaggi.it",
        nomePortale: "Borsaviaggi.it",
        btnText: "Leggi",
        btnUrlLink: "https://www.ilmioviaggiodinozze.org/"
      },
      regalaUnViaggio: {
        boxConfigType: MacroBoxEnum.BIG_SCONTO_V1,
        imgBackground: ImageUtilService.supportWebP() ? "assets/img/home/regala-un-viaggio.webp" : "assets/img/home/regala-un-viaggio.png",
        titolo: "Regala un BUONO viaggio",
        sottoTitolo: "5%",
        btnText: "Leggi",
        nomePortale: "Borsaviaggi.it",
        btnUrl: "regala-un-buono-vacanza"
      },
      giftCard: {
        boxConfigType: MacroBoxEnum.BIG_TEXT_V2,
        imgBackground: ImageUtilService.supportWebP() ? "assets/img/carte-regalo/gift-card-banner-home.webp" : "assets/img/carte-regalo/gift-card-banner-home.png",
        titolo: "Gift Card",
        cssClass: "gift-card",
        sottoTitolo: "con la tua dedica speciale!",
        btnText: "Leggi",
        nomePortale: "Borsaviaggi.it",
        btnUrl: "carte-regalo"
      },
      superTravelExpert: {
        boxConfigType: MacroBoxEnum.BIG_TEXT_V2,
        imgBackground: ImageUtilService.supportWebP() ? "assets/img/banner-super-travel-expert-home.webp" : "assets/img/banner-super-travel-expert-home.jpg",
        titolo: "Super Travel Expert!",
        cssClass: "super-travel-expert",
        sottoTitolo: "Costruiamo insieme il viaggio dei tuoi sogni",
        btnText: "Inizia",
        nomePortale: "Borsaviaggi.it",
        btnUrl: "super-travel-expert"
      },
      utilizzoCashback: {
        boxConfigType: MacroBoxEnum.BIG_TEXT_V2,
        imgBackground: ImageUtilService.supportWebP() ? "assets/img/home/prime-banner-no-scritte.webp" : "assets/img/home/prime-banner-no-scritte.jpg",
        titolo: "Il tuo Cashback PRIME è di:",
        sottoTitolo: "Scopri come ottenere fino 200€ di sconti",
        btnText: "Leggi",
        nomePortale: "Borsaviaggi.it",
        btnUrl: "prime",
        valoreCashback: "0,00"
      }
    }
  }

  public static macroSelezioniBoxConfigParser(paramData): Array<MacroBoxInterface> {
    let ret = [];
    paramData.map(
      (currItem) => {
        let tempObj = {
          boxConfigType: MacroBoxEnum.MACRO_VERTICAL_BOX,
          imgBackground: ImageUtilService.supportWebP() ? currItem.urlIcona.replace(/.png|.jpg|.jpeg/gi, ".webp") : currItem.urlIcona,
          titolo: currItem.titolo,
          sottoTitolo: currItem.descrizione,
          btnText: null,
          nomePortale: null,
          btnUrlLink: currItem.collegamento || null,
          btnUrl: currItem.collegamento ? null : "/" + UtilsStringService.replaceAllSpaces(currItem.titolo.trim(), '-').toLowerCase() + "/" +
            currItem.idSelezione + "/" + SEO_OFFERTE.OFFERTA_MACRO_KEYWORD
        };
        ret.push(tempObj);
      }
    );

    return ret;
  }

  public getBuoniScontoUtente(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/user/getBuoniScontoUtente")
  }
}
