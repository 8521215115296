export class ResultCardConfig {
    vertical: boolean;
    isVacanze: boolean;
    isCrociere: boolean;
    isListaPrenotazioni: boolean;
    isListaDesideri: boolean;

    constructor(obj?: any){
        this.vertical = obj && obj.vertical || null;
        this.isVacanze = obj && obj.isVacanze || null;
        this.isCrociere = obj && obj.isCrociere || null;
        this.isListaPrenotazioni = obj && obj.isListaPrenotazioni || null;
        this.isListaDesideri = obj && obj.isListaDesideri || null;
    }
}
