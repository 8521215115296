import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {MacroDestinazioneService} from "../macro-destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioDestinazioneNostraSelezioneResolveService {
  constructor(private macroDestSrv: MacroDestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let dataParam = {
      "idDestinazione": routeSnap.params.idDestinazione,
      "idSelezione": routeSnap.params.idSelezione
    };

    return this.macroDestSrv.getPartenzeNostraSelezione(dataParam)
      .catch(err => {
        return err;
      });
  }

}
