import {Component, OnInit, ViewChild} from '@angular/core';
import {RecensioniPageService} from "../../services/gate/recensioni-page.service";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'borsaviaggi-recensioni-page',
  templateUrl: './recensioni-page.component.html',
  styleUrls: ['./recensioni-page.component.scss']
})
export class RecensioniPageComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public showLoader;
  public votiPiattaforme;
  public recensioni;
  public length = 100;
  public pageSize = 10;
  public pageSizeOptions: number[] = [5, 10, 25, 100];
  public pageEvent: PageEvent;
  public pageData = [];
  public selectedValue: string;

  constructor(private recensioniSrv: RecensioniPageService) {
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = "Recensioni per pagina";
    this.recensioniSrv.getVotiPiattaforme().then(
      (response) => {
        this.votiPiattaforme = response.body.data;
      }
    )

    this.recensioniSrv.getRecensioni().then(
      (response) => {
        this.recensioni = response.body.data;
        this.showLoader = false;
        this.setPageData({pageSize: 10, pageIndex: 0});

        this.length = this.recensioni.length;
      }
    )
  }

  public setPageData(event) {
    this.pageData = [];
    this.pageData = this.recensioni.slice(event.pageIndex * event.pageSize,
      event.pageIndex * event.pageSize + event.pageSize);
  }

  public orderPageData(value, page) {
    this.recensioni.sort(function (a, b) {
      return b.rating - a.rating;
    });

    switch (value) {
      case 'positivo':
        this.recensioni.sort(function (a, b) {
          return b.rating - a.rating;
        });
        break;
      case 'negativo':
        this.recensioni.sort(function (a, b) {
          return a.rating - b.rating;
        });
        break;
      case 'meno-recente':
        this.recensioni.sort(function (a, b) {
          return new Date(a.data).getTime() - new Date(b.data).getTime();
        });
        break;
      default:
        this.recensioni.sort(function (a, b) {
          return new Date(b.data).getTime() - new Date(a.data).getTime();
        });
    }

    this.setPageData({pageIndex: page.pageIndex, pageSize: page.pageSize});
  }
}
