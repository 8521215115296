<div class="gift-card-container" *ngIf="configObj">
  <mat-card class="mt-10px mb-10px gift-card">
    <mat-card-content class="text-align-center">
      <img class="gift-image"
           alt="gift card viaggio"
           [src]="configObj.urlImmagine">
      <h3 class="color-text-blue-borsaviaggi">{{configObj.titolo}}</h3>
      <div *ngIf="configObj && configObj.tipologia != 1" class="details-container combine">
        <p>a partire da</p>
        <p class="color-text-orange-borsaviaggi ml-5px">{{configObj.importo}} €</p>
      </div>
      <div *ngIf="configObj && configObj.tipologia == 1" class="details-container">
        <div class="combine">
          <div>Acquistalo con</div>
          <div class="color-text-orange-borsaviaggi ml-5px">{{configObj.importo}} €</div>
        </div>
        <div class="combine">
          <div>Per te un valore fino a</div>
          <div class="color-text-orange-borsaviaggi ml-5px">{{configObj.importoValoreExtra}} €</div>
        </div>
      </div>
      <button mat-raised-button class="color-white background-blue-borsaviaggi" (click)="getDetails(configObj.rowGuid)">
        Info & Condizioni
      </button>
    </mat-card-content>
  </mat-card>
</div>

