import {Component, HostListener, OnInit} from '@angular/core';
import {UtilityService} from "../../services/gate/utility.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalService} from "../../services/common/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../components/loader/loader.service";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'borsaviaggi-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public dataResponse;
  public dataLanding: any;
  public landingAttiva: boolean = true;
  public statusConferma: boolean = false;
  public isMobileDevice: boolean;
  public mobileStickyHeader: boolean;
  public formGroup: FormGroup;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.mobileStickyHeader = scrollOffset >= 100;
  }

  constructor(private utilitySrv: UtilityService,
              public route: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              public router: Router,
              private modalSrv: ModalService,
              private loaderSrv: LoaderService) {

    let status = this.route.snapshot.queryParamMap.get('status');

    this.formGroup = new FormGroup({
      'nome': new FormControl('', Validators.required),
      'cognome': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'cellulare': new FormControl('', Validators.required),
      'privacy': new FormControl('', Validators.required),
      'riceviNewsletter': new FormControl('')
    });

    this.formGroup.get('privacy').valueChanges.subscribe((val) => {
      if (val) {
        this.formGroup.get('riceviNewsletter').setValue(true);
      }
    });

    if (this.route.snapshot.data.dataLanding.body && this.route.snapshot.data.dataLanding.body.data) {
      this.isMobileDevice = this.deviceService.isMobile();
      this.dataResponse = this.dataLanding = this.route.snapshot.data.dataLanding.body.data;
      this.landingAttiva = this.dataResponse.attiva;
      this.statusConferma = status == 'conferma';
    } else {
      this.modalSrv.openErrorDialog("Si è verificato un errore, ci scusiamo per l'incoveniente.");
    }
  }

  ngOnInit(): void {
    this.formGroup.get('privacy').setValue(true);
    if (!this.landingAttiva) {
      this.modalSrv.openErrorDialog("Siamo spiacenti, questa Convenzione non è più attiva.");
    }
  }

  public scaricaSconto() {
    if (this.formGroup.valid) {
      this.loaderSrv.show();

      let param = {
        "nome": this.formGroup.controls['nome'].value,
        "cognome": this.formGroup.controls['cognome'].value,
        "email": this.formGroup.controls['email'].value,
        "cellulare": this.formGroup.controls['cellulare'].value,
        "idLanding": this.dataResponse.idLanding,
        "riceviNewsletter": this.formGroup.controls["riceviNewsletter"].value
      };

      this.utilitySrv.getRegistrazioneLanding(param).then(
        (response) => {
          if (response && response.body && (response.body.status === 0 || response.body.status === 1)) {
            this.formGroup.reset();
            this.formGroup.markAsPristine();
            this.formGroup.markAsUntouched();

            this.redirectTo('/convenzione/' + this.dataResponse.testoInUrl);
          } else {
            this.formGroup.reset();
            this.formGroup.markAsPristine();
            this.formGroup.markAsUntouched();

            this.modalSrv.openErrorDialog(response.body.message);
          }
        },
        (error) => {
          this.modalSrv.openErrorDialog(error);
        }
      );
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(
      () =>
        this.router.navigate([uri], {queryParams: {status: 'conferma'}})
    );
  }
}
