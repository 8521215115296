export const SEO_OFFERTE: any = {
  VACANZA_CATEGORIA_KEYWORD: "offerta",
  CROCIERA_CATEGORIA_KEYWORD: "crociera",
  OFFERTA_MACRO_KEYWORD: "macro",
  AREA_PERSONALE: "area-personale",
  RISULTATI_VACANZA_KEYWORD: "risultati",
  RISULTATI_CROCIERE_KEYWORD: "risultati-crociere",
  CHECKOUT_CROCIERE_KEYWORD: "crociera-checkout",
  DETTAGLIO_PARTENZA_VACANZA: "Scopri l'offerta lastminute {nome_struttura} ({nazione}) su Borsaviaggi.it. Prenota la tua vacanza al miglior prezzo garantito",
};
export const SEO_GLOBAL_KEYWORD: any = {
  AUTHOR: "Aubay S.p.A.",
  FB_APP_ID: "2257160297681336",
  CARTE_REGALO: "Carte regalo",
  COME_PRENOTARE_PAGE: "Come prenotare",
  DEFAULT_TITLE_PAGE: "Vacanze Last Minute, Offerte Viaggi e Crociere | Borsaviaggi.it",
  DEFAULT_DESCRIPTION_PAGE: "Sito italiano specializzato in vacanze last minute, crociere, offerte di viaggio, weekend",
  DEFAULT_KEYWORD_PAGE: "last minute, offerte last minute, lastminute, viaggi last minute, crociere, crociere last minute, offerte crociere, offerte, offerte vacanze, offerte viaggi, sharm, mar rosso, marrosso",
};
export const SEO_PAGE: any = {
  DETTAGLIO_PARTNER: "partner",
  HOME_CAMPAGNA: "campagna",
  DESTINAZIONE_TOP_KEYWORD: "destinazioni-top",
  DETTAGLIO_STRUTTURA: "struttura",
  DETTAGLIO_DESTINAZIONE: "offerte"
};
