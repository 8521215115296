import {Injectable} from "@angular/core";
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class DirettoreService {

  constructor(private genericHttpService: GenericGateService) {
  }
  public sendMessage(param): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/form/scriviAlDirettore", param);
  }
}
