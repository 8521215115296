<div>
  <mat-dialog-content align="center">
    <div>
      <h2 class="fs-1-6rem">Il preventivo non è più valido!</h2>
    </div>
    <!--<div class="fs-1_2rem d-flex flex-column justify-content-center">
      <span>Prossima partenza disponibile:</span>
      <span class="bold">{{data.dataParam | date: 'dd/MM/yyyy'}}</span>
    </div>-->
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button class="button-style" (click)="closeModal()">
      <span>Chiudi</span>
    </button>
  </mat-dialog-actions>
</div>
