<borsaviaggi-loader [show]="loadingFullScreen" [typeMode]='loaderTypeMode'></borsaviaggi-loader>
<mat-drawer-container autosize (backdropClick)="toolbar.closeSideNav()" class="borsaviaggi-nav-wrapper"
                      [ngClass]="{'webP':supportWebP}"
                      [hidden]="loadPage && loadingFullScreen">
  <borsaviaggi-toolbar #toolbar [showMobileMenu]="borsaviaggiNav.isMobileMode"
                       [sideNavLoginCtrl]="sidenav"
                       (toggleMenuFunction)="eventToggleMenu($event)"></borsaviaggi-toolbar>
  <!--<mat-drawer #sidenav [mode]="borsaviaggiNav.typeModeMenu">
    <borsaviaggi-navigation #borsaviaggiNav [sideNaveCtrl]="sidenav" configMode="mobile"></borsaviaggi-navigation>
  </mat-drawer>-->
  <mat-drawer #sidenav autoFocus="false" [mode]="borsaviaggiNav.typeModeMenu" class="mat-drawer-login">
    <borsaviaggi-login-sidenav
      [hidden]="toolbar.isOpenNavigationMenu"
      [sideNavLoginCtrl]="sidenav"></borsaviaggi-login-sidenav>
    <borsaviaggi-navigation
      [hidden]="!toolbar.isOpenNavigationMenu"
      #borsaviaggiNav [sideNaveCtrl]="sidenav" configMode="mobile"></borsaviaggi-navigation>
  </mat-drawer>
  <div class="app-content app-content-layout">
    <router-outlet></router-outlet>
    <noscript><a href="https://www.livechatinc.com/chat-with/8274441/" rel="nofollow">Chat with us</a>, powered by <a
      title="Live chat" href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
    </noscript>
  </div>
  <borsaviaggi-footer></borsaviaggi-footer>
</mat-drawer-container>
