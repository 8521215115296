import {Inject, Pipe, PipeTransform} from "@angular/core";
import {DestinazioneService} from "../app/services/gate/destinazione.service";

/**
 * @Pipe({name: 'status'})
 * Sorts an array.
 *
 */

@Pipe({name: "calcolaImportoVantaggioPrime"})
export class CalcolaImportoVantaggioPrimePipe implements PipeTransform {

  constructor(@Inject(DestinazioneService) private destSrv: DestinazioneService) {

  }

  transform(vantaggio, camere, totaleP): number {
    return this.destSrv.getSommaVantaggoPrime(vantaggio, camere, totaleP);
  }
}
