import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {ContainerDestinazioniService} from "../container-destinazioni.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioHotelMondoResolveService {
  constructor(private containerSrv: ContainerDestinazioniService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {

    return this.containerSrv.getLocalitaMondo(routeSnap.params.idDestinazione)
      .catch(err => {
        return err;
      });
  }
}
