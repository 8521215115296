<div class="background">
  <div class="container">
    <div class="search-results-bar">
      <div class="search-result"></div>
      <div class="search-result-btn"></div>
    </div>
    <div class="g-card">
      <div class="title-container">
        <div class="main-section">
          <div class="title-wrapper">
            <h1 class="title">{{dataResponse.nomeStrutturaOfferta | titlecase}}{{dataResponse.prenotaPrima | titlecase}}</h1> <!-- Wady Lahmi Azur Resort-->
            <div class="icon d-inline-block ml-8px d-sm-none" *ngIf="isMobileDevice">
              <borsaviaggi-share-button></borsaviaggi-share-button>
            </div>
          </div>
          <div class="subtitle-wrapper">
            <h3 class="subtitle">{{dataResponse.destinazione | titlecase}}
              ,&nbsp;{{dataResponse.nazione | titlecase}}</h3>
            <borsaviaggi-mostra-mappa [luogo]="dataResponse.nomeStrutturaOfferta"
                                      [nazione]="dataResponse.nazione"></borsaviaggi-mostra-mappa>
          </div>
        </div>
        <div class="side-section" *ngIf="!isMobileDevice">
          <div class="side-top-wrapper d-none d-sm-inline-block">
            <borsaviaggi-share-button></borsaviaggi-share-button>
          </div>
          <div class="side-bottom-wrapper d-none d-sm-inline-block">
            <div class="text">
              <span>Codice Offerta:&nbsp;</span>
              <!-- <span>{{dataResponse.codOfferta}}</span> -->
              <span>{{route.snapshot.paramMap.get("idG")}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="codice-offerta-xs d-block d-sm-none">
        <span>Codice Offerta:&nbsp;</span>
        <!-- <span>{{dataResponse.codOfferta}}</span> -->
        <span>{{route.snapshot.paramMap.get("idG")}}</span>
      </div>
      <div class="separator"></div>

      <div class="photo-container">

        <div class="photo-wrapper">
          <div class="row">

            <div class="col-12 gallery-wrapper"> <!-- full-width-->
              <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
            </div>
          <!--  <borsaviaggi-loader class="display-block mt-20px" [(show)]="loadingValues" typeMode="piker"
                                diameter="30"></borsaviaggi-loader>-->
            <mat-tab-group mat-stretch-tabs (selectedIndexChange)="getTabFoto($event)">
              <mat-tab *ngIf="showStructureTab" class="mt-10px mb-10px" label="Struttura">
                <ng-template mat-tab-label>
                  Struttura
                  <mat-icon class="ml-2">apartment</mat-icon>
                </ng-template>
                <div class="div-descrizione p-4" [innerHTML]="dataResponse.descrizioneStruttura | safeHtml"></div>
                <!--borsaviaggi-readmore class="display-block p-0-10px"
                                      [content]="dataResponse.descrizioneStruttura"></borsaviaggi-readmore-->
              </mat-tab>
              <mat-tab class="mt-10px mb-10px" label="Destinazione">
                <ng-template mat-tab-label>
                  Destinazione
                  <mat-icon class="ml-2">beach_access</mat-icon>
                </ng-template>
                <div class="div-descrizione p-4" [innerHTML]="dataResponse.descrizioneDestinazione | safeHtml"></div>
                <!--borsaviaggi-readmore [content]="dataResponse.descrizioneDestinazione"></borsaviaggi-readmore-->
              </mat-tab>
              <mat-tab *ngIf="showItineraryTab" class="mt-10px mb-10px" label="Itinerario">
                <ng-template mat-tab-label>
                  Itinerario
                  <mat-icon class="ml-2">map</mat-icon>
                </ng-template>
                <div class="div-descrizione p-4" [innerHTML]="dataResponse.itinerario | safeHtml"></div>
                <!--borsaviaggi-readmore [content]="dataResponse.itinerario"></borsaviaggi-readmore-->
              </mat-tab>
            </mat-tab-group>
            <div class="box-icona-pricing" *ngIf="dataResponse.iconaPricing">
              <img src="{{ webPSupport && dataResponse.iconaPricingWebP ? dataResponse.iconaPricingWebP : dataResponse.iconaPricing}}">
            </div>
            <!-- TABELLA -->
            <div class="partenza">
              <mat-label class="fw-800">Partenze da:</mat-label>
              <button mat-button *ngFor="let partenza of dataResponse.listaLuoghiPartenza; index as i"
                      [value]="partenza.LuogoPartenza | titlecase"
                      [disabled]="partenza.idLuogoPartenza==selectedIdPartenza"
                      (click)="changePartenzaEvent(partenza.idLuogoPartenza)">{{partenza.LuogoPartenza}}</button>
            </div>
            <div class="separator mt-1rem"></div>
          </div>
         <!-- <div class="mat-elevation-z8" (scroll)="tableScroll($event)">-->
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="codicePartenza">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Codice Partenza</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> {{element.idPartenza}} </td>
              </ng-container>
              <ng-container matColumnDef="dataPartenza">
                <th mat-header-cell *matHeaderCellDef> Data Partenza</th>
                <td mat-cell *matCellDef="let element"> {{element.dataPartenza | date :'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="giorniNotti">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Giorni/Notti</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> {{element.giorniNotti}} </td>
              </ng-container>
              <ng-container matColumnDef="trattamento">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Trattamento</th>
                <td mat-cell *matCellDef="let element"
                    class="d-none d-sm-table-cell"> {{element.trattamento | titlecase}}</td>
              </ng-container>
              <ng-container matColumnDef="icons">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"></th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                  <borsaviaggi-partenze-icons [partenza]="element"></borsaviaggi-partenze-icons>
                </td>
              </ng-container>
              <ng-container matColumnDef="listaDesideri">
                <th mat-header-cell *matHeaderCellDef> Lista Desideri</th>
                <td mat-cell *matCellDef="let element">
                  <borsaviaggi-preferiti [partenza]="element.idPartenza"
                                         [isFavourite]="element.isPreferito"></borsaviaggi-preferiti>
                </td>
              </ng-container>
              <ng-container matColumnDef="prezzo">
                <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                <td mat-cell *matCellDef="let element" class="bold ws-nowrap"> {{element.prezzo}} €</td>
              </ng-container>
              <ng-container matColumnDef="prime">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Offerta</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                  <a routerLink="/prime" target="_blank" title="offerta PRIME">
                    <img *ngIf="element.isPartenzaPrime"
                         alt="Borsaviaggi offerta prime"
                         src="../../../assets/img/borsaviaggi_prime.png"
                         height="25"
                         width="100"/>
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <a [routerLink]="element.url" [title]="'Offerta lastminute '+ element.titleHref">
                    <button class="prenota"
                            mat-raised-button>{{!element.isMezzoProprioLive ? 'VAI' : 'Fai Preventivo'}}</button>
                  </a>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-left"></tr>
            </table>
            <borsaviaggi-loader [show]="moreValues" typeMode="piker" diameter="30"></borsaviaggi-loader>

          </div>
          <div></div>
        </div>
      </div>

    </div>
  </div>
</div>
