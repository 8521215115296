import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../../services/gate/user.service";
import {AuthService} from "../../../../services/common/auth.service";
import {ResultCardConfig} from "../../../../model/search-results/resultCardConfig.model";
import {PreferitiService} from "../../../../components/preferiti/preferiti.service";

@Component({
  selector: 'borsaviaggi-lista-desideri',
  templateUrl: './lista-desideri.component.html',
  styleUrls: ['./lista-desideri.component.scss']
})
export class ListaDesideriComponent implements OnInit {
  public listaDesideri;
  public resultCardConfig: ResultCardConfig;

  constructor(public userSrv: UserService,
              private preferitiSrv: PreferitiService,
              public authSrv: AuthService) {

    this.resultCardConfig = new ResultCardConfig({
      vertical: false,
      isVacanze: false,
      isCrociere: false,
      isListaPrenotazioni: false,
      isListaDesideri: true
    });
  }

  ngOnInit() {
    this.getListaDesideriUtente()
  }

  public getListaDesideriUtente() {
    let dataParam = {};
    this.listaDesideri = [];
    this.userSrv.getListaDesideriUtente(dataParam).then(
      (response) => {
        if (response && response.body && response.body.data) {
          this.listaDesideri = response.body.data;
          this.preferitiSrv.togglePreferiti();
        } else {
          console.log("La tua lista preferiti è vuota!");
        }
      },
      (error) => {
        console.log(error);

      }
    );
  }

}
