import {Component, Input} from '@angular/core';
import {ModalService} from "../../services/common/modal.service";
import {UserService} from "../../services/gate/user.service";
import {AuthService} from "../../services/common/auth.service";
import {CacheService} from "../../services/common/cache.service";
import {PreferitiService} from "./preferiti.service";
import {LoginSidenavService} from "../login-sidenav/loginSidenav.service";

@Component({
  selector: 'borsaviaggi-preferiti',
  templateUrl: './preferiti.component.html',
  styleUrls: ['./preferiti.component.scss']
})
export class PreferitiComponent {
  @Input() partenza;
  @Input() crociera;
  @Input() isFavourite;
  public user;

  constructor(
    public modalSrv: ModalService,
    private userSrv: UserService,
    private preferitiSrv: PreferitiService,
    private cacheSrv: CacheService,
    private authSrv: AuthService,
    private loginSideSrv: LoginSidenavService
  ) {
  }

  addRemoveFavourite() {
    this.user = this.authSrv.getUser();
    let dataParam = {
      idPartenza: this.partenza,
      testoListaDesideri: "Esegui l'accesso o registrati per aggiungere " +
        "l'offerta alla tua Lista dei Desideri."
    };

    if (this.user) {

      let serviceToCall = this.isFavourite ? "deleteListaDesideriUtente" : "addListaDesideriUtente";
      if (this.crociera) {
        serviceToCall = this.isFavourite ? "deleteListaDesideriCrociera" : "addListaDesideriCrocieraUtente";
      }
      this.cacheSrv.removeCacheData('partenza-' + dataParam.idPartenza);
      this.userSrv[serviceToCall](dataParam).then(
        (response) => {
          if (response && response.body && response.body.status === 0) {
            this.isFavourite = !this.isFavourite;
            this.preferitiSrv.togglePreferiti();

          } else {
            this.authSrv.clearUser();
            this.loginSideSrv.open();
            /*this.modalSrv.openLoginModal(dataParam, (data) => {
              if (data) {
                this.user = data;
                this.authSrv.setUser(data);
                this.addRemoveFavourite()
              }
            });*/
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.loginSideSrv.open();
      /*this.modalSrv.openLoginModal(dataParam, (data) => {
        if (data) {
          this.user = data;
          this.authSrv.setUser(data);
          this.addRemoveFavourite()
        }
      });*/
    }
  }
}
