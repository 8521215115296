import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalInterface} from "../../../interface/modal.interface";

@Component({
  selector: 'borsaviaggi-promo-flash-modal',
  templateUrl: './promo-flash-modal.component.html',
  styleUrls: ['./promo-flash-modal.component.scss']
})
export class PromoFlashModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PromoFlashModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<PromoFlashModalComponent>) {
    setTimeout(() => {
      this.dialogRef.close();
    }, 4000)
  }

  ngOnInit(): void {
  }

}
