import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {MacroDestinazioneService} from "../macro-destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class DestinazioneTopResolveService {
  constructor(private macroDestSrv: MacroDestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    return this.macroDestSrv.getDestinazioneTop(routeSnap.params.idSelezione)
      .catch(err => {
        return err;
      });
  }

}
