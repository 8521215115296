import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SearchResultsViewMode } from "../../model/search-results/searchResultsViewMode.model";
import { ResultCard } from "../../model/search-results/resultCard.model";
import { ResultCardConfig } from "../../model/search-results/resultCardConfig.model";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ResponseCheckerService } from "../../services/common/response.checker.service";
import { LabelType, Options } from "ng5-slider";
import { LoaderService } from "../../components/loader/loader.service";
import { ModalService } from "../../services/common/modal.service";
import { Subscription } from "rxjs";
import { RicercaCrociereService } from "../../services/gate/ricerca-crociere.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { RESPONSIVE_VALUE } from "../../constants/const.service";
import { ImageUtilService } from "../../services/common/image.util.service";

@Component({
  templateUrl: './search-results-crociere.component.html',
  styleUrls: ['./search-results-crociere.component.scss']
})
export class SearchResultsCrociereComponent implements OnInit, OnDestroy {
  @Input() mode: SearchResultsViewMode;
  public resultCardConfig: ResultCardConfig;
  public hideInMobile: boolean;
  public showFilters: boolean;

  // mock
  public searchResults: any;
  public searchResultsCrociere: any;
  public queryParams: any;
  public naveDaCrocieraFilter: any;
  public dataPartenzaFilter: any;
  public compagniaFilter: any;
  public cabinaFilter: any;
  public dateFilters: any;
  public depFilters: any;
  public selectedNave = [];
  public selectedDataPartenza = [];
  public selectedCompagnia = [];
  public selectedCabina = [];
  public selectedDates = [];
  public selectedDep = [];
  public initialResults;
  public selectedCategories = [];
  public tourOperatorFilters: any;
  public selectedTourOperators = [];
  public serviceFilters: any;
  public selectedService = [];
  public cardReduce = false;
  public minPrice = 0;
  public maxPrice = 0;
  public sliderOptions: Options;
  public showSearchEngine: boolean = false;
  public isMobileDevice: boolean;
  public subscribe: Subscription;
  private cabinaInternaId = 1;
  private cabinaEsternaId = 2;
  private cabinaConBalconeId = 3;
  private suiteId = 4;
  public NottiList : any;
  public selectedNotti: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private loaderSrv: LoaderService,
    private modalSrv: ModalService,
    private ricercaCrociereSrv: RicercaCrociereService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.isMobileDevice = this.deviceService.isMobile();
    this.hideInMobile = false;
    this.mode = this.mode ? this.mode : new SearchResultsViewMode({
      isVacanze: false,
      isCrociere: true,
    });
    this.searchResultsCrociere = [];
    this.searchResults = [];

    if (ResponseCheckerService.isSuccessResponse(this.route.snapshot.data.listRisultati.body)) {
      this.searchResults = this.route.snapshot.data.listRisultati.body.data || null;
      this.queryParams = this.route.snapshot.queryParams;
    } else {
      this.modalSrv.openErrorDialog();
    }

    this.searchResults.forEach((el) => {
      this.searchResultsCrociere.push(this.makeResultCardCrociere(el));
    });
    this.NottiList = this.searchResults
      .map(m => ({
        value: m.notti,
        label: m.notti,
        isSelected: false
      }))
      .filter((obj, index, self) =>
        index === self.findIndex((t) => t.value === obj.value)
      );
    //copy of original results
    this.initialResults = Object.assign([], this.searchResultsCrociere);
    this.generateFilters();
  }


  ngOnInit() {
    this.init();

    // this.getMinMaxPrice();
    this.sliderOptions = {
      floor: this.minPrice,
      ceil: this.maxPrice,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '€' + value;
          case LabelType.High:
            return '€' + value;
          default:
            return "";
        }
      }
    };
    setTimeout(() => {
      this.subscribe = this.router.events.subscribe((e: any) => {
        console.log('Router event:', e); // Add this log
        if (e instanceof NavigationEnd) {
          console.log('NavigationEnd event detected'); // Add this log
          setTimeout(() => {
            this.loaderSrv.show();
          }, 0);


          this.rqRicerca().then(
            (resp) => {
              if (resp.body && resp.body.data) {
                console.log('Response data:', resp.body.data); // Log the response data
                if (ResponseCheckerService.isSuccessResponse(this.route.snapshot.data.listRisultati.body)) {
                  this.searchResults = resp.body.data || [];
                  this.searchResultsCrociere = [];
                  this.searchResults.forEach((el) => {
                    this.searchResultsCrociere.push(this.makeResultCardCrociere(el));
                  });
                  // Copy of original results
                  this.initialResults = Object.assign([], this.searchResultsCrociere);
                  this.generateFilters();
}
              }
            },
            () => {
              // Handle the error case if needed
            }
          ).finally(
            () => {
              this.loaderSrv.hide();
            }
          );


        }
      });
    }, 0
    )
  }

  public init() {
    this.mode = this.mode ? this.mode : new SearchResultsViewMode({
      isVacanze: false,
      isCrociere: true,
    });

    this.resultCardConfig = new ResultCardConfig({
      vertical: false,
      isVacanze: this.mode.isVacanze,
      isCrociere: this.mode.isCrociere,
      isListaPrenotazioni: false,
      isListaDesideri: false
    });

    this.hideInMobile = true;
    this.showFilters = true;

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.resultCardConfig.vertical = true;
          this.hideInMobile = true;
          this.showFilters = false;
        } else {
          this.hideInMobile = false;
          this.showFilters = true;
        }
      });
  }

  private generateFilters() {
    this.naveDaCrocieraFilter = this.generateNaveCrocieraArray();
    this.dataPartenzaFilter = this.generateDatePartenzaFilterArray();
    this.compagniaFilter = this.generateCompagniaFilterArray();
    this.cabinaFilter = this.generateCabinaFilterArray();
  }

  private rqRicerca() {
    let reqObj = {
      data: this.route.snapshot.queryParams.data || null,
      idPorto: this.route.snapshot.queryParams.idPorto || null,
      idCompagnia: this.route.snapshot.queryParams.idCompagnia || null,
      idDestinazione: this.route.snapshot.queryParams.idDestinazione || null
    };
    return this.ricercaCrociereSrv.ricercaCrociere(reqObj)
  }


  public toggleShowFilters() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showFilters = !this.showFilters;
        }
      });
  }

  public getPrezziCabine(obj) {
    let cabine: Array<any> = obj.cabine;
    let res = {
      prezzoCabinaInterna: null,
      prezzoCabinaEsterna: null,
      prezzoCabinaConBalcone: null,
      prezzoSuite: null,
    };
    cabine.forEach((cabina) => {
      switch (cabina.IdCategoria) {
        case 1:
          res.prezzoCabinaInterna = cabina.CostoBase;
          break;
        case 2:
          res.prezzoCabinaEsterna = cabina.CostoBase;
          break;
        case 3:
          res.prezzoCabinaConBalcone = cabina.CostoBase;
          break;
        case 4:
          res.prezzoSuite = cabina.CostoBase;
          break;
      }
    });
    return res;
  }

  public getTipoligiaCabine(obj) {
    let cabine: Array<any> = obj.cabine;
    let res = [];
    cabine.forEach((cabina) => {
      res.push(cabina.IdCategoria);
    });
    return res;
  }

  public makeResultCardCrociere(obj: any) {
    let prezziCabine = this.getPrezziCabine(obj);
    let tipologiaCabine = this.getTipoligiaCabine(obj);
    let fotoOrdered = obj.fotoOfferta ? ImageUtilService.ordinaFotoArray(obj.fotoOfferta) : [];
    return new ResultCard({
      titleNave: obj && obj.nave || null,
      title: obj && obj.titolo || null,
      subtitle: obj && obj.compagnia || null,
      categoria: obj && obj.categoria || null, //?
      destinazione: obj && obj.destinazione || null,
      luogoPartenza: obj && obj.porto || null,
      dataPartenza: obj && obj.dataPartenza || null,
      price: obj && obj.prezzoTotale || null,
      totalPrice: obj && obj.prezzoTotale || null,
      tipologiaCabine: tipologiaCabine || null,
      prezzoCabinaInterna: prezziCabine && prezziCabine.prezzoCabinaInterna || null,
      prezzoCabinaEsterna: prezziCabine && prezziCabine.prezzoCabinaEsterna || null,
      prezzoCabinaConBalcone: prezziCabine && prezziCabine.prezzoCabinaConBalcone || null,
      prezzoSuite: prezziCabine && prezziCabine.prezzoSuite || null,
      altrePartenze: obj && obj.altrePartenze || null,
      photos: this.isMobileDevice && fotoOrdered.length > 4 ?
        fotoOrdered.splice(0, 4) : fotoOrdered.splice(0, 10),
      img: obj && obj.img || null,
      icona: obj && obj.icona || null,
      compagnia: obj && obj.compagnia || null,
      nomeStruttura: obj && obj.nomeStruttura || null,
      nazione: obj && obj.nazione,
      tourOperator: obj && obj.tourOperator || null,
      notti: obj && obj.notti.toString(),
      giorni: obj && obj.giorni.toString(),
      pax: obj && obj.pax,
      tipoOfferta: obj && obj.trattamento,
      isTrasferimentiCollettivi: obj && obj.isTrasferimentiCollettivi,
      bonusIcons: '',
      isOffertaPrime: obj && (obj.isPartenzaPrime || obj.isPricingPrime) || false,
      idPartenza: obj && obj.idPartenza,
      isPreferito: obj && obj.isPreferito,
      isAnimali: obj && obj.isAnimali,
      isBimbiGratis: obj && obj.isBimbiGratis,
      listaServiziPrime: obj && obj.listaServiziPrime,
      isFamigliaNumerosa: obj && obj.isFamigliaNumerosa,
      isFuoriTutto: obj && obj.isFuoriTutto,
      isInEvidenza: obj && obj.isInEvidenza,
      isLastMinute: obj && obj.isLastMinute,
      isNoFuel: obj && obj.isNoFuel,
      isOffSpeciale: obj && obj.isOffSpeciale,
      isOZeroPensieri: obj && obj.isOZeroPensieri,
      isPrenotaPrima: obj && obj.isPrenotaPrima,
      isPricingInEvidenza: obj && obj.isPricingInEvidenza,
      isPZeroPensieri: obj && obj.isPZeroPensieri,
      isSingle: obj && obj.isSingle,
      isSoloCoppie: obj && obj.isSoloCoppie,
      isZeroPensieri: obj && obj.isZeroPensieri,

      idDestinazione: obj && obj.idDestinazione,
      idLuogoPartenza: obj && obj.idLuogoPartenza,
      idOfferta: obj && obj.idOfferta,
      idPartenzaCrociera: obj && obj.idPartenzaCrociera,
      isVolo: obj && obj.isVolo,
    })
  }

  public cardReduction() {
    this.cardReduce = !this.cardReduce;
  }

  public parseFotoOfferta(arrFoto) {
    let arrRes = [];
    arrFoto.forEach((obj) => {
      let o = {
        idOfferta: null,
        foto: null
      };
      o.idOfferta = obj.IdOfferta ? obj.IdOfferta : obj.idOfferta ? obj.idOfferta : null
      o.foto = obj.Foto ? obj.Foto : obj.foto ? obj.foto : null
      arrRes.push(o);
    });
    return arrRes;
  }

  public switchCardsLayout(flag) {
    this.resultCardConfig.vertical = flag;
  }


  private generateNaveCrocieraArray() {
    let naviArray = [];
    this.searchResults.forEach((elem) => {
      if (naviArray.indexOf(elem.nave) == -1) {
        naviArray.push(elem.nave);
      }
    });

    let naveFilters = [];
    naviArray.forEach(nave => {
      naveFilters.push({
        value: nave,
        label: nave,
        isSelected: false
      })
    });
    return naveFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  private generateDatePartenzaFilterArray() {
    let dateArray = [];
    this.searchResults.forEach((elem) => {
      if (dateArray.indexOf(elem.dataPartenza) == -1) {
        dateArray.push(elem.dataPartenza);
      }
    });

    let dateFilters = [];
    dateArray.forEach(dataPartenza => {
      dateFilters.push({
        value: dataPartenza,
        label: dataPartenza,
        isSelected: false
      })
    });
    return dateFilters.sort((dateA, dateB) => {
      return new Date(dateA.value).getTime() - new Date(dateB.value).getTime();
    });
  }

  private generateCompagniaFilterArray() {
    let dateArray = [];
    this.searchResults.forEach((elem) => {
      if (dateArray.indexOf(elem.compagnia) == -1) {
        dateArray.push(elem.compagnia);
      }
    });

    let compagniaFilters = [];
    dateArray.forEach(currCompagnia => {
      compagniaFilters.push({
        value: currCompagnia,
        label: currCompagnia,
        isSelected: false
      })
    });
    return compagniaFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  private generateCabinaFilterArray() {
    let dateArray = [];
    let tipoCabina;
    this.searchResults.forEach((elem) => {
      elem.cabine.filter((value) => {
        let existElement = dateArray.some(element => element.IdCategoria === value.IdCategoria)
        if (!existElement) {
          tipoCabina = {
            IdCategoria: value.IdCategoria,
            TipoCabina: value.TipoCabina
          };
          dateArray.push(tipoCabina);
        }
      });
    });

    const cabinaFilters = [];
    dateArray.forEach(currCompagnia => {
      cabinaFilters.push({
        value: currCompagnia.IdCategoria,
        label: currCompagnia.TipoCabina,
        isSelected: false
      });
    });

    return cabinaFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }


  public toggleCheckBox() {
    this.loaderSrv.show();

    //retrieve selected filters
    this.selectedNave = this.naveDaCrocieraFilter.filter(f => f.isSelected);
    this.selectedDataPartenza = this.dataPartenzaFilter.filter(f => f.isSelected);
    this.selectedCompagnia = this.compagniaFilter.filter(f => f.isSelected);
    this.selectedCabina = this.cabinaFilter.filter(f => f.isSelected);
    this.selectedNotti = this.NottiList.filter(f => f.isSelected);

    //reset search results to initial results
    this.searchResultsCrociere = [];
    this.searchResults.forEach((el) => {
      this.searchResultsCrociere.push(this.makeResultCardCrociere(el));
    });

    if (this.selectedNotti.length > 0) {
      let filteredResults = [];
      this.selectedNotti.forEach((l) => {
        this.searchResultsCrociere.filter(item => item.notti == l.label  ).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResultsCrociere = Object.assign([], filteredResults);
    }

    //Destinations filters - if some filters are selected, it starts from initial results this.searchResults = this.initialResults
    if (this.selectedNave.length > 0) {
      let filteredResults = [];
      this.selectedNave.forEach((dest) => {
        this.searchResultsCrociere.filter(item => item.titleNave === dest.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResultsCrociere = Object.assign([], filteredResults);
    }
    if (this.selectedDataPartenza.length > 0) {
      let filteredResults = [];
      this.selectedDataPartenza.forEach((dest) => {
        this.searchResultsCrociere.filter(item => item.dataPartenza === dest.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResultsCrociere = Object.assign([], filteredResults);
    }

    if (this.selectedCompagnia.length > 0) {
      let filteredResults = [];
      this.selectedCompagnia.forEach((dest) => {
        this.searchResultsCrociere.filter(item => item.compagnia === dest.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResultsCrociere = Object.assign([], filteredResults);
    }

    if (this.selectedCabina.length > 0) {
      let filteredResults = [];
      this.searchResultsCrociere.forEach(item => {
        let prezzoCabinaInterna = null;
        let prezzoCabinaEsterna = null;
        let prezzoCabinaConBalcone = null;
        let prezzoSuite = null;
        let filtered = 0;
        let cabinaSort = null;
        item.tipologiaCabine.filter(tipo => {
          this.selectedCabina.forEach((element) => {
            if (tipo === element.value) {
              filtered++;
              cabinaSort = tipo;
              switch (tipo) {
                case this.cabinaInternaId: {
                  prezzoCabinaInterna = item.prezzoCabinaInterna;
                  break;
                }
                case this.cabinaEsternaId: {
                  prezzoCabinaEsterna = item.prezzoCabinaEsterna;
                  break;
                }
                case this.cabinaConBalconeId: {
                  prezzoCabinaConBalcone = item.prezzoCabinaConBalcone;
                  break;
                }
                case this.suiteId: {
                  prezzoSuite = item.prezzoSuite;
                  break;
                }
              }
            }
          });
        });
        if (filtered === this.selectedCabina.length) {
          item.prezzoCabinaInterna = prezzoCabinaInterna;
          item.prezzoCabinaEsterna = prezzoCabinaEsterna;
          item.prezzoCabinaConBalcone = prezzoCabinaConBalcone;
          item.prezzoSuite = prezzoSuite;
          filteredResults.push(item);
          if (cabinaSort) {
            switch (cabinaSort) {
              case this.cabinaInternaId: {
                filteredResults.sort((a, b) => (a.prezzoCabinaInterna >= b.prezzoCabinaInterna ? 1 : -1));
                break;
              }
              case this.cabinaEsternaId: {
                filteredResults.sort((a, b) => (a.prezzoCabinaEsterna >= b.prezzoCabinaEsterna ? 1 : -1));
                break;
              }
              case this.cabinaConBalconeId: {
                filteredResults.sort((a, b) => (a.prezzoCabinaConBalcone >= b.prezzoCabinaConBalcone ? 1 : -1));
                break;
              }
              case this.suiteId: {
                filteredResults.sort((a, b) => (a.prezzoSuite >= b.prezzoSuite ? 1 : -1));
                break;
              }
            }
          }
        }
      });
      this.searchResultsCrociere = Object.assign([], filteredResults);
    }


    this.loaderSrv.hide();
  }


  public toggleShowSearchEngine() {
    this.showSearchEngine = !this.showSearchEngine;
  }

  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }
}
