import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {DestinazioneService} from "../destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioStrutturaPartnerResolveService {
  constructor(private destSrv: DestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let dataParam = {
      "id": routeSnap.params.idStruttura,
      "url": routeSnap.params.nomeStruttura.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "_s" + routeSnap.params.idStruttura + ".html"
    };

    return this.destSrv.getStruttura(dataParam)
      .catch(err => {
        return err;
      });
  }
}
