<div class="header-modal mb-20px">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <div class="d-flex align-items-center justify-content-left">
    <mat-icon matPrefix class="mr-5px">room</mat-icon>
    <span mat-dialog-title class="inline margin-0">Seleziona Destinazioni</span>
  </div>
</div>
<mat-dialog-content class="mb-20px">
  <div class="container-fluid wrapper-destinazione mb-10px" *ngFor="let destinazione of listDestinazioni">
    <div class="title-destinazione mb-10px">
      <mat-checkbox name="localitaCheckBox" ngDefaultControl
                    class="nome-destinazione"
                    (change)="actionCheckUnCheck()"
                    [disabled]="destinazione.disabled"
                    [(ngModel)]="destinazione.checked">
        <span>{{destinazione.nome}}</span>
      </mat-checkbox>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="background-orange-borsaviaggi color-white"
          (click)="actionConfirm()">
    <span>Conferma</span>
    <i class="material-icons">done</i>
  </button>
</mat-dialog-actions>

