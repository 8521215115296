<mat-card class="card-offerta-top">
  <a [routerLink]="urlHref" [title]="titleHref">
    <div class="image-container position-relative overflow-hidden mb-20px" lazy-load-images>
      <img mat-card-image
           [src]="configObj.linkFoto"
           [alt]="configObj.struttura">
      <div class="prime-box" *ngIf="configObj.note">

        <span>{{configObj.note}}</span>
      </div>
      <div class="prezzo-box">
        <span *ngIf="!configObj.isCrociera">{{configObj.costo}}€</span>
        <span *ngIf="configObj.isCrociera">da {{configObj.costo}}€</span>
      </div>
    </div>
  </a>
  <mat-card-content>
    <div class="row">
      <div class="col-1 no-gutters pr-0 pl-16px">
        <a [routerLink]="urlHref" [title]="titleHref">
          <i class="fs-1rem fa fa-map-marker mr-10px" aria-hidden="true"></i>
        </a>
      </div>
      <div class="col-8">
        <a [routerLink]="urlHref" [title]="titleHref" class="title-box-top">
          <span class="fs-1rem  fw-600 span-ellipsis">{{configObj.nazione | titlecase}}
            <span *ngIf="configObj.nazione !== ''">- </span>{{configObj.destinazione | titlecase}}</span>
        </a>
        <!--   <span class="fs-08rem date-notti-text">{{configObj.soggiorno}} Notti</span>-->
      </div>
      <!--   <div *ngIf="configObj.idP !== -1" class="col-2 container-bv-preferiti">
           <borsaviaggi-preferiti [isFavourite]="configObj.isPreferito" [partenza]="configObj.idP"></borsaviaggi-preferiti>
         </div>-->
      <div class="col-auto offerta-prime lh-13px" *ngIf="configObj.isPrime">
        <span class="fs-08rem color-text-orange-borsaviaggi display-block">Offerta</span>
        <span class="fs-1_1rem fw-600 prime-red">PRIME</span>
      </div>
    </div>
    <a [routerLink]="urlHref" [title]="titleHref">
      <div class="row">
        <div class="col-1 no-gutters pr-0">
          <i class="fs-1rem fa fa-building mr-10px" aria-hidden="true" *ngIf="!configObj.isCrociera"></i>
          <i class="fs-1rem fa fa-ship mr-10px" aria-hidden="true" *ngIf="configObj.isCrociera"></i>
        </div>
        <div class="col-11">
          <span class="fs-1rem span-ellipsis">{{configObj.struttura | titlecase}}</span>
        </div>
      </div>


      <div class="row">
        <div class="col-1 no-gutters pr-0">
          <i class="fs-1rem fa fa-bed mr-10px" aria-hidden="true"></i>
        </div>
        <div class="col-11">
          <span class="fs-1rem span-ellipsis">{{configObj.soggiorno}} notti</span>
        </div>
      </div>


      <div class="row">
        <div class="col-1 no-gutters pr-0">
          <i class="fs-1rem fa fa-calendar-o mr-10px" aria-hidden="true"></i>
        </div>
        <div class="col-11">
          <span class="fs-1rem  position-relative mr-10px span-ellipsis">{{configObj.date}}</span>
        </div>

      </div>

      <div class="row">
        <div class="col-1 no-gutters pr-0">
          <i class="fs-1rem fa fa-plane" aria-hidden="true" *ngIf="!configObj.isCrociera"></i>
          <i class="fs-1rem fa fa-anchor" aria-hidden="true" *ngIf="configObj.isCrociera"></i>
        </div>
        <div class="col-11">
          <span class="fs-1rem span-ellipsis">{{configObj.luoghi | titlecase}}</span>
        </div>
      </div>
    </a>
  </mat-card-content>
</mat-card>
