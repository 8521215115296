<div class="header-modal">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <!-- <mat-icon matPrefix>single_bed</mat-icon>-->
  <h2 mat-dialog-title>Richiedi informazioni</h2>
</div>
<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="form-group row">
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Nome" required name="nome" formControlName="nome" class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.nome"
                       [formControlParam]="formGroup.controls.nome"></bv-form-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Cognome</mat-label>
        <input matInput placeholder="Cognome" required name="cognome" formControlName="cognome" class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.cognome"
                       [formControlParam]="formGroup.controls.cognome"></bv-form-error>
      </mat-form-field>
    </div>
    <div class="form-group row">
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Cellulare</mat-label>
        <input matInput placeholder="Cellulare" required type="number" name="cellulare" formControlName="cellulare" class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.cellulare"
                       [formControlParam]="formGroup.controls.cellulare"></bv-form-error>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email"  type="email" name="email" formControlName="email" class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.email"
                       [formControlParam]="formGroup.controls.email"></bv-form-error>
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="button-style mr-5px" (click)="richiediInfo(formGroup.valid)">Invia dati</button>
</mat-dialog-actions>
