import {Injectable} from "@angular/core";
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class LavoraConNoiService {

  constructor(private genericHttpService: GenericGateService) {
  }
  public sendCurriculum(param): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/form/lavoraConNoi", param);
  }
  public getRegioni(): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/utility/getRegioni");
  }
  public getProvinceByRegione(idRegione): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/utility/getProvince", idRegione);
  }
}
