import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable({
  providedIn: 'root'
})
export class RecensioniPageService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getVotiPiattaforme(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/rating/getVotiPiattaforme");
  }

  public getRecensioni(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/rating/getRecensioni");
  }
}
