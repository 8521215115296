<mat-card class="mt-5px border-grey-1px">
  <mat-card-content>
    <p class="abbonamento-prime" *ngIf="datiUtente.isPrime">ABBONAMENTO REGOLARMENTE ATTIVO - scade
      il {{scadenzaPrime | date: 'dd/MM/yyyy'}}</p>
    <div class="row">
      <div class="col-12">
        <h2 class="subtitle color-text-blue-borsaviaggi text-align-center">Il tuo account</h2>
        <form class="text-style" [formGroup]="formAccount">
          <div class="form-group row mr-15px ml-5px">
            <label for="name" class="col-sm-3 col-form-label lh-3num">Nome</label>
            <mat-form-field appearance="outline" class="col-sm-9">
              <input matInput id="name" placeholder="Nome" type="text" formControlName="nome" required>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="surname" class="col-sm-3 col-form-label lh-3num">Cognome</label>
            <mat-form-field appearance="outline" class="col-sm-9">
              <input matInput id="surname" placeholder="Cognome" type="text" formControlName="cognome" required>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="email" class="col-sm-3 col-form-label lh-3num">Email</label>
            <mat-form-field appearance="outline" class="col-sm-9">
              <input matInput id="email" placeholder="Email" type="text" formControlName="email" required>
            </mat-form-field>
          </div>

          <div class="form-group row mr-15px ml-5px">
            <label for="indirizzo" class="col-sm-3 col-form-label lh-3num">Indirizzo</label>
            <mat-form-field appearance="outline" class="col-sm-9">
              <input matInput id="indirizzo" placeholder="Indirizzo" type="text" formControlName="indirizzo" required>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="regione" class="col-sm-3 col-form-label lh-3num">Regione</label>
            <mat-form-field id="regione" appearance="outline" class="col-sm-6">
              <mat-select (selectionChange)="onChangeRegione($event)" formControlName="idRegione" required>
                <mat-option *ngFor="let regione of listaRegioni" [value]="regione.idRegione">
                  {{regione.regione}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="comune" class="col-sm-3 col-form-label lh-3num">Comune</label>
            <mat-form-field id="comune" appearance="outline" class="col-sm-6">
              <mat-select formControlName="idComune" required>
                <mat-option *ngFor="let comune of listaComuni" [value]="comune.IdComune">
                  {{comune.Comune}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="cap" class="col-sm-3 col-form-label lh-3num">Cap</label>
            <mat-form-field appearance="outline" class="col-sm-6">
              <input matInput id="cap" placeholder="Cap" type="text" formControlName="cap" required>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="sesso" class="col-sm-3 col-form-label lh-3num text-style">Sesso</label>
            <mat-form-field id="sesso" required appearance="outline" class="col-sm-6">
              <mat-select formControlName="sesso" required>
                <mat-option value="M">M</mat-option>
                <mat-option value="F">F</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="date" class="col-sm-3 col-form-label lh-3num text-style">Data di nascita</label>
            <mat-form-field appearance="outline" class="col-sm-6">
              <input id="date" matInput [matDatepicker]="picker" formControlName="dataNascita" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="codiceFiscale" class="col-sm-3 col-form-label lh-3num">Codice Fiscale</label>
            <mat-form-field appearance="outline" class="col-sm-6">
              <input matInput id="codiceFiscale" placeholder="Codice Fiscale" type="text"
                     formControlName="codiceFiscale" required>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="cellulare" class="col-sm-3 col-form-label lh-3num">Cellulare</label>
            <mat-form-field appearance="outline" class="col-sm-6">
              <input matInput id="cellulare" placeholder="Cellulare" type="number" formControlName="cellulare" required>
            </mat-form-field>
          </div>
          <div class="form-group row mr-15px ml-5px">
            <label for="cellulare" class="col-sm-3 col-form-label lh-3num">Telefono</label>
            <mat-form-field appearance="outline" class="col-sm-6">
              <input matInput id="telefono" placeholder="Telefono" type="number" formControlName="telefono" required>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-12">
        <button mat-button class="button-style center" (click)="enableForm()" *ngIf="modificaForm">Modifica</button>
        <button mat-button class="button-style center" (click)="editAccount(formAccount.valid)" *ngIf="salvaModifica">
          Salva Modifiche
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mt-5px border-grey-1px">
  <mat-card-content>
    <div class="row text-style">
      <div class="col-12">
        <h2 class="subtitle color-text-blue-borsaviaggi text-align-center">Modifica password</h2>
        <form [hidden]="hidePwdForm" class="text-style" [formGroup]="formPassword">
          <div class="form-group row">
            <label for="password" class="col-sm-3 col-form-label lh-3num">Password</label>
            <div class="col-sm-9">
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput id="password" type="password" placeholder="password" formControlName="password" matTooltip="{{getTooltipMsg()}}" matTooltipPosition="after" required>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div>
          <button [hidden]="!hidePwdForm" mat-button class="button-style center" (click)="showPasswordForm()">Modifica</button>
        </div>
      </div>
      <div [hidden]="hidePwdForm" class="col-12">
        <button mat-button class="button-style center" (click)="editPassword(formPassword.valid)" [disabled]="formPassword.invalid">Salva nuova password</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!--<mat-card class="mt-5px border-grey-1px newsletter">
  <mat-card-content>
    <div class="row">
      <div class="col-12">
        <h2 class="subtitle pb-10px color-text-blue-borsaviaggi text-align-center">Newsletter - Personalizza il
          servizio</h2>
        <div class="">
          <label>Inserisci le tue preferenze, ti consiglieremo al meglio!</label>
          <div class="text-style text-style-small form-group row pb-10px">
            <label class="col-12 pt-3px pb-3px ">1 - Scegli quale promozione vuoi ricevere:</label>
            <mat-checkbox class="col-12 pt-3px pb-3px newsletter" *ngFor="let item of newsLetterPromo"
                          [(ngModel)]="item.isActive">{{item.newsLetter}} </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
          <div class="text-style text-style-small form-group row">
            <label class="col-12 pt-3px pb-3px">2 - Quali sono le preferenze per la tua prossima vacanza?</label>
            <mat-checkbox class="col-12 pt-3px pb-3px" *ngFor="let item of newsLetterPreferenze" [(ngModel)]="item.isActive">{{item.newsLetter}} </mat-checkbox>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button mat-button class="button-style center" (click)="updateNewsletterUtente()">Salva le tue preferenze</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>-->
