import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {MultimediaService} from "../../services/gate/multimedia.service";
import {CacheService} from "../../services/common/cache.service";
import {CACHE_KEY, RESPONSIVE_VALUE} from "../../constants/const.service";
import {HomeService} from "../../services/gate/home.service";
import {MatTabGroup} from "@angular/material/tabs";
import {MacroBoxInterface} from "../../components/macro-box/config.interface";
import {FormGroup} from "@angular/forms";
import {UserValidaror} from "../../services/validator/user.validator";
import {isPlatformBrowser} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {ResponseCheckerService} from "../../services/common/response.checker.service";
import {AuthService} from "../../services/common/auth.service";
import {User} from "../../model/user.dto";
import {Subscription} from "rxjs";
import {NgxGalleryOptions} from "../../components/gallery/ngx-gallery-options.model";
import {NgxGalleryAnimation} from "../../components/gallery/ngx-gallery-animation.model";
import {ImageUtilService} from "../../services/common/image.util.service";
import {ModalService} from "../../services/common/modal.service";
import {CampagnaService} from "../../services/common/campagna.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {SEO_GLOBAL_KEYWORD} from "../../constants/seo.const";

const NUMERO_BOX_OFFERTE_TOP = 8;

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  public gallery: {
    options: NgxGalleryOptions[],
    images: any[],
    currentIndex: number
    dataResponse: any
  };
  public listPartner: [];
  public isPlatformBrowser: boolean;
  public listTabOfferteTop: [];
  public listOfferteMap: {};
  public configSwiper: { topItems: any, servizi: any };
  public formGroupNewsLetter: FormGroup;
  public formGroupNewsLetterError: any;
  public loadingSendIscrizioneNL;
  public macroBoxConfig: {
    listaDiNozze: MacroBoxInterface,
    regalaUnViaggio: MacroBoxInterface,
    giftCard: MacroBoxInterface,
    superTravelExpert: MacroBoxInterface,
    utilizzoCashback: MacroBoxInterface
  };
  public listSelezioniMacro: Array<MacroBoxInterface>;
  public user: User;
  public subscribeUser: Subscription;
  public subscribeRoute: Subscription;
  public isRenderInBrowser: boolean;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  public indiceTopItem;
  public isMobileDevice;
  public userAgent: DeviceDetectorService;
  public showSearchEngine: boolean = false;
  public webPSupported: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.isMobileDevice && (this.userAgent.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE) || this.isMobileDevice && !this.userAgent.isMobile()) {
      this.gallery.images.splice(0, this.gallery.images.length);
      this.populateGallery(this.gallery.dataResponse);
    }
    this.isMobileDevice = this.userAgent.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.TABLET_VERTICAL;
  }

  constructor(private homeSrv: HomeService,
              @Inject(PLATFORM_ID) platformId: string,
              private multimediaSrv: MultimediaService,
              private cacheSrv: CacheService,
              private titleService: Title,
              private metaTagService: Meta,
              private deviceService: DeviceDetectorService,
              public route: ActivatedRoute,
              private campagnaSrv: CampagnaService,
              public router: Router,
              public authSrv: AuthService,
              public modalSrv: ModalService) {
    this.isRenderInBrowser = isPlatformBrowser(platformId);
    this.userAgent = this.deviceService;
    this.webPSupported = ImageUtilService.supportWebP();

    this.gallery = {
      images: [],
      options: [],
      currentIndex: 0,
      dataResponse: [],
    };
    this.listTabOfferteTop = [];
    this.listOfferteMap = {};
    this.listSelezioniMacro = [];
    this.isMobileDevice = this.userAgent.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.TABLET_VERTICAL;
    this.setGalleryOptions();
    this.setSwiperOptions();
    this.getHomeData();
    this.getGallery();
    this.macroBoxConfig = HomeService.macroBoxConfig();
    this.formGroupNewsLetter = UserValidaror.formNewsLetter();
    this.formGroupNewsLetterError = UserValidaror;
    this.isPlatformBrowser = isPlatformBrowser(platformId);
    this.user = this.authSrv.getUser();
    if (this.user) {
      this.calcolaChashback(true);
    }
  }

  ngOnInit() {
    this.titleService.setTitle(SEO_GLOBAL_KEYWORD.DEFAULT_TITLE_PAGE);
    this.metaTagService.updateTag(
      {name: 'description', content: SEO_GLOBAL_KEYWORD.DEFAULT_DESCRIPTION_PAGE}
    );
    this.metaTagService.updateTag(
      {name: 'title', content: SEO_GLOBAL_KEYWORD.DEFAULT_TITLE_PAGE},
    );
    this.metaTagService.updateTag(
      {property: 'og:title', content: SEO_GLOBAL_KEYWORD.DEFAULT_TITLE_PAGE},
    );
    this.metaTagService.updateTag(
      {property: 'og:image', content: window.location.origin + "/assets/img/logo-borsaviaggi.png"}
    );
    this.metaTagService.updateTag(
      {property: 'og:description', content: SEO_GLOBAL_KEYWORD.DEFAULT_DESCRIPTION_PAGE}
    );
    if (this.tabGroup) {
      this.tabGroup.realignInkBar();
    }
    this.subscribeUser = this.authSrv.userObservable().subscribe(
      (isLogged) => {
        this.calcolaChashback(isLogged);
      }
    );
    if (this.route.snapshot.params) {
      this.campagnaSrv.startManager(this.route.snapshot.params);
    }
  }

  public actionImageClick(event: Event) {
    if (event.target['className'].indexOf('ngx-gallery-icon-content') === -1) {
      let link = this.gallery.images[this.gallery.currentIndex].link;
      let regex = new RegExp(this.multimediaSrv.regexUrl);
      if (!link.match(regex)) {
        this.router.navigate([link]);
      } else {
        window.open(link);
      }
    }
  }

  public actionChangeImage(event) {
    this.gallery.currentIndex = event.index;
  }

  private setSwiperOptions() {
    this.configSwiper = {
      topItems: {
        effect: 'flip',
        /*cubeEffect: true,*/
        navigation: true,
        updateOnWindowResize: true
      },
      servizi: {
        effect: 'slide',
        pagination: true,
        navigation: false,
        updateOnWindowResize: true
      }
    };
  }

  private setGalleryOptions() {
    this.gallery.options = [
      {
        width: '100%',
        height: this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.TABLET_VERTICAL ? '40vh' : '67vh',
        fullWidth: true,
        lazyLoading: true,
        imageAutoPlay: true,
        // imageArrows: this.deviceService.isMobile(),
        // imageArrowsAutoHide: !this.deviceService.isMobile(),
        imageAutoPlayInterval: 4000,
        imageSwipe: true,
        imageDescription: true,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageBullets: this.deviceService.isMobile(),
        thumbnails: false,
        preview: false,
        imageAnimation: NgxGalleryAnimation.Fade
      }
    ];
  }

  private calcolaChashback(isLogged) {
    if (isLogged) {
      this.user = this.authSrv.getUser();
      let buoniScontoUser = this.user.listaBuoniSconto;
      let totale = 0;
      for (let i = 0; i < buoniScontoUser.length; i++) {
        totale = totale + (buoniScontoUser[i].valore || buoniScontoUser[i].ImportoSconto);
      }
      this.macroBoxConfig.utilizzoCashback.valoreCashback = totale || "0";
      this.macroBoxConfig.utilizzoCashback.sottoTitolo = totale > 0 && this.user.userInfo.isPrime ? "Usa subito il tuo buono sconto" : "Scopri come ottenere fino 200€ di sconti"
    } else {
      this.macroBoxConfig.utilizzoCashback.valoreCashback = "0";
      this.macroBoxConfig.utilizzoCashback.sottoTitolo = "Scopri come ottenere fino 200€ di sconti"
    }
  }

  private getHomeData() {
    this.homeSrv.getHomeData().then(
      (response) => {
        if (response && ResponseCheckerService.isSuccessResponse(response.body)) {
          this.listPartner = response.body.data.listaNostriPartner;
          this.listTabOfferteTop = response.body.data.listaTipologiaTop;
          this.managerDettOfferteTop(this.listTabOfferteTop);
          this.listSelezioniMacro = HomeService.macroSelezioniBoxConfigParser(response.body.data.listaNostreSelezioni);
          this.cacheSrv.setCacheData(CACHE_KEY.PARTNER_LIST, response.body.data.listaNostriPartner, 0);
        } else {
          //TODO gestire errore in home
        }
      },
      (errror) => {
        console.error("Errore in caricamento gallery", errror);
      }
    )
  }

  private managerDettOfferteTop(paramArraOfferteTop) {
    if (paramArraOfferteTop && paramArraOfferteTop.length > 0) {
      this.getDettaglioTabOffertaTop(paramArraOfferteTop[0]);
    }
  }

  private getDettaglioTabOffertaTop(paramItemOffertaTop) {
    let paramRq = {
      "tipologia": paramItemOffertaTop.nome,
      "idTipologia": paramItemOffertaTop.idTipologia,
      "numeroElementi": NUMERO_BOX_OFFERTE_TOP
    };

    this.homeSrv.getDettaglioTipologiaOfferta(paramRq).then(
      (response) => {
        if (ResponseCheckerService.isSuccessResponse(response.body)) {
          this.listOfferteMap[paramRq.idTipologia] = response.body.data;
          //console.log(this.listOfferteMap);
        } else {
          console.error(ResponseCheckerService.getErrorMessage(response.body));
        }

      },
      (error) => {
        console.error("Errore caricamento offerta top: " + paramRq.tipologia + " " + error);
      }
    )
  }

  private populateGallery(dataResp) {
    this.gallery.images.length = 0;
    dataResp.body.data.map((currFoto, index) => {
      if (this.isMobileDevice && currFoto.urlFotoMobile) {
        let urlFoto = ImageUtilService.supportWebP() ? currFoto.urlFotoMobile.replace(/.png|.jpg|.jpeg/gi, ".webp") : currFoto.urlFotoMobile;
        this.gallery.images.push(
          {
            small: urlFoto,
            medium: urlFoto,
            big: urlFoto,
            link: currFoto.collegamento
          }
        );
      } else if (!this.isMobileDevice && currFoto.urlFoto) {
        let urlFoto = ImageUtilService.supportWebP() ? currFoto.urlFoto.replace(/.png|.jpg|.jpeg/gi, ".webp") : currFoto.urlFoto;
        this.gallery.images.push(
          {
            small: urlFoto,
            medium: urlFoto,
            big: urlFoto,
            link: currFoto.collegamento
          }
        );
      }
    });
  }


  private getGallery() {
    this.multimediaSrv.getHomeGallery().then(
      (response) => {
        if (ResponseCheckerService.isSuccessResponse(response.body) && response.body.data.length > 0) {
          this.gallery.dataResponse = response;
          this.populateGallery(response);
        } else {
          //TODO gestire errore gallery
        }
      },
      (errror) => {
        console.error("Errore in caricamento gallery", errror);
      }
    )
  }

  public loadSelezione(dataItem) {
    if (!this.listOfferteMap[dataItem.idTipologia]) {
      this.getDettaglioTabOffertaTop(dataItem);
    }
  }

  public actionSubmitNewsLetter(isFormaValid) {
    if (isFormaValid) {
      this.loadingSendIscrizioneNL = true;
      this.homeSrv.iscrizioneNewsletter({email: this.formGroupNewsLetter.get('email').value})
        .then(
          (resp) => {
            resp.body.status !== -1 ? this.modalSrv.openFeedbackModal({'success': resp.body.message}) :
              this.modalSrv.openFeedbackModal({'error': resp.body.message});
          },
          (error) => {
            this.modalSrv.openFeedbackModal({'error': error});
          }
        ).finally(
        () => {
          this.loadingSendIscrizioneNL = false;
          this.formGroupNewsLetter.get('email').setValue('');
          this.formGroupNewsLetter.get('email').markAsUntouched();
        }
      );
    }
  }

  public changeTab() {
    this.indiceTopItem = 0
  }

  public toggleShowSearchEngine() {
    this.showSearchEngine = !this.showSearchEngine;
  }


  ngOnDestroy(): void {
    if (this.subscribeRoute) {
      this.subscribeRoute.unsubscribe();
    }
    if (this.subscribeUser) {
      this.subscribeUser.unsubscribe();
    }
  }
}
