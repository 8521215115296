import {Component, Input, OnInit} from '@angular/core';
import {LOADER_TYPE} from "./loader.service";

@Component({
  selector: 'borsaviaggi-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() show: boolean;
  @Input() color: string;
  @Input() diameter: number;
  @Input() typeMode: LOADER_TYPE;
  public fullScreen = false;

  constructor() {
    this.color = "primary";
    this.show = false;
  }

  public showLoader() {
    this.show = true;
  }

  public hideLoader() {
    this.show = false;
  }

  ngOnInit() {
    this.fullScreen = this.typeMode === "fullScreen";
    this.diameter = this.diameter || (this.typeMode === "piker" ? 30 : 80);
  }

}
