import {Injectable} from "@angular/core";
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class LampadaDesideriService {

  constructor(private genericHttpService: GenericGateService) {
  }
  public getDestinazioniLampadaDesideri(): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/banner/getDestinazioniLampadaDesideri");
  }

  public getLocalitaLampadaDesideri(idDestinazione): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/banner/getLocalitaLampadaDesideri/" + idDestinazione);
  }

  public getLuoghiPartenzaLampadaDesideri(idLocalita): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/banner/getLuoghiPartenzaLampadaDesideri/" + idLocalita);
  }

  public creaAvvisoLampadaDesideri(param): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/banner/creaAvvisoLampadaDesideri", param);
  }

}
