import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[highlight]'
})
export class HighlightDirective {

  constructor(private renderer: Renderer2,private el: ElementRef) { }

  @Input('highlightClass') highlightClass: string;

  @HostListener('click') onMouseClick() {
    let parent = this.el.nativeElement.parentNode;
    for (var i = 0; i < parent.children.length; i++) {
      parent.children[i].classList.remove(this.highlightClass);
    }
    this.renderer.addClass(this.el.nativeElement, this.highlightClass);
  }


}
