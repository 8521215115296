import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ImageCropperComponent} from 'ngx-image-cropper';
import {ModalInterface} from "../../../interface/modal.interface";
import {UserService} from "../../../services/gate/user.service";
import {LoaderService} from "../../loader/loader.service";

@Component({
  selector: 'borsaviaggi-cropimage',
  templateUrl: './cropimage.component.html',
  styleUrls: ['./cropimage.component.scss']
})
export class CropimageComponent implements OnInit {
  private croppedImage: any = '';
  public imageObj: any;
  public onSuccessUpdate: boolean = false;
  public onErrorUpdate: boolean = false;
  public messageFromService = "";

  @ViewChild(ImageCropperComponent) imageCropper:ImageCropperComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalInterface<CropimageComponent>,
              private userSrv: UserService,
              private loaderSrv: LoaderService) {
    this.imageObj = this.data.dataParam;
  }

  ngOnInit() {
  }

  uploadImage(){
    this.croppedImage = this.imageCropper.crop().base64; //manual crop
    let dataParam = {
      foto: this.croppedImage.replace('data:image/png;base64,','')
    };

    this.loaderSrv.show();
    this.userSrv.putFotoUtente(dataParam).then((response) => {
      this.messageFromService = response.body.message;
      if (response && response.body.status === 0) {
        this.imageObj.url = this.croppedImage;
        this.onSuccessUpdate = true;
      } else {
        this.onErrorUpdate = true;
      }
    },(error) => {

    }).finally(()=>{
      this.loaderSrv.hide();
    });

  }
}
