<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px background-white">
  <img class="regalo-img" alt="Regalati un viaggio da sogno"
       src="../../../assets/img/carte-regalo/header-banner-cartaregalo.jpg">
  <div *ngIf="route.children.length == 0" class="row justify-content-between">
    <div *ngFor="let cards of giftCards">
      <borsaviaggi-gift-card [configObj]="cards"></borsaviaggi-gift-card>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
