<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px background-white">
  <div class="text-align-right">
    <button mat-raised-button class="background-orange-borsaviaggi" (click)="moveBack()">
      <mat-icon>arrow_left</mat-icon>
      Vedi Tutte
    </button>
  </div>
  <div *ngIf="detailsData" class="main-detail row">
    <diV class="img-container col col-md-5 text-align-center">
      <img class="details-img" [src]="detailsData.imgBanner">
    </diV>
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi">{{detailsData.titolo}}</h1>
      <div [innerHTML]="detailsData.sottotitolo"></div>
      <h3 *ngIf="detailsData.dataScadenzaPromo" class="mt-3">Promo attiva fino
        al {{detailsData.dataScadenzaPromo | date }}</h3>
    </div>
  </div>
  <div>
    <form [formGroup]="giftForm" class="mt-20px">
      <div class="form-group row">
        <mat-form-field *ngIf="detailsData && detailsData.tipologia != 1" class="col quantita gift-form-field"
                        appearance="outline">
          <mat-label>Scegli l'Importo</mat-label>
          <mat-select formControlName="quantita" placeholder="Scegli l'Importo">
            <mat-option *ngFor="let valore of valoriMenu" [value]="valore.value">
              {{valore.viewValue}}
            </mat-option>
          </mat-select>
          <!--<mat-select formControlName="quantita" required placeholder="Quantita">
            <mat-option *ngFor="let valore of [1,2,3,4,5,6,7,8,9,10]" [value]="valore">
              {{valore}}
            </mat-option>
          </mat-select>-->
        </mat-form-field>
        <div *ngIf="detailsData && detailsData.tipologia != 1"
             class="col gift-form-field border-grey-1px gift-label mb-10px">
          <mat-label class="fs-14px">Importo da Pagare</mat-label>
          <div class="text-align-center color-text-orange-borsaviaggi fs-16px">{{getGiftValue()}} €</div>
        </div>
        <div *ngIf="detailsData && detailsData.tipologia == 1"
             class="col gift-form-field border-grey-1px gift-label first-label mb-10px">
          <mat-label class="fs-14px">Importo da Pagare</mat-label>
          <div class="text-align-center color-text-orange-borsaviaggi fs-16px">{{detailsData.importo}} €</div>
        </div>
        <div *ngIf="detailsData && detailsData.tipologia == 1"
             class="col gift-form-field border-grey-1px gift-label mb-10px">
          <mat-label class="fs-14px">Valore</mat-label>
          <div class="text-align-center color-text-orange-borsaviaggi fs-16px">{{detailsData.importoValoreExtra}}€
          </div>
        </div>
        <button mat-raised-button class="col-12 col-md-2 gift-button-field background-orange-borsaviaggi
        button-gift" (click)="getRegalareForm()">VUOI REGALARE?
        </button>
        <button mat-raised-button class="col-12 col-md-2 acquista-button gift-button-field background-blue-borsaviaggi
        color-white button-gift" (click)="getAcquistareForm()">ACQUISTA
        </button>
      </div>
    </form>
    <div *ngIf="regalare">
      <mat-card class="mb-15px mt-10px">
        <form [formGroup]="dataForm">
          <h2>Mittente</h2>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput required placeholder="Nome" name="nome-mittente" formControlName="nome-mittente">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cognome</mat-label>
            <input matInput required placeholder="Cognome" name="cognome-mittente" formControlName="cognome-mittente">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Email" name="email-mittente" formControlName="email-mittente">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cellulare</mat-label>
            <input matInput placeholder="Cellulare" name="cellulare-mittente" formControlName="cellulare-mittente">
          </mat-form-field>
          <h2>Destinatario</h2>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput required placeholder="Nome" name="nome-destinatario" formControlName="nome-destinatario">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cognome</mat-label>
            <input matInput required placeholder="Cognome" name="cognome-destinatario"
                   formControlName="cognome-destinatario">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Email" name="email-destinatario" formControlName="email-destinatario">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cellulare</mat-label>
            <input matInput placeholder="Cellulare" name="cellulare-destinatario"
                   formControlName="cellulare-destinatario">
          </mat-form-field>
          <mat-form-field class="col" appearance="outline">
            <mat-label>Scrivi la tua Dedica</mat-label>
            <textarea rows="10" matInput placeholder="Scrivi la tua Dedica" name="dedica"
                      formControlName="dedica"></textarea>
          </mat-form-field>
          <div class="text-align-center">
            <h2>Il Metodo di Pagamento</h2>
            <div class="row">
              <div class="col col-md-3">
                <img class="credit-card-img" src="../../../assets/img/carte-regalo/img-cartedicredito.png">
              </div>
              <div class="col text-align-left">
                <h3 class="color-text-orange-borsaviaggi mt-10px">Rapido con Carta di Credito</h3>
                <p>Paga subito con Carta di Credito, sarai reindirizzato sulla piattaforma di pagamento
                  Gestpay di Banca Sella dove dovrai inserire i dati della tua carta di credito e procedere con il
                  pagamento. Ti ricordiamo che sono accettate tutte le carte di credito dei circuiti Visa e Mastercard
                  e le carte prepagate ricaricabili.</p>
              </div>
            </div>
            <button mat-raised-button class="background-orange-borsaviaggi" (click)="checkout()">Procedi</button>
          </div>
        </form>
      </mat-card>
    </div>
    <div *ngIf="acquistare">
      <mat-card class="mb-15px mt-10px">
        <form [formGroup]="acquistaForm">
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput required placeholder="Nome" name="nome" formControlName="nome">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cognome</mat-label>
            <input matInput required placeholder="Cognome" name="cognome" formControlName="cognome">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Email" name="email" formControlName="email">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cellulare</mat-label>
            <input matInput required placeholder="Cellulare" name="cellulare" formControlName="cellulare">
          </mat-form-field>
          <div class="text-align-center">
            <h2>Il Metodo di Pagamento</h2>
            <div class="row">
              <div class="col col-md-3">
                <img class="credit-card-img" src="../../../assets/img/carte-regalo/img-cartedicredito.png">
              </div>
              <div class="col text-align-left">
                <h3 class="color-text-orange-borsaviaggi mt-10px">Rapido con Carta di Credito</h3>
                <p>Paga subito con Carta di Credito, sarai reindirizzato sulla piattaforma di pagamento
                  Gestpay di Banca Sella dove dovrai inserire i dati della tua carta di credito e procedere con il
                  pagamento. Ti ricordiamo che sono accettate tutte le carte di credito dei circuiti Visa e Mastercard
                  e le carte prepagate ricaricabili.</p>
              </div>
            </div>
            <button mat-raised-button class="background-orange-borsaviaggi" (click)="checkout()">Procedi</button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
  <div *ngIf="detailsData">
    <h2 class="color-text-blue-borsaviaggi mt-20px">Info & Condizioni</h2>
    <mat-card>
      <div [innerHTML]="detailsData.descrizione"></div>
    </mat-card>
  </div>
</div>
