<div class="home-page">

  <div class="d-none d-sm-block search-engine-container">
    <borsaviaggi-search-engine [dataSearch]=""></borsaviaggi-search-engine>
  </div>

  <div class="d-block d-sm-none search-engine-container">
    <div class="row no-gutters d-block d-sm-none" (click)="toggleShowSearchEngine()">
      <div class="col-12 mobile-search-btn mr-05rem">
        <mat-icon>{{showSearchEngine ? 'cancel' : 'search' }}</mat-icon>
        <span>{{showSearchEngine ? 'Chiudi ricerca' : 'Modifica la tua ricerca'}}</span>
      </div>
    </div>
    <borsaviaggi-search-engine *ngIf="showSearchEngine"></borsaviaggi-search-engine>
  </div>

  <div class="search-results">
    <div class="row no-gutters">
      <div [ngClass]="{'riepilogo-fixed': desktopRiepilogo, 'fixed-element': stopFixed, 'd-none d-md-block': dBlock}"
           class="col-xl-3 col-md-3 col-sm-12 col-xs-12 margin-filter pr-8px pl-8px"
      >
        <div class="sidebar-left ml-0 mr-0" [hidden]="isMobileDevice">
          <!--div class="head d-flex justify-content-center align-items-center" (click)="toggleShowFilters($event)" -->
          <div class="head d-flex justify-content-center align-items-center">
            <mat-icon>filter_alt</mat-icon>
            Filtra la tua ricerca
          </div>
          <div class="body" *ngIf="showFilters">
            <div class="elem" *ngIf="!isMobileDevice">
              <div class="col-12 mt-10px" *ngIf="showFilter('isOffertaPrime')">
                <mat-slide-toggle
                  [(ngModel)]="offertaPrimeSelected" (change)="toggleCheckBox()"
                  color="primary">
                  Offerte <span class="prime-red">PRIME</span>
                </mat-slide-toggle>
              </div>
            </div>

            <!-- Filtro Budget -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Budget per persona
                  </div>
                  <div class="arrow rotate-90">
                    <img class="arrow"
                         src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="custom-slider entry" [hidden]="!initialResults.length>0">
                  <ng5-slider [(value)]="minPrice" [(highValue)]="maxPrice" [options]="sliderOptions"
                              (userChangeEnd)="actionSliderPrice()"></ng5-slider>
                </div>
              </div>
            </div>

            <!-- Filtri Destinazioni -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Destinazioni
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let df of destFilters" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Destinazioni" [(ngModel)]="df.isSelected" [hidden]="df.hidden"
                                (change)="toggleCheckBox()">
                    {{df.label| titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- Filtri Data Partenza -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Data di partenza
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let dpf of depFilters" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Destinazioni" [(ngModel)]="dpf.isSelected" [hidden]="dpf.hidden"
                                (change)="toggleCheckBox()">
                    {{dpf.label| date: 'dd MMMM yyyy'}}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- Filtri notti -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Notti
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let nf of nightFilters" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Destinazioni" [(ngModel)]="nf.isSelected" [hidden]="nf.hidden"
                                (change)="toggleCheckBox()">
                    {{nf.label}} notti
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- Filtri Categoria -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Categoria
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let ctf of categoryFilters" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox [(ngModel)]="ctf.isSelected" [hidden]="ctf.hidden" (change)="toggleCheckBox()">
                    {{ctf.label| titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- Filtri Trattamento -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Trattamento
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let sf of serviceFilters" [hidden]="!initialResults.length>0">
                  <mat-checkbox [(ngModel)]="sf.isSelected" [hidden]="sf.hidden" (change)="toggleCheckBox()">
                    {{sf.label| titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- Filtri Singoli per Tipologia -->
            <div class="elem" [hidden]="!this.initialResults.length>0">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Tipologia
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngIf="showTipologia.isAnimali">
                  <mat-checkbox [(ngModel)]="animaliAccettatiSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black" src="assets/img/icone-partenze/animali.svg">
                    Animali Accettati
                  </mat-checkbox>
                </div>
                <div class="entry" *ngIf="showTipologia.isFamigliaNumerosa">
                  <mat-checkbox [(ngModel)]="famigliaExtraSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black" src="assets/img/icone-partenze/famiglia-xl.svg">
                    Famiglia Extra
                  </mat-checkbox>
                </div>
                <div class="entry" *ngIf="showTipologia.isLastMinute">
                  <mat-checkbox [(ngModel)]="lastMinuteSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black" src="assets/img/icone-partenze/last-minute.svg">
                    Last Minute
                  </mat-checkbox>
                </div>
                <div class="entry" *ngIf="showTipologia.isBimbiGratis">
                  <mat-checkbox [(ngModel)]="offertaBimbiSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black" src="assets/img/icone-partenze/bimbi-1.svg">
                    Offerta Bimbi
                  </mat-checkbox>
                </div>
                <div class="entry" *ngIf="showTipologia.isSoloCoppie">
                  <mat-checkbox [(ngModel)]="soloCoppieSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black" src="assets/img/icone-partenze/coppie.svg">
                    Promo Coppie
                  </mat-checkbox>
                </div>
                <div class="entry" *ngIf="showTipologia.isSingle">
                  <mat-checkbox [(ngModel)]="singleSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black"
                         src="assets/img/icone-partenze/single.svg">
                    Singola in Offerta
                  </mat-checkbox>
                </div>
                <div class="entry" *ngIf="showTipologia.isZeroPensieri">
                  <mat-checkbox [(ngModel)]="zeroPensieriSelected" (change)="toggleCheckBox()">
                    <img class="icon filter-black" src="assets/img/icone-partenze/zero-pensieri.svg">
                    Zero Pensieri
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- Filtri Tour Operator -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Tour Operator
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let to of tourOperatorFilters" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox [(ngModel)]="to.isSelected" [hidden]="to.hidden" (change)="toggleCheckBox()">
                    {{to.label| titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--MOBILE-->
        <div class="sidebar-left ml-0 mr-0" *ngIf="isMobileDevice">
          <mat-accordion class="padding-0">
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-description class="pl-45px">
                  <div class="head d-flex justify-content-center align-items-center">
                    <mat-icon>filter_alt</mat-icon>
                    Filtra la tua ricerca
                  </div>

                </mat-panel-description>

              </mat-expansion-panel-header>
              <div class="body padding-0">
                <!-- Filtro Budget -->
                <div class="elem">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Budget per persona
                      </div>
                      <div class="arrow rotate-90">
                        <img class="arrow"
                             src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="custom-slider entry" [hidden]="!initialResults.length>0">
                      <ng5-slider [(value)]="minPrice" [(highValue)]="maxPrice" [options]="sliderOptions"
                                  (userChangeEnd)="actionSliderPrice()"></ng5-slider>
                    </div>
                  </div>
                </div>

                <!-- Filtri Destinazioni -->
                <div class="elem">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Destinazioni
                      </div>
                      <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="entry" *ngFor="let df of destFilters" [hidden]="!this.initialResults.length>0">
                      <mat-checkbox aria-label="Destinazioni" [(ngModel)]="df.isSelected" [hidden]="df.hidden"
                                    (change)="toggleCheckBox()">
                        {{df.label| titlecase}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <!-- Filtri Data Partenza -->
                <div class="elem">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Data di partenza
                      </div>
                      <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="entry" *ngFor="let dpf of depFilters" [hidden]="!this.initialResults.length>0">
                      <mat-checkbox aria-label="Destinazioni" [(ngModel)]="dpf.isSelected" [hidden]="dpf.hidden"
                                    (change)="toggleCheckBox()">
                        {{dpf.label| date: 'dd MMMM yyyy'}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <!-- Filtri Categoria -->
                <div class="elem">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Categoria
                      </div>
                      <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="entry" *ngFor="let ctf of categoryFilters" [hidden]="!this.initialResults.length>0">
                      <mat-checkbox [(ngModel)]="ctf.isSelected" [hidden]="ctf.hidden" (change)="toggleCheckBox()">
                        {{ctf.label| titlecase}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <!-- Filtri Trattamento -->
                <div class="elem">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Trattamento
                      </div>
                      <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="entry" *ngFor="let sf of serviceFilters" [hidden]="!this.initialResults.length>0">
                      <mat-checkbox [(ngModel)]="sf.isSelected" [hidden]="sf.hidden" (change)="toggleCheckBox()">
                        {{sf.label| titlecase}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <!-- Filtri Singoli per Tipologia -->
                <div class="elem" [hidden]="!this.initialResults.length>0">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Tipologia
                      </div>
                      <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isAnimali">
                      <mat-checkbox [(ngModel)]="animaliAccettatiSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black" src="assets/img/icone-partenze/animali.svg">
                        Animali Accettati
                      </mat-checkbox>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isFamigliaNumerosa">
                      <mat-checkbox [(ngModel)]="famigliaExtraSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black" src="assets/img/icone-partenze/famiglia-xl.svg">
                        Famiglia Extra
                      </mat-checkbox>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isLastMinute">
                      <mat-checkbox [(ngModel)]="lastMinuteSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black" src="assets/img/icone-partenze/last-minute.svg">
                        Last Minute
                      </mat-checkbox>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isBimbiGratis">
                      <mat-checkbox [(ngModel)]="offertaBimbiSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black" src="assets/img/icone-partenze/bimbi-1.svg">
                        Offerta Bimbi
                      </mat-checkbox>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isSoloCoppie">
                      <mat-checkbox [(ngModel)]="soloCoppieSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black" src="assets/img/icone-partenze/coppie.svg">
                        Promo Coppie
                      </mat-checkbox>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isSingle">
                      <mat-checkbox [(ngModel)]="singleSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black"
                             src="assets/img/icone-partenze/single.svg">
                        Singola in Offerta
                      </mat-checkbox>
                    </div>
                    <div class="entry" *ngIf="showTipologia.isZeroPensieri">
                      <mat-checkbox [(ngModel)]="zeroPensieriSelected" (change)="toggleCheckBox()">
                        <img class="icon filter-black" src="assets/img/icone-partenze/zero-pensieri.svg">
                        Zero Pensieri
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <!-- Filtri Tour Operator -->
                <div class="elem">
                  <div class="col-12">
                    <div class="title-wrapper">
                      <div class="title">
                        Tour Operator
                      </div>
                      <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                    </div>
                    <div class="entry" *ngFor="let to of tourOperatorFilters" [hidden]="!this.initialResults.length>0">
                      <mat-checkbox [(ngModel)]="to.isSelected" [hidden]="to.hidden" (change)="toggleCheckBox()">
                        {{to.label| titlecase}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <!--div class="sidebar-left ml-0 mr-0 mb-3 pl-8px border-0">
          <div class="fs-1_2rem">
            <span class="mr-5px">Offerte trovate:</span>
            <span class="fw-800 color-text-orange-borsaviaggi">{{searchResults.length}}</span>
          </div>
        </div-->
      </div>

      <div *ngIf="isMobileDevice" class="row ml-0 mr-0 position-relative full-width">
        <div class="col-12">
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            (click)="cardReduction()">
            Vedi lista ridotta
          </mat-slide-toggle>
        </div>
        <div class="col-12 mt-5px" *ngIf="showFilter('isOffertaPrime') ">
          <mat-slide-toggle
            [(ngModel)]="offertaPrimeSelected" (change)="toggleCheckBox()"
            color="primary">
            Offerte <span class="prime-red">PRIME</span>
          </mat-slide-toggle>
        </div>
        <div class="mobile-mode-select">
          <mat-button-toggle-group #group="matButtonToggleGroup"
                                   [(value)]="toggleSelected"
          >
            <mat-button-toggle value="list" (click)="switchCardsLayout(false)">
              <i class="material-icons">
                view_list
              </i>
            </mat-button-toggle>
            <mat-button-toggle value="map" (click)="switchMapLayout()">
              <i class="material-icons">
                map
              </i>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div id="risultatiElenco" class="col-xl-9 col-md-9 col-sm-12 col-xs-12 px-3"
           [ngClass]="{'col-left': (desktopRiepilogo || stopFixed)}">
        <div class="main-container">
          <div class="options-row" *ngIf="!isMobileDevice">
            <div class="col-auto">
              <div class="full-width fs-1_2rem">
                <span class="mr-5px">Offerte trovate:</span>
                <span class="fw-800 color-text-orange-borsaviaggi">{{searchResults.length}}</span>
              </div>
            </div>
            <div class="mode-select">
              <mat-button-toggle-group #group="matButtonToggleGroup"
                                       [(value)]="toggleSelected"
              >
                <mat-button-toggle value="list" (click)="switchCardsLayout(false)">
                  <i class="material-icons">
                    view_list
                  </i>
                </mat-button-toggle>
                <mat-button-toggle value="module" (click)="switchCardsLayout(true)">
                  <i class="material-icons">
                    view_module
                  </i>
                </mat-button-toggle>
                <mat-button-toggle value="map" (click)="switchMapLayout()">
                  <i class="material-icons">
                    map
                  </i>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>


          </div>
          <div class="cards-container">
            <div *ngIf="!searchResults || searchResults.length == 0">Nessun risultato trovato</div>
            <ng-container *ngIf="!map.show">
              <result-card *ngFor="let searchResult of searchResults"
                           [config]="resultCardConfig" [data]="searchResult" [cardReduce]="cardReduce"></result-card>
            </ng-container>

            <div *ngIf="searchResults  && searchResults.length > 0  && map.show" class="mt-15px">
              <agm-map
                [fitBounds]="map.bounds"
                (zoomChange)="eventZoomChange($event)"
                (mapReady)="mapReady($event)"
                [maxZoom]="19"
                gestureHandling="greedy"
                [zoom]="map.zoom">
                <agm-marker
                  *ngFor="let marker of map.markers"
                  [latitude]="marker.coords.latitude"
                  [longitude]="marker.coords.longitude"
                  [label]="marker.options.labelOptions"
                  [openInfoWindow]="false"
                  (markerClick)="actionMarker(marker)"
                  [visible]="marker.visible"
                  [iconUrl]="marker.options.icon">
                  <agm-snazzy-info-window [isOpen]="marker.infoWindowIsOpen" [openOnMarkerClick]="false"
                                          (afterClose)="actionCloseInfoWindow()" [closeWhenOthersOpen]="true"
                                          [maxWidth]="325" [padding]="0">
                    <ng-template>
                      <div class="infowin">
                        <div class="title">Destinazione</div>
                        <div class="wrapper-edr-infowindow">
                          <div class="infowin-elem">
                            <div class="col-xs-12 map-info">
                              <div class="tbl-info-edr col-xs-12 position-relative">
                                <table class="tbl-info-edr col-xs-12 max-width-80p">
                                  <thead></thead>
                                  <tbody>
                                  <tr class="">
                                    <td class="col-xs-4 text-left"><span class="">Nome:</span></td>
                                    <td class="col-xs-8 text-left"><strong
                                      class="">{{map.markerSelected.data.nomeStruttura}}</strong></td>
                                  </tr>
                                  <tr class="">
                                    <td class="col-xs-4 text-left"><span class="">Partenza:</span></td>
                                    <td class="col-xs-8 text-left"><strong
                                      class="">{{map.markerSelected.data.dataPartenza |  date:'dd-MM-yyyy'}}</strong>
                                    </td>
                                  </tr>
                                  <tr class="">
                                    <td class="col-xs-4 text-left"><span class="">Notti:</span></td>
                                    <td class="col-xs-8 text-left"><strong
                                      class="">{{map.markerSelected.data.notti}}</strong>
                                    </td>
                                  </tr>
                                  <tr class="">
                                    <td class="col-xs-4 text-left"><span class="">Prezzo:</span></td>
                                    <td class="col-xs-8 text-left"><strong
                                      class="">{{map.markerSelected.data.totalPrice}}€</strong>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                                <a class="btn-vai-infowin " [routerLink]="map.markerSelected?.data?.linkParteza"
                                   [title]="'Offerta last minute'" target="_blank">
                                  <button class="background-orange-borsaviaggi"
                                          mat-raised-button>
                                    <span class="color-white">VAI</span>
                                  </button>
                                </a>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </agm-snazzy-info-window>
                </agm-marker>
              </agm-map>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
