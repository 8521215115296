import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
