import {Injectable} from "@angular/core";
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class ContainerDestinazioniService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getViaggiMondo(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/form/getViaggiMondo");
  }

  public getViaggiItalia(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/form/getViaggiItalia");
  }

  public getHotelMondo(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/form/getHotelMondo");
  }

  public getHotelItalia(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/form/getHotelItalia");

  }

  public getOfferteDestinazione(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getOfferteDestinazione", param);
  }

  public getLocalitaItalia(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getLocalitaItalia", param);
  }

  public getLocalitaMondo(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/offerta/getLocalitaMondo/" + param);
  }

}
