import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DatiPersonaliValidator} from "../../../services/validator/dati-personali.validator";

@Component({
  selector: 'borsaviaggi-contattaci-modal',
  templateUrl: './contattaci-modal.component.html',
  styleUrls: ['./contattaci-modal.component.scss']
})
export class ContattaciModalComponent implements OnInit {
  public formGroup: FormGroup = DatiPersonaliValidator.formRichiestaInfoValidator;
  public validation_messages:any = DatiPersonaliValidator.formRichiestaInfoErrorMessage;
  public loading: any;
  constructor() { }

  ngOnInit() {
  }

  public richiediInfo(valid) {
    if (valid) {
      let dataParam = {
        "email": this.formGroup.controls['email'].value,
        "password": this.formGroup.controls['password'].value,
        "nome": this.formGroup.controls['nome'].value,
        "cognome": this.formGroup.controls['cognome'].value,
        "cellulare": this.formGroup.controls['cellulare'].value,
      };
      /*      this.userSrv.new(dataParam).then(
              (response) => {
                if (response && response.body && response.body.status === 0) {
                  this.loading = false;

                  this.openFeedback({success: response.body.message},this.closeDialog.bind(this));
                } else {
                  this.loading = false;
                  this.openFeedback({error: response.body.message});

                }
              },
              (error) => {
                this.loading = false;
                this.openFeedback({error: error});
              }
            )*/
    }
    else {
      for (let formControlName in this.formGroup.controls) {
        this.formGroup.get(formControlName).markAsTouched();
        this.formGroup.get(formControlName).markAsDirty();
      }
    }
  }

}
