import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {LoginSidenavService} from "./loginSidenav.service";
import {TYPE_MODE} from "../navigation-component/type-mode-menu.const";
import {FormGroup} from "@angular/forms";
import {LoginValidator} from "../../services/validator/login.validator";
import {UserService} from "../../services/gate/user.service";
import {Router} from '@angular/router';
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {ModalService} from "../../services/common/modal.service";
import {LoaderService} from "../loader/loader.service";
import {AuthService} from "../../services/common/auth.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'borsaviaggi-login-sidenav',
  templateUrl: './login-sidenav.component.html',
  styleUrls: ['./login-sidenav.component.scss']
  //host: {'(window:resize)': 'onResize($event)'}
})
export class LoginSidenavComponent implements OnInit {
  @Input('sideNavLoginCtrl') private sideNavLoginCtrl: MatSidenav;
  @Output() mobileModeChange = new EventEmitter();

  public isMobileMode: boolean = false;
  public isOpenLogin: boolean = false;
  public typeModeMenu: string;
  public loginForm: FormGroup;
  public errorMessage;
  public showAlreadyPrime;
  public verifyEmail;

  constructor(private loginSideSrv: LoginSidenavService,
              private userSrv: UserService,
              private modalSrv: ModalService,
              private deviceService: DeviceDetectorService,
              private authSrv: AuthService,
              private loaderSrv: LoaderService,
              private _router: Router) {
    this.isMobileMode = this.deviceService.isMobile();
    this.typeModeMenu = TYPE_MODE.DEFAULT;
    this.loginForm = LoginValidator.formLoginValidator;
    this.loginSideSrv.visibleLoginState.subscribe((state: boolean) => {
      this.isOpenLogin = false;
      this.sideNavLoginCtrl.close();
    });
    this.loginSideSrv.openLoginState.subscribe((state: boolean) => {
      this.isOpenLogin = state;
      if (this.isOpenLogin) {
        this.sideNavLoginCtrl.open();
      } else {
        this.sideNavLoginCtrl.close();
      }
    });
  }

  ngOnInit(): void {
    this.isOpenLogin = false;
  }

  /*onResize(event) {
    if (event.target.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE) {
      this.isMobileMode = true;
      this.sideNavLoginCtrl.close();
    } else {
      this.isMobileMode = false;
      this.sideNavLoginCtrl.close();
    }
  }*/

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileMode = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  public actionSignUp() {
    if (!this.showAlreadyPrime) {
      this.loginSideSrv.close();
    }

    this.modalSrv.openRegistrazioneModal({},
      (data) => {
        if (data) {
          this.verifyEmail = true;
        }
      });
  }

  public closeLogin() {
    this.loginSideSrv.close();
  }

  public actionRecupero() {
    this.loginSideSrv.close();
    this.modalSrv.openRecuperoPassword(null);
  }

  public goToPrime() {
    this._router.navigate(['prime']);
    this.loginSideSrv.close();
  }

  public login(valid) {
    if (valid) {
      this.loaderSrv.show();
      let dataParam = {
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value
      };
      this.userSrv.login(dataParam).then(
        (response) => {
          if (response && response.body.data) {
            this.authSrv.setUser(response.body.data);
            this.loginSideSrv.close();
          } else {
            this.errorMessage = "Email o Password errati!";
          }
        },
        (error) => {
          this.errorMessage = error;
        }
      ).finally(
        () => {
          this.loaderSrv.hide();
        }
      )
    } else {
      for (let formControlName in this.loginForm.controls) {
        this.loginForm.get(formControlName).markAsTouched();
        this.loginForm.get(formControlName).markAsDirty();
      }
    }
  }
}
