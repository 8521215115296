<div class="header-modal mb-20px">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <div class="d-flex align-items-center justify-content-left">
    <span mat-dialog-title class="inline margin-0">Contatti</span>
  </div>
</div>
<mat-dialog-content align="center">
  <div class="w-100 p-2 mb-10px">
    Borsaviaggi Srl<br />
    Via del Serafico 185<br />
    00142 - Roma<br />
    Tel.: 06.55.87.667<br />
    Fax.: 06.55.30.23.27<br />
    <a href="mailto:info@borsaviaggi.it">info@borsaviaggi.it</a>
  </div>
</mat-dialog-content>
<!--mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Chiudi</button>
</mat-dialog-actions-->
