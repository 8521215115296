<div class="header-modal">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <!-- <mat-icon matPrefix>single_bed</mat-icon>-->
  <h2 mat-dialog-title>{{errorData.title}}</h2>
</div>
<mat-dialog-content class="position-relative">
  <borsaviaggi-loader [(show)]="loading" typeMode='piker' diameter="30"></borsaviaggi-loader>
  <form [hidden]="loading" [formGroup]="formGroup">
    <div class="info-container row">
      <mat-form-field name="select" class="col-12 col-sm-6 text-center pt-15px" appearance="outline">
        <mat-label>Numero di Passeggeri</mat-label>
        <mat-select formControlName="numeroPasseggeri" (selectionChange)="generateFormGroup(false)">
          <mat-option class="text-center" value="1">1</mat-option>
          <mat-option class="text-center" value="2">2</mat-option>
          <mat-option class="text-center" value="3">3</mat-option>
          <mat-option class="text-center" value="4">4</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="info-wrapper col-12">
      <div class="row no-gutters info-container ">
        <div *ngFor="let number of arrayPax;" class="col-6 col-sm-3">
          <mat-form-field name="select" appearance="outline" class="w-75">
            <mat-label>Età</mat-label>
            <input matInput type="text" formControlName="{{'etaPax-'+number}}" placeholder="Età" required>
          </mat-form-field>
        </div>
        <div [hidden]="loading" class="col-12 col-sm-12 mb-10px text-align-center">
          <button class="verifica-button" (click)="verificaDisponibilita()" mat-raised-button>
            Verifica Disponibilità
          </button>
        </div>
      </div>
      <div class="col-12 main-title-container" *ngIf="listaCabine.length > 0">
        <div class="title">Seleziona Cabina</div>
      </div>
      <div class="supplementi-column" *ngIf="listaCabine.length > 0">
        <div class="supplemento fs-1rem mb-5px">
          <mat-accordion [multi]="true">
            <mat-radio-group formControlName="cabinaSelezionata">
              <mat-expansion-panel *ngFor="let categoria of listaCabine; let iCat = index" [expanded]="iCat===0">
                <mat-expansion-panel-header class="bg-accordion-cabine">
                  <mat-panel-description class="color-white">
                    {{categoria.nomeCategoria}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row no-gutters mt-16px" *ngFor="let cabina of categoria.cabineDisponibili; let iCab = index">
                  <div class="col-12 col-sm-3">
                    {{cabina.nomeCabina}}
                  </div>
                  <div class="col-12 col-sm-9">
                    <div class="row">
                      <div class="col-12 col-sm-6 text-align-left" *ngFor="let tariffa of cabina.tariffeCabine; let i = index">
                        <mat-radio-button [value]="tariffa.codicePromozione+'$'+categoria.nomeCategoria+'$'+cabina.nomeCabina+'$'+tariffa.prezzo+'$'+tariffa.tariffa">
                          <p>{{tariffa.tariffa}}</p>
                          <p class="price bold">{{tariffa.prezzo | euroFormat}}&nbsp;€</p>
                        </mat-radio-button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-radio-group>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="nessunaDisponibilita">
        <span class="red">Nessun cabina disponibile</span>
      </div>
      <!-- AEROPORTO -->
      <div *ngIf="data.dataParam.dataResponse.isVoloIncluso" class="row pt-2rem">
        <div class="col-12">
          <span class="fs-1_2rem bold p-0 m-0">Scegli l'aeroporto</span>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Aeroporto</mat-label>
            <mat-select required placeholder="Aeroporto" formControlName="volo">
              <mat-option [value]="''">---</mat-option>
              <mat-option *ngFor="let volo of data.dataParam.dataResponse.voli" [value]="volo.idVolo">
                {{volo.tipoVolo + ' ' + volo.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="button-style mr-5px mb-10px" [hidden]="loading"
          *ngIf="!mostraPulsanteVerifica" [disabled]="!formGroup.valid" (click)="generatePreventivoRq()">Salva cabina
  </button>
</mat-dialog-actions>
