import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'borsaviaggi-scaricabuono',
  templateUrl: './scaricabuono.component.html',
  styleUrls: ['./scaricabuono.component.scss']
})
export class ScaricabuonoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
