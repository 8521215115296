<div class="title-container ml-15px" (click)="openMapsModal()">
  <div class="subtitle-wrapper">
    <div class="see-on-map cursor-pointer row">
      <div class="icon">
        <img width="20px" src="assets/img/icons/placeholder.svg">
      </div>
      <div class="text d-none d-md-inline-block">Vedi su mappa</div>
    </div>
  </div>
</div>
