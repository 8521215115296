<div class="pageMain col-md-6 col-lg-6" style="margin: auto;padding-top: 20px">
  <div class="inner">
    <div class="pageArt">
      <h2 class="pageSubTitle" style="color:#0d0964">Scalapay è l’innovativo metodo di pagamento per prenotare la tua vacanza con Costa Crociere su Borsaviaggi e pagarla in 3 comode rate senza interessi.</h2>
      <div class="article basicText">
      <img alt="scalapay" class="largeimage" src="https://cdn.borsaviaggi.it/images/0/c81d1326-b919-465e-8806-a0ff48b93442.png" style="margin-top: 30px; margin-bottom: 30px; width: 100%;">
      <h2 class="pageSubTitle" style="color:#0d0964">PROMO SCALAPAY</h2>
      <h3  style="color:#0d0964">Risparmia fino a <b style="font-weight: 900;font-size: larger;color:#0d0964">100€</b> sulle tariffe All Inclusive di <b style="font-weight: 900;font-size: larger;color:#0d0964">Costa Crociere!</b>
        <br/>
        Solo entro il <b style="font-weight: 900;font-size: larger;color:#0d0964">23 Settembre</b>
        <br/>
      </h3>
      <p style="color:darkgray">
        Grazie a Costa Crociere hai accesso ad 1 codice sconto di 100€ [legato all’importo minimo della tua prenotazione] che ti consente di acquistare qualsiasi crociera Costa in tariffa All Inclusive o Super All Inclusivea ad un prezzo scontato rispetto al miglior listino in vigore!
        <br/>
      </p>
      <h3>
        <a href="https://www.borsaviaggi.it/convenzione/scalapaycosta"><b style="font-weight: 900;font-size: larger;color:#0d0964">RISCATTA SUBITO IL TUO CODICE SCONTO ></b></a>
      </h3>
      <br/>
      <br/>
      <h2 class="pageSubTitle" style="color:#0d0964">Conosci Scalapay?</h2>
      <p style="color:darkgray">
        Puoi usare Scalapay per prenotare online la tua crociera Costa Crociere su Borsaviaggi e saldare in 3 rate, senza alcun interesse. Ti basterà selezionare il metodo di pagamento all'interno del modulo online che riceverai per confermare la tua prenotazione e creare o accedere al tuo account Scalapay, il tutto senza necessità di busta paga o invio di documentazione aggiuntiva.
      </p>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <table align="left" border="0" cellpadding="1" cellspacing="1" style="width:500px">
            <tbody>
            <tr>
              <td style="width:130px;"><img src="../../../assets/img/scalapay/World.png"></td>
              <td style="text-align:left;">
                <b style="font-weight: 900;font-size: large;color:#0d0964">Scegli la Crociera<br/> Costa</b><br>
                che preferisci.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 col-lg-6">
          <table align="left" border="0" cellpadding="1" cellspacing="1" style="width:500px;">
            <tbody>
            <tr>
              <td style="width:130px;"><img src="../../../assets/img/scalapay/Heart.png"></td>
              <td style="text-align:left;">
                Seleziona Scalapay<br>
                <b style="font-weight: 900;font-size: large;color:#0d0964">sul modulo contrattuale</b>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <table align="left" border="0" cellpadding="1" cellspacing="1" style="width:500px;">
            <tbody>
            <tr>
              <td style="width:130px;"><img src="../../../assets/img/scalapay/Person.png"></td>
              <td style="text-align:left;">
                <b style="font-weight: 900;font-size: large;color:#0d0964">Accedi o crea un account</b><br>
                in pochissimi minuti.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 col-lg-6">
          <table align="left" border="0" cellpadding="1" cellspacing="1" style="width:500px;">
            <tbody>
            <tr>
              <td style="width:130px;"><img src="../../../assets/img/scalapay/Umbrella.png"></td>
              <td style="text-align:left;">
                Prenota la tua crociera in<br>
                <b style="font-weight: 900;font-size: large;color:#0d0964">3 rate, senza interessi!</b>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      &nbsp;<a href="https://www.borsaviaggi.it/"><img alt="prenota ora" class="largeimage" src="https://cdn.borsaviaggi.it/images/0/bd114187-ecc5-4944-be1d-1cf5140ec5de.png" style="margin-top: 30px; margin-bottom: 30px; width: 100%;"></a>
    </div>
    </div>
  </div>
</div>
<div class="pageMod infoMod col-md-6 col-lg-6"style="margin: auto">
  <div class="inner">
    <div class="pageModMain infoBox">
      <div class="pageModHead ">
        <div class="article articleMod basicText">
          <h3 style="color:#0d0964">Come Funziona</h3>
          <ol>
            <li><strong>Usa Scalapay per prenotazioni fino a 2.500 € </strong></li>
            <li><strong>Scegli Scalapay al checkout</strong></li>
            <li><strong>Crea un account in pochi minuti. Scalapay accetta tutte le principali carte di credito, debito e prepagate dei circuiti VISA, MASTERCARD e AMERICAN EXPRESS.</strong></li>
            <li>
              <strong>Al momento dell’acquisto ti verrà addebitata sulla carta la prima rata. Le successive 2 rate ti verranno addebitate con cadenza mensile.</strong>
              <ul>
                <li>Prima rata: al momento dell’acquisto e 1 mese dal check -in</li>
                <li>Seconda rata: 1 mese dal giorno dell’acquisto</li>
                <li>Terza e ultima rata: 2 mesi dal giorno dell’acquisto.</li>
              </ul>
            </li>
          </ol>
          <em>Scalapay ti informerà qualche giorno prima della scadenza di ogni rata via SMS e email.</em><strong>​</strong>
          <h3 style="color:#0d0964">
            <br>
            Condizioni
          </h3>
          <ol>
            <li><strong>Devi essere maggiorenne</strong></li>
            <li><strong>Se il pagamento automatico della rata non andasse a buon fine, avrai 24 ore di tempo per completare il pagamento direttamente dal tuo account Scalapay</strong></li>
            <li>
              <strong>Se ciò non avverrà, Scalapay applicherà delle commissioni per ritardato pagamento come spiegato di seguito:</strong>
              <ul>
                <li>Pagamento effettuato entro la data di scadenza = nessun costo aggiuntivo!</li>
                <li>Pagamento effettuato con oltre 1 giorno di ritardo = fino a 6 euro di costi aggiuntivi</li>
                <li>Pagamento effettuato con oltre 1 settimana di ritardo = ulteriori 6 euro (in aggiunta alla prima commissione, fino ad un massimo di 12 euro per ogni pagamento)</li>
              </ul>
            </li>
          </ol>
          <em>Il costo totale delle commissioni non potrà superare il 15% del valore del prestito (seconda e terza rata).</em>
          <ol>
            <li value="4"><strong>Visita </strong><a href="http://www.scalapay.com/it/?tick=da05cd66" target="_blank"><strong>www.scalapay.com/it/</strong></a><strong> per tutte le condizioni</strong><strong>​</strong></li>
          </ol>
          <h3 style="color:#0d0964">
            <br>
            Da sapere&nbsp;
          </h3>
          Se prenoti con Scalapay paghi in 3 rate. Prendi atto che le rate verranno cedute a Incremento SPV S.r.l., a soggetti correlati e ai loro cessionari, e che autorizzi tale cessione.<br>
          <br>
          I sistemi Scalapay valutano ogni ordine individualmente al momento dell’acquisto e non tutti gli ordini vengono approvati. Quando possibile, ti comunicheremo il motivo del rifiuto ma spesso per motivi di sicurezza non siamo in grado di fornire una spiegazione dettagliata.<!--  -->L’approvazione non dipende dalla disponibilità sulla tua carta. <a href="https://scalapay.zendesk.com/hc/it/articles/360034600934?tick=4373f514" target="_blank">https://scalapay.zendesk.com/hc/it/articles/360034600934</a>
          <h3  style="color:#0d0964">
            <br>
            Assistenza&nbsp;
          </h3>
          Per supporto contattare l'assistenza Scalapay compilando il seguente form&nbsp;<a href="https://help.scalapay.com/hc/it/requests/new?ticket_form_id=360011048535&amp;tick=a276dbd7" target="_blank">https://help.scalapay.com/hc/it/requests/new?ticket_form_id=360011048535</a>.<br>
          Inserire il flag su “<strong>sto contattando in merito ad una vacanza</strong>”.
        </div>
      </div>
    </div>
  </div>
</div>
