<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px background-white">
  <mat-card class="mb-15px mt-5px pt-0">
    <div>
      <h2 class="text-align-center color-text-blue-borsaviaggi mb-20px">Inserisci tutti i dati per il pagamento</h2>
      <div class="row">
        <div class="col-md mb-20px causale">
          <mat-label class="fs-14px">Causale: <span
            class="color-text-orange-borsaviaggi fs-16px"> ABBONAMENTO PRIME</span></mat-label>
        </div>
        <div class="col-md mb-20px importo">
          <mat-label class="fs-14px">Importo: <span class="color-text-orange-borsaviaggi fs-16px"> 19,00 €</span>
          </mat-label>
        </div>
      </div>
    </div>
    <form [formGroup]="formGroup">
      <mat-form-field class="col col-md-6" appearance="outline">
        <mat-label>Nominativo</mat-label>
        <input matInput required placeholder="Nominativo" name="nominativo" formControlName="nominativo">
        <bv-form-error [formErrorMessage]="validation_messages.nominativo"
                       [formControlParam]="formGroup.controls.nominativo"></bv-form-error>
      </mat-form-field>
      <mat-form-field class="col col-md-6" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput required placeholder="Email" name="email" formControlName="email">
        <bv-form-error [formErrorMessage]="validation_messages.email"
                       [formControlParam]="formGroup.controls.email"></bv-form-error>
      </mat-form-field>
      <div class="mb-20px mt-20px">
        <div class="text-align-center mb-20px">
          <h2 class="color-text-blue-borsaviaggi">Inserisci i tuoi dati anagrafici</h2>
          <p class="font-italic">I seguenti dati sono obbligatori, per legge, ai fini della Fatturazione Elettronica</p>
        </div>
        <mat-form-field class="col col-md-6" appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput required placeholder="Nome" name="nome" formControlName="nome">
          <bv-form-error [formErrorMessage]="validation_messages.nome"
                         [formControlParam]="formGroup.controls.nome"></bv-form-error>
        </mat-form-field>
        <mat-form-field class="col col-md-6" appearance="outline">
          <mat-label>Cognome</mat-label>
          <input matInput required placeholder="Cognome" name="cognome" formControlName="cognome">
          <bv-form-error [formErrorMessage]="validation_messages.cognome"
                         [formControlParam]="formGroup.controls.cognome"></bv-form-error>
        </mat-form-field>
        <mat-form-field name="select" class="col col-md-6" appearance="outline">
          <mat-label>Regione</mat-label>
          <mat-select required (selectionChange)="changeRegioneEvent($event)" formControlName="idRegione">
            <mat-option *ngFor="let option of listRegioni" [value]="option.idRegione">{{option.regione}}</mat-option>
          </mat-select>
          <bv-form-error [formErrorMessage]="validation_messages.idComune"
                         [formControlParam]="formGroup.controls.idComune"></bv-form-error>
        </mat-form-field>
        <mat-form-field class="col col-md-6" appearance="outline">
          <mat-label>Comune di Residenza</mat-label>
          <mat-select formControlName="idComune" required>
            <mat-option *ngFor="let comune of listaComuni" [value]="comune.IdComune">
              {{comune.Comune}}
            </mat-option>
          </mat-select>
          <bv-form-error [formErrorMessage]="validation_messages.idComune"
                         [formControlParam]="formGroup.controls.idComune"></bv-form-error>
        </mat-form-field>
        <mat-form-field class="col col-md-6" appearance="outline">
          <mat-label>Indirizzo di Residenza</mat-label>
          <input matInput required placeholder="Indirizzo" name="indirizzo" formControlName="indirizzo">
          <bv-form-error [formErrorMessage]="validation_messages.indirizzo"
                         [formControlParam]="formGroup.controls.indirizzo"></bv-form-error>
        </mat-form-field>
        <mat-form-field class="col col-md-6" appearance="outline">
          <mat-label>Cap</mat-label>
          <input matInput required placeholder="Cap" name="cap" formControlName="cap">
          <bv-form-error [formErrorMessage]="validation_messages.cap"
                         [formControlParam]="formGroup.controls.cap"></bv-form-error>
        </mat-form-field>
        <mat-form-field class="col col-md-6" appearance="outline">
          <mat-label>Codice Fiscale</mat-label>
          <input matInput required placeholder="Codice Fiscale" name="codiceFiscale"
                 formControlName="codiceFiscale">
          <bv-form-error [formErrorMessage]="validation_messages.codiceFiscale"
                         [formControlParam]="formGroup.controls.codiceFiscale"></bv-form-error>
        </mat-form-field>
      </div>
      <div class="mb-20px">
        <h2 class="text-align-center color-text-blue-borsaviaggi mb-20px">Scegli il Metodo di Pagamento</h2>
        <mat-radio-group required formControlName="tipoPagamento">
          <div class="row pagamento-container">
            <div class="col-md-1 col-sm-3 col-1 text-align-center">
              <mat-radio-button value="cartaDiCredito" [checked]="true"></mat-radio-button>
            </div>
            <div class="col-md-3 col-sm-4 col-4">
              <img class="payment-img" src="../../../assets/img/carte-regalo/img-cartedicredito.png">
            </div>
            <div class="col-md mb-10px">
              <h3 class="title color-text-orange-borsaviaggi mt-10px">
                Rapido con Carta di Credito
              </h3>
              <div class="text">
                <p>Paga subito con Carta di Credito, sarai reindirizzato sulla piattaforma di pagamento
                  Gestpay di Banca Sella dove dovrai inserire i dati della tua carta di credito e procedere con il
                  pagamento. Ti ricordiamo che sono accettate tutte le carte di credito dei circuiti Visa e Mastercard
                  e le carte prepagate ricaricabili.</p>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div>
      <div class="text-align-center">
        <button mat-raised-button class="background-orange-borsaviaggi" (click)="register()">Procedi</button>
      </div>
    </form>
  </mat-card>
</div>
