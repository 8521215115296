import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {RicercaCrociereService} from '../ricerca-crociere.service';

@Injectable({
  providedIn: 'root'
})
export class DettaglioCrocieraResolveService {
  constructor(private ricercaCrociereSrv: RicercaCrociereService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let req = routeSnap.params;
    let reqObj = {
      idPartenzaCrociera: req && req.idPartenzaCrociera || null
    };

    /** MOCK CROCIERA CON VOLO INCLUSO */
    // let reqObj = { idPartenzaCrociera: 98596 }
    /** MOCK CROCIERA CON VOLO NON INCLUSO */
    // let reqObj = { idPartenzaCrociera: 67960 }

    return this.ricercaCrociereSrv.getPartenzaCrociera(reqObj)
      .catch(err => {
        return err;
      });
  }

}
