<div class="container border-r-2px padding-20px box-promo mt-20px background-white">
  <div class="text-align-center mb-20px">
    <img class="img-hd mb-10px full-width" src="../../../assets/img/prezzo-piu-basso/garanziaPrezzo-hd.jpg"
         aria-label="">
    <h1 class="color-text-blue-borsaviaggi mb-20px">Borsaviaggi.it ti propone una TARIFFA CHIARA, SENZA SOPRESE!</h1>
  </div>

  <div class="row banner-price mb-20px no-gutters">
    <img class="col-12 col-sm-4 mb-10px"
         src="../../../assets/img/prezzo-piu-basso/garanziaPrezzo-garanzia.png">
    <div class="col-12 col-sm-8 text-align-center color-text-orange-borsaviaggi">
      <h2 class="pl-15px">Se sei un cliente <span class="prime-text">PRIME</span> e hai trovato un prezzo più
        vantaggioso SEGNALACELO!</h2>
      <h2 class="pl-15px">Noi te lo garantiamo e ti regaliamo anche 50 EURO</h2>
    </div>
  </div>

  <div class="mt-30px mb-30px">
    <p>Attraverso un'attenta selezione delle proposte <span class="fw-800"> otterrai sempre l'offerta prescelta
      con il migliore rapporto qualità/prezzo</span>. Se prima di effettuare la prenotazione <span class="fw-800">
      hai trovato la stessa proposta ad un prezzo più vantaggioso </span>, segnelacela, il nostro consulente
      raccoglierà tutte le informazioni tecniche sull'offerta e, una volta verificata la fonte e la qualità,
      <span class="fw-800">provvederà immediatamente a prenotarla per tuo conto confermando il prezzo
      più basso</span>.</p>
    <ul>
      <li class="fw-800">E in più avrai un buono di 50 Euro da spendere per una prossima prenotazione!*</li>
    </ul>
  </div>

  <div class="row banner-price mb-30px no-gutters">
    <img class="col-12 col-sm-4 mb-10px"
         src="../../../assets/img/prezzo-piu-basso/garanziaPrezzo-operazione.png">
    <div class="col-12 col-sm-8 text-align-center color-text-orange-borsaviaggi">
      <h2 class="pl-15px">Tutte le nostre offerte sono presentate </h2>
      <h2 class="pl-15px">da subito con il loro PREZZO FINITO!</h2>
    </div>
  </div>

  <div>
    <p>Borsaviaggi presenta una politica di vera trasparenza nei confronti dei suoi utenti!<br>
      Su qualsiasi partenza presente sul portale ed effettuando una ricerca da motore o da selezioni speciali viene
      indicato sin da subito il prezzo finale a persona su base doppia/matrimoniale.</p>
  </div>
  <div class="mt-20px">
    <p>Le tariffe indicate riporteranno già inclusi tutti i costi che compongono il pacchetto:</p>
    <ul class="fw-800">
      <li>Quota Base</li>
      <li>Costi Accessori e Tasse</li>
      <li>Eventuale Adeguamento Carburante*</li>
    </ul>
  </div>
  <div class="mb-20px mt-20px mb-20px">
    <p>NB: il costo dell'Adeguamento Carburante nella quota Prezzo Finito è incluso se:</p>
    <ul class="fw-800">
      <li>Nell'offerta è presente il bollino NO FUEL</li>
      <li>Nell'offerta è già indicata la voce di costo relativa ad Adeguamento Carburante</li>
    </ul>
  </div>
  <div class="mb-20px fs-07rem mt-30px">*NB: il buono è nominativo - valido per acquisti di un pacchetto viaggio per due
    persone su Borsaviaggi.it
    da spendere entro un anno dalla emissione - I buoni non sono cumulabili ne tra di loro, con gli sconti derivanti
    dal programma fedeltà GOLD Membership ed in periodi di sconti speciali quali Fuori Tutto ecc...
  </div>
</div>
