import {Injectable} from '@angular/core';
import {GenericGateService} from '../common/genericGate.service';
import {RequestMethodEnum} from './request.method.enum';

@Injectable()
export class DettaglioZService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getOfferteDestinazioni(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/form/getLastMinuteOfferteDestinazione/" + param);
  }

  public getHeaderDestinazioni(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/form/getLastMinuteHeaderDestinazione/" + param)
  }
}
