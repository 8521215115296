import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalInterface} from "../../../interface/modal.interface";
import {InfoModalDto} from "../../../model/infoModal.dto";

@Component({
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  public confirmData = new InfoModalDto();
  public loading: boolean;
  public onSuccessUpdate: boolean = false;
  public onErrorUpdate: boolean;

  constructor(public dialogRef: MatDialogRef<SuccessModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<SuccessModalComponent>) {

    if (Object.keys(data).length) {
      for (let key in data) {
        this.confirmData[key] = data[key] || this.confirmData[key];
      }
      this.confirmData.button_text = this.confirmData["dataParam"].button_text || this.confirmData.button_text;
      this.confirmData.button_icon = this.confirmData["dataParam"].button_icon || this.confirmData.button_icon;
      if (this.confirmData["dataParam"].success) {
        this.confirmData.title = "Success";
        this.onSuccessUpdate = this.confirmData["dataParam"].success;
      } else {
        this.confirmData.title = "Error";
        this.onErrorUpdate = this.confirmData["dataParam"].error;
      }
    }
  }

  ngOnInit() {
  }




}
