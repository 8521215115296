<ng-container>
  <div class="desktop-mode-view" *ngIf="configMode=='desktop' && !isMobileMode" >
    <nav class="navbar navbar-expand-lg">
      <ng-container *ngFor="let navItem of itemMenu">
        <a *ngIf="!navItem.macroItem"
          class="display-inline-block menu-item-a"
          [title]="navItem.title"
          [routerLink]="[navItem.path]">
          <span>{{navItem.title}}</span>
        </a>
        <div class="position-relative display-inline">
          <a
            *ngIf="navItem.macroItem && navItem.path"
            class="display-inline-block menu-item-a macro-item"
            [routerLink]="navItem.path"
            [title]="navItem.title"
            (mouseenter) ="mouseEnterMacroItemMenu($event,navItem)"
            (mouseleave) ="mouseLeaveMacroItemMenu($event,navItem)"
          >
            <span>{{navItem.title}}</span>
          </a>
          <a
            *ngIf="navItem.macroItem && navItem.link"
            class="display-inline-block menu-item-a macro-item"
            [href]="navItem.link"
            target="_blank"
            [title]="navItem.title"
          >
            <span>{{navItem.title}}</span>
          </a>
          <div
            *ngIf="navItem.macroItem && !navItem.path && !navItem.link"
            class="display-inline-block menu-item-a macro-item"
            [ngClass]="{'children':navItem?.childItem?.length>0}"
            (mouseenter) ="mouseEnterMacroItemMenu($event,navItem)"
            (mouseleave) ="mouseLeaveMacroItemMenu($event,navItem)"
          >
            <span>{{navItem.title}}</span>
          </div>
          <div
            class="navbar-nav row sub-item-container {{navItem.macroItem && navItem.showSubMenu ? 'active' : 'inactive'}}"
            [ngClass]="navItem.subMenuCssDesktopClass"
            (mouseenter) ="mouseEnterMacroItemMenu($event,navItem)"
            (mouseleave) ="mouseLeaveMacroItemMenu($event,navItem)"
          >
            <div class="sub-item-ul" [ngClass]="navItem.subMenuBanner ? 'col-8': 'col-12'">
              <div class="row">
                <div *ngFor="let item of navItem.childItem"
                  class="nav-item"
                  [ngClass]="item.cssDesktopClass">
                  <a *ngIf="!item.link"
                    class="nav-link"
                    [routerLink]="[item.path]"
                    [title]="item.title"
                    routerLinkActive="selected">
                    <span>{{item.title}}</span>
                  </a>
                  <a *ngIf="item.link"
                    class="nav-link"
                    [href]="item.link"
                    target="_blank"
                    [title]="item.title">
                    <span>{{item.title}}</span>
                  </a>
                </div>
              </div>

            </div>
            <div class="sub-menu-banner col-4 no-gutters padding-0"
              *ngIf="navItem.subMenuBanner && !navItem.subMenuBanner.clickLink"
              [ngClass]="navItem.subMenuBanner.cssClass">
              <img class="img-fluid"
                [src]="navItem.subMenuBanner.img"
                [alt]="navItem.subMenuBanner.seoDesc">
            </div>
            <a *ngIf="navItem.subMenuBanner && navItem.subMenuBanner.clickLink"
              [href]="navItem.subMenuBanner.clickLink"
              target="_blank"
              class="sub-menu-banner col-4 no-gutters padding-0 action"
              [ngClass]="navItem.subMenuBanner.cssClass">
              <img class="img-fluid"
                [src]="navItem.subMenuBanner.img"
                [alt]="navItem.subMenuBanner.seoDesc">
            </a>
          </div>
        </div>
      </ng-container>

    </nav>
  </div>
  <div class="mobile-mode-view" *ngIf="configMode=='mobile' && isMobileMode">
    <div class="mobile-menu-header" *ngIf="isMobileMode">
      <div class="pull-right mr-10px">
        <mat-icon class="md-36 cursor-pointer" aria-hidden="false"
          (click)="toggleMenu()">
          close
        </mat-icon>
      </div>
    </div>
    <ul class="sidebar-sub-level-items">
      <li class="fly-out-top-item" *ngFor="let navItem of itemMenu">
        <div *ngIf="navItem.macroItem" class="macro-items"
          (click)="actionNavigate(navItem)"
          [ngClass]="{'menu-item-navigable':navItem.path,'children':navItem?.childItem?.length>0}">
          <div class="macro-item-title">
            <mat-icon matPrefix>{{navItem.icon}}</mat-icon>
            <span>{{navItem.title}}</span>
            <span class="counter">{{navItem.counter}}</span>
          </div>
          <ng-container  *ngFor="let item of navItem.childItem">
            <a *ngIf="item.path"
              class="macro-item-menu" mat-list-item
              mat-ripple routerLinkActive="selected"
              [title]="item.title"
              [routerLink]="[item.path]">
              <div class="nav-icon-container" *ngIf="item.icon">
                <mat-icon matPrefix>{{item.icon}}</mat-icon>
              </div>
              <span class="fly-out-top-item-name">{{item.title}}</span>
            </a>
            <a *ngIf="item.link"
              class="macro-item-menu" mat-list-item
              mat-ripple routerLinkActive="selected"
              [title]="item.title"
              [href]="item.link">
              <div class="nav-icon-container" *ngIf="item.icon">
                <mat-icon matPrefix>{{item.icon}}</mat-icon>
              </div>
              <span class="fly-out-top-item-name">{{item.title}}</span>
            </a>
          </ng-container>

        </div>
        <a *ngIf="!navItem.macroItem && !navItem.link"
          mat-list-item mat-ripple
          routerLinkActive="selected"
          [title]="navItem.title"
          [routerLink]="[navItem.path]">
          <div class="nav-icon-container" *ngIf="navItem.icon">
            <mat-icon matPrefix>{{navItem.icon}}</mat-icon>
          </div>
          <span class="fly-out-top-item-name">{{navItem.title}}</span>
        </a>
        <a *ngIf="!navItem.macroItem && navItem.link"
          target="_blank"
          mat-list-item mat-ripple
          routerLinkActive="selected"
          [title]="navItem.title"
          [href]="navItem.link">
          <div class="nav-icon-container" *ngIf="navItem.icon">
            <mat-icon matPrefix>{{navItem.icon}}</mat-icon>
          </div>
          <span class="fly-out-top-item-name">{{navItem.title}}</span>
        </a>
      </li>
      <li class="fly-out-top-item">
        <div class="macro-items">
          <a class="macro-item-title" style="color:#174787;" href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/borsaviaggi/un-consulente-a-tua-disposizione?text_color=000066&primary_color=ff9900'});document.querySelector('#chat-widget-container')['style'].zIndex = '9999';return false;">
            <div class="nav-icon-container">
              <mat-icon matPrefix>event</mat-icon>
            </div>
            <span class="fly-out-top-item-name">FISSA UN APPUNTAMENTO</span>
          </a>
        </div>
      </li>
      <li class="fly-out-top-item mt-50px">
        <div class="macro-items">
          <a class="macro-item-title"
             title="Dove Siamo"
             href="https://www.google.com/maps/place/Borsaviaggi.it/@41.833022,12.4889903,17z/data=!3m1!4b1!4m5!3m4!1s0x13258a9f52b192f9:0x64e1066a597af0d7!8m2!3d41.833022!4d12.491179"
             target="_blank" style="color:#174787;">
            <div class="nav-icon-container">
              <mat-icon matPrefix>location_on</mat-icon>
            </div>
            <span class="fly-out-top-item-name">VIA DEL SERAFICO, 185 - 00142 ROMA</span>
          </a>
        </div>
      </li>
      <li class="fly-out-top-item">
        <div class="macro-items">
          <a class="macro-item-title"
             title="Telefono"
             href="tel:+39065587667" style="color:#174787;">
            <div class="nav-icon-container">
              <mat-icon matPrefix>phone</mat-icon>
            </div>
            <span class="fly-out-top-item-name">06.55.87.667</span>
          </a>
        </div>
      </li>
      <li class="fly-out-top-item">
        <div class="macro-items">
          <a class="macro-item-title"
             title="Note Legali"
             (click)="openDialogNoteLegali()" style="color:#174787;">
            <div class="nav-icon-container">
              <mat-icon matPrefix>policy</mat-icon>
            </div>
            <span class="fly-out-top-item-name">NOTE LEGALI</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</ng-container>

