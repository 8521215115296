import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/common/auth.service";
import {LampadaDesideriService} from "../../services/gate/lampada-desideri.service";
import {LoaderService} from "../../components/loader/loader.service";
import {ModalService} from "../../services/common/modal.service";
import {HttpClient} from "@angular/common/http";
import {NazioniLampadaDesideriService} from "../../services/common/nazioniLampadaDesideri.service";

interface DepartureInfo {
  id: string,
  name: string,
  type: string
}

@Component({
  selector: 'borsaviaggi-lampada-desideri',
  templateUrl: './lampada-desideri.component.html',
  styleUrls: ['./lampada-desideri.component.scss']
})
export class LampadaDesideriComponent implements OnInit {
  public formGroup: FormGroup;
  public loading;
  public optionsForMSelect: DepartureInfo[]; //option objects for multi-select
  public selectedOptions: DepartureInfo[]; //list of selected departures
  public selectedOptionsView: any; //list of departure names to view in the select
  public minDate: Date;

  public listaDestinazioni = [];
  public listaLocalita = [];
  public listaPartenze = [];
  public budgetOptions = [
    {id: '1', value: 'Fino a 500 €'},
    {id: '2', value: 'Da 500 € a 900 €'},
    {id: '3', value: 'Da 900 € a 1300 €'},
    {id: '4', value: 'Da 1300 €'}
    /*{id: '1', value: 'Fino a 500 €'},
    {id: '2', value: 'Da 500 € a 700 €'},
    {id: '3', value: 'Da 700 € a 900 €'},
    {id: '4', value: 'Da 900 € a 1100 €'},
    {id: '5', value: 'Da 1100 € a 1300 €'},
    {id: '6', value: 'Da 1300 € a 1500 €'},
    {id: '7', value: 'Da 1500 €'}*/
  ];

  public frequenzeOptions = [
    {id: '3', value: 'Ogni 3 giorni'},
    {id: '1', value: 'Settimanalmente'},
    {id: '2', value: 'Ogni 2 settimane'},
    {id: '4', value: 'Ogni mese'}
  ];

  public userInfo;

  constructor(private authSrv: AuthService,
              private prevService: LampadaDesideriService,
              private loaderSrv: LoaderService,
              private modalSrv: ModalService,
              private http: HttpClient) {

    this.minDate = new Date();
  }


  ngOnInit() {
    //retrieve user info
    let user = this.authSrv.getUser();
    if(user && user.userInfo){
      this.userInfo = user.userInfo;
    }
    //create form and controls
    this.formGroup = new FormGroup({
      nome: new FormControl(this.userInfo?this.userInfo.nome:'', Validators.required),
      cognome: new FormControl(this.userInfo?this.userInfo.cognome:'', Validators.required),
      email: new FormControl(this.userInfo?this.userInfo.email:'', [Validators.email, Validators.required]),
      cellulare: new FormControl(this.userInfo?this.userInfo.cellulare:'',Validators.pattern('^((00|\\+)39[\\. ]??)??3\\d{2}[\\. ]??\\d{6,7}$')),
      destinazione: new FormControl(''),
      localita: new FormControl({value: '', disabled: true}),
      dataPartenzaDa: new FormControl('', Validators.required),
      dataPartenzaAl: new FormControl('', Validators.required),
      partenzaDa: new FormControl('', Validators.required),
      budget: new FormControl('1'),
      frequenza: new FormControl('3'),
      informativaPrivacy: new FormControl(false, Validators.requiredTrue)
    });

    this.getDestinazioni();
  }

  public onChangeDestinazione(event){
    this.getLocalita(event.value);
    this.formGroup.get('localita').enable();
    this.resetDeparturesSelectFields();
    this.getLuoghiPartenza(event.value);
  }

  public onChangeLocalita(event){
    this.resetDeparturesSelectFields();
    this.getLuoghiPartenza(event.value);
  }

  public onChangeBudget(event){
    //console.log(event);
  }

  public onChangeFrequenza(event){
    //console.log(event);
  }

  public setSelectTextbox() {
    this.selectedOptionsView = [];
    let nameList = [];
    this.selectedOptions.map(function (val) {
      nameList.push(val.name);
    });
    this.selectedOptionsView = nameList;
  }

  private resetDeparturesSelectFields() {
    this.optionsForMSelect = [];
    this.selectedOptions = [];
    this.selectedOptionsView = [];

    this.formGroup.get('partenzaDa').setValue('');
  }

  private buildMSelectOptions(data) {
    let optionsArray = [];
    data.forEach(elem => {
      let optionsForSelect = <DepartureInfo>{
        name: elem.nome,
        id: elem.idLuogoPartenza,
        type: this.getDepartureIcon(elem)
      };
      optionsArray.push(optionsForSelect);
    });

    this.optionsForMSelect = optionsArray;
  }

  private getDepartureIcon(obj){
    let icon_name;
    if(obj.aeroporto){
      icon_name = 'flight';
    }
    else if(obj.bus){
      icon_name = 'directions_bus';
    }
    else if(obj.porto){
      icon_name = 'directions_boat';
    }
    else if(obj.soloSoggiorno){
      icon_name = 'directions_car';
    }
    else{
      icon_name = '';
    }
    return icon_name;
  }

  private getDestinazioni(){
    this.listaDestinazioni = NazioniLampadaDesideriService.getNazioni();
  }

  private getLocalita(id){
    this.prevService.getLocalitaLampadaDesideri(id).then(
      (response) => {
        //console.log("Lista Località per destinazione " + id + ": " + JSON.stringify(response.body.data.listaLocalitaLampadaDesideri));
        this.listaLocalita = response.body.data.listaLocalitaLampadaDesideri;
      },
      (error) => {
        console.log("Errore caricamento Località", error);
      }
    );
  }

  private getLuoghiPartenza(id){
    this.loaderSrv.show();
    this.prevService.getLuoghiPartenzaLampadaDesideri(id).then(
      (response) => {
        if (response && response.body.status === 0) {
          if (response.body.data.listaLPLD) { //available departures
            //console.log("Lista partenze per località " + id + ": " + JSON.stringify(response.body.data.listaLPLD));
            let luoghiPartenzaDisponibili = response.body.data.listaLPLD;
            this.buildMSelectOptions(luoghiPartenzaDisponibili);
          }
          else {
            console.log("Nessuna partenza disponibile");
          }
        }
        else {
          console.log("Response status " + response.body.status + ": " + response.body.message);
        }
      },
      (error) => {
        console.log("Errore chiamata a ws getLuoghiPartenzaLampadaDesideri: ", error);
      }
    ).finally(()=>{
      this.loaderSrv.hide();
    });
  }

  public creaAvviso() {
    let idPartenze = [];
    this.selectedOptions.forEach(elem => {
      idPartenze.push(elem.id);
    });
    let dataDa = new Date(this.formGroup.get('dataPartenzaDa').value);
    let serializedDateDa = dataDa.toISOString();
    let dataAl = new Date(this.formGroup.get('dataPartenzaAl').value);
    let serializedDateAl = dataAl.toISOString();

    let request = {
      "nome": this.formGroup.get('nome').value,
      "cognome": this.formGroup.get('cognome').value,
      "email": this.formGroup.get('email').value,
      "cellulare": this.formGroup.get('cellulare').value,
      "idDestinazione": this.formGroup.get('destinazione').value,
      "idLocalita": this.formGroup.get('localita').value,
      "listaIdLuoghiPartenza": idPartenze,
      "dataPartenzaDa": serializedDateDa,
      "dataPartenzaAl": serializedDateAl,
      "idBudget": this.formGroup.get('budget').value,
      "idFrequenza": this.formGroup.get('frequenza').value
      };

      //console.log("Request: " + JSON.stringify(request));
      this.loaderSrv.show();
      this.prevService.creaAvvisoLampadaDesideri(request).then(
        (response) => {
          if (response && response.body.status === 0) {
            this.modalSrv.openFeedbackModal({success: "Iscrizione avvenuta con successo."});
          } else {
            this.modalSrv.openErrorDialog(response.body.message);
          }
        },
        (error) => {
          console.log("Errore chiamata a ws creaAvvisoLampadaDesideri", error);
        }
      ).finally(()=>{
        this.loaderSrv.hide();
        this.formGroup.reset();
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      });
  }

}
