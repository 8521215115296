export class NazioniLampadaDesideriService {

  public static getNazioni() {

    return [
      {
        idDestinazione: 9237,
        nomeDestinazione: "ANTIGUA"
      },
      {
        idDestinazione: 9123,
        nomeDestinazione: "ARGENTINA"
      },
      {
        idDestinazione: 9594,
        nomeDestinazione: "AUSTRALIA"
      },
      {
        idDestinazione: 8800,
        nomeDestinazione: "AUSTRIA"
      },
      {
        idDestinazione: 9239,
        nomeDestinazione: "BAHAMAS"
      },
      {
        idDestinazione: 9005,
        nomeDestinazione: "BALEARI"
      },
      {
        idDestinazione: 9154,
        nomeDestinazione: "BELGIO"
      },
      {
        idDestinazione: 9941,
        nomeDestinazione: "BIRMANIA"
      },
      {
        idDestinazione: 8648,
        nomeDestinazione: "BRASILE"
      },
      {
        idDestinazione: 8727,
        nomeDestinazione: "CAMPANIA"
      },
      {
        idDestinazione: 9006,
        nomeDestinazione: "CANARIE"
      },
      {
        idDestinazione: 278,
        nomeDestinazione: "CAPO VERDE"
      },
      {
        idDestinazione: 9324,
        nomeDestinazione: "CILE"
      },
      {
        idDestinazione: 378,
        nomeDestinazione: "CIPRO"
      },
      {
        idDestinazione: 9777,
        nomeDestinazione: "COSTA DEL SOL"
      },
      {
        idDestinazione: 8988,
        nomeDestinazione: "CROAZIA"
      },
      {
        idDestinazione: 351,
        nomeDestinazione: "CUBA"
      },
      {
        idDestinazione: 9753,
        nomeDestinazione: "CURACAO"
      },
      {
        idDestinazione: 9128,
        nomeDestinazione: "DANIMARCA"
      },
      {
        idDestinazione: 9693,
        nomeDestinazione: "ECUADOR"
      },
      {
        idDestinazione: 281,
        nomeDestinazione: "EGITTO"
      },
      {
        idDestinazione: 321,
        nomeDestinazione: "EMIRATI ARABI"
      },
      {
        idDestinazione: 9194,
        nomeDestinazione: "FILIPPINE"
      },
      {
        idDestinazione: 9765,
        nomeDestinazione: "FINLANDIA"
      },
      {
        idDestinazione: 8824,
        nomeDestinazione: "FRANCIA"
      },
      {
        idDestinazione: 8827,
        nomeDestinazione: "GERMANIA"
      },
      {
        idDestinazione: 9115,
        nomeDestinazione: "GIAPPONE"
      },
      {
        idDestinazione: 8856,
        nomeDestinazione: "GIORDANIA"
      },
      {
        idDestinazione: 380,
        nomeDestinazione: "GRECIA"
      },
      {
        idDestinazione: 9107,
        nomeDestinazione: "INDIA"
      },
      {
        idDestinazione: 9069,
        nomeDestinazione: "INDONESIA"
      },
      {
        idDestinazione: 8802,
        nomeDestinazione: "INGHILTERRA"
      },
      {
        idDestinazione: 8832,
        nomeDestinazione: "IRLANDA"
      },
      {
        idDestinazione: 8804,
        nomeDestinazione: "ISLANDA"
      },
      {
        idDestinazione: 9166,
        nomeDestinazione: "ISRAELE"
      },
      {
        idDestinazione: 393,
        nomeDestinazione: "ITALIA"
      },
      {
        idDestinazione: 359,
        nomeDestinazione: "JAMAICA"
      },
      {
        idDestinazione: 296,
        nomeDestinazione: "KENYA"
      },
      {
        idDestinazione: 9878,
        nomeDestinazione: "KOREA"
      },
      {
        idDestinazione: 302,
        nomeDestinazione: "MADAGASCAR"
      },
      {
        idDestinazione: 9053,
        nomeDestinazione: "MALAYSIA"
      },
      {
        idDestinazione: 324,
        nomeDestinazione: "MALDIVE"
      },
      {
        idDestinazione: 8974,
        nomeDestinazione: "MALTA"
      },
      {
        idDestinazione: 304,
        nomeDestinazione: "MAROCCO"
      },
      {
        idDestinazione: 306,
        nomeDestinazione: "MAURITIUS"
      },
      {
        idDestinazione: 363,
        nomeDestinazione: "MESSICO"
      },
      {
        idDestinazione: 9937,
        nomeDestinazione: "NAMIBIA"
      },
      {
        idDestinazione: 9943,
        nomeDestinazione: "NEPAL"
      },
      {
        idDestinazione: 9420,
        nomeDestinazione: "NORVEGIA"
      },
      {
        idDestinazione: 8807,
        nomeDestinazione: "OLANDA"
      },
      {
        idDestinazione: 9161,
        nomeDestinazione: "OMAN"
      },
      {
        idDestinazione: 9216,
        nomeDestinazione: "peru'"
      },
      {
        idDestinazione: 9159,
        nomeDestinazione: "POLINESIA"
      },
      {
        idDestinazione: 8641,
        nomeDestinazione: "POLONIA"
      },
      {
        idDestinazione: 8809,
        nomeDestinazione: "PORTOGALLO"
      },
      {
        idDestinazione: 9602,
        nomeDestinazione: "QATAR"
      },
      {
        idDestinazione: 371,
        nomeDestinazione: "REP DOMINICANA"
      },
      {
        idDestinazione: 8812,
        nomeDestinazione: "REP.CECA"
      },
      {
        idDestinazione: 8834,
        nomeDestinazione: "ROMANIA"
      },
      {
        idDestinazione: 9146,
        nomeDestinazione: "RUSSIA"
      },
      {
        idDestinazione: 9090,
        nomeDestinazione: "SCOZIA"
      },
      {
        idDestinazione: 310,
        nomeDestinazione: "SEYCHELLES"
      },
      {
        idDestinazione: 8773,
        nomeDestinazione: "SICILIA"
      },
      {
        idDestinazione: 9493,
        nomeDestinazione: "SINGAPORE"
      },
      {
        idDestinazione: 9788,
        nomeDestinazione: "SLOVACCHIA"
      },
      {
        idDestinazione: 9461,
        nomeDestinazione: "SLOVENIA"
      },
      {
        idDestinazione: 404,
        nomeDestinazione: "SPAGNA"
      },
      {
        idDestinazione: 9038,
        nomeDestinazione: "SPAGNA E FRANCIA"
      },
      {
        idDestinazione: 8902,
        nomeDestinazione: "SRI LANKA"
      },
      {
        idDestinazione: 8818,
        nomeDestinazione: "STATI UNITI D'AMERICA"
      },
      {
        idDestinazione: 9099,
        nomeDestinazione: "SUD AFRICA"
      },
      {
        idDestinazione: 312,
        nomeDestinazione: "TANZANIA"
      },
      {
        idDestinazione: 332,
        nomeDestinazione: "THAILANDIA"
      },
      {
        idDestinazione: 8792,
        nomeDestinazione: "TOSCANA"
      },
      {
        idDestinazione: 8797,
        nomeDestinazione: "TRENTINO"
      },
      {
        idDestinazione: 314,
        nomeDestinazione: "TUNISIA"
      },
      {
        idDestinazione: 412,
        nomeDestinazione: "TURCHIA"
      },
      {
        idDestinazione: 8816,
        nomeDestinazione: "UNGHERIA"
      }
    ];
  }
}
