<div class="container border-r-2px padding-20px mt-20px mb-20px text-align-justify background-white overflow-hidden">
  <h1 class="color-text-blue-borsaviaggi" *ngIf="dataHotel.destinazione">HOTEL - {{dataHotel.nazione}}
    - {{dataHotel.destinazione}}</h1>
  <div *ngIf="dataHotel.listaStruttureLocalita" class="row">
    <div class="col-sm">
      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let data of dataHotel.listaStruttureLocalita; index as i"
                       [hidden]="i>=twoColumnsThreshold">
          <a class="link-struttura" [routerLink]="'/'+ makeUrlSeoHrefForStrutture(data)">
            <div class="text-align-left">{{data.nomeStruttura | titlecase}}</div>
          </a>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="col-sm">
      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let data of dataHotel.listaStruttureLocalita; index as i"
                       [hidden]="i<twoColumnsThreshold">
          <a class="link-struttura" [routerLink]="'/'+ makeUrlSeoHrefForStrutture(data)">
            <div class="text-align-left">{{data.nomeStruttura | titlecase}}</div>
          </a></mat-list-item>
      </mat-list>
    </div>
  </div>
  <div *ngIf="!dataHotel.destinazione">
    <h1 class="color-text-blue-borsaviaggi" *ngIf="route.snapshot.url[0].path == 'offerte-hotel-italia'">
      Offerte Hotel Italia</h1>
    <h1 class="color-text-blue-borsaviaggi" *ngIf="route.snapshot.url[0].path == 'offerte-hotel-mondo'">
      Offerte Hotel Mondo</h1>
    <p class="text-align-center">Nussuna destinazione disponibile.</p>
  </div>
  <p class="text-align-center" *ngIf="dataHotel.destinazione && !dataHotel.listaStruttureLocalita.length">
    Siamo spiacenti. Al momento non esistono offerte attive per il periodo selezionato.
  </p>
</div>

