<div class="modal-wrapper">
  <div mat-dialog-close class="float-right">
    <!-- i class="material-icons">close</i -->
  </div>
  <mat-dialog-content align="center">
    <div class="w-100 text-align-center">
      <img class="w-logo" src="../../../../assets/img/logo-borsaviaggi.png" alt="logo Borsaviaggi">
    </div>
    <div class="w-100 mt-3 p-2">
      <span [innerHTML]="testoComunicazione"></span>
      <span [innerHTML]="testoSaluti"></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Chiudi</button>
  </mat-dialog-actions>
</div>
