import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LavoraConNoiService} from "../../services/gate/lavora-con-noi.service";
import {ModalService} from "../../services/common/modal.service";

@Component({
  selector: 'borsaviaggi-lavora-con-noi',
  templateUrl: './lavora-con-noi.component.html',
  styleUrls: ['./lavora-con-noi.component.scss']
})
export class LavoraConNoiComponent implements OnInit {

  public formGroup:FormGroup;
  public regioni:[];
  public province:[];
  public elencoDiplomi:string[] = ["ist. tecn. del turismo", "liceo linguistico", "ist. tecn. commerciale", "liceo scentifico", "liceo classico", "ragioneria", "geometra"];
  public elencoLauree:string[] = ["lingue", "economia e comm.", "giurisprudenza", "scienza della comunicazione"];
  public lingue:string[] = ["Inglese", "Francese", "Tedesco", "Portoghese", "Spagnolo"];
  public profiloInteressato:string[] = ["Agente di lavoro - Area booking", "Agente di lavoro - Area booking - Stage","Area prodotto","Area prodotto - Stage"];
  public verificaLaurea:boolean = false;
  public verificaDiploma:boolean = false;
  public loading;

  constructor(private lavoraConNoiService: LavoraConNoiService, private dialog:ModalService) {
  }

  ngOnInit() {
    this.province = [];
    this.regioni = [];
    this.formGroup = new FormGroup({
        nome: new FormControl('', Validators.required),
        cognome: new FormControl('', Validators.required),
        dataNascita: new FormControl('', Validators.required),
        indirizzo: new FormControl('', Validators.required),
        nazionalita: new FormControl('', Validators.required),
        telefono: new FormControl('',
          [Validators.pattern("^[0-8\\-\\+]{8,15}$"), Validators.required]),
        cellulare: new FormControl('',[
          Validators.required,
          Validators.pattern("^(\\+\\d{1,3}[- ]?)?\\d{10}$"),
        ]),
        email: new FormControl('', [Validators.email, Validators.required]),
        provincia: new FormControl('', Validators.required),
        regione: new FormControl('', Validators.required),
        diploma: new FormControl('', Validators.required),
        laurea: new FormControl(''),
        specializzazione: new FormControl(''),
        occupazioniPrecedenti: new FormControl(''),
        lingue: new FormControl(''),
        altreLingue: new FormControl(''),
        profiloInteressato: new FormControl(''),
        orari: new FormControl('', Validators.required),
        attitudini: new FormControl(''),
        privacy: new FormControl(false, Validators.requiredTrue),

    })
    this.getRegioni();
  }


  updateVerificaLaurea(){
    this.formGroup.get('laurea').setValue('');
    this.verificaLaurea = this.verificaLaurea ? false : true;
  }

  updateVerificaDiploma()
  {
    this.formGroup.clearValidators();
    this.formGroup.get('diploma').setValue('');
    this.verificaDiploma = this.verificaDiploma ? false : true;
  }

  getRegioni(){
    this.lavoraConNoiService.getRegioni().then(
      (response)=>{
        this.regioni = response.body.data;
      },
      (error)=>{
        console.error(error);
      }
    );
  }

  getProvince(idRegione:number){
    let obj = {
      "idRegione" : idRegione
    }
    this.lavoraConNoiService.getProvinceByRegione(obj).then(
      (response) => {
        this.province = response.body.data;
      },
      (error) => {
        console.error(error);
      }
    )
  }

  send(formDataValid){

      if(formDataValid){
        this.loading = true;
        let arrayAltreLingue = [];
        let arrayLingue = [];
        let altreLingue = this.formGroup.get("altreLingue").value ?
          this.formGroup.get("altreLingue").value.toString().trim() : "";
        let lingue = this.formGroup.get("lingue").value;

        if (altreLingue != "") {
          if (altreLingue.includes(",")) {
            arrayAltreLingue = altreLingue.split(",");
          } else {
            arrayLingue.push(altreLingue);
          }
        }

        if (lingue != "") {
          arrayLingue = lingue;
        }

        let obj = {
          "nome": this.formGroup.get("nome").value,
          "cognome": this.formGroup.get("cognome").value,
          "dataNascita": this.formGroup.get("dataNascita").value,
          "indirizzo": this.formGroup.get("indirizzo").value,
          "nazionalita": this.formGroup.get("nazionalita").value,
          "telefono": this.formGroup.get("telefono").value,
          "cellulare": this.formGroup.get("cellulare").value,
          "email": this.formGroup.get("email").value,
          "provincia": this.formGroup.get("provincia").value,
          "regione": this.formGroup.get("regione").value,
          "diploma": this.formGroup.get("diploma").value,
          "laurea": this.formGroup.get("laurea").value,
          "specializzazione": this.formGroup.get("specializzazione").value,
          "occupazioniPrecedenti": this.formGroup.get("occupazioniPrecedenti").value,
          "lingue": arrayLingue,
          "altreLingue": arrayAltreLingue,
          "profiloInteressato": this.formGroup.get("profiloInteressato").value,
          "orari": this.formGroup.get("profiloInteressato").value == "SI" ? true : false,
          "attitudini": this.formGroup.get("attitudini").value,
          "privacy": this.formGroup.get("privacy").value,
          "isStage": false
        };
        let esito;
        this.lavoraConNoiService.sendCurriculum(obj).then(
          (response) => {

            if(response.body.status === 0){
              esito = {
                success : response.body.message,
                error : false,
                button_text : "chiudi",
                button_icon : "clear"
              };
            }
            else{
              esito = {
                success : false,
                error : "Errore durante l'invio del curriculum",
                button_text : "chiudi",
                button_icon : "clear"
              };
            }
            this.dialog.openFeedbackModal(esito);

            /*for(var name in this.formGroup.controls) {
              this.formGroup.controls[name].reset();
            }*/
          },
          (error) => {
            esito = {
              success : false,
              error : "Errore di comunicazione con il Server verificare la conettività",
              button_text : "chiudi",
              button_icon : "clear"
            };
            this.dialog.openFeedbackModal(esito);
          }
        ).finally(
          () => {
            this.loading = false;
            this.formGroup.reset();
            this.formGroup.markAsPristine();
            this.formGroup.markAsUntouched();
          }
        );
      }


  }

}
