<mat-card class="border-grey-1px">
  <mat-card-content>
    <div class="row">
      <div class="col-12">
        <label class="subtitle col-12 p-0">Le tue prenotazioni</label>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="border-grey-1px mt-5px">
  <mat-card-content>
    <result-card *ngFor="let prenotazine of listaPrenotazioniData"
                 [config]="resultCardConfig" [data]="prenotazine" [cardReduce]="false"></result-card>
  </mat-card-content>
  <div class="col-12">
    <label class="col-12 p-0" *ngIf="listaPrenotazioniData && listaPrenotazioniData.length===0">La tua lista
      prenotazioni è vuota.</label>
  </div>
</mat-card>
