import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-informativa-dati-personali',
  templateUrl: './informativa-dati-personali.component.html',
  styleUrls: ['./informativa-dati-personali.component.scss']
})
export class InformativaDatiPersonaliComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
