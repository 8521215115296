import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {VerificaTransazioneService} from "../verifica-transazione.service";

@Injectable({
  providedIn: 'root'
})
export class VerificaTransazioneResolveService {
  constructor(private verificaSrv: VerificaTransazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let req = routeSnap.queryParams;
    let reqObj = {
      "paramG": req && req.g || null
      //"paramA": req && req.a || null,
      //"paramB": req && req.b || null
    };
    return this.verificaSrv.getVerifica(reqObj)
      .catch(err => {
        return err;
      });
  }

}
