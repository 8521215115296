<ng-container>
  <div class="mt-1-5-rem">
    <mat-label>Scegli per data di partenza: </mat-label>
  </div>
  <!-- date and transport filter -->
  <div class="mt-1-5-rem" >
    <form [formGroup]="filterForm" class="col-12 position-relative">
      <mat-form-field appearance="outline" class="form-filter col-3 pl-0 pr-0">
        <mat-icon matPrefix>today</mat-icon>
        <mat-label>Quando vuoi partire</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="data" [readonly]="true" (dateChange)="getAirports()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-icon class="sentiment-icon" style="color: #1A2B48" >{{sentiment}}</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-filter col-3 pl-0 pr-0">
        <mat-select formControlName="tolerance" (selectionChange)="getAirports()">
          <mat-option *ngFor="let range of dateRange" [value]="range.value">
            {{range.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field appearance="outline" class="form-filter col-3 pl-0 pr-0">
        <mat-label>Soluzioni Disponibili</mat-label>
        <mat-select multiple formControlName="transportMode" [(value)]="selectedOptions" (selectionChange)="setSelectTextbox()">
          <mat-select-trigger>{{selectedOptionsView}}</mat-select-trigger>
          <mat-option *ngFor="let option of optionsForMSelect" [value]="option" >
            <mat-icon class="select-icon">{{option.type}}</mat-icon>
            <span>{{option.name}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button class="col-2 filter-btn background-blue-borsaviaggi color-white" type="submit"
              [disabled]="isFilterHidden()" (click)="filter()">
        Filtra
      </button>
    </form>

  </div>
</ng-container>
