import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {User} from "../../model/user.dto";


@Injectable()
export class AuthService {
  private user: User;
  private isLoginSubject: Subject<boolean>;
  private isLoginObservable: Observable<boolean>;

  constructor() {
    this.user = JSON.parse(localStorage.getItem('userBV'));
    this.isLoginSubject = new Subject<boolean>();
    this.isLoginObservable = this.isLoginSubject.asObservable();
  }

  public setUser(paramUser: User) {
    if (paramUser) {
      this.user = new User();
      this.user.assignJsonToInstance(paramUser);
      this.user.authorizationHeader = paramUser.token;
      localStorage.setItem('userBV', JSON.stringify(this.user));
      this.setUserInfo(paramUser);
      this.isLoginSubject.next(true);
    }
  }

  public setUserInfo(userInfo) {
    if (userInfo) {
      this.user.userInfo = userInfo;
      localStorage.setItem('userBV', JSON.stringify(this.user));
    }
  }

  public setUserAuthToken(paramToken: string) {
    if (paramToken && this.user) {
      this.user.authorizationHeader = paramToken;
      localStorage.setItem('userBV', JSON.stringify(this.user));
    }
  }

  public userObservable(): Observable<boolean> {
    return this.isLoginObservable;
  }


  public setUserNavigationHistory(paramHistory) {
    if (this.user) {
      this.user.navigationHistory = paramHistory;
      localStorage.setItem('userBV', JSON.stringify(this.user));
    }
  }


  public getUserNavigationHistory() {
    return this.user && this.user.navigationHistory ? this.user.navigationHistory : "";
  }


  public getUser(): User {
    return this.user;
  }

  public getUserToken(): string {
    return this.user && this.user.authorizationHeader ? this.user.authorizationHeader : null;
  }


  public isUserLogedin(): boolean {
    return !!this.user;
  }


  public clearUser() {
    if (this.user) {
      this.user = null;
      localStorage.removeItem('userBV');
      this.isLoginSubject.next(false);
    }
  }

}
