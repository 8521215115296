import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from '../page/home-page/home-page.component';
import {DettaglioVacanzaGPage} from '../page/vacanza/dettaglio-vacanza-g-page/dettaglio-vacanza-g-page.component';
import {SearchResultsComponent} from '../page/search-results/search-results.component';
import {SearchResultsCrociereComponent} from '../page/search-results-crociere/search-results-crociere.component';
import {ComePrenotareComponent} from '../page/come-prenotare/come-prenotare.component';
import {QuestionarioPostVenditaComponent} from '../page/questionario-post-vendita/questionario-post-vendita.component';
import {TerminiCondizioniComponent} from '../page/termini-condizioni/termini-condizioni.component';
import {SEO_OFFERTE, SEO_PAGE} from '../constants/seo.const';
import {ScriviDirettoreComponent} from '../page/scrivi-direttore/scrivi-direttore.component';
import {InformativaPrivacyComponent} from '../page/informativa-privacy/informativa-privacy.component';
import {SuggerimentiComponent} from '../page/suggerimenti/suggerimenti.component';
import {LavoraConNoiComponent} from '../page/lavora-con-noi/lavora-con-noi.component';
import {InformativaDatiPersonaliComponent} from '../page/informativa-dati-personali/informativa-dati-personali.component';
import {FormazioneComponent} from '../page/formazione/formazione.component';
import {RicercaVacanzeResolveService} from '../services/gate/resolve/ricerca-vacanze.resolve.service';
import {MacroDestinazionePageComponent} from '../page/macro-destinazione-page/macro-destinazione-page.component';
import {MacroCategoriaResolveService} from '../services/gate/resolve/macro-categoria.resolve.service';
import {AreaPersonaleComponent} from '../page/area-personale/area-personale.component';
import {DatiPersonaliResolve} from '../services/gate/resolve/dati-personali.resolve';
import {DatiPersonaliComponent} from '../page/area-personale/sezioni-area-personale/dati-personali/dati-personali.component';
import {ListaPrenotazioniComponent} from '../page/area-personale/sezioni-area-personale/lista-prenotazioni/lista-prenotazioni.component';
import {ListaDesideriComponent} from '../page/area-personale/sezioni-area-personale/lista-desideri/lista-desideri.component';
import {DettaglioGResolveService} from '../services/gate/resolve/dettaglio-g.resolve.service';
import {DettaglioPartnersComponent} from '../page/dettaglio-partners/dettaglio-partners.component';
import {DettaglioPartnerResolveService} from '../services/gate/resolve/dettaglio-partner.resolve.service';
import {DettaglioPResolveService} from '../services/gate/resolve/dettaglio-p.resolve.service';
import {DettaglioVacanzaPPage} from '../page/vacanza/dettaglio-vacanza-p-page/dettaglio-vacanza-p-page.component';
import {DettaglioPDescrizioneResolveService} from '../services/gate/resolve/dettaglio-p-descrizione.resolve.service';
import {DettaglioStrutturaPartnerResolveService} from '../services/gate/resolve/dettaglio-struttura-partner.resolve.service';
import {DettaglioStrutturaPage} from '../page/dettaglio-partners/dettaglio-struttura-partners/dettaglio-struttura-page.component';
import {CheckoutPageComponent} from '../page/checkout/checkout-page.component';
import {DettaglioDestinazioneComponent} from '../page/dettaglio-partners/dettaglio-destinazione/dettaglio-destinazione.component';
import {DettaglioDestinazioneResolveService} from '../services/gate/resolve/dettaglio-destinazione.resolve.service';
import {ContainerDettaglioDestinazioniResolveService} from '../services/gate/resolve/container-dettaglio-destinazioni.resolve.service';
import {DettaglioDestinazioneNostraSelezioneResolveService} from '../services/gate/resolve/dettaglio-destinazione-nostra-selezione.resolve.service';
import {CheckoutResolveService} from '../services/gate/resolve/checkout.resolve.service';
import {PrimePageComponent} from '../page/prime-page/prime-page.component';
import {PrimeResolveService} from '../services/gate/resolve/prime.resolve.service';
import {QuiDietroComponent} from '../page/qui-dietro/qui-dietro.component';
import {RicercaCrociereResolveService} from '../services/gate/resolve/ricerca-crociere.resolve.service';
import {DettaglioCrocieraPage} from '../page/crociera/dettaglio-crociera-page/dettaglio-crociera-page.component';
import {LampadaDesideriComponent} from '../page/lampada-desideri/lampada-desideri.component';
import {ContainerDestinazioniComponent} from '../page/container-destinazioni/container-destinazioni.component';
import {DettaglioDestinazioneNostraSelezioneComponent} from '../page/macro-destinazione-page/dettaglio-destinazione-nostra-selezione/dettaglio-destinazione-nostra-selezione.component';
import {DettaglioCrocieraResolveService} from '../services/gate/resolve/dettaglio-crociera.resolve.service';
import {CarteRegaloComponent} from '../page/carte-regalo/carte-regalo.component';
import {DettaglioCartaRegaloComponent} from '../page/dettaglio-carta-regalo/dettaglio-carta-regalo.component';
import {CartaRegaloResolveService} from '../services/gate/resolve/carta-regalo.resolve.service';
import {DettaglioDestinazioniComponent} from '../page/container-destinazioni/dettaglio-destinazioni/dettaglio-destinazioni.component';
import {VerificaTransazioneComponent} from '../page/verifica-transazione/verifica-transazione.component';
import {VerificaTransazioneResolveService} from '../services/gate/resolve/verifica-transazione.resolve.service';
import {VetrinaPrimeComponent} from '../page/vetrina-prime/vetrina-prime.component';
import {VetrinaPrimeResolveService} from '../services/gate/resolve/vetrina-prime.resolve.service';
import {DettaglioZResolveService} from '../services/gate/resolve/dettaglio-z.resolve.service';
import {DestinazioniMondoResolveService} from '../services/gate/resolve/destinazioni-mondo.resolve.service';
import {DestinazioniItaliaResolveService} from '../services/gate/resolve/destinazioni-italia.resolve.service';
import {HotelMondoResolveService} from '../services/gate/resolve/hotel-mondo.resolve.service';
import {HotelItaliaResolveService} from '../services/gate/resolve/hotel-italia.resolve.service';
import {CheckoutPageCrociereComponent} from '../page/crociera/checkout-crociera/checkout-page-crociere.component';
import {CheckoutCrociereResolveService} from '../services/gate/resolve/checkout-crociere.resolve.service';
import {DestinazioneTopResolveService} from '../services/gate/resolve/destinazione-top.resolve.service';
import {ListaPrenotazioniResolveService} from '../services/gate/resolve/lista-prenotazioni.resolve.service';
import {DettaglioHotelComponent} from '../page/dettaglio-hotel/dettaglio-hotel.component';
import {PrimePagamentoComponent} from '../page/prime-pagamento/prime-pagamento.component';
import {DettaglioHotelItaliaResolveService} from '../services/gate/resolve/dettaglio-hotel-italia.resolve.service';
import {DettaglioHotelMondoResolveService} from '../services/gate/resolve/dettaglio-hotel-mondo.resolve.service';
import {PageNotFoundComponent} from '../page/page-not-found/page-not-found.component';
import {PrivacyPageComponent} from '../page/privacy-page/privacy-page.component';
import {FuoriTuttoComponent} from '../page/fuori-tutto/fuori-tutto.component';
import {RecensioniPageComponent} from '../page/recensioni-page/recensioni-page.component';
import {PrezzoPiuBassoComponent} from '../page/prezzo-piu-basso/prezzo-piu-basso.component';
import {NewsletterComponent} from '../page/area-personale/sezioni-area-personale/newsletter/newsletter.component';
import {CancellazioneGratuitaComponent} from '../page/cancellazione-gratuita/cancellazione-gratuita.component';
import {ScaricabuonoComponent} from '../page/scaricabuono/scaricabuono.component';
import {AttivaProfiloComponent} from '../page/attiva-profilo/attiva-profilo.component';
import {RassegnaStampaComponent} from '../page/rassegna-stampa/rassegna-stampa.component';
import {LandingComponent} from '../page/landing/landing.component';
import {LandingResolveService} from '../services/gate/resolve/landing.resolve.service';
import {TravelExpertComponent} from '../page/travel-expert/travel-expert.component';
import {ScalapayComponent} from '../page/scalapay/scalapay.component';

const routes: Routes = [
  {path: '', redirectTo: '/', pathMatch: 'full'},
  {
    path: '', component: HomePageComponent,
  },
  {
    path: 'scalapay', component : ScalapayComponent
  },
  {
    path: SEO_PAGE.HOME_CAMPAGNA + '/:nomeCampagna', component: HomePageComponent,
  },

  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:nazione/:destinazione/:struttura/g/:idG',
    resolve: {
      datG: DettaglioGResolveService,
      // pListWithUrls: PListWithUrlsResolveService,
    },
    pathMatch: 'full',
    component: DettaglioVacanzaGPage
  },
  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:nazione/:destinazione/:struttura/g/:idG/:idLuogoPartenza',
    resolve: {
      datG: DettaglioGResolveService,
      // pListWithUrls: PListWithUrlsResolveService,
    },
    pathMatch: 'full',
    component: DettaglioVacanzaGPage
  },
  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:destinazione/:struttura/g/:idG/:idLuogoPartenza',
    resolve: {
      datG: DettaglioGResolveService,
    },
    pathMatch: 'full',
    component: DettaglioVacanzaGPage
  },
  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:destinazione/g/:idG/:idLuogoPartenza',
    resolve: {
      datG: DettaglioGResolveService,
    },
    pathMatch: 'full',
    component: DettaglioVacanzaGPage
  },
  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:nazione/:destinazione/:struttura/:luogoPartenza/:dataPartenza/p/:idP',
    resolve: {
      dataP: DettaglioPResolveService,
      dataPDesc: DettaglioPDescrizioneResolveService,
    },
    pathMatch: 'full',
    component: DettaglioVacanzaPPage
  },
  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:nazione/:destinazione/:struttura/:luogoPartenza/:dataPartenza/p/:idP/preventivo/:idPreventivo/checkout',
    resolve: {
      dataP: DettaglioPResolveService,
      dataPDesc: DettaglioPDescrizioneResolveService,
      dataCheckout: CheckoutResolveService,
      datiUtente: DatiPersonaliResolve,
    },
    component: CheckoutPageComponent
  },
  {
    path: SEO_OFFERTE.RISULTATI_VACANZA_KEYWORD,
    component: SearchResultsComponent,
    resolve: {
      listRisultati: RicercaVacanzeResolveService
    }
  },
  {
    path: SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + '/:nazione/:destinazione/:struttura/p/:idP',
    resolve: {
      dataP: DettaglioPResolveService
    },
    pathMatch: 'full',
    component: DettaglioVacanzaPPage
  },

  // CROCIERE
  {
    path: SEO_OFFERTE.RISULTATI_CROCIERE_KEYWORD,
    component: SearchResultsCrociereComponent,
    resolve: {
      listRisultati: RicercaCrociereResolveService
    }
  },
  {
    path: SEO_OFFERTE.CROCIERA_CATEGORIA_KEYWORD + '/:compagnia/:titolo/:porto/:dataPartenza/c/:idPartenzaCrociera',
    pathMatch: 'full',
    component: DettaglioCrocieraPage,
    resolve: {
      listRisultati: DettaglioCrocieraResolveService
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: SEO_OFFERTE.CHECKOUT_CROCIERE_KEYWORD + '/:idPreventivoCrociera',
    pathMatch: 'full',
    component: CheckoutPageCrociereComponent,
    resolve: {
      listRisultati: CheckoutCrociereResolveService
    },
    runGuardsAndResolvers: 'always'
  },

  {path: 'come-prenotare', component: ComePrenotareComponent},
  {path: 'questionario-post-vendita', component: QuestionarioPostVenditaComponent},
  {path: 'condizioni-generali-contratto', component: TerminiCondizioniComponent},
  {path: 'termini-e-condizioni', component: TerminiCondizioniComponent},
  {path: 'scrivi-al-direttore', component: ScriviDirettoreComponent},
  {path: 'informativa-privacy', component: InformativaPrivacyComponent},
  {path: 'lampada-desideri', component: LampadaDesideriComponent},
  {
    path: 'viaggi-mondo',
    component: ContainerDestinazioniComponent,
    resolve: {
      dataResolve: DestinazioniMondoResolveService
    }
  },
  {
    path: 'viaggi-italia',
    component: ContainerDestinazioniComponent,
    resolve: {
      dataResolve: DestinazioniItaliaResolveService
    }
  }, {
    path: 'hotel-mondo',
    component: ContainerDestinazioniComponent,
    resolve: {
      dataResolve: HotelMondoResolveService
    }
  }, {
    path: 'hotel-italia',
    component: ContainerDestinazioniComponent,
    resolve: {
      dataResolve: HotelItaliaResolveService
    }
  }, {
    path: 'super-travel-expert',
    component: TravelExpertComponent,
    data: {
      seo_title: 'Super Travel Expert | Borsaviaggi.it',
      seo_description: 'Costruiamo insieme il viaggio dei tuoi sogni!'
    },
  }, {
    path: 'suggerimenti',
    component: SuggerimentiComponent,
    data: {
      seo_title: 'Suggerimenti | Borsaviaggi.it',
      seo_description: 'Dai un voto a Borsaviaggi.it e aiutaci a migliorare il servizio!'
    },
  },
  {path: 'lavora-con-noi', component: LavoraConNoiComponent},
  {path: 'informativa-dati-personali', component: InformativaDatiPersonaliComponent},
  {path: 'formazione', component: FormazioneComponent},

  {
    path: SEO_PAGE.DESTINAZIONE_TOP_KEYWORD + '/:idSelezione/:titolo-macro',
    pathMatch: 'full',
    component: MacroDestinazionePageComponent,
    resolve: {
      dataMacroCategoria: DestinazioneTopResolveService
    }
  },
  {
    path: SEO_PAGE.DESTINAZIONE_TOP_KEYWORD + '/:idSelezione/:nomeDestinazione/:idDestinazione/:infoN/dettaglio',
    pathMatch: 'full',
    component: DettaglioDestinazioneNostraSelezioneComponent,
    resolve: {
      dataPartner: ContainerDettaglioDestinazioniResolveService
    }
  },
  {
    path: SEO_PAGE.DESTINAZIONE_TOP_KEYWORD + '/:idSelezione/:nomeDestinazione/:idDestinazione/:infoN/dettaglio/:filtroDest',
    pathMatch: 'full',
    component: DettaglioDestinazioneNostraSelezioneComponent,
    resolve: {
      dataPartner: ContainerDettaglioDestinazioniResolveService
    }
  },
  {
    path: SEO_PAGE.DETTAGLIO_PARTNER + '/:nomeTourOperator/:title/:idTo',
    pathMatch: 'full',
    component: DettaglioPartnersComponent,
    resolve: {
      dataPartner: DettaglioPartnerResolveService
    }
  },
  {
    path: SEO_PAGE.DETTAGLIO_STRUTTURA + '/:nomeTourOperator/:nomeStruttura/:idStruttura',
    pathMatch: 'full',
    component: DettaglioStrutturaPage,
    resolve: {
      dataStrutturaPartner: DettaglioStrutturaPartnerResolveService
    }
  },
  {
    path: SEO_PAGE.DETTAGLIO_STRUTTURA + '/:nomeStruttura/:idStruttura',
    pathMatch: 'full',
    component: DettaglioStrutturaPage,
    resolve: {
      dataStrutturaPartner: DettaglioStrutturaPartnerResolveService
    }
  },
  {
    path: 'hotel' + '/:nomeStruttura/:idStruttura',
    pathMatch: 'full',
    component: DettaglioStrutturaPage,
    resolve: {
      dataStrutturaPartner: DettaglioStrutturaPartnerResolveService
    }
  },
  {
    path: SEO_PAGE.DETTAGLIO_DESTINAZIONE + '/:nomeTourOperator/:title/:idTo/:idDest',
    pathMatch: 'full',
    component: DettaglioDestinazioneComponent,
    resolve: {
      dataPartner: DettaglioDestinazioneResolveService
    }
  },
  {
    path: ':titolo-macro/:idSelezione/' + SEO_OFFERTE.OFFERTA_MACRO_KEYWORD,
    pathMatch: 'full',
    component: MacroDestinazionePageComponent,
    resolve: {
      dataMacroCategoria: MacroCategoriaResolveService
    }
  },
  {
    path: ':titolo-macro/:idSelezione/' + SEO_OFFERTE.OFFERTA_MACRO_KEYWORD + '/:nomeDestinazione/:idDestinazione',
    pathMatch: 'full',
    component: DettaglioDestinazioneNostraSelezioneComponent,
    resolve: {
      dataPartner: DettaglioDestinazioneNostraSelezioneResolveService
    }
  },
  {
    path: ':titolo-macro/:idSelezione/' + SEO_OFFERTE.OFFERTA_MACRO_KEYWORD + '/:nomeDestinazione/:idDestinazione/:filtroDest',
    pathMatch: 'full',
    component: DettaglioDestinazioneNostraSelezioneComponent,
    resolve: {
      dataPartner: DettaglioDestinazioneNostraSelezioneResolveService
    }
  },
  {
    path: SEO_OFFERTE.AREA_PERSONALE,
    component: AreaPersonaleComponent,
    resolve: {dataResolve: DatiPersonaliResolve},
    children: [
      {path: '', redirectTo: 'desideri', pathMatch: 'full'},
      {
        path: 'dati',
        component: DatiPersonaliComponent
      },
      {
        path: 'prenotazioni',
        resolve: {
          dataPrenotazioni: ListaPrenotazioniResolveService
        },
        component: ListaPrenotazioniComponent
      },
      {
        path: 'desideri',
        component: ListaDesideriComponent
      },
      {
        path: 'newsletter',
        component: NewsletterComponent
      }
    ]
  }, {
    path: 'prime',
    pathMatch: 'full',
    component: PrimePageComponent,
    resolve: {
      dataPartner: PrimeResolveService
    }
  }, {
    path: 'qui-dietro-ci-siamo-noi',
    pathMatch: 'full',
    component: QuiDietroComponent
  },
  {
    path: 'offerte-last-minute' + '/:nomeDestinazione' + '/:idDestinazione' + '/:infoN' + '/:isItalia',
    pathMatch: 'full',
    component: DettaglioDestinazioniComponent,
    resolve: {
      dataDestinazioni: ContainerDettaglioDestinazioniResolveService
    }
  }, {
    path: 'offerte-hotel-italia' + '/:nomeDestinazione' + '/:idDestinazione' + '/:infoN' + '/:isItalia',
    pathMatch: 'full',
    component: DettaglioHotelComponent,
    resolve: {
      dataDestinazioni: DettaglioHotelItaliaResolveService
    }
  },
  {
    path: 'offerte-hotel-mondo' + '/:nomeDestinazione' + '/:idDestinazione' + '/:infoN' + '/:isItalia',
    pathMatch: 'full',
    component: DettaglioHotelComponent,
    resolve: {
      dataDestinazioni: DettaglioHotelMondoResolveService
    }
  },
  {
    path: ':nomeDestinazione' + '/:fDataTxt' + '/offerte-last-minute' + '/:idDestinazione' + '/:infoN' + '/:fDataId' + '/:fDataType' + '/:isItalia',
    pathMatch: 'full',
    component: DettaglioDestinazioniComponent,
    resolve: {
      dataDestinazioni: ContainerDettaglioDestinazioniResolveService
    }
  },
  {
    path: 'carte-regalo',
    component: CarteRegaloComponent,
    resolve: {dataPartner: CartaRegaloResolveService},
    children: [
      {
        path: 'dettaglio/:guid',
        component: DettaglioCartaRegaloComponent
      }
    ]
  },
  {
    path: 'risultato-transazione',
    component: VerificaTransazioneComponent,
    resolve: {dataResolve: VerificaTransazioneResolveService}
  },
  {
    path: 'vetrina-offerte-prime',
    pathMatch: 'full',
    component: VetrinaPrimeComponent,
    resolve: {
      offerteVetrina: VetrinaPrimeResolveService,
    },
  }, {
    path: 'offerte/:nomeDestinazione/z/:idDestinazione',
    pathMatch: 'full',
    component: DettaglioStrutturaPage,
    resolve: {
      dataPartner: DettaglioZResolveService,
    },
  },
  {
    path: 'termini-condizioni',
    component: TerminiCondizioniComponent
  },
  {
    path: 'abbonamento-prime',
    component: PrimePagamentoComponent
  },
  {
    path: 'fuori-tutto',
    component: FuoriTuttoComponent
  },
  {
    path: 'privacy',
    component: PrivacyPageComponent
  }, {
    path: 'recensioni',
    component: RecensioniPageComponent
  }, {
    path: 'prezzo-piu-basso',
    component: PrezzoPiuBassoComponent
  },
  {
    path: 'cancellazionegratuita',
    component: CancellazioneGratuitaComponent
  },
  {
    path: 'scaricabuono',
    component: ScaricabuonoComponent
  },
  {
    path: 'attiva-profilo/:id',
    component: AttivaProfiloComponent
  },
  {
    path: 'rassegna-stampa',
    component: RassegnaStampaComponent
  },
  {
    path: 'convenzione/:titolo',
    resolve: {
      dataLanding: LandingResolveService
    },
    component: LandingComponent
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
