<div class="nav-login-content">
  <div class="w-100 padding-20px">
    <div class="row">
      <div class="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 text-align-left header-login-text">
        Accedi all'Area Personale
      </div>
      <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-align-right">
        <mat-icon class="md-36 cursor-pointer" aria-hidden="false"
                  (click)="closeLogin()">close
        </mat-icon>
      </div>
    </div>
    <form [formGroup]="loginForm">
      <div class="row mt-2">
        <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput
                   autocomplete="off"
                   placeholder="Email"
                   type="text"
                   formControlName="email">
          </mat-form-field>
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <mat-form-field appearance="outline">
            <!--<mat-icon matPrefix>email</mat-icon>-->
            <mat-label>Password</mat-label>
            <input matInput id="password" autocomplete="off" placeholder="Password" type="password"
                   formControlName="password">
          </mat-form-field>
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <button (click)="login(loginForm.valid)" mat-button class="button-style" type="submit">
            <span>Accedi >></span>
          </button>
        </div>
      </div>
      <div class="w-100 red">{{errorMessage}}</div>
    </form>
    <p>
      Password dimenticata? <a class="color-text-orange-borsaviaggi cursor-pointer" (click)="actionRecupero()">clicca
      qui</a>
    </p>
  </div>
  <div class="w-100 div-attiva cursor-pointer" (click)="actionSignUp()">
    <div class="row border-b-darkgrey-1px pb-1rem">
      <div class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-align-right pr-1">
        <img
          alt="Offerte vacanze"
          src="../../../assets/img/icona-freccia.jpg" class="img-fluid"/>
      </div>
      <div class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-align-left pl-1">
        <span class="color-text-orange-borsaviaggi">Non hai un profilo?</span><br>
        <span class="testo-registrati">Registrati, per te un mondo di Servizi!</span>
      </div>
    </div>
    <div class="row pt-1rem">
      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="float-left">
          <img
            alt="Lampada dei desideri, offerte vacanze"
            src="../../../assets/img/icona-lampada-login.jpg" class="img-fluid"/>
        </div>
        <div class="float-left testo-servizi-registrazione">
          Lampada<br>dei Desideri
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="float-left">
          <img
            alt="Desideri di viaggio, offerte vacanze"
            src="../../../assets/img/icona-lista-desideri-login.jpg" class="img-fluid"/>
        </div>
        <div class="float-left testo-servizi-registrazione">
          Lista dei<br>Preferiti
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="float-left">
          <img
            alt="Fuori tutto, offerte vacanze"
            src="../../../assets/img/icona-fuori-tutto-login.jpg" class="img-fluid"/>
        </div>
        <div class="float-left testo-servizi-registrazione">
          Esclusiva<br>Fuori Tutto
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="float-left">
          <img
            alt="Newsletter, offerte vacanze"
            src="../../../assets/img/icona-newsletter-login.jpg" class="img-fluid"/>
        </div>
        <div class="float-left testo-servizi-registrazione">
          Offerte in<br>Anteprima
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <div class="w-100 div-prime-servizi-login">
    <div class="row border-b-darkgrey-1px pb-1rem">
      <div class="col-3 col-xs-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 text-align-right">
        <img
          alt="Prime, offerte vacanze"
          src="../../../assets/img/img-prime-login.jpg" class="img-fluid"/>
      </div>
      <div class="col-9 col-xs-9 col-sm-9 col-md-8 col-lg-8 col-xl-8 text-align-left mt-2rem">
        <span class="testo-registrati">Cashback ad ogni acquisto<br> e Vantaggi Esclusivi?</span><br>
        <span class="prime-text">Solo con PRIME!</span><br>
      </div>
    </div>
    <div class="w-80 mx-auto mb-50px">
      <div class="w-100 pt-1rem valign-wrapper">
        <img
          alt="Spendi meno, offerte vacanze"
          src="../../../assets/img/prime/Prime-Icona-Spendi-Meno.png" class="img-servizio-prime"/>&nbsp;&nbsp;<span class="testo-servizi-prime"><b>Più Sconto</b>&nbsp;per te e i tuoi compagni di viaggio</span>
      </div>
      <div class="w-100 pt-1rem valign-wrapper">
        <img
          alt="Zero pensieri, offerte vacanze"
          src="../../../assets/img/prime/Prime-Icona-Zero-Pensieri.png" class="img-servizio-prime"/>&nbsp;&nbsp;<span class="testo-servizi-prime"><b>Service+</b>&nbsp;Polizza Medica ed Annullamento</span>
      </div>
      <div class="w-100 pt-1rem valign-wrapper">
        <img
          alt="Parcheggio in areoporto, offerte vacanze"
          src="../../../assets/img/prime/Prime-Icona-Parcheggio.png" class="img-servizio-prime"/>&nbsp;&nbsp;<span class="testo-servizi-prime"><b>Parcheggio</b>&nbsp;in Aeroporto per la durata del viaggio</span>
      </div>
      <div class="w-100 pt-1rem valign-wrapper">
        <img
          alt="Prime Borsaviaggi.it, offerte vacanze"
          src="../../../assets/img/prime/icona-cashback.png"
          class="img-servizio-prime"/>&nbsp;&nbsp;<span class="testo-servizi-prime"><b>Cashback</b>&nbsp;fino a 200€ ad ogni tuo viaggio</span>
      </div>
      <button mat-stroked-button color="accent" class="button-prime mt-2" (click)="goToPrime()">ATTIVA ORA è
        praticamente GRATIS >>
      </button>
    </div>
  </div>
</div>
