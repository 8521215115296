<div class="text-align-right" *ngIf="urlRisultatiRicerca && !isMobileDevice">
  <a routerLink="/risultati" [queryParams]="urlRisultatiRicerca"
     class="btn-risultati-ricerca bckground-orange d-flex align-items-center cursor-pointer">
    <mat-icon>navigate_before</mat-icon>
    Risultati di ricerca
  </a>
</div>
<div class="background">
  <div class="text-align-right" *ngIf="urlRisultatiRicerca && isMobileDevice">
    <a routerLink="/risultati" [queryParams]="urlRisultatiRicerca"
       class="btn-risultati-ricerca bckground-orange d-flex align-items-center cursor-pointer">
      <mat-icon>navigate_before</mat-icon>
      Risultati di ricerca
    </a>
  </div>
  <div class="container">
    <div class="container border-r-2px text-align-center background-white border-red"
         *ngIf="partenzaNonDisp">
      <div class="row">
        <div class="col sezione">
          <div class="mb-10px mt-10px">
            <h2 class="mb-none">LA PARTENZA NON È PIÙ DISPONIBILE</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-card-container" [ngClass]="{'pt-40px': openShare}">
      <div class="col-12 col-sm-12 col-md-8 col-xl-8">
        <div class="w-100 div-promo text-align-center mb-15px" *ngIf="promozioneAttiva && isMobileDevice">
          OFFERTA IN FUORI TUTTO<br>
          <div class="timer">
            <!-- span id="days" *ngIf="daysToDday > 0"> {{daysToDday}} </span>Giorni-->
            HAI ANCORA
            <span id="hoursM" *ngIf="hoursToDday > 0"> {{hoursToDday}} </span><span class="fs-12px"
                                                                                    *ngIf="hoursToDday > 0">Ore</span>
            <span id="minutesM"> {{minutesToDday}} </span><span class="fs-12px">Minuti</span>
            <span id="secondsM"> {{secondsToDday}} </span><span class="fs-12px">Secondi</span>
          </div>
        </div>
        <div class="g-card">
          <div class="title-container">
            <div class="main-section">
              <div class="title-wrapper">
                <h1 class="title">{{dataResponse.dettaglioPartenza.nomeStrutturaOfferta | titlecase}}</h1>
                <div class="z-index-2 ml-10px">

                  <borsaviaggi-preferiti [isFavourite]="dataResponse.dettaglioPartenza.isPreferito"
                                         [partenza]="idPartenza"></borsaviaggi-preferiti>
                </div>
                <div class="icon d-inline-block ml-8px">
                  <borsaviaggi-share-button
                    [idPartenza]="idPartenza"
                    [title]="dataResponse.dettaglioPartenza.nomeStrutturaOfferta"
                    (click)="onClickShare($event)"></borsaviaggi-share-button>
                </div>

              </div>

              <div class="full-width">
                <h3 class="subtitle mr-10px display-inline-block dark-blue-color">
                  {{dataResponse.dettaglioPartenza.destinazione | titlecase}}
                  ,&nbsp;{{dataResponse.dettaglioPartenza.nazione | titlecase}}
                </h3>
                <borsaviaggi-mostra-mappa class="display-inline-block"
                                          [luogo]="dataResponse.dettaglioPartenza.nomeStrutturaOfferta"
                                          [nazione]="dataResponse.dettaglioPartenza.nazione"></borsaviaggi-mostra-mappa>

                <div class="prezzo-dinamico  float-right display-inline-block" [ngClass]="{'pl-4rem':!isMobileDevice}">
                  <div *ngIf="dataResponse.dettaglioPartenza.isHPartenzaLive"
                       class="display-inline col text-align-center no-gutters"
                       [ngClass]="{'pl-5px':isMobileDevice ,'pr-5px':isMobileDevice}">
                    <img
                      alt="prezzo viaggio dinamico"
                      [height]="isMobileDevice?'18':'25'"
                      [matTooltip]="txtPrezzoDinamico"
                      src="assets/img/icons/prezzo-dinamico.svg">
                  </div>
                  <div class="col text-align-center display-inline  no-gutters"
                       [ngClass]="{'pl-5px':isMobileDevice ,'pr-5px':isMobileDevice}">
                    <img [height]="isMobileDevice?'18':'25'"
                         alt="viaggio garantito"
                         [matTooltip]="txtAGarantito"
                         src="assets/img/icons/acquisto-garantito.svg">
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div class="photo-container">
            <div class="photo-wrapper">
              <div class="row">
                <div class="col-md-12 gallery-wrapper overflow-hidden">
                  <ngx-gallery [hidden]="photosType === 'Struttura'" [options]="galleryOptions"
                               [images]="galleryImagesStruttura"></ngx-gallery>
                </div>

                <div class="col-md-12 gallery-wrapper overflow-hidden">
                  <ngx-gallery [hidden]="photosType === 'Destinazione' || photosType === 'Itinerario'"
                               [options]="galleryOptions"
                               [images]="galleryImagesDestinazione"></ngx-gallery>
                </div>
              </div>
            </div>
          </div>
          <div class="description-container">
            <!-- TAB -->
            <mat-tab-group mat-stretch-tabs (selectedTabChange)="setTabContext($event)">
              <mat-tab *ngIf="showStructureTab" class="mt-10px mb-10px" label="Struttura" (click)="onTabClick()">
                <ng-template mat-tab-label>
                  <mat-tab-label-content class="mobile-content">Struttura</mat-tab-label-content>
                  <mat-icon class="ml-2">apartment</mat-icon>
                </ng-template>
                <div class="div-descrizione p-2" [innerHTML]="dataDescPartenza.descrizioneStruttura | safeHtml"></div>
                <!--borsaviaggi-readmore [content]="dataDescPartenza.descrizioneStruttura"></borsaviaggi-readmore-->
              </mat-tab>

              <mat-tab class="mt-10px mb-10px" label="Destinazione" (click)="onTabClick()">
                <ng-template mat-tab-label>
                  <mat-tab-label-content class="mobile-content">Destinazione</mat-tab-label-content>
                  <mat-icon class="ml-2">beach_access</mat-icon>
                </ng-template>
                <div class="div-descrizione p-2" [innerHTML]="dataDescPartenza.descrizioneDestinazione | safeHtml"></div>
                <!--borsaviaggi-readmore [content]="dataDescPartenza.descrizioneDestinazione"></borsaviaggi-readmore-->
              </mat-tab>

              <mat-tab *ngIf="showItineraryTab" class="mt-10px mb-10px" label="Itinerario" (click)="onTabClick()">
                <ng-template mat-tab-label>
                  <mat-tab-label-content class="mobile-content">Itinerario</mat-tab-label-content>
                  <mat-icon class="ml-2">map</mat-icon>
                </ng-template>
                <div class="div-descrizione p-2" [innerHTML]="dataDescPartenza.itinerario | safeHtml"></div>
                <!--borsaviaggi-readmore [content]="dataDescPartenza.itinerario"></borsaviaggi-readmore-->
              </mat-tab>

              <mat-tab
                *ngIf="dataResponse.voli && dataResponse.voli.compagniaAndata && dataResponse.dettaglioPartenza.isLuogoAereoporto"
                class="mt-10px mb-10px mh-100" label="Informazioni Volo">
                <ng-template mat-tab-label>
                  <mat-tab-label-content class="mobile-content">Informazioni Volo</mat-tab-label-content>
                  <mat-icon class="ml-2">flight_takeoff</mat-icon>
                </ng-template>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                  <ng-container matColumnDef="compagnia">
                    <th mat-header-cell *matHeaderCellDef> Compagnia</th>
                    <td mat-cell *matCellDef="let element" class="mobile-fs-1rem"> {{element.compagnia}} </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="partenza">
                    <th mat-header-cell *matHeaderCellDef> Partenza</th>
                    <td mat-cell *matCellDef="let element">
                      <span class="col-12 float-left mobile-fs-1rem">{{element.luogoP}}</span>
                      <span class=" col-12 float-left fs-small mobile-fs-1rem bold">{{element.dataP}}</span>
                      <span
                        class="col-12 float-left fs-small mobile-fs-1rem color-text-orange-borsaviaggi">{{element.scaloP}}</span>
                    </td>

                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="arrivo">
                    <th mat-header-cell *matHeaderCellDef> Arrivo</th>
                    <td mat-cell *matCellDef="let element">


                      <span class="col-12 float-left mobile-fs-1rem">{{element.luogoAr}}</span>
                      <span class="col-12 float-left fs-small mobile-fs-1rem bold">{{element.dataAr}}</span>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>


              </mat-tab>
            </mat-tab-group>
            <ng-container *ngFor="let campagna of dataBannerCampagne">
              <div class="banner" *ngIf="isMobileDevice && campagna.tipoDevice == 'Mobile'">
                <img
                  class="banner" [src]="campagna.bannerUrl">
              </div>
              <div class="banner" *ngIf="!isMobileDevice && campagna.tipoDevice == 'Desktop'">
                <img
                  class="banner" [src]="campagna.bannerUrl">
              </div>
            </ng-container>

            <div class="main-title-container col-12">
              <div class="title">Dettagli partenza</div>
              <div class="codice-p d-none d-sm-inline-block">Codice Partenza P{{idPartenza}}</div>
            </div>
            <div class="info-container row no-gutters">
              <div class="info-partenza col-12 col-sm-12 col-md-6 col-lg-6">
                <div class="title">Informazioni partenza</div>
                <bv-info-partenza-row [label]="'Da'" [value]="dataResponse.dettaglioPartenza.luogoNome"
                                      [icon]="nameIconDeparturePlace"></bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Partenza il'" [value]="dataResponse.dettaglioPartenza.lblDataPartenza"
                                      [icon]="'calendar'"></bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Rientro il'" [value]="dataResponse.dettaglioPartenza.lblDataRientro"
                                      [icon]="'calendar'"></bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Soggiorno'" [value]="dataResponse.dettaglioPartenza.lblGiorniNotti"
                                      [icon]="'bed'"></bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Trattamento'"
                                      [value]="dataResponse.dettaglioPartenza.lblTrattamento | titlecase"
                                      [icon]="'fork'"></bv-info-partenza-row>
              </div>
              <div class="dettagli col-12 col-sm-12 col-md-6 col-lg-6">
                <div class="title">La quota include:</div>
                <div class="text" [innerHTML]="quotaComprende"></div>
                <div class="title" *ngIf="noteQuotaComprende">Note:</div>
                <div class="text" *ngIf="noteQuotaComprende" [innerHTML]="noteQuotaComprende"></div>
              </div>
              <div class="dettagli col-12 col-sm-12" *ngIf="costiLoco">
                <div class="title">Costi in loco:</div>
                <div class="text" [innerHTML]="costiLoco"></div>
              </div>
              <div class="dettagli col-12 col-sm-12" *ngIf="bambiniGratisTxt">
                <div class="title">Promo Bambini:</div>
                <div class="text" [innerHTML]="bambiniGratisTxt"></div>
              </div>
            </div>
            <a [routerLink]="'/'+makeUrlSeoHref()">
              <div class="col-12 banner-altre-partenze text-align-center">
                Altre partenze
                <mat-icon>flight_takeoff</mat-icon>
              </div>
            </a>
          </div>

          <div class="text-align-center assistenza-mobile mt-20px">
            <div>
              <span class="fs-16px bold">Hai bisogno di assistenza?</span>
            </div>
            <div class="mt-10px fs-14px">
              Chiamaci indicando il codice:
              <p class="fs-14px bold">P{{idPartenza}}</p>
              <a href="tel:065587667" data-role="none">
                <mat-icon class="call-now">phone_enabled</mat-icon>
              </a>
            </div>
            <div class="display-flex flex-column mt-10px fs-10px">
              <span>Lun - Ven: 09:00/19:00</span>
              <span>Sab: 9:00/13:00</span>
            </div>
          </div>

          <div></div>
          <div></div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-4 col-xl-4">
        <div class="top-box" [ngClass]="{'top-0-imp':mobileStickyHeader}">

          <div class="top-section-wrapper">

            <div *ngIf="!dataResponse.dettaglioPartenza.isHPartenzaLive" class="price-wrapper">
              <div class="title">Prezzo Finito {{preventivoRq.costiCamere.length === 0 ? ' per 2 persone' : '' }}</div>
              <mat-accordion *ngIf="preventivoRq.costiCamere.length > 0">
                <mat-expansion-panel class="camere-expansion-panel bg-black"
                                     *ngFor="let camera of preventivoRq.costiCamere">
                  <mat-expansion-panel-header>
                    <mat-panel-description class="color-white pl-45px">
                      <b> Camera {{camera.idCamera}}</b>&nbsp; {{" -> " + getPrezzoCamera(camera, false)}}€

                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row color-white fs-small mobile-fs-1rem">
                   <span *ngFor="let supplemento of camera.supplementiTipoCamera" class="col-12">
                     {{supplemento.descrizione}}

                </span>
                    <span *ngIf="camera.supplementiTipoCamera.length === 0" class="col-12">
                    Nessun supplemento selezionato
                </span>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
              <!-- <div class="price">€3645.12</div> -->
              <div class="price" [hidden]="partenzaNonDisp">{{calcolaPrezzoTotale() | euroFormat}}<span class="fs-16px">€</span>
              </div>
            </div>
            <div *ngIf="dataResponse.dettaglioPartenza.isHPartenzaLive"
                 class="price-wrapper">
              <div *ngIf="!partenzaNonDisp" class="title">Prezzo
                Finito  {{preventivoRq.costiCamere.length === 0 ? ' per 2 persone' : '' }}</div>
              <mat-accordion
                *ngIf="preventivoRq.camereLive.length > 0 && preventivoRq.camereLive[0].supplementiTipoCamera.length >0">
                <mat-expansion-panel class="camere-expansion-panel bg-black"
                                     *ngFor="let camera of preventivoRq.camereLive">
                  <mat-expansion-panel-header>
                    <mat-panel-description class="color-white pl-45px">
                      <b> Camera {{camera.id}}</b>&nbsp; {{" -> " + getPrezzoCamera(camera, true)}}€

                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row color-white fs-small">
                   <span class="col-12">
                     {{camera.adulti + " Adulti + " + camera.bambini + " Bambini"}}
                </span>
                    <div class="col-12" *ngIf="camera.supplementiTipoCamera && !isMobileDevice">
                    <span *ngFor="let item of camera.supplementiTipoCamera; let i = index">
                      <span *ngIf="i == 0">( </span><span>{{item.descrizioneCamera}}</span>
                      <span *ngIf="i != camera.supplementiTipoCamera.length - 1"> + </span>
                      <span *ngIf="i == camera.supplementiTipoCamera.length - 1"> ) </span>
                    </span>
                    </div>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
              <borsaviaggi-loader class="loader-prezzo" [hidden]="partenzaNonDisp" [show]="prezzoLoading" diameter="30"
                                  typeMode="piker"></borsaviaggi-loader>
              <div *ngIf="!partenzaNonDisp && !loadingCamere"
                   class="price">{{totaleSupplementi || totaleLiveDef | euroFormat}}<span
                class="fs-16px">€</span>
              </div>
              <div *ngIf="partenzaNonDisp" class="price"><span class="fs-16px">PARTENZA NON DISPONIBILE</span>
              </div>
            </div>
            <div *ngIf="!dataResponse.dettaglioPartenza.isHPartenzaLive" class="sub-price-wrapper">
              <div class="title"></div>
              <div class="sub-price"></div>
            </div>
            <div *ngIf="!dataResponse.dettaglioPartenza.isHPartenzaLive && preventivoRq.costiCamere.length === 0"

                 class="subtitle">
              per persona {{calcolaPrezzoAPersona(dataResponse.dettaglioPartenza) | euroFormat}}€
            </div>
            <div class="d-md-none d-flex margin-auto modifica procedi mobile-top-box-container-edit"
                 [ngClass]="{'top-0-imp':mobileStickyHeader}">
              <a (click)="savePreventivo()" class="procedi-link">
                <div class="btn-top-box cursor-pointer" [ngClass]="{'btn-disabled':loadingCamere}">Procedi</div>
              </a>
            </div>
            <div class="modifica mobile-top-box-container-summary"
                 [ngClass]="{'top-0-imp':mobileStickyHeader}"
                 (click)="openModificaOccupazioneModal()">
              <div class="icon">
                <mat-icon class="color-text-orange-borsaviaggi">edit</mat-icon>
              </div>
              <span class="modifica-personalizza-label">Modifica camere</span>
            </div>
            <a (click)="savePreventivo()">
              <div class="btn-top-box cursor-pointer d-none d-md-block" [ngClass]="{'btn-disabled':loadingCamere}">
                Procedi
              </div>
            </a>

          </div>
          <div class="tab-wrapper">
            <div class="tab">
              <div class="tab-content">
                <div class="vantaggi-prime-container prime-offerta-tab">
                  <mat-accordion
                    *ngIf="!dataResponse.dettaglioPartenza.isPartenzaPrime && !(userData &&  userData.userInfo && userData.userInfo.isPrime)">
                    <mat-expansion-panel class="scroll-on-mobile" (opened)="toggleAccordionPrime(true)"
                                         (closed)="toggleAccordionPrime(false)">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b class="fs-09rem full-width text-center">
                            <span class="display-block fs-1_1rem">
                              Con <span class="prime-red">PRIME</span> hai buono di <span
                              class="prime-red">{{importoBuonScontoPrime}}€</span>.</span>
                            <span>
                            Accedi ad un nuovo livello di servizio!
                            </span>
                          </b>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="vantaggi-container">
                        <div class="vantaggi-descr fs-12px">Avrai un Consulente Personale che disegna il viaggio dei
                          tuoi sogni,
                          un Assistente attivo 7 giorni su 7 che ti segue anche in Vacanza e molto altro!
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
                          <div class="benefit-title fs-16px fw-500">Very Important Traveller</div>
                          <div class="benefit-descr fs-12px">Sarai riconosciuto con priorità dal tuo Consulente di
                            fiducia
                          </div>
                        </div>
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Preventivi.png"
                               alt="icona preventivi sartoriali">
                          <div class="benefit-title fs-16px fw-500">Preventivi Sartoriali</div>
                          <div class="benefit-descr fs-12px">Potrai richiedere ad Esperti sui luoghi la costruzione di
                            Viaggi su misura
                          </div>
                        </div>
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Garanzia-Prezzo.png"
                               alt="icona garanzia prezzo">
                          <div class="benefit-title fs-16px fw-500">Garanzia Prezzo Migliore</div>
                          <div class="benefit-descr fs-12px">Se hai un prezzo più economico ti adeguiamo il preventivo
                          </div>
                        </div>
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Assistenza.png"
                               alt="icona assistenza viaggi">
                          <div class="benefit-title fs-16px fw-500">Assistenza Viaggio</div>
                          <div class="benefit-descr fs-12px">Un Assistente online 7 giorni su 7 che ti segue in vacanza,
                            dalla partenza al rientro
                          </div>
                        </div>
                        <div class="col mb-10px">
                          <img width="80px" src="../../../assets/img/prime/icona-cashback-gialla.png"
                               alt="icona sconti">
                          <div class="benefit-title fs-16px fw-500">Fedeltà che ti Premia</div>
                          <div class="benefit-descr fs-12px">Prenotando questa Offerta per te un Buono di <span
                            class="red bold">{{destinazioneSrv.getImportoBuonoSconto(calcolaPrezzoTotale())}}€</span>
                            che potrai
                            usare o regalare a chi vorrai
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion
                    *ngIf="!dataResponse.dettaglioPartenza.isPartenzaPrime && (userData &&  userData.userInfo && userData.userInfo.isPrime)">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b class="fs-09rem"> Sei un utente <span class="prime-red">PRIME</span> acquistando questa
                            vacnaza hai buono di <span
                              class="prime-red">{{importoBuonScontoPrime}}€</span>. !</b>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="vantaggi-container">
                        <div class="vantaggi-descr fs-12px">Hai un Consulente Personale che disegna il viaggio dei
                          tuoi sogni,
                          un Assistente attivo 7 giorni su 7 che ti segue anche in Vacanza e molto altro!
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
                          <div class="benefit-title fs-16px fw-500">Very Important Traveller</div>
                          <div class="benefit-descr fs-12px">Sei riconosciuto con priorità dal tuo Consulente di
                            fiducia
                          </div>
                        </div>
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Preventivi.png"
                               alt="icona preventivi sartoriali">
                          <div class="benefit-title fs-16px fw-500">Preventivi Sartoriali</div>
                          <div class="benefit-descr fs-12px">Puoi richiedere ad Esperti sui luoghi la costruzione di
                            Viaggi su misura
                          </div>
                        </div>
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Garanzia-Prezzo.png"
                               alt="icona garanzia prezzo">
                          <div class="benefit-title fs-16px fw-500">Garanzia Prezzo Migliore</div>
                          <div class="benefit-descr fs-12px">Se hai un prezzo più economico ti adeguiamo il preventivo
                          </div>
                        </div>
                        <div class="col-6 mb-10px">
                          <img width="80px" src="../../../assets/img/prime/Prime-Icona-Assistenza.png"
                               alt="icona assistenza viaggi">
                          <div class="benefit-title fs-16px fw-500">Assistenza Viaggio</div>
                          <div class="benefit-descr fs-12px">Un Assistente online 7 giorni su 7 che ti segue in vacanza,
                            dalla partenza al rientro
                          </div>
                        </div>
                        <div class="col mb-10px">
                          <img width="80px" src="../../../assets/img/prime/icona-cashback-gialla.png"
                               alt="icona sconti">
                          <div class="benefit-title fs-16px fw-500">Fedeltà che ti Premia</div>
                          <div class="benefit-descr fs-12px">Prenotando questa Offerta per te un Buono di <span
                            class="red bold">{{destinazioneSrv.getImportoBuonoSconto(calcolaPrezzoTotale())}}€</span>
                            che potrai
                            usare o regalare a chi vorrai
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion
                    *ngIf="dataResponse.dettaglioPartenza.isPartenzaPrime">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b class="fs-09rem full-width text-center">
                            <span class="display-block fs-1_1rem">Hai scelto un Offerta<span
                              class="prime-red">PRIME</span></span>
                            hai subito
                            <span
                              class="prime-red">{{risparmioPrime}}€</span> di vantaggi
                          </b>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <div class="col mb-10px" *ngFor="let vantaggi of dataResponse.serviziPrime">
                          <img width="80px" [src]="vantaggi.logo">
                          <div class="benefit-title fs-16px fw-500">{{vantaggi.titolo}}</div>
                          <div class="mini-label red">
                            Risparmi {{vantaggi | calcolaImportoVantaggioPrime:(preventivoRq.costiCamere):calcolaPrezzoTotale()}}
                            €
                          </div>
                          <div class="benefit-descr fs-12px">{{vantaggi.descrizione}}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col mb-10px">
                          <img width="80px" src="../../../assets/img/prime/icona-cashback.png"
                               alt="icona sconti">
                          <div class="benefit-title fs-16px fw-500">Fedeltà che ti Premia</div>
                          <div class="benefit-descr fs-12px">Prenotando questa Offerta per te un Buono di <span
                            class="red bold">{{destinazioneSrv.getImportoBuonoSconto(calcolaPrezzoTotale())}}€</span>
                            che potrai
                            usare o regalare a chi vorrai
                          </div>
                        </div>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion
                    *ngIf="dataResponse.dettaglioPartenza.isHPromoFlashAlpitour">
                    <mat-expansion-panel class="mt-10px-i promo-flash-expansion-panel" [expanded]="isFlashExpanded">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b class="pl-75px"> Promo Flash Borsaviaggi
                          </b>
                        </mat-panel-title>

                      </mat-expansion-panel-header>

                      <div class="row">
                        <div class="col mb-10px">
                          <img width="80px"
                               alt="partenza prime offerta flash"
                               src="assets/img/icone-partenze/LogoPromoFlash.png">
                          <div class="benefit-descr fs-12px">Se procedi con questa offerta ti verrà applicato un</div>
                          <div class="mini-label red">
                            codice sconto di {{dataResponse.dettaglioPartenza.lblScontoAlpitour}}€
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
            <div class="w-100 div-promo text-align-center" *ngIf="promozioneAttiva && !isMobileDevice">
              OFFERTA IN FUORI TUTTO<br>
              <div class="timer">
                <!-- span id="days" *ngIf="daysToDday > 0"> {{daysToDday}} </span>Giorni-->
                HAI ANCORA
                <span id="hours" *ngIf="hoursToDday > 0"> {{hoursToDday}} </span><span class="fs-12px"
                                                                                       *ngIf="hoursToDday > 0">Ore</span>
                <span id="minutes"> {{minutesToDday}} </span><span class="fs-12px">Minuti</span>
                <span id="seconds"> {{secondsToDday}} </span><span class="fs-12px">Secondi</span>
              </div>
            </div>
            <div class="tab">
              <div class="tab-label ti-serve-aiuto-label text-align-center" (click)="toggleShowTiServeAiuto()">
                TI SERVE AIUTO SULL'OFFERTA?
              </div>
              <div class="tab-content ti-serve-aiuto-tab" *ngIf="showTiServeAiuto">
                <div class="col"><img width="92px" src="../../../assets/img/home/call-center.png" alt="icona vit"></div>
                <div class="col text">
                  <div>Chiamaci al <span class="bold">06 5587667</span></div>
                  <div>o <span class="bold">TI RICHIAMIAMO NOI!</span></div>
                </div>
                <form [formGroup]="formGroup">
                  <div class="form-group row pl-15px pr-15px ">
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Nome</mat-label>
                      <input matInput placeholder="Nome" required name="nome" formControlName="nome"
                             class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.nome"
                                     [formControlParam]="formGroup.controls.nome"></bv-form-error>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Cognome</mat-label>
                      <input matInput placeholder="Cognome" required name="cognome" formControlName="cognome"
                             class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.cognome"
                                     [formControlParam]="formGroup.controls.cognome"></bv-form-error>
                    </mat-form-field>

                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Cellulare</mat-label>
                      <input matInput placeholder="Cellulare" required type="number" name="cellulare"
                             formControlName="cellulare" class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.cellulare"
                                     [formControlParam]="formGroup.controls.cellulare"></bv-form-error>
                    </mat-form-field>

                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" type="email" name="email" formControlName="email"
                             class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.email"
                                     [formControlParam]="formGroup.controls.email"></bv-form-error>
                    </mat-form-field>
                    <div>
                    <mat-checkbox formControlName="privacy" required class="col-12 mb-1em">
                      Ho letto ed accetto l'<a routerLink="/informativa-privacy">Informativa Privacy*</a>
                    <bv-form-error class="text-align-left"
                                    [formErrorMessage]="validation_messages.privacy"
                                   [formControlParam]="formGroup.controls.privacy">
                    </bv-form-error>
                    </mat-checkbox>
                    </div>
                  </div>
                </form>
                <div class="btn cursor-pointer mt-0" (click)="richiediInfo(formGroup.valid)">Richiamami!!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
