import {Component, OnInit} from '@angular/core';
import {UtilityService} from "../../services/gate/utility.service";

@Component({
  selector: 'borsaviaggi-rassegna-stampa',
  templateUrl: './rassegna-stampa.component.html',
  styleUrls: ['./rassegna-stampa.component.scss']
})

export class RassegnaStampaComponent implements OnInit {
  public listaArticoli = [];

  constructor(private utilitySrv: UtilityService) {
  }

  ngOnInit(): void {
    this.utilitySrv.getArticoli().then(
      (response) => {
        if (response && response.body.data) {
          this.listaArticoli = response.body.data;
        } else {
          console.log("Errore reperimento articoli")
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }
}
