<div class="d-sm-none mobile-title-text-container">

  <div class="mobile-background-container">
    <div class="mobile-title-container">
      <div class="mobile-image-container">
        <img class="mobile-image"
             [alt]="infoPartner.title"
             [src]="infoPartner.imageUrl">
      </div>
      <h1 class="text-align-center mobile-title">{{infoPartner.title}}</h1>
    </div>
    <div class="mobile-background-text">
      <h3 class="text-align-left d-sm-none mobile-testo">{{infoPartner.description}}</h3>
    </div>
  </div>
</div>
<div class="background">
  <div class="container border-r-2px padding-20px mt-20px mb-20px text-align-justify background-white overflow-hidden">
    <div class="row mb-15px partner-box display-not-mobile">
      <div class="col-sm-3 align-items-center justify-content-center text-center">
        <img class="mt-2rem img-partner"
             [alt]="infoPartner.title"
             [src]="infoPartner.imageUrl">
      </div>
      <div class="col-sm-9 mt-4">
        <h1 class="text-align-left fs-h1">{{infoPartner.title}}</h1>
        <h3 class="text-align-left fs-h3">{{infoPartner.description}}</h3>
      </div>
    </div>
    <div class="g-card">
      <div class="separator"></div>

      <div class="info-type-bar">
        <mat-label>Le nostre offerte per:</mat-label>
        <mat-label class="location-text">{{nazione}} </mat-label>
      </div>

      <!-- location filter -->
      <div class="location-filter mt-1rem">

        <mat-label>&nbsp; Filtra per:</mat-label>
        <a class="link" *ngFor="let location of destinazioneInOfferte"
           [title]="infoPartner.title+' '+location.nomeDestinazione"
           [routerLink]="makeFilterUrl(location)">{{location.nomeDestinazione}}</a>
      </div>

      <!-- date and transport filter -->
      <div class="mt-15px mb-15px">
        <borsaviaggi-search-vacanze [filterDestinations]="filteredDestinations"
                                    [idTourOperator]="idTo"></borsaviaggi-search-vacanze>
      </div>

      <!-- offers list -->
      <div class="mobile-offers offers-container">
        <mat-accordion [multi]="true">
          <mat-expansion-panel class="xps-panel mat-elevation-z8" *ngFor="let data of objDataSource; first as isFirst"
                               [expanded]="isFirst">
            <mat-expansion-panel-header class="expansion-header">
              <mat-panel-title class="fs-1_2rem text-align-left">
                {{data.infoOfferta.titolo | titlecase}}
              </mat-panel-title>
              <mat-panel-description class="d-none d-sm-inline-block">
                Codice Offerta: G{{data.infoOfferta.idOfferta}}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-sm carousel">
                <mat-carousel
                  timings="250ms ease-in"
                  interval="5000"
                  color="accent"
                  proportion="80"
                  orientation="ltr"
                  [autoplay]="false"
                  [loop]="true"
                  [hideArrows]="false"
                  [hideIndicators]="false"
                  [useKeyboard]="true"
                  [useMouseWheel]="false"
                >
                  <mat-carousel-slide
                    #matCarouselSlide
                    *ngFor="let img of data.images; let i = index"
                    [image]="img"
                    overlayColor="#00000040"
                    [hideOverlay]="false"
                  ></mat-carousel-slide>
                </mat-carousel>
              </div>

              <div class="col-sm-9">
                <table mat-table [dataSource]="data.tabellaPartenze">
                  <ng-container matColumnDef="partenzaDa">
                    <th mat-header-cell *matHeaderCellDef > Partenza Da</th>
                    <td mat-cell *matCellDef="let element"
                        > {{element.luogoPartenza | titlecase}} </td>
                  </ng-container>
                  <ng-container matColumnDef="dataPartenza">
                    <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Data</th>
                    <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> dal {{element.dal | date :'dd/MM/yyyy'}}
                      al {{element.al | date :'dd/MM/yyyy'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="giorniNotti">
                    <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Giorni/Notti</th>
                    <td mat-cell *matCellDef="let element"
                        class="d-none d-md-table-cell"> {{element.giorniNotti}} </td>
                  </ng-container>
                  <ng-container matColumnDef="trattamento">
                    <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Trattamento</th>
                    <td mat-cell *matCellDef="let element"
                        class="d-none d-md-table-cell"> {{element.trattamento | titlecase}}</td>
                  </ng-container>
                  <ng-container matColumnDef="prezzo">
                    <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                    <td mat-cell *matCellDef="let element" class="bold ws-nowrap"> {{element.costoTotaleMinimo}}</td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <a
                        [title]="infoPartner.title+' '+data.infoOfferta.titolo"
                        [routerLink]="element.url"
                      >
                        <button class="btn-prenota" mat-raised-button>VAI</button>
                      </a>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
