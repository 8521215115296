import {Component, Inject, OnInit, PLATFORM_ID, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {LOADER_TYPE, LoaderService} from './components/loader/loader.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import {AppConfig} from '../app.config';
import {Meta} from '@angular/platform-browser';
import {SEO_GLOBAL_KEYWORD} from './constants/seo.const';
import * as moment from 'moment';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {NavigationComponent} from './components/navigation-component/navigation.component';
import {ImageUtilService} from './services/common/image.util.service';
import {isPlatformBrowser} from '@angular/common';
import {NavigationsUtilsService} from './services/common/navigation.utils.service';
import {FooterComponent} from './components/footer/footer.component';
import {ModalService} from './services/common/modal.service';
import {UtilityService} from './services/gate/utility.service';
import {UtilsStringService} from './services/common/utils-string.service';
import {LoaderState} from "./components/loader/loader.state.interface";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public loadingFullScreen;
  public loaderTypeMode: LOADER_TYPE;
  public supportWebP;
  public testoAvviso;
  public avvisoAttivo = false;
  public loadPage = true;
  @ViewChild(ToolbarComponent) toolbarCtrl: ToolbarComponent;
  @ViewChild(NavigationComponent) navigationCtrl: NavigationComponent;
  @ViewChild(FooterComponent) footerCtrl: FooterComponent;


  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private appConfig: AppConfig,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) public platformId: string,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private modalSrv: ModalService,
    private utilitySrv: UtilityService
  ) {

    this.loaderTypeMode = LOADER_TYPE.FULL_SCREEN;
    this.supportWebP = ImageUtilService.checkSupportImageWebP(!isPlatformBrowser(this.platformId));

    this.metaTagService.addTags([
      {name: 'keywords', content: SEO_GLOBAL_KEYWORD.DEFAULT_KEYWORD_PAGE},
      {name: 'robots', content: 'index, follow'},
      {name: 'author', content: SEO_GLOBAL_KEYWORD.AUTHOR},
      {name: 'date', content: moment().format('YYYY-MM-DD'), scheme: 'YYYY-MM-DD'},
      {name: 'title', content: SEO_GLOBAL_KEYWORD.DEFAULT_TITLE_PAGE},
      {name: 'description', content: SEO_GLOBAL_KEYWORD.DEFAULT_DESCRIPTION_PAGE},
      {name: 'fb:app_id', content: SEO_GLOBAL_KEYWORD.FB_APP_ID},
      {charset: 'UTF-8'}
    ]);
    router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });
    this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.loaderTypeMode = state.typeMode;
        this.loadingFullScreen = state.show;
        this.loadPage = state.loadPage;
      });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('AppComponentLoadedAlready')) {

      } else {
        // first load
        this.utilitySrv.getAvvisoPop().then(
          (response) => {
            if (response && response.body.data) {
              this.testoAvviso = response.body.data.testoAvviso;
              this.avvisoAttivo = response.body.data.avvisoAttivo;

              if (this.avvisoAttivo) {
                sessionStorage.setItem('AppComponentLoadedAlready', 'yes');

                const paramData = {
                  testoComunicazione: this.testoAvviso,
                  testoSaluti: ''
                };
                this.modalSrv.openComunicazioniModal(paramData);
              }
            } else {
              console.log('Errore reperimento avviso pop');
            }
          },
          (error) => {
            console.log(error);
          });
      }
    }
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      const typeLoader = UtilsStringService.getParameterByName('typeLoader', event.url);
      this.loaderTypeMode =  typeLoader as LOADER_TYPE || LOADER_TYPE.FULL_SCREEN;
      this.loadingFullScreen = true;
    }
    if (event instanceof NavigationEnd) {
      if (isPlatformBrowser(this.platformId)) {
        /*ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');*/
      }
      document.querySelector('body').classList.remove('overflow-hidden');
      const elementToScroll = document.querySelector('html');
      if (elementToScroll && elementToScroll.scroll) {
        setTimeout(
          () => {
            elementToScroll.scroll({top: 0, left: 0, behavior: 'smooth'});
          }, 0
        );
      }
      this._toolbarManager(event);
      this._hideSpinner();
      NavigationsUtilsService.setLastState(event.url);
    }
    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    setTimeout(() => {
      this.loadingFullScreen = false;
    });
  }

  private _toolbarManager(paramData): void {
    if (paramData.url && (paramData.url.indexOf('/risultati') !== -1 ||
      paramData.url.indexOf('/p/') !== -1 ||
      paramData.url.indexOf('/risultati-crociere') !== -1) ||
      paramData.url.indexOf('/c/') !== -1 ||
      paramData.url.indexOf('crociera-checkout') !== -1) {
      if (this.toolbarCtrl) {
        this.toolbarCtrl.hideNavigationDesktop = true;
      }
    } else if (this.toolbarCtrl) {
      this.toolbarCtrl.hideNavigationDesktop = false;
    }

    this.footerCtrl.hideNavigationDesktop = paramData.url.indexOf('/preventivo/') !== -1 ||
      paramData.url.indexOf('/crociera-checkout/') !== -1 ||
      paramData.url === '/';
  }

  public eventToggleMenu(isOpenMenu) {
    const chatElement = document.querySelector<HTMLElement>('#chat-widget-container');
    if (chatElement) {
      chatElement.style.display = isOpenMenu ? 'none' : 'block';
    }
    const bodyElement = document.querySelector<HTMLElement>('#bodyContainer');
    if (bodyElement) {
      bodyElement.style.overflowY = 'auto';
    }
  }
  protected readonly LOADER_TYPE = LOADER_TYPE;
}
