<div class="text-align-right" *ngIf="urlRisultatiRicerca && !isMobileDevice">
  <a [href]="urlRisultatiRicerca"
     class="btn-risultati-ricerca bckground-blue d-flex align-items-center cursor-pointer">
    <mat-icon>navigate_before</mat-icon>
    Risultati di ricerca
  </a>
</div>
<div class="background">
  <div class="container" [ngClass]="{'pt-20px': openShare}">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-8 col-xl-8 mt-1rem">
        <div class="g-card">
          <div class="title-container">
            <div class="main-section">
              <div class="title-wrapper">
                <!-- <h1 class="title">{{dataResponse.dettaglioPartenza.nomeStrutturaOfferta | titlecase}}</h1> -->
                <h1 class="title">
                  <span><img style="height:2rem; max-height:2rem;" src="assets/img/icons/ship.svg"></span>
                  <!-- MSC Meraviglia -->
                  {{dataResponse.nave.nomeNave}}
                </h1>
                <div class="z-index-2">
                  <!-- <borsaviaggi-preferiti [isFavourite]="dataResponse.dettaglioPartenza.isPreferito" [partenza]="idPartenza"></borsaviaggi-preferiti> -->
                  <borsaviaggi-preferiti [isFavourite]="dataResponse.isPreferito" [partenza]="dataResponse.idCrociera" crociera="true"></borsaviaggi-preferiti>
                </div>
                <div class="icon d-inline-block d-sm-none preferiti">
                  <borsaviaggi-share-button [title]="dataResponse.nave.nomeNave"
                                            (click)="onClickShare($event)"></borsaviaggi-share-button>
                </div>
              </div>

              <div class="subtitle-wrapper">
                <h3 class="subtitle ws-auto">
                  <!-- Italia, Francia, Spagna -->
                  {{dataResponse.titolo}}
                </h3>
              </div>
              <div class="third-title-wrapper">
                <h4 class="third-title">
                  <span><img class="icon" src="assets/img/icons/ship.svg"></span>
                  <!-- Msc Crociere - <i>Giro del Mondo</i> -->
                  {{parseUrlStr(dataResponse.nomeCompagnia)}}
                </h4>
              </div>
            </div>
            <div class="side-section">
              <div class="side-top-wrapper d-none d-sm-inline-block">
                <borsaviaggi-share-button [title]="'Nome Struttura Offerta'"></borsaviaggi-share-button>
              </div>
              <div class="side-bottom-wrapper d-none d-sm-inline-block">
                <div class="text">
                  <span>Codice Crociera:&nbsp;</span>
                  <span>C{{dataResponse.idCrociera}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="codice-offerta-xs d-block d-sm-none">
            <span>Codice Offerta:&nbsp;</span>
            <span>{{dataResponse.idCrociera}}</span>
          </div>
          <div class="separator"></div>
          <div class="photo-container">
            <div class="photo-wrapper">
              <div class="row">
                <div class="col-md-12 gallery-wrapper overflow-hidden">
                  <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
                </div>
              </div>
            </div>
          </div>
          <div class="description-container">
            <!-- 1 - Dettagli crociera -->
            <div (click)="showDettagliCrocieraToggle()"
                 class="main-title-container col-12 col-sm-12 col-md-12 col-lg-12 cursor-pointer">
              <div class="title">Dettagli Crociera</div>
              <div class="arrow">
                <img *ngIf="!showDettagliCrociera" class="tab-arrow" src="assets/img/icons/down-arrow.svg">
                <img *ngIf="showDettagliCrociera" class="tab-arrow" src="assets/img/icons/up-arrow.svg">
              </div>
            </div>
            <div *ngIf="showDettagliCrociera" class="info-container row no-gutters">
              <div class="info-partenza col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="title">Informazioni partenza</div>
                <bv-info-partenza-row [label]="'Porto di partenza'" [value]="dataResponse.porto" [icon]="'ship'">
                </bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Partenza il'" [value]="trimStr(dataResponse.dataPartenza)"
                                      [icon]="'calendar'"></bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Rientro il'" [value]="trimStr(dataResponse.dataRientro)"
                                      [icon]="'calendar'"></bv-info-partenza-row>
                <bv-info-partenza-row [label]="'Durata'"
                                      [value]="dataResponse.giorni + ' giorni / ' + dataResponse.notti + ' notti'"
                                      [icon]="'bed'">
                </bv-info-partenza-row>
                <bv-info-partenza-row *ngIf="dataResponse.isVoloIncluso"
                                      [label]="'Volo'" [value]="getMsgVoloIncluso(dataResponse.isVoloIncluso)"
                                      [icon]="'plane'"></bv-info-partenza-row>
              </div>
            </div>
            <!-- END OF: 1 - Dettagli crociera -->
          </div>

          <!-- TAB -->
          <mat-tab-group mat-stretch-tabs (selectedTabChange)="setTabContext($event)" selectedIndex="0">
            <mat-tab class="mt-10px mb-10px" label="Itinerario">
              <ng-template mat-tab-label>
                Itinerario
                <img class="tab-svg" src="../../../assets/img/icons/calendar.svg">
              </ng-template>
              <div class="showmoretab" (click)="showTabToggle()">
                <ng-container *ngIf="!showTabContent">
                  <span>Leggi tutto</span>
                  <mat-icon >expand_more</mat-icon>
                </ng-container>

                <ng-container *ngIf="showTabContent">
                  <span>Riduci</span>
                  <mat-icon >expand_less</mat-icon>
                </ng-container>
              </div>
              <div [hidden]="!showTabContent">
                <div class="foto-itinerario">
                  <!--<img src="../../../../assets/img/crociere/itinerario.png" alt="">-->
                  <img [src]="dataResponse.imgMappa" alt="Mappa itinerario">
                </div>
                <table class="itinerario-table">
                  <tr class="crociera-table-header">
                    <th>Giorno</th>
                    <th>Porto</th>
                    <th>Arrivo</th>
                    <th>Partenza</th>
                  </tr>
                  <tr *ngFor="let tappa of dataResponse.itinerario">
                    <td>{{tappa.giorno || '-'}}</td>
                    <td>{{tappa.porto || '-'}}</td>
                    <td>{{tappa.arrivo || '-'}}</td>
                    <td>{{tappa.partenza || '-'}}</td>
                  </tr>
                </table>
                <div class="showlesstab" (click)="showTabToggle()">
                  <span>Riduci</span>
                  <mat-icon >expand_less</mat-icon>
                </div>
              </div>
            </mat-tab>
            <mat-tab class="mt-10px mb-10px" label="La Nave">
              <ng-template mat-tab-label>
                La Nave
                <img class="tab-svg" src="../../../assets/img/icons/ship.svg">
              </ng-template>
              <div class="showmoretab" (click)="showTabToggle()">
                <ng-container *ngIf="!showTabContent">
                  <span>Leggi tutto</span>
                  <mat-icon>expand_more</mat-icon>
                </ng-container>
                <ng-container *ngIf="showTabContent">
                  <span>Riduci</span>
                  <mat-icon >expand_less</mat-icon>
                </ng-container>
              </div>
              <!-- <borsaviaggi-readmore [content]="dataDescPartenza.descrizioneDestinazione"></borsaviaggi-readmore> -->
              <!-- <borsaviaggi-readmore [content]="mockLaNaveDescrizione"></borsaviaggi-readmore> -->
              <div [hidden]="!showTabContent">
                <div class="description-cabina">
                  <h1>La Nave</h1>
                  <!-- {{mockLaNaveDescrizione}} -->
                  {{dataResponse.nave.descrizioneNave}}
                </div>
                <div class="scheda-tecnica-nave">
                  <div class="header-scheda-tecnica">
                    Scheda tecnica
                  </div>
                  <div class="info-nave-container row no-gutters">
                    <div class="info-nave col-12 col-sm-6 col-md-6 col-lg-6">
                      <bv-info-row [label]="'Nave'" [value]="dataResponse.nave.nomeNave"></bv-info-row>
                      <bv-info-row [label]="'Numero di cabine'" [value]="dataResponse.nave.numeroCabine"></bv-info-row>
                      <bv-info-row [label]="'Capacità'" [value]="dataResponse.nave.capacita"></bv-info-row>
                      <bv-info-row [label]="'Larghezza'"
                                   [value]="dataResponse.nave.capacita ? dataResponse.nave.capacita + ' metri' : '-'">
                      </bv-info-row>
                      <bv-info-row [label]="'Peso'"
                                   [value]="dataResponse.nave.peso ? dataResponse.nave.peso + ' tonnellate'  : '-'"></bv-info-row>
                    </div>
                    <div class="info-partenza col-12 col-sm-6 col-md-6 col-lg-6">
                      <bv-info-row [label]="'Anno di costruzione'" [value]="dataResponse.nave.annoDiCostruzione">
                      </bv-info-row>
                      <bv-info-row [label]="'Numero di ponti'" [value]="dataResponse.nave.numeroPonti"></bv-info-row>
                      <bv-info-row [label]="'Numero di equipaggio'" [value]="dataResponse.nave.numeroEquipaggio">
                      </bv-info-row>
                      <bv-info-row [label]="'Lunghezza'"
                                   [value]="dataResponse.nave.lunghezza ? dataResponse.nave.lunghezza + ' metri' : '-'">
                      </bv-info-row>
                      <bv-info-row [label]="'Velocità'"
                                   [value]="dataResponse.nave.velocita ? dataResponse.nave.velocita + ' nodi' : '-'"></bv-info-row>
                    </div>
                  </div>
                </div>
                <div class="showlesstab" (click)="showTabToggle()">
                  <span>Riduci</span>
                  <mat-icon >expand_less</mat-icon>
                </div>
              </div>
            </mat-tab>
            <mat-tab class="mt-10px mb-10px" label="Cabine">
              <ng-template mat-tab-label>
                Cabine
                <img class="tab-svg" src="../../../assets/img/icons/bed.svg">
              </ng-template>
              <div class="showmoretab" (click)="showTabToggle()">
                <ng-container *ngIf="!showTabContent">
                  <span>Leggi tutto</span>
                  <mat-icon >expand_more</mat-icon>
                </ng-container>
                <ng-container *ngIf="showTabContent">
                  <span>Riduci</span>
                  <mat-icon >expand_less</mat-icon>
                </ng-container>

              </div>
              <div [hidden]="!showTabContent">
                <!-- <borsaviaggi-readmore [content]="mockDescrizioneDestinazione"></borsaviaggi-readmore> -->
                <div class="cabina">
                  <div *ngFor="let cabina of dataResponse.cabine" class="row mh-7rem">
                    <div class="col-12 col-sm-7 col-md-7 col-lg-5">
                      <div class="photo-container-cabina bv-alt-grey">
                        <img [src]="cabina.imgCabina[0]" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-sm-5 col-md-5 col-lg-7">
                      <div class="description-cabina">
                        <h2>{{cabina.categoria}}</h2>
                        <p>{{cabina.descrizione}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="showlesstab" (click)="showTabToggle()">
                  <span>Riduci</span>
                  <mat-icon >expand_less</mat-icon>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <!-- END OF: TAB -->

          <!-- END OF: Preventivo crociera -->
          <div class="description-container">
            <!-- 2 - Altre partenze -->
            <div class="main-title-container cursor-pointer col-12 col-sm-12 col-md-12 col-lg-12"
                 (click)="showAltrePartenzeToggle()">
              <div class="title">Altre partenze</div>
              <div class="arrow">
                <img *ngIf="!showAltrePartenze" class="tab-arrow" src="assets/img/icons/down-arrow.svg">
                <img *ngIf="showAltrePartenze" class="tab-arrow" src="assets/img/icons/up-arrow.svg">
              </div>
            </div>
            <div *ngIf="showAltrePartenze" class="info-container row no-gutters altre-partenze">
              <div *ngIf="altrePartenze && altrePartenze.length"
                   class="info-partenza col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row no-gutters">
                  <div *ngFor="let data of altrePartenze"
                       class="col-12 col-sm-5 col-md-5 col-lg-3 altra-partenza-box"
                       [routerLink]="getUrlAltrePartenze(data)">
                    <div class="row no-gutters">
                      <div class="col-6 col-sm-12 col-md-12 col-lg-12">{{parseFullDate(data.dataPartenza)}}</div>
                      <div class="bold col-6 col-sm-12 col-md-12 col-lg-12">da €&nbsp;{{data.prezzo | euroFormat}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="!altrePartenze || !altrePartenze.length" class="mt-20px">
                Non ci sono altre partenze disponibili per questo itinerario!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-xl-4">
        <div class="top-box" [ngClass]="{'top-0-imp':mobileStickyHeader}">
          <div class="top-section-wrapper" *ngIf="!dataResponse.isCrocieraLive">
            <div class="mobile-top-box-container-summary" [ngClass]="{'top-0-imp':mobileStickyHeader}">Riepilogo</div>
            <div class="price-container crociere">
              <div class="price-description text-align-center">
                Prezzo per persona su base doppia<br> a partire da
              </div>
              <div class="cabin-prices-list-container-nolive d-none d-sm-none d-md-block d-lg-block">
                <div *ngFor="let cabina of dataResponse.cabine"
                     class="row price-wrapper-nolive mobile-top-box-container-total-price">
                  <div class="col-6 cabin-description text-align-right">{{cabina.categoria}}</div>
                  <div class="col-6 cabin-price text-align-left">
                    <span>€&nbsp;</span>{{cabina.prezzo | euroFormat}}
                  </div>
                </div>
              </div>
              <!--MOBILE-->
              <div class="cabin-prices-list-container-nolive d-block d-sm-block d-md-none d-lg-none">
                <div class="price-wrapper-nolive mobile-top-box-container-total-price"
                     [ngClass]="{'top-0-imp':mobileStickyHeader}">
                  <div>
                    <div class="cabin-description">Prezzo per persona</div>
                  </div>
                  <div class="cabin-price">
                    {{cabinaPrezzoMinimo | euroFormat}}<span>€&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modifica mobile-top-box-container-edit bv-dark-red-bgc"
                 [ngClass]="{'top-0-imp':mobileStickyHeader}"
                 (click)="onClickRichiediPreventivoBtn()"
                 *ngIf="isMobileDevice">
              <span class="fs-1_1rem">Richiedi un preventivo</span>
            </div>
            <a class="procedi-link" (click)="onClickRichiediPreventivoBtn()" *ngIf="!isMobileDevice">
              <div class="btn-top-box cursor-pointer bv-dark-red-bgc">
                Richiedi un preventivo
              </div>
            </a>
          </div>
          <div class="top-section-wrapper" *ngIf="dataResponse.isCrocieraLive">
            <div class="mobile-top-box-container-summary" *ngIf="!isMobileDevice">
              {{preventivoRq.cabina === '' ? 'Prezzo per persona su base doppia' : 'Prezzo Finito per ' + preventivoRq.numeroPasseggeri + ' persone'}}
            </div>
            <mat-accordion *ngIf="preventivoRq.cabina !== ''">
              <mat-expansion-panel class="camere-expansion-panel bg-black pt-3">
                <mat-expansion-panel-header>
                  <mat-panel-description class="color-white pl-45px">
                    <span class="font-weight-bold text-uppercase">{{preventivoRq.cabina}}</span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row color-white fs-small">
                  <span class="col-12">{{preventivoRq.numeroPasseggeri}} Passeggeri</span>
                  <div class="col-12" *ngIf="preventivoRq.cabina !== '' && !isMobileDevice">
                    Età:
                    <span *ngFor="let item of preventivoRq.etaPasseggeri; let i = index">
                      {{item}}
                      <span *ngIf="i != preventivoRq.etaPasseggeri.length - 1">,</span>
                    </span>
                  </div>
              </div>
              </mat-expansion-panel>
            </mat-accordion>
            <div class="price-container crociere">
              <div class="cabin-prices-list-container d-none d-sm-none d-md-block d-lg-block">
                <div class="price-wrapper mobile-top-box-container-total-price">
                  <div class="col-12 cabin-price text-align-center py-1" *ngIf="preventivoRq.cabina === ''">
                    <span class="fs-16px">da</span> {{dataResponse.costoMinimo | euroFormat}}<span class="fs-16px">€</span>
                  </div>
                  <div class="col-12 cabin-price text-align-center py-1" *ngIf="preventivoRq.cabina !== ''">
                    {{preventivoRq.costoCabina | euroFormat}}<span class="fs-16px">€</span>
                  </div>
                </div>
              </div>
              <!--MOBILE-->
              <div class="cabin-prices-list-container d-block d-sm-block d-md-none d-lg-none">
                <div class="price-wrapper mobile-top-box-container-total-price"
                     [ngClass]="{'top-0-imp':mobileStickyHeader}">
                  <div>
                    <div class="cabin-description">
                      {{preventivoRq.cabina === '' ? 'Prezzo per persona su base doppia' : 'Prezzo Finito per ' + preventivoRq.numeroPasseggeri + ' persone'}}
                    </div>
                  </div>
                  <div class="cabin-price" *ngIf="preventivoRq.cabina === ''">
                    <span class="fs-16px">da</span> {{dataResponse.costoMinimo | euroFormat}}<span class="fs-16px">€</span>
                  </div>
                  <div class="cabin-price" *ngIf="preventivoRq.cabina !== ''">
                    {{preventivoRq.costoCabina | euroFormat}}<span>€&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="modifica mobile-top-box-container-summary"
                 [ngClass]="{'top-0-imp':mobileStickyHeader}"
                 (click)="openModificaOccupazioneCrocieraModal()">
              <div class="icon">
                <mat-icon class="color-azzurro-crociera">edit</mat-icon>
              </div>
              <span class="modifica-personalizza-label">{{preventivoRq.cabina === '' ? 'Scegli la cabina' : 'Modifica cabina'}}</span>
            </div>
            <button class="modifica mobile-top-box-container-edit bv-dark-red-bgc button-style"
                 [ngClass]="{'top-0-imp':mobileStickyHeader}"
                 (click)="savePreventivoCrocieraLive()"
                 *ngIf="isMobileDevice && preventivoRq.cabina !== ''">
              <span class="fs-1_1rem">Procedi</span>
            </button>
            <button class="modifica mobile-top-box-container-edit button-style-disabled"
                    [ngClass]="{'top-0-imp':mobileStickyHeader}"
                    (click)="savePreventivoCrocieraLive()"
                    *ngIf="isMobileDevice && preventivoRq.cabina === ''" disabled>
              <span class="fs-1_1rem">Procedi</span>
            </button>
            <a class="procedi-link" (click)="savePreventivoCrocieraLive()" *ngIf="!isMobileDevice && preventivoRq.cabina !== ''">
              <div class="btn-top-box cursor-pointer bv-dark-red-bgc">
                Procedi
              </div>
            </a>
          </div>

          <div class="tab-wrapper">
            <div class="tab">
              <div class="tab-label mt-5px">
                TI SERVE AIUTO SULL'OFFERTA?
              </div>
              <div class="tab-content d-none d-sm-block">
                <div class="col"><img width="92px" src="../../../assets/img/home/call-center.png" alt="icona vit"></div>
                <div class="col text">
                  <div>Chiamaci al <span class="bold">06 5587667</span></div>
                  <div>o <span class="bold">TI RICHIAMIAMO NOI!</span></div>
                </div>
                <form [formGroup]="formGroupRichiestaInfo">
                  <div class="form-group row pl-15px pr-15px">
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Nome</mat-label>
                      <input matInput placeholder="Nome" required name="nome" formControlName="nome"
                             class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.nome"
                                     [formControlParam]="formGroupRichiestaInfo.controls.nome"></bv-form-error>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Cognome</mat-label>
                      <input matInput placeholder="Cognome" required name="cognome" formControlName="cognome"
                             class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.cognome"
                                     [formControlParam]="formGroupRichiestaInfo.controls.cognome"></bv-form-error>
                    </mat-form-field>

                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Cellulare</mat-label>
                      <input matInput placeholder="Cellulare" required type="number" name="cellulare"
                             formControlName="cellulare" class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.cellulare"
                                     [formControlParam]="formGroupRichiestaInfo.controls.cellulare"></bv-form-error>
                    </mat-form-field>

                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" type="email" name="email" formControlName="email"
                             class="mb-8px-impo">
                      <bv-form-error [formErrorMessage]="validation_messages.email"
                                     [formControlParam]="formGroupRichiestaInfo.controls.email"></bv-form-error>
                    </mat-form-field>

                    <div>
                      <mat-checkbox formControlName="privacy" required class="col-12 mb-1em">
                        Ho letto ed accetto l'<a routerLink="/informativa-privacy">Informativa Privacy*</a>
                        <bv-form-error class="text-align-left"
                                       [formErrorMessage]="validation_messages.privacy"
                                       [formControlParam]="formGroupRichiestaInfo.controls.privacy">
                        </bv-form-error>
                      </mat-checkbox>
                    </div>
                  </div>
                </form>
                <div class="btn cursor-pointer mt-0 bv-cyan-bgc" (click)="richiediInfo(formGroupRichiestaInfo.valid)">
                  Richiamami!!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
