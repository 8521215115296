<div class="header-modal">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <h2 mat-dialog-title>{{errorData.title}}</h2>
</div>
<mat-dialog-content>
  <borsaviaggi-loader [(show)]="loading" typeMode='fullScreen'></borsaviaggi-loader>
  <!-- Preventivo crociera -->
  <div class="description-container">
    <div class="info-container row no-gutters">
      <div class="dettagli col-12">
        <!-- SELECT NUMERO CABINE -->
        <div class="row no-gutters pt-1rem pb-1rem">
          <div class="col-12 col-sm-6">
            Seleziona il numero di cabine :
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-select placeholder="Numero di cabine"
                          (selectionChange)="onChangeCabineDaPrenotare(cabineDaPrenotareNum)"
                          [(ngModel)]="cabineDaPrenotareNum"
                          name="cabineDaPrenotare">
                <mat-option *ngFor="let num of cabinePrenotabiliArr" [value]="num.value">
                  {{num.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- DETTAGLI SISTEMAZIONE -->
        <div *ngIf="cabineDaPrenotare" class="row">
          <div class="col-12">
            <span class="fs-1_2rem bold p-0 m-0">Dettagli sistemazione</span>
          </div>
        </div>
        <form [formGroup]="formPreventivoCrociera">

          <ng-container *ngFor="let cabina of cabineDaPrenotare; let i = index">
            <hr>
            <div class="row pt-1rem pb-1rem">
              <div class="col-12 bold cyan">
                <span class="fs-1_1rem bold p-0 m-0">Cabina {{i + 1}}</span>
              </div>
            </div>
            <div class="row pb-1rem">
              <div class="col-12">
                <div class="row no-gutters">
                  <mat-radio-group formControlName="idTipoCabina_{{i+1}}">
                    <mat-radio-button class="mr-1rem mb-5px" *ngFor="let cabina of dettaglioData.dataResponse.cabine"
                                      [value]="cabina.idCategoria">
                      <span *ngIf="cabina.idCategoria === 1">Cabina interna</span>
                      <span *ngIf="cabina.idCategoria === 2">Cabina esterna</span>
                      <span *ngIf="cabina.idCategoria === 3">Cabina con balcone</span>
                      <span *ngIf="cabina.idCategoria === 4">Suite</span>
                      <div class="price bold">{{cabina.prezzo | euroFormat}}&nbsp;€</div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div class="row pt-1rem pb-1rem">
              <div class="col-12 bold cyan">
                <span class="fs-1_1rem bold p-0 m-0">Passeggeri</span>
              </div>
            </div>
            <!-- NUM ADULTI + NUM BAMBINI -->
            <div class="row mb-1rem">
              <div class="col-6">
                <label class="bold row no-gutters">Adulti</label>
                <mat-form-field appearance="outline" class="row no-gutters">
                  <mat-select *ngIf="numAdultiArr"
                              formControlName="numAdulti_{{i+1}}"
                              placeholder="N° di Adulti"
                              (selectionChange)="onChangeNumeroAdulti(i)">
                    <mat-option *ngFor="let num of numAdultiArr" [value]="num.value">
                      {{num.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <label class="bold row no-gutters">Bambini</label>
                <mat-form-field appearance="outline" class="row no-gutters">
                  <mat-select formControlName="numBambini_{{i+1}}"
                              *ngIf="numBambiniArr" placeholder="N° di Bambini"
                              (selectionChange)="onChangeNumeroBambini(i)">
                    <mat-option *ngFor="let num of numBambiniArr" [value]="num.value">
                      {{num.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6" *ngFor="let indexAdulto of [].constructor(formPreventivoCrociera.controls['numAdulti_'+(i+1)].value); let indiceAdulto = index ">
                <label class="bold row no-gutters">Età Adulto {{indiceAdulto+1}}</label>
                <mat-form-field appearance="outline" class="row no-gutters">
                  <mat-select formControlName="etaAdulto{{indiceAdulto+1}}_{{i+1}}"
                              placeholder="Età Adulto" required>
                    <mat-option *ngFor="let num of etaAdultiArr" [value]="num.value">
                      {{num.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6" *ngIf="selectedBambini[i] && selectedBambini[i]> 0">
                <label class="bold row no-gutters">Età Bambino 1</label>
                <mat-form-field appearance="outline" class="row no-gutters">
                  <mat-select formControlName="etaBambiniUno_{{i+1}}"
                              placeholder="Età Bambino" required>
                    <mat-option *ngFor="let num of etaBambiniArr" [value]="num.value">
                      {{num.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6" *ngIf="selectedBambini[i] && selectedBambini[i] > 1">
                <label class="bold row no-gutters">Età Bambino 2</label>
                <mat-form-field appearance="outline" class="row no-gutters">
                  <mat-select formControlName="etaBambiniDue_{{i+1}}"
                              placeholder="Età Bambino"
                              required>
                    <mat-option *ngFor="let num of etaBambiniArr" [value]="num.value">
                      {{num.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <!-- AEROPORTO -->
          <div *ngIf="dettaglioData.dataResponse.isVoloIncluso" class="row pt-3rem">
            <div class="col-12">
              <span class="fs-1_2rem bold p-0 m-0">Scegli l'aeroporto</span>
            </div>
            <div class="col-12">
              <mat-form-field class="full-width" appearance="outline">
                <!-- <mat-icon matPrefix>location_on</mat-icon> -->
                <mat-label>Aeroporto</mat-label>
                <mat-select required placeholder="Aeroporto" formControlName="volo">
                  <mat-option [value]="''">---</mat-option>
                  <mat-option *ngFor="let volo of dettaglioData.dataResponse.voli" [value]="volo.idVolo">
                    {{volo.tipoVolo + ' ' + volo.nome + ' - ' + volo.costo + ' €'}}
                  </mat-option>
                </mat-select>
                <!-- <bv-form-error [formControlParam]="regione"></bv-form-error> -->
              </mat-form-field>
            </div>
          </div>
        </form>
        <!-- END OF: INTESTATARIO -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <div class="row mobile-btn-wrapper full-width">
    <div (click)="salvaModificheAndCloseModal()" class="col-6 btn-main-centered background-blue-borsaviaggi">
      Procedi
      <mat-icon class="ml-5px">navigate_next</mat-icon>
    </div>
  </div>
</mat-dialog-actions>
