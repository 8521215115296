<div class="full-width">
  <div class="container mt-20px mb-20px">
    <div class="row">
      <div class="col-sm-4 col-xs-12">
        <mat-card class=" border-grey-1px">
          <mat-card-content class=" style-card-action border-b-grey-1px">
            <div class="row profile-img-container">
              <div class="display menu">
                <button mat-button [matMenuTriggerFor]="menu">
                  <span class="material-icons">
                    more_vert
                  </span>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="goToPage($event,'dati')">Dati Personali</button>
                 <!-- <button mat-menu-item (click)="goToPage($event,'prenotazioni')">Lista Prenotazioni</button>-->
                  <button mat-menu-item (click)="goToPage($event,'desideri')">Lista Desideri</button>
                  <button mat-menu-item (click)="actionLogout()">Logout</button>
                </mat-menu>
              </div>
              <borsaviaggi-upload-image-profile [isAreaPersonale]="true"></borsaviaggi-upload-image-profile>
            </div>
            <div class="row d-flex align-items-center">
              <label class="subtitle pl-15px">{{user?.userInfo?.nome}} {{user?.userInfo?.cognome}}</label>
              <mat-card-actions id='dati_personali' class="style-card-action matcard cursor-pointer"
                                (click)="goToPage($event,'dati')">

                <button mat-mini-fab class="button-mini" matTooltip="Modifica i tuoi dati personali">
                  <mat-icon>create</mat-icon>
                </button>
              </mat-card-actions>
            </div>
            <mat-card class="mt-5px  background-cashback d-none d-sm-flex">
              <mat-card-content class="expanded-icon">
                <label class="subtitle white-text">Il tuo cashback:</label>
                <br>
                <label class="subtitle white-text"> € {{user ? totaleCashback : '0,00' }}</label>
              </mat-card-content>
            </mat-card>
            <div class="row mobile-prime-background d-sm-none mt-1rem fw-bold"
                 [ngClass]="expandBanner || !isPrime? 'expanded-prime': 'not-expanded-prime'" (click)="expandPrime()">
              <div class="mobile-prime-icon d-flex"
                   [ngClass]="expandBanner || !isPrime? 'expanded-icon': 'not-expanded-icon'"
                   [@expandHide]="expandBanner || !isPrime? 'expanded' : 'hidden'"> {{expandedText}} </div>
            </div>
          </mat-card-content>

          <mat-card-actions id="lista_desideri" class="style-card-action  border-b-grey-1px matcard cursor-pointer"
                            highlight [highlightClass]="'bg-hover'"
                            (click)="goToPage($event,'desideri')">
            <label class="fs-1_2rem fw-bold pt-15px cursor-pointer">Lista desideri</label>
          </mat-card-actions>
<!--          <mat-card-actions id="lista_prenotazioni" class="style-card-action border-b-grey-1px matcard cursor-pointer"
                            highlight
                            [highlightClass]="'bg-hover'" (click)="goToPage($event,'prenotazioni')">
            <label class="fs-1_2rem fw-bold pt-15px cursor-pointer">Lista prenotazioni</label>
          </mat-card-actions>-->
          <mat-card-actions id="newsletter" class="style-card-action border-b-grey-1px matcard cursor-pointer"
                            highlight
                            [highlightClass]="'bg-hover'" (click)="goToPage($event,'newsletter')">
            <label class="fs-1_2rem fw-bold pt-15px cursor-pointer">Newsletter</label>
          </mat-card-actions>
          <mat-card-actions class="style-card-action matcard cursor-pointer" highlight [highlightClass]="'bg-hover'"
                            (click)="actionLogout()">
            <label class="fs-1_2rem fw-bold pt-15px cursor-pointer">Logout</label>
          </mat-card-actions>
        </mat-card>
        <!--<mat-card class="mt-5px  background-cashback d-none d-sm-flex">
          <mat-card-content class="expanded-icon">
            <label class="subtitle white-text">Il tuo cashback:</label>
            <br>
            <label class="subtitle white-text"> € {{user ? getTotaleCashback() : '0,00' }}</label>
          </mat-card-content>
        </mat-card>-->
      </div>
      <div class="col-sm-8 col-xs-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
