import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CacheService} from "../../../services/common/cache.service";
import {ModalInterface} from "../../../interface/modal.interface";
import {Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {LoginValidator} from "../../../services/validator/login.validator";
import {UserService} from "../../../services/gate/user.service";
import {AuthService} from "../../../services/common/auth.service";
import {RegistrazioneModalComponent} from "../registrazione-modal/registrazione-modal.component";
import {RecuperoPasswordModalComponent} from "../recupero-password-modal/recupero-password-modal.component";

@Component({
  selector: 'borsaviaggi-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  public loginData;
  public loginForm: FormGroup = LoginValidator.formLoginValidator;
  public loading: boolean = false;
  public errorMessage;
  public showAlreadyPrime;
  public utenteData;
  public verifyEmail;

  constructor(public dialogRef: MatDialogRef<LoginModalComponent>,
              private cacheSrv: CacheService,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<LoginModalComponent>,
              private router: Router,
              private userSrv: UserService,
              private authSrv: AuthService,
              private dialog: MatDialog) {
    if (Object.keys(data).length) {
      this.loginData = data;
    }
    this.data.dataParam && this.data.dataParam.verifyEmail ?
      this.verifyEmail = true : this.verifyEmail = false;
  }

  ngOnInit() {

  }

  public actionSignUp() {
    if (!this.showAlreadyPrime) {
      this.dialogRef.close();
    }
    this.openRegistrazioneModal({}, (data) => {
      if (data) {
        this.verifyEmail = true;
      }
    });
  }

  public actionRecupero() {
    this.dialogRef.close();
    this.openRecuperoModal();
  }

  public closeModal() {
    this.dialogRef.close(this.utenteData);
  }

  public login(valid) {
    if (valid) {
      this.loading = true;
      let dataParam = {
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value
      };
      this.userSrv.login(dataParam).then(
        (response) => {
          if (response && response.body.data) {
            this.loading = false;
            if (response.body.data.isPrime && this.data.dataParam.activatePrime) {
              this.showAlreadyPrime = true;
              this.utenteData = response.body.data;
            } else if (!response.body.data.isPrime && this.data.dataParam.activatePrime) {
              //this.router.navigate(['abbonamento-prime']);
              document.location.href = 'https://www.borsaviaggi.it/Contratti/Pos.aspx?from=abbonamento-prime&IdUtente=' + response.body.data.idUtente;
              this.dialogRef.close(response.body.data);
            } else {
              this.dialogRef.close(response.body.data);
            }
          } else {
            this.errorMessage = "Email o Password errati!";
            this.loading = false;
          }
        },
        (error) => {
          this.errorMessage = error;
          this.loading = false;
        }
      )
    }
    else {
      for (let formControlName in this.loginForm.controls) {
        this.loginForm.get(formControlName).markAsTouched();
        this.loginForm.get(formControlName).markAsDirty();
      }
    }
  }

  private openRegistrazioneModal(paramData, callBackAction?: Function) {
    setTimeout(()=>{
      this.hideShowChatElement(true);
    },500);

    let dataModal: ModalInterface<RegistrazioneModalComponent> = {
      title: "Registrati",
      body_message: "",
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(RegistrazioneModalComponent, {
      panelClass: ['centered-dialog','txt-center'],
      disableClose: false,
      width: '750px',
      data: dataModal,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 1 && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  private openRecuperoModal() {
    setTimeout(() => {
      this.hideShowChatElement(true);
    }, 500);

    let dialogRef = this.dialog.open(RecuperoPasswordModalComponent, {
      panelClass: ['centered-dialog', 'txt-center', 'max-width-94vw'],
        disableClose: false,
        width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.hideShowChatElement(false);
    });
  }

  private hideShowChatElement(hide) {
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = hide ? 'none' : 'block';
    }
  }
  public returnToLogin() {
    this.verifyEmail = false;
  }
}
