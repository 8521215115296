import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {NavItemInterface} from './navitem.interface';
import {NavigationEnd, Router} from "@angular/router";
import {TYPE_MODE} from "./type-mode-menu.const";
import {HttpClient} from "@angular/common/http";
import {NavigationService} from "./navigation.service";
import {AuthService} from "../../services/common/auth.service";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {fadeAnimation} from "../../animations/fade.animations";
import {DeviceDetectorService} from "ngx-device-detector";
import {NoteLegaliModalComponent} from "../modal/note-legali-modal/note-legali-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'borsaviaggi-navigation',
  templateUrl: './navigation.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeAnimation],
  styleUrls: ['./navigation.component.scss'],
  host: {'(window:resize)': 'onResize($event)'}
})
export class NavigationComponent implements OnInit {
  @Input('sideNaveCtrl') public sideNavCtrl: MatSidenav;
  @Input('configMode') public configMode: "desktop" | "mobile";
  @Output() mobileModeChange = new EventEmitter();

  public isOpen: boolean = false;
  public isMobileMode: boolean = false;
  public typeModeMenu: string;
  public isVisibleMenu: boolean = true;
  public static mainMenuItems: NavItemInterface[] = [];
  private allMenuItems: NavItemInterface[] = [];
  private idTimeoutSubMenu;
  private static menuIsInCache: boolean;

  constructor(private router: Router,
              private http: HttpClient,
              private deviceService: DeviceDetectorService,
              private navServ: NavigationService,
              public authSrv: AuthService,
              public dialog: MatDialog) {
    this.isMobileMode = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.TABLET_VERTICAL;
    this.typeModeMenu = TYPE_MODE.SLIDE;
    if (!NavigationComponent.menuIsInCache) {
      this.getMenuItems();
    }

    this.navServ.visibleState.subscribe((state: boolean) => {
      this.isVisibleMenu = state;
      if (!this.isVisibleMenu) {
        this.isOpen = false;
        this.sideNavCtrl.close();
      }
    });
    this.navServ.openState.subscribe((state: boolean) => {
      this.isOpen = state;
      if (this.isOpen) {
        this.sideNavCtrl.open();
      } else {
        this.sideNavCtrl.close();
      }
    });
    this.navServ.reloadMenuObservr.subscribe(() => {
      this.reloadMenuForUser();
    });
    router.events.subscribe(event => {
        /*Close ritardato per migliorare l'animazione di chiusura del menu mobile*/
        if (event instanceof NavigationEnd && this.isMobileMode && this.sideNavCtrl && this.sideNavCtrl.opened) {
          setTimeout(() => {
            this.isOpen = false;
            this.sideNavCtrl.close();
          }, 0);
        }
      }
    );
  }

  ngOnInit() {
    this.isOpen = !this.isMobileMode;
  }

  public mouseEnterMacroItemMenu(event, item: NavItemInterface) {
    clearTimeout(this.idTimeoutSubMenu);
    NavigationComponent.mainMenuItems.map((currItem) => {
      if (currItem !== item) {
        currItem.showSubMenu = false;
      }
    });
    item.showSubMenu = true;
  }

  public get itemMenu() {
    return NavigationComponent.mainMenuItems;
  }

  public mouseLeaveMacroItemMenu(event, item: NavItemInterface) {
    clearTimeout(this.idTimeoutSubMenu);
    this.idTimeoutSubMenu = setTimeout(() => {
      item.showSubMenu = false;
    }, 500)
  }

  public actionNavigate(paramItemPath) {
    if (paramItemPath) {
      if (paramItemPath.path) {
        this.router.navigate([paramItemPath.path])
      } else if (paramItemPath.link) {
        window.open(paramItemPath.link, "_blank");
      }

    }
  }

  private checkAndInsertItem(paramItem: NavItemInterface) {
    if (paramItem) {
      let deviceType = this.deviceService.isMobile() ? "mobile" : "desktop";
      if (paramItem.device) {
        if (paramItem.device.indexOf(deviceType) !== -1) {
          NavigationComponent.mainMenuItems.push(paramItem);
        }
      } else {
        NavigationComponent.mainMenuItems.push(paramItem);
      }
    }
  }

  private getMenuItems() {
    NavigationComponent.menuIsInCache = true;
    this.http.get('/config/menu-config.json?timestamp=' + new Date().getTime()).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.allMenuItems = data;
          data.map((dataItem: NavItemInterface) => this.checkAndInsertItem(dataItem));
        }
      });
  }

  private reloadMenuForUser() {
    NavigationComponent.mainMenuItems.splice(0, NavigationComponent.mainMenuItems.length);
    this.allMenuItems.map((dataItem: NavItemInterface) => this.checkAndInsertItem(dataItem));
  }


  onResize(event) {
    let tempStartMode = this.isMobileMode;
    if (event.target.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE || event.target.innerWidth <= RESPONSIVE_VALUE.TABLET_VERTICAL) {
      this.isMobileMode = true;
    } else {
      this.isMobileMode = false;
    }
  }

  public toggleMenu() {
    if (this.sideNavCtrl.opened) {
      this.sideNavCtrl.close().then(
        () => {
          this.navServ.hide();
        }
      )
    } else {
      this.navServ.show();
      this.sideNavCtrl.open();
    }
  }

  openDialogNoteLegali(): void {
    const dialogRef = this.dialog.open(NoteLegaliModalComponent, {
      width: '450px',
      data: { }
    });
  }

}
