import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {ContainerDestinazioniService} from "../container-destinazioni.service";


@Injectable({
  providedIn: 'root'
})
export class DestinazioniItaliaResolveService {
  constructor(private containerSrv: ContainerDestinazioniService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    return this.containerSrv.getViaggiItalia()
      .catch(err => {
        return err;
      });
  }

}
