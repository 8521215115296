import {Injectable} from "@angular/core";

@Injectable()
export class UtilsStringService {
  public replaceAllSpaces(str: string, strToReplace): string {
    return str.replace(/ /g, strToReplace);
  }

  public static replaceAllSpaces(str: string, strToReplace): string {
    return str.replace(/ /g, strToReplace);
  }

  public static toTitleCase(str: string): string {
    let ret = str.toLowerCase().split(' ');
    for (let i = 0; i < ret.length; i++) {
      ret[i] = ret[i].charAt(0).toUpperCase() + ret[i].slice(1);
    }
    return ret.join(' ');
  }


  public static strToBool(str) {
    return str && str === "true" ? true : str && str === "false" ? null : null;
  }


  public static removeSpecialChar(parmStr: string) {
    return parmStr ? parmStr.toString().trim()
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      .replace(/\s/g, "-")
      .replace(/--/g, "-") : "";
  }


  public static makeIdArray(idArr: string) {
    let res = [];
    let arr = [];
    if (idArr.indexOf(",") > -1) {
      arr = idArr.split(',');
    } else {
      arr = [idArr]
    }
    arr.forEach((id) => {
      res.push(id)
    });
    return res;
  }

  public static getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public static makeDestinazioniArray(idArr: string, paramArrIsNazione: string) {
    let res = [];
    let arr = [];
    let arrIsNazione = [];
    if (idArr) {
      if (idArr.indexOf(",") > -1) {
        arr = idArr.split(',');
        arrIsNazione = paramArrIsNazione && paramArrIsNazione.split(',');
      } else {
        arr = [idArr];
        arrIsNazione = [paramArrIsNazione];
      }
      arr.forEach((id, index) => {
        res.push({
          idDestinazione: id,
          isNazione: arrIsNazione[index]
        })
      });
    }

    return res;
  }
}
