<div class="position-relative full-width carousel-slider">
  <div class="carousel">
    <div class="carousel-container car-1 row margin-0" [ngStyle]="dynamicStyle">
      <a *ngFor="let partner of listPartner" [title]="partner.nomeTourOperator +' '+partner.title"
         class="d-flex flex-wrap align-items-center"
         lazy-load-images
         [routerLink]="'/'+makeUrlSeoHref(partner)">
        <img [src]="partner.urlLogo" [title]="partner.nomeTourOperator">
      </a>
    </div>
    <div class="carousel-container car-2 row margin-0" [ngStyle]="dynamicStyle">
      <a *ngFor="let partner of listPartner" [title]="partner.nomeTourOperator +' '+partner.title"
         class="d-flex flex-wrap align-items-center"
         lazy-load-images
         [routerLink]="'/'+makeUrlSeoHref(partner)">
        <img [src]="partner.urlLogo" [title]="partner.nomeTourOperator">
      </a>
    </div>
  </div>
</div>
