import {Injectable} from '@angular/core';
import {AppConfig} from '../../../app.config';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable()
export class GenericGateService {
  constructor(private config: AppConfig, private http: HttpClient, private userAgentSrv: DeviceDetectorService) {

  }

  public doHttpRequest(paramMethod: 'POST' | 'PUT' | 'GET' | 'DELETE', urlEndpoint: string,
                       dataParam?: Object, headers?: Array<{ key, value }>,
                       paramResponseType?: 'arraybuffer' | 'blob' | 'json' | 'text'): Promise<any> {
    const host: string = this.config.getConfig('host');
    let uri = host + urlEndpoint;
    if (this.userAgentSrv.browser.toUpperCase() === 'IE') {
      if (uri.indexOf('?') !== -1) {
        uri += '&cache=' + new Date().getTime();
      } else {
        uri += '?cache=' + new Date().getTime();
      }

    }
    if (environment.mock) {
      uri += '.json';
      paramMethod = 'GET';
    }

    let httpRequest = new HttpRequest(paramMethod, uri, dataParam, {responseType: (paramResponseType || 'json')});

    if (headers && headers.length > 0) {
      headers.map((item) => {
        httpRequest = httpRequest.clone({
          headers: httpRequest.headers.set(item.key, item.value)
        });
      });
    } else {
      const headers = new HttpHeaders({
        "APIKey": "B0rs@V1@gg1_202024F3bbr@10;202005041453584662",
        'Content-Type': 'application/json'
      });

      httpRequest = httpRequest.clone({headers});
    }
    return new Promise((resolve, reject) => {
      const rq = this.http.request(httpRequest);
      if (rq) {
        rq.toPromise().then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
      }

    });
  }
}
