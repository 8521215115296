export const RESPONSIVE_VALUE: any = {
  MOBILE_DEVICE: 768,
  TABLET_VERTICAL: 820,
  TABLET: 1200
};
export const CACHE_KEY: any = {
  PARTNER_LIST: "PARTNER_LIST",
  CROCIERE_PORTI_LIST: "CROCIERE_PORTI_LIST"
};

