<!-- <div class="col-4"> -->
<div class="card" [ngClass]="{'vertical': config.vertical, 'card-ridotta': reduceCard}">
  <div class="row no-gutters">
    <!-- ex row -->
    <div class="main-section"
         [ngClass]="{'col-xl-9 col-md-9 col-sm-12 col-xs-12': !config.vertical, 'col-xl-12 col-md-12 col-sm-12 col-xs-12': config.vertical}">
      <div class="row no-gutters">
        <!--flex ex row -->
        <div class="col-12">
          <div class="info-section">
            <div class="row no-gutters">
              <div *ngIf="photoArray"
                   class="container-photo"
                   [ngClass]="{'col-xl-6 col-md-6 col-sm-12 col-xs-12': !config.vertical, 'col-xl-12 col-md-12 col-sm-12 col-xs-12': config.vertical}">

                <div class="carousel-photo">
                  <mat-carousel
                    color="accent"
                    orientation="ltr"
                    slides="12"
                    proportion="55"
                    [autoplay]="false"
                    [loop]="false"
                    [hideArrows]="true"
                    [hideIndicators]="false"
                    [useKeyboard]="!isMobileDevice"
                    [useMouseWheel]="!isMobileDevice"
                  >
                    <mat-carousel-slide
                      #matCarouselSlide
                      *ngFor="let photo of photoArray"
                      [image]="webPSupport && photo.fotoWebp && !config.isListaDesideri? photo.fotoWebp : photo.foto"
                      [hideOverlay]="true"
                    ></mat-carousel-slide>
                  </mat-carousel>
                </div>

              </div>

              <div
                [ngClass]="{'col-xl-6 col-md-6 col-sm-12 col-xs-12': !config.vertical, 'col-xl-12 col-md-12 col-sm-12 col-xs-12': config.vertical}">
                <div class="info">
                  <a target="_blank" [routerLink]="linkContinua"
                     class="display-block title"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     [matTooltip]="data.title" *ngIf="config.isVacanze">
                    <!-- Wady Lahmi Azur Beach Resort -->
                    {{data.title | titlecase}}
                  </a>
                  <div (click)="openMapsModal(data.nomeStruttura, data.destinazione)"
                     class="display-block location cursor-pointer"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     *ngIf="config.isVacanze">
                    <img class="icon" src="assets/img/icons/result-card/placeholder.svg">
                    <!-- &nbsp;&nbsp; -->
                    <div class="label">
                      <!-- Egitto -->
                      {{data.subtitle}}
                    </div>
                  </div>

                  <div (click)="openMapsModal(data.nomeStruttura, data.destinazione)" [title]="data.title" class="title display-block cursor-pointer"
                     *ngIf="config.isListaDesideri">
                    <!-- Wady Lahmi Azur Beach Resort -->
                    {{data.destinazione | titlecase}}
                  </div>
                  <div (click)="openMapsModal(data.nomeStruttura, data.destinazione)"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="location display-block cursor-pointer" *ngIf="config.isListaDesideri">
                    <img class="icon" src="assets/img/icons/result-card/placeholder.svg">
                    <!-- &nbsp;&nbsp; -->
                    <div class="label">
                      <!-- Egitto -->
                      {{data.titolo}}
                    </div>
                  </div>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="location display-block" *ngIf="config.isListaPrenotazioni">
                    <mat-icon class="mr-5px">room</mat-icon>
                    <!-- &nbsp;&nbsp; -->
                    <div class="label vertical-align-super col-2">
                      <!-- Egitto -->
                      {{data.nome}}
                    </div>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="location col-12 padding-0 margin-0 display-block"
                     *ngIf="config.isListaPrenotazioni">
                    <mat-icon class="mr-5px vertical-align-sub">flight_takeoff</mat-icon>
                    <span class="mr-5px col-2">Data partenza:</span>
                    <span class="label ">
                      <!-- Egitto -->
                      {{data.dataPartenza | date:'dd-MM-yyyy'}}
                    </span>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="location col-12 padding-0 margin-0 display-block"
                     *ngIf="config.isListaPrenotazioni">
                    <mat-icon class="mr-5px vertical-align-sub">flight_land</mat-icon>
                    <span class="mr-5px col-2">Data ritorno:</span>
                    <span class="label ">
                      <!-- Egitto -->
                      {{data.dataRientro | date:'dd-MM-yyyy'}}
                    </span>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="location col-12 padding-0 margin-0 display-block"
                     *ngIf="config.isListaPrenotazioni">
                    <mat-icon class="mr-5px vertical-align-sub">receipt</mat-icon>
                    <span class="mr-5px col-2">N° contratto:</span>
                    <span class="label  ">
                      <!-- Egitto -->
                      {{data.idContratto}}
                    </span>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="location col-12 padding-0 margin-0 display-block"
                     *ngIf="config.isListaPrenotazioni">
                    <mat-icon class="mr-5px vertical-align-sub">bookmark</mat-icon>
                    <span class="mr-5px col-2">Stato:</span>
                    <span class="label ">
                      <!-- Egitto -->
                      {{data.statusContratto | titlecase}}
                    </span>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                     class="details-container row" *ngIf="config.isVacanze">
                    <div class="detail col-6 col-md-12">
                      <mat-icon *ngIf="data.nameIconDeparturePlace"
                                class="mr-5px vertical-align-sub">{{data.nameIconDeparturePlace}}</mat-icon>
                      <div class="label">
                        {{data.luogoPartenza}}
                        <!-- Roma -->
                      </div>
                    </div>
                    <div class="detail col-6 col-md-12">
                      <!-- <div class="icon">000</div> -->
                      <img class="icon" src="assets/img/icons/result-card/calendar.svg">
                      <div class="label">
                        {{parseFullDate(data.dataPartenza)}}
                        <!-- 15/02/2020 -->
                      </div>
                    </div>
                    <div class="detail col-6 col-md-12">
                      <!-- <div class="icon">000</div> -->
                      <img class="icon" src="assets/img/icons/result-card/bed.svg">
                      <div class="label">
                        {{data.notti}} <span>notti</span>
                        <!-- 7 notti -->
                      </div>
                    </div>
                    <div class="detail col-6 col-md-12">
                      <!-- <div class="icon">000</div> -->
                      <img class="icon" src="assets/img/icons/result-card/fork.svg">
                      <div class="label">
                        {{data.tipoOfferta}}
                        <!-- Soft All Inclusive -->
                      </div>
                    </div>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta crociera '+data.destinazione+' '+data.title"
                     class="title display-block" *ngIf="config.isCrociere">
                    <img class="icon" src="assets/img/icons/ship.svg">
                    <!-- Msc Meraviglia  -->
                    <div class="label" [matTooltip]="data.titleNave">{{data.titleNave}}</div>
                    <!-- <span><img class="icon" src="assets/img/icons/yacht.svg"></span> -->
                    <img *ngIf="data.icona" class="logo-crociera" src="assets/img/partner/{{data.icona}}">
                  </a>

                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta crociera '+data.destinazione+' '+data.title"
                     class="location text-ellipsis display-block"
                     *ngIf="config.isCrociere">
                    <!-- <div class="label">Italia, Francia, Spagna</div> -->
                    <div class="label white-space-normal text-align-left word-break-word" [matTooltip]="data.title">{{data.title}}</div>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta crociera '+data.destinazione+' '+data.title"
                     class="company display-block" *ngIf="config.isCrociere">
                    <!--
                                        <img *ngIf="data.img" class="icon" [src]="data.img">
                    -->
                    <!-- <div class="label">Msc Crociere - <i>Giro del Mondo</i></div> -->
                    <!-- Compagnia -->
                    <div class="label">{{data.subtitle}}</div>
                  </a>
                  <a target="_blank" [routerLink]="linkContinua"
                     [title]="'Offerta crociera '+data.destinazione+' '+data.title"
                     class="details-container" *ngIf="config.isCrociere">
                    <div class="detail">
                      <img class="icon" src="assets/img/icons/bed.svg">
                      <!-- <div class="label">6 giorni/ 5 notti</div> -->
                      <div class="label">{{data.giorni}}&nbsp;giorni / {{data.notti}}&nbsp;notti</div>
                    </div>
                    <div class="detail">
                      <img class="icon" src="assets/img/icons/anchor.svg">
                      <!-- <div class="label">da Civitavecchia</div> -->
                      <div class="label">da&nbsp;{{data.luogoPartenza}}</div>
                    </div>
                    <div class="detail">
                      <img class="icon" src="assets/img/icons/calendar.svg">
                      <!-- <div class="label">Mer 29 Apr 2020</div> -->
                      <div class="label">{{parseFullDate(data.dataPartenza)}}</div>
                    </div>
                    <div class="detail" *ngIf="data.isVolo">
                      <img class="icon rotate-90" src="assets/img/icons/result-card/plane.svg">
                      <div class="label">
                        VOLO INCLUSO
                      </div>
                    </div>
                  </a>
                  <div class="row no-gutters box-icons">
                    <a target="_blank" [routerLink]="linkContinua"
                       [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
                       class="icons col-10">
                      <div class="row no-gutters">
                        <div class="detail" *ngIf="data.isAnimali">
                          <img class=" filter-black custom-icon"
                               alt="Trasferimenti Collettivi"
                               src="assets/img/icone-partenze/animali.svg"
                               matTooltip="Animali ammessi" matTooltipPosition="above">
                        </div>
                        <div class="detail" *ngIf="data.isBimbiGratis"
                             matTooltipPosition="above"
                             matTooltip="Bimbi gratis">
                          <img class=" filter-black custom-icon"
                               alt="Bimbi gratis"
                               src="assets/img/icone-partenze/bimbi-1.svg">
                        </div>
                        <div class="detail" *ngIf="data.isFamigliaNumerosa"
                             matTooltip="Famiglia numerosa"
                             matTooltipPosition="above">
                          <img class=" filter-black custom-icon"
                               alt="Famiglia numerosa"
                               src="assets/img/icone-partenze/famiglia-xl.svg">
                        </div>
                        <div class="detail" *ngIf="data.isSingle"
                             matTooltip="Singola in Offerta"
                             matTooltipPosition="above">
                          <img class=" filter-black custom-icon"
                               alt="Singola in Offerta"
                               src="assets/img/icone-partenze/single.svg">
                        </div>
                        <div class="detail" *ngIf="data.isSoloCoppie"
                             matTooltip="Promo Coppie"
                             matTooltipPosition="above">
                          <img class=" filter-black custom-icon"
                               alt="Promo Coppie"
                               src="assets/img/icone-partenze/coppie.svg">
                        </div>
                        <div class="detail" *ngIf="data.isZeroPensieri"
                             matTooltip="Zero Pensieri" matTooltipPosition="above">
                          <img class="filter-black custom-icon"
                               alt="Zero Pensieri"
                               src="assets/img/icone-partenze/zero-pensieri.svg">
                        </div>
                        <div class="detail" *ngIf="data.isLastMinute"
                             matTooltip="Last minute" matTooltipPosition="above">
                          <img class=" filter-black custom-icon"
                               alt="Last minute"
                               src="assets/img/icone-partenze/last-minute.svg">
                        </div>
                        <div class="detail" *ngIf="data.isOffertaPrime"
                             matTooltip="Offerta Prime" matTooltipPosition="above">
                          <img class=" filter-black custom-icon"
                               alt="Offerta Prime"
                               src="assets/img/icone-partenze/offerta-prime.svg">
                        </div>
                        <div class="detail" *ngIf="data.isFuoriTutto && data.iconaPromozione"
                             matTooltip="{{data.nomePromozione}}" matTooltipPosition="above">
                          <img class=" custom-icon"
                               alt="{{data.nomePromozione}}"
                               src="{{data.iconaPromozione}}">
                        </div>
                      </div>
                    </a>
                    <div class="lista-desideri d-block d-sm-none d-md-none d-lg-none col-2">
                      <div class="text" *ngIf="!config.isListaDesideri && !config.isListaPrenotazioni">
                        <borsaviaggi-preferiti [hidden]="config.isCrociere || reduceCard"
                                               [isFavourite]="data.isPreferito"
                                               [partenza]="data.idPartenza || data.idPartenzaCrociera"></borsaviaggi-preferiti>
                      </div>
                      <div class="icon cursor-pointer" (click)="deleteDesideri()" *ngIf="config.isListaDesideri">
                        <mat-icon>delete_forever</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="box-icona-pricing" *ngIf="data.iconaPricing">
                    <img src="{{ webPSupport && data.iconaPricingWebP ? data.iconaPricingWebP : data.iconaPricing}}">
                  </div>
                </div>

              </div>

            </div>
          </div>
          <a target="_blank" [routerLink]="linkContinua"
             [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
             class="messages-section" *ngIf="data.messages">
            <div class="message danger">
              <div class="alert danger">
                !
              </div>
              <div class="text">
                {{data.messages[0]}}
                <!-- Offerta valida solo aderendo a Prime - se ti iscrivi entro 24h, in regalo un set di valigie! -->
              </div>
            </div>
            <div class="message success">
              <div class="alert success">
                !
              </div>
              <div class="text">
                {{data.messages[1]}}
                <!-- Sconto del 30% su questa offerta! -->
              </div>
            </div>
            <div class="message warning">
              <div class="alert warning">
                !
              </div>
              <div class="text">
                {{data.messages[2]}}
                <!-- Risparmi 30€! -->
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="price-summary-section"
         [ngClass]="{'col-xl-3 col-md-3 col-sm-12 col-xs-12': !config.vertical, 'col-xl-12 col-md-12 col-sm-12 col-xs-12': config.vertical}">
      <div class="top-section">
        <div class="message-container" *ngIf="data.isOffertaPrime">
          <div class="message success">
            <div class="alert success">
              !
            </div>
            <div class="text">
              Risparmi {{data.listaServiziPrime | somma:'importo' }}€!
            </div>
          </div>
        </div>

        <div [ngClass]="{'card-ridotta': reduceCard}"
             class="lista-desideri-ridotta">
          <div class="text" *ngIf="!config.isListaDesideri && !config.isListaPrenotazioni">
            <span class="p-15-noleft">Aggiungi a Lista Desideri</span>
            <borsaviaggi-preferiti [hidden]="config.isCrociere || reduceCard"
                                   [isFavourite]="data.isPreferito"
                                   [partenza]="data.idPartenza || data.idPartenzaCrociera"></borsaviaggi-preferiti>
          </div>
          <div class="icon cursor-pointer" (click)="deleteDesideri()" *ngIf="config.isListaDesideri">
            <mat-icon>delete_forever</mat-icon>
          </div>
        </div>
        <div class="bottom-section position-relative">
          <div class="price-container"
               *ngIf="!config.isCrociere && !config.isListaDesideri">
            <div *ngIf="!config.isCrociere && !config.isListaDesideri && !config.isListaPrenotazioni"
                 class="price-description d-none">A partire da
            </div>
            <div *ngIf="config.isListaPrenotazioni"
                 class="price-description">Totale contratto
            </div>
            <a target="_blank"
              [routerLink]="linkContinua"
              [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
              class="price-wrapper">
              <div>
                <div *ngIf="!data.isPartenzaLive">Prezzo finito</div>
                <div *ngIf="data.isPartenzaLive">A partire da</div>
                <span class="price">{{data.price || data.totaleContratto | euroFormat}}</span> <span
                class="fs-1_1rem">€</span>
                <div>a persona</div>
              </div>
              <div *ngIf="data.isOffertaPrime" class="offerta-prime">
                <span class="offerta display-block">offerta</span>
                <span class="prime">prime</span>
              </div>
            </a>
            <div class="price-description d-none" *ngIf="config.isListaDesideri || !config.isListaPrenotazioni">a
              persona
            </div>
          </div>
          <div class="price-container crociere" *ngIf="config.isCrociere">
            <div class="price-description text-align-left"
                 *ngIf="!config.isListaDesideri || !config.isListaPrenotazioni">
              Prezzo per persona su base doppia
              <div>a partire da</div>
            </div>

            <a target="_blank" class="cabin-prices-list-container"
               [routerLink]="linkContinua"
               [title]="'Offerta crociera '+data.destinazione +' '+data.title"
               *ngIf="!config.isListaPrenotazioni || !config.isListaDesideri">
              <div *ngIf="data.prezzoCabinaInterna" class="price-wrapper row no-gutters mb-5px">
                <div class="col-7">
                  <div class="cabin-description">Cabina interne</div>
                </div>
                <div class="col-5">
                  <div class="row no-gutters">
                    <div class="col-10 cabin-price">{{data.prezzoCabinaInterna| euroFormat}}
                      <span class="fs-1_1rem">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="data.prezzoCabinaEsterna" class="price-wrapper row no-gutters mb-5px">
                <div class="col-7">
                  <div class="cabin-description">Cabina esterne</div>
                </div>
                <div class="col-5">
                  <div class="row no-gutters">
                    <div class="col-10 cabin-price">{{data.prezzoCabinaEsterna | euroFormat}}
                      <span class="fs-1_1rem">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="data.prezzoCabinaConBalcone" class="price-wrapper row no-gutters mb-5px">
                <div class="col-7">
                  <div class="cabin-description">Cabina con balcone</div>
                </div>
                <div class="col-5">
                  <div class="row no-gutters">
                    <div class="col-10 cabin-price">{{data.prezzoCabinaConBalcone| euroFormat}}
                      <span class="fs-1_1rem">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="data.prezzoSuite" class="price-wrapper row no-gutters mb-5px">
                <div class="col-7">
                  <div class="cabin-description">Suite</div>
                </div>
                <div class="col-5">
                  <div class="row no-gutters">
                    <div class="col-10 cabin-price">{{data.prezzoSuite| euroFormat}}
                      <span class="fs-1_1rem">€</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div class="price-description" *ngIf="config.isListaPrenotazioni">Prezzo Totale</div>
            <div class="price-wrapper" *ngIf="config.isListaPrenotazioni">
              <div class="price">{{data.totaleContratto}}</div>
              <div *ngIf="data.isOffertaPrime" class="offerta-prime">
                <span class="offerta">offerta</span><br>
                <span class="prime">prime</span>
              </div>
            </div>
          </div>

          <div class="separator" *ngIf="!config.isListaDesideri && !config.isListaPrenotazioni"></div>
          <div class="lista-desideri d-none d-sm-block d-md-block d-lg-block">
            <div class="text" *ngIf="!config.isListaDesideri && !config.isListaPrenotazioni">
              <span class="fs-12px p-15-noleft">Aggiungi a Lista Desideri</span>
              <borsaviaggi-preferiti [hidden]="config.isCrociere || reduceCard"
                                     [isFavourite]="data.isPreferito"
                                     [partenza]="data.idPartenza || data.idPartenzaCrociera"></borsaviaggi-preferiti>
            </div>
            <div class="icon cursor-pointer" matTooltip="Rimuovi dalla lista dei desideri" (click)="deleteDesideri()"
                 *ngIf="config.isListaDesideri">
              <mat-icon>delete_forever</mat-icon>
            </div>
          </div>
          <a target="_blank" class="btn d-none d-sm-block d-md-block d-lg-block"
             *ngIf="config.isVacanze && !config.isListaDesideri && !config.isListaPrenotazioni"
             [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
             [routerLink]="linkContinua"
             [ngClass]="{'orange': config.isVacanze, 'lightblue': config.isCrociere}">
            Continua
          </a>
          <a target="_blank" class="btn d-none d-sm-block d-md-block d-lg-block"
             *ngIf="config.isCrociere && !config.isListaDesideri && !config.isListaPrenotazioni"
             [title]="'Offerta crociera '+data.destinazione+' '+data.title"
             [routerLink]="linkContinua"
             [ngClass]="{'orange': config.isVacanze, 'lightblue': config.isCrociere}">
            Continua
          </a>
          <a target="_blank" class="btn display-block" *ngIf="config.isListaDesideri"
             [routerLink]="linkContinua"
             [title]="'Offerta lastminute '+data.destinazione+' '+data.nomeStruttura"
             [ngClass]="{'orange': config.isVacanze, 'lightblue': config.isCrociere}">
            Vai
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



