export class ImageUtilService {

  private static _supportWebP: boolean = false;

  public static checkSupportImageWebP(isSsr?) {
    if (isSsr) {
      this._supportWebP = true;
    } else {
      let elem = document.createElement('canvas');

      if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        this._supportWebP = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      } else {
        // very old browser like IE 8, canvas not supported
        this._supportWebP = false;
      }
    }

    return this._supportWebP
  }

  public static ordinaFotoArray(dataFoto) {
    let destinazioniFoto = [];
    let struttureFoto = [];
    dataFoto.map((element) => {
      if ((element.foto && element.foto.includes('strutture')) || (!element.foto && element.includes('strutture'))) {
        struttureFoto.push(element);
      } else {
        destinazioniFoto.push(element);
      }
    });
    return struttureFoto.concat(destinazioniFoto);
  }


  public static supportWebP(): boolean {
    return this._supportWebP;
  }

}
