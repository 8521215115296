import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from "../../services/common/modal.service";
import {UserService} from "../../services/gate/user.service";
import {AuthService} from "../../services/common/auth.service";

@Component({
  selector: 'borsaviaggi-upload-image-profile',
  templateUrl: './upload-image-profile.component.html',
  styleUrls: ['./upload-image-profile.component.scss']
})

export class UploadImageProfileComponent {
  public imageObj = {url: "../../assets/img/upload-image-profile/profile-img.png", urlToEdit: ""};
  @Input() isAreaPersonale: boolean;

  constructor(private modalSrv: ModalService,
              private userSrv: UserService,
              private authSrv: AuthService) {
    if (this.authSrv.isUserLogedin()) {
      this.getImage();
    }

  }

  private getImage() {
    this.userSrv.getFotoUtente().then((response) => {
      if (response && response.body.status === 0) {
        this.imageObj.url = 'data:image/png;base64,' + response.body.data;
      }
    }, (error) => {

    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.startsWith("image/")) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => { // called once readAsDataURL is completed
          this.imageObj.urlToEdit = reader.result.toString();
          this.modalSrv.openCropImageModal(this.imageObj);
        }
      } else {
        this.modalSrv.openErrorDialog("Formato del file non valido");
      }
      //reset event value, if not no event fires when selecting the same file
      event.target.value = '';
    }
  }
}
