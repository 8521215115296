<div class="container border-r-2px mt-20px text-align-justify background-white">
  <div class="row">
    <div class="col text-align-center">
      <h1 class="color-text-blue-borsaviaggi">Privacy Policy</h1>
    </div>
  </div>
  <div class="row mb-20px">
    <div class="col mt-20px">
      <div>
        <h3 class="color-text-blue-borsaviaggi">RIFERIMENTI NORMATIVI</h3>
        <ul class="mt-10px">
          <li>
            Direttiva 2002/58/CE - relativa al "trattamento dei dati personali e alla tutela della
            vita privata nel settore delle comunicazioni elettroniche".
          </li>
          <li>
            Regolamento (UE) 2016/679 del parlamento europeo e del consiglio del 27 aprile 2016 relativo
            alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché
            alla libera circolazione di tali dati e che abroga la direttiva 95/46/ce (Regolamento Generale
            sulla Protezione dei Dati).
          </li>
        </ul>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">IL TITOLARE DEL TRATTAMENTO</h3>
        <p>
          A seguito della consultazione di questo sito possono essere trattati dati relativi
          a persone identificate o identificabili. Il “Titolare” del loro trattamento è Borsaviaggi
          S.r.l., con sede in Via del Serafico, 185 - 00142 Roma
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">TIPI DI DATI TRATTATI</h3>
        <h4 class="color-text-orange-borsaviaggi mt-10px">Dati di navigazione</h4>
        <p>
          I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono,
          nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei
          protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere
          associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni
          ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
          In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli
          utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
          richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione
          del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server
          (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
          Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito
          e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione.
        </p>
      </div>
      <div class="mt-20px">
        <h4 class="color-text-orange-borsaviaggi">Dati forniti volontariamente dall’utente</h4>
        <p>I dati personali forniti dall’utente tramite form sono raccolti e trattati per le seguenti finalità:</p>
        <ul>
          <li>per lo svolgimento delle attività di relazione con il cliente in base agli accordi contrattuali;</li>
          <li>per finalità amministrative e per l’adempimento di obblighi di legge quali ad esempio quelli di natura contabile, fiscale, o per dar corso a richieste dell’autorità giudiziaria;</li>
          <li>in presenza di specifico consenso, per l’invio periodico, tramite e-mail, di newsletter e materiale pubblicitario;</li>
          <li>in presenza di specifico consenso, per ricevere aggiornamenti sulle nostre attività;</li>
          <li>in presenza di specifico consenso, per ricevere comunicazioni promozionali e promozioni speciali;</li>
          <li>nel caso di invio di curriculum vitae, esclusivamente per finalità di selezione.</li>
        </ul>
        <p>La base giuridica che legittima il trattamento è l’esecuzione di un contratto di cui l’interessato è parte o l’esecuzione di misure precontrattuali adottate su richiesta dello stesso. Nei casi espressamente indicati la base giuridica è il consenso liberamente fornito dall’interessato. </p>
      </div>
      <div class="mt-20px">
        <h4 class="color-text-orange-borsaviaggi">Dati di minori</h4>
        <p>
          I Dati personali forniti e relativi a minori sono trattati esclusivamente quando ciò si renda
          necessario per garantire il corretto utilizzo del prodotto/servizio richiesto a seguito della
          stipulazione di un contratto di cui l’interessato è parte. Tali dati non saranno in alcun modo
          utilizzati per ulteriori scopi se non necessari a garantire il godimento di tutti i vantaggi del
          prodotto/servizio (a titolo esemplificativo e non esaustivo, potranno essere utilizzati al fine
          di garantire: la protezione della carta di credito del minore, la copertura assicurativi del minore;
          le prenotazioni di voli, hotel o crociere).
        </p>
      </div>
      <div class="mt-20px">
        <h4 class="color-text-orange-borsaviaggi">Dati anonimi o aggregati</h4>
        <p>
          L’anonimizzazione si configura come un trattamento che ha lo scopo di impedire l’identificazione
          dell’interessato. I dati resi anonimi non rientrano nell’ambito di applicazione della legislazione
          in materia di protezione dei dati. Raccogliamo, elaboriamo e condividiamo dati aggregati come dati
          statistici o demografici per diverse finalità. I dati aggregati possono derivare ​​da dati personali
          forniti dall’utente ma non sono considerati dati personali in quanto, come specificato, non consentono
          né direttamente né indirettamente l’identificazione dell’interessato. Tali dati saranno utilizzati anche
          al fine di migliorare la qualità dei nostri prodotti/servizi esistenti, sviluppare nuove funzionalità,
          nonché per ulteriori scopi di ricerca generali (ad esempio al fine di verificare la frequenza con cui
          si utilizza un determinato prodotto servizio; per calcolare la percentuale di utenti che accedono a una
          specifica funzione del sito Web etc.). Poiché tali dati non consentono l’individuazione di una persona
          fisica e non sono considerati personali, saranno condivisi con ulteriori terzi, controllati, controllate o partners.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">COOKIES</h3>
        <p>
          I cookies sono piccoli file di testo contenenti una certa quantità di informazioni scambiate tra un sito
          internet ed il terminale dell’utente (solitamente il browser). Essi sono principalmente utilizzati con lo
          scopo di far funzionare i siti web e farli operare in modo più efficiente, nonché al fine di fornire
          informazioni ai proprietari del sito stesso. I cookies possono essere sia di sessione che persistenti.
          I cookies di sessione rimangono memorizzati nel terminale per un breve periodo di tempo e sono eliminati
          non appena l'utente chiude il browser. Il loro utilizzo è strettamente limitato alla trasmissione di
          identificativi di sessione (costituiti da numeri casuali generati dal server) necessari per consentire
          l'esplorazione sicura ed efficiente del sito. I cookies persistenti invece rimangono archiviati nel terminale
          dell’utente fino ad una scadenza prestabilita. Questi, non essendo cancellati direttamente alla chiusura
          del browser, consentono di ricordare le scelte operate dall’utente sul proprio sito nonché di raccogliere
          informazioni circa le pagine del sito visitate dall’utente, la frequenza con la quale il sito viene visitato
          e per identificare il tracciato di navigazione degli utenti, al fine di migliorare l’esperienza sul presente
          sito. I cookies, di sessione o persistenti, possono essere infine di Prima parte o di Terza parte a seconda
          che il soggetto che installa i cookies sul terminale dell'utente sia lo stesso gestore del sito che l'utente
          sta visitando (si parlerà allora di cookies di Prima parte) oppure un soggetto diverso (si parlerà allora di
          cookies di Terza parte).
        </p>
      </div>
      <div class="mt-20px">
        <h4 class="color-text-orange-borsaviaggi">Cookie utilizzati dal presente sito</h4>
        <div>
          Il presente sito si avvale dell’utilizzo di cookies sia di Prima parte che di Terza parte in quanto si avvale delle funzionalità dei servizi Google Analytics, offerti da Google Inc.
          Google Analytics utilizza i "cookies" per raccogliere e analizzare le informazioni in merito alle modalità di utilizzo dei siti web visitati dall’utente. Per avere maggiori informazioni sui cookies di Google Analytics e sul loro utilizzo da parte di Google Inc. è possibile consultare:
          l'informativa sull’utilizzo dei cookies da parte di Google disponibile all’indirizzo:
        </div>
        <div>
          <a href="http://www.google.com/intl/it/policies/technologies/cookies/">http://www.google.com/intl/it/policies/technologies/cookies/</a>
          e <a href=" https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-usage"> cookies-usage</a>
        </div>
        <div>
          l'informativa per la privacy policy di Google Analytics all’indirizzo:
        </div>
        <div>
          <a href="http://www.google.com/intl/en/analytics/privacyoverview.html">http://www.google.com/intl/en/analytics/privacyoverview.html</a>
        </div>
        <p class="mt-10px">
          Criteo utilizza cookie per finalità di marketing attraverso il cd. remarketing. Il remarketing aiuta a
          raggiungere gli utenti che hanno visitato il sito web dopo che hanno lasciato le pagine del presente sito web.
          Per avere maggiori informazioni sui cookies di Criteo e sul loro utilizzo è possibile consultare la seguente pagina web:
          <a href=" http://www.criteo.com/it/privacy/full-privacy-text/"> http://www.criteo.com/it/privacy/full-privacy-text/</a>
        </p>
      </div>
      <div class="mt-20px">
        <h4 class="color-text-orange-borsaviaggi">Gestione dei cookie</h4>
        <p>
          Normalmente i browser permettono il controllo della maggior parte dei cookie tramite le impostazioni
          del browser stesso. Si ricorda tuttavia che la disabilitazione totale o parziale dei cookies c.d. tecnici
          può compromettere l’utilizzo delle funzionalità del sito. Ad ogni modo qualora l’utente non desideri
          ricevere alcun tipo di cookies sul proprio elaboratore, né da questo sito, né da altri, può elevare il
          livello di protezione privacy modificando le impostazioni di sicurezza del proprio browser:
        </p>
        <ul>
          <li>Mozilla Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a></li>
          <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=it">https://support.google.com/chrome/answer/95647?hl=it</a></li>
          <li>Internet Explorer: <a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies</a></li>
          <li>Safari 6/7 Mavericks: <a href="https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT">https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT</a></li>
          <li>Safari 8 Yosemite: <a href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=it_IT">https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=it_IT</a></li>
          <li>Safari su iPhone, iPad, o iPod touch: <a href="https://support.apple.com/it-it/HT201265">https://support.apple.com/it-it/HT201265</a></li>
        </ul>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">LINK AD ALTRI SITI WEB</h3>
        <p>
          Questo sito potrebbe contenere link o riferimenti per l’accesso ad altri siti. Ti informiamo il
          Titolare del trattamento non controlla i cookie o le altre tecnologie di monitoraggio di tali siti
          web ai quali la presente Policy non si applica.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">FACOLTATIVITA’ DEL CONFERIMENTO DEI DATI</h3>
        <p>
          A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire
          i dati propri personali. Tuttavia il loro mancato conferimento può comportare l’impossibilità
          di ottenere quanto richiesto.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">MODALITA’ DEL TRATTAMENTO</h3>
        <p>
          I dati personali sono trattati, anche mediante l’ausilio di strumenti automatizzati.
          Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti
          o non corretti ed accessi non autorizzati. Il Titolare ha adottato tutte le misure minime di
          sicurezza previste dalla legge ed ispirandosi ai principali standard internazionali, ha inoltre
          adottato misure di sicurezza ulteriori per ridurre al minimo i rischi afferenti la riservatezza,
          la disponibilità e l’integrità dei dati personali raccolti e trattati.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">TEMPI DI CONSERVAZIONE</h3>
        <p>
          I Dati raccolti saranno conservati – per ciascuna tipologia di dati trattati – esclusivamente
          per il tempo necessario ad adempiere alle specifiche finalità indicate nelle specifiche informative
          di sintesi visualizzate nelle pagine del sito e predisposte per particolari prodotti/servizi.
          I Dati saranno conservati in modo completo per tutto il periodo dell’esecuzione del contratto
          eventualmente stipulato con il Titolare o per tutta la durata del servizio/prodotto comunque erogato.
          Successivamente, i Dati saranno conservati per un periodo di dieci anni ai fini di ottemperare agli
          obblighi di legge e, tra questi, gli obblighi di cui all’art. 2214 codice civile. L’eventuale ulteriore
          conservazione di Dati o parte dei Dati potrà essere disposta per far valere o difendere i propri diritti
          in ogni eventuale sede ed in particolare nelle sedi giudiziarie.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">CONDIVISIONE, COMUNICAZIONE E DIFFUSIONE DEI DATI</h3>
        <p>
          I trattamenti connessi ai servizi web di questo sito hanno luogo presso la predetta sede del
          Titolare e sono curati solo da personale da questo espressamente autorizzato. I dati raccolti
          potranno essere condivisi, trasferiti o comunicati ad altre società per attività strettamente
          connesse e strumentali all’operatività del servizio, come la gestione del sistema informatico
          oppure ad eventuali fornitori terzi incaricati di occasionali operazioni di manutenzione.
          Nei predetti casi, il Titolare provvede a nominare tali soggetti terzi Responsabili del trattamento
          ai sensi dell’articolo 28 del GDPR. Al di fuori di questi casi, i dati personali non saranno comunicati
          a terzi salvo previsione contrattuale o di legge, ovvero salvo specifico consenso richiesto all’interessato.
          In questo senso, i dati personali potrebbero essere trasmessi a terze parti, ma solo ed esclusivamente nel
          caso in cui:
        </p>
        <ul>
          <li>vi sia consenso esplicito a condividere i dati con terze parti;</li>
          <li>vi sia la necessità di condividere con terzi le informazioni al fine di prestare il servizio richiesto;</li>
          <li>ciò sia necessario per adempiere a richieste dell'Autorità Giudiziaria o di Pubblica Sicurezza.</li>
          <li>Nessun dato derivante dal servizio web viene diffuso.</li>
        </ul>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">DIRITTI DEGLI INTERESSATI</h3>
        <p>
          La normativa a protezione dei dati personali prevede espressamente alcuni diritti
          in capo ai soggetti cui dati si riferiscono (c.d. interessato). In particolare, ai sensi
          degli articoli 15 e ss. del Regolamento (UE) 2016/679, ciascun interessato ha il diritto
          di ottenere la conferma dell’esistenza o meno di dati che lo riguardano, di ottenere
          l’indicazione dell’origine e delle finalità e modalità del trattamento, l’aggiornamento,
          la rettificazione, l’integrazione dei dati nonché la loro cancellazione qualora trattati in
          violazione di legge o qualora sussista uno dei motivi specificati dall’articolo 17 del
          Regolamento (UE) 2016/679.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">MODIFICHE ALLE PRESENTI POLITICHE SULLA PRIVACY</h3>
        <p>
          Il Titolare verifica periodicamente la propria politica relativa alla privacy e alla sicurezza e,
          se del caso, la rivede in relazione alle modifiche normative, organizzative o dettate dall’evoluzione tecnologica.
          In caso di modifica delle politiche, la nuova versione sarà pubblicata in questa pagina del sito.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">DOMANDE, RECLAMI, SUGGERIMENTI E ESERCIZIO DEI DIRITTI</h3>
        <p>
          Chiunque fosse interessato a maggiori informazioni, a contribuire con propri suggerimenti o
          avanzare reclami o contestazioni in merito alle politiche privacy, sul modo in cui la nostra
          Società tratta i dati personali, nonché per far valere i propri diritti previsti dalla normativa
          in materia di protezione dei dati personali, può rivolgersi al Titolare del trattamento scrivendo a
          Borsaviaggi S.r.l., con sede in Via del Serafico, 185 - 00142 Roma.
          In alternativa può inviare un messaggio di posta elettronica all’indirizzo <span class="font-weight-bold">privacy@borsaviaggi.it</span>.
        </p>
      </div>
      <div class="mt-20px">
        <h3 class="color-text-blue-borsaviaggi">LEGGE E GIURISDIZIONE</h3>
        <p>
          L'interpretazione e l'esecuzione di queste condizioni sono regolate dalla legge italiana.
          Il Titolare si riserva di ottenere rimedi urgenti presso qualunque Corte anche all'estero,
          per proteggere i propri interessi e far rispettare i propri diritti.
        </p>
      </div>
    </div>
  </div>
</div>
