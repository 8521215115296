<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center background-white">
  <div class="row mb-15px">
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xl-5" *ngIf="!isMobileDevice">
      <img class="prime-banner" src="{{dataResponse.imgHeader}}" alt="Banner {{dataResponse.descrizione}}"/>
    </div>
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xl-5 mb-15px" *ngIf="isMobileDevice">
      <img class="prime-banner" src="{{dataResponse.imgMobile}}" alt="Banner {{dataResponse.descrizione}}"/>
    </div>
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 text-align-left prime-desc-container" *ngIf="!statusConferma">
      <h1 class="titolo-prime">CON {{dataResponse.descrizione | uppercase}} {{dataResponse.maxSconto}}€ DI SCONTO!</h1>
      <p class="prime-desc mt-3">
        <b>Scarica Subito</b> il Tuo <b>Codice Sconto*</b> e prenoti
        la tua vacanza con uno <b>sconto fino a {{dataResponse.maxSconto}}€</b> sul
        miglior prezzo garantito Borsaviaggi.it
      </p>
      <p class="sottotitolo-prime font-weight-bold">
        La PROMO scade il {{dataResponse.dataFinePromozione | date: 'dd MMMM yyyy'}}!
      </p>
      <div class="w-100 mt-4 mb-15px text-align-left">
        <form [formGroup]="formGroup">
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput required placeholder="Nome" name="nome" formControlName="nome">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cognome</mat-label>
            <input matInput required placeholder="Cognome" name="cognome" formControlName="cognome">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Email" name="email" formControlName="email">
          </mat-form-field>
          <mat-form-field class="col col-md-6" appearance="outline">
            <mat-label>Cellulare</mat-label>
            <input matInput required placeholder="Cellulare" name="cellulare" formControlName="cellulare">
          </mat-form-field>
          <mat-checkbox formControlName="privacy" class="fs-12px left full-width" [checked]="true"> Ho letto ed accetto l'Informativa
            Privacy in
            merito al
            trattamento dei miei
            dati personali
          </mat-checkbox>
          <mat-checkbox formControlName="riceviNewsletter" class="fs-12px left full-width">Consenso per l'invio di materiale promozionale
            (facoltativo)
          </mat-checkbox>
          <div class="clearfix"></div>
          <button mat-raised-button class="button-attiva font-weight-bold mt-3" [disabled]="!dataResponse.attiva" (click)="scaricaSconto()">SCARICA LO SCONTO</button>
        </form>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 text-align-left prime-desc-container" *ngIf="statusConferma">
      <h1 class="titolo-prime">CON {{dataResponse.descrizione | uppercase}} {{dataResponse.maxSconto}}€ DI SCONTO!</h1>
      <p class="prime-desc mt-3">
        <b>Usa subito il Tuo Codice Sconto*!</b><br>
        Scegli la Vacanza ed <b>inserisci lo sconto</b> alla richiesta <b>online o
        comunicalo</b> telefonicamente ad un <b>nostro consulente.</b>
      </p>
      <p class="sottotitolo2-prime font-weight-bold">
        Affrettati - lo sconto è valido per prenotazioni entro il {{dataResponse.dataFinePromozione | date: 'dd MMMM yyyy'}}!
      </p>
      <div class="w-100 " style="padding: 30px 0px;" *ngFor="let buono of dataResponse.buoniSconto">
        <div class="row mx-0">
          <div class="col-xs-12 col-md-6 text-center">
            <div class="sconto-container p-2 text-align-center">
              <span class="sconto-importo">{{buono.importo}}&euro;</span><br />
              <span class="sconto-testo">BUONO SCONTO</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 pl-0" style="line-height: 1.1;">
            <div class="float-left mt-4 codice-sconto-testo">
              Codice<br />Sconto:
            </div>
            <div class="float-left ml-2 codice-sconto-codice">
              {{buono.codice}}
            </div>
            <div class="clearfix"></div>
            <span class="codice-sconto-validita">Valido per importo pratica minimo {{buono.importoPratica}}&euro;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 mt-5">
      <span class="fs-16px">* Il buono sconto è personale e spendibile entro il {{dataResponse.dataFinePromozione | date: 'dd MMMM yyyy'}}</span><br>
      <span class="fs-12px">Non è cumulabile con altri sconti e non è spendibile durante Fuori Tutto, Prime Days e Campagne.</span>
    </div>
  </div>
</div>
