import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {MacroDestinazioneService} from "../macro-destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class MacroCategoriaResolveService {
  constructor(private macroDestSrv: MacroDestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let reqObj = {
      idSelezione: routeSnap.params.idSelezione
    };
    return this.macroDestSrv.getNostraSelezione(reqObj)
      .catch(err => {
        return err;
      });
  }

}
