<div class="background">
  <div class="container border-r-2px padding-20px mt-20px mb-20px text-align-justify background-white overflow-hidden">
    <div class="row mb-15px ml-10px selection-box" *ngIf="!luoghiPartenzaFiltro">
      <a class="d-flex align-items-center bckground-orange"
         [routerLink]="['/' + urlParams[2] + '/' + urlParams[0] + '/' + urlParams[3] + '/' + urlParams[4]+'/'+urlParams[7]]">
        <mat-icon>navigate_before</mat-icon>
        Torna Indietro</a>
    </div>

    <div class="row mb-20px">
      <div class="col-md-3 align-items-center justify-content-center text-center">
        <img class="mt-1-5-rem width-40-percent"
             [alt]="data.title"
             [src]="data.imgUrl">
      </div>
      <div class="col-9 mt-4 offerte-title">
        <div class="row">
          <h1 class="text-align-left mr-2">OFFERTE LAST MINUTE </h1>
          <h2 class="text-align-left d-flex align-items-center color-text-blue-borsaviaggi mr-2">
            -{{' ' + nomeDestinazione}}</h2>
          <h2 class="text-align-center d-flex align-items-center color-text-blue-borsaviaggi"
              [hidden]="!routerParams || !routerParams.fDataTxt"> -{{' ' + capitalize(routerParams.fDataTxt)}}</h2>
        </div>
        <div class="row">
          <h3 class="text-align-left">{{capitalize(data.text)}}</h3>
        </div>
      </div>
    </div>
    <!-- date and transport filter -->
    <div class="mt-15px mb-15px">
      <borsaviaggi-search-vacanze [filterDestinations]="filteredDestinations"></borsaviaggi-search-vacanze>
    </div>
    <!-- Filters grid -->
    <div class="row mt-1rem filter-grid links-container">
      <div class="col-md-4 pl-0">
        <div class="grid-col-title">
          SCEGLI PER MESE DI PARTENZA
        </div>
        <mat-grid-list cols="3" rowHeight="20px" class="grid-tile">
          <mat-grid-tile class="filter-tile" *ngFor="let month of months">
            <a class="grid-link" [routerLink]="makeFilterUrl('month', month)">{{month.text}}</a>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="col-md-4 pl-0 pr-0 txt-center links-container">
        <div class="grid-col-title">
          SCEGLI PER LUOGO PARTENZA
        </div>
        <mat-grid-list cols="3" rowHeight="40px">
          <mat-grid-tile class="filter-tile" *ngFor="let lp of luoghiPartenzaFiltro">
            <a class="grid-link" [routerLink]="makeFilterUrl('fromDepLocFilter', lp)">{{lp.Nome}}</a>
          </mat-grid-tile>
        </mat-grid-list>
        <span class="grid-link link-color" [hidden]="this.luoghiPartenzaFiltro"> - </span>
      </div>
      <div class="col-md-4 pr-0 links-container">
        <div class="grid-col-title">
          SCEGLI PER RICORRENZA
        </div>
        <mat-grid-list cols="3" rowHeight="40px">
          <mat-grid-tile class="filter-tile text-align-center" *ngFor="let rec of recurrence">
            <a class="grid-link" [routerLink]="makeFilterUrl('recurrence', rec)">{{rec.text}}</a>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>


    <div class="g-card">
      <div class="separator"></div>

      <!-- offers list -->
      <div class="offers-container">
        <mat-expansion-panel class="xps-panel mat-elevation-z8" *ngFor="let data of objDataSource" [expanded]="true">
          <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
            <mat-panel-title class="fs-1_2rem">
              {{data.infoOfferta.titolo}}
            </mat-panel-title>
            <mat-panel-description class="d-none d-sm-table-cell">
              Codice Offerta: G{{data.infoOfferta.idOfferta}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-sm carousel">
              <mat-carousel
                timings="250ms ease-in"
                interval="5000"
                color="accent"
                proportion="80"
                orientation="ltr"
                [autoplay]="!isMobileMode"
                [loop]="true"
                [hideArrows]="false"
                [hideIndicators]="false"
                [useKeyboard]="true"
                [useMouseWheel]="false"
              >
                <mat-carousel-slide
                  #matCarouselSlide
                  *ngFor="let img of data.images; let i = index"
                  [image]="img"
                  overlayColor="#00000040"
                  [hideOverlay]="true"
                ></mat-carousel-slide>
              </mat-carousel>
            </div>

            <div class="col-sm-9">
              <table mat-table [dataSource]="data.tabellaPartenze">
                <ng-container matColumnDef="partenzaDa">
                  <th mat-header-cell *matHeaderCellDef> Partenza Da</th>
                  <td mat-cell *matCellDef="let element"> {{element.luogoPartenza | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="dataPartenza">
                  <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Data</th>
                  <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                    dal {{element.dal | date :'dd/MM/yyyy'}}
                    al {{element.al | date :'dd/MM/yyyy'}} </td>
                </ng-container>
                <ng-container matColumnDef="giorniNotti">
                  <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Giorni/Notti</th>
                  <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell"> {{element.giorniNotti}} </td>
                </ng-container>
                <ng-container matColumnDef="trattamento">
                  <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Trattamento</th>
                  <td mat-cell *matCellDef="let element"
                      class="d-none d-md-table-cell"> {{element.trattamento | titlecase}}</td>
                </ng-container>
                <ng-container matColumnDef="prezzo">
                  <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                  <td mat-cell *matCellDef="let element" class="bold ws-nowrap"><span
                    *ngIf="element.costoTotaleMinimo !== '0'">da {{element.costoTotaleMinimo}}</span></td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <a [routerLink]="element.url">
                      <button class="btn-prenota"
                              mat-raised-button>{{element.costoTotaleMinimo !== '0' ? 'VAI' : 'Calcola preventivo'}}</button>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
        <borsaviaggi-loader class="display-block mt-20px" [(show)]="loadingValues" typeMode="piker"
                            diameter="30"></borsaviaggi-loader>
      </div>
      <div *ngIf="objDataSource.length == 0">
        Nessuna offerta disponibile per il tipo di ricerca effettuato
      </div>
    </div>
  </div>
</div>
