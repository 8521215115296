import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "../../services/common/localStorage.service";
import {AuthService} from "../../services/common/auth.service";
import {UserService} from "../../services/gate/user.service";
import {ModalService} from "../../services/common/modal.service";
import {User} from "../../model/user.dto";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../constants/const.service";

@Component({
  selector: 'borsaviaggi-area-personale',
  templateUrl: './area-personale.component.html',
  styleUrls: ['./area-personale.component.scss'],
  animations: [
    trigger('expandHide', [
      state('expanded', style({
        height: '40px',
        width: '100%',
        borderRadius: '0px',
        backgroundImage: 'radial-gradient(circle at 0% 100%, #fa9803 20%, #ffa500 60%, transparent 100%)'
      })),
      state('hidden', style({
        height: '25px',
        width: '55px'
      })),
      transition('hidden => expanded', [
        animate('0.25s')
      ]),
      transition('expanded => hidden', [
        animate('0.25s')
      ])
    ])
  ]
})

export class AreaPersonaleComponent implements OnInit {

  public user: User;
  public expandBanner: boolean = false;
  public expandedText;
  public totaleCashback;
  public isPrime;
  public isMobileDevice: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private storage: LocalStorageService,
              private authSrv: AuthService,
              private deviceService: DeviceDetectorService,
              private userSrv: UserService,
              private modalSrv: ModalService) {

    this.user = this.authSrv.getUser();
    this.isPrime = this.user.userInfo.isPrime;
    if (this.route.snapshot.data && this.route.snapshot.data.dataResolve.body.status) {
      this.authSrv.clearUser();
      this.modalSrv.openSessionEnded();
    }
    this.getTotaleCashback();
    this.expandedText = this.isPrime ? 'Prime' : 'Il tuo cashback è di: ' + this.totaleCashback + ' €';
    this.isMobileDevice = this.deviceService.isMobile();
  }

  ngOnInit() {
  }

  public goToPage(event, link) {
    this.router.navigate(['area-personale/' + link], {
      state: this.user
    });
  }

  public actionLogout() {
    this.userSrv.logout().then(
      (response) => {
        if (response && response.body.status === 0) {
          this.authSrv.clearUser();
          this.modalSrv.openFeedbackModal({success: response.body.message});
          this.router.navigate(['/']);
        } else {
          this.modalSrv.openFeedbackModal({error: response.body.message});
        }
      },
      (error) => {
        this.modalSrv.openFeedbackModal({error: error});
      }
    );
  }


  public getTotaleCashback() {
    if (this.user) {
      this.totaleCashback = 0;
      if (this.user.listaBuoniSconto.length) {
        this.user.listaBuoniSconto.forEach((element) => {
          this.totaleCashback = this.totaleCashback + element.ImportoSconto
        });
      }
    }
  }

  public expandPrime() {
    if (this.isPrime) {
      this.expandBanner ? this.expandBanner = false : this.expandBanner = true;
      this.expandBanner ? this.expandedText = 'Il tuo cashback è di: ' + this.totaleCashback + ' €' : this.expandedText = 'Prime';
    }
  }
}
