<mat-card class="border-grey-1px mt-5px">
  <div class="col-12">
    <label class="subtitle col-12 p-0">La tua lista desideri</label>
  </div>
  <mat-card-content>
    <result-card *ngFor="let preferito of listaDesideri"
                 [config]="resultCardConfig" [data]="preferito"
                 (preferitoCancellato)="getListaDesideriUtente()"></result-card>
  </mat-card-content>
  <div class="col-12">
    <label class="col-12 p-0" *ngIf="listaDesideri.length===0">La tua lista desideri è vuota.</label>
  </div>
</mat-card>
