import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "./environments/environment";

@Injectable()
export class AppConfig {

  private config: Object = null;
  private env: any = null;

  constructor(private http: HttpClient) {

  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.env = environment.production ? "production" : "develop";
      let request: Observable<Object> = null;

      if (environment.production) {
        request = this.http.get('/config/config.production.json');
      } else if (environment.mock) {
        request = this.http.get("/config/config.mock.json");
      } else {
        request = this.http.get("/config/config.development.json");
      }

      if (request) {
        request
          .subscribe((responseData) => {
              this.config = responseData;
              resolve(true);
            },
            (error: any) => {
              console.error('Error reading ' + this.env + ' configuration file');
              resolve(error);
              return observableThrowError(error.body.error || 'Server error');
            });
      } else {
        console.error('Env config file "env.json" is not valid');
        resolve(true);
      }
    });
  }
}
