import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {LoginValidator} from "../../services/validator/login.validator";
import {UtilityService} from "../../services/gate/utility.service";
import {UserService} from "../../services/gate/user.service";
import {RegistrazioneFormComponent} from "../../components/registrazione-form/registrazione-form.component";
import {ModalService} from "../../services/common/modal.service";
import {AuthService} from "../../services/common/auth.service";
import * as moment from "moment";

@Component({
  selector: 'borsaviaggi-fuori-tutto',
  templateUrl: './fuori-tutto.component.html',
  styleUrls: ['./fuori-tutto.component.scss']
})
export class FuoriTuttoComponent implements OnInit, AfterViewInit {
  @ViewChild(RegistrazioneFormComponent) registrazione: RegistrazioneFormComponent;
  public formGroup: FormGroup = LoginValidator.formRegisterPrimeValidator;
  public alreadyNewsletter: any;
  public formValid = [];
  public creaAvvisoButton;

  constructor(private utilitySrv: UtilityService,
              private userSrv: UserService,
              private modalSrv: ModalService,
              private authSrv: AuthService) {

    moment.locale('it');

    this.getNewsletterInfo();
    this.authSrv.userObservable().subscribe(
      (dataUserLogged) => {
        this.getNewsletterInfo();
      }
    );
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.registrazioneValid();
    this.registrazione.formGroup.get('privacy').valueChanges.subscribe((val) => {
      this.creaAvvisoButton = val;
    });
  }

  public getNewsletterInfo() {
    this.userSrv.getNewsLetterUtente().then(
      (result) => {
        let newsletterData = result.body.data ? result.body.data : [];
        for (let i = 0; i < newsletterData.length; i++) {
          if (newsletterData[i].idTipoNewsLetter == 2) {
            this.alreadyNewsletter = newsletterData[i].isActive;
          }
        }
      },
      (error) => {

      }
    )
  }

  public registrazioneValid() {
    let formFields = ['nome', 'cognome', 'dataNascita', 'email', 'sesso', 'cellulare'];
    this.formValid = [];
    for (let i = 0; i < formFields.length; i++) {
      this.formValid.push(this.registrazione.formGroup.get(formFields[i]).status == 'DISABLED'
        || this.registrazione.formGroup.get(formFields[i]).valid);
    }
    this.formValid.push(!!this.registrazione.formGroup.get('idComune').valid);
    console.log('valid', this.registrazione.formGroup.get('idComune').value);
  }

  public signUp() {
    this.registrazioneValid();
    if (!this.formValid.includes(false)) {
      let dataNascita = new Date(this.registrazione.formGroup.controls['dataNascita'].value);
      let dataParam = {
        email: this.registrazione.formGroup.controls['email'].value,
        password: this.registrazione.formGroup.controls['password'].value,
        "nome": this.registrazione.formGroup.controls['nome'].value,
        "cognome": this.registrazione.formGroup.controls['cognome'].value,
        "dataNascita": moment(this.registrazione.formGroup.controls['dataNascita'].value).format('YYYY-MM-DD'),
        "sesso": this.registrazione.formGroup.controls['sesso'].value,
        "idComune": this.registrazione.formGroup.controls['idComune'].value,
        "cellulare": this.registrazione.formGroup.controls['cellulare'].value,
        "riceviNewsletterFuoriTutto": this.registrazione.formGroup.controls['riceviNewsletter'].value
      };
      this.userSrv.new(dataParam).then(
        (response) => {
          if (response && response.body && response.body.status === 0) {
            this.modalSrv.openFeedbackModal({success: response.body.message}, (data) => {
              this.alreadyNewsletter = true;
            });
          } else {
            this.modalSrv.openErrorDialog(response.body.message);
          }
          this.registrazione.formGroup.reset();
          this.registrazione.formGroup.markAsPristine();
          this.registrazione.formGroup.markAsUntouched();
        },
        (error) => {
          this.modalSrv.openErrorDialog(error);
        }
      )
    } else {
      for (let formControlName in this.registrazione.formGroup.controls) {
        this.registrazione.formGroup.get(formControlName).markAsTouched();
        this.registrazione.formGroup.get(formControlName).markAsDirty();
      }
    }
  }
}
