import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-recensioni-card',
  templateUrl: './recensioni-card.component.html',
  styleUrls: ['./recensioni-card.component.scss']
})
export class RecensioniCardComponent implements OnInit {
  @Input() recensioniData;
  public ratingPercent;

  constructor() {
  }

  ngOnInit(): void {
    this.getRatingPercent();
  }

  public getRatingPercent() {
    this.ratingPercent = this.recensioniData.rating * 100 / 5;
  }

}
