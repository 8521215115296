import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-links-banner',
  templateUrl: './links-banner.component.html',
  styleUrls: ['./links-banner.component.scss']
})
export class LinksBannerComponent implements OnInit {
  public linkItems;

  constructor() {
    this.linkItems = [
      {link: '/hotel-italia', text: 'HOTEL IN ITALIA'},
      {link: '/viaggi-italia', text: 'DESTINAZIONI IN ITALIA'},
      {link: '/hotel-mondo', text: 'HOTEL NEL MONDO'},
      {link: '/viaggi-mondo', text: 'DESTINAZIONI NEL MONDO'},
      {link: '/offerte/messico/z/20', text: 'MESSICO'},
      {link: '/offerte/fuerteventura/z/19', text: 'FUERTEVENTURA'},
      {link: '/offerte/maldive/z/18', text: 'MALDIVE'},
      {link: '/offerte/sharm/z/12', text: 'SHARM'},
      {link: '/offerte/cuba/z/14', text: 'CUBA'},
      {link: '/offerte/marsa-alam/z/13', text: 'MARSA ALAM'},
      {link: '/offerte/zanzibar/z/11', text: 'ZANZIBAR'},
      {link: '/offerte/playacar/z/10', text: 'PLAYACAR'}
    ];
  }

  ngOnInit() {
  }

}
