<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px background-white">
  <h1 class="color-text-blue-borsaviaggi text-align-center mb-20px">Qui dietro... ci siamo noi!</h1>
  <div class="row">
    <div class="col-sm order-2 order-sm-1">
      <p>
        Chi chiama per la prima volta al nostro Call Center ha deciso di accordarci la sua fiducia, almeno per una
        volta.
        Borsaviaggi.it prima di tutto è un'azienda che opera online da molti anni ma che non ha mai dimenticato
        la necessità di un rapporto diretto con i propri utenti.
      </p>
      <p><span class="orange-text">Sappiamo di doverti il massimo</span> e di dover dimostrare che meritiamo la tua
        fiducia!</p>
      <p>Già nel <span class="bold">1991</span> (qualche anno prima dell'avvento di Internet per il grande pubblico)
        questa era la filosofia di
        Borsaviaggi.it, il primo servizio italiano di informazioni e prenotazioni telefoniche di viaggi in offerta
        speciale ed ultimo minuto rivolto a clienti diffusi su tutto il territorio italiano.</p>
      <p>In questi anni abbiamo continuamente migliorato il servizio prediligendo lo sviluppo di un rapporto umano
        all'esasperata automazione dei sistemi di prenotazione.</p>
    </div>
    <div class="mb-10px align-text-right col-sm-4 order-1 order-sm-2 img-container">
      <img class="qui-image" src="assets/img/qui-dietro/img-qui-dietro.jpg" alt="immagine qui dietro">
    </div>
  </div>
  <div>
    <p>Lo sviluppo tecnologico e l'uso dei sistemi multimediali in Borsaviaggi.it è molto importante ed è visto come lo
      strumento per migliorare il metodo di lavoro e velocizzare i tempi di risposta per le sempre più pressanti
      richieste del mercato.</p>
    <p><span class="orange-text">Il nostro obiettivo è la soddisfazione totale del cliente</span>, raggiungibile solo
      attraverso la sensibilità e la
      flessibilità dell'intelligenza umana assicurata dalla presenza diretta e costante di Consulenti turistici
      professionali che assistono il consumatore in qualsiasi fase di informazione e prenotazione.</p>
    <p>Per questo, se telefoni al nostro <span class="orange-text">CALL CENTER</span> [ <span
      class="bold">06/5587667</span> ] per chiedere informazioni su come prenotare, sui
      vantaggi riservati ai nostri associati o sulle singole proposte di vacanza, <span class="orange-text">riceverai sempre la massima attenzione</span>
      e verrai seguito con la massima efficienza dai nostri consulenti, professionisti formati per capire le tue
      esigenze
      e assisterti dalla scelta della tua vacanza fino al rientro.</p>
  </div>
  <div>
    <h3 class="color-text-blue-borsaviaggi">Prova, siamo sicuri che noterai la differenza!</h3>
  </div>
  <div>
    <h2 class="color-text-blue-borsaviaggi font-italic name">Carlo Locchi</h2>
    <p>L'Amministratore di Borsaviaggi.it</p>
  </div>
</div>
