import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {ContainerDestinazioniService} from "../container-destinazioni.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioHotelItaliaResolveService {
  constructor(private containerSrv: ContainerDestinazioniService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let dataParam = {
      "idLocalita": routeSnap.params.idDestinazione,
      "isRegione": routeSnap.params.infoN == 'n'
    };

    return this.containerSrv.getLocalitaItalia(dataParam)
      .catch(err => {
        return err;
      });
  }
}
