<div mat-dialog-close class="appearance-initial position-relative float-right"
     [hidden]="showAlreadyPrime && !verifyEmail">
  <i class="material-icons">close</i>
</div>
<div mat-dialog-close class=" appearance-initial text-align-right" *ngIf="showAlreadyPrime && !verifyEmail"
     (click)="closeModal()">
  <i class="material-icons">close</i>
</div>
<div *ngIf="!showAlreadyPrime && !verifyEmail">
  <div class="header-modal mb-20px text-align-center full-width">
    <div>
      <!-- <mat-icon matPrefix class="mr-5px">perm_identity</mat-icon>-->
      <span mat-dialog-title class="inline margin-0">{{loginData.title}}</span>
    </div>
  </div>
  <div *ngIf="data.dataParam.banner">
    <img class="full-width" [src]="data.dataParam.banner">
  </div>
  <div *ngIf="data.dataParam.testoListaDesideri" class="testo-lista">
    {{data.dataParam.testoListaDesideri}}
  </div>
  <mat-dialog-content class="mb-20px p-15px-impo">
    <borsaviaggi-loader [(show)]="loading" typeMode='fullScreen'></borsaviaggi-loader>
    <form [formGroup]="loginForm">
      <div class="form-group">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput id="Email" autocomplete="off" placeholder="Email" type="text" formControlName="email">
          </mat-form-field>
        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline">
          <!--<mat-icon matPrefix>email</mat-icon>-->
          <mat-label>Password</mat-label>
          <input matInput id="password" autocomplete="off" placeholder="Password" type="password"
                 formControlName="password">
        </mat-form-field>
      </div>
      <div class="col-12 red">{{errorMessage}}</div>
    </form>
    <mat-dialog-actions align="center">
      <button (click)="login(loginForm.valid)" mat-button class="blue-button" type="submit">
        <span>Accedi >></span>
        <!-- <i class="material-icons">send</i>-->
      </button>
    </mat-dialog-actions>
    <p class="mt-2rem">Non sei registrato? <a class="color-text-orange-borsaviaggi cursor-pointer"
                                              (click)="actionSignUp()">Registrati!</a>
    </p>
    <p class="mt-1rem">Hai dimenticato la Password? <a class="color-text-orange-borsaviaggi cursor-pointer"
                                                       (click)="actionRecupero()">clicca qui</a>
    </p>
  </mat-dialog-content>
</div>
<div *ngIf="showAlreadyPrime && !verifyEmail">
  <mat-dialog-content>Risulta gia attivo un abbonamento prime per il tuo account.</mat-dialog-content>
</div>
<div *ngIf="!showAlreadyPrime && verifyEmail">
  <mat-dialog-content>Per procedere verifica la tua mail. Verifacata la mail inserisci le credenziali.
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="blue-button" mat-button (click)="returnToLogin()">
      <span>Procedi >></span>
    </button>
  </mat-dialog-actions>
</div>
