import {Injectable} from '@angular/core';
import {isNumber} from "util";

declare let google: any;
const ZOOM_SHOW_ICON = 9;

@Injectable()
export class AggregaPuntiService {
  private pointsMap = {};
  private pathImgCluster = 'assets/img/mappa/';
  // in metri zoom level [ 0 1 2 3 4 5 6 7 8 9 10 11 12 14
  // 15 16 17 18 19 20 21 22]
  private distanceRange = [100000, 90000, 80000, 70000,
    65000, 62500, 55000, 10000, 7000, 4000, 2500,
    1000, 500, 300, 150, 100, 50, 30, 20, 10, 5, 0];
  activeFilters: any;

  constructor() {
  }

  private getFileNameFromHeader(paramHeaderStr: string): string {
    let ret: string;
    if (paramHeaderStr) {
      ret = paramHeaderStr.substr((paramHeaderStr.indexOf("=") + 1), paramHeaderStr.length);
    }
    return ret;
  }


  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function generaMatricePunti
   *
   * @description Genera un Matrice dimensione n*m con
   *              tutti i punti (latitudine e longitudine)
   *              passati alla funzione Sulla base di
   *              questa matrice di punti verranno
   *              effettuati gli accorpamenti
   *
   * @param {Array}
   *            name arrPoints array con le coordinate dei
   *            punti. es coords =
   *            [{latitudine:41.12,longitudine:12.23}]
   *
   */
  private generaMatricePunti = function (arrPoints) {
    // this.pointsMap.arrMatrix = new
    // Array(arrPoints.length);
    this.pointsMap.arrMatrix = [];
    for (let index in arrPoints) {
      if (arrPoints[index].latitudine
        && arrPoints[index].longitudine
        && arrPoints[index].latitudine != ""
        && arrPoints[index].latitudine != 0
        && arrPoints[index].longitudine != ""
        && arrPoints[index].longitudine != 0) {
        let edrElem = {idxMatrix: 0};
        let coords = new Array(2);
        coords[0] = arrPoints[index].latitudine;
        coords[1] = arrPoints[index].longitudine;
        this.pointsMap.arrMatrix.push(coords);
        edrElem.idxMatrix = this.pointsMap.arrMatrix.length - 1;
        for (let key in arrPoints[index]) {
          if (key != "latitudine"
            && key != "longitudine") {
            edrElem[key] = arrPoints[index][key];
          }
        }
        this.pointsMap.arrEdr.push(edrElem);
      }
    }
  };

  private setMatricePunti = function (arrMatrix) {
    this.pointsMap.arrMatrix = arrMatrix;
  };

  private getPointsMap = function () {
    return this.pointsMap;
  };

  private setPointsMap = function () {
    this.pointsMap = {
      arrMatrix: undefined,
      arrEdr: [],
      arrMatrixDist: undefined,
      groupsMaster: [],
      maxElementBox: 20,
      maxZoomBox: 18
    }

  };

  public setActiveFilters( filters ) {
    this.activeFilters = filters;
    this.getPointsMap().groupsMaster = [];
  }

  public getImage = function (markerData) {
    return 'assets/img/icons/marker-bv-grigio.png';
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function getScaledImg
   *
   * @description Ridimensiona la grandezza del marker in
   *              funzione del numero degli elementi
   *              all'interno del gruppo
   *
   * @param {Int}
   *            name numElem numero degli elementi
   *            all'interno del gruppo
   *
   */
  private getScaledClass = function (numElem) {
    let rangeElem = [10, 50, 500, 2000];
    switch (true) {
      case numElem < rangeElem[0]:
        return 'clusterMarker0';
      case numElem < rangeElem[1]:
        return 'clusterMarker1';
      case numElem < rangeElem[2]:
        return 'clusterMarker2';
      case numElem < rangeElem[3]:
        return 'clusterMarker3';
      default:
        return 'clusterMarker4';
    }
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function getLabelAnchor
   *
   * @description Riposizione in label del marker con il
   *              numero degli elementi centrandolo
   *              rispetto all'immagine del marker
   *
   * @param {Int}
   *            name numElem numero degli elementi
   *            all'interno del gruppo
   */
  private getLabelAnchor = function (numElem) {
    let rangeElem = [10, 50, 500, 2000];
    switch (true) {
      case numElem < rangeElem[0]:
        return "11 11";
      case numElem < rangeElem[1]:
        return "15 15";
      case numElem < rangeElem[2]:
        return "17 17";
      case numElem < rangeElem[3]:
        return "23 23";
      default:
        return "28 28";
    }
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function addPuntiIniziali
   *
   * @description Genera il primo gruppo con tutte le
   *              coordinare dei punti. Livello 0 di
   *              default. Serve per ribalanciare tutti i
   *              gruppi in base allo zoom della mappa
   *
   * @param {Array}
   *            arrMatrixDist matrice della distanza dei
   *            punti
   * @param {Int}
   *            name level 0 di default (ogni numero
   *            identifica il livello di zoom)
   */
  private addPuntiIniziali = function (arrMatrixDist, level) {
    this.pointsMap.groupsMaster[level] = {
      groups: new Array()
    };
    for (let i = 0; i < arrMatrixDist.length; i++) {
      let group = {
        arrIdxElem: new Array(),
        name: undefined
      };
      group.name = "GR_" + i;
      group.arrIdxElem[0] = i;
      this.pointsMap.groupsMaster[level].groups
        .push(group);
    }
  };

  public getEdrData = function (arrIdxElem) {
    let edrList = [];
    for (let index in arrIdxElem) {
      edrList.push(this.pointsMap.arrEdr[arrIdxElem[index]]);
    }
    return edrList;
  };

  private filterData = function (edrList, keyToFilter) {
    let edrListFilterAll = [];
    for (let edrItem in edrList) {
      let edrListFilter = {};
      for (let key in keyToFilter) {
        // keyToFilter.map(function(key){
        for (let elem in edrList[edrItem]) {
          if (elem.toLowerCase() == keyToFilter[key].fkey
              .toLowerCase()) {
            edrListFilter[keyToFilter[key].alias] = edrList[edrItem][elem];
          }
        }
      }
      edrListFilterAll.push(edrListFilter)
    }
    return edrListFilterAll;
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function generaCoordinateGruppi
   *
   * @description Estrae le coordinate dei punti
   *              raggruppati prendendole dalla matrice
   *              generale dei punti Tali punti sono
   *              associati ad un livello di zoom
   * @param {Int}
   *            name level livello di zoom della mappa
   */
  private generaCoordinateGruppi = function (level) {
    let levelGroup = this.pointsMap.groupsMaster[level];
    for (let index in levelGroup.groups) {
      let group = levelGroup.groups[index];
      group.coordinates = new Array();
      group.coordinates.numElem = 0;
      for (let k in group.arrIdxElem) {
        let coords = {idxMatrix: "", latitude: "", longitude: "", visible: true};
        coords.idxMatrix = k;
        coords.latitude = this.pointsMap.arrMatrix[group.arrIdxElem[k]][0];
        coords.longitude = this.pointsMap.arrMatrix[group.arrIdxElem[k]][1];
        group.animation = this.pointsMap.arrEdr[group.arrIdxElem[k]].animation;
        group.coordinates.numElem += 1;
        group.coordinates.push(coords);
      }
      this.calcolaBaricentroPunti(group);
    }
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function createMarkersGroup
   *
   * @description Creazione dei Markers sulla mappa in
   *              base a livello di zoom. Nel caso
   *              l'elemento sia 1 viene modificata
   *              l'immagine con l'elemento
   *              corrisponedente (Armadio,PPU, ecc...)
   * @param {Int}
   *            name level livello di zoom della mappa
   */
  private createMarkersGroup = function (level) {
    // distruggo markers precedenti
    let markers = [];
    let groups = this.getPointsMap().groupsMaster[level].groups;
    for (let index in groups) {

      let makerG = {
        id: undefined,
        idGroup: undefined,
        arrIdxElem: undefined,
        infoWindowIsOpen:false,
        options: {
          draggable: false,
          animation: undefined,
          labelContent: undefined,
          labelOptions: undefined,
          labelAnchor: undefined,
          icon: undefined,
          labelClass: 'labels-map'
        },
        coords: {
          latitude: undefined,
          longitude: undefined
        },
        visible: true
      };
      makerG.id = 'l' + level + '-mk' + index;
      makerG.idGroup = groups[index].name;
      makerG.arrIdxElem = groups[index].arrIdxElem;
      // indice degli edr nel marker group
      makerG.coords.latitude = groups[index].coordinates.average.latitude;
      makerG.coords.longitude = groups[index].coordinates.average.longitude;
      makerG.options.animation = groups[index].animation ? google.maps.Animation.BOUNCE : false;
      // se non esistono elementi visibili nel gruppo, nascondo il marker cluster
      makerG.visible = groups[index].coordinates.numElem > 0 ? true : false;
      makerG.options.icon = {
        url: this.managerImagePointer(level,
          groups[index], makerG)
      };
      makerG.options.labelContent = groups[index].coordinates.numElem + "";
      makerG.options.labelOptions = level > ZOOM_SHOW_ICON && groups[index].coordinates.numElem === 1 ? undefined : {
        color: 'white',
        fontSize: '10px',
        text: makerG.options.labelContent + "",
      };
      makerG.options.labelAnchor = this
        .getLabelAnchor(groups[index].coordinates.numElem);
      makerG.options.labelClass = makerG.options.labelContent ? this
          .getScaledClass(groups[index].coordinates.numElem)
        : "custom-marker";
      markers.push(makerG);
    }
    return markers;
  };

  private getVisibleMarkerIdx(group) {
    for (let i = 0; i < group.arrIdxElem.length; i++ ) {
      if (group.coordinates[i].visible ) {
        return group.arrIdxElem[i];
      }
    }
  }
  private managerImagePointer = function (level, groupItem,
                                          makerG) {
    let ret = "resources/images/mappa/transparent.gif";
    if (level > ZOOM_SHOW_ICON
      && groupItem.coordinates.numElem == 1
      /* || this.pointsMap.arrEdr[makerG.arrIdxElem[0]].showSingleIcon */
      ||  groupItem.coordinates.numElem == 1  && this.pointsMap.arrEdr[this.getVisibleMarkerIdx(groupItem)].showSingleIcon
      ) {
      ret = this.getImage(this.pointsMap.arrEdr[this.getVisibleMarkerIdx(groupItem)]);
    } else {
      let classElement = this.getScaledClass(groupItem.coordinates.numElem);
      switch (classElement) {
        case "clusterMarker0":
          ret = this.pathImgCluster + "circle-0x.png";
          break;
        case "clusterMarker1":
          ret = this.pathImgCluster + "circle-1x.png";
          break;
        case "clusterMarker2":
          ret = this.pathImgCluster + "circle-2x.png";
          break;
        case "clusterMarker3":
          ret = this.pathImgCluster + "circle-3x.png";
          break;
        case "clusterMarker4":
          ret = this.pathImgCluster + "circle-4x.png";
          break
      }
    }
    return ret;
  };

  public setUpMarkerAggregation = function (items) {
    this.setPointsMap(); // clean element
    this.generaMatricePunti(items);
    if (this.getPointsMap().arrMatrix[0]) {
      this.generaMatriceDistanzePunti(this.getPointsMap().arrMatrix);
      this.addPuntiIniziali(
        this.getPointsMap().arrMatrixDist, 0);
    } else {
      console
        .log("Non sono presenti coordinate per calcolare distanze!");
      return "KO";
    }
  };

  private startManagerLevelGroups = function (level) {
    let arrMatrixDistClone = this.cloneMatrix(this.getPointsMap().arrMatrixDist);
    this.calcolaDistanzaOnRange(arrMatrixDistClone, this.distanceRange[level], 0, level);
    this.generaCoordinateGruppi(level);

    return this.getPointsMap().groupsMaster[level];
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function visualizzaMarkersGrouped
   *
   * @description Creazione dei Markers sulla mappa in
   *              base a livello di zoom. All'interno del
   *              metodo viene calcolata la distanza,
   *              raggruppati i markers e calcolato il
   *              baricentro per il posizionamento sulla
   *              mappa *
   * @param {Int}
   *            name level livello di zoom della mappa
   */
  public visualizzaMarkersGrouped = function (level) {
    // se non ho già creato i gruppi
    if (!this.getPointsMap().groupsMaster[level]) {
      this.startManagerLevelGroups(level);
    }
    console.log("######## NUMERO GRUPPI CREATI ####### " + this.getPointsMap().groupsMaster[level].groups.length);
    return this.createMarkersGroup(level);
  };

  public getZoomMapToBlastPointer = function (itemModel,
                                              mapZoom, maxMapZoom) {
    if (itemModel
      && mapZoom !== undefined
      && maxMapZoom !== undefined
      && isNumber(maxMapZoom)
      && isNumber(mapZoom)) {
      let initialGroupsLevel = this.getPointsMap().groupsMaster[mapZoom]
        || this
          .startManagerLevelGroups(mapZoom);
      let zoomedGroupLevel = this.getPointsMap().groupsMaster[mapZoom + 1]
        || this
          .startManagerLevelGroups(mapZoom + 1);
      while (this.getGroupFromId(
        initialGroupsLevel.groups,
        itemModel.idGroup).length === this
        .getGroupFromId(
          zoomedGroupLevel.groups,
          itemModel.idGroup).length
      && mapZoom < maxMapZoom) {
        mapZoom++;
        initialGroupsLevel = this.getPointsMap().groupsMaster[mapZoom]
          || this
            .startManagerLevelGroups(mapZoom);
        zoomedGroupLevel = this.getPointsMap().groupsMaster[mapZoom + 1]
          || this
            .startManagerLevelGroups(mapZoom + 1);
      }
      return mapZoom + 1;
    } else {
      console
        .error("Errore valorizzare i parametri in ingresso !!!");
    }
  };

  public getZoomMapShowIcon() {
    return ZOOM_SHOW_ICON + 1;
  }

  private getGroupFromId = function (groups, paramid) {
    let ret = undefined;
    if (groups && paramid !== undefined) {
      groups.map(function (currentGroup) {
        if (paramid === currentGroup.name) {
          ret = currentGroup.arrIdxElem;
        }
      });
    } else {
      console.error("Errore valorizzare i parametri in ingresso !!!");
    }
    return ret;
  };

  private calcolaBaricentroPunti = function (group) {
    let coordsAll = {
      latitude: 0,
      longitude: 0
    };
    group.coordinates.average = {};
    for (let k in group.arrIdxElem) {
      if ( group.coordinates[k].visible ) {


      /*
                       * coordsAll.latitude +=
                       * parseFloat(group.coordinates[k].latitude);
                       * coordsAll.longitude +=
                       * parseFloat(group.coordinates[k].longitude);
                       */
      coordsAll.latitude += parseFloat(group.coordinates[k].latitude);
      coordsAll.longitude += parseFloat(group.coordinates[k].longitude);
      }
    }

    /* group.coordinates.average.latitude = coordsAll.latitude
      / group.arrIdxElem.length;
    group.coordinates.average.longitude = coordsAll.longitude
      / group.arrIdxElem.length; */

    // se gruppo non ha elementi visibili SKIP
    if (group.coordinates.numElem > 0) {
      group.coordinates.average.latitude = coordsAll.latitude
        / group.coordinates.numElem;
      group.coordinates.average.longitude = coordsAll.longitude
        / group.coordinates.numElem;
    }
  };

  private creoLevelAggregationGroups = function (level) {
    return this.pointsMap.groupsMaster[level] = {
      groups: new Array()
    }
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function aggregaPunti
   *
   * @description Calcola la distanza dai punti e li
   *              raggruppa se sono sotto una certa
   *              distanza definita dal livello di zoom
   *
   * @param {Int}
   *            name levelStart livello iniziale da dove
   *            calcolare il raggruppamento
   * @param {Int}
   *            name levelDestGroup livello dove inserire
   *            il raggruppamento
   * @param {Int}
   *            indX coordinate indice dell'elemento da
   *            raggruppare
   * @param {Int}
   *            indY coordinate indice dell'elemento da
   *            raggruppare
   * @param {Array}
   *            name arMatrixDist Matrice della distanza
   *            dei punti
   */
  private aggregaPunti = function (levelStar, levelDestGroup,
                                   indX, indY, arMatrixDist) {
    let groupExists = false;
    for (let index in levelDestGroup.groups) {
      let group = levelDestGroup.groups[index];
      for (let k in group.arrIdxElem) {
        if (group.arrIdxElem[k] == indX
          || group.arrIdxElem[k] == indY) {
           let indXY = (group.arrIdxElem[k] == indX) ? indY
            : indX;
          group.arrIdxElem.push(indXY);

          // distruggo tutti punti delle distanze
          // rispetto agli elementi del gruppo già
          // presenti
          group.arrIdxElem
            .map(function (indZ) {
              arMatrixDist[indXY][indZ] = undefined;
              arMatrixDist[indZ][indXY] = undefined;
              arMatrixDist[indZ][indXY] = undefined;
              arMatrixDist[indXY][indZ] = undefined;
            });

          // console.log(" Gruppo: " + group.name
          // + " Elem: " + group.arrIdxElem);
          groupExists = true;
          break;
        }
      }
    }

    // se ancora non esiste il gruppo lo creo
    if (!groupExists) {
      this
        .creaLonelyGroup(levelDestGroup, indX,
          indY);
      console.log("Gruppi solitari : " + indX + "  "
        + indY);
      arMatrixDist[indX][indY] = undefined;
      arMatrixDist[indY][indX] = undefined;
    }
  };

  private creaLonelyGroup = function (levelDestGroup, indX,
                                      indY) {
    let groupDest = {
      arrIdxElem: new Array(),
      name: undefined
    };
    groupDest.name = "GR_" + indX;
    groupDest.arrIdxElem.push(indX);
    if (indY) {
      groupDest.arrIdxElem.push(indY);
    }
    levelDestGroup.groups.push(groupDest);
  };

  private cloneMatrix = function (aMatrix) {
    let aMatrixCloned = new Array(aMatrix.length);
    for (let i = 0; i < aMatrix.length; i++) {
      aMatrixCloned[i] = new Array(i);
      for (let j = 0; j < i; j++) {
        aMatrixCloned[i][j] = aMatrix[i][j];
      }
    }
    return aMatrixCloned;
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function generaMatriceDistanzePunti
   *
   * @description Genera la matrice della distanza dei
   *              punti
   *
   * @param {Array}
   *            name aMatrix Matrice della distanza dei
   *            punti
   */
    // distanze tra tutti i punti
  private generaMatriceDistanzePunti = function (aMatrix) {
    let distEucl = 0, aMatrixDist = new Array(
      aMatrix.length);
    for (let i = 0; i < aMatrix.length; i++) {
      aMatrixDist[i] = new Array(i);// new
      // Array(aMatrix.length);
      for (let j = 0; j < aMatrix.length && j != i; j++) {
        // per ogni punto calcolo la sua distanza
        // rispetto ad un altro
        distEucl = this.calcolaDistanzaPunti(i, j,
          aMatrix);
        aMatrixDist[i][j] = distEucl;
      }
    }

    // assegno alla matrice generale delle distanze dei
    // punti
    this.pointsMap.arrMatrixDist = aMatrixDist;
  };

  // Metodo che calcola la distanza dei punti tramite la
  // distanza Euclidea
  private calcolaDistanzaPunti = function (nRiga, mRiga,
                                           arrMatrix) {
    let quadDistEucl = 0;
    // metodo Distanza Euclidea (espressa in metri)
    quadDistEucl = Math
      .sqrt((Math
          .pow(
            (arrMatrix[nRiga][0] - arrMatrix[mRiga][0]),
            2))
        + Math
          .pow(
            (arrMatrix[nRiga][1] - arrMatrix[mRiga][1]),
            2)) * 100000;
    return quadDistEucl;
  };

  /**
   * @ngdoc service
   * @name gate-services.service:AggregaPuntiService
   * @function generaMatriceDistanzePunti
   *
   * @description Calcola la distanza dai punti e li
   *              raggruppa se sono sotto una certa
   *              distanza definita dal livello di zoom
   *
   * @param {Array}
   *            name arMatrixDist Matrice della distanza
   *            dei punti
   * @param {Int}
   *            range distanza entro il quale eseguire il
   *            raggruppamento
   * @param {Int}
   *            name levelStart livello iniziale da dove
   *            calcolare il raggruppamento
   * @param {Int}
   *            name levelDest livello dove inserire il
   *            raggruppamento
   */

  private calcolaDistanzaOnRange = function (arMatrixDist,
                                             range, levelStart, levelDest) {
    let dist = 99999999999, indX = 0, indY = 0;
    let levelGroupDest = this
      .creoLevelAggregationGroups(levelDest);
    // ciclo sulla matrice delle distanze per estrarre
    // ogni volta la distanza all'interno del range
    for (let i = 0; i < arMatrixDist.length; i++) {
      let lonely = true;
      for (let j = 0; j < arMatrixDist.length
      && j != i; j++) {
        dist = arMatrixDist[i][j];
        if (dist < range && dist >= 0) {
          indX = i;
          indY = j;
          this.aggregaPunti(levelStart,
            levelGroupDest, indX, indY,
            arMatrixDist);
          // console.log("Aggrego idx: " + indX +
          // " con idx: " + indY + " Distance:" +
          // dist);
          lonely = false;
          break;
        }
      }
      if (lonely) {
        // creo gruppo solitario
        this.creaLonelyGroup(levelGroupDest, i,
          undefined);
        arMatrixDist[indX][indY] = undefined;
        arMatrixDist[indY][indX] = undefined;
      }

    }

  }
}
