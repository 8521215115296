<div class="container border-r-2px mt-20px text-align-justify background-white mb-20px pb-20px">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi mb-5px">SCRIVI AL DIRETTORE</h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-20px">
        Questa pagina è dedicata alle nostre decine di migliaia di utenti iscritti che
        desiderano scrivermi esponendo suggerimenti o critiche.
        Poiché ritengo fondamentale, per un'azienda in continua crescita come Borsaviaggi.it,
        mantenere dinamico questo scambio di informazioni ed opinioni curando un rapporto personale
        con ogni singolo utente, garantisco che leggerò ogni appunto rispondendo alle questioni che
        meritano un approfondimento.
      </div>
      <div class="mt-15px mb-15px">
        Per non disperdere comunicazioni e dati utili al nostro servizio statistico
        <span class="font-weight-bold">NON</span> utilizzare questa rubrica per:
      </div>
      <ul>
        <li>
          <span class="font-weight-bold">esprimere commenti</span> al ritorno da viaggi o soggiorni,
          ma utilizza il modulo che riceverai via email al tuo rientro dalla vacanza
        </li>
        <!--li>
          comunicare valutazioni sul servizio di informazioni ed assistenza offerto
          da Borsaviaggi.it prima di effettuare la prenotazione, per il quale puoi compilare
          il questionario che trovi cliccando qui.
        </li-->
      </ul>
      <div class="mt-20px">
        Desidero ringraziarti sin da ora per il prezioso apporto che contribuirà al continuo miglioramento dei nostri servizi.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-20px">
      <h2 class="color-text-blue-borsaviaggi">Compila i campi qui di seguito:</h2>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="formGroup" class="mt-40px" >
      <div class="row mt-20px">
        <div class="col-md-6 col-sm-12">
          <mat-form-field appearance="outline" class="campi-input">
            <mat-label>La tua email</mat-label>
            <input required type="email" matInput formControlName="email">
            <mat-error *ngIf="formGroup.get('email').invalid">
              <div *ngIf="formGroup.get('email').hasError('required'); else errorEmail">
                Il campo email è obbligatorio
              </div>
              <ng-template #errorEmail>
                Email non valida
              </ng-template>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field  appearance="outline" class="campi-input">
            <mat-label>Nome</mat-label>
            <input required type="text" matInput formControlName="nome">
            <mat-error *ngIf="formGroup.get('nome').invalid">
              <div>Il campo nome è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    <div class="row mt-20px">
      <div class="col-md-6 col-sm-12">
        <mat-form-field appearance="outline" class="campi-input">
          <mat-label>Cognome</mat-label>
          <input required type="text" matInput formControlName="cognome">
          <mat-error *ngIf="formGroup.get('cognome').invalid">
            <div>Il campo cognome è obbligatorio</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-sm-12">
        <mat-form-field  appearance="outline" class="campi-input" (click)="picker.open()">
          <mat-label>Data di nascita</mat-label>
          <input required matInput [matDatepicker]="picker" formControlName="dataNascita">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="formGroup.get('dataNascita').invalid">
            <div>Il campo data di nascita è obbligatorio</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
      <div class="row mt-20px">
        <div class="col-md-6 col-sm-12">
          <mat-form-field appearance="outline" class="campi-input">
            <mat-label>Messaggio</mat-label>
            <textarea required matInput formControlName="messaggio"></textarea>
            <mat-error *ngIf="formGroup.get('messaggio').invalid">
              <div>Il campo messaggio è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col">
          <mat-checkbox matInput formControlName="informativaPrivacy">
          </mat-checkbox>
          <span class="ml-5px">Ho letto ed accetto l'<a routerLink="/informativa-privacy">Informativa Privacy</a> in merito al trattamento dei miei dati personali.</span>
        </div>
      </div>
      <div class="row mt-40px">
        <div class="col mr-20px text-align-center">
          <borsaviaggi-promise-btn
            type="submit"
            [loading]="loading"
            buttonText="Invia"
            (buttonClick)="send(formGroup.valid)"
            [disabled]="!formGroup.valid"
            buttonClass=" no-gutters padding-0 background-blue-borsaviaggi color-white">

          </borsaviaggi-promise-btn>
        </div>
      </div>
    </form>
  </div>
</div>
