import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ModalService} from "../../services/common/modal.service";
import {AuthService} from "../../services/common/auth.service";
import {Router} from "@angular/router";
import {UserService} from "../../services/gate/user.service";

@Component({
  selector: 'borsaviaggi-prime-page',
  templateUrl: './prime-page.component.html',
  styleUrls: ['./prime-page.component.scss']
})
export class PrimePageComponent implements OnInit {
  public images;
  public userData;

  constructor(private dialog: MatDialog, private modalSrv: ModalService,
              private authSrv: AuthService, private router: Router,
              private userSrv: UserService) {
    this.images = {
      'banner': '../../assets/img/prime/Prime-Banner-PrimeDays-png'
    };
    this.userData = this.authSrv.getUser();
    console.log('this.userData', this.userData);
    this.authSrv.userObservable().subscribe(
      (dataUserLogged) => {
        if (dataUserLogged) {
          this.userData = this.authSrv.getUser()
        } else {
          this.authSrv.clearUser();
          this.userData = this.authSrv.getUser()
        }
      }
    );
  }

  ngOnInit() {
  }

  public getPrimeOrRegister() {
    this.userData = this.authSrv.getUser();
    if (!this.userData) {
      this.modalSrv.openRegistrazioneModal({'activatePrime': true},
        (data) => {
          //this.userSrv.getUtente();
          //this.userData = this.authSrv.getUser();
          //console.log(this.userData);
        }
      );
    } else if (this.userData && !this.userData.isPrime) {
      document.location.href = 'https://www.borsaviaggi.it/Contratti/Pos.aspx?from=abbonamento-prime&IdUtente=' + this.userData.idUtente;
      //this.router.navigate(['abbonamento-prime']);
    }
  }
}
