<div class="header-modal mb-10px">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <div class="d-flex align-items-center justify-content-left">
    <mat-icon matPrefix class="mr-5px">room</mat-icon>
    <span mat-dialog-title class="inline margin-0">{{destinazioniData.title}}</span>
  </div>
</div>
<mat-dialog-content class="mb-20px">
  <mat-tab-group class="help" mat-align-tabs="start" [@.disabled]="true" [(selectedIndex)]="currentTabIndex" (selectedTabChange)="tabChange($event)">
    <mat-tab label="Destinazioni Mondo" *ngIf="currentTabIndex == 0">
      <ng-template mat-tab-label>
        <div class="destination-tab-label">Destinazioni Mondo</div>
        <mat-icon class="ml-2">language</mat-icon>
      </ng-template>
      <div class="container-fluid wrapper-destinazione mb-10px mt-10px" *ngFor="let destinazione of listDestinazioni">
        <div class="title-destinazione mb-10px">
          <img *ngIf="destinazione.urlIcona" [src]="destinazione.urlIcona" class="mr-5px">
          <span class="fs-1_2rem fw-600">{{destinazione.titolo}}</span>
        </div>

        <div class="container">
          <div class="row">
            <div *ngFor="let localita of destinazione.destinazioni" class="col-sm-12 col-md-3">
              <a *ngIf="localita.link && !isMobile" [href]="localita.link" [title]="localita.nome">
                <mat-icon class="icon-external-dest">launch</mat-icon>
                <span class="span-external-dest">{{localita.nome}}</span>
              </a>
              <a *ngIf="localita.link && isMobile" [href]="localita.link" [title]="localita.nome">
                <mat-icon class="icon-external-dest">launch</mat-icon>
                <span class="span-external-dest fs-16px">{{localita.nome}}</span>
              </a>
              <mat-checkbox *ngIf="!localita.link && !isMobile" name="localitaCheckBox" ngDefaultControl
                            class="nome-destinazione"
                            (change)="actionCheckUnCheck(false)"
                            [disabled]="localita.disabled"
                            [(ngModel)]="localita.checked">
                <span>{{localita.nome}}</span>
              </mat-checkbox>
              <mat-checkbox *ngIf="!localita.link && isMobile" name="localitaCheckBox" ngDefaultControl
                            class="nome-destinazione"
                            (change)="actionCheckUnCheck(false)"
                            [disabled]="localita.disabled"
                            [(ngModel)]="localita.checked">
                <span class="fs-16px">{{localita.nome}}</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Destinazioni Italia" *ngIf="currentTabIndex == 1">
      <ng-template mat-tab-label>
        <div class="destination-tab-label">Destinazioni Italia</div>
        <div class="mb-10px"><img class="italia-svg" src="../../../../assets/img/icons/italy_mod.svg"></div>
      </ng-template>
      <div class="container-fluid wrapper-destinazione mb-10px mt-10px"
           *ngFor="let destinazione of listDestinazioniMareItalia">
        <div class="title-destinazione mb-10px">
          <img *ngIf="destinazione.urlIcona" [src]="destinazione.urlIcona" class="mr-5px">
          <span class="fs-1_2rem fw-600">{{destinazione.titolo}}</span>
        </div>
        <div class="container">
          <div class="row">
            <div *ngFor="let localita of destinazione.destinazioni" class="col-sm-12 col-md-3">
              <a *ngIf="localita.link && !isMobile" [href]="localita.link" [title]="localita.nome">
                <mat-icon class="icon-external-dest">launch</mat-icon>
                <span class="span-external-dest">{{localita.nome}}</span>
              </a>
              <a *ngIf="localita.link && isMobile" [href]="localita.link" [title]="localita.nome">
                <mat-icon class="icon-external-dest">launch</mat-icon>
                <span class="span-external-dest fs-16px">{{localita.nome}}</span>
              </a>
              <mat-checkbox *ngIf="!localita.link && !isMobile" name="localitaCheckBox" ngDefaultControl
                            (change)="actionCheckUnCheck(true)"
                            [disabled]="localita.disabled"
                            [(ngModel)]="localita.checked">
                {{localita.nome}}
              </mat-checkbox>
              <mat-checkbox *ngIf="!localita.link && isMobile" name="localitaCheckBox" ngDefaultControl
                            (change)="actionCheckUnCheck(true)"
                            [disabled]="localita.disabled"
                            [(ngModel)]="localita.checked">
                <span class="fs-16px">{{localita.nome}}</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <div class="display-flex text-align-center">
    <button mat-button class="background-orange-borsaviaggi color-white"
            (click)="actionConfermaDestinazioni()"
            [disabled]="disabledConferma()">
      <span>Conferma</span>
      <i class="material-icons">done</i>
    </button>
  </div>
</mat-dialog-actions>
