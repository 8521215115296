import {Component, ElementRef, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorModalDto} from "../../../model/errorModal.dto";
import {ModalInterface} from "../../../interface/modal.interface";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ResponseCheckerService} from "../../../services/common/response.checker.service";
import {LoaderService} from "../../loader/loader.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {RicercaCrociereService} from "../../../services/gate/ricerca-crociere.service";

@Component({
  selector: 'borsaviaggi-modifica-occupazione-crociera-modal',
  templateUrl: './modifica-occupazione-crociera-modal.component.html',
  styleUrls: ['./modifica-occupazione-crociera-modal.component.scss']
})
export class ModificaOccupazioneCrocieraModalComponent implements OnInit {
  public errorData = new ErrorModalDto();
  public pPageData: any;
  public supplementi: any;
  public arrayPax;
  public formGroup: FormGroup = new FormBuilder().group({
    numeroPasseggeri: new FormControl('2', Validators.compose([]))
  });
  public listaCabine = [];
  public mostraPulsanteVerifica: boolean = true;
  public loading: boolean;
  public isMobileDevice: boolean;
  public nessunaDisponibilita: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(public dialogRef: MatDialogRef<ModificaOccupazioneCrocieraModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<ModificaOccupazioneCrocieraModalComponent>,
              private elementRef: ElementRef,
              private deviceService: DeviceDetectorService,
              private loaderSrv: LoaderService,
              private crociereSrv: RicercaCrociereService) {

    this.isMobileDevice = this.deviceService.isMobile();

    if (Object.keys(data).length) {
      for (let key in data) {
        this.errorData[key] = data[key] || this.errorData[key];
      }
    }

    this.mostraPulsanteVerifica = true;
  }

  ngOnInit(): void {
    this.generateFormGroup(true);
    //this.verificaDisponibilita();
  }

  public generateFormGroup(isPageLoad) {
    this.arrayPax = [];
    this.mostraPulsanteVerifica = true;
    this.listaCabine = [];
    this.formGroup.addControl("cabinaSelezionata", new FormControl('', Validators.required));

    if (this.data.dataParam.dataResponse.isVoloIncluso) {
      this.formGroup.addControl("volo", new FormControl('', Validators.required));
    }

    if (isPageLoad) {
      for (let i = 1; i <= 2; i++) {
        this.formGroup.addControl("etaPax-" + i, new FormControl('35', Validators.compose([])));
        this.formGroup.addControl("etaPax-" + i, new FormControl('35', Validators.compose([])));
        this.arrayPax.push(i);
      }
    }
    else {
      for (let i = 1; i <= this.formGroup.get("numeroPasseggeri").value; i++) {
        this.formGroup.addControl("etaPax-" + i, new FormControl('35', Validators.compose([])));
        this.arrayPax.push(i)
      }
    }
  }

  public verificaDisponibilita() {
    this.loading = true;
    let paramRq = {
      "idCrociera": this.data.dataParam.dataResponse.idCrociera,
      "codiceCrociera": this.data.dataParam.dataResponse.codiceCrociera,
      "haVolo": this.data.dataParam.dataResponse.haVolo,
      "eta": []
    };

    this.arrayPax.forEach((indiceCamera, index) => {
      paramRq.eta.push(
          this.formGroup.get('etaPax-' + indiceCamera).value
      );
    });

    if (this.data.dataParam.dataResponse.nomeCompagnia == "MSC Crociere") {
      this.crociereSrv.getQuotazioneCabinaMsc(paramRq).then(
        (response) => {
          if (ResponseCheckerService.isSuccessResponse(response.body)) {
            this.listaCabine = response.body.data;
            this.nessunaDisponibilita = this.listaCabine.length === 0;
            this.mostraPulsanteVerifica = false;
            setTimeout(
              () => {
                let scrollOffset = this.elementRef.nativeElement.querySelector('mat-dialog-content').offsetHeight;
                this.elementRef.nativeElement.querySelector('mat-dialog-content').scrollTo(0, scrollOffset);
              }, 0
            );
          } else {
            console.error(ResponseCheckerService.getErrorMessage(response.body));
          }

        },
        (error) => {
          this.nessunaDisponibilita = true;
          console.error("Errore Verifica disponibilità: " + error);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
    else if (this.data.dataParam.dataResponse.nomeCompagnia == "Costa Crociere") {
      this.crociereSrv.getQuotazioneCabinaCosta(paramRq).then(
        (response) => {
          if (ResponseCheckerService.isSuccessResponse(response.body)) {
            this.listaCabine = response.body.data;
            this.nessunaDisponibilita = this.listaCabine.length === 0;
            this.mostraPulsanteVerifica = false;
            setTimeout(
              () => {
                let scrollOffset = this.elementRef.nativeElement.querySelector('mat-dialog-content').offsetHeight;
                this.elementRef.nativeElement.querySelector('mat-dialog-content').scrollTo(0, scrollOffset);
              }, 0
            );
          } else {
            console.error(ResponseCheckerService.getErrorMessage(response.body));
          }

        },
        (error) => {
          this.nessunaDisponibilita = true;
          console.error("Errore Verifica disponibilità: " + error);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  public generatePreventivoRq() {
    let idVoloRichiesto = this.formGroup.controls['volo'] ? (this.formGroup.controls['volo'].value + '' === '' ? null : this.formGroup.controls['volo'].value + '') : null;
    let cabinaSelezionata = this.formGroup.get('cabinaSelezionata').value.split('$');
    let totaleCabina = cabinaSelezionata[3];
    let arrayEta = [];

    this.arrayPax.forEach((i) => {
      arrayEta.push(
        this.formGroup.get('etaPax-' + i).value
      );
    });

    let dataParam = {
      idCrociera: this.data.dataParam.dataResponse.idCrociera,
      numCabine: 1,
      numeroPasseggeri: this.formGroup.get('numeroPasseggeri').value || 0,
      etaPasseggeri: arrayEta,
      categoriaCabina: cabinaSelezionata[1],
      cabina: cabinaSelezionata[2],
      codiceTariffa: cabinaSelezionata[0],
      costoCabina: cabinaSelezionata[3],
      isVoloIncluso: this.data.dataParam.dataResponse.isVoloIncluso,
      idVoloRichiesto: idVoloRichiesto,
      nomeTariffa: cabinaSelezionata[4]
    };

    let datiPreventivo = {
      "dataParam": dataParam,
      "totaleCabina": totaleCabina,
      "formGroup": this.formGroup
    };
    this.dialogRef.close(datiPreventivo);
  }
}
