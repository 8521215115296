<mat-grid-tile-footer class="validation"
                      [hidden]="formControl.valid || (formControl.pristine || !formControl.touched )"
                      *ngIf="formControl">
  <ng-container *ngFor="let validation of formErrorMessage">
    <div class="error-message"
         *ngIf="formControl.hasError(validation.type) ">

      <label>
        <span>{{ validation.message }}</span>
        <span *ngIf="showComma(validation)">,</span>
      </label>
    </div>
  </ng-container>
</mat-grid-tile-footer>
