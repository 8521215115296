import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class UtilityService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getComuni(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/utility/getComuni");
  }

  public getComuniByRegione(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/utility/getComuniByRegione",data);
  }

  public getRegioni(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/utility/getRegioni");
  }

  public getArticoli(): Promise<any>{
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/utility/getArticoli");
  }

  public getLandingByUrl(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/utility/getLandingByUrl", data);
  }

  public getRegistrazioneLanding(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/utility/registrazioneLanding", param);
  }

  public registrazioneTravelExpert(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/utility/registrazioneTravelExpert", param);
  }

  public getAvvisoPop(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/utility/getAvvisoPop");
  }

  public getPromozioneAttiva(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/utility/getPromozioneAttiva");
  }

  public getDatiContrattoQuestionario(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, '/utility/getDatiContrattoQuestionario', param);
  }

  public saveQuestionarioPostVendita(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, '/utility/saveQuestionarioPostVendita', param);
  }
}
