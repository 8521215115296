<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center background-white">
  <mat-card class="mb-15px">
    <div class="sweet-alert" *ngIf="detailsData.status == 0 || detailsData.status == 2">
      <div class="sa-icon sa-success animate" style="display: block;"><span
        class="sa-line sa-tip animateSuccessTip"></span> <span class="sa-line sa-long animateSuccessLong"></span>
        <div class="sa-placeholder"></div>
        <div class="sa-fix"></div>
      </div>
      <div class="message-container text-align-center">
        <h2 class="verifica-message mt-15px">{{detailsData.message}}</h2>
      </div>
    </div>
    <div class="sweet-alert" *ngIf="detailsData.status == 1">
      <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
						<span class="sa-x-mark animateXMark"> <span
              class="sa-line sa-left"></span> <span class="sa-line sa-right"></span>
						</span>
      </div>
      <div class="message-error-container text-align-center">
        <h2 class="verifica-message mt-15px">Errore inaspettato</h2>
        <h2 class="verifica-message mt-15px">{{detailsData.message}}</h2>
      </div>
    </div>
  </mat-card>
</div>
