import {Component, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'borsaviaggi-cancellazione-gratuita',
  templateUrl: './cancellazione-gratuita.component.html',
  styleUrls: ['./cancellazione-gratuita.component.scss']
})
export class CancellazioneGratuitaComponent implements OnInit {

  constructor(private titleService: Title,
              private metaTagService: Meta) {
  }

  private pupolateSeoTag() {
    let title = "Borsaviaggi.it - Cancellazione Gratuita";
    this.titleService.setTitle(title);
    this.metaTagService.updateTag(
      {name: 'title', content: title},
    );
    this.metaTagService.updateTag(
      {property: 'og:title', content: title},
    );
    this.metaTagService.updateTag(
      {name: 'title', content: title},
    );
  }

  ngOnInit() {
    this.pupolateSeoTag();
  }
}
