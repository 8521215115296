import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {VerificaTransazioneService} from "../../services/gate/verifica-transazione.service";

@Component({
  selector: 'borsaviaggi-verifica-transazione',
  templateUrl: './verifica-transazione.component.html',
  styleUrls: ['./verifica-transazione.component.scss']
})
export class VerificaTransazioneComponent implements OnInit {
  public dataVerifica;
  public dataPartenza;
  public idPartenza;
  public statusVerifica;

  constructor(private route: ActivatedRoute,
              private verificaSrv: VerificaTransazioneService) {
    this.dataVerifica = this.route.snapshot.data.dataResolve.body.data;
    this.statusVerifica = this.route.snapshot.data.dataResolve.body.status;
    if (this.dataVerifica.esito.idTipologiaTransazione == 6) {
      this.verificaSrv.getPreventivo(3270).then(
        (result) => {
          this.idPartenza = result.body.data.idPartenza;
          this.getPartenzaData(result.body.data.idPartenza);
        }
      );
    }
  }

  getPartenzaData(paramData) {
    this.verificaSrv.getPartenza({'idPartenza': paramData}).then(
      (result) => {
        this.dataPartenza = result.body.data.dettaglioPartenza;
      }
    );
  }

  ngOnInit() {
  }

  public getRegaloValue() {
    let acquisto = this.dataVerifica.cartaRegalo.valoreCartaRegaloAcquisto;
    let regalo = this.dataVerifica.cartaRegalo.valoreCartaRegaloRegalo;
    let acquistoExtra = this.dataVerifica.cartaRegalo.valoreCartaRegaloExtraValoreAcquisto;
    let regaloExtra = this.dataVerifica.cartaRegalo.valoreCartaRegaloExtraValoreRegalo;

    return acquisto || regalo || acquistoExtra || regaloExtra || " ";
  }

  public getIdValue() {
    let idUno = this.dataVerifica.cartaRegalo.idContrattoCartaRegaloAcquisto;
    let idDue = this.dataVerifica.cartaRegalo.idContrattoCartaRegaloExtraValoreAcquisto;
    return idUno || idDue || " ";
  }
}
