import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable({
  providedIn: 'root'
})
export class CartaRegaloService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getGiftCard(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/banner/getCarteRegalo");
  }

  public getGiftCardDetails(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/banner/getDettaglioCartaRegalo", param);
  }

  public getCheckOut(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/banner/checkoutGiftCard", param);
  }
}
