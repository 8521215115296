<div class="background">
  <div class="container border-r-2px padding-20px mt-20px mb-20px text-align-justify background-white overflow-hidden">
    <div class="mb-15px text-align-right">
      <a class="bckground-orange d-flex align-items-center go-back"
         [routerLink]="['/'+ routeParams[0].path+'/' + routeParams[1].path+ '/'+ routeParams[2].path]">
        <mat-icon>navigate_before</mat-icon>
        Torna Indietro
      </a>
    </div>

    <div class="mobile-background-container display-mobile"
         [ngStyle]="{'backgroundImage': 'url(' + titleImageUrl + ')'}">
      <div class="mobile-title-container">
        <h1 class="text-align-center mobile-title">{{title | titlecase}}</h1>
        <h1 [hidden]="routerUrls[0].path != 'destinazioni-top'" class="text-align-center mobile-title">
          {{titleDestinazioniTop | titlecase}} </h1>
      </div>
      <div class="mobile-background-text">
        <h3 class="text-align-left d-sm-none mb-1rem mobile-testo"
            [hidden]="routerUrls[0].path == 'destinazioni-top'">{{titleDescription}}</h3>
        <h3 [hidden]="routerUrls[0].path != 'destinazioni-top'" class="d-sm-none mb-1rem mobile-top-destination"
            [innerHTML]="routerData.descrizioneDestinazione"></h3>
      </div>
    </div>

    <div class="row mb-15px selection-box display-not-mobile">
      <div class="col-3 text-center macro-image">
        <img class="macro-header-img" [alt]="routerData.nomeDestinazione" [src]="titleImageUrl">
      </div>
      <div class="col-9 top-container row">
        <h1 class="col-12 text-align-left" [hidden]="routerUrls[0].path == 'destinazioni-top'">{{title | titlecase}}
          - {{routerParams.nomeDestinazione | titlecase}}</h1>
        <h1 class="col-12 text-align-left" [hidden]="routerUrls[0].path != 'destinazioni-top'">
          {{titleDestinazioniTop | titlecase}} - {{routerData.nomeDestinazione | titlecase}}</h1>
        <h3 class="col-12 text-align-left">{{titleDescription}}</h3>
        <h3 class="col-12 text-align-left" [innerHTML]="routerData.descrizioneDestinazione"></h3>
      </div>
    </div>

    <div class="g-card">
      <div class="separator"></div>
      <div class="info-type-bar">
        <mat-label>Le nostre offerte per:</mat-label>
        <mat-label
          class="location-text">{{routerData.nomeDestinazione ? routerData.nomeDestinazione : routerParams.nomeDestinazione}} </mat-label>
      </div>

      <borsaviaggi-search-vacanze class="col-12"
                                  *ngIf="routerUrls[0].path != 'destinazioni-top' && routerParams.idSelezione"
                                  [ricercaItalia]="ricercaItalia"
                                  [filterDestinations]="filterDestinations"
                                  [idSelezione]="routerParams.idSelezione"></borsaviaggi-search-vacanze>
     <!-- &lt;!&ndash; location filter desktop&ndash;&gt;
      <div class="location-filter mt-1rem text-align-left flex-wrap">
        <mat-label>&nbsp; Filtra per:</mat-label>
        <a class="link" *ngFor="let location of filterDestinations"
           [routerLink]="makeFilterUrlDestinations(location)">{{location.nomeDestinazione}}</a>
      </div>-->

      <!-- date and transport filter -->
      <div class="mt-1-5-rem" *ngIf="objDataSource && objDataSource.length > 0">
        <div class="mt-15px mb-15px" *ngIf="routerUrls[0].path == 'destinazioni-top'">
          <borsaviaggi-search-vacanze [destinazioniTop]="true"
                                      [ricercaItalia]="ricercaItalia"
                                      [filterDestinations]="searchEngineDestinations"
                                      [idDestinazioneTop]="routerParams.idSelezione"></borsaviaggi-search-vacanze>
        </div>


      </div>

      <borsaviaggi-loader class="display-block mt-20px" [(show)]="loadingValues" typeMode="piker"
                          diameter="30"></borsaviaggi-loader>
      <mat-accordion [hidden]="filteredValues" class="offers-container" [multi]="true">
        <mat-expansion-panel class="xps-panel mat-elevation-z8" *ngFor="let data of objDataSource; first as isFirst"
                             [expanded]="isMobileDevice? isFirst: true">
          <mat-expansion-panel-header class="expansion-header" [collapsedHeight]="''" [expandedHeight]="''">
            <mat-panel-title class="fs-1_2rem text-align-left">
              {{data.infoOfferta.titolo}}
            </mat-panel-title>
            <div class="codice-offerta">
              <mat-panel-description>
                Codice G:{{data.infoOfferta.idOfferta}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <div class="row" *ngIf="data.images.length">
            <div class="col-sm carousel">
              <mat-carousel
                timings="250ms ease-in"
                interval="5000"
                color="accent"
                proportion="80"
                orientation="ltr"
                [autoplay]="false"
                [loop]="true"
                [hideArrows]="false"
                [hideIndicators]="false"
                [useKeyboard]="true"
                [useMouseWheel]="false"
              >
                <mat-carousel-slide
                  #matCarouselSlide
                  *ngFor="let img of data.images; let i = index"
                  [image]="img"
                  overlayColor="#00000040"
                  [hideOverlay]="true"
                ></mat-carousel-slide>
              </mat-carousel>
            </div>

            <div class="col-sm-9">
              <table mat-table [dataSource]="data.tabellaPartenze">
                <ng-container matColumnDef="partenzaDa">
                  <th mat-header-cell *matHeaderCellDef> Partenza Da</th>
                  <td mat-cell *matCellDef="let element"
                  > {{element.luogoPartenza | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="dataPartenza">
                  <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Data</th>
                  <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                    dal {{element.dal | date :'dd/MM/yyyy'}}
                    al {{element.al | date :'dd/MM/yyyy'}} </td>
                </ng-container>
                <ng-container matColumnDef="giorniNotti">
                  <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Giorni/Notti</th>
                  <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell"> {{element.giorniNotti}} </td>
                </ng-container>
                <ng-container matColumnDef="trattamento">
                  <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Trattamento</th>
                  <td mat-cell *matCellDef="let element"
                      class="d-none d-md-table-cell"> {{element.trattamento | titlecase}}</td>
                </ng-container>
                <ng-container matColumnDef="prezzo">
                  <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                  <td mat-cell *matCellDef="let element" class="bold ws-nowrap"> {{element.costoTotaleMinimo}}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <a [routerLink]="element.url">
                      <button class="btn-prenota" mat-raised-button>VAI</button>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>


      <!-- Tabella Destinazioni -->
      <mat-accordion class="offers-container" [hidden]="!filteredValues" [multi]="true">
        <mat-expansion-panel *ngFor="let item of dataTable; first as isFirst" [expanded]="true">
          <mat-expansion-panel-header class="expansion-header filtered-expansion">
            <mat-panel-title class="fs-1_2rem text-align-left">
              {{item.titolo}}
            </mat-panel-title>
            <div class="codice-offerta">
              <mat-panel-description>
                Codice G: {{item.idOfferta}}
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <div class="bv-table overflow-auto">
            <table mat-table [dataSource]="item.listaPartenze">
              <ng-container matColumnDef="partenzaDa">
                <th mat-header-cell class="d-none d-sm-table-cell" *matHeaderCellDef>Partenza Da</th>
                <td mat-cell class="d-none d-sm-table-cell" *matCellDef="let element"> {{element.luogoPartenza}} </td>
              </ng-container>
              <ng-container matColumnDef="dataPartenza">
                <th mat-header-cell *matHeaderCellDef> Data Partenza</th>
                <td mat-cell *matCellDef="let element"
                > {{element.dataPartenza | date :'dd/MM/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="giorniNotti">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Giorni/Notti</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> {{element.giorniNotti}} </td>
              </ng-container>
              <ng-container matColumnDef="trattamento">
                <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Trattamento</th>
                <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell"> {{element.trattamento}}</td>
              </ng-container>
              <ng-container matColumnDef="icons">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"></th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                  <borsaviaggi-partenze-icons [partenza]="element"></borsaviaggi-partenze-icons>
                </td>
              </ng-container>
              <ng-container matColumnDef="prezzo">
                <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                <td mat-cell *matCellDef="let element"
                    class="bold ws-nowrap"> {{element.prezzo && element.prezzo != '0' ? element.prezzo + '€' : ''}} </td>
              </ng-container>
              <ng-container matColumnDef="prime">
                <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Offerta</th>
                <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                  <img *ngIf="element.isPartenzaPrime"
                       src="../../../assets/img/borsaviaggi_prime.png"
                       height="25"
                       width="100"/></td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <a [routerLink]="element.url">
                    <button class="btn-prenota"
                            mat-raised-button>{{element.prezzo && element.prezzo != '0' ? 'VAI' : 'Calcola preventivo'}}
                    </button>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="filteredColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: filteredColumns;"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>


      <div *ngIf="objDataSource.length == 0">
        Nessuna selezione disponibile
      </div>
    </div>
  </div>
</div>
