import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {UtilityService} from "../utility.service";

@Injectable({
  providedIn: 'root'
})
export class LandingResolveService {
  constructor(private utilitySrv: UtilityService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let param = {
      "testoUrl": routeSnap.params.titolo
    };

    return this.utilitySrv.getLandingByUrl(param)
      .catch(err => {
        return err;
      });
  }
}
