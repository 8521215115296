<div class="container border-r-2px mt-20px pt-10px background-white mb-20px pb-20px">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi mt-5px mb-5px text-align-center">Fuori Tutto!</h1>
      <p class="subtitle">Nelle ore del "Fuori Tutto!" tutte le partenze migliori tour operatore pubblicate su
        borsaviaggi.it subiranno un forte ribasso con prezzi introvabili sulla rete e la garanzia del servizio di
        borsaviaggi</p>
    </div>
  </div>
  <div class="row">
    <div class="col sezione">
      <div class="mb-10px">
        <img class="immagine-fuori-tutto" src="assets/img/fuori-tutto.png" alt="immagine fuori tutto">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-20px">
      <h2 class="color-text-blue-borsaviaggi">I "FUORI TUTTO" sono eventi promozionali spot improvvisi,
        registrati e ricevi un avviso per il prossimo:</h2>
    </div>
  </div>
  <div>
    <borsaviaggi-registrazione-form [fuoriTuttoPage]="true"
                                    [hidden]="alreadyNewsletter"
                                    class="registration-form"></borsaviaggi-registrazione-form>
    <div [hidden]="!alreadyNewsletter">Risulti essere già registrato alla newsletter "Fuori Tutto". Riceverai
      aggiornamenti non
      appena la campagnia sarà attiva.
    </div>
  </div>
  <div class="crea-avviso full-width" [hidden]="alreadyNewsletter">
    <borsaviaggi-promise-btn
      type="submit"
      (buttonClick)="signUp()"
      [disabled]="!creaAvvisoButton"
      buttonText="Crea Avviso"
      buttonClass=" no-gutters padding-0 background-blue-borsaviaggi color-white">
    </borsaviaggi-promise-btn>
  </div>
</div>
