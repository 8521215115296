export class ResponseCheckerService {

  private static SUCCESS_RESPONSE = 0;
  private static OK_ESITO = "OK";

  public static isSuccessResponse(dataResponse): boolean {
    return dataResponse && dataResponse.data && dataResponse.status === ResponseCheckerService.SUCCESS_RESPONSE;
  }

  public static getErrorMessage(dataResponse): string {
    return dataResponse && dataResponse.message ? dataResponse.message : 'Internal Server Error';
  }

}
