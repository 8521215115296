import {MomentDateAdapter} from "@angular/material-moment-adapter";
import * as _moment from 'moment';
import {Moment} from 'moment';
import {Inject, Optional} from "@angular/core";
import {MAT_DATE_LOCALE} from "@angular/material/core";

const moment = _moment;

export class BorsaviaggiDateAdapter extends MomentDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    moment.locale('it');
    super(dateLocale);
  }

  parse(value: any, parseFormat: string | string[]): Moment | null {
    if (value && typeof value === 'string') {
      return moment(value, parseFormat);
    }
    return value ? moment.utc(value).locale(this.locale) : null;
  }

  format(date: Moment, displayFormat: Object): string {
    if (date) {
      let day = date.date();
      let month = date.get('month') + 1;
      let year = date.get('year');
      if (displayFormat === "l") {
        return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
      } else {
        let hours = date.get('hour');
        let minutes = date.get('minute');
        return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year + " " + this._to2digit(hours) + ":" + this._to2digit(minutes);
      }
    }
  }


  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}
