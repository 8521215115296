import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SearchEngineValidator} from '../../../services/validator/search-engine.validator';
import {ModalService} from '../../../services/common/modal.service';
import {DestinazioneService} from '../../../services/gate/destinazione.service';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {PopoverTrigger} from '../../popover-box/popover-trigger';
import {CacheService} from '../../../services/common/cache.service';
import {CACHE_KEY, RESPONSIVE_VALUE} from '../../../constants/const.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SEO_OFFERTE} from '../../../constants/seo.const';
import {ResponseCheckerService} from '../../../services/common/response.checker.service';
import {SearchEngineService} from '../search-engine.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {CalendarHeader} from "../../calendar-header/calendarHeader.component";

declare global {
  interface JQuery {
    LoadingOverlay(arg: any): any;
  }
}

const DEFAULT_MAX_ADULTI = 5;
const DEFAULT_MAX_BAMBINI = 4;
const DEFAULT_MAX_ETA_BAMBINI = 17;
const DATE_FORMATS = {
  parse: {dateInput: 'DD/MM/YYYY'},
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
};

@Component({
  selector: 'borsaviaggi-search-italia',
  templateUrl: './search-italia.component.html',
  styleUrls: ['./search-italia.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
  ]
})

export class SearchItaliaComponent implements OnInit, OnChanges, OnDestroy {
  public formGroup: FormGroup;
  public dateStart: any;
  public formGroupCamere: FormGroup;
  public formGroupAltro: FormGroup;
  public dataPartenzaLoading: boolean;
  public luogoPartenzaLoading: boolean;
  public formDisabled: boolean;
  public fromMacro: boolean;
  public fromRisultati: boolean;
  public modelSearch: {
    destinazione: { chipListDestinazioni: Array<any> },
    date: { disponibili: Array<any> },
    partenzaDa: { list: Array<any>, chipList: Array<any> },
    partner: { list: Array<any> },
    camere: {
      numeroAdultiMax: number,
      numeroBambiniMax: number,
      etaBambiniMax: number
    }
  };
  private promiseDestinazioni: Promise<any>;
  private onLineOffLineSubscription: Subscription[] = new Array<Subscription>();
  private costoArray;
  public destinationsObject;
  public minimumDatesArray = [];
  public disabledConfirmPartenzaDa: boolean;
  @Input() private dataSearch;
  @Input() public isVetrinaPrime;
  @Input() public destinazioneVprime;
  @Input() public idTourOperator;
  @Input() public destinazioniTop;
  @Input() public idSelezione;
  @Input() public idDestinazioneTop;
  @Input() public cssSearchBtn;
  @Input() public filterDestinations;
  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('pickerDataPartenze') pickerDataPartenze: MatDatepicker<any>;
  @ViewChild('triggerPopoverPartenzaDa') triggerPopoverPartenzaDa: PopoverTrigger;
  @ViewChild(CalendarHeader) calendarHeader: CalendarHeader<any>;
  public isMobileDevice: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  public maxDate: any;
  public minimumMonthArray = [];
  public customCalendarHeader = CalendarHeader;

  constructor(private modalSrv: ModalService,
              private destinazioniSrv: DestinazioneService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              private searchEngineSrv: SearchEngineService,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private cacheSrv: CacheService) {
    this.costoArray = [];
    this.formGroup = SearchEngineValidator.formVacanze();
    this.formGroupCamere = SearchEngineValidator.formCamere();
    this.formGroupAltro = SearchEngineValidator.formAltro();
    this.destinationsObject = {};
    this.modelSearch = {
      destinazione: {
        chipListDestinazioni: []
      },
      date: {
        disponibili: []
      },
      partner: {list: this.cacheSrv.getCacheData(CACHE_KEY.PARTNER_LIST)},
      partenzaDa: {
        list: [],
        chipList: []
      },
      camere: {
        numeroAdultiMax: DEFAULT_MAX_ADULTI,
        numeroBambiniMax: DEFAULT_MAX_BAMBINI,
        etaBambiniMax: DEFAULT_MAX_ETA_BAMBINI
      }
    };
    this.dateStart = new Date();
    this.isMobileDevice = this.deviceService.isMobile();
    this.maxDate = moment().add(1, 'years');
  }

  ngOnInit() {
    const dataParam = this.activeRoute.snapshot.queryParams;
    if (dataParam.from === 'italia') {
      this.formDisabled = true;
      this.fromRisultati = true;
      const arrDestinazioni = dataParam.destinazioneLabel.split(',');
      const arrIdDestinazioni = dataParam.destinazione.split(',');
      const arrNazioneDestinazioni = dataParam.destinazioneNazione.split(',');
      const arrPartenzaLbl = dataParam.partenzaDaLabel.split(',');
      const arrPartenzaId = dataParam.partenzaDa.split(',');
      const arrDestinazioniResult = [];
      arrDestinazioni.map(
        (currDest, index) => {
          const objDest = {
            idDestinazione: arrIdDestinazioni[index],
            nome: currDest,
            isNazione: arrNazioneDestinazioni[index]
          };
          arrDestinazioniResult.push(objDest);
        }
      );
      this.addChip('destinazione', arrDestinazioniResult);

      arrPartenzaLbl.map(
        (currDest, index) => {
          const objPartenzaDa = {
            idLuogoPartenza: arrPartenzaId[index],
            nome: currDest
          };
          this.modelSearch.partenzaDa.chipList.push(objPartenzaDa);
        }
      );

      this.formGroup.get('destinazione').setValue(this.modelSearch.destinazione.chipListDestinazioni);
      this.formGroup.get('partenzaDa').setValue(this.modelSearch.partenzaDa.chipList);

      if (dataParam.idTourOperator) {
        this.idTourOperator = dataParam.idTourOperator;
        this.fromMacro = true;
      }
      if (dataParam.idSelezione) {
        this.idSelezione = dataParam.idSelezione;
        this.fromMacro = true;
      }
      if (dataParam.idDestinazioneTop) {
        this.idDestinazioneTop = dataParam.idDestinazioneTop;
        this.fromMacro = true;
      }

      this.getDateDisponibile({
        listaDestinazioni: SearchEngineValidator.clearDestinazioniParam(this.modelSearch.destinazione.chipListDestinazioni),
        listaLuoghiPartenza: this.modelSearch.partenzaDa.chipList.map(currDest => parseInt(currDest.idLuogoPartenza))
      }, () => {
        this.formGroup.get('dataPartenza').setValue(dataParam.dataPartenza);
      });
      this.getLuoghiPartenza();
    }
    else {
      if (this.idTourOperator) {
        this.fromMacro = true;
      }
      if (this.idSelezione) {
        this.fromMacro = true;
      }
      if (this.idDestinazioneTop) {
        this.fromMacro = true;
      }
    }

    if (this.isVetrinaPrime && this.destinazioneVprime) {
      this.formGroup.get('destinazione').setValue(this.destinazioneVprime);
      this.modelSearch.destinazione.chipListDestinazioni = this.destinazioneVprime;
      this.getLuoghiPartenzaVPrime();
    } else {
      this.promiseDestinazioni = this.destinazioniSrv.getHomeDestinazioni();
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterDestinations && this.filterDestinations) {
      this.getTourData();
    }
  }

  private hideShowChatElement(hide) {
    const chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = hide ? 'none' : 'block';
    }
  }

  public togglePopoverPartenzaDa(popoverCTRL: PopoverTrigger) {
    if (this.formGroup.get('destinazione').value) {

      if (this.formGroup.get('partenzaDa').value) {
        this.disabledConfirmPartenzaDa = false;
        this.modelSearch.partenzaDa.list.map(
          (currPartenzaDa) => {
            if (this.formGroup.get('partenzaDa').value.find(currSelectDest =>
              parseInt(currSelectDest.idLuogoPartenza) === currPartenzaDa.idLuogoPartenza)) {
              currPartenzaDa.checked = true;
            }
          }
        );
      } else {
        this.disabledConfirmPartenzaDa = true;
      }
      if (popoverCTRL._popoverOpen) {
        this.hideShowChatElement(false);
      } else {
        this.hideShowChatElement(true);
      }
      popoverCTRL.togglePopover();
    }
  }

  private getDateDisponibile(dataParam, callback?) {
    this.dataPartenzaLoading = true;
    if (this.idTourOperator) {
      dataParam['idTo'] = this.idTourOperator;
    } else if (this.idSelezione) {
      dataParam['idSelezione'] = parseInt(this.idSelezione);
    } else if (this.idDestinazioneTop) {
      dataParam['idDestinazioneTop'] = parseInt(this.idDestinazioneTop);
    }

    this.destinazioniSrv.getHomeDateDestinazioni(dataParam).then(
      (data) => {
        if (ResponseCheckerService.isSuccessResponse(data.body)) {
          this.modelSearch.date.disponibili = data.body.data.map((currDate) => {
              return {data: moment(currDate.dataPartenza, ['DD/MM/YYYY mm:ss:mm']), costo: currDate.costoTotale};
            }
          );
          if (this.modelSearch.date.disponibili.length > 0) {
            this.dateStart = moment.min(this.modelSearch.date.disponibili.map(currDate => currDate.data)).format();
          }
          this.getMinimumDates();
          this.addPriceClassStyle(this.modelSearch.date.disponibili);
          if (callback) {
            callback();
          }
        } else {
          // TODO gestire errore
        }
      }
    ).finally(
      () => {
        this.dataPartenzaLoading = false;
      }
    );
  }

  private getLuoghiPartenza(callback?) {
    const dataParam = {listaDestinazioni: this.modelSearch.destinazione.chipListDestinazioni,
      idDestinazioneTop: undefined,
      idTo: undefined,
      idSelezione: undefined
    };
    if (this.idTourOperator) {
      dataParam.idTo = this.idTourOperator;
    } else if (this.idSelezione) {
      dataParam.idSelezione = parseInt(this.idSelezione);
    } else if (this.idDestinazioneTop) {
      dataParam.idDestinazioneTop = parseInt(this.idDestinazioneTop);
    }

    this.luogoPartenzaLoading = true;
    this.destinazioniSrv.getLuoghiPartenza(dataParam).then(
      (resp) => {
        if (resp.body) {
          this.modelSearch.partenzaDa.list = resp.body.data.sort(currEle => currEle.isSoloSoggiorno ? -1 : 0);
        }
      },
      (error) => {
        this.modelSearch.partenzaDa.list = [];
        console.log('Errore caricamento aeroporto di partenza', error);
      }
    ).finally(
      () => {
        this.luogoPartenzaLoading = false;
        if (callback) {
          callback();
        }
      }
    );
  }

  public actionFocusDestinazione() {
    if (this.fromRisultati || (!this.fromRisultati && !this.fromMacro) || (this.fromMacro && this.fromRisultati)) {
      const paramData = {
        promiseDest: this.promiseDestinazioni,
        destSelected: this.formGroup.get('destinazione').value,
        tabItalia: true
      };
      this.modalSrv.openSelezionaDestinazione(paramData,
        (data) => {
          this.addChip(data.list, data.dataChip);
          this.getLuoghiPartenza();
          if (this.formGroup.get('partenzaDa').value) {
            this.formGroup.get('partenzaDa').setValue('');
            this.formGroup.get('dataPartenza').setValue('');
            this.modelSearch.partenzaDa.chipList.length = 0;
            this.getDateDisponibile({
              listaDestinazioni: SearchEngineValidator.clearDestinazioniParam(this.modelSearch.destinazione.chipListDestinazioni),
              listaLuoghiPartenza: this.modelSearch.partenzaDa.chipList.map(currDest => parseInt(currDest.idLuogoPartenza))
            });
          }

          this.triggerPopoverPartenzaDa.openPopover();
        });
      // Verifico se ho i partner in cache.
      if (!this.modelSearch.partner.list) {
        this.destinazioniSrv.getPartner().then(
          (response) => {
            this.modelSearch.partner.list = response.body;
          }
        );
      }
    }
  }


  private getObjPersone() {
    const numeroAdulti = this.formGroupCamere.get('numeroAdulti').value;
    const numeroBambini = parseInt(this.formGroupCamere.get('numeroBambini').value);
    const bambini = [];
    if (numeroBambini > 0) {
      for (let i = 0; i < numeroBambini; i++) {
        bambini.push({
          eta: this.formGroupCamere.get('etaBambini-' + (i + 1)).value
        });
      }
    }
    return {
      adulti: numeroAdulti,
      bambini: bambini,
      isPrime: this.isVetrinaPrime || (this.formGroup.get('isPrime') && this.formGroup.get('isPrime').value),
    };
  }

  private getObjAltro() {
    const ret = {};
    for (const keyControl in this.formGroupAltro.controls) {
      if (keyControl !== 'hideRequired') {
        ret[keyControl] = this.formGroupAltro.controls[keyControl].value;
      }
    }

    return ret;
  }

  public actionSubmitRicerca() {
    if (this.formGroup.get('dataPartenza').value && this.formGroup.get('partenzaDa').value && this.formGroup.get('destinazione').value) {
      const objParam = {
        listDestinazioni: this.formGroup.get('destinazione').value,
        dataPartenza: this.formGroup.get('dataPartenza').value,
        partenzaDa: this.formGroup.get('partenzaDa').value,
        persone: this.getObjPersone(),
        tipoRicerca: 'italia',
        //  altro: this.getObjAltro()
        idTo: undefined
      };
      if (this.idTourOperator) {
        objParam.idTo = this.idTourOperator;
      }
      if (this.isVetrinaPrime) {
        this.buttonClick.emit();
      }
      const queryParam = this.destinazioniSrv.generateUrlRisultati(objParam);
      this.searchEngineSrv.start();
      this.formDisabled = true;
      this.router.navigate([SEO_OFFERTE.RISULTATI_VACANZA_KEYWORD], {queryParams: queryParam});
    } else {
      this.formDisabled = false;
    }
  }

  public actionFocusDataPartenza() {
    if (!this.dataPartenzaLoading && this.modelSearch.date.disponibili.length > 0 &&
      this.formGroup.get('destinazione').value && this.formGroup.get('partenzaDa').value) {
      this.dateStart = moment.min(this.modelSearch.date.disponibili.map(currDate => currDate.data)).format();
      this.pickerDataPartenze.open();

    } else if (this.dataPartenzaLoading &&
      this.modelSearch.date.disponibili.length === 0) {
      // TODO APRIRE MODALE NESSUNA PARTENZA DISPOSNIBILE
    }

  }


  private addPriceClassStyle(dateDisponibili) {
    const style = document.createElement('style');
    style.setAttribute('id', 'pikerPriceStyle');
    dateDisponibili.map(
      (dataParam) => {
        style.innerHTML +=
          " .search-vacanza-piker .prezzo-cell-" + dataParam.costo + " .mat-calendar-body-cell-content::after " +
          "{" +
          "content: 'da " + dataParam.costo + "€ '" +
          "}"
      }
    );
    document.head.appendChild(style);
  }

  public pikerDataClose() {
    if (this.isMobileDevice) {
      this.actionSubmitRicerca();
    }
  }

  public partenzaFilter = (date) => {
    if (!this.idTourOperator) {
      return this.modelSearch.date.disponibili.some((currDate) => {
        return moment(moment(date)).isSame(currDate.data);
      });
    } else {
      return true;
    }

  }

  public partenzaDateCssCustom = (date, view) => {
    let costo = null;
    let bestMonth = null;

    if (this.destinationsObject[moment(date).year()] &&
      this.destinationsObject[moment(date).year()][moment(date).month() + 1]) {
      const dateTemp = this.destinationsObject[moment(date).year()][moment(date).month() + 1].
      find(currDate => currDate.data.format() === moment(date).format());
      if (dateTemp) {
        costo = dateTemp.costo;
      }

      if (view === 'year') {
        // check if date in destinationObject is in minimumMonthArray
        let x = this.destinationsObject[moment(date).year()][moment(date).month() + 1].filter(currDate => this.minimumMonthArray.includes(currDate));
        if (x.length > 0) {
          costo = x[0].costo;
        }

        let minValue = 0;
        this.minimumMonthArray.forEach((currMonth) => {
          // get best month (min price)
          if (minValue === 0 || currMonth.costo < minValue) {
            minValue = currMonth.costo;
            bestMonth = currMonth.data.month();
          }
        });
      }

      if (costo) {
        if ((this.minimumDatesArray.includes(moment(date).format()) && view !== 'year') ||
          (view === 'year' && bestMonth && moment(date).month() === bestMonth)) {
          return costo ? 'enabled-date-green prezzo-cell-' + costo : '';
        } else {
          return costo ? 'enabled-date prezzo-cell-' + costo : '';
        }
      }
    }
  }

  public getMinimumDates() {
    this.destinationsObject = {};
    this.minimumDatesArray = [];
    this.minimumMonthArray = [];
    this.modelSearch.date.disponibili.filter(currP => currP.costo > 0).map((currDate) => {
      this.destinationsObject[currDate.data.year()] = this.destinationsObject[currDate.data.year()] || {};
      this.destinationsObject[currDate.data.year()][currDate.data.month() + 1] =
        this.destinationsObject[currDate.data.year()][currDate.data.month() + 1] || [];
      if (this.destinationsObject[currDate.data.year()][currDate.data.month() + 1].every(
        dateDest => currDate.data.format() !== dateDest.data.format()
      )) {
        this.destinationsObject[currDate.data.year()][currDate.data.month() + 1].push(currDate);
      } else {
        const indexSameDate = this.destinationsObject[currDate.data.year()][currDate.data.month() + 1].
        findIndex(currDate2 => currDate2.data.format() === currDate.data.format());
        const objSameIndex = this.destinationsObject[currDate.data.year()][currDate.data.month() + 1][indexSameDate];
        if (currDate.costo > 0 && objSameIndex.costo > currDate.costo) {
          this.destinationsObject[currDate.data.year()][currDate.data.month() + 1].splice(indexSameDate, 1);
          this.destinationsObject[currDate.data.year()][currDate.data.month() + 1].push(currDate);
        }
      }
    });

    for (const anno in this.destinationsObject) {
      for (let mese = 1; mese <= 12; mese++) {
        let bestDateMeseCosto = null;
        if (this.destinationsObject[anno][mese]) {
          this.destinationsObject[anno][mese].map(
            (currPartenza, index) => {
              if ((bestDateMeseCosto && bestDateMeseCosto.costo && currPartenza.costo <= bestDateMeseCosto.costo) || !bestDateMeseCosto) {
                bestDateMeseCosto = currPartenza.costo > 0 ? currPartenza : bestDateMeseCosto;
              }
              if (this.destinationsObject[anno][mese].length - 1 === index) {
                this.destinationsObject[anno][mese].map(
                  (currDate) => {
                    if (currDate.costo === bestDateMeseCosto.costo) {
                      this.minimumDatesArray.push(currDate.data.format());
                      this.minimumMonthArray.push(bestDateMeseCosto);
                    }
                  });
                bestDateMeseCosto = null;
              }

            }
          );
        }

      }
    }
  }

  public toggleCheckBoxPartenzaDa(event, item) {
    const fullList = this.modelSearch.partenzaDa.list;
    fullList.map((val) => {
      if (val.idLuogoPartenza !== item.idLuogoPartenza) {
        val.checked = false;
      }
    });
    item.checked = event.checked;
    this.disabledConfirmPartenzaDa = this.modelSearch.partenzaDa.list.every(currPartenza => !currPartenza.checked);
  }

  public actionConfirmLuogoPartenza(popOverTriggerCtrl: PopoverTrigger) {
    if (!this.disabledConfirmPartenzaDa) {
      this.modelSearch.partenzaDa.chipList.length = 0;
      this.modelSearch.partenzaDa.list.map(
        (itemPartenza) => {
          if (itemPartenza.checked) {
            this.modelSearch.partenzaDa.chipList.push(itemPartenza);
          }
        }
      );
      this.formGroup.get('partenzaDa').setValue(this.modelSearch.partenzaDa.chipList);
      this.getDateDisponibile({
        listaDestinazioni: SearchEngineValidator.clearDestinazioniParam(this.modelSearch.destinazione.chipListDestinazioni),
        listaLuoghiPartenza: this.modelSearch.partenzaDa.chipList.map(currDest => parseInt(currDest.idLuogoPartenza))
      }, () => {
        this.pickerDataPartenze.open();
      });
      this.hideShowChatElement(false);
      popOverTriggerCtrl.closePopover();
    }
  }

  public removeChipDestinazione(item: any) {
    const index = this.modelSearch.destinazione.chipListDestinazioni.indexOf(item as never);
    this.modelSearch.destinazione.chipListDestinazioni.splice(index, 1);
    this.formGroup.get('destinazione').setValue(this.modelSearch.destinazione.chipListDestinazioni);
    this.getLuoghiPartenza();
    this.formGroup.get('dataPartenza').setValue(null);
    this.formGroup.get('partenzaDa').setValue(null);
    this.modelSearch.partenzaDa.list.length = 0;
    this.modelSearch.partenzaDa.chipList.length = 0;
    this.triggerPopoverPartenzaDa.closePopover();
    if (this.modelSearch.destinazione.chipListDestinazioni.length > 0) {
      this.triggerPopoverPartenzaDa.openPopover();
    }

  }

  public removeChipPartenzaDa(item: any) {
    const index = this.modelSearch.partenzaDa.chipList.indexOf(item as never);
    if (index >= 0) {
      const itemUnCheck = this.modelSearch.partenzaDa.chipList[index] as any;
      itemUnCheck.checked = false;
      this.modelSearch.partenzaDa.chipList.splice(index, 1);
      this.formGroup.get('partenzaDa').setValue(this.modelSearch.partenzaDa.chipList);
    }
    if (this.modelSearch.partenzaDa.chipList.length > 0) {
      this.getDateDisponibile({
        listaDestinazioni: SearchEngineValidator.clearDestinazioniParam(this.modelSearch.destinazione.chipListDestinazioni),
        listaLuoghiPartenza: this.modelSearch.partenzaDa.chipList.map(currDest => parseInt(currDest.idLuogoPartenza))
      }, () => {
        this.pickerDataPartenze.open();
      });
    }
    if (this.modelSearch.destinazione.chipListDestinazioni.length === 0) {
      this.formGroup.get('partenzaDa').setValue(null);
      this.triggerPopoverPartenzaDa.closePopover();
    }
    if (this.modelSearch.partenzaDa.chipList.length === 0) {
      this.formGroup.get('partenzaDa').setValue(null);
      this.formGroup.get('dataPartenza').setValue(null);
    }
  }

  private addChip(nameList, dataList) {
    this.modelSearch[nameList].chipListDestinazioni = dataList;
    this.formGroup.get(nameList).setValue(dataList);
  }

  private getLuoghiPartenzaVPrime() {
    const dataParam = {listaDestinazioni: this.destinazioneVprime};
    this.luogoPartenzaLoading = true;
    this.destinazioniSrv.getLuoghiPartenza(dataParam).then(
      (resp) => {
        if (resp.body) {
          this.modelSearch.partenzaDa.list = resp.body.data;
        }
      },
      (error) => {
        this.modelSearch.partenzaDa.list = [];
        console.log('Errore caricamento aeroporto di partenza', error);
      }
    ).finally(
      () => {
        this.luogoPartenzaLoading = false;
      }
    );
  }

  public getTourData() {
    const destinationDataFilter = this.filterDestinations.map(
      (val) => {
        return {
          checked: false,
          disabled: false,
          idDestinazione: val.idDestinazione,
          isDestinazione: true,
          isNazione: val.isNazione,
          link: null,
          nome: val.nomeDestinazione,
          urlFoto: null
        };
      }
    );
    this.addChip('destinazione', destinationDataFilter);
    this.getLuoghiPartenza();
  }

  ngOnDestroy() {
    const eleStyle = document.getElementById('pikerPriceStyle');
    if (eleStyle && eleStyle.remove) {
      eleStyle.remove();
    }
    this.onLineOffLineSubscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }


}
