import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DirettoreService} from "../../services/gate/direttore.service";
import {ModalService} from "../../services/common/modal.service";

@Component({
  selector: 'borsaviaggi-scrivi-direttore',
  templateUrl: './scrivi-direttore.component.html',
  styleUrls: ['./scrivi-direttore.component.scss']
})
export class ScriviDirettoreComponent implements OnInit {
  public formGroup: FormGroup;
  public loading;

  constructor(private direttoreService: DirettoreService,
              private modalSrv: ModalService) {
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      nome: new FormControl('', Validators.required),
      cognome: new FormControl('', Validators.required),
      messaggio: new FormControl('', Validators.required),
      dataNascita: new FormControl('', Validators.required),
      informativaPrivacy: new FormControl(false, Validators.requiredTrue)
    })
  }

  send(formDataValid) {
    if (formDataValid) {
      this.loading = true;
      let obj = {
        "nome": this.formGroup.get("nome").value,
        "cognome": this.formGroup.get("cognome").value,
        "email": this.formGroup.get("email").value,
        "messaggio": this.formGroup.get("messaggio").value,
        "dataNascita": this.formGroup.get("dataNascita").value,
        "isInformativaPrivacy": this.formGroup.get("informativaPrivacy").value
      };
      this.direttoreService.sendMessage(obj).then(
        (response) => {
          let mess = response.body.status === 0 ? "Valutazioni inviate con successo" : response.body.message;
          this.modalSrv.openFeedbackModal({success: mess});
        },
        (error) => {
          this.modalSrv.openErrorDialog();
        }
      ).finally(
        () => {
          this.loading = false;
          this.formGroup.reset();
          this.formGroup.markAsPristine();
          this.formGroup.markAsUntouched();
        }
      );
    }

  }

}
