import {Injectable} from '@angular/core';
import {MapsAPILoader} from '@agm/core';

declare var google: any;

@Injectable()
export class GeocodeService {
  private geocoder: any;

  constructor(private mapLoader: MapsAPILoader) {

  }

  geocodeAddress(location: string): Promise<{ latitudine: any, longitudine: any }> {
    return new Promise((resolve, reject) => {
      this.mapLoader.load().then(
        (resp) => {
          this.geocoder = new google.maps.Geocoder();
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              resolve({
                latitudine: results[0].geometry.location.lat(),
                longitudine: results[0].geometry.location.lng()
              });
            } else {
              reject({latitudine: 0, longitudine: 0});
            }
          })
        }
      );
    });
  }

}
