import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CheckoutValidator} from '../../../services/validator/checkout.validator';
import {UtilityService} from '../../../services/gate/utility.service';
import {RicercaCrociereService} from '../../../services/gate/ricerca-crociere.service';
import {LoaderService} from '../../../components/loader/loader.service';
import {PrenotaCrocieraRequestObj} from './prenotaCrocieraRequesObj';
import * as moment from 'moment';
import {DestinazioneService} from '../../../services/gate/destinazione.service';
import {User} from "../../../model/user.dto";
import {AuthService} from "../../../services/common/auth.service";
import {ModalService} from "../../../services/common/modal.service";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {BannerManagerService} from "../../../services/common/banner-manager.service";
import {SEO_OFFERTE} from "../../../constants/seo.const";
import {UtilsStringService} from "../../../services/common/utils-string.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'borsaviaggi-checkout-page',
  templateUrl: './checkout-page-crociere.component.html',
  styleUrls: ['./checkout-page-crociere.component.scss']
})
export class CheckoutPageCrociereComponent implements OnInit {
  public formCheckoutCrociera: FormGroup = CheckoutValidator.formCheckoutCrociera;
  public formPrivacy: FormGroup = CheckoutValidator.formPrivacyValidator;
  public formNote: FormGroup = CheckoutValidator.formNoteValidator;
  public formBuonoSconto: FormGroup = CheckoutValidator.formCheckoutBuonoScontoCrociera;
  public listSesso: any;
  public checkoutStep: any;
  public dataResponse: any; // dati dalla getPreventivoCrociera
  public cabineDaPrenotareNum: any;
  public cabinePrenotabiliArr: any;
  public listaComuni: Array<any>;
  public listaRegioni: Array<any>;
  public cabineConPrezzoTotalePostModifica: Array<any>;
  public cabineSelezionateTopBox: any;
  public cabine: any;
  /** se true, da' la possibilità di cambiare anche cabina e numero passeggeri */
  public showPrezzoTotaleConAssicurazione: boolean;
  public sconto: any;
  public assicurazionePrime: boolean;
  public costoTotale: number;
  public isMobileDevice: boolean;
  public userData: User;
  public arrSubscriber: Array<Subscription>;
  public prezzoPrime = 19;
  public prezzoPolizza = 50;
  public desktopRiepilogo;
  public stopFixed;
  public disableNext;
  public showPrime: any;
  public urlPartenza: string;
  public cabineDaPrenotareForm: any;
  public erroreSconto: any;
  public isPrimeObbligatoriaSconto: boolean = false;
  public dataPromo: any;
  public idPromozione: any;
  public idBuono: any;
  public linkEstrattoPolizza: string;
  public linkIconaPartnerAssicurativo: string;
  public insurancePrice: number;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.checkoutStep != 'step3' && !this.isMobileDevice) {
      let eleToppone = this.elementRef.nativeElement.querySelector('.top-box');
      let eleContainer = this.elementRef.nativeElement.querySelector('.background');
      const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.desktopRiepilogo = scrollOffset >= 300 && scrollOffset <= (eleContainer.scrollHeight - eleToppone.offsetHeight);
      this.stopFixed = scrollOffset >= (eleContainer.scrollHeight - eleToppone.offsetHeight);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private utilitySrv: UtilityService,
    private ricercaCrociereSrv: RicercaCrociereService,
    private loaderSrv: LoaderService,
    private destSrv: DestinazioneService,
    private deviceService: DeviceDetectorService,
    private authSrv: AuthService,
    private elementRef: ElementRef,
    private modalSrv: ModalService,
    private bannerSrv: BannerManagerService) {
    this.bannerSrv.showHideBanner(false);
    this.disableNext = true;
    this.formPrivacy.get('privacy').valueChanges.subscribe((val) => {
      this.disableNext = !val;
      if (val) {
        this.formPrivacy.get('riceviNewsletter').setValue(true);
      }
    });
    moment.locale('it');
    if (this.route.snapshot.data && this.route.snapshot.data.listRisultati.body.data) {
      this.dataResponse = this.route.snapshot.data.listRisultati.body.data;
      this.getPrezzoTotalePostModifica(this.dataResponse.cabineSelezionate);
      this.cabineSelezionateTopBox = this.dataResponse.cabineSelezionate;
      this.linkEstrattoPolizza = this.dataResponse.linkEstrattoPolizza;
      this.linkIconaPartnerAssicurativo = this.dataResponse.linkIconaPartnerAssicurativo;

      let partenzaObj = {
        compagnia: this.dataResponse.nomeCompagnia,
        itinerario: this.dataResponse.titolo,
        porto: this.dataResponse.porto,
        dataPartenza: moment(this.dataResponse.dataPartenza, "DD MMM YYYY", "it-IT").format(),
        idPartenza: this.dataResponse.idCrociera,
      };

      this.urlPartenza = this.makePartenzaUrl(partenzaObj);
    }
    this.isMobileDevice = this.deviceService.isMobile();
    this.showPrezzoTotaleConAssicurazione = this.dataResponse.nomeCompagnia !== 'MSC Crociere';
    this.sconto = 0;
    this.idPromozione = 0;
    this.idBuono = 0;
    this.insurancePrice = this.dataResponse.nomeCompagnia === 'MSC Crociere' ? this.costoTotale * 0.06 : 50;
    //this.costoTotale = this.costoTotale + (this.showPrime ? this.prezzoPrime : 0) + this.prezzoPolizza;
  }

  ngOnInit() {
    this.userData = this.authSrv.getUser();
    /*if (this.authSrv.isUserLogedin()) {
      this.userData = this.authSrv.getUser();
    }*/
    this.checkoutStep = 'step1';
    this.listSesso = this.getListSesso();
    this.listaComuni = [];
    this.listaRegioni = [];
    this.cabine = this.dataResponse.cabine;
    this.cabineDaPrenotareNum = this.dataResponse.cabineSelezionate.length;
    if (this.dataResponse.voloSelezionato && this.dataResponse.voloSelezionato.idVolo) {
      this.formCheckoutCrociera.get('volo').setValue(this.dataResponse.voloSelezionato.idVolo);
    }
    this.cabineDaPrenotareForm = this.dataResponse ? this.makeCamereObj(this.dataResponse) : null;
    if (this.cabineDaPrenotareForm) {
      this.cabineDaPrenotareForm.forEach((camera) => {
        camera.forms = this.createDynamicOspitiForm(camera)
      })
    }

    if (this.userData) {
      this.populateFormByUtente(this.userData.userInfo);
    }

    this.arrSubscriber = [
      this.authSrv.userObservable().subscribe(
        (result) => {
          if (result) {
            this.userData = this.authSrv.getUser();
            this.populateFormByUtente(this.userData.userInfo);
          }
        }
      )
    ]
  }

  public makeCamereObj(dataCheckout) {
    let camere = [];
    let dataCamere = dataCheckout.cabineSelezionate;
    for (let camera of dataCamere) {
      let obj = {
        numCabina: null,
        ospiti: []
      };
      obj.numCabina = camera.idCabina;

      let numPersone = camera.numAdulti + camera.numBambini;
      for (let t = 1; t <= numPersone; t++) {
        obj.ospiti.push(t)
      }
      camere.push(obj);
    }
    return camere;
  }

  public createDynamicOspitiForm(camera) {
    let ospitiForm = [];
    camera.ospiti.forEach((ospite, i) => {
      let nome = 'nome' + '-' + camera.numCabina + '-' + ospite;
      let cognome = 'cognome' + '-' + camera.numCabina + '-' + ospite;
      let dataNascita = 'dataNascita' + '-' + camera.numCabina + '-' + ospite;
      let sesso = 'sesso' + '-' + camera.numCabina + '-' + ospite;
      let cellulare = 'cellulare' + '-' + camera.numCabina + '-' + ospite;
      let riceviNewsletter = 'riceviNewsletter' + '-' + camera.numCabina + '-' + ospite;
      let privacy = 'privacy' + '-' + camera.numCabina + '-' + ospite;
      let email = 'email' + '-' + camera.numCabina + '-' + ospite;
      if (camera.numCabina === 1 && ospite === 1) {
        let intestatarioForm: FormGroup =
          new FormBuilder().group({
            hideRequired: false,
            [nome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [cognome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [dataNascita]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [sesso]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [cellulare]: new FormControl('', Validators.compose([Validators.required])),
            [riceviNewsletter]: new FormControl('', Validators.compose([])),
            [privacy]: new FormControl('', Validators.compose([])),
            [email]: new FormControl('', Validators.compose([
              Validators.email,
              Validators.required
            ]))
          });
        ospitiForm.push(intestatarioForm);
      } else {
        let formOspite: FormGroup =
          new FormBuilder().group({
            hideRequired: false,
            [nome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [cognome]: new FormControl('', Validators.compose([
              Validators.required
            ])),
            [dataNascita]: new FormControl('', Validators.compose([
              Validators.required
            ]))
          });
        ospitiForm.push(formOspite);
      }
    });
    return ospitiForm;
  }

  public populateFormByUtente(user) {
    let formIntestatario = this.cabineDaPrenotareForm[0].forms[0];
    formIntestatario.get('nome-1-1').setValue(user.nome);
    formIntestatario.get('cognome-1-1').setValue(user.cognome);
    formIntestatario.get('dataNascita-1-1').setValue(moment(user.dataNascita).format('YYYY-MM-DD'));
    formIntestatario.get('sesso-1-1').setValue(user.sesso);
    formIntestatario.get('cellulare-1-1').setValue(user.cellulare);
    formIntestatario.get('email-1-1').setValue(user.email);
  }

  public onChangeCheckboxPrime(event) {
    this.showPrime = event.checked;
    if (event.checked) {
      this.getPrezzoTotalePostModifica(this.dataResponse.cabineSelezionate);
      //this.costoTotale = this.costoTotale + this.prezzoPrime + (this.showPrezzoTotaleConAssicurazione ? this.prezzoPolizza * this.cabineDaPrenotareNum : 0) - this.sconto;
    } else {
      this.getPrezzoTotalePostModifica(this.dataResponse.cabineSelezionate);
      //this.costoTotale = this.costoTotale + (this.showPrezzoTotaleConAssicurazione ? this.prezzoPolizza * this.cabineDaPrenotareNum : 0) - this.sconto;
    }
  }

  public getListSesso() {
    return [{
      value: "M",
      label: "Uomo"
    },
      {
        value: "F",
        label: "Donna"
      }
    ]
  }


  public getPrezzoBaseCabinaByIdTipoCabina(cabineOrig, idTipoCabina) {
    let prezzoBase;
    cabineOrig.forEach((c) => {
      if (idTipoCabina === c.idCategoria) {
        prezzoBase = c.prezzo
      }
    });
    return prezzoBase;
  }

  public getPrezzoBaseCabinaByCategoria(cabineOrig, cabinaObj) {
    let prezzoBase;
    cabineOrig.forEach((c) => {
      if (cabinaObj.idTipoCabina === c.idCategoria) {
        prezzoBase = c.prezzo
      }
    });
    return prezzoBase;
  }

  public getCategoriaCabina(obj) {
    return this.getCategoriaCabinaById(obj.idTipoCabina)
  }

  public getCategoriaCabinaById(idTipoCabina) {
    let res = '';
    switch (idTipoCabina) {
      case 1:
        res = 'Cabina interna';
        break;
      case 2:
        res = 'Cabina esterna';
        break;
      case 3:
        res = 'Cabina con balcone';
        break;
      case 4:
        res = 'Suite';
        break;
      default:
        res = '';
        break;
    }
    return res
  }

  public getCategoriaCabinaByIdTruncated(idTipoCabina) {
    let res = '';
    switch (idTipoCabina) {
      case 1:
        res = 'Interna';
        break;
      case 2:
        res = 'Esterna';
        break;
      case 3:
        res = 'Con balcone';
        break;
      case 4:
        res = 'Suite';
        break;
      default:
        res = '';
        break;
    }
    return res
  }

  public getPrezzoTotalePostModifica(cabine) {
    let cabineConPrezzoTotalePostModifica = [];
    let arrPrezzi = [];
    let prezzoTotale;

    if (!this.dataResponse.isCrocieraLive) {
      cabine.forEach((cabina) => {
        let cabinaConPrezzo = {
          idCabina: cabina.idCabina,
          prezzo: this.getPrezzoCabina(cabina)
        };
        cabineConPrezzoTotalePostModifica.push(cabinaConPrezzo);
        arrPrezzi.push(cabinaConPrezzo.prezzo);
      });
      prezzoTotale = arrPrezzi.reduce((a, b) => a + b, 0);
    }
    else {
      this.dataResponse.cabineSelezionate.forEach((cabina) => {
        let cabinaConPrezzo = {
          idCabina: cabina.idCabina,
          prezzo: cabina.costoCabina
        };
        cabineConPrezzoTotalePostModifica.push(cabinaConPrezzo);
        arrPrezzi.push(cabinaConPrezzo.prezzo);
      });
      prezzoTotale = arrPrezzi.reduce((a, b) => a + b, 0);
    }

    this.cabineConPrezzoTotalePostModifica = cabineConPrezzoTotalePostModifica;
    this.costoTotale = prezzoTotale;
  }

  public getPrezzoCabina(cabinaObj) {
    let prezzoCabina;
    let prezzoBase = this.getPrezzoBaseCabinaByCategoria(this.dataResponse.cabine, cabinaObj);
    let moltiplicatore = cabinaObj.numAdulti + cabinaObj.numBambini;
    prezzoCabina = prezzoBase * moltiplicatore;
    return prezzoCabina;
  }

  public nextStepCheckout(state) {
    switch (state) {
      case 'step1':
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.checkoutStep = state;
        break;
      case 'step2':
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.checkoutStep = state;
        break;
      case 'step3':
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.checkoutStep = state;
        break;
    }
  }

  public previousStepCheckout() {
    if (this.checkoutStep === 'step2') this.checkoutStep = 'step1';
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  public getCabineSelezionate() {
    let numCabine = this.cabineDaPrenotareNum;
    let cabineSelezionate = [];

    for (let i = 0; i < numCabine; i++) {
      let numAdulti = this.dataResponse.cabineSelezionate[i].numAdulti;
      let numBambini = this.dataResponse.cabineSelezionate[i].numBambini;
      let idTipoCabina = this.dataResponse.cabineSelezionate[i].idTipoCabina; //this.formCheckoutCrociera.controls['idTipoCabina' + '_' + (i + 1)].value;
      let tipoCabina = this.getCategoriaCabinaById(idTipoCabina);
      let costoBase = this.getPrezzoBaseCabinaByIdTipoCabina(this.dataResponse.cabine, idTipoCabina);
      let adultiArr = [];
      let bambiniArr = [];
      let cabinaSelezionata = {};
      let listaEtaAdulti = [];


      /*for (let j = 0; j < numAdulti; j++) {
        if (this.dataResponse.isVoloIncluso) {
          let dataNascita = this.formCheckoutCrociera.controls['dataNascitaAdulto' + (j + 1) + '_' + (i + 1)].value;
          let dataNascitaString = moment(dataNascita);
          let anno = moment(dataNascitaString).year();
          let mese = moment(dataNascitaString).month();
          let giorno = moment(dataNascitaString).day();
          let adulto = {
            nome: this.formCheckoutCrociera.controls['nomeAdulto' + (j + 1) + '_' + (i + 1)].value,
            cognome: this.formCheckoutCrociera.controls['cognomeAdulto' + (j + 1) + '_' + (i + 1)].value,
            anno: anno,
            mese: mese,
            giorno: giorno
          };
          adultiArr.push(adulto);
        }
      }

      for (let j = 0; j < numBambini; j++) {
        if (this.dataResponse.isVoloIncluso) {
          let dataNascita = this.formCheckoutCrociera.controls['dataNascitaBambino' + (j + 1) + '_' + (i + 1)].value;
          let dataNascitaString = moment(dataNascita);
          let anno = moment(dataNascitaString).year();
          let mese = moment(dataNascitaString).month();
          let giorno = moment(dataNascitaString).day();
          let bambino = {
            nome: this.formCheckoutCrociera.controls['nomeBambino' + (j + 1) + '_' + (i + 1)].value,
            cognome: this.formCheckoutCrociera.controls['cognomeBambino' + (j + 1) + '_' + (i + 1)].value,
            anno: anno,
            mese: mese,
            giorno: giorno
          };
          bambiniArr.push(bambino);
        }
      }*/

      cabinaSelezionata = {
        listaAdulti: adultiArr,
        listaBambini: bambiniArr,
        costoBase: costoBase,
        tipoCabina: tipoCabina,
        listaEtaAdulti: listaEtaAdulti,
        numAdulti: numAdulti,
        numBambini: numBambini,
        nomeCabina: this.dataResponse.isCrocieraLive ? this.dataResponse.cabineSelezionate[0].nomeCabina : '',
        codiceTariffaCabina: this.dataResponse.isCrocieraLive ? this.dataResponse.cabineSelezionate[0].codiceTariffaCabina : '',
        nomeTariffaCabina: this.dataResponse.isCrocieraLive ? this.dataResponse.cabineSelezionate[0].nomeTariffaCabina : '',
        costoCabina: this.dataResponse.isCrocieraLive ? this.dataResponse.cabineSelezionate[0].costoCabina : ''
      };

      cabineSelezionate.push(cabinaSelezionata);
    }

    return cabineSelezionate;
  }

  public makePrenotaCrocieraRequestObj() {
    let cabineSelezionate = this.getCabineSelezionate();
    let noteRichiesta = this.formNote.get('note').value;
    let formIntestatario = this.cabineDaPrenotareForm[0].forms[0];

    let reqObj = {
      /** Intestatario */
      nome: formIntestatario.get("nome-1-1").value,
      cognome: formIntestatario.get("cognome-1-1").value,
      annoNascita: moment(formIntestatario.get('dataNascita-1-1').value).format('YYYY'),
      meseNascita: moment(formIntestatario.get('dataNascita-1-1').value).format('MM'),
      giornoNascita: moment(formIntestatario.get('dataNascita-1-1').value).format('DD'),
      sesso: formIntestatario.get("sesso-1-1").value,
      idLuogoNascita: 0, //this.formCheckoutCrociera.controls['comuneDiNascita'].value,
      codFiscale: "", //this.formCheckoutCrociera.controls['codiceFiscale'].value,
      idComuneResidenza: 0, //this.formCheckoutCrociera.controls['comuneDiResidenza'].value,
      cellulare: formIntestatario.get("cellulare-1-1").value,
      email: formIntestatario.get("email-1-1").value,
      note: noteRichiesta, //this.formCheckoutCrociera.controls['note'].value,
      dataPartenza: this.parseDataPartenzaFromStringToTimestamp(this.dataResponse.dataPartenza),
      destinazione: this.dataResponse.destinazione,
      giorni: this.dataResponse.giorni,
      idCrociera: this.dataResponse.idCrociera,
      idPreventivoOnLine: this.route.snapshot.params.idPreventivoCrociera,
      isVoloIncluso: this.dataResponse.isVoloIncluso,
      nomeCompagnia: this.dataResponse.nomeCompagnia,
      nomeNave: this.dataResponse.nomeNave,
      notti: this.dataResponse.notti,
      porto: this.dataResponse.porto,
      voloRichiesto: this.getStringVoloRichiesto(this.dataResponse.volo),
      cabine: cabineSelezionate,
      isAssicurazione: this.showPrezzoTotaleConAssicurazione,
      costoTotale: this.costoTotale,
      isRiceveNewsletter: this.formPrivacy.get('riceviNewsletter').value,
      isCrocieraLive: this.dataResponse.isCrocieraLive,
      idVoloRichiesto: this.dataResponse.voloSelezionato && this.dataResponse.voloSelezionato.idVolo ? this.dataResponse.voloSelezionato.idVolo : -1,
      isUtentePrime: this.userData ? this.userData.userInfo.isPrime : false,
      isGetPrime: this.userData ? (this.userData.userInfo.isPrime ? false : this.showPrime) : this.showPrime,
      listaPasseggeriPerCamera: [],
      idBuono: this.idBuono,
      idPromozione: this.idPromozione
    };
    this.cabineDaPrenotareForm.map(
      (currCamera, indexCamera) => {
        indexCamera = indexCamera + 1;
        let objArrayCameraOspite = [];
        currCamera.forms.map(
          (currOspiteForm, indexOspite) => {
            indexOspite = indexOspite + 1;
            if ((indexCamera === 1 && indexOspite > 1) || indexCamera > 1) {
              objArrayCameraOspite.push(
                {
                  nome: currOspiteForm.get("nome-" + indexCamera + "-" + indexOspite).value,
                  cognome: currOspiteForm.get("cognome-" + indexCamera + "-" + indexOspite).value,
                  giornoNascita: moment(currOspiteForm.get("dataNascita-" + indexCamera + "-" + indexOspite).value).format('DD'),
                  meseNascita: moment(currOspiteForm.get("dataNascita-" + indexCamera + "-" + indexOspite).value).format('MM'),
                  annoNascita: moment(currOspiteForm.get("dataNascita-" + indexCamera + "-" + indexOspite).value).format('YYYY'),
                  idCamera: indexCamera
                }
              );
            }

          }
        );
        reqObj.listaPasseggeriPerCamera.push(objArrayCameraOspite);
      }
    );

    return reqObj;
  }

  public parseDataPartenzaFromStringToTimestamp(dataPartenza) {
    /** Esempio: converte '16 luglio 2002' in "2002-07-16T00:00:00+02:00 */
    return dataPartenza ? moment(dataPartenza, 'LL').format() : null;
  }

  public getStringVoloRichiesto(volo) {
    return volo ? volo.tipoVolo + ' ' + volo.nome + ' - ' + volo.costo + '€ per persona' + '|' + volo.idVolo : null;
  }

  public prenotaCrociera() {
    this.loaderSrv.show();
    let req = this.makePrenotaCrocieraRequestObj();
    //let prenotaCrocieraRequestObj = new PrenotaCrocieraRequestObj(req);
    this.ricercaCrociereSrv.prenotaCrociera(req).then(
      (response) => {
        this.loaderSrv.hide();
        if (response && response.body.status === 0) {
          this.nextStepCheckout('step3');
        } else {
          this.modalSrv.openErrorDialog(response.body.message);
        }
      }, (reject) => {
        this.modalSrv.openErrorDialog(reject);
      }
    )
  }

  public onClickPrenotaCrociera() {
    if (this.formPrivacy.get('privacy').value) {
      if (this.formPrivacy.valid) {
        this.prenotaCrociera();
        this.nextStepCheckout('step2');
      } else {
        this.formPrivacy.markAsDirty();
        this.formPrivacy.markAllAsTouched();
      }
    }
  }

  public onChangeCheckboxAssicurazione(event) {
    this.showPrezzoTotaleConAssicurazione = event.checked;
    if (event.checked) {
      this.getPrezzoTotalePostModifica(this.dataResponse.cabineSelezionate);
      //this.costoTotale = this.costoTotale + (this.showPrime ? this.prezzoPrime : 0) + (this.prezzoPolizza * this.cabineDaPrenotareNum) - this.sconto;
    } else {
      this.getPrezzoTotalePostModifica(this.dataResponse.cabineSelezionate);
      //this.costoTotale = this.costoTotale + (this.showPrime ? this.prezzoPrime : 0) - this.sconto;
    }

    if (!event.checked && this.dataResponse.nomeCompagnia !== 'MSC Crociere') {
      let paramData = {
        testoComunicazione: "Attenzione, eliminando la Cruise Service+ perderai il vantaggio dell’annullamento e riceverai un preventivo maggiorato, che includerà la polizza ufficiale della compagnia.",
        testoSaluti: ""
      };
      this.modalSrv.openComunicazioniModal(paramData);
    }
  }

  public toggleAccoridionRiepilogo(opend) {
    if (opend && this.isMobileDevice) {
      document.querySelector('body').classList.add("overflow-hidden");
    } else if (this.isMobileDevice) {
      document.querySelector('body').classList.remove("overflow-hidden");
    }
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = opend ? 'none' : 'block';
    }
  }

  private makePartenzaUrl(p) {
    let url = "/" + SEO_OFFERTE.CROCIERA_CATEGORIA_KEYWORD + "/";
    url += UtilsStringService.removeSpecialChar(p.compagnia) + "/";
    url += UtilsStringService.removeSpecialChar(p.itinerario) + "/";
    url += UtilsStringService.removeSpecialChar(p.porto) + "/";
    url += moment(p.dataPartenza).locale("it").format("DD-MMMM") + "/";
    url += "c" + "/";
    url += p.idPartenza + '';
    return url.toLowerCase().replace(/\s/g, "-");
  }

  public applicaSconto() {
    if (this.formBuonoSconto.valid) {
      this.getImportoPromozione();
    } else {
      this.formBuonoSconto.markAsDirty();
      this.formBuonoSconto.markAllAsTouched();
    }
  }

  public getImportoPromozione() {
    let reqObj = {
      codicePromo: this.formBuonoSconto.controls['buonoSconto'].value,
      idCrociera: this.dataResponse.idCrociera,
      isPrime: this.userData ? (this.userData.userInfo.isPrime ? false : this.showPrime) : this.showPrime,
      totalePreventivo: (this.costoTotale + (this.showPrezzoTotaleConAssicurazione ? this.insurancePrice : 0) + (this.userData ? (this.userData.userInfo.isPrime ? 0 : (this.showPrime ? 19 : 0)) : (this.showPrime ? 19 : 0)))
    };
    this.destSrv.getImportoPromozioneCrociera(reqObj).then((response) => {
      this.dataPromo = response.body.data;
      this.sconto = this.dataPromo ? (!this.dataPromo.isErrorePromo ? this.dataPromo.importo : 0) : 0;
      this.isPrimeObbligatoriaSconto = this.dataPromo ? (!this.dataPromo.isErrorePromo ? this.dataPromo.isPrimeObbligatoria : false) : false;
      this.idPromozione = this.dataPromo ? (!this.dataPromo.isErrorePromo ? this.dataPromo.idPromozione : 0) : 0;
      this.idBuono = this.dataPromo ? (!this.dataPromo.isErrorePromo ? this.dataPromo.idBuono : 0) : 0;
      this.erroreSconto = this.dataPromo ? this.dataPromo.errorePromo : "Il codice inserito non è valido. Inserirne un altro.";
    }, (error) => {
      console.log('error');
    })
  }

}
