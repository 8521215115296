<div class="container border-r-2px padding-20px box-info mt-20px mb-20px text-align-justify background-white overflow-hidden">
  <div class="row mb-40px">
    <div class="col-md-3 align-items-center justify-content-center text-center">
      <img class="mt-1-5-rem width-40-percent"
           [alt]="data.title"
           [src]="data.imgUrl">
    </div>
    <div class="col-9 mt-4">
      <h1 class="text-align-left">{{data.title}}</h1>
      <h3 class="text-align-left">{{data.text}}</h3>
    </div>
  </div>

  <div class="separator"></div>

  <div class="row cont-dest">
    <div class="col-md-6">
      <div class="wd-100" *ngFor="let nazione of listaNazioniViaggiMondo; index as i">
        <div class="mt-1rem" *ngIf="i<twoColumnsThreshold">
          <a class="nation-title link" [routerLink]="'/'+makeUrlSeoHrefForNation(nazione)"
             [hidden]="pathHotel == 'hotel-mondo'">
            {{nazione.nazione | titlecase}}
          </a>
          <div class="nation-title mondo-nation"
               [hidden]="pathHotel != 'hotel-mondo'">{{nazione.nazione | titlecase}}</div>
          <br>
          <a class="link" *ngFor="let destinazione of nazione.destinazioni"
             [title]="destinazione.destinazione"
             [routerLink]="'/'+makeUrlSeoHrefForDest(destinazione)"> {{destinazione.destinazione | titlecase}}
          </a>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="wd-100" *ngFor="let nazione of listaNazioniViaggiMondo; index as i">
        <div class="mt-1rem" *ngIf="i>=twoColumnsThreshold">
          <a [hidden]="pathHotel == 'hotel-mondo'" class="nation-title link"
             [routerLink]="'/'+makeUrlSeoHrefForNation(nazione)">
            {{nazione.nazione | titlecase}}
          </a>
          <div class="nation-title mondo-nation"
               [hidden]="pathHotel != 'hotel-mondo'">{{nazione.nazione | titlecase}}</div>
          <br>
          <a class="link" *ngFor="let destinazione of nazione.destinazioni"
             [title]="destinazione.destinazione"
             [routerLink]="'/'+makeUrlSeoHrefForDest(destinazione)">{{destinazione.destinazione | titlecase}}
          </a>
        </div>
      </div>
    </div>

  </div>

</div>
