import {Component, OnInit} from '@angular/core';
import {HomeService} from "../../services/gate/home.service";
import {MacroBoxEnum} from "../../components/macro-box/config-layout.enum";
import {ModalService} from "../../services/common/modal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ImageUtilService} from "../../services/common/image.util.service";
import {MacroBoxInterface} from "../../components/macro-box/config.interface";
import {config} from "rxjs";

@Component({
  selector: 'borsaviaggi-vetrina-prime',
  templateUrl: './vetrina-prime.component.html',
  styleUrls: ['./vetrina-prime.component.scss']
})
export class VetrinaPrimeComponent implements OnInit {
  public serviziSelezionati = [];
  public datiOffertaPrime;
  public listSelezioniDestinazioni: Array<MacroBoxInterface>;
  constructor(private homeSrv: HomeService,
              private modalSrv: ModalService,
              private router: Router,
              private route: ActivatedRoute) {
    this.datiOffertaPrime = this.route.snapshot.data.offerteVetrina.body.data;
    this.listSelezioniDestinazioni = this.datiOffertaPrime.listaDestinazioniOfferteGold.map((currentOfferta) => {
      return this.mapDestinazioni(currentOfferta);
    });
  }

  ngOnInit() {

  }


  public getOffertePrime(event, title) {
    let param = {
      listaIdServizi: this.getFiltriSelezionati(event, title)
    };
    this.homeSrv.getVetrinaOffertePrime(param).then(
      (response) => {
        if (response && response.body.data) {
          this.listSelezioniDestinazioni = response.body.data.listaDestinazioniOfferteGold.map((currentOfferta) => {
            return this.mapDestinazioni(currentOfferta);
          });
        } else {
          console.error("errore recupero dati prime");
        }
      },
      (error) => {
        console.error("Errore servizio prime");
      }
    )
  }

  public mapDestinazioni(destinazione) {
    let configObj = {};
    configObj['titolo'] = destinazione.nome;
    configObj['boxConfigType'] = MacroBoxEnum.MACRO_VERTICAL_BOX;
    configObj['imgBackground'] = ImageUtilService.supportWebP() ? destinazione.bannerDestWebp : destinazione.bannerDest;
    configObj['isNazione'] = destinazione.isNazione;
    configObj['idDestinazione'] = destinazione.idDestinazione;
    configObj['isDestinazione'] = destinazione.isDestinazione;

    return configObj

  }

  public getFiltriSelezionati(event, title) {
    if (event.checked) {
      this.serviziSelezionati.push(title.toString())
    } else {
      this.serviziSelezionati = this.serviziSelezionati.filter(e => e !== title.toString());
    }

    return this.serviziSelezionati

  }

  public openModaleDestinazioniPrime(destinazione) {
    let paramData = {
      idDestinazione: destinazione.idDestinazione,
      isNazione: destinazione.isNazione || false,
      checked: false,
      disabled: false,
      isDestinazione: destinazione.isDestinazione || false,
      link: null,
      nome: destinazione.titolo,
      urlFoto: null
    };
    this.modalSrv.openModaleDestinazioniPrime(paramData);
  }


}
