<div *ngIf="showSearchForm" class="container-fluid no-gutters wrapper-component padding-0"> <!-- d-sm-flex  -->
  <form [formGroup]="formGroup" class="row no-gutters cursor-default" (ngSubmit)="actionSubmitRicercaCrociere()">
    <!-- position-relative margin-0 -->
    <div class="col-12 col-sm input-search destinazione-col cursor-default">
      <mat-form-field class="text-box mt-15px  full-width" floatLabel="always">
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>room</mat-icon>
        <mat-label>Destinazione</mat-label>
        <mat-select formControlName="destinazione"
                    placeholder="Dove vuoi andare?">
          <mat-option *ngFor="let destinazione of modelSearch.destinazione.list" [value]="destinazione">
            {{destinazione.nomeDestinazioneCrociere | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm input-search partenza-col">
      <mat-form-field class="text-box mt-15px full-width" floatLabel="always">
        <mat-label>Mese partenza</mat-label>
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>today</mat-icon>
        <mat-select formControlName="dataPartenza"
                    placeholder="Quando vuoi partire?"
                    [disabled]="!formGroup.get('destinazione').valid">
          <mat-option *ngFor="let data of modelSearch.date.disponibili" [value]="data.meseAnnoDate">
            {{data.nomeMese}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm input-search porto-col">
      <mat-form-field class="text-box mt-15px full-width" floatLabel="always">
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>map</mat-icon>
        <mat-label>Porto di partenza</mat-label>
        <mat-select formControlName="portoDiPartenza"
                    placeholder="Da dove?"
                    [disabled]="!formGroup.get('destinazione').value || !formGroup.get('dataPartenza').value">
          <mat-option *ngFor="let porto of modelSearch.portoDiPartenza.list" [value]="porto.idPorto">
            {{porto.nomePorto | titlecase}}
          </mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm input-search compagnia-col ">
      <mat-form-field class="text-box mt-9px full-width" floatLabel="always">
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>directions_boat</mat-icon>
        <mat-label>Compagnia</mat-label>
        <mat-select formControlName="compagniaCrociera"
                    placeholder="Con quale compagnia?"
                    [disabled]="!formGroup.get('destinazione').valid || !formGroup.get('dataPartenza').valid || !formGroup.get('portoDiPartenza').valid">
          <mat-option *ngFor="let compagnia of modelSearch.compagniaCrociera.list" [value]="compagnia.idCompagnia">
            {{compagnia.nomeCompagnia | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col col-12 col-sm-2 col-md-2 col-lg-2 ricerca-col">
      <button mat-raised-button type="submit"
              [ngClass]="{'button-disabled':!formGroup.valid}"
              class="ricerca-btn-col background-orange-borsaviaggi color-white">
        <mat-icon role="img"
                  class="color-white fs-1_4rem" aria-hidden="true">search
        </mat-icon>
        <span class="fs-1_2rem">Ricerca</span>
      </button>
    </div>

  </form>
</div>
