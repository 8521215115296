<mat-card class="mt-5px border-grey-1px newsletter">
  <mat-card-content>
    <div class="row">
      <div class="col-12">
        <h2 class="subtitle pb-10px color-text-blue-borsaviaggi text-align-center">Newsletter - Personalizza il
          servizio</h2>
        <div class="">
          <label>Inserisci le tue preferenze, ti consiglieremo al meglio!</label>
          <div class="text-style text-style-small form-group row pb-10px">
            <label class="col-12 pt-3px pb-3px ">1 - Scegli quale promozione vuoi ricevere:</label>
            <mat-checkbox class="col-12 pt-3px pb-3px newsletter" *ngFor="let item of newsLetterPromo"
                          [(ngModel)]="item.isActive">{{item.newsLetter}} </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-style text-style-small form-group row">
          <label class="col-12 pt-3px pb-3px">2 - Quali sono le preferenze per la tua prossima vacanza?</label>
          <mat-checkbox class="col-12 pt-3px pb-3px" *ngFor="let item of newsLetterPreferenze" [(ngModel)]="item.isActive">{{item.newsLetter}} </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button mat-button class="button-style center" (click)="updateNewsletterUtente()">Salva le tue preferenze</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
