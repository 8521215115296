import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";
import * as moment from "moment";
import {Params} from "@angular/router";
import {LOADER_TYPE} from "../../components/loader/loader.service";

@Injectable()
export class DestinazioneService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public getHomeDestinazioni(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/motore/getDestinazioni");
  }

  public getHomeCrocierePorti(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getPortiCrociere", param);
  }

  public getStrutture(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/motore/getStrutture");
  }

  public getCrociereDestinazioni(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/motore/getDestinazioniCrociere");
  }

  public getCrociereCompagnie(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getCompagnieCrociere", param);
  }

  public getCrociereDatePartenza(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getDateCrociere", param);
  }

  public getHomeDateDestinazioni(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getDate", data);
  }

  public getLuoghiPartenza(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getLuoghi", data);
  }

  public getDestinazioniMareItalia(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/motore/getDestinazioniMareItalia");
  }

  public getAeroportiDisponibili(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getAeroportiDisponibili", data);
  }

  public getPartner(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/partner/all");
  }

  public getG(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getOfferta", data);
  }

  public getOfferteTO(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getOfferteTO", data);
  }

  public getStruttura(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getStruttura", data);
  }

  public getDestinazione(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getDestinazione", data);
  }

  public getPartenza(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getPartenza", data);
  }

  public getDescrizionePartenza(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getDescrizionePartenza", data);
  }

  public getOffertaByCode(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/getOffertaByCode", data);
  }

  public getCamereLive(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getCamereLive", data);
  }

  public getCostiPartenzaLive(idPartenza): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/partenza/getCostiPartenzaLive/" + idPartenza);
  }

  public savePreventivo(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/savePreventivo", data);
  }

  public getPreventivo(idPreventivo): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/partenza/getPreventivo/" + idPreventivo);
  }

  public getPreventivoCrociera(idPreventivo): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/partenza/getPreventivoCrociera/" + idPreventivo);
  }

  public getImportoPromozione(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getImportoPromozione", data);
  }

  public getImportoPromozioneCrociera(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getImportoPromozioneCrociera", data);
  }

  public checkout(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/checkout", data);
  }

  public contattaci(data): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/contattaci", data);
  }

  public convertLblTotale(lblTotale) {
    let ret = 0;
    if (lblTotale) {
      ret = parseFloat(lblTotale.replace('€', '').replace('&euro;', '').replace('.', ''));
    }
    return ret;
  }

  public getSommaVantaggiPrime(dataResponse, paramCamera?, totSupplemento?) {
    let totale = 0;

    totSupplemento = totSupplemento || (paramCamera ? paramCamera.totale : false);
    if (dataResponse.serviziPrime) {
      if (paramCamera) {
        if (paramCamera.ListaCamere && Object.keys(paramCamera.ListaCamere).length > 0) {
          let zeroPensieriCalcolato = false;
          for (let key in paramCamera.ListaCamere) {
            dataResponse.serviziPrime.forEach((element) => {
              if (element.isPax) {
                let isSingola = parseInt(paramCamera.ListaCamere[key]) === 1;
                totale += isSingola ? element.importo : element.importo * 2;
              } else {
                if (element.idServizio === -2 && !zeroPensieriCalcolato) {
                  totale += parseInt(this.calcolaAssicurazione(totSupplemento, totSupplemento));
                  zeroPensieriCalcolato = true;
                } else if (element.idServizio !== -2) {
                  totale += element.importo;
                }
              }
            });
          }
        } else if (paramCamera.ListaCamereLive && Object.keys(paramCamera.ListaCamereLive).length > 0) {
          let zeroPensieriCalcolato = false;
          for (let key in paramCamera.ListaCamereLive) {
            let arrDataLive = paramCamera.ListaCamereLive[key] ? paramCamera.ListaCamereLive[key].split("|") : false;
            dataResponse.serviziPrime.forEach((element) => {
              if (element.isPax) {
                let isSingola = arrDataLive[0] === "CAMERA SINGOLA";
                totale += isSingola ? element.importo : element.importo * 2;
              } else {
                if (!zeroPensieriCalcolato && element.idServizio === -2) {
                  totale += parseInt(this.calcolaAssicurazione(totSupplemento));
                  zeroPensieriCalcolato = true;
                } else if (element.idServizio !== -2) {
                  totale += element.importo;
                }
              }
            });
          }
        } else {
          let zeroPensieriCalcolato = false;
          for (let i = 0; i < paramCamera.length; i++) {
            dataResponse.serviziPrime.forEach((element) => {
              if (element.isPax) {
                let isSingola = paramCamera[i].supplementiTipoCamera.filter((currCamera) => currCamera && currCamera.isSingola).length > 0;
                totale += isSingola ? element.importo : element.importo * 2;
              } else {
                if (!zeroPensieriCalcolato && element.idServizio === -2) {
                  totale += parseInt(this.calcolaAssicurazione(totSupplemento));
                  zeroPensieriCalcolato = true;
                } else if (element.idServizio !== -2) {
                  totale += element.importo;
                }
              }
            });
          }
        }
      } else if (dataResponse.serviziPrime) {
        dataResponse.serviziPrime.forEach((element) => {
          if (element.isPax) {
            totale += (element.importo * 2);
          } else {
            totale += element.importo;
          }
        });
      }
    }
    return totale
  }

  private isCameraSingola(cameraParam) {
    let ret = false;
    if (cameraParam.supplementiTipoCamera) {
      ret = cameraParam.supplementiTipoCamera.filter((currCamera) =>
        currCamera && (currCamera.isSingola || currCamera.descrizioneCamera === "CAMERA SINGOLA")).length > 0;
    } else if (cameraParam.ospiti) {
      ret = cameraParam.ospiti.length === 1;
    }
    return ret;
  }

  public getSommaVantaggoPrime(vantaggio, camere, totale) {
    let ret = 0;
    if (camere && camere.length > 0) {
      if (vantaggio.idServizio !== -2) {
        camere.map(
          (currCamera) => {
            if (vantaggio.isPax && vantaggio.idServizio !== -2) {
              let isSingola = this.isCameraSingola(currCamera);
              ret += isSingola ? vantaggio.importo : vantaggio.importo * 2;
            } else {
              ret += vantaggio.importo;
            }
          }
        );
      } else {
        ret += parseInt(this.calcolaAssicurazione(totale));
      }
    } else {
      if (vantaggio.isPax) {
        ret = vantaggio.importo * 2;
      } else if (vantaggio.idServizio === -2) {
        ret = parseInt(this.calcolaAssicurazione(totale)) || vantaggio.importo;
      } else {
        ret = vantaggio.importo;
      }
    }
    return ret
  }

  public generateUrlRisultati(param) {
    let ret: Params = {};
    let destinazioni = param.listDestinazioni.map(item => item.idDestinazione).toString();
    let dataPartenza = moment(param.dataPartenza).format("YYYY-MM-DD");
    let partenzaDa = param.partenzaDa.map(item => item.idLuogoPartenza).toString();
    let adulti = param.persone.adulti;
    let bambini = param.persone.bambini.length;
    let etaBambini = param.persone.bambini.length > 0 ? param.persone.bambini.map(item => item.eta).toString() : null;

    ret["destinazione"] = destinazioni;
    ret["dataPartenza"] = dataPartenza;
    ret["partenzaDa"] = partenzaDa;
    ret["adulti"] = adulti;
    ret["bambini"] = bambini;
    ret["isPrime"] = param.persone.isPrime;
    ret["destinazioneLabel"] = param.listDestinazioni.map(item => item.nome).toString();
    ret["destinazioneNazione"] = param.listDestinazioni.map(item => item.isNazione).toString();
    ret["partenzaDaLabel"] = param.partenzaDa.map(item => item.nome).toString();
    ret["idTo"] = param.idTo;
    ret["idSelezione"] = param.idSelezione;
    ret["idDestinazioneTop"] = param.idDestinazioneTop;
    ret["from"] = param.tipoRicerca;
    ret["typeLoader"] = LOADER_TYPE.SEARCH_VACANZA;

    if (param.persone.bambini.length > 0) {
      ret["etaBambini"] = etaBambini;
    }

    return ret;
  }


  public generateUrlRisultatiCrociere(params) {
    let ret: Params = {};
    let data = params.dataPartenza.toString();
    let idDestinazione = params.destinazione.idDestinazioneCrociere.toString();
    let idPorto = params.portoDiPartenza.toString();
    let idCompagnia = params.compagniaCrociera ? params.compagniaCrociera.toString() : null;

    ret["data"] = data;
    ret["idDestinazione"] = idDestinazione;
    ret["idPorto"] = idPorto;
    ret["idCompagnia"] = idCompagnia;
    ret["from"] = "crociera";
    ret["typeLoader"] = LOADER_TYPE.SEARCH_CROCIERA;

    return ret;
  }


  public getImportoBuonoSconto(totale) {
    let importoSconto = 0;
    if (totale <= 1600) {
      importoSconto = 25;
    } else if (totale > 1600 && totale <= 2800) {
      importoSconto = 50;
    } else if (totale > 2800 && totale <= 4500) {
      importoSconto = 75;
    } else if (totale > 4500) {
      importoSconto = 100;
    }
    return importoSconto;
  }

  public calcolaScontoFlash(totale) {
    let importoSconto = 0;
    if (totale >= 1500) {
      if (totale >= 1500 && totale <= 2000) {
        importoSconto = 50;
      } else if (totale > 2000 && totale <= 2700) {
        importoSconto = 75;
      } else if (totale > 2700 && totale <= 5000) {
        importoSconto = 100;
      } else if (totale > 5000) {
        importoSconto = 200;
      }
    }
    return importoSconto;
  }

  public calcolaAssicurazione(costoTotalePrime, costoTotale?): any {
    let tot = costoTotalePrime || (costoTotale ? costoTotale.toString() : costoTotale);
    let totAssicurazione = tot ? (tot / 100) * 6 : 0;
    return totAssicurazione.toFixed();
  }

}
