import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UtilityService} from "../../services/gate/utility.service";
import {LoaderService} from "../../components/loader/loader.service";
import {ModalService} from "../../services/common/modal.service";

@Component({
  selector: 'borsaviaggi-questionario-post-vendita',
  templateUrl: './questionario-post-vendita.component.html',
  styleUrls: ['./questionario-post-vendita.component.scss']
})
export class QuestionarioPostVenditaComponent implements OnInit {

  public isVacanza: boolean;
  public isUtentePrime: boolean;
  public isCompilato: boolean;
  public serviziForm: FormGroup;
  public vacanzaForm: FormGroup;
  public utentePrimeForm: FormGroup;
  public utenteNoPrimeForm: FormGroup;
  public loading;
  public idContratto: any;
  public showQuestionario: boolean;
  public statusOk: boolean;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public utilSrv: UtilityService,
              private loaderSrv: LoaderService,
              private modalSrv: ModalService) {

    this.serviziForm = new FormGroup({
      'conosciutoTramite': new FormControl('', Validators.required),
      'qualitaPrenotazione': new FormControl('', Validators.required),
      'modalitaPrenotazione': new FormControl('', Validators.required),
      'suggerimentiServizio': new FormControl(''),
      'qualitaPrezzoOfferte': new FormControl('', Validators.required),
      'consultazioneOfferte': new FormControl('', Validators.required),
      'varietaOfferte': new FormControl('', Validators.required),
      'velocitaPagine': new FormControl('', Validators.required),
      'graficaSito': new FormControl('', Validators.required),
      'suggerimentiSito': new FormControl('')
    });

    this.vacanzaForm = new FormGroup({'condizioniStruttura': new FormControl('', Validators.required),
      'qualitaCiboStruttura': new FormControl('', Validators.required),
      'serviziStruttura': new FormControl('', Validators.required),
      'animazioneStruttura': new FormControl('', Validators.required),
      'assistenzaToStruttura': new FormControl('', Validators.required),
      'suggerimentiStruttura': new FormControl('')
    });

    this.utentePrimeForm = new FormGroup({
      'soddisfattoPrime': new FormControl('', Validators.required),
      'consiglioPrime': new FormControl('', Validators.required),
      'suggerimentiPrime': new FormControl('')
    });

    this.utenteNoPrimeForm = new FormGroup({
      'considerazionePrime': new FormControl('', Validators.required)
    });

    this.isVacanza = true;
    this.isUtentePrime = false;
    this.isCompilato = false;
    this.showQuestionario = true;
    this.statusOk = false;

    let idContratto = this.route.snapshot.queryParamMap.get('i');
    let status = this.route.snapshot.queryParamMap.get('status');

    if (idContratto !== null && status === null)
    {
      this.idContratto = idContratto;
      this.statusOk = false;
      let params =  { "idContratto": this.idContratto };

      this.utilSrv.getDatiContrattoQuestionario(params).then(
        (response) => {
          this.isVacanza = response.body.data.isVacanza;
          this.isUtentePrime = response.body.data.isUtentePrime;
          this.isCompilato = response.body.data.isCompilato;
          this.showQuestionario = !response.body.data.isCompilato;
        },
        (error) => {
          console.error("Errore in caricamento dati", error);
        }
      );
    }
    else if (idContratto !== null && status === "conferma") {
      this.idContratto = idContratto;
      this.isVacanza = true;
      this.isUtentePrime = false;
      this.isCompilato = false;
      this.showQuestionario = false;
      this.statusOk = true;
    }
    else if (idContratto === null && status === null) {
      this.showQuestionario = false;
      this.statusOk = false;
    }
  }

  ngOnInit(): void {

  }

  public inserisci() {

    let valid = this.serviziForm.valid && (this.isVacanza ? this.vacanzaForm.valid : true) && (this.isUtentePrime ? this.utentePrimeForm.valid : this.utenteNoPrimeForm.valid);
    if (valid) {
      this.loaderSrv.show();

      let vacanzaParams;
      let utentePrimeParams;
      let utenteNoPrimeParams;

      if (this.isVacanza) {
        vacanzaParams = {
          'condizioniStruttura': this.vacanzaForm.controls['condizioniStruttura'].value,
          'qualitaCiboStruttura': this.vacanzaForm.controls['qualitaCiboStruttura'].value,
          'serviziStruttura': this.vacanzaForm.controls['serviziStruttura'].value,
          'animazioneStruttura': this.vacanzaForm.controls['animazioneStruttura'].value,
          'assistenzaToStruttura': this.vacanzaForm.controls['assistenzaToStruttura'].value,
          'suggerimentiStruttura': this.vacanzaForm.controls['suggerimentiStruttura'].value
        };
      }
      else {
        vacanzaParams = {
          'condizioniStruttura': 0,
          'qualitaCiboStruttura': 0,
          'serviziStruttura': 0,
          'animazioneStruttura': 0,
          'assistenzaToStruttura': 0,
          'suggerimentiStruttura': 0
        };
      }

      if (this.isUtentePrime) {
        utentePrimeParams = {
          'soddisfattoPrime': this.utentePrimeForm.controls['soddisfattoPrime'].value,
          'consiglioPrime': this.utentePrimeForm.controls['consiglioPrime'].value,
          'suggerimentiPrime': this.utentePrimeForm.controls['suggerimentiPrime'].value
        };

        utenteNoPrimeParams = {
          'considerazionePrime': null
        };
      }
      else {
        utentePrimeParams = {
          'soddisfattoPrime': null,
          'consiglioPrime': null,
          'suggerimentiPrime': null
        };

        utenteNoPrimeParams = {
          'considerazionePrime': this.utenteNoPrimeForm.controls['considerazionePrime'].value
        };
      }

      let param = {
        'idContratto': this.idContratto,
        'conosciutoTramite': this.serviziForm.controls['conosciutoTramite'].value,
        'qualitaPrenotazione': this.serviziForm.controls['qualitaPrenotazione'].value,
        'modalitaPrenotazione': this.serviziForm.controls['modalitaPrenotazione'].value,
        'suggerimentiServizio': this.serviziForm.controls['suggerimentiServizio'].value,
        'qualitaPrezzoOfferte': this.serviziForm.controls['qualitaPrezzoOfferte'].value,
        'consultazioneOfferte': this.serviziForm.controls['consultazioneOfferte'].value,
        'varietaOfferte': this.serviziForm.controls['varietaOfferte'].value,
        'velocitaPagine': this.serviziForm.controls['velocitaPagine'].value,
        'graficaSito': this.serviziForm.controls['graficaSito'].value,
        'suggerimentiSito': this.serviziForm.controls['suggerimentiSito'].value,
        'condizioniStruttura': vacanzaParams.condizioniStruttura,
        'qualitaCiboStruttura': vacanzaParams.qualitaCiboStruttura,
        'serviziStruttura': vacanzaParams.serviziStruttura,
        'animazioneStruttura': vacanzaParams.animazioneStruttura,
        'assistenzaToStruttura': vacanzaParams.assistenzaToStruttura,
        'suggerimentiStruttura': vacanzaParams.suggerimentiStruttura,
        'soddisfattoPrime': utentePrimeParams.soddisfattoPrime,
        'consiglioPrime': utentePrimeParams.consiglioPrime,
        'suggerimentiPrime': utentePrimeParams.suggerimentiPrime,
        'considerazionePrime': utenteNoPrimeParams.considerazionePrime
      };

      this.utilSrv.saveQuestionarioPostVendita(param).then(
        (response) => {
          if (response && response.body && (response.body.status === 0 || response.body.status === 1)) {

            if (response.body.data.salvataggioDatiOk) {
              this.redirectTo('/questionario-post-vendita');
            }
            else {
              this.modalSrv.openErrorDialog(response.body.message);
            }

            this.loaderSrv.hide();
          } else {
            this.modalSrv.openErrorDialog(response.body.message);
          }
        },
        (error) => {
          this.modalSrv.openErrorDialog(error);
        }
      );
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(
      () =>
        this.router.navigate([uri], {queryParams: {i: this.idContratto, status: 'conferma'}})
    );
  }
}
