import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";
import {MacroBoxEnum} from "../../components/macro-box/config-layout.enum";
import {MacroBoxInterface} from "../../components/macro-box/config.interface";

@Injectable()
export class SearchResultsCrociereService {

  constructor(private genericHttpService: GenericGateService) {
  }
  

  public searchOfferteVacanze(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/searchOfferteVacanze", request);
  }

  public searchOfferteCrociere(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/searchOfferteCrociere", request);
  }
//   -----------------------------------------------------------------------------------------

  public getHomeData(): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/offerta/getHomeElements");
  }

  public getDettaglioTipologiaOfferta(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getTipologiaTopItems", param);
  }

  public static macroBoxConfig() {
    return {
      listaDiNozze: {
        boxConfigType: MacroBoxEnum.BIG_TEXT_V1,
        imgBackground: "assets/img/home/lista-nozze-macro.png",
        titolo: "LISTA DI NOZZE",
        sottoTitolo: "su Borsaviaggi.it",
        nomePortale: "Borsaviaggi.it",
        btnText: "Leggi",
        btnUrl: "https://www.ilmioviaggiodinozze.org/"
      },
      regalaUnViaggio: {
        boxConfigType: MacroBoxEnum.BIG_SCONTO_V1,
        imgBackground: "assets/img/home/regala-un-viaggio.png",
        titolo: "Regala un BUONO viaggio",
        sottoTitolo: "5%",
        btnText: "Leggi",
        nomePortale: "Borsaviaggi.it",
        btnUrl: "regala-un-buono-vacanza"//TODO
      },
      famiglieInViaggio: {
        boxConfigType: MacroBoxEnum.BIG_TEXT_V2,
        imgBackground: "assets/img/home/famiglie-in-viaggio.jpg",
        titolo: "Famiglia in villaggio",
        sottoTitolo: "Offerte selezionate",
        btnText: "Prenota ora",
        nomePortale: "Borsaviaggi.it",
        btnUrl: "family-bimbi-in-offerta"//TODO
      }
    }
  }

  public static macroSelezioniBoxConfigParser(paramData): Array<MacroBoxInterface> {
    let ret = [];
    paramData.map(
      (currItem) => {
        let tempObj = {
          boxConfigType: MacroBoxEnum.MACRO_VERTICAL_BOX,
          imgBackground: currItem.urlIcona,
          titolo: currItem.titolo,
          sottoTitolo: currItem.descrizione,
          btnText: null,
          nomePortale: null,
          btnUrl: currItem.urlSeo
        };
        ret.push(tempObj);
      }
    );

    return ret;
  }
}
