import {Injectable} from '@angular/core';
import {DestinazioneService} from './destinazione.service';

@Injectable()
export class DettaglioPService {

  constructor(private destSrv: DestinazioneService) {
  }

  // resolve(routeSnap: ActivatedRouteSnapshot) {
  //   let dataParam = {
  //     "idOfferta": routeSnap.params.idG,
  //     "idLuogoPartenza":  0
  //   };

  //   return this.destSrv.getP(dataParam)
  //     .catch(err => {
  //       return err;
  //     });
  // }

  // public getPartenza(param): Promise<any> {
  //   return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/offerta/getTipologiaTopItems", param);
  // }

}
