import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";


@Component({
  selector: 'bv-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.css']
})
export class FormErrorComponent implements OnInit {

  @Input("formControlParam") formControl: FormControl;
  @Input("formErrorMessage") formErrorMessage: Array<any>;

  private defaultErrorMessage: Object = {
    'default': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'pattern', message: 'Codice fiscale non valido!'},
      {type: 'minlength', message: 'Lunghezza minima non rispettata'},
      {type: 'min', message: 'Valore minimo non rispettato'},
      {type: 'max', message: 'Valore massimo non rispettato'},
      {type: 'invalidDate', message: 'Data non valida'},

    ]
  };


  constructor() {

  }

  ngOnInit() {
    if (!this.formControl) {
      console.error("Param formControParam is required! ");
    }
    if (!this.formErrorMessage) {
      this.formErrorMessage = this.defaultErrorMessage['default'];
    }
  }

  public showComma(currentValidation: any) {
    let ret = false;
    let arrVisibleError = [];

    this.formErrorMessage.map((currentErrorMess) => {
      if (this.formControl.hasError(currentErrorMess['type'])) {
        arrVisibleError.push(currentErrorMess);
      }
    });

    if (arrVisibleError.length > 1) {
      let positionCurrentError = arrVisibleError.findIndex(currentItem => currentItem.type === currentValidation['type']);
      ret = positionCurrentError !== (arrVisibleError.length - 1);
    }

    return ret;
  }

}
