<div class="home-page">
  <div class="d-none d-sm-block search-engine-container">
    <borsaviaggi-search-engine [dataSearch]=""></borsaviaggi-search-engine>
  </div>

  <div class="d-block d-sm-none search-engine-container">
    <div class="row no-gutters d-block d-sm-none" (click)="toggleShowSearchEngine()">
      <div class="col-12 mobile-search-btn mr-05rem">
        <mat-icon *ngIf="!showSearchEngine">search</mat-icon>
        <mat-icon *ngIf="showSearchEngine">cancel</mat-icon>
        <span *ngIf="!showSearchEngine">Modifica la tua ricerca</span>
        <span *ngIf="showSearchEngine">Chiudi ricerca</span>
      </div>
    </div>
    <borsaviaggi-search-engine *ngIf="showSearchEngine"></borsaviaggi-search-engine>
  </div>

  <div class="search-results crociere">
    <div class="row no-gutters">
      <div class="col-xl-3 col-md-3 col-sm-12 col-xs-12 margin-filter">
        <div class="sidebar-left ml-0 mr-0">
          <div class="head crociere" (click)="toggleShowFilters()">
            Filtra la tua ricerca
          </div>
          <div class="body" *ngIf="showFilters">

            <!-- Filtro Nave da crociera -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Nave da crociera
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div>
                </div>
                <div class="entry" *ngFor="let df of naveDaCrocieraFilter" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Nave da crociera" [(ngModel)]="df.isSelected" (change)="toggleCheckBox()">
                    {{df.label | titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <!-- Filtro Data partenza -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Data di partenza
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let df of dataPartenzaFilter" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Data di partenza" [(ngModel)]="df.isSelected" (change)="toggleCheckBox()">
                    {{df.label| date:"dd MMMM yyyy"}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <!-- Filtro Mese partenza -->
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Compagnia
                  </div>
                  <div class="arrow rotate-90">
                    <img class="arrow" src="assets/img/icons/result-card/next.svg">
                  </div>
                </div>
                <div class="entry" *ngFor="let df of compagniaFilter" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Compagnia" [(ngModel)]="df.isSelected" (change)="toggleCheckBox()">
                    {{df.label| titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Notti
                  </div>
                  <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
                </div>
                <div class="entry" *ngFor="let nf of NottiList" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Destinazioni" [(ngModel)]="nf.isSelected" [hidden]="nf.hidden"
                                (change)="toggleCheckBox()">
                    {{nf.label}} notti
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="elem">
              <div class="col-12">
                <div class="title-wrapper">
                  <div class="title">
                    Tipologia Cabina
                  </div>
                  <div class="arrow rotate-90">
                    <img class="arrow" src="assets/img/icons/result-card/next.svg">
                  </div>
                </div>
                <div class="entry" *ngFor="let cf of cabinaFilter" [hidden]="!this.initialResults.length>0">
                  <mat-checkbox aria-label="Tipologia cabina" [(ngModel)]="cf.isSelected" (change)="toggleCheckBox()">
                    {{cf.label| titlecase}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-9 col-md-9 col-sm-12 col-xs-12 ">
        <div class="main-container">
          <div class="options-row">
            <div class="options">
              <div class="col-auto" *ngIf="!isMobileDevice">
                <div class="fs-1_2rem">
                  Offerte Trovate:
                  <span class="fw-600">{{searchResultsCrociere.length}}</span>
                </div>
              </div>
              <div class="ml-0 mr-0" *ngIf="isMobileDevice">
                <div class="ml-10px">
                  <mat-slide-toggle
                    color="primary"
                    (click)="cardReduction()">
                    Lista ridotta
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="mode-select" *ngIf="!isMobileDevice">
              <mat-button-toggle-group value="list">
                <mat-button-toggle value="list" (click)="switchCardsLayout(false)">
                  <i class="material-icons">
                    view_list
                  </i>
                </mat-button-toggle>
                <mat-button-toggle value="module" (click)="switchCardsLayout(true)">
                  <i class="material-icons">
                    view_module
                  </i>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="cards-container">
            <div *ngIf="!searchResultsCrociere || searchResultsCrociere.length == 0">Nessun risultato trovato</div>
            <result-card *ngFor="let searchResult of searchResultsCrociere"
                         [config]="resultCardConfig" [data]="searchResult" [cardReduce]="cardReduce"></result-card>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
