<div class="row no-gutters info-row">
    <div class="key-column text-align-right d-none d-md-inline-block col-md-6 col-lg-6 ">
        <div class="key">{{label}}</div>
    </div>
    <div class="key-column col-6 col-sm-12 d-md-none">
        <div class="key">{{label}}</div>
    </div>
    <div class="value-column col-6 col-sm-12 col-md-6 col-lg-6">
      <div class="value-wrapper">
        <span *ngIf="icon" class="icon"><img src="assets/img/icons/{{icon}}.svg"></span>
        <div class="value">{{value || '-'}}</div>
      </div>
    </div>
  </div>
