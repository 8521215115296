import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {DestinazioneService} from "../destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class CheckoutCrociereResolveService {
  constructor(private destSrv: DestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    console.log('routeSnap', routeSnap);
    let dataParam = {
      "idPreventivoCrociera": routeSnap.params.idPreventivoCrociera
    };

    return this.destSrv.getPreventivoCrociera(routeSnap.params.idPreventivoCrociera)
    .catch(err => {
      return err;
    });
  }


}
