export class ResultCard {
  title: string;
  titleNave: string;
  subtitle: string;
  thirdTitle: string;
  destinazione: string;
  nazione: string;
  tourOperator: string;
  nomeStruttura: string;
  categoria: string;
  price: string;
  priceCrociera: any;
  totalPrice: string;
  luogoPartenza: string;
  dataPartenza: string;
  dataFine: string;
  notti: string;
  pax:string;
  tipoOfferta: string;
  isTrasferimentiCollettivi: boolean;
  bonusIcons: any;
  isPricingPrime: boolean;
  messages: any;
  isOffertaPrime: boolean;
  giorniNotti: string;
  giorni: any;
  portoPartenza: string;
  dataInizioCrociera: string;
  dataFineCrociera: string;
  altrePartenze: any;
  listaServiziPrime: [];
  photos: any;
  foto: string;
  urlFoto: string;
  idPartenza: string;
  isPreferito: boolean;
  isAnimali: boolean;
  isBimbiGratis: boolean;
  isFamigliaNumerosa: boolean;
  isFuoriTutto: boolean;
  iconaPromozione: string;
  nomePromozione: string;
  isInEvidenza: boolean;
  isLastMinute: boolean;
  isNoFuel: boolean;
  isOffSpeciale: boolean;
  isOZeroPensieri: boolean;
  isPrenotaPrima: boolean;
  isPricingInEvidenza: boolean;
  isPZeroPensieri: boolean;
  isSingle: boolean;
  isSoloCoppie: boolean;
  isZeroPensieri: boolean;
  isPartenzaLive: boolean;
  iconaPricing: string;
  iconaPricingWebP: string;
  iconaLuogoPartenza: string;

  /** ONLY CROCIERE */
  tipologiaCabine: any;
  prezzoCabinaInterna: any;
  prezzoCabinaEsterna: any;
  prezzoCabinaConBalcone: any;
  prezzoSuite: any;
  img: any;
  icona: any;
  compagnia: any;

  /** END OF: ONLY CROCIERE */

  idDestinazione: any;
  idLuogoPartenza: any;
  idOfferta: any;
  idPartenzaCrociera: any;
  isVolo: boolean;

  constructor(obj?: any) {

    this.title = obj && obj.title || null;
    this.titleNave = obj && obj.titleNave || null;
    this.subtitle = obj && obj.subtitle || null;
    this.thirdTitle = obj && obj.thirdTitle || null;
    this.nomeStruttura = obj && obj.nomeStruttura || null;
    this.categoria = obj && obj.categoria || null;
    this.price = obj && obj.price || null;
    this.destinazione = obj && obj.destinazione || null;
    this.nazione = obj && obj.nazione || null;
    this.tourOperator = obj && obj.tourOperator || null;
    this.listaServiziPrime = obj && obj.listaServiziPrime || [];
    this.priceCrociera = obj && obj.priceCrociera || null;
    this.totalPrice = obj && obj.totalPrice || null;
    this.luogoPartenza = obj && obj.luogoPartenza || null;
    this.dataPartenza = obj && obj.dataPartenza || null;
    this.dataFine = obj && obj.dataFine || null;
    this.pax = obj && obj.pax || null;
    this.notti = obj && obj.notti || null;
    this.tipoOfferta = obj && obj.tipoOfferta || null;
    this.isTrasferimentiCollettivi = obj && obj.isTrasferimentiCollettivi || null;
    this.bonusIcons = obj && obj.bonusIcons || null;
    this.messages = obj && obj.messages || null;
    this.isPricingPrime = obj && obj.isPricingPrime || null;
    this.isOffertaPrime = obj && obj.isOffertaPrime || null;
    this.giorniNotti = obj && obj.giorniNotti || null;
    this.giorni = obj && obj.giorni || null;
    this.portoPartenza = obj && obj.portoPartenza || null;
    this.dataInizioCrociera = obj && obj.dataInizioCrociera || null;
    this.dataFineCrociera = obj && obj.dataFineCrociera || null;
    this.altrePartenze = obj && obj.altrePartenze || null;
    this.titleNave = obj && obj.titleNave || null;
    this.photos = obj && obj.photos || null;
    this.foto = obj && obj.foto || null;
    this.urlFoto = obj && obj.urlFoto || null;
    this.idPartenza = obj && obj.idPartenza || null;
    this.isPreferito = obj && obj.isPreferito || null;
    this.isPartenzaLive = obj && obj.isPartenzaLive || null;

    this.isAnimali = obj && obj.isAnimali || null;
    this.isBimbiGratis = obj && obj.isBimbiGratis || null;
    this.isFamigliaNumerosa = obj && obj.isFamigliaNumerosa || null;
    this.isFuoriTutto = obj && obj.isFuoriTutto || null;
    this.iconaPromozione = obj && obj.iconaPromozione || null;
    this.nomePromozione = obj && obj.nomePromozione || null;
    this.isInEvidenza = obj && obj.isInEvidenza || null;
    this.isLastMinute = obj && obj.isLastMinute || null;
    this.isNoFuel = obj && obj.isNoFuel || null;
    this.isOffSpeciale = obj && obj.isOffSpeciale || null;
    this.isOZeroPensieri = obj && obj.isOZeroPensieri || null;
    this.isPrenotaPrima = obj && obj.isPrenotaPrima || null;
    this.isPricingInEvidenza = obj && obj.isPricingInEvidenza || null;
    this.isPZeroPensieri = obj && obj.isPZeroPensieri || null;
    this.isSingle = obj && obj.isSingle || null;
    this.isSoloCoppie = obj && obj.isSoloCoppie || null;
    this.isZeroPensieri = obj && obj.isZeroPensieri || null;
    this.iconaPricing = obj && obj.iconaPricing || null;
    this.iconaPricingWebP = obj && obj.iconaPricingWebP || null;
    this.iconaLuogoPartenza = obj && obj.iconaLuogoPartenza || null;

    /** ONLY CROCIERE */
    this.tipologiaCabine = obj && obj.tipologiaCabine || null;
    this.prezzoCabinaInterna = obj && obj.prezzoCabinaInterna || null;
    this.prezzoCabinaEsterna = obj && obj.prezzoCabinaEsterna || null;
    this.prezzoCabinaConBalcone = obj && obj.prezzoCabinaConBalcone || null;
    this.prezzoSuite = obj && obj.prezzoSuite || null;
    this.img = obj && obj.img || null;
    this.icona = obj && obj.icona || null;
    this.compagnia = obj && obj.compagnia || null;
    /** END OF: ONLY CROCIERE */

    this.idDestinazione = obj && obj.idDestinazione;
    this.idLuogoPartenza = obj && obj.idLuogoPartenza;
    this.idOfferta = obj && obj.idOfferta;
    this.idPartenzaCrociera = obj && obj.idPartenzaCrociera;
    this.isVolo = obj && obj.isVolo;

  }
}
