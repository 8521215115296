<div class="header-modal">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <h2 mat-dialog-title>{{luogo?.toUpperCase()}}</h2>
</div>
<mat-dialog-content class="position-relative">
  <borsaviaggi-loader [show]="loading" typeMode="piker" diameter="30"></borsaviaggi-loader>
  <agm-map [hidden]="loading" [latitude]="latitude" [longitude]="longitude"
           gestureHandling="greedy"
           [mapTypeControl]='true' [zoom]="10">
    <agm-marker iconUrl="/assets/img/icons/marker-bv-grigio.png" [latitude]="latitude" [longitude]="longitude"
                [markerDraggable]="false"
    ></agm-marker>
  </agm-map>
</mat-dialog-content>

