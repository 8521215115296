import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss']
})

export class GiftCardComponent implements OnInit {
  @Input() public configObj;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  public getDetails(guid){
    this.router.navigate(['./dettaglio', guid], { relativeTo: this.route });
  }
}
