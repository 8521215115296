/*
 * @title Select & Multiselect
 *
 * @property [multi] - to activate multiselect mode
 * @property [data] - type: any[]
 * @property [promiseData] - type: Promise<any>
 * @property [promiseDataPath] - type: string[]
 *
 * @example <howdace-select [multi] [data]="optionsList" [promiseData]="promiseOptionsList"
 *     [promiseDataPath]="'body.data'"></howdace-select>
 * @example <howdace-select [multi] [promiseData]="promiseOptionsList" [promiseDataPath]="'body.data'"></howdace-select>
 * @example <howdace-select [multi] [promiseData]="promiseOptionsList"></howdace-select>
 * @example <howdace-select [multi] [data]="optionsList" ></howdace-select>
 * @example <howdace-select [data]="optionsList" [promiseData]="promiseOptionsList"
 *     [promiseDataPath]="'body.data'"></howdace-select>
 *
 *
 * @info promiseData (should be a Promise) or data (should be an array) will be
 * the selectable options for the multiselect.
 *
 * @info data has precedence over promiseData.
 **/
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {MatSelect} from "@angular/material/select";
import {ControlValueAccessor, FormControl, FormControlName, NgControl} from "@angular/forms";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Subject} from "rxjs";
import {PromiseUtilConfig, PromiseUtilService} from "../../services/common/promiseUtil.service";

@Component({
  selector: 'bv-select',
  templateUrl: './bv-select.component.html',
  styleUrls: ['./bv-select.component.scss'],
  providers: [{provide: [MatFormField, MatSelect], useExisting: [MatSelect, BvSelectComponent]}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BvSelectComponent implements MatFormFieldControl<BvSelectComponent>, ControlValueAccessor, OnInit, OnChanges, DoCheck {
  @ViewChild('matSelect', {static: true}) matSelectObj: MatSelect;
  @Input() data: any[];
  @Input() promiseData: Promise<any>;
  @Input() promiseDataPath: string;
  @Input() valueKey: string;
  @Input() disableClearBtn: boolean;
  @Input() textAlign: string;
  @Input() labelKey: string;
  @Input() ordered: string;
  @Input() disabled: boolean;
  @Input() multi: boolean; // to enable Multiselect mode
  @Input() addMode: any; // to enable 'Add new values' mode
  @Input() addModeAsyncRequest: any;
  @Input() classContainer: string;
  @Output() addModeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() addModePostAsync: EventEmitter<any> = new EventEmitter<any>();
  @Input() typeNumber: boolean; // sets input to type="number"
  @Input() get placeholder() {
    return this.matSelectObj.placeholder;
  };

  @Input()
  get required() {
    return this._required;
  }


  private promiseUtilConfig: PromiseUtilConfig;
  private optionsList: Array<any>;
  public optionsListFiltered: Array<any>;
  public filterText: string;
  public loading: boolean;
  public stateChanges = new Subject<void>();
  public selectAll: boolean = false;
  public newValue: any;
  public tooltipList: Array<any>;
  public Array = Array;

  id: string;
  focused: boolean = false;
  _required: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  errorState: boolean;
  errorMessage: string;
  control: FormControl;


  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _controlName: FormControlName,
    private promiseUtilSrv: PromiseUtilService) {

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.multi = false;
    this.addMode = false;
    this.classContainer = "howdace-select-container";
    this.promiseDataPath = this.promiseDataPath || 'body.data';
    this.errorState = false;
    this.promiseUtilConfig = new PromiseUtilConfig({
      permissiveErrorDialog: true,
      noErrorDialog: false,
      ignoreIsSuccessResponseCheck: false
    });
    this.typeNumber = false;
    this.tooltipList = new Array<any>();

  }

  ngOnInit() {
    this.control = this._controlName.control;
    this.filterText = '';
    this.newValue = '';
    this.getOptions();
    if (this.disabled) {
      this.setDisabledState(true);
    }
    this.matSelectObj._handleKeydown = () => {
    };
  }

  ngDoCheck(): void {
    this.matSelectObj.updateErrorState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.data = changes.data.currentValue;
      this.optionsList = this.data;
      this.optionsListFiltered = this.normalizeOptionItem(this.optionsList);
    }
    if (this.data && this.matSelectObj.value) {
      this.populateTooltipFromRequest();
    }
  }

  public asyncReq() {
    if (this.newValue) {
      const addModeAsyncRequest = this.addModeAsyncRequest(this.newValue);
      this.promiseUtilSrv.resolve(addModeAsyncRequest, (resolve) => {
        this.addModePostAsync.emit(resolve);
        this.clearAddModeInputField();
      }, this.promiseUtilConfig)
    }
  }


  public clearAddModeInputField() {
    this.newValue = '';
  }


  private normalizeOptionItem(paramList) {
    let ret = [];
    if (paramList && Array.isArray(paramList)) {
      paramList.map(
        (currItem) => {
          let tempLabel = this.labelKey ? currItem[this.labelKey] : currItem;
          ret.push(
            {
              value: this.valueKey ? currItem[this.valueKey] : currItem,
              label: tempLabel,
              hidden: false
            }
          )
        }
      );
    }
    return ret;
  }

  get value(): any | null {
    return this.matSelectObj.value;
  }

  set value(value: any | null) {
    this.matSelectObj.value = value;
  }


  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  set placeholder(plh) {
    setTimeout(() => {
      this.matSelectObj.placeholder = plh;
    }, 0);
  }

  public clearFilter() {
    this.filterText = '';
    this.filterOptions('');
  }

  public writeValue(value: any): void {
    this.value = value;

  }

  public registerOnChange(fn: any): void {
    this.matSelectObj.registerOnChange(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.matSelectObj.setDisabledState(isDisabled);
  }

  setDescribedByIds(ids: string[]): void {
  };

  onContainerClick(event: MouseEvent): void {
  };

  public registerOnTouched(fn: any): void {
    this.matSelectObj.registerOnTouched(fn);
  }

  private setErrorState(paramMess) {
    this.errorState = true;
    this.errorMessage = paramMess;
  }

  public actionClearField($event: Event) {
    this.control.setValue("");
    this.tooltipList = [];
    $event.stopImmediatePropagation();
  }

  public populateTooltipValue(value, fromController?) {
    if (this.optionsListFiltered.length > 0) {
      let objValue = this.optionsListFiltered.find(x => x.value === value || x.id === value);
      let textKeyOrLabel = objValue ? (objValue.label || objValue[this.labelKey]) : "";

      let isNotPresent = !this.tooltipList.find(x => x === textKeyOrLabel);
      if (isNotPresent) {
        if (!this.multi) {
          this.tooltipList = [];
        }
        this.tooltipList.push(textKeyOrLabel);
      } else if (!fromController) {
        this.tooltipList = this.removeElementInArray(this.tooltipList, textKeyOrLabel);
      }

    }
  }

  public populateTooltipFromRequest() {
    if (Array.isArray(this.matSelectObj.value)) {
      this.matSelectObj.value.forEach(val => {
        this.populateTooltipValue(val, true);
      });
    } else {
      this.populateTooltipValue(this.matSelectObj.value, true);
    }
  }

  public getOptions() {
    if (this.data) {
      this.loading = true;
      if (Array.isArray(this.data)) {
        this.optionsList = this.data;
        this.optionsListFiltered = this.normalizeOptionItem(this.optionsList);
      }
      this.loading = false;
    } else if (this.promiseData) {
      this.promiseData.then(
        (resolve) => {
          if (Array.isArray(this.getProperty(this.promiseDataPath, resolve))) {
            this.optionsList = this.getProperty(this.promiseDataPath, resolve);
            this.optionsListFiltered = this.normalizeOptionItem(this.optionsList);
            this.control.updateValueAndValidity();
          } else {
            if (this.promiseDataPath) {
              console.error("[promiseDataPath]=" + this.promiseDataPath + " property on <howdace-select> is undefined. ");
            }
            this.setErrorState("Dati non disponibili");
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.setErrorState("Error: getOptions promise rejected");
        }
      )
    }
  }

  public filterOptions(filterText) {
    this.optionsListFiltered.map(
      (el) => {
        if (filterText) {
          filterText = filterText + '';
        }
        el.hidden = filterText ? !el.label.toLowerCase().includes(filterText.toLowerCase()) : false;
      });
  }

  public selectAllResetAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.matSelectObj.value = this.optionsListFiltered;
      this.ngControl.control.setValue(this.optionsListFiltered);
    } else {
      this.ngControl.control.setValue([]);
      this.matSelectObj.value = [];
    }
  }

  public formatTooltipArray(arr: string[] = []) {
    return arr.join(', ');
  }

  private getProperty(propertyName, object) {
    let parts = propertyName.split("."),
      length = parts.length,
      i,
      property = object || this;

    for (i = 0; i < length; i++) {
      property = property[parts[i]];
    }
    return property;
  }

  private removeElementInArray(array, itemToRemove) {
    let supportArray = [];

    array.forEach(ele => {
      if (ele !== itemToRemove) {
        supportArray.push(ele);
      }
    });

    return supportArray;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

}
