import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {ContainerDestinazioniService} from "../container-destinazioni.service";

@Injectable({
  providedIn: 'root'
})
export class DestinazioniMondoResolveService {
  constructor(private containerSrv: ContainerDestinazioniService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    return this.containerSrv.getViaggiMondo()
      .catch(err => {
        return err;
      });
  }

}
