<div class="header-modal">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <!-- <mat-icon matPrefix>single_bed</mat-icon>-->
  <h2 mat-dialog-title>{{errorData.title}}</h2>
</div>
<mat-dialog-content class="position-relative">
  <borsaviaggi-loader [(show)]="loading" typeMode='piker' diameter="30"></borsaviaggi-loader>
  <form [hidden]="loading" [formGroup]="formGroup">
    <div class="info-container row">
      <mat-form-field name="select" class="col-12 col-sm-6 text-center pt-15px" appearance="outline">
        <mat-label>Numero di camere</mat-label>
        <mat-select formControlName="numeroStanze" (selectionChange)="generateFormGroup()">
          <mat-option class="text-center" value="1">1</mat-option>
          <mat-option class="text-center" value="2">2</mat-option>
          <mat-option class="text-center" value="3">3</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="w-100 text-align-right" *ngIf="!isMobileDevice">
      <span class="fs-12px" matTooltip="Gli anni indicati si intendono 'non compiuti'. Es: Bambino 2/12 anni, si intende bambino che non abbia compiuto il 12° anno di età.">
        *età dei bambini - regolamento
      </span>
    </div>
    <div *ngIf="pPageData.dettaglioPartenza.isHPartenzaLive || (!pPageData.dettaglioPartenza.isHPartenzaLive && supplementi && supplementi.length > 0)">
      <div *ngFor="let number of arrayCamere;">
        <div class="main-title-container col-12">
          <div class="title">
            {{pPageData.dettaglioPartenza.isHPartenzaLive ? "Camera" : "Seleziona supplementi camera"}}
            <span>{{number}}</span></div>
          <div class="right-title"></div>
        </div>
        <div class="row no-gutters info-container ">
          <div class="info-wrapper col-12">
            <!-- SELEZIONE TIPO CAMERA -->
            <div class="pt-10px" *ngIf="pPageData.dettaglioPartenza.isHPartenzaLive">
              <mat-form-field name="select" class="col-12 col-sm-6" appearance="outline">
                <mat-label>Numero Adulti</mat-label>
                <mat-select placeholder="Numero Adulti" (selectionChange)="mostraPulsanteVDisp()"
                            formControlName="{{'adultiCamera-'+number}}">
                  <mat-option class="text-center" value="1">1</mat-option>
                  <mat-option class="text-center" value="2">2</mat-option>
                  <mat-option class="text-center" value="3">3</mat-option>
                  <mat-option class="text-center" value="4">4</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field name="select" class="col-12 col-sm-6" appearance="outline">
                <mat-label>Numero Bambini</mat-label>
                <mat-select placeholder="Numero Bambini" (selectionChange)="mostraPulsanteVDisp()"
                            formControlName="{{'bambiniCamera-'+number}}">
                  <mat-option class="text-center" value="">0</mat-option>
                  <mat-option class="text-center" value="1">1</mat-option>
                  <mat-option class="text-center" value="2">2</mat-option>
                  <mat-option class="text-center" value="3">3</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field name="select" *ngIf="formGroup.get('bambiniCamera-'+number).value > 0"
                              class="col-12 col-sm-4"
                              appearance="outline">
                <mat-label>Età Bambino 1</mat-label>
                <mat-select placeholder="Età Bambino 1" (selectionChange)="mostraPulsanteVDisp()"
                            formControlName="{{'etaBambino1Camera-'+number}}">
                  <mat-option class="text-center" value="-">-</mat-option>
                  <mat-option *ngFor="let eta of arrayEta" class="text-center" [value]="eta">{{eta}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="formGroup.get('bambiniCamera-'+number).value > 1" name="select"
                              class="col-12 col-sm-4"
                              appearance="outline">
                <mat-label>Età Bambino 2</mat-label>
                <mat-select placeholder="Età Bambino 2" (selectionChange)="mostraPulsanteVDisp()"
                            formControlName="{{'etaBambino2Camera-'+number}}">
                  <mat-option class="text-center" value="-">-</mat-option>
                  <mat-option *ngFor="let eta of arrayEta" class="text-center" [value]="eta">{{eta}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="formGroup.get('bambiniCamera-'+number).value > 2" name="select"
                              class="col-12 col-sm-4"
                              appearance="outline">
                <mat-label>Età Bambino 3</mat-label>
                <mat-select placeholder="Età Bambino 3" (selectionChange)="mostraPulsanteVDisp()"
                            formControlName="{{'etaBambino3Camera-'+number}}">
                  <mat-option class="text-center" value="-">-</mat-option>
                  <mat-option *ngFor="let eta of arrayEta" class="text-center" [value]="eta">{{eta}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="supplementi-column col-12 padding-0" *ngIf="!pPageData.dettaglioPartenza.isHPartenzaLive">
              <div class="supplemento fs-1rem" *ngFor="let tipologia of supplementi;">
                <div class="name">
                  <mat-checkbox
                    [disabled]="isCameraSingolaSelected(tipologia.idSupplemento,number) || isLettoOcuppato(tipologia.idSupplemento,tipologia.letto,number) || isLettoPrecedenteLibero(tipologia.idSupplemento,tipologia.letto,number) ||
                     disableCameraSingola(tipologia.idSupplemento,tipologia.letto,number)" (change)="checkCameraSingolaSelected($event,tipologia.isSingola,tipologia.idSupplemento)"
                    formControlName="{{tipologia.idSupplemento+'-'+number}}">&nbsp;{{tipologia.nome | titlecase}}
                  </mat-checkbox>
                </div>
                <div class="price bold">{{tipologia.totaleSupplemento | euroFormat}}&nbsp;€</div>
              </div>
            </div>
            <div class="col-12 title-camere-container"
                 *ngIf="pPageData.dettaglioPartenza.isHPartenzaLive && listaCamereLive.length>0 && listaCamereLive[number-1]">
              <div class="title-camera">Seleziona tipologia camera {{number}}</div>
            </div>
            <div class="supplementi-column pl-20px row"
                 *ngIf="pPageData.dettaglioPartenza.isHPartenzaLive && listaCamereLive.length>0 && listaCamereLive[number-1]">
              <div class="supplemento fs-1rem ml-10px mb-5px"
                   *ngFor="let tipologia of listaCamereLive[number-1].listaSupplementiTipoCameraLive;">
                <div class="name">
                  <mat-checkbox formControlName="{{getFormControlNameLive(tipologia.codiceCamera,number, tipologia.costoCamera)}}"
                                (change)="allowOnlyOneCheck(tipologia.codiceCamera, tipologia.costoCamera,number,$event)"
                                class="room-price">
                    <div class="camera-live">
                      <div>{{tipologia.descrizioneCamera | titlecase}}&nbsp;</div>
                      <span class="bold fs-14px costo-camera">{{tipologia.costoCamera | euroFormat}}€</span>
                    </div>
                  </mat-checkbox>
                </div>
              </div>
              <span *ngIf="listaCamereLive[number-1].listaSupplementiTipoCameraLive.length === 0" class="red">Nessuna
                sistemazione disponibile. Verifica altre combinazioni possibili. </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(!supplementi || supplementi.length == 0) && !pPageData.dettaglioPartenza.isHPartenzaLive">
      <span>Nessun supplemento disponibile</span>
    </div>
    <div class="w-100 text-align-left fs-12px" *ngIf="isMobileDevice">
      * Età dei bambini - regolamento: gli anni indicati si intendono 'non compiuti'. Es: Bambino 2/12 anni, si intende bambino che non abbia compiuto il 12° anno di età.
    </div>
    <div *ngIf="pPageData.supplementiFacoltativi.length > 0">
      <div class="title-container-other col-12">
        <div class="title-other">Supplementi su richiesta</div>
        <div class="right-title"></div>
      </div>
      <div class="row no-gutters info-container ">
        <div class="info-wrapper col-12">
          <div class="supplementi-column col-12 padding-0">
            <div class="supplemento fs-1rem" *ngFor="let supplemento of pPageData.supplementiFacoltativi">
              <div class="name">
                {{supplemento.Nome | titlecase}} <span *ngIf="supplemento.bGlobale">(A Camera)</span><span *ngIf="!supplemento.bGlobale">(A Persona)</span>
              </div>
              <div class="price bold">{{supplemento.TotaleSupplemento | euroFormat}}&nbsp;€</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="pPageData.riduzioni.length > 0">
      <div class="title-container-other col-12">
        <div class="title-other">Riduzioni</div>
        <div class="right-title"></div>
      </div>
      <div class="row no-gutters info-container ">
        <div class="info-wrapper col-12">
          <div class="supplementi-column col-12 padding-0">
            <div class="supplemento fs-1rem" *ngFor="let riduzione of pPageData.riduzioni">
              <div class="name">
                {{riduzione.Nome | titlecase}}
              </div>
              <div class="price bold">{{riduzione.TotaleRiduzione | euroFormat}}&nbsp;€</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="pPageData.layoutPartenza.isPnlSecondaSettimanaVisible">
      <div class="title-container-other col-12">
        <div class="title-other">Hai bisogno della seconda settimana?</div>
        <div class="right-title"></div>
      </div>
      <div class="row no-gutters info-container ">
        <div class="info-wrapper col-12">
          <div class="supplementi-column col-12 padding-0">
            <div class="supplemento fs-1rem">
              <div class="name">
                <mat-checkbox formControlName="secondaSettimana">&nbsp;Richiedo la seconda settimana
                </mat-checkbox>
              </div>
            </div>
            <div class="price fs-1rem">Richiedendo la settimana aggiuntiva riceverai un preventivo personalizzato a
              tariffe
              esclusive!
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="button-style mr-5px mb-10px" [disabled]="checkOccupazioneCamere()" [hidden]="loading"
          *ngIf="(pPageData.dettaglioPartenza.isHPartenzaLive && !mostraPulsanteVerifica) || !pPageData.dettaglioPartenza.isHPartenzaLive "
          (click)="generatePreventivoRq()">Salva modifiche
  </button>

  <div [hidden]="loading" class="row mb-10px"
       *ngIf="pPageData.dettaglioPartenza.isHPartenzaLive && mostraPulsanteVerifica">
    <div class="col-12 col-sm mb-10px">
      <button mat-raised-button [disabled]="!costiLive" *ngIf="pPageData.dettaglioPartenza.nottiPartenza >= 7"
              (click)="verificaDisponibilita(7)">Verifica Disponibilità 7 notti
      </button>
      <button mat-raised-button [disabled]="!costiLive" *ngIf="pPageData.dettaglioPartenza.nottiPartenza < 7"
              (click)="verificaDisponibilita(pPageData.dettaglioPartenza.nottiPartenza)">Mostra Altre Sistemazioni Disponibili
      </button>
    </div>
    <div class="col-12 col-sm">
      <button class="verifica-button"
              (click)="verificaDisponibilita(14)"
              *ngIf="pPageData.dettaglioPartenza.isSecondaSettimanaLive" mat-raised-button>Verifica Disponibilità 14
        notti
      </button>
    </div>
  </div>
</mat-dialog-actions>
