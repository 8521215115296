<!--div mat-dialog-close class="appearance-initial position-relative float-right">
  <i class="material-icons">close</i>
</div-->
<div mat-dialog-close class=" appearance-initial text-align-right"
     (click)="closeModal()">
  <i class="material-icons">close</i>
</div>
<div>
  <div class="header-modal mb-20px text-align-center full-width">
    <div>
      <!-- <mat-icon matPrefix class="mr-5px">perm_identity</mat-icon>-->
      <span mat-dialog-title class="inline margin-0">Recupero Password</span>
    </div>
  </div>
  <mat-dialog-content class="mb-20px p-15px-impo">
    <borsaviaggi-loader [(show)]="loading" typeMode='fullScreen'></borsaviaggi-loader>
    <form>
      <div class="form-group">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput [formControl]="recuperoForm" autocomplete="off" placeholder="Email" type="text">
            <mat-error *ngIf="recuperoForm.hasError('email') && !recuperoForm.hasError('required')">
              Inserisci un indirizzo email valido
            </mat-error>
            <mat-error *ngIf="recuperoForm.hasError('required')">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 red">{{errorMessage}}</div>
    </form>
    <mat-dialog-actions align="center">
      <button (click)="recupero(recuperoForm.valid)" mat-button class="blue-button" type="submit">
        <span>Recupera</span>
      </button>
    </mat-dialog-actions>
    <!--<p class="mt-2rem">Non sei registrato? <a class="color-text-orange-borsaviaggi cursor-pointer" (click)="actionSignUp()">Registrati!</a>
    </p>
    <p class="mt-1rem">Hai dimenticato la Password? <a class="color-text-orange-borsaviaggi cursor-pointer" (click)="actionSignUp()">clicca qui</a>
    </p>-->
  </mat-dialog-content>
</div>
