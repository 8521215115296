<div class="container-fluid no-gutters wrapper-component padding-0"> <!-- padding-0 -->
  <form [formGroup]="formGroup" class="row position-relative margin-0" (ngSubmit)="actionSubmitRicerca()">
    <div
      class="col-12 col-sm-10 col-md-10 col-lg-10 cursor-default struttura-col input-search d-flex align-items-center justify-content-center border-r-1px">
      <!-- col-sm-auto col -->
      <mat-form-field class="text-box mt-15px full-width" floatLabel="always">
        <mat-icon class="mr-5px vertical-align-sub" matPrefix>business</mat-icon>
        <mat-label>Struttura</mat-label>
        <input class="cursor-default"
               type="text" matInput
               formControlName="struttura"
               placeholder="In quale struttura vuoi andare?"
               [matAutocomplete]="struttura"
               disabled>
        <mat-autocomplete #struttura="matAutocomplete"
                          [displayWith]="displayStruttura">
          <mat-option *ngFor="let struttura of modelSearch.strutture.autocompeteList | async"
                      [value]="struttura">
            {{struttura.nomeStruttura | titlecase}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="col-12 col-sm-2 col-md-2 col-lg-2 padding-0"> <!-- col-sm-auto -->
      <button mat-raised-button type="submit"
              [ngClass]="{'button-disabled':!formGroup.valid}"
              class="ricerca-btn-col background-orange-borsaviaggi color-white">
        <mat-icon role="img"
                  class="color-white fs-1_4rem" aria-hidden="true">search
        </mat-icon>
        <span class="fs-1_2rem">Ricerca</span>
      </button>
    </div>


  </form>

</div>
