import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ResultCardConfig} from "../../../../model/search-results/resultCardConfig.model";

@Component({
  selector: 'borsaviaggi-lista-prenotazioni',
  templateUrl: './lista-prenotazioni.component.html',
  styleUrls: ['./lista-prenotazioni.component.scss']
})
export class ListaPrenotazioniComponent implements OnInit {

  public listaPrenotazioniData = [];
  public resultCardConfig: ResultCardConfig;

  constructor(private route: ActivatedRoute,) {
    this.resultCardConfig = new ResultCardConfig({
      vertical: false,
      isVacanze: false,
      isCrociere: false,
      isListaPrenotazioni: true,
      isListaDesideri: false
    });
    if (this.route.snapshot.data.dataPrenotazioni.body && this.route.snapshot.data.dataPrenotazioni.body.data) {
      this.listaPrenotazioniData = this.route.snapshot.data.dataPrenotazioni.body.data.listaPreventiviUtente;
    }
  }

  ngOnInit() {
  }


}
