import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class LoginSidenavService {
  private visibleLoginSubject = new Subject<boolean>();
  private openLoginSubject = new Subject<boolean>();
  public openLoginState = this.openLoginSubject.asObservable();
  public visibleLoginState = this.visibleLoginSubject.asObservable();

  constructor() {
  }

  public open() {
    let bodyElement = document.querySelector('#bodyContainer');
    if (bodyElement) {
      bodyElement['style'].overflowY = 'hidden';
    }

    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = 'none';
    }

    this.openLoginSubject.next(true);
  }

  public close() {
    let bodyElement = document.querySelector('#bodyContainer');
    if (bodyElement) {
      bodyElement['style'].overflowY = 'auto';
    }

    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = 'inline';
    }

    this.openLoginSubject.next(false);
  }

  public show() {
    this.visibleLoginSubject.next(true);
  }

  public hide() {
    this.visibleLoginSubject.next(false);
  }
}
