<div class="container border-r-2px mt-20px pt-10px  text-align-justify background-white">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi">
        Se sei "veramente capace" e vuoi essere adeguatamente retribuito allora...Vieni a lavorare con Noi!
      </h1>
    </div>
  </div>
  <div class="row mt-40px">
    <div class="col">
      <h2 class="color-text-blue-borsaviaggi">Chi cerchiamo</h2>
      <p>
        Ricerchiamo agenti di viaggio, esperti in vendita di pacchetti, con almeno un anno di esperienza
        nel settore turistico e spiccata attitudine alla vendita. Cerchiamo consulenti di viaggio propositivi
        da inserire nello staff dei nostri uffici con lavoro in sede in un ambiente dinamico, accogliente,
        ben retribuito e stimolante.
      </p>
    </div>
  </div>
  <div class="row mt-40px">
    <div class="col">
      <h2 class="color-text-blue-borsaviaggi">I profili richiesti:</h2>
      <h3 class="color-text-orange-borsaviaggi mt-20px">- Agente di viaggio - Area booking</h3>
      <p>
        Ricerchiamo agenti di viaggio, esperti in vendita di pacchetti, con almeno un anno di esperienza
        nel settore turistico e spiccata attitudine alla vendita. Cerchiamo consulenti di viaggio propositivi
        da inserire nello staff dei nostri uffici con lavoro in sede in un ambiente dinamico, accogliente,
        ben retribuito e stimolante.
      </p>
      <h3 class="color-text-orange-borsaviaggi mt-20px">- Area Prodotto</h3>
      <p>
        E' richiesta una buona conoscenza del mercato dei pacchetti turistici e attitudine alle relazioni
        commerciali con i Tour Operator. Il ruolo comporta l'individuazione, l'inserimento e la creazione
        all'interno dei nostri database delle migliori offerte di viaggio presenti sul mercato italiano.
        E' richiesta una buona dimestichezza con Internet.
      </p>
      <h3 class="color-text-orange-borsaviaggi mt-20px">- Stage</h3>
      <p>
        Ricerchiamo studenti universitari, neo-laureati o diplomati con buona cultura generale, conoscenza geografica, capacità di lavorare in team e grande passione per i viaggi.
      </p>
    </div>
  </div>
  <div class="row mt-40px mb-20px">
    <div class="col">
      <h2 class="color-text-blue-borsaviaggi">Cosa Offriamo</h2>
      <p>
        - Offriamo un inquadramento contrattuale con incentivi sulla produzione
        ed ottime prospettive di crescita professionale. Il pacchetto retributivo è di
        sicuro interesse e verrà definito in base alle esperienze del candidato.
      </p>
      <p>
        - Offriamo la possibilità di effettuare uno stage formativo a termine (3/6 mesi) per l'avviamento al lavoro.
        A fine stage le risorse migliori potranno essere integrate nel nostro staff con un contratto di lavoro.
        La sede di lavoro è Roma.
      </p>
      <p>La sede di lavoro è Roma. </p>
    </div>
  </div>
  <hr>
  <div class="row mt-20px">
    <div class="col text-align-center">
      <h1 class="color-text-blue-borsaviaggi">
        COMPILA IL CURRICULUM
      </h1>
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="formGroup" class="mt-40px">
      <div class="row mt-15px">
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Nome</mat-label>
            <input required type="text" matInput formControlName="nome">
            <mat-error *ngIf="formGroup.get('nome').invalid">
              <div>Il campo nome è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Cognome</mat-label>
            <input required type="text" matInput formControlName="cognome">
            <mat-error *ngIf="formGroup.get('cognome').invalid">
              <div>Il campo cognome è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col">
          <mat-form-field  appearance="outline" (click)="picker.open()" class="dataNascita">
            <mat-label>Data di nascita</mat-label>
            <input required matInput [matDatepicker]="picker" formControlName="dataNascita">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="formGroup.get('dataNascita').invalid">
              <div>Il campo data di nascita è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Indirizzo</mat-label>
            <input required type="text" matInput formControlName="indirizzo">
            <mat-error *ngIf="formGroup.get('indirizzo').invalid">
              <div>Il campo indirizzo è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Regione</mat-label>
            <mat-select required (valueChange)="getProvince($event)" formControlName="regione">
              <mat-option *ngFor="let regione of regioni" [value]="regione.idRegione">
                {{regione.regione}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('regione').invalid">
              <div>Il campo regione è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Provincia</mat-label>
            <mat-select required formControlName="provincia">
              <mat-option *ngFor="let provincia of province" [value]="provincia.idProvincia">
                {{provincia.provincia}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('provincia').invalid">
              <div>Il campo provincia è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Nazionalità</mat-label>
            <input required type="text" matInput formControlName="nazionalita">
            <mat-error *ngIf="formGroup.get('nazionalita').invalid">
              <div>Il campo nazionalita è obbligatorio</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Telefono fisso</mat-label>
            <input required type="number" matInput formControlName="telefono">
            <mat-error *ngIf="formGroup.get('telefono').invalid">
              <div *ngIf="formGroup.get('telefono').hasError('required'); else errorTelefono">
                Il campo telefono è obbligatorio
              </div>
              <ng-template #errorTelefono>
                Numero non valido
              </ng-template>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Cellulare</mat-label>
            <input required type="number" matInput formControlName="cellulare">
            <mat-error *ngIf="formGroup.get('cellulare').invalid">
              <div *ngIf="formGroup.get('cellulare').hasError('required'); else errorCell">
                Il campo cellulare è obbligatorio
              </div>
              <ng-template #errorCell>
                Numero non valido
              </ng-template>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Email</mat-label>
            <input required type="email" matInput formControlName="email">
            <mat-error *ngIf="formGroup.get('email').invalid">
              <div *ngIf="formGroup.get('email').hasError('required'); else errorEmail">
                Il campo email è obbligatorio
              </div>
              <ng-template #errorEmail>
                Email non valida
              </ng-template>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col">
          <div class="fs-10px">Se nella lista non trovi quello che cerchi clicca sul pulsante per compilare a mano</div>
          <mat-form-field appearance="outline" class="specific-input" *ngIf="!verificaDiploma">
            <mat-label>Diploma in</mat-label>
            <mat-select required formControlName="diploma">
              <mat-option *ngFor="let diploma of elencoDiplomi" [value]="diploma">
                {{diploma}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('diploma').invalid">
              <div>Il campo diploma è obbligatorio</div>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="specific-input" *ngIf="verificaDiploma">
            <mat-label>Diploma in</mat-label>
            <input required type="text" matInput formControlName="diploma">
            <mat-error *ngIf="formGroup.get('diploma').invalid">
              <div>Il campo diploma è obbligatorio</div>
            </mat-error>
          </mat-form-field>
          <mat-icon (click)="updateVerificaDiploma()">
            loop
          </mat-icon>
        </div>

        <div class="col">
          <div class="fs-10px">Se nella lista non trovi quello che cerchi clicca sul pulsante per compilare a mano</div>
          <mat-form-field appearance="outline" *ngIf="!verificaLaurea" class="specific-input">
            <mat-label>Laurea in</mat-label>
            <mat-select formControlName="laurea">
              <mat-option *ngFor="let laurea of elencoLauree" [value]="laurea">
                {{laurea}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="verificaLaurea" class="specific-input">
            <mat-label>Laurea in</mat-label>
            <input type="text" matInput formControlName="laurea">
          </mat-form-field>
          <mat-icon (click)="updateVerificaLaurea()">
            loop
          </mat-icon>
        </div>
      </div>

      <div class="row mt-20px">
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Specializzazione</mat-label>
            <input type="text" matInput formControlName="specializzazione">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Occupazioni precedenti</mat-label>
            <textarea matInput formControlName="occupazioniPrecedenti"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Lingue</mat-label>
            <mat-select multiple formControlName="lingue">
              <mat-option *ngFor="let lingua of lingue" [value]="lingua">{{lingua}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field  appearance="outline">
            <mat-label>Altre Lingue (separate da una ',' virgola )</mat-label>
            <input type="text" matInput formControlName="altreLingue">
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-15px">
        <div class="col-md-6 col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Indicaci il profilo che ti interessa maggiormente</mat-label>
            <mat-select formControlName="profiloInteressato">
              <mat-option *ngFor="let interesse of profiloInteressato" [value]="interesse">{{interesse}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12 mt-20px">
          <mat-label>Orari/spostamenti</mat-label>
          <mat-radio-group required aria-label="Orari/spostamenti" formControlName="orari">
            <mat-radio-button value="SI">Si</mat-radio-button>
            <mat-radio-button value="NO">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col-md-6 col-sm-12">
          <mat-form-field  appearance="outline">
            <mat-label>Particolari attitudini</mat-label>
            <input type="text" matInput formControlName="attitudini">
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-20px">
        <div class="col mt-20px">
          <mat-checkbox matInput formControlName="privacy">
          </mat-checkbox>
          <span class="ml-5px">Dò il mio consenso per il trattamento dei dati personali come descritto nella seguente
            <a target="_blank" routerLink="/informativa-dati-personali">informativa</a></span>
        </div>
      </div>
      <div class="row mt-40px mb-20px">
        <div class="col text-align-center">
          <borsaviaggi-promise-btn
            type="submit"
            [loading]="loading"
            buttonText="Invia"
            (buttonClick)="send(formGroup.valid)"
            [disabled]="!formGroup.valid"
            buttonClass=" no-gutters padding-0 background-blue-borsaviaggi color-white">

          </borsaviaggi-promise-btn>
        </div>
      </div>
    </form>
  </div>
</div>
