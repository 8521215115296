import {ModalInterface} from "../interface/modal.interface";

export class InfoModalDto implements ModalInterface<InfoModalDto> {

  public title: string;
  public body_message: any;
  public button_text: string;
  public button_icon?: string;
  public dataParam?: any;

  constructor() {
    this.title = "Info";
    this.body_message = "";
    this.button_text = "Dettaglio Chiamata";
    this.button_icon = "call_made";
    this.dataParam = {};
  }


}
