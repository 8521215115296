import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

export class SearchEngineValidator {

  static formVacanze(configData?): FormGroup {
    configData = configData || {
      destinazione: '',
      dataPartenza: {disabled: true, value: ''},
      persone: {disabled: true, value: ''},
      isPrime: {disabled: false, value: false},
      partenzaDa: {disabled: true, value: ''}
    };
    return new FormBuilder().group({
      hideRequired: false,
      destinazione: new FormControl(configData.destinazione, [Validators.required]),
      partenzaDa: new FormControl(configData.partenzaDa, [Validators.required]),
      dataPartenza: new FormControl(configData.dataPartenza, [Validators.required]),
      isPrime: new FormControl(configData.isPrime, []),
      persone: new FormControl(configData.persone, [])
    });
  }

  static formCamere(configData?): FormGroup {
    configData = configData || {
      numeroAdulti: 2,
      numeroBambini: 0,
    };
    return new FormBuilder().group({
      hideRequired: false,
      numeroAdulti: new FormControl(configData.numeroAdulti, [Validators.required]),
      numeroBambini: new FormControl(configData.numeroBambini, [Validators.required])
    });
  }

  static formCrociere(configData?): FormGroup {
    configData = configData || {
      destinazione: "",
      dataPartenza: {disabled: true, value: ""},
      portoDiPartenza: "",
      compagniaCrociera: ""
    };
    return new FormBuilder().group({
      hideRequired: false,
      destinazione: new FormControl(configData.destinazione, [Validators.required]),
      dataPartenza: new FormControl(configData.dataPartenza, [Validators.required]),
      portoDiPartenza: new FormControl(configData.portoDiPartenza, [Validators.required]),
      compagniaCrociera: new FormControl(configData.compagniaCrociera, [])
    });
  }

  static formStruttura(configData?): FormGroup {
    configData = configData || {
      struttura: ""
    };
    return new FormBuilder().group({
      hideRequired: false,
      struttura: new FormControl(configData.struttura, [Validators.required])
    });
  }

  static formAltro(configData?): FormGroup {
    configData = configData || {
      tourOperator: "",
      lastminute: false,
      single: false,
      bimbiGratis: false,
      zeroPensieri: false,
      famigliaNumerosa: false,
    };
    return new FormBuilder().group({
      hideRequired: false,
      tourOperator: new FormControl(configData.tourOperator, []),
      lastminute: new FormControl(configData.lastminute, []),
      single: new FormControl(configData.single, []),
      zeroPensieri: new FormControl(configData.zeroPensieri, []),
      bimbiGratis: new FormControl(configData.bimbiGratis, []),
      famigliaNumerosa: new FormControl(configData.famigliaNumerosa, [])
    });
  }

  static formFiltersCheckboxes(configData?): FormGroup {
    configData = configData || {
      test: true
    };
    return new FormBuilder().group({
      test: new FormControl(configData.test)
    })
  }

  static clearDestinazioniParam(dataDestionazioni) {
    return dataDestionazioni.map(
      (item) => {
        return {
          isNazione: item.isNazione,
          idDestinazione: item.idDestinazione
        }
      }
    );
  }


  static dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (t.value && t.value < f.value) {
        group.controls[to].setErrors({
          invalidDate: true
        });
        return {
          invalidDate: true
        };
      }
      return null;
    }
  }


}
