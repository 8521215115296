<div class="mobile-background-container display-mobile mt-10px"
     [ngStyle]="{'backgroundImage': 'url(' + dataResponse.urlIcona + ')'}">
  <div class="mobile-title-container">
    <h1 class="text-align-center mobile-title">{{dataResponse.titolo}}</h1>
  </div>
  <div class="mobile-background-text">
    <h3 class="text-align-left d-sm-none mobile-testo">{{dataResponse.descrizione}}</h3>
  </div>
</div>
<div class="container border-r-2px pl-20px pt-10px pr-20px box-promo mb-20px text-align-justify background-white">
  <ng-container *ngIf="dataResponse">


    <div class="row mb-40px display-not-mobile">
      <div class="macro-image col-3">
        <img class="macro-header-img"
             [src]="dataResponse.urlIcona"
             [alt]="dataResponse.titolo">
      </div>
      <div class="col-9 mt-1-5-rem tablet-mt-0">
        <h1 class="title-macro">{{dataResponse.titolo}}</h1>
        <h3 class="descr-macro">{{dataResponse.descrizione}}</h3>
      </div>
    </div>

    <div class="row mt-10px">
      <span class="ml-15px mb-10px title-filter display-block color-text-blue-borsaviaggi fs-1_4rem fw-600">
        Scopri le offerte disponibili
      </span>
      <borsaviaggi-search-macro class="col-12"
                                  *ngIf="route.snapshot.url[0].path == 'destinazioni-top'"
                                  [filterDestinations]="dataDestinazioni"
                                  [destinazioniTop]="true"
                                  [idDestinazioneTop]="routerParams.idSelezione"></borsaviaggi-search-macro>
      <borsaviaggi-search-macro class="col-12"
                                  *ngIf="routerParams.idSelezione && route.snapshot.url[0].path != 'destinazioni-top'"
                                  [filterDestinations]="dataDestinazioni"
                                  [idSelezione]="routerParams.idSelezione"></borsaviaggi-search-macro>
    </div>

    <!-- Tabella Destinazioni -->
    <borsaviaggi-loader [show]="!showTable && filterClick" typeMode="piker"></borsaviaggi-loader>
    <mat-accordion [hidden]="!showTable" [multi]="true">
      <mat-expansion-panel *ngFor="let item of dataTable; first as isFirst" [expanded]="true">
        <mat-expansion-panel-header class="prenota2 right-aligned-header">
          <mat-panel-title *ngIf="objectKeys(item.listaPartenze).length > 0">
            {{item.panelTitle}}
          </mat-panel-title>
          <mat-panel-description>
            <div class="mobile-id-struttura">
              Codice G : {{item.idOfferta}}</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="mat-elevation-z8 bv-table overflow-auto" [hidden]="!showTable">
          <table mat-table [dataSource]="item.listaPartenze">
            <ng-container matColumnDef="codicePartenza">
              <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Codice Partenza</th>
              <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> {{element.idPartenza}} </td>
            </ng-container>
            <ng-container matColumnDef="dataPartenza">
              <th mat-header-cell *matHeaderCellDef> Data Partenza</th>
              <td mat-cell *matCellDef="let element"> {{element.dataPartenza | date :'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="giorniNotti">
              <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Giorni/Notti</th>
              <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell"> {{element.giorniNotti}} </td>
            </ng-container>
            <ng-container matColumnDef="trattamento">
              <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> Trattamento</th>
              <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell"> {{element.trattamento}}</td>
            </ng-container>
            <ng-container matColumnDef="icons">
              <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"></th>
              <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                <borsaviaggi-partenze-icons [partenza]="element"></borsaviaggi-partenze-icons>
              </td>
            </ng-container>
            <ng-container matColumnDef="listaDesideri">
              <th mat-header-cell *matHeaderCellDef> Lista Desideri</th>
              <td mat-cell *matCellDef="let element">
                <borsaviaggi-preferiti [partenza]="element" [mode]="'g-page'"></borsaviaggi-preferiti>
              </td>
            </ng-container>
            <ng-container matColumnDef="prezzo">
              <th mat-header-cell *matHeaderCellDef> Prezzo</th>
              <td mat-cell *matCellDef="let element"
                  class="bold ws-nowrap">  {{element.prezzo && element.prezzo != '0' ? element.prezzo + '€' : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="prime">
              <th mat-header-cell *matHeaderCellDef class="d-none d-sm-table-cell"> Offerta</th>
              <td mat-cell *matCellDef="let element" class="d-none d-sm-table-cell">
                <img *ngIf="element.isPartenzaPrime"
                     src="../../../assets/img/borsaviaggi_prime.png"
                     height="25"
                     width="100"/></td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <a [routerLink]="element.url">
                  <button class="prenota"
                          mat-raised-button>{{element.prezzo && element.prezzo != '0' ? 'VAI' : 'Calcola preventivo'}}
                  </button>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="row mb-20px mt-20px">
      <div class="col-12 ">
        <div class="title-divider text-align-center  border-r-2px background-orange-borsaviaggi p-1px-0">
          <span class="fw-400 fs-1-6rem mb-30px color-white">Scegli per Destinazione</span>
        </div>
      </div>
    </div>
    <div class="row image-cards">
      <borsaviaggi-macro-box *ngFor="let boxSelezioneDistinazione of listSelezioniDestinazioni"
                             [configObj]="boxSelezioneDistinazione"
                             class="pl-0 col-lg-3 col-md-4 col-sm-6 mb-20px  d-flex align-items-center justify-content-center"></borsaviaggi-macro-box>
    </div>
  </ng-container>
</div>

