import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorModalDto} from "../../../model/errorModal.dto";
import {ModalInterface} from "../../../interface/modal.interface";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {ResponseCheckerService} from "../../../services/common/response.checker.service";
import {LoaderService} from "../../loader/loader.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  templateUrl: './modifica-occupazione-modal.component.html',
  styleUrls: ['./modifica-occupazione-modal.component.scss']
})
export class ModificaOccupazioneModalComponent implements OnInit {
  public errorData = new ErrorModalDto();
  public pPageData: any;
  public supplementi: any;
  public arrayCamere;
  public formGroup: FormGroup = new FormBuilder().group({
    secondaSettimana: new FormControl('', Validators.compose([])),
    numeroStanze: new FormControl('1', Validators.compose([]))

  });
  public arrayEta = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  public costiLive;
  public listaCamereLive = [];
  public mostraPulsanteVerifica: boolean = true;
  public loading: boolean;
  public isFristLoad: boolean = true;
  private secondaSettimanaLive = false;
  public isMobileDevice: boolean;
  public ricercaLiveValida: boolean;
  private idCameraSingolaSelected: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(public dialogRef: MatDialogRef<ModificaOccupazioneModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<ModificaOccupazioneModalComponent>,
              private elementRef: ElementRef,
              private deviceService: DeviceDetectorService,
              private loaderSrv: LoaderService,
              private destSrv: DestinazioneService) {

    this.isMobileDevice = this.deviceService.isMobile();
    this.idCameraSingolaSelected = null;

    if (Object.keys(data).length) {
      for (let key in data) {
        this.errorData[key] = data[key] || this.errorData[key];
      }
    }

    this.pPageData = {
      partenza: this.data.dataParam.dataResponse,
      dettaglioPartenza: this.data.dataParam.dataResponse.dettaglioPartenza,
      layoutPartenza: this.data.dataParam.dataResponse.layoutPartenza,
      descPartenza: this.data.dataParam.dataDescPartenza,
      idPartenza: this.data.dataParam.idPartenza,
      tipoCameraDef: this.data.dataParam.costiLive ? this.data.dataParam.costiLive.codiceCameraLive : null,
      supplementiFacoltativi: this.data.dataParam.dataResponse.supplementiFacoltativi,
      riduzioni: this.data.dataParam.dataResponse.riduzioni
    };

    this.supplementi = this.pPageData.partenza.supplementi;
    this.costiLive = this.data.dataParam.costiLive;
    if (this.data.dataParam.formGroupCamere) {
      this.formGroup = this.data.dataParam.formGroupCamere;
      this.isFristLoad = false;
      this.idCameraSingolaSelected = this.data.dataParam.idCameraSingolaSelected;
    }
    this.mostraPulsanteVerifica = true;
  }

  ngOnInit() {
    this.generateFormGroup();
  }


  public generateFormGroup() {
    this.arrayCamere = [];
    this.mostraPulsanteVerifica = true;
    this.listaCamereLive = [];
    for (let i = 1; i <= this.formGroup.get("numeroStanze").value; i++) {
      if (this.pPageData.dettaglioPartenza.isHPartenzaLive) {
        this.formGroup.addControl("adultiCamera-" + i, new FormControl('2', Validators.compose([])));
        this.formGroup.addControl("bambiniCamera-" + i, new FormControl('', Validators.compose([])));
        this.formGroup.addControl("etaBambino1Camera-" + i, new FormControl('-', Validators.compose([])));
        this.formGroup.addControl("etaBambino2Camera-" + i, new FormControl('-', Validators.compose([])));
        this.formGroup.addControl("etaBambino3Camera-" + i, new FormControl('-', Validators.compose([])));
      }
      this.supplementi.forEach((element) => {
          this.formGroup.addControl(element.idSupplemento + "-" + i, new FormControl('', Validators.compose([])));
        }
      );
      this.arrayCamere.push(i)
    }
  }

  public mostraPulsanteVDisp() {
    this.mostraPulsanteVerifica = true;
    this.listaCamereLive = [];
    /*setTimeout(
      () => {
        let scrollOffset = this.elementRef.nativeElement.querySelector('mat-dialog-content').offsetHeight;
        this.elementRef.nativeElement.querySelector('mat-dialog-content').scrollTo(0, scrollOffset);
      }, 0
    );*/
  }

  public checkCameraSingolaSelected(ob: MatCheckboxChange, isSingola, suppId) {
    if (ob.checked) {
      if (isSingola) {
        this.idCameraSingolaSelected = suppId;
      }
      else {
        this.idCameraSingolaSelected = null;
      }
    }
    else {
      this.idCameraSingolaSelected = null;
    }
  }

  private isCameraSingola(numeroCamera) {
    let idSupplementoCameraSingola = this.idCameraSingolaSelected != null ? this.idCameraSingolaSelected : null;
    if (this.idCameraSingolaSelected == null) {
      this.supplementi.forEach((element) => {
        if (element.isSingola) {
          idSupplementoCameraSingola = element.idSupplemento
        }
      });
    }

    return idSupplementoCameraSingola ? this.formGroup.get(idSupplementoCameraSingola + '-' + numeroCamera).value === true : false;


  }


  public isCameraSingolaSelected(currentSuppId, numeroCamera) {
    let idSupplementoCameraSingola = this.idCameraSingolaSelected != null ? this.idCameraSingolaSelected : null;
    if (this.idCameraSingolaSelected == null) {
      this.supplementi.forEach((element) => {
        if (element.isSingola) {
          idSupplementoCameraSingola = element.idSupplemento
        }
      });
    }

    if (idSupplementoCameraSingola) {
      let isCameraSingolaSelected = (this.formGroup.get(idSupplementoCameraSingola + '-' + numeroCamera).value === true) && idSupplementoCameraSingola !== currentSuppId;
      if (isCameraSingolaSelected) {
        this.supplementi.forEach((supplemento) => {
          if (supplemento.idSupplemento !== idSupplementoCameraSingola)
            this.formGroup.get(supplemento.idSupplemento + '-' + numeroCamera).setValue(false)
        });
      }
      return isCameraSingolaSelected

    } else {
      return false
    }

  }

  public isLettoOcuppato(idSupplemento, letto, numeroCamera) {
    let isLettoOccupato = false;
    this.supplementi.forEach((element) => {
      if ((this.formGroup.get(element.idSupplemento + '-' + numeroCamera).value === true) && element.letto === letto &&
        (element.idSupplemento + '-' + numeroCamera) !== (idSupplemento + '-' + numeroCamera)) {
        isLettoOccupato = true
      }
    });

    return isLettoOccupato
  }

  public isLettoPrecedenteLibero(idSupplemento, letto, numeroCamera) {
    let isLettoPrecedenteLibero = true;
    this.supplementi.forEach((element) => {
      if ((this.formGroup.get(element.idSupplemento + '-' + numeroCamera).value === true) && element.letto === (letto - 1) &&
        letto > 3 && (element.idSupplemento + '-' + numeroCamera) !== (idSupplemento + '-' + numeroCamera)) {
        isLettoPrecedenteLibero = false
      }
    });
    if (isLettoPrecedenteLibero && letto > 3) {
      this.formGroup.get(idSupplemento + '-' + numeroCamera).setValue(false)
    }


    return letto > 3 ? isLettoPrecedenteLibero : false

  }

  public disableCameraSingola(idSupplemento, letto, numeroCamera) {
    let idSupplementoCameraSingola = this.idCameraSingolaSelected != null ? this.idCameraSingolaSelected : null;
    if (this.idCameraSingolaSelected == null) {
      this.supplementi.forEach((element) => {
        if (element.isSingola) {
          idSupplementoCameraSingola = element.idSupplemento
        }
      });
    }

    let disableSingola = false;
    this.supplementi.forEach((element) => {
      if ((this.formGroup.get(element.idSupplemento + '-' + numeroCamera).value === true) && element.letto >= 2
        && idSupplementoCameraSingola === idSupplemento) {
        disableSingola = true
      }
    });


    return disableSingola

  }

  public verificaRicercaLive() {
    this.ricercaLiveValida = true;
    this.arrayCamere.forEach((indiceCamera, index) => {
      if (this.formGroup.get('bambiniCamera-' + indiceCamera).value > 0) {
        if (this.formGroup.get('etaBambino1Camera-' + indiceCamera).value === "-") {
          this.ricercaLiveValida = false;
          return;
        }
      }
      if (this.formGroup.get('bambiniCamera-' + indiceCamera).value > 1) {
        if (this.formGroup.get('etaBambino2Camera-' + indiceCamera).value === "-") {
          this.ricercaLiveValida = false;
          return;
        }
      }
      if (this.formGroup.get('bambiniCamera-' + indiceCamera).value > 2) {
        if (this.formGroup.get('etaBambino3Camera-' + indiceCamera).value === "-") {
          this.ricercaLiveValida = false;
          return;
        }
      }
    });
  }

  public verificaDisponibilita(giorni) {
    this.loading = true;
    this.verificaRicercaLive();

    if (!this.ricercaLiveValida) {
      this.formGroup.markAsTouched();
      this.formGroup.markAsDirty();
      this.loading = false;
    }
    else {
      this.secondaSettimanaLive = false;
      let dataParam = {
        "camereLive": [],
        "costoTotaleLive": this.costiLive.costoBaseLive,
        "costoTotaleLiveOriginale": this.costiLive.costoBaseLive,
        "idPartenza": this.pPageData.idPartenza,
        "luogoPartenza": this.pPageData.dettaglioPartenza.luogoNome,
        "isPartenzaPrime": this.pPageData.dettaglioPartenza.isPartenzaPrime,
        "notti": giorni,
        "isSecondaSettimana": this.formGroup.get('secondaSettimana').value
      };

      this.arrayCamere.forEach((indiceCamera, index) => {
        dataParam.camereLive.push(
          {
            "adulti": this.formGroup.get('adultiCamera-' + indiceCamera).value || 0,
            "bambini": this.formGroup.get('bambiniCamera-' + indiceCamera).value || 0,
            "progressivo": indiceCamera
          }
        );
        if (this.formGroup.get('bambiniCamera-' + indiceCamera).value > 0) {
          dataParam.camereLive[index]['etaBambini1'] = this.formGroup.get('etaBambino1Camera-' + indiceCamera).value || 1
        }
        if (this.formGroup.get('bambiniCamera-' + indiceCamera).value > 1) {
          dataParam.camereLive[index]['etaBambini2'] = this.formGroup.get('etaBambino2Camera-' + indiceCamera).value || 1
        }
        if (this.formGroup.get('bambiniCamera-' + indiceCamera).value > 2) {
          dataParam.camereLive[index]['etaBambini3'] = this.formGroup.get('etaBambino3Camera-' + indiceCamera).value || 1
        }
      });

      this.destSrv.getCamereLive(dataParam).then(
        (response) => {
          if (ResponseCheckerService.isSuccessResponse(response.body)) {
            this.listaCamereLive = response.body.data.listaCamerePartenzaLive;
            this.mostraPulsanteVerifica = false;
            if (giorni > 7 && this.listaCamereLive.length > 0) {
              this.secondaSettimanaLive = true;
            }
            setTimeout(() => {
              this.scrollToBottom();
            });
          } else {
            console.error(ResponseCheckerService.getErrorMessage(response.body));
          }

        },
        (error) => {
          console.error("Errore Verifica disponibilità: " + error);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )

      window.scrollTo(0,0);
    }
  }

  public generatePreventivoRq() {
    let totaleSupplementi = 0;
    let dataParam = {
      costiCamere: [],
      camereLive: [],
      idPartenza: this.pPageData.idPartenza,
      numCamere: this.arrayCamere.length,
      isSecondaSettimana: this.formGroup.get('secondaSettimana').value || this.secondaSettimanaLive,
      idCameraSingolaSelected: null
    };
    for (let i = 1; i <= this.arrayCamere.length; i++) {
      if (!this.pPageData.dettaglioPartenza.isHPartenzaLive) {
        dataParam.costiCamere.push({
          supplementiTipoCamera: [],
          idCamera: i,
          itemType: 2
        });

        this.supplementi.forEach((supplemento) => {
          if (this.formGroup.get(supplemento.idSupplemento + '-' + i).value) {
            if (this.isCameraSingola(i)){
              dataParam.idCameraSingolaSelected = supplemento.idSupplemento;
            }

            dataParam.costiCamere[i - 1].supplementiTipoCamera.push({
              "id": supplemento.idSupplemento,
              "descrizione": supplemento.nome,
              "isSingola": this.isCameraSingola(i),
              "costo": supplemento.totaleSupplemento
            });
            totaleSupplementi = totaleSupplementi + supplemento.totaleSupplemento
          }

        })

      } else {

        dataParam.camereLive.push(
          {
            "adulti": this.formGroup.get('adultiCamera-' + i).value || 0,
            "bambini": this.formGroup.get('bambiniCamera-' + i).value || 0,
            id: i,
            supplementiTipoCamera: [],
          }
        );
        if (this.formGroup.get('bambiniCamera-' + i).value > 0) {
          dataParam.camereLive[i - 1]['etaBambini1'] = this.formGroup.get('etaBambino1Camera-' + i).value || "0"
        }
        if (this.formGroup.get('bambiniCamera-' + i).value > 1) {
          dataParam.camereLive[i - 1]['etaBambini2'] = this.formGroup.get('etaBambino2Camera-' + i).value || 0
        }
        if (this.formGroup.get('bambiniCamera-' + i).value > 1) {
          dataParam.camereLive[i - 1]['etaBambini3'] = this.formGroup.get('etaBambino3Camera-' + i).value || 0
        }
        this.listaCamereLive[i - 1].listaSupplementiTipoCameraLive.forEach((supplemento) => {
          let formControlName = (supplemento.codiceCamera) + "-" + i + "-" + supplemento.costoCamera;
          if (this.formGroup.get(formControlName).value) {
            dataParam.camereLive[i - 1].supplementiTipoCamera.push(supplemento);
            totaleSupplementi = totaleSupplementi + supplemento.costoCamera
          }

        })


      }


    }
    let datiPreventivo = {
      "dataParam": dataParam,
      "totaleSupplementi": totaleSupplementi,
      "formGroup": this.formGroup
    };
    this.dialogRef.close(datiPreventivo);
  }


  public allowOnlyOneCheck(codiceCamera, costoCamera, number, event) {
    this.listaCamereLive[number - 1].listaSupplementiTipoCameraLive.forEach((supplemento) => {
      this.formGroup.get(supplemento.codiceCamera + '-' + number + '-' + supplemento.costoCamera).setValue(false)
    });
    this.formGroup.get(codiceCamera + '-' + number + '-' + costoCamera).setValue(event.checked)
  }

  public checkOccupazioneCamere() {
    if (this.pPageData.dettaglioPartenza.isHPartenzaLive) {
      let numeroStanze = this.formGroup.get("numeroStanze").value;
      let opSelezionate = 0;
      for (let i = 1; i <= numeroStanze; i++) {
        this.listaCamereLive[i - 1].listaSupplementiTipoCameraLive.forEach((supplemento) => {
          if (this.formGroup.get(supplemento.codiceCamera + '-' + i + '-' + supplemento.costoCamera).value) {
            opSelezionate++
          }
        });
      }
      return numeroStanze > opSelezionate

    } else {
      return false
    }


  }

  public getFormControlNameLive(codiceCamera, number, costoCamera) {
    let formControlName = codiceCamera + "-" + number + "-" + costoCamera;
    this.formGroup.addControl(formControlName, new FormControl('', Validators.compose([])));
    if (this.pPageData.tipoCameraDef && this.pPageData.tipoCameraDef === codiceCamera && this.isFristLoad) {
      this.formGroup.get(formControlName).setValue(true);
      this.isFristLoad = false;
    }
    return formControlName
  }

  public scrollToBottom(){
    let scrollOffset = this.elementRef.nativeElement.querySelector('mat-dialog-content').scrollHeight;
    this.elementRef.nativeElement.querySelector('mat-dialog-content').scrollTo(0, scrollOffset);
  }
}
