import {Injectable} from '@angular/core';
import {DateTimeService} from "./dateTime.service";
import {Observable, Subject} from "rxjs";

const MINUTES_CACHE_VALID = 2;

@Injectable()
export class CacheService {
  private mapCache: { key?: { data: any, created: any } };
  private cacheSubject: Subject<{ cacheKey: string, cacheData: any }>;
  private cacheObservable: Observable<{ cacheKey: string, cacheData: any }>;

  constructor() {
    this.mapCache = {};
    this.cacheSubject = new Subject<{ cacheKey: string, cacheData: any }>();
    this.cacheObservable = this.cacheSubject.asObservable();
  }

  public clearCache() {
    this.mapCache = {};
  }

  public getCacheData(paramkey: string) {
    let ret = null;
    if (this.mapCache[paramkey] && paramkey &&
      ((DateTimeService.getMinutesDifference(this.mapCache[paramkey].created, new Date()) <= (this.mapCache[paramkey].delay || MINUTES_CACHE_VALID)) ||
        !navigator.onLine || this.mapCache[paramkey].delay === 0)) {
      ret = Object.keys(this.mapCache[paramkey].data).length > 0 ? Object.assign({}, this.mapCache[paramkey].data) : false;
    }
    return ret;
  }

  public removeCacheData(paramkey: string) {
    if (this.mapCache[paramkey]) {
      delete this.mapCache[paramkey]
    }
  }

  public cacheObservabler(): Observable<{ cacheKey: string, cacheData: any }> {
    return this.cacheObservable;
  }

  public setCacheData(paramkey: string, paramdata: any, delayDestroyCache?: number) {
    if (paramkey) {
      this.mapCache[paramkey] = {data: paramdata, created: new Date(), delay: delayDestroyCache};
      this.cacheSubject.next({cacheKey: paramkey, cacheData: paramdata});
    }
  }
}
