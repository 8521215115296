import {Component, HostListener} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {ContattiModalComponent} from "../modal/contatti-modal/contatti-modal.component";
import {NoteLegaliModalComponent} from "../modal/note-legali-modal/note-legali-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'borsaviaggi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public isMobileDevice: boolean;
  public hideNavigationDesktop;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  constructor(private deviceService: DeviceDetectorService,
              public dialog: MatDialog) {
    this.isMobileDevice = this.deviceService.isMobile();
  }

  openDialogContatti(): void {
    const dialogRef = this.dialog.open(ContattiModalComponent, {
      width: '400px',
      data: { }
    });
  }

  openDialogNoteLegali(): void {
    const dialogRef = this.dialog.open(NoteLegaliModalComponent, {
      width: '450px',
      data: { }
    });
  }
}
