import { Component, Inject, OnInit } from '@angular/core';
import {ModalInterface} from "../../../interface/modal.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-note-legali-modal',
  templateUrl: './note-legali-modal.component.html',
  styleUrls: ['./note-legali-modal.component.scss']
})
export class NoteLegaliModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NoteLegaliModalComponent>,
              private route: Router,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<NoteLegaliModalComponent>) { }

  ngOnInit(): void {
  }

}
