import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsStringService} from "../../../services/common/utils-string.service";
import {LoaderService} from "../../../components/loader/loader.service";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {SEO_OFFERTE} from '../../../constants/seo.const';
import {FormGroup} from '@angular/forms';
import {DatiPersonaliValidator} from '../../../services/validator/dati-personali.validator';
import * as moment from "moment";
import {CheckoutValidator} from '../../../services/validator/checkout.validator';
import {RicercaCrociereService} from '../../../services/gate/ricerca-crociere.service';
import {UtilityService} from '../../../services/gate/utility.service';
import {AuthService} from '../../../services/common/auth.service';
import {ModalService} from '../../../services/common/modal.service';
import {ResponseCheckerService} from '../../../services/common/response.checker.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {NgxGalleryOptions} from '../../../components/gallery/ngx-gallery-options.model';
import {NgxGalleryImage} from '../../../components/gallery/ngx-gallery-image.model';
import {NavigationsUtilsService} from "../../../services/common/navigation.utils.service";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {BannerManagerService} from "../../../services/common/banner-manager.service";

@Component({
  selector: 'borsaviaggi-dettaglio-crociera-page',
  templateUrl: './dettaglio-crociera-page.component.html',
  styleUrls: ['./dettaglio-crociera-page.component.scss']
})
export class DettaglioCrocieraPage implements OnInit, OnDestroy {
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public utilsStr: UtilsStringService;
  public displayedColumns: string[] = [
    'codicePartenza',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'icons',
    'prime',
    'prezzo',
    'listaDesideri',
    'actions'
  ];
  public dataResponse;
  public urlRisultatiRicerca;
  public dataSource = new MatTableDataSource();
  private paginator: MatPaginator;
  public photoNumber: number;
  public photos: any;
  public listaPartenzeOfferta: any;
  public configObj: any;
  public dettaglioOfferta: any;
  public mock: boolean;
  public photosType: any;
  public isFavourite: any = false;
  public formGroupRichiestaInfo: FormGroup = DatiPersonaliValidator.formRichiestaInfoValidator;
  public validation_messages: any = DatiPersonaliValidator.formRichiestaInfoErrorMessage;
  public showPreventivo: boolean;
  public navigationSubscription;
  /** Preventivo Crociera */
  public formPreventivoCrociera: FormGroup = CheckoutValidator.formPreventivoCrociera;
  public listSesso: any;
  public items: any;
  public cabineDaPrenotare: any = null;
  public cabinePrenotabiliArr: any;
  public numBambiniArr: any;
  public numBambiniArrSelectedArr: Array<any> = [];
  public numAdultiArr: any;
  public etaAdultiArr: any;
  public etaBambiniArr: any;
  public listaComuni: Array<any>;
  public listaRegioni: Array<any>;
  public showFormComune: boolean;
  public showAltrePartenze: boolean;
  public showTabContent: boolean;
  public cabine: boolean;
  public showDettagliCrociera: boolean;
  // END OF: Preventivo Crociera
  public doShow: boolean = false;
  public savePreventivoRequestObj: any = null;
  public showDatiPreventivo: any;
  public prezzoTotalePostModifica: any;
  public cabineConPrezzoTotalePostModifica: any;
  public cabinaPrezzoMinimo;
  public isMobileDevice;
  public mobileStickyHeader;
  public altrePartenze;
  public openShare: boolean;
  public formGroupCamere;
  public preventivoRq;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.mobileStickyHeader = scrollOffset >= 100;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    public loaderSrv: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private crociereSrv: RicercaCrociereService,
    private utilitySrv: UtilityService,
    private deviceService: DeviceDetectorService,
    public destinazioneSrv: DestinazioneService,
    private authSrv: AuthService,
    private modalSrv: ModalService,
    private bannerSrv: BannerManagerService) {
    moment.locale('it');
    this.showTabContent = true;
    this.isMobileDevice = this.deviceService.isMobile();
    this.mock = false;

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      //if NavigationEnd event, re-initialize the component
      if (e instanceof NavigationEnd) {
        this.initComponent();
      }
    });
    if (NavigationsUtilsService.getLastState() && NavigationsUtilsService.getLastState().indexOf('/risultati-crociere?') !== -1) {
      this.urlRisultatiRicerca = NavigationsUtilsService.getLastState();
    }

    this.preventivoRq = {
      idCrociera: this.route.snapshot.data.listRisultati.body.data.idCrociera,
      numCabine: 1,
      numeroPasseggeri: 2,
      etaPasseggeri: [35,35],
      categoriaCabina: '',
      cabina: '',
      codiceTariffa: '',
      costoCabina: 0,
      nomeTariffa: ''
    };
  }

  public initComponent() {
    if (this.route && this.route.snapshot.data) {
      this.dettaglioOfferta = this.dataResponse = this.route.snapshot.data.listRisultati.body.data;
      this.getAltrePartenze();
      this.getMobileCabine();
      this.galleryImages = [];
      this.listaComuni = [];
      this.listaRegioni = [];
      this.photoNumber = 0;
      this.setGalleryOptions();
      let singolaFoto = [{'imgNave': ''}];
      singolaFoto[0].imgNave = this.dataResponse.nave.imgNave;
      this.dataResponse.fotoNave.length ? this.populateGallery(this.dataResponse.fotoNave) :
        this.populateGallery(singolaFoto);
      this.photos = this.dataResponse.fotoNave;
      this.cabine = this.dataResponse.cabine;
      this.utilsStr = UtilsStringService;

      if (this.dataResponse.promozioneAttiva) {
        this.bannerSrv.showHideBanner(false);
      }
    }
  }


  ngOnInit() {
    this.showDettagliCrociera = true;
    this.showFormComune = false;
    this.showAltrePartenze = false;
    this.showPreventivo = false;
    this.listSesso = this.getListSesso();
    this.cabineDaPrenotare = null;
    this.cabinePrenotabiliArr = this.getObjectsArray(1, 4);
    this.numAdultiArr = this.getObjectsArray(1, 4);
    this.numBambiniArr = this.getObjectsArray(0, 2);
    this.etaAdultiArr = this.getObjectsArray(18, 100);
    this.etaBambiniArr = this.getObjectsArray(0, 17);
    this.items = this.getObjectsArray(1, 4);
    this.getRegioni();
  }

  public getMobileCabine() {
    let prezzi = [];
    for (let i = 0; i < this.dataResponse.cabine.length; i++) {
      prezzi.push(this.dataResponse.cabine[i].prezzo);
    }
    this.cabinaPrezzoMinimo = Math.min(...prezzi);
  }


  private getRegioni() {
    this.utilitySrv.getRegioni().then(
      (response) => {
        if (response && response.body.data) {
          this.listaRegioni = response.body.data;
        } else {
          console.log("Errore reperimento regioni")
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }


  public getUrlAltrePartenze(data) {
    let res = '/crociera/' +
      this.parseUrlStr(this.dataResponse.nomeCompagnia) +
      '/' + this.parseUrlStr(this.dataResponse.titolo) +
      '/' + this.parseUrlStr(this.dataResponse.porto) +
      '/' + this.getDataPUrl(data.dataPartenza) +
      '/c/' + data.idCrociera;
    return res;
  }

  private setGalleryOptions() {
    this.galleryOptions = [{
      imageAutoPlay: true,
      imageAutoPlayPauseOnHover: true,
      previewAutoPlay: true,
      previewAutoPlayPauseOnHover: true,
      previewFullscreen: true,
      previewForceFullscreen: true,
      image: false,
      thumbnails: true,
      preview: true,
      width: '100%',
      height: '250px',
      imageInfinityMove: true
    },
      {"breakpoint": 425, "width": "100%", "thumbnailsColumns": 1, "thumbnailsRemainingCount": false, "image": false},
      {"breakpoint": 992, "width": "100%", "height": "200px", "thumbnailsColumns": 2},
      {"breakpoint": 1200, "width": "100%", "height": "200px", "thumbnailsColumns": 3}
    ]
  }

  private populateGallery(listaFoto) {
    listaFoto.map((currFoto) => {
      this.galleryImages.push({
        small: currFoto.imgNave,
        medium: currFoto.imgNave,
        big: currFoto.imgNave,
        description: currFoto.nave
      });
    });
  }

  public setTabContext(event) {
    this.photosType = event.tab.textLabel === 'Informazioni Volo' ? 'Destinazione' : event.tab.textLabel;
  }

  public getDataPUrl(paramDate) {
    return moment(paramDate).format('DD-MMMM');
  }

  public parseFullDate(paramDate) {
    return moment(paramDate).format('DD MMMM YYYY');
  }

  public parseUrlStr(str) {
    return str ? str.toString().trim().replace(/\s+/g, "-") : null;
  }

  public trimStr(str) {
    return str.toString().trim();
  }

  public getMsgVoloIncluso(flag) {
    return flag ? 'VOLO INCLUSO' : 'VOLO NON INCLUSO';
  }


  public getObjectsArray(first, last) {
    let arr = [];
    for (let i = first; i < last + 1; i++) {
      arr.push({
        value: i,
        viewValue: i + ''
      });
    }
    return arr;
  }

  public getListSesso() {
    return [{
      value: "M",
      label: "Uomo"
    },
      {
        value: "F",
        label: "Donna"
      }
    ]
  }

  public showDettagliCrocieraToggle() {
    this.showDettagliCrociera = !this.showDettagliCrociera;
  }

  public showAltrePartenzeToggle() {
    this.showAltrePartenze = !this.showAltrePartenze;
  }

  public showTabToggle() {
    this.showTabContent = !this.showTabContent;
  }

  public richiediInfo(valid) {
    if (valid) {
      let dataParam = {
        "email": this.formGroupRichiestaInfo.controls['email'].value,
        "cognome": this.formGroupRichiestaInfo.controls['cognome'].value,
        "nome": this.formGroupRichiestaInfo.controls['nome'].value,
        "cellulare": this.formGroupRichiestaInfo.controls['cellulare'].value,
      };
      this.destinazioneSrv.contattaci(dataParam).then(
        (response) => {
          this.modalSrv.openFeedbackModal({success: "Messaggio inviato con successo"})
        },
        (error) => {
          this.modalSrv.openErrorDialog();
        }
      )
    } else {
      for (let formControlName in this.formGroupRichiestaInfo.controls) {
        this.formGroupRichiestaInfo.get(formControlName).markAsTouched();
        this.formGroupRichiestaInfo.get(formControlName).markAsDirty();
      }
    }
  }


  public onClickRichiediPreventivoBtn() {
    this.openModificaPreventivoCrocieraModal();
  }

  public savePreventivoCrociera() {
    let request = this.savePreventivoRequestObj;
    this.crociereSrv.savePreventivoCrociere(request).then(
      (response) => {
        if (ResponseCheckerService.isSuccessResponse(response.body)) {
          this.goToCheckoutPageCrociere(response.body.data);
        } else {
          this.modalSrv.openErrorDialog(response.body.message);
          console.error(ResponseCheckerService.getErrorMessage(response.body));
        }
      }, (error) => {
        this.modalSrv.openErrorDialog(error);
        console.log('error savePreventivoCrociera');
      })
  }

  public savePreventivoCrocieraLive() {

    if (this.preventivoRq.cabina === '') {
      let paramData = this;
      this.modalSrv.openModificaOccupazioneCrocieraModal(paramData, '', (data) => {
        if (data && data.dataParam) {
          this.preventivoRq = data.dataParam;
          this.formGroupCamere = data.formGroup;
        }
      });
    }
    else {
      let request = this.preventivoRq;
      this.crociereSrv.savePreventivoCrocieraLive(request).then(
        (response) => {
          if (ResponseCheckerService.isSuccessResponse(response.body)) {
            this.goToCheckoutPageCrociere(response.body.data);
          } else {
            this.modalSrv.openErrorDialog(response.body.message);
            console.error(ResponseCheckerService.getErrorMessage(response.body));
          }
        }, (error) => {
          this.modalSrv.openErrorDialog(error);
          console.log('error savePreventivoCrocieraLive');
        });
    }
  }

  public openModificaPreventivoCrocieraModal() {
    let paramData = this;
    this.modalSrv.openModificaPreventivoCrocieraModal(paramData, '', (datiModal) => {
      if (datiModal) {
        this.savePreventivoRequestObj = datiModal.savePreventivoRequestObj;
        this.savePreventivoCrociera();
      }
    });
  }

  public goToCheckoutPageCrociere(data) {
    this.router.navigate([SEO_OFFERTE.CHECKOUT_CROCIERE_KEYWORD + '/' + data.idPreventivoOnLine]);
  }

  public getPrezzoBaseCabinaByCategoria(cabineOrig, cabinaObj) {
    let prezzoBase;
    cabineOrig.forEach((c) => {
      if (cabinaObj.idTipoCabina === c.idCategoria) {
        prezzoBase = c.prezzo
      }
    });
    return prezzoBase;
  }

  public getPrezzoCabina(cabinaObj) {
    let prezzoCabina;
    let prezzoBase = this.getPrezzoBaseCabinaByCategoria(this.dataResponse.cabine, cabinaObj);
    let moltiplicatore = cabinaObj.numAdulti + cabinaObj.numBambini;
    prezzoCabina = prezzoBase * moltiplicatore;
    return prezzoCabina;
  }

  private getAltrePartenze() {
    this.altrePartenze = this.dataResponse.altreDateCrociera.filter(
      (date) => {
        let formattedDate = moment(date.dataPartenza).format('DD MMMM YYYY')
        if (formattedDate != this.dataResponse.dataPartenza) {
          return date;
        }
      }
    )
  }

  public onClickShare(event) {
    if (this.isMobileDevice) {
      this.openShare = !this.openShare;
    }
  }

  public openModificaOccupazioneCrocieraModal() {
    let paramData = this;
    this.modalSrv.openModificaOccupazioneCrocieraModal(paramData, '', (data) => {
      if (data && data.dataParam) {
        this.preventivoRq = data.dataParam;
        this.formGroupCamere = data.formGroup;
      }
    });
  }

  ngOnDestroy() {
    /* router.events is global, unsubscribe handler on component destruction
       to avoid continuing to run initComponent() method on every navigationEnd event in the application */
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

}
