import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BannerManagerService {

  private isFuoriTuttoSubject: Subject<boolean>;
  private isFuoriTuttoObservable: Observable<boolean>;

  constructor() {
    this.isFuoriTuttoSubject = new Subject<boolean>();
    this.isFuoriTuttoObservable = this.isFuoriTuttoSubject.asObservable();
  }

  public fuoriTuttoVisibilityObservable() {
    return this.isFuoriTuttoObservable;
  }

  public showHideBanner(showParam: boolean) {
    this.isFuoriTuttoSubject.next(showParam);
  }
}
