import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {DestinazioneService} from "../destinazione.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioDestinazioneResolveService {
  constructor(private destSrv: DestinazioneService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let dataParam = {
      "idDestinazione": routeSnap.params.idDest,
      "idTo": routeSnap.params.idTo
    };

    return this.destSrv.getDestinazione(dataParam)
      .catch(err => {
        return err;
      });
  }

}
