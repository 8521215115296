import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Router} from "@angular/router";
import {AuthService} from "../../common/auth.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private router: Router;

  constructor(private injector: Injector) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    this.router = this.injector.get(Router);
    const authToken = this.authService.getUserToken();
    let paramRequest: HttpRequest<any> = req;
    if (authToken) {
      paramRequest = req.clone({
        headers: req.headers
          .set('token', authToken)
      });
    }
    return next.handle(paramRequest).pipe(map(
      (event: HttpEvent<any>) => {

        if (event instanceof HttpResponse) {
          return event;
        }
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
    /*        if (this.authService.getUser()) {
              let returnUrl = '/' + ROUTING_PATH_CONSTANTS.HOME;
              if (this.router.routerState.snapshot) {
                returnUrl = this.router.routerState.snapshot.url;
              }
              this.router.navigate(['/login', {sessionTimeout: true, returnUrl:  returnUrl}]);
            }*/
            this.authService.clearUser();
          }
          return observableThrowError(err);
        }
      }));
  }
}
