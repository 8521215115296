import {Injectable} from "@angular/core";
import {ModalService} from "./modal.service";

@Injectable()
export class CampagnaService {


  constructor(private modalSv: ModalService) {
  };

  public startManager = (dataParam) => {
    switch (dataParam["nomeCampagna"]) {
      case 'registrati':
        let dataParam = {
          banner: "/assets/img/REGISTRAZIONE-AREA.png"
        };
        this.modalSv.openRegistrazioneModal(dataParam);
        break;
    }
  };

}
