import {Injectable} from '@angular/core';
import {GenericGateService} from '../common/genericGate.service';
import {RequestMethodEnum} from './request.method.enum';

@Injectable()
export class DettaglioGService {

  constructor(private genericHttpService: GenericGateService) {
  }

  // public getPartenza(param): Promise<any> {
  //   return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/offerta/getTipologiaTopItems", param);
  // }

  public getOffertaByCode(param): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/motore/getOffertaByCode", param);
  }

}
