import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalInterface} from "../../../interface/modal.interface";
import {Location} from '@angular/common';

@Component({
  selector: 'borsaviaggi-partenza-non-disponibile',
  templateUrl: './partenza-non-disponibile.component.html',
  styleUrls: ['./partenza-non-disponibile.component.scss']
})
export class PartenzaNonDisponibileComponent implements OnInit {

  constructor(private location: Location,
              private dialogRef: MatDialogRef<PartenzaNonDisponibileComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<PartenzaNonDisponibileComponent>) {

  }

  ngOnInit(): void {
  }

  public closeModal() {
    this.location.back();
    this.dialogRef.close();
  }

}
