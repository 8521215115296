import {HammerGestureConfig} from "@angular/platform-browser";
import {Injectable} from "@angular/core";
import Hammer from '@egjs/hammerjs';
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element);

    mc.get('pinch').set({enable: false});
    mc.get('rotate').set({enable: false});
    mc.get('swipe').set({enable: true});

    for (const eventName in this.overrides) {
      mc.get(eventName).set(this.overrides[eventName]);
    }

    return mc;
  }
}
