import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  Inject, Input,
  OnDestroy
} from '@angular/core';
import {MatCalendar, MatMonthView} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/** Custom header component for datepicker. */

@Component({
  selector: 'custom-header',
  styles: [`
    .example-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
      justify-content: space-between;
    }

    .example-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }
  `],
  template: `
    <div class="example-header" #calendarHeader>
      <div class="previous-button">
        <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-icon-button [disabled]="disabled" class="close-button" (click)="previousClicked('month')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <div class="change-view-button">
        <button mat-button id="headerLabel" (click)="changeView('year')">
          {{label}}
        </button>
      </div>
      <div class="next-button">
        <button mat-icon-button [disabled]="disabled" class="close-button" (click)="nextClicked('month')">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
          <mat-icon>keyboard_arrow_right</mat-icon>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  public label: string;
  public disabled: boolean = false;


  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());

    this.label = this.periodLabel('month');

    // register event listener for the calendar
    this._calendar.monthSelected.subscribe((date) => {
      this.reset(date);
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  periodLabel(mode: 'month' | 'year') {
    this.disabled = this._calendar.currentView === 'year';

    return this._dateAdapter
      .format(this._calendar.activeDate, this._calendar.currentView === 'month' ? this._dateFormats.display.monthYearLabel : 'YYYY')
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);

    this.label = this.periodLabel(mode);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);

    this.label = this.periodLabel(mode);
  }

  changeView(mode: 'month' | 'year') {
    if(this._calendar.currentView === 'month') {
      this._calendar.currentView = mode;
      this.label = this.periodLabel(mode);
    }
  }

  reset(date) {
      this._calendar.currentView = 'month';
      this._calendar.activeDate = this._dateAdapter.createDate(this._dateAdapter.getYear(date), this._dateAdapter.getMonth(date), 1);
      this.label = this.periodLabel('month');
      this.disabled = false;
  }
}
