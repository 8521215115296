import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {LoginValidator} from "../../services/validator/login.validator";
import {User} from "../../model/user.dto";
import {UtilityService} from "../../services/gate/utility.service";
import {AuthService} from "../../services/common/auth.service";

@Component({
  selector: 'borsaviaggi-registrazione-form',
  templateUrl: './registrazione-form.component.html',
  styleUrls: ['./registrazione-form.component.scss']
})

export class RegistrazioneFormComponent implements OnInit {
  public formGroup: FormGroup = LoginValidator.formSignUpValidator;
  public validation_messages: any = LoginValidator.formSignUpErrorMessage;
  public loading: boolean = false;
  public listComuni = [];
  public listRegioni = [];
  public user: User;
  @Input() fuoriTuttoPage: boolean;


  constructor(private utilitySrv: UtilityService, private authSrv: AuthService) {
    this.formGroup.get('privacy').valueChanges.subscribe((val) => {
      if (val) {
        this.formGroup.get('riceviNewsletter').setValue(true);
      }
    });

    this.user = this.authSrv.getUser();
    this.authSrv.userObservable().subscribe(
      (dataUserLogged) => {
        if (dataUserLogged) {
          this.user = this.authSrv.getUser();
          this.setUser();
        } else {
          this.authSrv.clearUser();
          this.user = this.authSrv.getUser();
          this.setUser();
        }
      }
    );
  }

  ngOnInit() {
    this.formGroup.get('privacy').setValue(true);
    this.getRegioni();
    if (this.fuoriTuttoPage) {
      this.setUser();
      this.setDisabled();
    }
  }

  public setUser() {
    for (let fuoriTuttoForm in this.formGroup.controls) {
      this.user ? this.formGroup.get(fuoriTuttoForm).setValue(this.user.userInfo[fuoriTuttoForm]) :
        this.formGroup.get(fuoriTuttoForm).setValue('');
    }
  }

  public setDisabled() {
    for (let fuoriTuttoForm in this.formGroup.controls) {
      this.formGroup.get(fuoriTuttoForm).value ? this.formGroup.get(fuoriTuttoForm).disable() :
        this.formGroup.get(fuoriTuttoForm).enable();
    }
  }

  public changeRegioneEvent(event) {
    this.formGroup.get('idComune').setValue(null);
    this.listComuni = [];
    this.getComuni(event.value);
  }

  public getComuni(regione) {
    this.utilitySrv.getComuniByRegione({idRegione: regione}).then(
      (response) => {
        if (response && response.body.data) {
          this.listComuni = response.body.data
        } else {
          console.log("Errore reperimento comuni")
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  public getRegioni() {
    this.utilitySrv.getRegioni().then(
      (response) => {
        if (response && response.body.data) {
          this.listRegioni = response.body.data

        } else {
          console.log("Errore reperimento regioni")
        }
      },
      (error) => {
        console.log(error)

      }
    )
  }
}
