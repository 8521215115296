import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {UtilsStringService} from "../../../services/common/utils-string.service";
import {MatTableDataSource} from "@angular/material/table";
import {LoaderService} from "../../../components/loader/loader.service";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxGalleryOptions} from "../../../components/gallery/ngx-gallery-options.model";
import {NgxGalleryImage} from "../../../components/gallery/ngx-gallery-image.model";
import {SEO_OFFERTE} from "../../../constants/seo.const";
import {Subscription} from 'rxjs';
import {ImageUtilService} from "../../../services/common/image.util.service";
import * as moment from "moment";
import {Meta, Title} from "@angular/platform-browser";
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";

@Component({
  selector: 'borsaviaggi-dettaglio-vacanza-g-page',
  templateUrl: './dettaglio-vacanza-g-page.component.html',
  styleUrls: ['./dettaglio-vacanza-g-page.component.scss']
})
export class DettaglioVacanzaGPage implements OnInit {
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public utilsStr: UtilsStringService;
  public displayedColumns: string[] = [
    'codicePartenza',
    'dataPartenza',
    'giorniNotti',
    'trattamento',
    'icons',
    'prime',
    'prezzo',
    'listaDesideri',
    'actions'
  ];
  public dataResponse;
  public dataSource = new MatTableDataSource();
  public photoNumber: number;
  public photos: any;
  public listaPartenzeOfferta: any;
  public configObj: any;
  public listPartenzeOffertaWithUrls: any;
  public dettaglioOfferta: any;
  public selectedIdPartenza;
  public showStructureTab = false;
  public showItineraryTab = false;
  public subscription: Subscription;
  public isMobileDevice: boolean;
  public webPSupport;
  public tablePage;
  public tableTotalPages;
  public moreValues;
  public lastScrollOffset;
  public currentPage: number;
  public loadingValues;
  public routerData;
  private idLuogoPartenza;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let scrollElementScroll = document.querySelector('mat-drawer-container').scrollHeight - 1000;
    if (this.listPartenzeOffertaWithUrls.length < this.routerData.totalePartenze &&
      this.lastScrollOffset < scrollElementScroll
      && scrollOffset > scrollElementScroll
      && !this.loadingValues) {
      this.lastScrollOffset = scrollOffset;
      this.currentPage++;
      this.getTableData(this.currentPage);
    }
  }

  constructor(
    public loaderSrv: LoaderService,
    private router: Router,
    public route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private deviceService: DeviceDetectorService,
    private destinazioneSrv: DestinazioneService,
    private elementRef: ElementRef) {
    this.moreValues = false;
    this.tablePage = 0;
    this.webPSupport = ImageUtilService.supportWebP();
    this.currentPage = 1;
    this.lastScrollOffset = 0;
    this.loadingValues = false;
    this.idLuogoPartenza = this.route.snapshot.params.idLuogoPartenza;
    this.routerData = this.route.snapshot.data.datG.body.data;
    if (this.route.snapshot.data.datG.body && this.route.snapshot.data.datG.body.data) {
      this.dettaglioOfferta = this.dataResponse = this.route.snapshot.data.datG.body.data.dettaglioOfferta;

      this.listaPartenzeOfferta = this.route.snapshot.data.datG.body.data.listaPartenzeOfferta;
      this.tableTotalPages = Math.ceil(this.route.snapshot.data.datG.body.data.totalePartenze / 20);
      this.galleryImages = [];
      this.photoNumber = 0;
      this.setGalleryOptions();
      this.populateGallery(this.dettaglioOfferta.listaFotoStruttura || this.dettaglioOfferta.listaFotoOfferta);
      this.photos = this.dettaglioOfferta.listaFotoOfferta;
      this.utilsStr = UtilsStringService;
      this.getPartenzaDataTable();
      this.isMobileDevice = this.deviceService.isMobile();
    }

  }

  private makePartenzaUrl(p) {
    let url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    url += p.nazione + "/";
    url += p.destinazione + "/";
    url += UtilsStringService.removeSpecialChar(p.struttura) + "/";
    url += p.luogoPartenza + "/";
    url += moment(p.dataPartenza).locale("it").format("DD-MMMM") + "/";
    url += "p" + "/";
    url += p.idPartenza + '';
    return url.toLowerCase().replace(/\s/g, "-");
  }

  ngOnInit() {
    if (this.dettaglioOfferta) {
      this.titleService.setTitle(this.dettaglioOfferta.titoloOfferta);

      this.metaTagService.updateTag(
        {property: 'og:title', content: this.dettaglioOfferta.titoloOfferta},
      );
      this.metaTagService.updateTag(
        {name: 'title', content: this.dettaglioOfferta.titoloOfferta},
      );
      let urlPhoto = window.location.origin + "/assets/img/logo-borsaviaggi.png";
      if (this.dettaglioOfferta.listaFotoStruttura && this.dettaglioOfferta.listaFotoStruttura.length > 0) {
        urlPhoto = <string>this.dettaglioOfferta.listaFotoStruttura[0].urlFoto
      }
      this.metaTagService.updateTag(
        {property: 'og:image', content: urlPhoto}
      );
      this.metaTagService.updateTag(
        {property: 'og:description', content: this.dettaglioOfferta.descrizioneOfferta},
      );
      this.metaTagService.updateTag(
        {name: 'description', content: this.dettaglioOfferta.descrizioneOfferta},
      );
    }
    if (this.dettaglioOfferta.descrizioneStruttura != null) {
      this.showStructureTab = true;
    }
    if (this.dettaglioOfferta.itinerario != null) {
      this.showItineraryTab = true;
    }
    if (this.route.snapshot.paramMap.get("idLuogoPartenza")) {
      this.selectedIdPartenza = this.route.snapshot.paramMap.get("idLuogoPartenza");
    } else {
      this.selectedIdPartenza = this.dataResponse.listaLuoghiPartenza[0].idLuogoPartenza;
    }
  }

  public changePartenzaEvent(data) {
    this.tablePage = 0;
    this.currentPage = 1;
    this.selectedIdPartenza = data;
    this.getPartenze(data);
    this.idLuogoPartenza = data;
    let idPath = this.route.snapshot.url.length > 6 ? 6 : 5;
    if (data.idLuogoPartenza == this.route.snapshot.url[idPath].path) {
      return 'true';
    }
  }

  public getPartenze(partenza) {
    this.loaderSrv.show();
    let dataParam = {
      "idOfferta": this.route.snapshot.paramMap.get("idG"),
      "idLuogoPartenza": partenza,
      "pageNumber": 1,
      "pageSize": 20
    };

    this.destinazioneSrv.getG(dataParam).then(
      (response) => {
        if (response && response.body && response.body.data) {
          this.lastScrollOffset = 0;
          this.routerData.totalePartenze = response.body.data.totalePartenze;
          this.tableTotalPages = Math.ceil(this.routerData.totalePartenze / 20);
          this.populateGallery(response.body.data.dettaglioOfferta.listaFotoStruttura || response.body.data.dettaglioOfferta.listaFotoOfferta);
          this.loaderSrv.hide();
          this.listaPartenzeOfferta = response.body.data.listaPartenzeOfferta;
          this.getPartenzaDataTable();
        } else {
          console.error("Nessuna partenza trovata");
          this.loaderSrv.hide();
        }
      },
      (errror) => {
        console.error("Errore in caricamento partenze", errror);
        this.loaderSrv.hide();
      }
    )
  }

  private getPartenzaDataTable() {
    this.listPartenzeOffertaWithUrls = [];
    this.listaPartenzeOfferta.forEach((partenza) => {
      let partenzaObj = {
        nazione: this.dettaglioOfferta.nazione,
        destinazione: this.dettaglioOfferta.destinazione,
        struttura: partenza.struttura,
        luogoPartenza: partenza.luogoPartenza,
        dataPartenza: partenza.dataPartenza,
        idPartenza: partenza.idPartenza,
      };
      partenza.titleHref = partenzaObj.nazione + " " + partenzaObj.destinazione + " " + moment(partenzaObj.dataPartenza).format("DD-MM-YYYY") + " " + partenzaObj.struttura;
      partenza.url = this.makePartenzaUrl(partenzaObj);

      this.listPartenzeOffertaWithUrls.push(partenza);
    });
    let isMezzoProprioLive = this.listPartenzeOffertaWithUrls.every(currP => currP.isMezzoProprioLive);
    let noPrime = this.listPartenzeOffertaWithUrls.every(currP => !currP.isPartenzaPrime);
    if (isMezzoProprioLive) {
      this.displayedColumns.map((currHader, index) => {
        if (currHader === "prezzo") {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
    else {
      let prezzoExist = this.displayedColumns.find(x=> x === "prezzo");
      if (!prezzoExist) {
        this.displayedColumns.splice(6,0,"prezzo");
      }
    }

    if (noPrime) {
      this.displayedColumns.map((currHader, index) => {
        if (currHader === "prime") {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
    else {
      let primeExist = this.displayedColumns.find(x=> x === "prime");
      if (!primeExist) {
        this.displayedColumns.splice(5,0,"prime");
      }
    }
    this.dataSource = new MatTableDataSource(this.listPartenzeOffertaWithUrls);
  }


  private setGalleryOptions() {
    this.galleryOptions = [
      {
        "imageAutoPlay": true,
        "previewCloseOnEsc": true,
        "previewZoom": true,
        "imageAutoPlayPauseOnHover": true,
        "previewAutoPlay": true,
        "previewAutoPlayPauseOnHover": true,
        "previewFullscreen": true,
        "previewForceFullscreen": true,
        "image": false,
        thumbnails: true,
        preview: true,
        width: '100%',
        height: '250px',
        imageInfinityMove: true

      },
      {
        "breakpoint": 768,
        "width": "100%",
        "thumbnailsColumns": 1,
        imageSwipe: true,
        thumbnailsSwipe: true,
        previewCloseOnClick: true,
        "thumbnailsRemainingCount": false,
        "image": false
      },
      {"breakpoint": 1200, "width": "100%", "height": "200px", previewCloseOnClick: true, "thumbnailsColumns": 3},
    ]
  }

  private populateGallery(listaFoto) {
    this.galleryImages = [];
    listaFoto.map((currFoto) => {
      this.galleryImages.push(
        {
          small: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
          medium: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
          big: ImageUtilService.supportWebP() ? currFoto.urlFotoWebp : currFoto.urlFoto,
          description: currFoto.titolo
        }
      );
    });
  }

  public getTabFoto(tabIndex) {
    let fotoStruttura = tabIndex == 1 ? this.dettaglioOfferta.listaFotoOfferta : this.dettaglioOfferta.listaFotoStruttura || this.dettaglioOfferta.listaFotoOfferta;
    this.populateGallery(fotoStruttura);
  }

  public getTableData(n) {

    if (n <= this.tableTotalPages) {
      this.loadingValues = true;
      this.moreValues = true;
      let dataParam = {
        "idLuogoPartenza": this.idLuogoPartenza,
        "idOfferta": this.route.snapshot.params.idG,
        "pageNumber": n,
        "pageSize": 20
      };

      this.destinazioneSrv.getG(dataParam).then(
        (result) => {
          this.listaPartenzeOfferta = this.listaPartenzeOfferta.concat(result.body.data.listaPartenzeOfferta);
          this.getPartenzaDataTable();
          this.moreValues = false;
        }
      ).finally(
        () => {
          this.loadingValues = false;
        }
      );
      this.tablePage++;
    }
  }

  public tableScroll(event?: Event) {
    console.log('event');
    console.log(event);
    let tableScroll = (event.target as Element).scrollTop;
    console.log(tableScroll);
    let tableHeight = this.elementRef.nativeElement.querySelector('.bv-table').offsetHeight;
    let cellHeight = this.elementRef.nativeElement.querySelector('td').offsetHeight;
    if (tableScroll > tableHeight + (cellHeight * 15 * this.tablePage)) {
      this.getTableData(2 + this.tablePage);
    }
  }
}
