import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalInterface} from "../../../interface/modal.interface";
import {Router} from "@angular/router";
import {CacheService} from "../../../services/common/cache.service";
import {ResponseCheckerService} from "../../../services/common/response.checker.service";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {DeviceDetectorService} from "ngx-device-detector";

const DEFAULT_MAX_DESTINAZIONI = 2;
const DEFAULT_MAX_DESTINAZIONI_ITALIA = 3;

@Component({
  templateUrl: './destinazioni-modal.component.html',
  styleUrls: ['./destinazioni-modal.component.scss']
})
export class DestinazioniModalComponent implements OnInit, OnDestroy {
  public destinazioniData;
  public modalItalia: boolean;
  public listDestinazioni;
  public listDestinazioniMareItalia;
  public currentTabIndex;
  public isMobile: boolean;

  constructor(public dialogRef: MatDialogRef<DestinazioniModalComponent>,
              private cacheSrv: CacheService,
              private deviceService: DeviceDetectorService,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<DestinazioniModalComponent>,
              private router: Router,
              private destinationSrv: DestinazioneService) {
    this.listDestinazioni = [];
    this.currentTabIndex = 0;
    if (Object.keys(data).length) {
      this.destinazioniData = data;
      this.modalItalia = this.destinazioniData.dataParam.tabItalia;

      if (this.modalItalia) {
        this.currentTabIndex = 1;
      }

      this.destinazioniData.dataParam.promiseDest.then(
        (resp) => {
          if (ResponseCheckerService.isSuccessResponse(resp.body)) {
            this.listDestinazioni = this.makeDestList(resp.body.data, this.destinazioniData.dataParam.destSelected);
          }
        }
      );
    }

    this.destinationSrv.getDestinazioniMareItalia().then(
      (response) => {
        if (response && response.body && response.body.data) {
          this.listDestinazioniMareItalia = this.makeDestList(response.body.data, this.destinazioniData.dataParam.destSelected);
        }
      },
      (errror) => {
        console.error("Errore in caricamento destinazioni mare", errror);
      }
    );
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {

  }

  private makeDestList(arrRespDest, paramDestSelected) {
    let ret = arrRespDest;
    if (paramDestSelected) {
      ret.map(
        (currCategoria) => {
          currCategoria.destinazioni.map(
            (currDest) => {
              if (paramDestSelected.find(currSelectDest => parseInt(currSelectDest.idDestinazione) === currDest.idDestinazione)) {
                currDest.checked = true;
              } else if (paramDestSelected.length === 2) {
                currDest.disabled = true;
              }
            }
          );
        }
      );
    }
    return ret;
  }

  public actionConfermaDestinazioni() {
    let destSel = [];
    let currentList = this.currentTabIndex === 0 ? this.listDestinazioni : this.listDestinazioniMareItalia;
    currentList.map(
      (macroDestItem) => {
        macroDestItem.destinazioni.map(item => {
            if (item.checked) {
              destSel.push(item);
            }
          }
        );
      });

    this.dialogRef.close({action: 1, dataChip: destSel, list: "destinazione"});
  }

  public actionCheckUnCheck(isModalItalia) {
    let totChecked = [];

    let maxDestinazioni = DEFAULT_MAX_DESTINAZIONI;
    if (Boolean(isModalItalia)) {
      maxDestinazioni = DEFAULT_MAX_DESTINAZIONI_ITALIA;
    }

    let currentList = !Boolean(isModalItalia) ? this.listDestinazioni : this.listDestinazioniMareItalia;
    currentList.map(
      (currDest) => {
        let checkedItem = currDest.destinazioni.filter(currLoc => currLoc.checked);
        checkedItem.map(item => totChecked.push(item));
      }
    );
    if ((!this.deviceService.isMobile() && totChecked.length >= maxDestinazioni)) {
      currentList.map(
        (currDest) => {
          currDest.destinazioni.map(
            (currLoc) => {
              if (!currLoc.checked) {
                currLoc.disabled = true;
              }
            }
          );
        }
      );
    } else {
      currentList.map(
        (currDest) => {
          currDest.destinazioni.map((currLoc) => {
              currLoc.disabled = false;
            }
          );
        }
      );
    }
  }

  public disabledConferma() {
    let currentList = this.currentTabIndex === 0 ? this.listDestinazioni : this.listDestinazioniMareItalia;
    return currentList ? currentList.every(
      (macroDestItem) => {
        return macroDestItem.destinazioni.every(item => !item.checked)
      }) : true;
  }

  public tabChange(isModalItalia) {

  }

  ngOnDestroy(): void {
    this.listDestinazioni.map(
      (macroDestItem) => {
        return macroDestItem.destinazioni.map(item => {
          item.disabled = false;
          item.checked = false;
        })
      });
    this.listDestinazioniMareItalia.map(
      (macroDestItem) => {
        return macroDestItem.destinazioni.map(item => {
          item.disabled = false;
          item.checked = false;
        })
      });
  }

}
