<div class="background" *ngIf="dataResponse">
  <div class="breadcrumb-container">
    <div class="container bg-white">
      <div class="row no-gutters breadcrumb-row">
        <div class="col-4 breadcrumb first"
             [ngClass]="{'selected': checkoutStep === 'riepilogo'}">
          <div class="title">Riepilogo / Personalizza</div>
          <div class="description">Personalizza il tuo viaggio con servizi aggiuntivi</div>
          <div class="arrow outline"
               [ngClass]="{'selected': checkoutStep === 'riepilogo'}"></div>
          <div class="arrow" [ngClass]="{'selected': checkoutStep === 'riepilogo'}">
          </div>
        </div>
        <div class="col-4 breadcrumb indented"
             [ngClass]="{'selected': checkoutStep === 'prenota'}">
          <div class="title">Prenota</div>
          <div class="description">Prenota il tuo viaggio</div>
          <div class="arrow outline"
               [ngClass]="{'selected': checkoutStep === 'prenota'}"></div>
          <div class="arrow" [ngClass]="{'selected': checkoutStep === 'prenota'}">
          </div>
        </div>
        <div class="col-4 breadcrumb indented"
             [ngClass]="{'selected': checkoutStep === 'completato'}">
          <div class="title">Ordine completato</div>
          <div class="description">La tua vacanza ti aspetta</div>
        </div>
      </div>
    </div>
  </div>

  <div class="container bg-white d-block d-md-none" [hidden]="checkoutStep === 'completato'" *ngIf="isDeviceMobile">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-xl-4">

        <div class="top-box">
          <mat-accordion *ngIf="isDeviceMobile"
                         class="mobile-fixed"
                         [ngClass]="{'top-0-imp':mobileStickyHeader}">
            <mat-expansion-panel class="background-sconto background-blue-borsaviaggi scroll-on-mobile"
                                 (opened)="toggleAccoridionRiepilogo(true)" (closed)="toggleAccoridionRiepilogo(false)">
              <mat-expansion-panel-header class="h-60px">
                <mat-panel-title class="d-flex justify-content-center align-items-center">
                  <div class="fs-1-6rem bold color-white">Riepilogo dei costi:</div>
                  <div class="fs-2_4rem bold color-text-orange-borsaviaggi ml-10px">
                    <span>{{costoTotale | euroFormat}}</span>
                    <span class="fs-1-6rem ml-2px">€</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="bold fs-1-6rem color-white text-align-center mt-1rem">Sistemazione:</div>
              <div class="top-box">
                <div class="top-section-wrapper">
                  <div class="col-12">
                    <ul class="sistemazioni-list">
                      <li *ngFor="let camera of camere" class="list-elements">
                        <p class="key fs-1_1rem mb-8px">Camera&nbsp;{{camera.numCamera}} -
                          <span *ngIf="camera.ospiti" class="value">{{camera.ospiti.length}}
                            <span *ngIf="camera.ospiti.length === 1">persona</span>
                            <span *ngIf="camera.ospiti.length > 1">persone</span>
                          </span>
                        </p>
                        <p *ngIf="camera.info">
                          <span *ngFor="let item of camera.info.Supplementi; let i = index">
                              <span *ngIf="i == 0">( </span><span>{{item}}</span>
                              <span *ngIf="i != camera.info.Supplementi.length - 1">
                                <br *ngIf="!dataResponse.dettaglioPartenza.isHPartenzaLive">
                                <span *ngIf="dataResponse.dettaglioPartenza.isHPartenzaLive"> + </span>
                              </span>
                              <span *ngIf="i == camera.info.Supplementi.length - 1">  )</span>
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content row no-gutters">
                    <div class="detail col-12">
                      <div class="key">Pacchetto Vacanza</div>
                      <div class="value">{{dataCheckout.pacchettoVacanza|euroFormat}}€</div>
                      <mat-icon [matTooltip]="infoTooltip" matTooltipClass="info-tooltip"
                                class="info-icon cursor-pointer">
                        info_outline
                      </mat-icon>
                    </div>
                    <div class="detail col-12"
                         *ngIf="showPrezzoTotaleConPrime && (!userData || (userData && !userData.userInfo.isPrime))">
                      <div class="key">Abbonamento <span class="prime-text">PRIME</span></div>
                      <div class="value">+{{dataCheckout.prezzoAbbonamentoPrime}}€</div>
                    </div>
                    <div class="detail col-12"
                         *ngIf="showPrezzoTotaleConPrime && dataPreventivo.isPartenzaPrime && dataCheckout.risparmioVantaggiPrime> 0 ">
                      <div class="key">Risparmio Vantaggi <span class="prime-text">PRIME</span></div>
                      <div class="value">-{{dataCheckout.risparmioVantaggiPrime}}€</div>
                    </div>
                    <div class="detail col-12" *ngIf="sconto">
                      <div class="key">Buono sconto</div>
                      <div class="value">-{{sconto}}€</div>
                    </div>
                    <div class="detail col-12" *ngIf="scontoFlash && showPrezzoTotaleConPrime">
                      <div class="key">Sconto FLASH</div>
                      <div class="value">-{{scontoFlash}}€</div>
                    </div>
                    <div class="detail col-12" *ngIf="showPrezzoTotaleConAssicurazione">
                      <div class="key">Annullamento <span class="success-color">SERVICE+</span></div>
                      <div class="value ">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="dataPreventivo && !isPartenzaLive && dataPreventivo.isSecondaSettimana"
                       class="col-12 text-align-center bold fs-1_2rem">Richiesta seconda settimana
                  </div>
                </div>
              </div>
              <div class="tab-wrapper">
                <div class="tab">
                  <div class="tab-content row no-gutters">
                    <h3 class="text-align-center color-white riepilogo full-width">
                      Riepilogo Partenza
                    </h3>
                    <div class="detail color-white col-12">
                      <div class="key">Codice Partenza</div>
                      <div class="value">{{dataCheckout.codicePartenza}}</div>
                    </div>
                    <div class="detail color-white col-12">
                      <div class="key">Destinazione</div>
                      <div class="value">{{dataCheckout.nazione + " - " + dataCheckout.destinazione | titlecase}}</div>
                    </div>
                    <div class="detail color-white col-12">
                      <div class="key">Struttura</div>
                      <div class="value">{{dataCheckout.struttura| titlecase}}</div>
                    </div>
                    <div class="detail color-white col-12">
                      <div class="key">Da</div>
                      <div class="value">{{dataCheckout.luogoPartenza| titlecase}}</div>
                    </div>
                    <div class="detail color-white col-12">
                      <div class="key">Partenza il</div>
                      <div class="value">{{dataCheckout.dataPartenza}}</div>
                    </div>
                    <div class="detail color-white col-12"
                         [hidden]="isPartenzaLive && dataPreventivo.isSecondaSettimana">
                      <div class="key">Rientro il</div>
                      <div class="value">{{dataCheckout.dataRientro}}</div>
                    </div>
                    <div class="detail color-white col-12">
                      <div class="key">Soggiorno</div>
                      <div class="value">{{
                        isPartenzaLive && dataPreventivo.isSecondaSettimana ? 14 + ' Notti' : dataCheckout.soggiorno| titlecase}}</div>
                    </div>
                    <div class="detail color-white col-12">
                      <div class="key">Trattamento</div>
                      <div class="value">{{dataCheckout.trattamento| titlecase}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="tab-wrapper"
               *ngIf="!isDeviceMobile">
            <div class="tab">
              <div class="tab-content row no-gutters">
                <h3 class="title text-align-center color-text-blue-borsaviaggi full-width">
                  Riepilogo Partenza
                </h3>
                <div class="detail col-12">
                  <div class="key">Codice Partenza</div>
                  <div class="value">{{dataCheckout.codicePartenza}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Destinazione</div>
                  <div class="value">{{dataCheckout.nazione + " - " + dataCheckout.destinazione | titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Struttura</div>
                  <div class="value">{{dataCheckout.struttura| titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Da</div>
                  <div class="value">{{dataCheckout.luogoPartenza| titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Partenza il</div>
                  <div class="value">{{dataCheckout.dataPartenza}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Rientro il</div>
                  <div class="value">{{dataCheckout.dataRientro}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Soggiorno</div>
                  <div class="value">{{dataCheckout.soggiorno| titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Trattamento</div>
                  <div class="value">{{dataCheckout.trattamento| titlecase}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="container bg-white">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-8 col-xl-8">
        <!-- STEP 1 - RIEPILOGO / PERSONALIZZA -->
        <div *ngIf="checkoutStep === 'riepilogo'" class="checkout-card">
          <!--<div class="no-gutters">
            <h2 class="big-title color-text-blue-borsaviaggi">
              Personalizza il tuo viaggio
            </h2>
          </div>-->
          <ng-container *ngIf="buoniSconto && buoniSconto.length>0">
            <div
              class="col-6 utilizzo-cashback d-flex justify-content-center align-items-center white title">
              Hai a disposizione il cashback. Utilizzalo!
            </div>
            <div class="row no-gutters">
              <div class="col-6">
                <div class="arrow-container">
                  <div class="arrow-down"></div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="w-100 div-promo text-align-center" *ngIf="promozioneAttiva && isDeviceMobile">
            OFFERTA IN FUORI TUTTO<br>
            <div class="timer">
              <!-- span id="days" *ngIf="daysToDday > 0"> {{daysToDday}} </span>Giorni-->
              HAI ANCORA
              <span id="hoursM" *ngIf="hoursToDday > 0"> {{hoursToDday}} </span><span class="fs-12px"
                                                                                      *ngIf="hoursToDday > 0">Ore</span>
              <span id="minutesM"> {{minutesToDday}} </span><span class="fs-12px">Minuti</span>
              <span id="secondsM"> {{secondsToDday}} </span><span class="fs-12px">Secondi</span>
            </div>
          </div>

          <form [formGroup]="formGroupPrenotazione">
            <div class="description-container">
              <div class="background-white text-align-left "
                   [ngClass]="{'mt-50px':isDeviceMobile,'mb-30px':!isDeviceMobile}">
                <a [routerLink]="urlPartenza">
                  <button class="prenota" mat-raised-button>
                    <mat-icon matPrefix>chevron_left</mat-icon>
                    Torna alla Partenza
                  </button>
                </a>
              </div>
              <div class="mt-20px main-title-container main-sconto col-12">
                <div class="title pl-1rem">
                  <span *ngIf="!scontoFlash">Hai un codice sconto?</span>
                  <span *ngIf="scontoFlash && showPrezzoTotaleConPrime">Buono sconto FLASH</span>
                </div>
              </div>
              <div *ngIf="!scontoFlash ||  !showPrezzoTotaleConPrime" class="info-container row no-gutters">
                <div class="dettagli display-flex col-12">
                  <div class="d-flex align-items-center pr-1rem" *ngIf="!isDeviceMobile">
                    <img class="buono-sconto" src="../../../assets/img/icons/img-buonoSconto.png" alt="buono sconto">
                  </div>
                  <div class="col overflow-hidden">
                    <div class="text">
                      Inserisci il codice e verifica se è applicabile all'offerta!
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-8 col-md-8 col-lg-8">
                        <div class="row-mobile">
                          <div class="d-flex align-items-center" *ngIf="isDeviceMobile">
                            <img class="buono-sconto-mobile" src="../../../assets/img/icons/img-buonoSconto.png"
                                 alt="buono sconto">
                          </div>
                          <mat-form-field class="sconto-field" name="select" appearance="outline">
                            <mat-label>Buono sconto</mat-label>
                            <input [disabled]="sconto > 0" matInput id="buonoSconto" placeholder="Buono sconto"
                                   type="text" formControlName="buonoSconto" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option *ngFor="let buono of filteredOptions | async" [value]="buono.Codice">
                                {{buono.Causale}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                        <div class="text mt-0" *ngIf="!userData">
                          Sei <span class="fs-1_1rem fw-600 prime-red">PRIME</span>? Effettua ora il
                          <span class="ml-5px color-text-orange-borsaviaggi text-login cursor-pointer"
                                (click)="actionLogin()">Login</span>
                          e verifica se hai sconti attivi!
                        </div>
                      </div>

                      <div class="col-12 col-sm-4 col-md-4 col-lg-4 text-align-center">
                        <div class="button-style-side" (click)="applicaSconto()">Applica</div>
                      </div>
                    </div>

                    <div *ngIf="erroreSconto" class="text mb-1rem red">
                      <b>{{erroreSconto}}</b>
                    </div>
                    <div *ngIf="sconto" class="text mb-1rem">
                      Sconto: <b>{{sconto}}€</b>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="scontoFlash && showPrezzoTotaleConPrime" class="info-container row no-gutters">
                <div class="dettagli col-12">
                  <div class="title">
                    Buono sconto di&nbsp;<span class="red bold">{{scontoFlash}}€</span>&nbsp;aggiunto correttamente!
                  </div>
                </div>
              </div>
            </div>

            <div class="big-title bold text-align-center color-text-blue-borsaviaggi mt-2rem mb-2rem">
              Personalizza il servizio
            </div>

            <div class="description-container">
              <div class="main-title-container description-services col-12"
                   *ngIf="!userData || userData && (userData && !userData.userInfo.isPrime)">
                <div class="flex-auto" *ngIf="isDeviceMobile">
                  <img class="prime-mobile" src="../../../assets/img/icons/Prime-Banner-quadrato.png"
                       alt="prime mobile">
                </div>
                <div class="title">
                  <div class="checkbox-wrapper" *ngIf="!isDeviceMobile && dataPreventivo">
                    <mat-checkbox (change)="onChangeCheckboxPrime($event)"
                                  formControlName="checkBoxPrime"
                    ></mat-checkbox>
                  </div>
                  Aggiungi <span class="prime-text">PRIME</span>
                </div>
                <div class="codice-p" *ngIf="dataPreventivo">+ {{dataCheckout.prezzoAbbonamentoPrime}} €</div>
                <div class="pt-1rem pb-1rem float-right" *ngIf="isDeviceMobile">
                  <mat-checkbox (change)="onChangeCheckboxPrime($event)"
                                formControlName="checkBoxPrime"
                  ></mat-checkbox>
                </div>
              </div>
              <mat-accordion class="prime"
                             *ngIf="isDeviceMobile">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="d-flex justify-content-center">
                      <div class="fs-1_2rem bold">{{(userData && userData.userInfo && userData.userInfo.isPrime) ?
                        'Per te che sei un utente ' : 'Vantaggi esclusivi '}}
                        <span class="prime-text fs-1_2rem bold">PRIME</span></div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngIf="!dataResponse.dettaglioPartenza.isPartenzaPrime"
                       class="text l-height">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Assistenza.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold fs-1_1rem">Assistenza in Viaggio 7/7</span>
                            <br/><span class="fs-1rem">Un Assistente online 7 giorni su 7 che ti segue in vacanza, dalla partenza al rientro</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold fs-1_1rem">Very Important Traveller</span>
                            <br/><span class="fs-1rem">{{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                            'Sei ricunosciuto con priorità dal tuo Consulente di fiducia' : 'Sarai riconosciuto con priorità dal tuo Consulente di fiducia'}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Preventivi.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold fs-1_1rem">Preventivi Sartoriali</span>
                            <br/><span class="fs-1rem">
                            {{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                            'Puoi richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura' : 'Potrai richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura'}}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/icona-cashback-gialla.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold fs-1_1rem">Fedeltà che ti Premia</span>
                            <br/><span class="fs-1rem">Prenotando questa Offerta per te un Buono di <span
                            class="red bold">{{destSrv.getImportoBuonoSconto(dataPreventivo.totale)}}€</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END OF: Se non hai PRIME -->

                  <!-- Se hai PRIME -->
                  <div
                    *ngIf="dataResponse.dettaglioPartenza.isPartenzaPrime"
                    class="text">
                    <div *ngIf="dataResponse.serviziPrime" class="row">
                      <div *ngFor="let vantaggi of dataResponse.serviziPrime" class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" [src]="vantaggi.logo" alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span
                              class="bold"> Risparmi {{vantaggi | calcolaImportoVantaggioPrime:camere:dataCheckout.prezzoTotaleConPrime}}
                              €</span>
                            <div class="benefit-descr fs-12px">{{vantaggi.descrizione}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/icona-cashback.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold">Fedeltà che ti Premia</span>
                            <br/><span class="fs-12px">Prenotando questa Offerta per te un Buono di <span
                            class="red bold">{{destSrv.getImportoBuonoSconto(dataPreventivo.totale)}}€</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="info-container row no-gutters">
                <div class="col-12" *ngIf="!isDeviceMobile">
                  <img class="banner" src="../../../assets/img/icons/Banner-Prime-Partenza.png" alt="banner prime">
                </div>
                <div class="dettagli d-none d-sm-block col-12">
                  <div class="title">{{(userData && userData.userInfo && userData.userInfo.isPrime) ?
                    'Per te che sei un utente ' : 'Vantaggi esclusivi '}}
                    <span class="prime-text">PRIME</span></div>
                  <!-- Se non hai PRIME -->
                  <div
                    *ngIf="!dataResponse.dettaglioPartenza.isPartenzaPrime"
                    class="text">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Assistenza.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold">Assistenza in Viaggio 7/7</span>
                            <br/><span>Un Assistente online 7 giorni su 7 che ti segue in vacanza, dalla partenza al rientro</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Vit.png" alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold">Very Important Traveller</span>
                            <br/><span>{{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                            'Sei ricunosciuto con priorità dal tuo Consulente di fiducia' : 'Sarai riconosciuto con priorità dal tuo Consulente di fiducia'}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/Prime-Icona-Preventivi.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold">Preventivi Sartoriali</span>
                            <br/><span>
                            {{ (userData && userData.userInfo && userData.userInfo.isPrime) ?
                            'Puoi richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura' : 'Potrai richiedere ad Esperti sui luoghi la costruzione di Viaggi su misura'}}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/icona-cashback-gialla.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold">Fedeltà che ti Premia</span>
                            <br/><span>Prenotando questa Offerta per te un Buono di <span
                            class="red bold"
                            *ngIf="dataPreventivo">{{destSrv.getImportoBuonoSconto(dataPreventivo.totale)}}
                            €</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END OF: Se non hai PRIME -->

                  <!-- Se hai PRIME -->
                  <div
                    *ngIf="dataResponse.dettaglioPartenza.isPartenzaPrime"
                    class="text">
                    <div *ngIf="dataResponse.serviziPrime" class="row">
                      <div *ngFor="let vantaggi of dataResponse.serviziPrime" class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" [src]="vantaggi.logo" alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span
                              class="bold"> Risparmi {{vantaggi | calcolaImportoVantaggioPrime:camere:dataCheckout.prezzoTotaleConPrime}}
                              €</span>
                            <div class="benefit-descr fs-12px">{{vantaggi.descrizione}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="row mt-05rem mb-05rem">
                          <div class="col-4">
                            <img class="w-5rem" src="../../../assets/img/prime/icona-cashback.png"
                                 alt="icona vit">
                          </div>
                          <div class="col-8">
                            <span class="bold">Fedeltà che ti Premia</span>
                            <br/><span class="fs-12px">Prenotando questa Offerta per te un Buono di <span
                            class="red bold"
                            *ngIf="dataPreventivo">{{destSrv.getImportoBuonoSconto(dataPreventivo.totale)}}
                            €</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END OF: Se hai PRIME -->

                </div>
              </div>
            </div>
            <!-- ASSICURAZIONE -->
            <div class="description-container" [hidden]="assicurazionePrime && showPrezzoTotaleConPrime">
              <div class="main-title-container description-services col-12">
                <div class="flex-auto" *ngIf="isDeviceMobile">
                  <img class="prime-mobile" src="../../../assets/img/icons/Banner-Service-Partenza-quadrato.png"
                       alt="prime mobile">
                </div>
                <div class="title" *ngIf="!isDeviceMobile">
                  <div class="checkbox-wrapper">
                    <mat-checkbox (change)="onChangeCheckboxAssicurazione($event)"
                                  formControlName="checkBoxAssicurazione"
                                  [disabled]="assicurazionePrime && showPrezzoTotaleConPrime"></mat-checkbox>
                  </div>
                  Proteggi la tua vacanza, includi il Service +
                </div>
                <div class="title" *ngIf="isDeviceMobile">
                  Aggiungi <span class="service-text">SERVICE +</span>
                </div>
                <div class="codice-p" *ngIf="dataPreventivo">
                  + {{destSrv.calcolaAssicurazione(dataCheckout.prezzoTotaleConPrime, dataCheckout.prezzoTotale)}} €
                </div>
                <div class="pt-1rem pb-1rem float-right" *ngIf="isDeviceMobile">
                  <mat-checkbox (change)="onChangeCheckboxAssicurazione($event)"
                                formControlName="checkBoxAssicurazione"
                                [disabled]="assicurazionePrime && showPrezzoTotaleConPrime"></mat-checkbox>
                </div>


              </div>
              <mat-accordion class="service"
                             *ngIf="isDeviceMobile">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="fs-1_2rem bold l-height text-align-center">Proteggi la tua vacanza anche dal Covid,
                        includi
                        <span class="service-text ml-2px">SERVICE +</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="text fs-1_1rem l-height">
                    Ti consigliamo di aggiungere la Service+. Attiva subito il nostro pacchetto di servizi inclusivi di
                    Help Desk Medico,
                    Assicurazione Medico/Bagaglio e l'esclusiva Polizza Annullamento che consente il rimborso della tua
                    vacanza
                    tutti i casi di necessità di annullamento certificabili - incluso Covid-19 sia per annullamento che prolungamento soggiorno.
                  </div>
                  <div>
                    <a href="{{linkEstrattoPolizza}}"
                       title="Assicurazione condizioni"
                       class="fs-1_1rem bold cursor-pointer mt-05rem full-width" target="_blank">>> Leggi le
                      Condizioni</a>
                  </div>
                  <img class="partner-icon" src="{{linkIconaPartnerAssicurativo}}"
                       alt="Partner Assicurazione">
                </mat-expansion-panel>
              </mat-accordion>
              <div class="info-container row no-gutters">
                <div class="col-12" *ngIf="!isDeviceMobile">
                  <img class="banner" src="../../../assets/img/icons/Banner-Service-Partenza-Covid.png"
                       alt="proteggi vacanza">
                </div>
                <div class="dettagli d-none d-sm-block col-12">
                  <div class="text">
                    Ti consigliamo di aggiungere la Service+. Attiva subito il nostro pacchetto di servizi inclusivi di
                    Help Desk Medico,
                    Assicurazione Medico/Bagaglio e l'esclusiva Polizza Annullamento che consente il rimborso della tua
                    vacanza
                    tutti i casi di necessità di annullamento certificabili - incluso Covid-19 sia per annullamento che prolungamento soggiorno.
                  </div>
                  <div>
                    <a href="{{linkEstrattoPolizza}}"
                       title="Assicurazione condizioni"
                       class="condizioni text bold cursor-pointer mt-05rem full-width" target="_blank">>> Leggi le
                      Condizioni</a>
                  </div>
                  <img class="partner-icon" src="{{linkIconaPartnerAssicurativo}}"
                       alt="Partner Assicurazione">
                </div>
              </div>
            </div>
          </form>
          <div class="row no-gutters">
            <div class="col-12">
              <div (click)="nextStepCheckout('prenota')" class="btn-main-centered">
                <div class="btn-container">Procedi
                  <mat-icon>navigate_next</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF: STEP 1 - RIEPILOGO / PERSONALIZZA -->
        <!-- STEP 2 - PRENOTA -->
        <div *ngIf="checkoutStep === 'prenota'" class="checkout-card">
          <div class="w-100 div-promo text-align-center" *ngIf="promozioneAttiva && isDeviceMobile">
            OFFERTA IN FUORI TUTTO<br>
            <div class="timer">
              <!-- span id="days" *ngIf="daysToDday > 0"> {{daysToDday}} </span>Giorni-->
              HAI ANCORA
              <span id="hoursM2" *ngIf="hoursToDday > 0"> {{hoursToDday}} </span><span class="fs-12px"
                                                                                       *ngIf="hoursToDday > 0">Ore</span>
              <span id="minutesM2"> {{minutesToDday}} </span><span class="fs-12px">Minuti</span>
              <span id="secondsM2"> {{secondsToDday}} </span><span class="fs-12px">Secondi</span>
            </div>
          </div>
          <div class="big-title color-text-blue-borsaviaggi" *ngIf="!isDeviceMobile">
            Prenota il tuo viaggio
          </div>
          <div class="description-container">
            <div class="main-title-container main-prenotazione col-12">
              <div class="title">
                Modalità di prenotazione
              </div>
            </div>
            <div class="info-container row no-gutters">
              <form [formGroup]="formGroupPrenotazione">
                <mat-radio-group formControlName="tipoPrenotazione">

                  <div class="dettagli col-12" *ngIf="dataPreventivo.prenotabileOnLine">
                    <img class="icon" src="../../../assets/img/icons/credit-card.svg" alt="">
                    <div class="checkbox-wrapper">

                      <mat-radio-button value="prenotabileOnline" [checked]="dataPreventivo.prenotabileOnLine"
                      ></mat-radio-button>
                    </div>
                    <div class="title checkbox-label">
                      Rapido con Carta di Credito a garanzia
                    </div>
                    <div class="text">
                      Blocca subito questa offerta! Inserisci la carta di credito e provvederemo a sospendere
                      il {{percentualeAcconto}}%
                      dell'importo della vacanza, pari a <span
                      class="bold">{{(costoTotale * percentualeAcconto) / 100}}€</span>, a garanzia della
                      prenotazione. Un nostro Consulente
                      confermerà i servizi, ti contatterà per illustrarti le norme contrattuali e gli estremi per
                      effettuare
                      il pagamento con Bonifico Bancario o con le altre modalità disponibili.
                    </div>
                  </div>
                  <div class="dettagli col-12">
                    <img class="icon" src="../../../assets/img/icons/phone-square.svg" alt="Telefono Viaggi">
                    <div class="checkbox-wrapper">
                      <mat-radio-button value="prenotabileTelefonicamente"
                                        [checked]="!dataPreventivo.prenotabileOnLine"></mat-radio-button>
                    </div>
                    <div class="title checkbox-label"> Con assistenza telefonica</div>
                    <div class="text">
                      Richiedi l'assistenza telefonica di un nostro Consulente di Viaggio per avere ulteriori
                      informazioni e
                      concludere la prenotazione telefonicamente. Ti ricontatteremo e ti aiuteremo in ogni fase della
                      richiesta, ti illustreremo le norme contrattuali ed i metodi di pagamento disponibili all'atto
                      della
                      prenotazione.
                    </div>
                  </div>
                </mat-radio-group>
              </form>
            </div>
            <div class="main-title-container col-12">
              <div class="title">
                Inserisci i dati e compila la richiesta
              </div>
            </div>
            <div class="info-container no-mb-imp row no-gutters" *ngIf="!userData">
              <div class="dettagli">
                <bv-gia-cliente-prime-box></bv-gia-cliente-prime-box>
              </div>
            </div>
            <div class="bv-form-container">
              <bv-checkout-form [camere]="camere"></bv-checkout-form>
              <div class="dettagli">
                <div class="row">
                  <div class="col-12">
                    <div class="title">Segnalaci tue particolari necessità</div>
                  </div>
                  <form [formGroup]="formNote" class="col-12 pb-0">
                    <mat-form-field class="col-12 pb-0" appearance="outline">
                      <mat-label>Note</mat-label>
                      <textarea matInput class="h-8rem w-100" placeholder="Note" formControlName="note"></textarea>
                    </mat-form-field>
                  </form>
                  <div class="col-12" *ngIf="supplementiFacoltativi.length > 0">
                    <div class="title">Supplementi su richiesta</div>
                  </div>
                  <div class="supplementi-column col-12" *ngIf="supplementiFacoltativi.length > 0">
                    <div class="supplemento fs-1rem" *ngFor="let supplemento of supplementiFacoltativi">
                      <div class="name">
                        <mat-checkbox (change)="checkSupplementi(supplemento.Nome, $event)">
                          &nbsp;{{supplemento.Nome | titlecase}}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="riduzioni.length > 0">
                    <div class="title">Riduzioni su richiesta</div>
                  </div>
                  <div class="supplementi-column col-12" *ngIf="riduzioni.length > 0">
                    <div class="supplemento fs-1rem" *ngFor="let riduzione of riduzioni">
                      <div class="name">
                        <mat-checkbox (change)="checkRiduzioni(riduzione.Nome, $event)">
                          &nbsp;{{riduzione.Nome | titlecase}}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <form [formGroup]="formPrivacy" class="col-12 mt-4">
                    <mat-checkbox formControlName="privacy" class="left full-width" [checked]="true"> Ho letto ed
                      accetto l'<a routerLink="/informativa-privacy">Informativa Privacy</a>
                      in
                      merito al
                      trattamento dei miei
                      dati personali
                    </mat-checkbox>
                    <mat-checkbox formControlName="riceviNewsletter" class="left">Consenso per l'invio di materiale
                      promozionale
                      (facoltativo)
                    </mat-checkbox>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="btn-wrapper">
              <div (click)="previousStepCheckout()" class="btn-main back-btn">
                <div class="btn-container">
                  <mat-icon>navigate_before</mat-icon>
                  Indietro
                </div>
              </div>
              <div (click)="nextStepCheckout('completato')" class="btn-main privacy-next"
                   [ngClass]="{disabled: disableNext}">
                <div class="btn-container">Procedi
                  <mat-icon>navigate_next</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF: STEP 2 - PRENOTA -->
      <!-- STEP 3 - ORDINE COMPLETATO -->
      <div *ngIf="checkoutStep === 'completato'" class="col-12 checkout-card">
        <!--<div class="big-title color-text-blue-borsaviaggi">
          Complimenti!
        </div>-->
        <div class="description-container">
          <div class="info-container row no-gutters step-3">
            <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center container-ordine">
              <img class="col-12 pr-0 pl-0 ordine-completato" src="../../../assets/img/icons/ordine-completato.png"
                   alt="ordine completato">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-xl-6 dettagli bt-grey-default bb-grey-default txt-step3 col-12">
              <img class="icon-success" src="../../../assets/img/icons/success-tick.svg" alt="success">
              <div class="title-success">Grazie per aver completato la tua richiesta!</div>
              <span class="display-block fs-1rem text-center">Un nostro consulente prenderà rapidamente in carico la tua richiesta.
                      Ti contatterà per concludere la prenotazione, illustrandoti le modalità di pagamento disponibili per la conferma del viaggio.*</span>
              <span class="display-block text-center fs-10px mt-10px">*Servizio attivo in orario operativo - lun/ven: 9:00 - 19:00 / sab: 9:30/13:00</span>
              <div class="btn-main-home">
                <a routerLink="/" title="Borsaviaggi home" class="btn-container">Torna in Home
                  <mat-icon>house</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="col-12" *ngIf="checkoutStep !== 'completato'">
          <div class="btn-main-home">
            <a href="/" class="btn-container">Torna in Home
              <mat-icon>house</mat-icon>
            </a>
          </div>
        </div>-->
      </div>
      <!-- END OF: STEP 3 - ORDINE COMPLETATO -->

      <div [ngClass]="{'riepilogo-fixed': desktopRiepilogo, 'fixed-element': stopFixed}"
           class="d-none d-md-block col-12 col-sm-12 col-md-4 col-xl-4"
           *ngIf="!isDeviceMobile && checkoutStep !== 'completato'">
        <div class="top-box">
          <div class="top-section-wrapper">
            <div class="price-wrapper">
              <div class="title fs-1-6rem">Riepilogo dei Costi:</div>
              <div class="price color-text-orange-borsaviaggi">
                <span>{{costoTotale | euroFormat}}</span>
                <span class="fs-1-6rem">€</span>
              </div>
            </div>
            <div class="fw-800 fs-1_4rem">Sistemazione:</div>
            <div class="col-12">
              <ul class="sistemazioni-list">
                <li *ngFor="let camera of camere" class="list-elements">
                  <p class="key fs-1_1rem mb-8px">Camera&nbsp;{{camera.numCamera}} -
                    <span *ngIf="camera.ospiti" class="value">{{camera.ospiti.length}} <span
                      *ngIf="camera.ospiti.length === 1">persona</span><span
                      *ngIf="camera.ospiti.length > 1">persone</span>
                    </span>
                  </p>
                  <p *ngIf="camera.info">
                    <span *ngFor="let item of camera.info.Supplementi; let i = index">
                      <span *ngIf="i == 0">( </span><span>{{item}}</span>
                      <span *ngIf="i != camera.info.Supplementi.length - 1">
                        <br *ngIf="dataResponse.dettaglioPartenza.isHPartenzaLive">
                        <span *ngIf="!dataResponse.dettaglioPartenza.isHPartenzaLive"> + </span>
                      </span>
                      <span *ngIf="i == camera.info.Supplementi.length - 1"> ) </span>
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div class="tab-content row no-gutters">
              <div class="detail col-12">
                <div class="key">Pacchetto Vacanza</div>
                <div class="value" *ngIf="dataPreventivo">{{dataCheckout.pacchettoVacanza|euroFormat}}€</div>
                <mat-icon [matTooltip]="infoTooltip" matTooltipClass="info-tooltip" class="info-icon cursor-pointer">
                  info_outline
                </mat-icon>
              </div>
              <div class="detail col-12"
                   *ngIf="showPrezzoTotaleConPrime && (!userData || (userData && !userData.userInfo.isPrime))">
                <div class="key">Abbonamento <span class="prime-text">PRIME</span></div>
                <div class="value">+{{dataCheckout.prezzoAbbonamentoPrime}}€</div>
              </div>
              <div class="detail col-12"
                   *ngIf="showPrezzoTotaleConPrime && dataPreventivo.isPartenzaPrime && dataCheckout.risparmioVantaggiPrime> 0 ">
                <div class="key">Risparmio Vantaggi <span class="prime-text">PRIME</span></div>
                <div class="value">-{{dataCheckout.risparmioVantaggiPrime}}€</div>
              </div>
              <!-- div class="detail col-12"
                   *ngFor="let supplemento of dataResponse.supplementiObbligatori"
                   [hidden]="!dataResponse.supplementiObbligatori">
                <div class="key">{{supplemento.Nome | titlecase}}</div>
                <div class="value">+{{supplemento.TotaleSupplemento}}€</div>
              </div -->
              <div class="detail col-12" *ngIf="sconto">
                <div class="key">Buono sconto</div>
                <div class="value">-{{sconto}}€</div>
              </div>
              <div class="detail col-12" *ngIf="scontoFlash && showPrezzoTotaleConPrime">
                <div class="key">Sconto FLASH</div>
                <div class="value">-{{scontoFlash}}€</div>
              </div>
              <div class="detail col-12" *ngIf="showPrezzoTotaleConAssicurazione">
                <div class="key">Annullamento <span class="success-color">SERVICE+</span></div>
                <div class="value ">
                  +{{destSrv.calcolaAssicurazione(dataCheckout.prezzoTotaleConPrime, dataCheckout.prezzoTotale)}}€
                </div>
              </div>
            </div>
            <div class="col-12 text-align-center bold fs-1_2rem"
                 *ngIf="dataPreventivo && !isPartenzaLive && dataPreventivo.isSecondaSettimana">Richiesta seconda
              settimana
            </div>
          </div>

          <div class="w-100 div-promo text-align-center" *ngIf="promozioneAttiva && !isDeviceMobile">
            OFFERTA IN FUORI TUTTO<br>
            <div class="timer">
              <!-- span id="days" *ngIf="daysToDday > 0"> {{daysToDday}} </span>Giorni-->
              HAI ANCORA
              <span id="hours" *ngIf="hoursToDday > 0"> {{hoursToDday}} </span><span class="fs-12px"
                                                                                     *ngIf="hoursToDday > 0">Ore</span>
              <span id="minutes"> {{minutesToDday}} </span><span class="fs-12px">Minuti</span>
              <span id="seconds"> {{secondsToDday}} </span><span class="fs-12px">Secondi</span>
            </div>
          </div>

          <div class="tab-wrapper">
            <div class="tab">
              <div class="tab-content row no-gutters">
                <h3 class="title text-align-center color-text-blue-borsaviaggi">
                  Riepilogo Partenza
                </h3>
                <div class="detail col-12">
                  <div class="key">Codice Partenza</div>
                  <div class="value" *ngIf="dataPreventivo">{{dataCheckout.codicePartenza}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Destinazione</div>
                  <div class="value"
                       *ngIf="dataPreventivo">{{dataCheckout.nazione + " - " + dataCheckout.destinazione | titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Struttura</div>
                  <div class="value" *ngIf="dataPreventivo">{{dataCheckout.struttura| titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Da</div>
                  <div class="value" *ngIf="dataPreventivo">{{dataCheckout.luogoPartenza| titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Partenza il</div>
                  <div class="value" *ngIf="dataPreventivo">{{dataCheckout.dataPartenza}}</div>
                </div>
                <div class="detail col-12" [hidden]="isPartenzaLive && dataPreventivo.isSecondaSettimana">
                  <div class="key">Rientro il</div>
                  <div class="value" *ngIf="dataPreventivo">{{dataCheckout.dataRientro}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Soggiorno</div>
                  <div class="value" *ngIf="dataPreventivo">{{
                    isPartenzaLive && dataPreventivo.isSecondaSettimana ? 14 + ' Notti' : dataCheckout.soggiorno| titlecase}}</div>
                </div>
                <div class="detail col-12">
                  <div class="key">Trattamento</div>
                  <div class="value" *ngIf="dataPreventivo">{{dataCheckout.trattamento| titlecase}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
