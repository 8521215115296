import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ModalInterface} from "../../interface/modal.interface";
import {DestinazioniModalComponent} from "../../components/modal/destinazione/destinazioni-modal.component";
import {LoginModalComponent} from "../../components/modal/login-modal/login-modal.component";
import {SuccessModalComponent} from "../../components/modal/success/success-modal.component";
import {ErrorModalComponent} from "../../components/modal/error-modal/error-modal.component";
import {CropimageComponent} from "../../components/modal/cropimage/cropimage.component";
import {ModificaOccupazioneModalComponent} from '../../components/modal/modifica-occupazione-modal/modifica-occupazione-modal.component';
import {MacroDestinazioneModalComponent} from "../../components/modal/macro-destinazione-modal/macro-destinazione-modal.component";
import {GoogleMapsComponent} from "../../components/google-maps/google-maps.component";
import {ModalDestinazioniVprimeComponent} from "../../components/modal/modal-destinazioni-vprime/modal-destinazioni-vprime.component";
import {RegistrazioneModalComponent} from "../../components/modal/registrazione-modal/registrazione-modal.component";
import {InfoModalComponent} from "../../components/modal/info-modal/info-modal.component";
import {SessioneScadutaModalComponent} from "../../components/modal/sessione-scaduta-modal/sessione-scaduta-modal.component";
import {ModificaPreventivoCrocieraModalComponent} from '../../components/modal/modifica-preventivo-crociera/modifica-preventivo-crociera-modal.component';
import {PromoFlashModalComponent} from "../../components/modal/promo-flash-modal/promo-flash-modal.component";
import {PartenzaNonDisponibileComponent} from "../../components/modal/partenza-non-disponibile/partenza-non-disponibile.component";
import {RecuperoPasswordModalComponent} from "../../components/modal/recupero-password-modal/recupero-password-modal.component";
import {ComunicazioniModalComponent} from "../../components/modal/comunicazioni-modal/comunicazioni-modal.component";
import {ModificaOccupazioneCrocieraModalComponent} from "../../components/modal/modifica-occupazione-crociera-modal/modifica-occupazione-crociera-modal.component";

@Injectable()
export class ModalService {

  constructor(private dialog: MatDialog) {
  }


  public openSelezionaDestinazione(paramData: any, callBackAction?: Function) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<DestinazioniModalComponent> = {
      title: "Scegli le destinazioni",
      body_message: "",
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(DestinazioniModalComponent, {
      panelClass: ['centered-dialog', 'destinazioni', 'max-width-94vw'],
      disableClose: false,
      width: '900px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 1 && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openLoginModal(paramData, callBackAction?: Function) {
    setTimeout(() => {
      this.hideShowChatElement(true);
    }, 500);

    let dataModal: ModalInterface<LoginModalComponent> = {
      title: "Esegui l'accesso",
      body_message: "",
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(LoginModalComponent, {
      panelClass: ['centered-dialog', 'login-modal', 'txt-center', 'max-width-94vw'],
      disableClose: false,
      width: '400px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openCropImageModal(paramData, callBackAction?: Function) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<CropimageComponent> = {
      title: "Carica una foto",
      body_message: "",
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(CropimageComponent, {
      panelClass: ['centered-dialog', 'txt-center', 'max-width-94vw'],
      disableClose: false,
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openMacroDestinazioneModal(param, callBackAction?: Function) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<MacroDestinazioneModalComponent> = {
      title: "Testing",
      body_message: "",
      dataParam: param,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(MacroDestinazioneModalComponent, {
      panelClass: ['centered-dialog', 'Esito', 'max-width-94vw'],
      disableClose: true,
      width: '500px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 1 && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openPromoFlashModal(data) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<PromoFlashModalComponent> = {
      title: "",
      body_message: "",
      dataParam: data,
      button_text: ""
    };
    this.dialog.open(PromoFlashModalComponent, {
      width: '600px',
      data: dataModal
    });

    this.hideShowChatElement(false);
  }

  public openFeedbackModal(esito?: { success?: any, error?: any, button_text?: string, button_icon?: string, additionalParams?: any }, callBackAction?, paramWidth = '400px') {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<SuccessModalComponent> = {
      title: "",
      body_message: "",
      dataParam: esito,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(SuccessModalComponent, {
      panelClass: ['centered-dialog', 'Esito', 'max-width-94vw'],
      disableClose: true,
      width: paramWidth,
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openErrorDialog(dataError?, callBackAction?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<ErrorModalComponent> = {
      title: "Errore",
      body_message: dataError,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(ErrorModalComponent, {
      panelClass: ['centered-dialog', 'max-width-94vw'],
      disableClose: true,
      width: '400px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction();
      }
      this.hideShowChatElement(false);
    });
  }

  public openSessionEnded(dataError?, callBackAction?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<SessioneScadutaModalComponent> = {
      title: "Errore",
      body_message: dataError,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(SessioneScadutaModalComponent, {
      panelClass: ['centered-dialog', 'max-width-94vw'],
      disableClose: true,
      width: '400px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction();
      }
      this.hideShowChatElement(false);
    });
  }

  public openRegistrazioneModal(paramData?, callBackAction?: Function) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<RegistrazioneModalComponent> = {
      title: "Registrati",
      body_message: "",
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(RegistrazioneModalComponent, {
      panelClass: ['centered-dialog', 'txt-center', 'max-width-94vw'],
      disableClose: false,
      autoFocus: false,
      width: '750px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 1 && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openRecuperoPassword(paramData?, callBackAction?: Function) {
    this.hideShowChatElement(true);
    let dialogRef = this.dialog.open(RecuperoPasswordModalComponent, {
      panelClass: ['centered-dialog', 'txt-center', 'max-width-94vw'],
      disableClose: false,
      autoFocus: false,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 1 && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openModificaOccupazioneModal(paramData, dataError?, callBackAction?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<ModificaOccupazioneModalComponent> = {
      title: "Modifica Occupazione Camera",
      body_message: dataError,
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(ModificaOccupazioneModalComponent, {
      panelClass: ['centered-dialog', 'max-width-94vw'],
      disableClose: true,
      autoFocus: false,
      width: '600px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });

  }

  public openModificaOccupazioneCrocieraModal(paramData, dataError?, callBackAction?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<ModificaOccupazioneCrocieraModalComponent> = {
      title: "Modifica Occupazione Cabina",
      body_message: dataError,
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(ModificaOccupazioneCrocieraModalComponent, {
      panelClass: ['centered-dialog', 'max-width-94vw'],
      disableClose: true,
      autoFocus: false,
      width: '900px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }


  public openModificaPreventivoCrocieraModal(paramData, dataError?, callBackAction?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<ModificaPreventivoCrocieraModalComponent> = {
      title: "Richiesta di preventivo gratuita",
      body_message: dataError,
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(ModificaPreventivoCrocieraModalComponent, {
      panelClass: ['centered-dialog', 'max-width-94vw'],
      disableClose: true,
      width: '500px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });

  }


  public openMapsModal(paramData, callBackAction?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<GoogleMapsComponent> = {
      title: "Posizione",
      body_message: "",
      button_text: "Chiudi",
      dataParam: paramData
    };
    let dialogRef = this.dialog.open(GoogleMapsComponent, {
      panelClass: ['centered-dialog', 'max-width-94vw'],
      disableClose: true,
      width: '1000px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });

  }

  public openModaleDestinazioniPrime(param, callBackAction?: Function) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<ModalDestinazioniVprimeComponent> = {
      title: "Testing",
      body_message: "",
      dataParam: param,
      button_text: "Chiudi"
    };
    let dialogRef = this.dialog.open(ModalDestinazioniVprimeComponent, {
      panelClass: ['centered-dialog', 'Esito', 'max-width-94vw'],
      disableClose: false,
      width: '1000px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 1 && callBackAction) {
        callBackAction(result);
      }
      this.hideShowChatElement(false);
    });
  }

  public openInfoModal(param?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<InfoModalComponent> = {
      title: "",
      body_message: "",
      dataParam: param,
      button_text: "Chiudi"
    };
    this.dialog.open(InfoModalComponent, {
      panelClass: ['centered-dialog', 'Esito', 'max-width-94vw'],
      disableClose: true,
      width: '600px',
      data: dataModal
    });

    this.hideShowChatElement(false);
  }

  public openComunicazioniModal(param?) {
    this.hideShowChatElement(true);

    let dataModal: ModalInterface<ComunicazioniModalComponent> = {
      title: "",
      body_message: "",
      dataParam: param,
      button_text: "Chiudi"
    };
    this.dialog.open(ComunicazioniModalComponent, {
      panelClass: ['centered-dialog', 'Esito', 'max-width-94vw'],
      disableClose: true,
      width: '600px',
      data: dataModal
    });

    this.hideShowChatElement(false);
  }

  public openPartenzaNonDisp(param?) {
    this.hideShowChatElement(true);
    let dataModal: ModalInterface<PartenzaNonDisponibileComponent> = {
      title: "",
      body_message: "",
      dataParam: param,
      button_text: "Chiudi"
    };
    this.dialog.open(PartenzaNonDisponibileComponent, {
      panelClass: ['centered-dialog', 'Esito', 'max-width-94vw'],
      disableClose: true,
      width: '500px',
      data: dataModal
    });

    this.hideShowChatElement(false);
  }

  private hideShowChatElement(hide) {
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = hide ? 'none' : 'block';
    }
  }
}



