import { Component, Inject, OnInit } from '@angular/core';
import {ModalInterface} from "../../../interface/modal.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-comunicazioni-modal',
  templateUrl: './comunicazioni-modal.component.html',
  styleUrls: ['./comunicazioni-modal.component.scss']
})
export class ComunicazioniModalComponent implements OnInit {

  public testoComunicazione;
  public testoSaluti;

  constructor(public dialogRef: MatDialogRef<ComunicazioniModalComponent>,
              private route: Router,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<ComunicazioniModalComponent>) {
    if (Object.keys(data).length) {
      this.testoComunicazione = data.dataParam.testoComunicazione;
      this.testoSaluti = data.dataParam.testoSaluti;
    }
  }

  ngOnInit(): void {
  }

}
