import {Component, Inject, OnInit} from '@angular/core';
import {GeocodeService} from '../../services/gate/geocodeService';
import {ModalInterface} from '../../interface/modal.interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'borsaviaggi-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
  public latitude;
  public longitude;
  public luogo;
  public loading: boolean;

  constructor(public geoSrv: GeocodeService,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<GoogleMapsComponent>) {
    if (Object.keys(data).length) {
      this.luogo = data.dataParam.luogo;
    }

    this.geoSrv.geocodeAddress(this.luogo + ' ' + data.dataParam.nazione)
      .then((location) => {
        this.latitude = location.latitudine;
        this.longitude = location.longitudine;
        this.loading = false;
      });
  }

  ngOnInit() {

  }


}

