<div mat-dialog-close class="appearance-initial text-align-right">
  <i class="material-icons">close</i>
</div>
<div class="text-align-center">
  <h1>Promo Flash Borsaviaggi</h1>
  <div class="col-12 mb-10px">
    <img width="80px" src="../../../../assets/img/icone-partenze/LogoPromoFlash.png">
  </div>
  <p class="benefit-descr fs-12px">Se procedi con questa offerta ti verrà applicato un
    <span class="mini-label red">
  codice sconto di {{data.dataParam}}€
  </span>
  </p>
</div>



