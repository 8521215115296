<div>
  <label [ngClass]="{'hoverable-icon':!isAreaPersonale, 'hoverable':isAreaPersonale}" for="fileInput">
     <img [src]="imageObj.url" alt="Immagine profilo">
     <div [ngClass]="{'hover-text':isAreaPersonale, 'no-modifica':!isAreaPersonale}">Modifica</div>
     <div [ngClass]="{'background':isAreaPersonale}"></div>
   </label>
   <br/>
   <input *ngIf="isAreaPersonale" id="fileInput" type='file' (change)="onSelectFile($event)" accept="image/*">
 <!-- <button class="btn-delete" *ngIf="url" (click)="delete()" >Rimuovi</button>-->
</div>
