export class ConfigSearchEngine {

  public static getConfig(dataSearch) {
    return {
      tabEnable: {
        vacanze: true,
        italia: false,
        crociere: true,
        struttura: true
      },
      selectedTabIndex : 0,
      currentTab : 'vacanze'
    };
  }
}
