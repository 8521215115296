import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxGalleryComponent} from "../components/gallery/ngx-gallery.component";
import {NgxGalleryActionComponent} from "../components/gallery/ngx-gallery-action.component";
import {NgxGalleryArrowsComponent} from "../components/gallery/ngx-gallery-arrows.component";
import {NgxGalleryBulletsComponent} from "../components/gallery/ngx-gallery-bullets.component";
import {NgxGalleryImageComponent} from "../components/gallery/ngx-gallery-image.component";
import {NgxGalleryPreviewComponent} from "../components/gallery/ngx-gallery-preview.component";
import {NgxGalleryThumbnailsComponent} from "../components/gallery/ngx-gallery-thumbnails.component";


@NgModule({
  declarations: [
    NgxGalleryComponent,
    NgxGalleryActionComponent,
    NgxGalleryArrowsComponent,
    NgxGalleryBulletsComponent,
    NgxGalleryImageComponent,
    NgxGalleryPreviewComponent,
    NgxGalleryThumbnailsComponent

  ],
  imports: [
    CommonModule
  ],
  exports: [
    NgxGalleryComponent,
    NgxGalleryActionComponent,
    NgxGalleryArrowsComponent,
    NgxGalleryBulletsComponent,
    NgxGalleryImageComponent,
    NgxGalleryPreviewComponent,
    NgxGalleryThumbnailsComponent]
})
export class NgxGalleryModule {
}
