import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'borsaviaggi-sessione-scaduta-modal',
  templateUrl: './sessione-scaduta-modal.component.html',
  styleUrls: ['./sessione-scaduta-modal.component.scss']
})
export class SessioneScadutaModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SessioneScadutaModalComponent>,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  public closeModal() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
}
