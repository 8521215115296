import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {CartaRegaloService} from "../../services/gate/carta-regalo.service";

@Component({
  selector: 'borsaviaggi-dettaglio-carta-regalo',
  templateUrl: './dettaglio-carta-regalo.component.html',
  styleUrls: ['./dettaglio-carta-regalo.component.scss']
})
export class DettaglioCartaRegaloComponent implements OnInit {
  public valoriMenu: Array<{ value: number, viewValue: string }> = [];
  public giftForm: FormGroup;
  public dataForm: FormGroup;
  public acquistaForm: FormGroup;
  public regalare: boolean;
  public acquistare: boolean;
  public detailsData;

  constructor(public router: Router, public route: ActivatedRoute, public regaloSrv: CartaRegaloService) {
    this.giftForm = new FormGroup({
      'quantita': new FormControl(1)
    });
    this.dataForm = new FormGroup({
      'nome-mittente': new FormControl('', Validators.required),
      'cognome-mittente': new FormControl('', Validators.required),
      'email-mittente': new FormControl('', Validators.required),
      'cellulare-mittente': new FormControl(''),
      'nome-destinatario': new FormControl('', Validators.required),
      'cognome-destinatario': new FormControl('', Validators.required),
      'email-destinatario': new FormControl('', Validators.required),
      'cellulare-destinatario': new FormControl(''),
      'dedica': new FormControl('')
    });
    this.acquistaForm = new FormGroup({
      'nome': new FormControl('', Validators.required),
      'cognome': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'cellulare': new FormControl('', Validators.required),
    });
    this.giftForm.controls['quantita'].valueChanges.subscribe((val)=>{
      this.giftForm.controls['valore'].setValue(this.detailsData.importo * val);
    })
  }

  ngOnInit() {
    let params =  { "guid": this.route.snapshot.params.guid };
    this.regaloSrv.getGiftCardDetails(params).then(
      (response) => {
        this.detailsData = response.body.data;
        for (let i = 1; i <= 10; i++) {
          this.valoriMenu.push({
            value: i,
            viewValue: (response.body.data.importo * i).toString()
          });
        }
      },
      (error) => {
        console.error("Errore in caricamento dati", error);
      }
    );
  }

  public getRegalareForm() {
    if(this.regalare){
      this.regalare = false;
    }else{
      this.regalare = true;
      this.acquistare = false;
    }
  }

  public getAcquistareForm(){
    if(this.acquistare){
      this.acquistare = false;
    }else{
      this.acquistare = true;
      this.regalare = false;
    }
  }

  public moveBack() {
    this.router.navigate(["../../../carte-regalo"], {relativeTo: this.route});
  }

  public checkout() {
    let acquisto;

    let destinatario = {
      'nome': this.dataForm.controls['nome-destinatario'].value,
      'cognome': this.dataForm.controls['cognome-destinatario'].value,
      'email': this.dataForm.controls['email-destinatario'].value,
      'cellulare': this.dataForm.controls['cellulare-destinatario'].value
    };

    if (this.acquistare) {
      acquisto = {
        'nome': this.acquistaForm.controls['nome'].value,
        'cognome': this.acquistaForm.controls['cognome'].value,
        'email': this.acquistaForm.controls['email'].value,
        'cellulare': this.acquistaForm.controls['cellulare'].value
      };
    } else {
      acquisto = {
        'nome': this.dataForm.controls['nome-mittente'].value,
        'cognome': this.dataForm.controls['cognome-mittente'].value,
        'email': this.dataForm.controls['email-mittente'].value,
        'cellulare': this.dataForm.controls['cellulare-mittente'].value
      };
    }
    let param = {
      "guid": this.route.snapshot.params.guid,
      "quantita": this.detailsData.tipologia == 1 ? 1 : this.giftForm.controls['quantita'].value,
      "importo": this.detailsData.importo,
      "valoreExtra": this.detailsData.importoValoreExtra - this.detailsData.importo,
      "cartaAcquistaToolTip": "CREDITCARD",
      "nomeAcquisto": acquisto.nome,
      "cognomeAcquisto": acquisto.cognome,
      "emailAcquisto": acquisto.email,
      "cellulareAcquisto": acquisto.cellulare,
      "nomeDestinatario": destinatario.nome,
      "cognomeDestinatario": destinatario.cognome,
      "emailDestinatario": destinatario.email,
      "cellulareDestinatario": destinatario.cellulare,
      "dedicaRegalo": this.dataForm.controls['dedica'].value
    };
    this.regaloSrv.getCheckOut(param).then(
      (response) => {
        if (this.acquistare ? this.acquistaForm.valid : this.dataForm.valid) {
          window.location.href = response.body.data.location;
        }
      }
    );
  }

  public getGiftValue() {
    return this.detailsData.importo * this.giftForm.controls['quantita'].value;
  }
}
