<div class="full-width tab-search-engine" [ngClass]="currentTab">
  <mat-tab-group class="group-tab-wrapper" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="actionChangeTab($event)">
    <mat-tab *ngIf="config.tabEnable.vacanze">
      <ng-template mat-tab-label>
        <span class="tab-label">Vacanze</span>
      </ng-template>
      <borsaviaggi-search-vacanze [dataSearch]="dataSearch" *ngIf="selectedTabIndex==0"></borsaviaggi-search-vacanze>
    </mat-tab>
    <mat-tab *ngIf="config.tabEnable.italia">
      <ng-template mat-tab-label>
        <span class="tab-label">Mare Italia</span>
      </ng-template>
      <borsaviaggi-search-italia [dataSearch]="dataSearch"></borsaviaggi-search-italia>
    </mat-tab>
    <mat-tab *ngIf="config.tabEnable.crociere" tabindex="2">
      <ng-template mat-tab-label>
        <span class="tab-label">Crociere</span>
      </ng-template>
      <borsaviaggi-search-crociere *ngIf="selectedTabIndex==1"></borsaviaggi-search-crociere>
    </mat-tab>
    <mat-tab *ngIf="config.tabEnable.struttura" >
      <ng-template mat-tab-label>
        <span class="tab-label">Struttura</span>
      </ng-template>
      <borsaviaggi-search-strutture *ngIf="selectedTabIndex==2"></borsaviaggi-search-strutture>
    </mat-tab>
  </mat-tab-group>
</div>
