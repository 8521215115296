import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'borsaviaggi-dettaglio-hotel',
  templateUrl: './dettaglio-hotel.component.html',
  styleUrls: ['./dettaglio-hotel.component.scss']
})
export class DettaglioHotelComponent implements OnInit {
  public dataHotel;
  public twoColumnsThreshold;

  constructor(private route: ActivatedRoute) {
    this.dataHotel = this.route.snapshot.data.dataDestinazioni.body.data;
    if (this.dataHotel.listaStruttureLocalita) {
      this.getCountStrutture()
    }
  }

  ngOnInit() {
  }

  public getCountStrutture() {
    let counter = 0;
    this.dataHotel.listaStruttureLocalita.forEach((nazione) => {
      counter++;
    });
    this.twoColumnsThreshold = Math.ceil(counter / 2);
  }

  public makeUrlSeoHrefForStrutture(n) {
    let ret = "hotel/" + n.nomeStruttura.toLowerCase().replace(/\s/g, "-") + "/" + n.idStruttura;
    return ret.toLowerCase();
  }

}
