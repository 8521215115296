import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {AuthService} from "../../common/auth.service";
import {UserService} from "../user.service";

@Injectable()
export class DatiPersonaliResolve {
  constructor(private utenteSrv: UserService, private authSrv: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
      return this.utenteSrv.getUtente()
        .catch(err => {
          return err;
        });

  }

}
