import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringFilterAutocomplete',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: any, paramKeyObj?: string): any {
    if (!items || !filter || (typeof filter !== "string")) {
      return items;
    }
    let keyNomeCentrale = paramKeyObj;
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter((item) => {
      return item[keyNomeCentrale].toUpperCase().indexOf(filter.toUpperCase()) !== -1;
    });
  }
}
