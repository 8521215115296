import {Injectable} from '@angular/core';
import {GenericGateService} from "../common/genericGate.service";
import {RequestMethodEnum} from "./request.method.enum";

@Injectable()
export class RicercaCrociereService {

  constructor(private genericHttpService: GenericGateService) {
  }

  public ricercaCrociere(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/motore/searchOfferteCrociere", request);
  }

  public getPartenzaCrociera(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.GET, "/partenza/getPartenzaCrociera" + "/" + request.idPartenzaCrociera);
  }

  public prenotaCrociera(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/prenotaCrociera", request);
  }

  public savePreventivoCrociere(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/savePreventivoCrociera", request);
  }

  public savePreventivoCrocieraLive(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/savePreventivoCrocieraLive", request);
  }

  public getPreventivoCrociera(idPreventivoCrociera): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getPreventivoCrociera/" + idPreventivoCrociera);
  }

  public getQuotazioneCabinaMsc(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getQuotazioneCabinaMsc", request);
  }

  public getQuotazioneCabinaCosta(request): Promise<any> {
    return this.genericHttpService.doHttpRequest(RequestMethodEnum.POST, "/partenza/getQuotazioneCabinaCosta", request);
  }
}
