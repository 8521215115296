import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {DettaglioZService} from "../dettaglio-z.service";

@Injectable({
  providedIn: 'root'
})
export class DettaglioZResolveService {
  constructor(private destSrv: DettaglioZService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    return this.destSrv.getHeaderDestinazioni(routeSnap.params.idDestinazione)
      .catch(err => {
        return err;
      });
  }
}
