import {Component, Inject, OnInit} from '@angular/core';
import {ModalInterface} from "../../../interface/modal.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";
import {ResponseCheckerService} from "../../../services/common/response.checker.service";

@Component({
  selector: 'borsaviaggi-macro-destinazione-modal',
  templateUrl: './macro-destinazione-modal.component.html',
  styleUrls: ['./macro-destinazione-modal.component.scss']
})
export class MacroDestinazioneModalComponent implements OnInit {
  public destinazioniData;
  public listDestinazioni;
  public showForm;

  constructor(public dialogRef: MatDialogRef<MacroDestinazioneModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalInterface<MacroDestinazioneModalComponent>,
              private deviceService: DeviceDetectorService) {
    this.showForm = false;
    this.listDestinazioni = this.getNazioniGroupValues();

    if (Object.keys(data).length) {
      this.destinazioniData = data;
      this.listDestinazioni = this.makeDestList(this.listDestinazioni, this.destinazioniData.dataParam.destSelected);
    }

  }


  ngOnInit() {

  }

  public getNazioniGroupValues(){
    let destinationDataFilter = this.data.dataParam.promiseDest.map(
      (val) => {
        return {
          checked: false,
          disabled: false,
          idDestinazione: val.idDestinazione,
          isDestinazione: true,
          isNazione: val.isNazione,
          link: null,
          nome: val.nomeDestinazione,
          urlFoto: null
        };
      }
    );

    return destinationDataFilter;
  }

  private makeDestList(arrRespDest, paramDestSelected) {
    let ret = arrRespDest;
    if (paramDestSelected) {
      ret.map(
        (currDest) => {
          if (paramDestSelected.find(currSelectDest => parseInt(currSelectDest.idDestinazione) === currDest.idDestinazione)) {
            currDest.checked = true;
          } else if (paramDestSelected.length === 3) {
            currDest.disabled = true;
          }
        }
      );
    }
    return ret;
  }

  public actionCheckUnCheck() {
    let totChecked = [];

    let maxDestinazioni = 3;

    let checkedItem = this.listDestinazioni.filter(
      currLoc => currLoc.checked
    );
    checkedItem.map(item => totChecked.push(item));

    if ((!this.deviceService.isMobile() && totChecked.length >= maxDestinazioni)) {
      this.listDestinazioni.map(
        (currDest) => {
          if (!currDest.checked) {
            currDest.disabled = true;
          }
        }
      );
    } else {
      this.listDestinazioni.map(
        (currDest) => {
          currDest.disabled = false;
        }
      );
    }
  }

  public actionConfirm() {
    let destSel = [];
    let currentList = this.listDestinazioni.map(
      (macroDestItem) => {
        if (macroDestItem.checked) {
          destSel.push(macroDestItem);
        }
      });
    this.dialogRef.close({action: 1, dataChip: destSel, list: "destinazione"})
  }
}
