<div>
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <mat-dialog-content class="modal-content">
    <image-cropper [hidden]="onSuccessUpdate || onErrorUpdate"
                   [imageBase64]="imageObj.urlToEdit"
                   [maintainAspectRatio]="true"
                   [aspectRatio]="4 / 4"
                   [resizeToWidth]="128"
                   [imageQuality]="10"
                   [roundCropper]="true"
                   [autoCrop]="false"
                   format="png"
       >

      </image-cropper>

      <div class="sweet-alert" [hidden]= !onSuccessUpdate>
        <div class="sa-icon sa-success animate" style="display: block;"><span
          class="sa-line sa-tip animateSuccessTip"></span> <span class="sa-line sa-long animateSuccessLong"></span>
          <div class="sa-placeholder"></div>
          <div class="sa-fix"></div>
        </div>
        <div class="message-container text-align-center">
          <span>{{messageFromService}}</span>
        </div>
      </div>
      <div class="sweet-alert" [hidden]= !onErrorUpdate>
        <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
              <span class="sa-x-mark animateXMark"> <span
                class="sa-line sa-left"></span> <span class="sa-line sa-right"></span>
              </span>
        </div>
        <div class="message-error-container text-align-center">
          <span>{{messageFromService}}</span>
        </div>
      </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button [hidden]="onSuccessUpdate || onErrorUpdate " (click)="uploadImage()" mat-button class="button-style" type="submit">
      <span>OK</span>
    </button>
  </mat-dialog-actions>

</div>
