import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'borsaviaggi-informativa-privacy',
  templateUrl: './informativa-privacy.component.html',
  styleUrls: ['./informativa-privacy.component.scss']
})
export class InformativaPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
