import {Component, HostListener, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {SearchEngineValidator} from "../../../services/validator/search-engine.validator";
import {ModalService} from "../../../services/common/modal.service";
import {DestinazioneService} from "../../../services/gate/destinazione.service";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {SEO_PAGE} from "../../../constants/seo.const";
import {Router} from "@angular/router";
import {RESPONSIVE_VALUE} from "../../../constants/const.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'borsaviaggi-search-strutture',
  templateUrl: './search-strutture.component.html',
  styleUrls: ['./search-strutture.component.scss']
})


export class SearchStruttureComponent implements OnInit {
  public formGroup: FormGroup;
  public isMobileDevice: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  public modelSearch: {
    strutture: { list: Array<any>, autocompeteList: Observable<any> }
  };

  constructor(private modalSrv: ModalService,
              private deviceService: DeviceDetectorService,
              private destinazioniSrv: DestinazioneService,
              private router: Router,) {
    this.formGroup = SearchEngineValidator.formStruttura();
    this.modelSearch = {
      strutture: {list: [], autocompeteList: new Observable()}
    };
    this.destinazioniSrv.getStrutture().then(
      (response) => {
        this.modelSearch.strutture.list = response.body.data;
        this.destinazioneAutocomplateChange();
      },
      (error) => {
        this.modelSearch.strutture.list = [];
        console.log("Errore caricamento strutture", error);
      }
    );
    this.isMobileDevice = this.deviceService.isMobile();
  }

  ngOnInit() {

  }

  private destinazioneAutocomplateChange() {
    this.modelSearch.strutture.autocompeteList = this.formGroup.get('struttura').valueChanges
      .pipe(
        startWith(''),
        map(
          (value) => {
            if (typeof value !== "object") {
              if ((this.isMobileDevice && value && value.length > 2) || !this.isMobileDevice) {
                const filterValue = value.toLowerCase();
                return this.modelSearch.strutture.list.filter(
                  (option: any) => option.nomeStruttura.toLowerCase().includes(filterValue));
              } else {
                return [];
              }

            } else if (this.isMobileDevice) {
              this.actionSubmitRicerca();
            }
          }
        )
      );
  }

  public displayStruttura(struttura): string {
    return struttura.nomeStruttura;
  }

  public actionSubmitRicerca() {
    let ret = SEO_PAGE.DETTAGLIO_STRUTTURA + "/";
    ret += this.formGroup.get('struttura').value.nomeStruttura + "/";
    ret += Number(this.formGroup.get('struttura').value.idStruttura) + "/";
    let url = ret.toLowerCase().replace(/\s/g, "-");

    this.router.navigate([url]);
  }


}
