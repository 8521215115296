import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatiPersonaliValidator} from "../../../../services/validator/dati-personali.validator";
import {PasswordValidator} from "../../../../services/validator/password.validator";
import {FormGroup} from "@angular/forms";
import {PromiseUtilService} from "../../../../services/common/promiseUtil.service";
import {UtilityService} from "../../../../services/gate/utility.service";
import {DatiPersonaliModel} from "../../../../model/datiPersonali.model";
import {UserService} from "../../../../services/gate/user.service";
import {AuthService} from "../../../../services/common/auth.service";
import {ModalService} from "../../../../services/common/modal.service";
import {LoaderService} from "../../../../components/loader/loader.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import * as moment from "moment";

interface Newsletter {
  idTipoNewsLetter: number,
  newsLetter: string,
  isActive: boolean
}


@Component({
  selector: 'borsaviaggi-dati-personali',
  templateUrl: './dati-personali.component.html',
  styleUrls: ['./dati-personali.component.scss']
})

export class DatiPersonaliComponent implements OnInit, OnDestroy {

  public datiUtente;
  public formAccount: FormGroup;
  public formPassword: FormGroup;
  public listaComuni = [];
  public listaRegioni = [];
  /*public newsLetterPromo: Newsletter[] = [];
  public newsLetterPreferenze: Newsletter[] = [];*/
  public hidePwdForm: boolean = true;
  public scadenzaPrime;
  public modificaForm: boolean;
  public salvaModifica: boolean;
  public subscribe: Subscription;


  constructor(private promiseUtilService: PromiseUtilService,
              private utilitySrv: UtilityService,
              private userSrv: UserService,
              private authSrv: AuthService,
              private modalSrv: ModalService,
              private loaderSrv: LoaderService,
              private route: ActivatedRoute) {

    moment.locale('it');

    this.datiUtente = this.authSrv.getUser();
    this.subscribe = this.route.parent.data
      .subscribe((data) => {
        if (this.datiUtente) {
          this.datiUtente.userInfo = data.dataResolve.body.data;
          this.authSrv.setUser(this.datiUtente);
        }

      });
    this.getComuni(this.datiUtente.userInfo.idRegione);
    this.scadenzaPrime = this.datiUtente.dataScadenzaPrime;
    this.modificaForm = true;

  }

  ngOnInit() {
    this.formAccount = DatiPersonaliValidator.formAccount(this.datiUtente.userInfo);
    this.formPassword = PasswordValidator.formPasswordValidator;
    this.formAccount.disable();

    this.utilitySrv.getRegioni().then(
      (response) => {
        if (response && response.body.data) {
          this.listaRegioni = response.body.data;
        } else {
          console.log("Errore reperimento regioni")
        }
      },
      (error) => {
        console.log(error)
      }
    );

    /*this.getNewsLetter();*/
  }

  public editAccount(valid) {
    if (valid) {
      let param = this.createModelAccount();
      this.loaderSrv.show();
      this.userSrv.edit(param).then((response) => {
        if (response && response.body.status === 0) {
          this.modalSrv.openFeedbackModal({success: response.body.message});
          this.formAccount.disable();
          this.modificaForm = true;
          this.salvaModifica = false;
        } else {
          this.modalSrv.openErrorDialog(response.body.message);
        }
      }, (error) => {
      }).finally(() => {
        this.loaderSrv.hide();
      });
    } else {
      this.formAccount.markAllAsTouched();
    }
  }

  showPasswordForm() {
    this.hidePwdForm = false;
  }

  public editPassword(valid) {
    let newPwd = this.formPassword.get('password').value;
    let param = {"newPassword": newPwd};
    if (valid) {
      this.loaderSrv.show();
      this.userSrv.editPassword(param).then((response) => {
        if (response && response.body.status === 0) {
          this.modalSrv.openFeedbackModal({success: response.body.message});
        } else {
          this.modalSrv.openErrorDialog(response.body.message);
        }
      }, (error) => {
      }).finally(() => {
        this.loaderSrv.hide();
      });
    } else {
    }
  }

  public getTooltipMsg() {
    return "La password deve contenere:\n  " +
      "  - Almeno 8 caratteri\n " +
      "  - Almeno un numero\n " +
      "  - Almeno un carattere alfanumerico minuscolo\n " +
      "  - Almeno un carattere alfanumerico maiuscolo\n " +
      "  - Almeno uno dei seguenti caratteri speciali: @ # * ! - +  . , _";
  }

  onChangeRegione(event) {
    this.listaComuni = [];
    this.getComuni(event.value);
    this.formAccount.get('idComune').setValue('');
    this.formAccount.get('idComune').markAsTouched();
  }

  private getComuni(idRegione) {
    this.utilitySrv.getComuniByRegione({idRegione: idRegione}).then(
      (response) => {
        if (response && response.body.data) {
          this.listaComuni = response.body.data;
        } else {
          console.log("Errore reperimento comuni")
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private createModelAccount() {
    let obj = {
      nome: this.formAccount.get('nome').value || '',
      cognome: this.formAccount.get('cognome').value || '',
      dataNascita: moment(this.formAccount.get('dataNascita').value).format('YYYY-MM-DD') || '',
      idComune: this.formAccount.get('idComune').value || '',
      idRegione: this.formAccount.get('idRegione').value || '',
      sesso: this.formAccount.get('sesso').value || '',
      email: this.formAccount.get('email').value || '',
      telefono: this.formAccount.get('telefono').value || '',
      cellulare: this.formAccount.get('cellulare').value || '',
      indirizzo: this.formAccount.get('indirizzo').value || '',
      codiceFiscale: this.formAccount.get('codiceFiscale').value || '',
      cap: this.formAccount.get('cap').value || '',
      riceviNewsletter: this.datiUtente.riceviNewsletter,
      password: ''
    };

    return new DatiPersonaliModel(obj);
  }

  /*private getNewsLetter() {
    let newsLetterList: Newsletter[] = [];
    this.userSrv.getNewsLetterUtente().then((response) => {
      if (response && response.body.status === 0) {
        newsLetterList = response.body.data;
        //first 3 elements are 'Promo'
        for (let i = 0; i < 3; i++) {
          this.newsLetterPromo.push(newsLetterList[i]);
        }
        //last elements are 'Preferenze'
        for (let i = 3; i < newsLetterList.length; i++) {
          this.newsLetterPreferenze.push(newsLetterList[i]);
        }

      }
    }, (error) => {

    })
  }*/

  /*public updateNewsletterUtente() {
    let newsLetterArray: Newsletter[] = [];
    this.newsLetterPromo.forEach(function (item) {
      newsLetterArray.push(item)
    });
    this.newsLetterPreferenze.forEach(function (item) {
      newsLetterArray.push(item)
    });

    let requestObj = {
      email: this.datiUtente.email,
      listaNewsletter: newsLetterArray
    };

    this.loaderSrv.show();
    this.userSrv.updateNewsletterUtente(requestObj).then((response) => {
      if (response && response.body.status === 0) {
        this.modalSrv.openFeedbackModal({success: response.body.message});
      } else {
        this.modalSrv.openErrorDialog(response.body.message);
      }
    }, (error) => {

    }).finally(() => {
      this.loaderSrv.hide();
    });
  }*/

  public enableForm() {
    this.formAccount.enable();
    this.salvaModifica = true;
    this.modificaForm = false;
  }

  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }
}
