import {Serializable} from "../interface/serializable.interface";

export class User implements Serializable<User> {

  public authorizationHeader: string;
  public dataAccesso: string;
  public token: string;
  public navigationHistory: string;
  public listaBuoniSconto: any;
  public userInfo: {
    nome: string;
    cognome: string;
    dataNascita: string;
    password: string;
    sesso: string;
    email: string;
    idComune: number;
    idRegione: number;
    indirizzo: string;
    cap: string;
    cellulare: string;
    codiceFiscale: string;
    telefono: string;
    riceviNewsletter: boolean;
    isPrime: boolean;
    cashback: string;
  };

  constructor() {

  }

  public assignJsonToInstance(json) {
    Object.assign(this, json);
  }
}
