import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

export class CheckoutValidator {


  public static formIntestatarioValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dataNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      sesso: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cellulare: new FormControl('', Validators.compose([Validators.required])),
      riceviNewsletter: new FormControl('', Validators.compose([])),
      privacy: new FormControl('', Validators.compose([])),
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ])),
      buonoSconto: new FormControl('', Validators.compose([])),
      note: new FormControl('', Validators.compose([]))
    });

  public static formOspiteValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dataNascita: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });


  public static formNoteValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      note: new FormControl('', Validators.compose([]))
    });

  public static formGroupValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      buonoSconto: new FormControl('', Validators.compose([])),
      aggiungiPrime: new FormControl('', Validators.compose([
        Validators.required
      ])),
      aggiungiAssicurazione: new FormControl('', Validators.compose([
        Validators.required
      ])),
      prenotazioneRapida: new FormControl('', Validators.compose([
        Validators.required
      ])),
      prenotazioneConAssistenzaTelefonica: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });

  public static formGroupPrenotazioneValidator(dataParam,assicurazionePrime): FormGroup {
    return new FormBuilder().group({
      hideRequired: false,
      tipoPrenotazione: new FormControl('', Validators.compose([
        Validators.required
      ])),
      checkBoxPrime: new FormControl(dataParam.isPartenzaPrime),
      checkBoxAssicurazione: new FormControl(assicurazionePrime),
      buonoSconto: new FormControl('', Validators.compose([]))
    });
  }


  public static formPrivacyValidator: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      privacy: new FormControl('', Validators.compose([
        Validators.required
      ])),
      riceviNewsletter: new FormControl('', Validators.compose([]))
    });

  public static formGroupIntestatarioCrociera: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dataNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      sesso: new FormControl('', Validators.compose([
        Validators.required
      ])),
      comuneDiNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      codiceFiscale: new FormControl('', Validators.compose([
        Validators.required
      ])),
      comuneDiResidenza: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cellulare: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ])),
      note: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });


  public static formPreventivoCrociera: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      volo: new FormControl('', Validators.compose([]))
    });


  public static formCheckoutCrociera: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      datiCabina_1: new FormControl('', Validators.compose([])),
      datiCabina_2: new FormControl('', Validators.compose([])),
      datiCabina_3: new FormControl('', Validators.compose([])),
      datiCabina_4: new FormControl('', Validators.compose([])),

      nome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cognome: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dataNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      sesso: new FormControl('', Validators.compose([
        Validators.required
      ])),
      regione: new FormControl('', Validators.compose([
        Validators.required
      ])),
      comuneDiNascita: new FormControl('', Validators.compose([
        Validators.required
      ])),
      codiceFiscale: new FormControl('', Validators.compose([
        Validators.required
      ])),
      comuneDiResidenza: new FormControl('', Validators.compose([
        Validators.required
      ])),
      cellulare: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required
      ])),
      note: new FormControl('', Validators.compose([])),
      volo: new FormControl('', Validators.compose([])),
      privacy: new FormControl('', Validators.compose([])),
      riceviNewsletter: new FormControl('', Validators.compose([])),
    });

  public static formCheckoutBuonoScontoCrociera: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      buonoSconto: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

  public static formGroupPersonalizzaCrociera: FormGroup =
    new FormBuilder().group({
      hideRequired: false,
      volo: new FormControl('', Validators.compose([]))
    });


  public static formSignUpErrorMessage: Object = {
    'email': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'email', message: "L' email inserita non è valida"}
    ],
    'password': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'nome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cognome': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'indirizzo': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cap': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'codiceFicale': [
      {type: 'required', message: 'Campo obbligatorio!'},
      {type: 'pattern', message: 'Codice fiscale non valido!'}
    ],
    'dataNascita': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'idComune': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'sesso': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'cellulare': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'telefono': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'buonoSconto': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ],
    'note': [
      {type: 'required', message: 'Campo obbligatorio!'}
    ]

  };


}
