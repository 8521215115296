import * as moment from "moment";


export const MESE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// @dynamic
export class DateTimeService {
  private static txt_day = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
  private static txt_months = new Array("December", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November");

  private static getTxtDay(day) {
    return this.txt_day[day];
  };

  private static getTxtMonth(months) {
    return this.txt_months[months];
  };

  public static getDateStringFromDate(paramdate): string {
    moment.locale('it');
    let dateObj: any = moment(paramdate);
    return paramdate && dateObj.isValid() ? dateObj.format("DD/MM/YYYY HH:MM") : "";
  }

  public static getMinutesDifference(paramDate1, paramDate2): number {
    let diff = (paramDate1.getTime() - paramDate2.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  public static getCurrentDate() {
    return new Date();
  }

  public static getTimeText(timestamp): string {
    let text = "";
    let today = new Date();
    let datePost = new Date(timestamp);
    let diffMs = (today.getTime() - datePost.getTime()); // milliseconds between now & Christmas
    let diffDays = Math.round(diffMs / 86400000); // days
    let diffHrs = Math.round((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    if (diffDays > 7) {
      let date = datePost.getUTCDate();
      let txtMonth = this.getTxtMonth(datePost.getUTCMonth());
      let hours = datePost.getHours();
      let minutes = datePost.getMinutes();

      text = date + " " + txtMonth + " at " + hours + "." + minutes;
    } else {
      if (diffDays > 0) {
        let day = this.getTxtDay(datePost.getUTCDay());
        let hours = datePost.getHours();
        let minutes = datePost.getMinutes().toString().length == 1 ? "0" + datePost.getMinutes() : datePost.getMinutes() + "";

        if (diffDays == 1) {
          text = " Yesterday";
        } else {
          text = day + " at " + hours + "." + minutes;
        }
      } else {
        if (diffHrs > 0) {
          text = diffHrs + " hrs";
        } else {
          if (diffMins > 0) {
            text = diffMins + " mins";
          } else {
            text = "now";
          }
        }
      }
    }
    return text;
  }

}
