import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from "@angular/router";
import {PrimeService} from "../prime.service";

@Injectable({
  providedIn: 'root'
})
export class PrimeResolveService {
  constructor(private primeSrv: PrimeService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
  }

}
