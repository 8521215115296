import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {CheckoutValidator} from '../../services/validator/checkout.validator';
import {UtilsStringService} from '../../services/common/utils-string.service';
import {FormGroup} from '@angular/forms';
import {DestinazioneService} from '../../services/gate/destinazione.service';
import {AuthService} from '../../services/common/auth.service';
import * as moment from "moment";
import {LoaderService} from "../../components/loader/loader.service";
import {ResponseCheckerService} from "../../services/common/response.checker.service";
import {ModalService} from "../../services/common/modal.service";
import {interval, Observable, Subscription} from "rxjs";
import {User} from "../../model/user.dto";
import {Meta} from "@angular/platform-browser";
import {map, startWith} from "rxjs/operators";
import {LoginSidenavService} from "../../components/login-sidenav/loginSidenav.service";
import {SEO_OFFERTE} from "../../constants/seo.const";
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {BannerManagerService} from "../../services/common/banner-manager.service";

const COSTO_PRIME = 19;

@Component({
  selector: 'borsaviaggi-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})


export class CheckoutPageComponent implements OnInit, OnDestroy {
  public utilsStr: UtilsStringService;
  public formGroup: FormGroup = CheckoutValidator.formGroupValidator;
  public formNote: FormGroup = CheckoutValidator.formNoteValidator;
  public formGroupPrenotazione: FormGroup;
  public formPrivacy: FormGroup = CheckoutValidator.formPrivacyValidator;
  public dataResponse;
  public dataSource = new MatTableDataSource();
  public dataP: any;
  public dataDescPartenza: any;
  public dataPreventivo: any;
  public assicurazionePrime: boolean;
  public checkoutStep: any;
  public dataCheckout: any;
  public prezzoTotaleNonScontato: any;
  public sconto: any;
  public scontoFlash: any;
  public idPromozioneFlashAlpitour: number;
  public costoTotale: number;
  public camere: any;
  public showPrezzoTotaleConPrime: boolean;
  public showPrezzoTotaleConAssicurazione: boolean;
  public dataPromo: any;
  public erroreSconto: any;
  public idPreventivo: any;
  public checkoutData: any;
  public userData: User;
  public arrSubscriber: Array<Subscription>;
  public buoniSconto: Array<string>;
  public isDeviceMobile: boolean;
  public mobileStickyHeader: boolean;
  public infoTooltip;
  public desktopRiepilogo;
  public stopFixed;
  public disableNext;
  public isPartenzaLive;
  public urlPartenza: string;
  public supplementiFacoltativi;
  public riduzioni;
  public supplementiSelezionati: Array<string>;
  public riduzioniSelezionate: Array<string>;
  public percentualeAcconto: number;
  public isPrimeObbligatoriaSconto: boolean = false;
  public linkEstrattoPolizza: string;
  public linkIconaPartnerAssicurativo: string;

  private subscription: Subscription;

  public promozioneAttiva: boolean = false;
  public dataFinePromozione: Date;
  public milliSecondsInASecond: number = 1000;
  public hoursInADay: number = 24;
  public minutesInAnHour: number = 60;
  public SecondsInAMinute: number = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  filteredOptions: Observable<string[]>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isDeviceMobile = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.checkoutStep != 'step3' && !this.isDeviceMobile) {
      let eleToppone = this.elementRef.nativeElement.querySelector('.top-box');
      let eleContainer = this.elementRef.nativeElement.querySelector('.background');
      const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.desktopRiepilogo = scrollOffset >= 300 && scrollOffset <= (eleContainer.scrollHeight - eleToppone.offsetHeight);
      this.stopFixed = scrollOffset >= (eleContainer.scrollHeight - eleToppone.offsetHeight);
    }
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.mobileStickyHeader = scrollOffset >= 60;
  }

  constructor(private route: ActivatedRoute,
              private destSrv: DestinazioneService,
              private modalSrv: ModalService,
              private elementRef: ElementRef,
              private metaTagService: Meta,
              private loaderSrv: LoaderService,
              private deviceService: DeviceDetectorService,
              private authSrv: AuthService,
              private loginSideSrv: LoginSidenavService,
              private bannerSrv: BannerManagerService) {
    this.bannerSrv.showHideBanner(false);
    this.disableNext = true;
    this.formPrivacy.get('privacy').valueChanges.subscribe((val) => {
      this.disableNext = !val;
      if (val) {
        this.formPrivacy.get('riceviNewsletter').setValue(true);
      }
    });
    this.metaTagService.updateTag(
      {name: 'robots', content: 'noindex'},
    );
    if (this.route.snapshot.data.dataP.body && this.route.snapshot.data.dataP.body.data && ResponseCheckerService.isSuccessResponse(this.route.snapshot.data.dataP.body)) {
      this.isDeviceMobile = this.deviceService.isMobile();
      this.dataResponse = this.dataP = this.route.snapshot.data.dataP.body.data;
      this.promozioneAttiva = this.route.snapshot.data.dataP.body.data.layoutPartenza.isDivImgFuoriTuttoVisible;
      this.dataFinePromozione = this.route.snapshot.data.dataP.body.data.layoutPartenza.dataFinePromozione;
      this.dataDescPartenza = this.route.snapshot.data.dataPDesc.body.data;
      this.dataPreventivo = this.route.snapshot.data.dataCheckout.body.data;
      this.showPrezzoTotaleConPrime = this.dataPreventivo ? this.dataPreventivo.isPartenzaPrime : null;
      this.utilsStr = UtilsStringService;
      this.dataSource = new MatTableDataSource(this.dataResponse);
      this.costoTotale = 0;
      this.isPartenzaLive = Object.keys(this.dataPreventivo.ListaCamereLive).length > 0;
      this.supplementiFacoltativi = this.route.snapshot.data.dataP.body.data.supplementiFacoltativi;
      this.riduzioni = this.route.snapshot.data.dataP.body.data.riduzioni;
      this.percentualeAcconto = this.route.snapshot.data.dataP.body.data.dettaglioPartenza.acconto;
      this.idPromozioneFlashAlpitour = this.route.snapshot.data.dataP.body.data.dettaglioPartenza.idPromozioneFlashAlpitour;
      this.linkEstrattoPolizza = this.route.snapshot.data.dataP.body.data.dettaglioPartenza.linkEstrattoPolizza;
      this.linkIconaPartnerAssicurativo = this.route.snapshot.data.dataP.body.data.dettaglioPartenza.linkIconaPartnerAssicurativo;

      let partenzaObj = {
        nazione: this.route.snapshot.data.dataP.body.data.dettaglioPartenza.nazione,
        destinazione: this.route.snapshot.data.dataP.body.data.dettaglioPartenza.destinazione,
        struttura: this.route.snapshot.data.dataP.body.data.dettaglioPartenza.nomeStrutturaOfferta,
        luogoPartenza: this.route.snapshot.data.dataP.body.data.dettaglioPartenza.luogoNome,
        dataPartenza: moment(this.route.snapshot.data.dataP.body.data.dettaglioPartenza.lblDataPartenza, "DD MMM YYYY", "it-IT").format(),
        idPartenza: this.dataPreventivo.idPartenza,
      };
      this.urlPartenza = this.makePartenzaUrl(partenzaObj);

      if (this.route.snapshot.data.dataP.body.data.serviziPrime) {
        this.route.snapshot.data.dataP.body.data.serviziPrime.map(
          (currServ) => {
            if (!this.assicurazionePrime && currServ.titolo === "Viaggio Protetto") {
              this.assicurazionePrime = true;
            }
          }
        );
      }
      this.formGroupPrenotazione = CheckoutValidator.formGroupPrenotazioneValidator(this.dataPreventivo, (this.assicurazionePrime && this.showPrezzoTotaleConPrime));
      if (!this.dataPreventivo) {
        this.modalSrv.openPartenzaNonDisp();
      }
    } else {
      this.modalSrv.openErrorDialog("Preventivo non trovato.");
    }
  }

  private getTimeDifference () {
    this.timeDifference = new Date(this.dataFinePromozione).getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits (timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute));
    //this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    //this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnInit() {
    this.supplementiSelezionati = [];
    this.riduzioniSelezionate = [];

    if (this.authSrv.isUserLogedin()) {
      this.userData = this.authSrv.getUser();
      this.buoniSconto = this.userData.listaBuoniSconto;
      this.filteredOptions = this.formGroupPrenotazione.controls['buonoSconto'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }

    this.arrSubscriber = [
      this.route.queryParams.subscribe(queryParams => {
        this.idPreventivo = queryParams['idPreventivo'];
      })
    ];
    this.sconto = 0;
    this.scontoFlash = this.dataPreventivo && this.dataPreventivo.scontoFlash ? this.dataPreventivo.scontoFlash : 0;
    if (this.scontoFlash && (!this.userData || this.userData && !this.userData.userInfo.isPrime)) {
      this.formGroupPrenotazione.get('checkBoxPrime').setValue(true);
      this.dataPreventivo.isPartenzaPrime = true;
      this.showPrezzoTotaleConPrime = true;
      this.formGroupPrenotazione.get('checkBoxAssicurazione').setValue( (this.assicurazionePrime && this.showPrezzoTotaleConPrime));
    }

    this.checkoutStep = 'riepilogo';
    this.dataCheckout = this.dataPreventivo ? this.setDataCheckout(this.route.snapshot.data.dataCheckout.body.data) : null;
    this.infoTooltip = this.dataPreventivo ? this.getToolTipValues() : null;
    this.camere = this.dataPreventivo ? this.makeCamereObj(this.dataPreventivo, (Object.keys(this.dataPreventivo.ListaCamereLive).length > 0)) : null;
    this.dataPreventivo ? this.calcolaCostoTotale() : null;
    this.setPrenotazioneOnInit();
    this.arrSubscriber.push(
      this.authSrv.userObservable().subscribe(
        (result) => {
          if (result) {
            this.userData = this.authSrv.getUser();
            this.buoniSconto = this.userData.listaBuoniSconto;
            this.calcolaCostoTotale();
            // this.options = this.userData? this.userData.listaBuoniSconto : [];
            console.log('user changed');
            this.filteredOptions = this.formGroupPrenotazione.controls['buonoSconto'].valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value))
            );
          }
        }
      )
    );

    if (this.promozioneAttiva) {
      this.subscription = interval(1000)
        .subscribe(x => { this.getTimeDifference(); });
    }
  }

  public checkSupplementi(supplemento, event) {
    if (event.checked) {
      this.supplementiSelezionati.push(supplemento);
    } else {
      const index: number = this.supplementiSelezionati.indexOf(supplemento);
      this.supplementiSelezionati.splice(index, 1);
    }
  }

  public checkRiduzioni(riduzione, event) {
    if (event.checked) {
      this.riduzioniSelezionate.push(riduzione);
    } else {
      const index: number = this.riduzioniSelezionate.indexOf(riduzione);
      this.riduzioniSelezionate.splice(index, 1);
    }
  }

  private makePartenzaUrl(p) {
    let url = "/" + SEO_OFFERTE.VACANZA_CATEGORIA_KEYWORD + "/";
    url += p.nazione + "/";
    url += p.destinazione + "/";
    url += UtilsStringService.removeSpecialChar(p.struttura) + "/";
    url += p.luogoPartenza + "/";
    url += moment(p.dataPartenza).locale("it").format("DD-MMMM") + "/";
    url += "p" + "/";
    url += p.idPartenza + '';
    return url.toLowerCase().replace(/\s/g, "-");
  }

  public actionLogin() {
    this.loginSideSrv.open();
  }

  public getToolTipValues() {
    let tooltip;
    tooltip = 'Dettaglio dei Costi \n \n';
    tooltip = tooltip + ' - Quota Base \n';

    if (this.dataCheckout.pratica) {
      tooltip = tooltip + '- Gestione Pratica ' + this.dataCheckout.tipoGestionePratica + '\n';
    }
    if (this.dataCheckout.oneri) {
      tooltip = tooltip + '- Oneri \n';
    }
    if (this.dataCheckout.tasseAeroportuali) {
      tooltip = tooltip + '- Tasse Aeroportuali \n';
    }
    if (this.dataCheckout.carbonTax) {
      tooltip = tooltip + '- Carbon Tax \n';
    }
    if (this.dataCheckout.visti) {
      tooltip = tooltip + '- Visti \n';
    }
    if (this.dataCheckout.adeguamentoValutario) {
      tooltip = tooltip + '- Adeguamento Valutario \n';
    }
    if (this.dataCheckout.addizionaleVoli) {
      tooltip = tooltip + '- Addizionale Comunale Voli \n';
    }
    if (this.dataCheckout.adeguamentoCarburante) {
      tooltip = tooltip + '- Adeguamento Carburante \n';
    }
    if (this.dataCheckout.contributoNave) {
      tooltip = tooltip + '- Contributo Nave \n';
    }
    if (this.dataCheckout.tassePortuali) {
      tooltip = tooltip + '- Tasse Portuali \n';
    }
    for (let supplemento of this.dataResponse.supplementiObbligatori) {
      tooltip = tooltip + '- ' + supplemento.Nome + '\n';
    }

    return tooltip;
  }

  private calcolaCostoTotale() {
    let costoPrime = this.dataCheckout.prezzoAbbonamentoPrime;
    this.costoTotale = this.dataCheckout.prezzoTotale;
    if (this.formGroupPrenotazione.get('checkBoxPrime').value) {
      if (this.showPrezzoTotaleConPrime) {
        let prezzoTotale = this.dataCheckout.prezzoTotaleConPrime > 0 ? this.dataCheckout.prezzoTotaleConPrime : this.dataCheckout.prezzoTotale;
        this.costoTotale = this.authSrv.isUserLogedin() && this.userData.userInfo.isPrime ? prezzoTotale : prezzoTotale + costoPrime;
        if (this.scontoFlash) {
          this.costoTotale = this.costoTotale - this.destSrv.calcolaScontoFlash(this.costoTotale);
        }
      }
    } else {
      this.costoTotale = this.authSrv.isUserLogedin() && this.userData.userInfo.isPrime ? this.dataCheckout.prezzoTotale : this.showPrezzoTotaleConPrime ? this.dataCheckout.prezzoTotale + costoPrime : this.dataCheckout.prezzoTotale;
    }
    if (this.showPrezzoTotaleConAssicurazione) {
      this.costoTotale = this.costoTotale + this.parsePrice(this.destSrv.calcolaAssicurazione(this.dataCheckout.prezzoTotaleConPrime, this.dataCheckout.prezzoTotale));
    }
    if (this.sconto) {
      this.costoTotale = this.costoTotale - this.sconto;
    }
  }

  public setPrenotazioneOnInit() {
    if (this.dataPreventivo && this.dataPreventivo.prenotabileOnLine) {
      this.formGroupPrenotazione.controls['tipoPrenotazione'].setValue('prenotabileOnline');
    } else {
      this.formGroupPrenotazione.controls['tipoPrenotazione'].setValue('prenotabileTelefonicamente');
    }
  }

  public toggleAccoridionRiepilogo(opend) {
    if (opend && this.isDeviceMobile) {
      document.querySelector('body').classList.add("overflow-hidden");
    } else if (this.isDeviceMobile) {
      document.querySelector('body').classList.remove("overflow-hidden");
    }
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = opend ? 'none' : 'block';
    }
  }

  public checkout() {
    this.loaderSrv.show();

    let noteRichiesta = this.formNote.get('note').value;
    for (let supp of this.supplementiSelezionati) {
      noteRichiesta += ' - [Supp.] ' + supp;
    }
    for (let rid of this.riduzioniSelezionate) {
      noteRichiesta += ' - [Rid.] ' + rid;
    }

    let formIntestatario = this.camere[0].forms[0];
    let requestParam = {
      listaRiduzioniSuRichiesta: [], // no
      listaSupplementiSuRichiesta: [], // no
      annoNascita: moment(formIntestatario.get("dataNascita-1-1").value).format('YYYY'),
      meseNascita: moment(formIntestatario.get("dataNascita-1-1").value).format('MM'),
      giornoNascita: moment(formIntestatario.get("dataNascita-1-1").value).format('DD'),
      assicurazione: this.showPrezzoTotaleConAssicurazione ? this.parsePrice(this.dataP.dettaglioPartenza.lblAssicurazione) : 0,
      isGetPrime: this.formGroupPrenotazione.get('checkBoxPrime').value,
      cellulare: formIntestatario.get("cellulare-1-1").value,
      nome: formIntestatario.get("nome-1-1").value,
      cognome: formIntestatario.get("cognome-1-1").value,
      email: formIntestatario.get("email-1-1").value,
      sesso: formIntestatario.get("sesso-1-1").value,
      idPreventivoOnline: this.route.snapshot.params.idPreventivo,
      isAcconto: this.formGroupPrenotazione.controls['tipoPrenotazione'].value === 'prenotabileOnline',
      isCallback: this.formGroupPrenotazione.controls['tipoPrenotazione'].value === 'prenotabileTelefonicamente',
      isLiveWebServices: this.isPartenzaLive,
      isRiceveNewsletter: this.formPrivacy.get('riceviNewsletter').value,
      isSecondaSettimana: this.dataPreventivo.isSecondaSettimana,
      isUtentePrime: false,
      isVenditaSegreta: false,
      listaPasseggeriPerCamera: [],
      note: noteRichiesta,
      idPromozioneFlashAlpitour: this.scontoFlash > 0 && this.formGroupPrenotazione.get('checkBoxPrime').value ? this.idPromozioneFlashAlpitour : 0,
      costoTotale: this.costoTotale
    };

    this.camere.map(
      (currCamera, indexCamera) => {
        indexCamera = indexCamera + 1;
        //let objArrayCameraOspite = [];
        currCamera.forms.map(
          (currOspiteForm, indexOspite) => {
            indexOspite = indexOspite + 1;
            if (indexCamera === 1 && indexOspite > 1 || indexCamera > 1) {
              requestParam.listaPasseggeriPerCamera.push(
                {
                  nome: currOspiteForm.get("nome-" + indexCamera + "-" + indexOspite).value,
                  cognome: currOspiteForm.get("cognome-" + indexCamera + "-" + indexOspite).value,
                  giornoNascita: moment(currOspiteForm.get("dataNascita-" + indexCamera + "-" + indexOspite).value).format('DD'),
                  meseNascita: moment(currOspiteForm.get("dataNascita-" + indexCamera + "-" + indexOspite).value).format('MM'),
                  annoNascita: moment(currOspiteForm.get("dataNascita-" + indexCamera + "-" + indexOspite).value).format('YYYY'),
                  idCamera: indexCamera
                }
              );
            }

          }
        );
        //requestParam.listaPasseggeriPerCamera.push(objArrayCameraOspite);
      }
    );
    this.destSrv.checkout(requestParam)
      .then((response) => {
          if (ResponseCheckerService.isSuccessResponse(response.body)) {
            this.checkoutData = response.body.data;
            if (this.formGroupPrenotazione.controls['tipoPrenotazione'].value === 'prenotabileOnline') {
              window.location.href = response.body.data.location;
            } else {
              this.checkoutStep = 'completato';
            }
          } else {
            this.modalSrv.openErrorDialog(ResponseCheckerService.getErrorMessage(response.body));
          }
        },
        (error) => {
          this.modalSrv.openErrorDialog(ResponseCheckerService.getErrorMessage(error));
        }).finally(
      () => {
        this.loaderSrv.hide();
      }
    )
  }


  public makeCamereObj(dataCheckout, isLive) {
    let camere = [];
    let dataCamere = isLive ? dataCheckout.ListaCamereLive : dataCheckout.ListaCamere;
    Object.keys(dataCamere).forEach((numCamera) => {
      let obj = {
        numCamera: null,
        ospiti: [],
        info: null
      };
      obj.numCamera = numCamera;
      obj.info = dataCheckout.ListaSupplementiCamere[Number(numCamera) - 1];
      if (!isLive) {
        for (let j = 1; j <= dataCamere[numCamera]; j++) {
          obj.ospiti.push(j)
        }
      } else {
        let dataCamera = dataCamere[numCamera].split(["|"]);
        let numeroAdulti = dataCamera[2];
        let numeroBambini = dataCamera[3];
        let numPersone = parseInt(dataCamera[2]) + parseInt(dataCamera[3]);
        obj.info.Supplementi.unshift((numeroAdulti + " Adulti + " + numeroBambini + " Bambini"));

        for (let t = 1; t <= numPersone; t++) {
          obj.ospiti.push(t)
        }
      }
      camere.push(obj);
    });
    return camere;
  }

  public nextStepCheckout(state) {
    switch (state) {
      case 'prenota':
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.checkoutStep = state;
        break;
      case 'completato':
        if (this.formPrivacy.get('privacy').value) {
          if (this.camere.every((currCamera => {
            return this.isDeviceMobile ? currCamera.forms[0].valid : currCamera.forms.every(currForm => currForm.valid)
          }))) {
            this.checkout();
          } else {
            this.camere.map((currCamera => {
              currCamera.forms.map(currForm => {
                  for (let formControlName in currForm.controls) {
                    currForm.get(formControlName).markAsTouched();
                    currForm.get(formControlName).markAsDirty();
                    this.formPrivacy.get('privacy').markAsTouched();
                    this.formPrivacy.get('privacy').markAsDirty();
                  }
                }
              )
            }))
          }
        }
        break;
    }
  }

  public previousStepCheckout() {
    if (this.checkoutStep === 'prenota') this.checkoutStep = 'riepilogo';
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  public applicaSconto() {
    this.getImportoPromozione();
  }

  public getImportoPromozione() {
    let reqObj = {
      assicurazione: this.parsePrice(this.dataP.dettaglioPartenza.lblAssicurazione),
      codicePromo: this.formGroupPrenotazione.controls['buonoSconto'].value,
      idPreventivoOnline: this.route.snapshot.params.idPreventivo,
      isAggiungiPrimeNoPrime: this.formGroupPrenotazione.get('checkBoxPrime').value,
      isPrime: this.formGroupPrenotazione.get('checkBoxPrime').value,
      isUtentePrime: this.userData ? this.userData.userInfo.isPrime : false
    };
    this.destSrv.getImportoPromozione(reqObj).then((response) => {
      this.dataPromo = response.body.data;
      this.sconto = this.dataPromo ? this.dataPromo.importo || this.dataPromo.importoPrime : 0;
      this.isPrimeObbligatoriaSconto = this.dataPromo.isPrimeObbligatoria;
      this.calcolaCostoTotale();
      this.erroreSconto = this.dataPromo ? this.dataPromo.errorePromo : "Il codice inserito non è valido. Inserirne un altro.";
    }, (error) => {
      console.log('error');
    })
  }

  public calcolaPrezzoTotale(dettaglioP) {
    let prezzoNoSimbol = dettaglioP.lblCostoBase.replace('€', '').replace('&euro;', '');
    let tax = dettaglioP.lblTaxAero ? dettaglioP.lblTaxAero.replace('€', '').replace('&euro;', '') : dettaglioP.lblTaxPort.replace('€', '').replace('&euro;', '');
    let costoPratica = dettaglioP.lblPratica.replace('€', '').replace('&euro;', '');
    return (dettaglioP.paxPerRisparmio * parseInt(prezzoNoSimbol)) + parseInt(tax) + parseInt(costoPratica)
  }

  public parsePrice(price) {
    let parsedPrice = price.replace('€', '').replace('&euro;', '');
    return parseInt(parsedPrice);
  }


  public getPrezzoPerPersona(dataPreventivo) {
    return dataPreventivo.totale / dataPreventivo.pax;
  }

  public setDataCheckout(dataPreventivo) {
    this.prezzoTotaleNonScontato = this.calcolaPrezzoTotale(this.dataP.dettaglioPartenza);

    return {
      prezzoTotale: dataPreventivo.totale,
      prezzoTotaleConPrime: dataPreventivo.totaleConPrime,
      prezzoAbbonamentoPrime: COSTO_PRIME,
      prezzoAssicurazione: this.dataP.dettaglioPartenza.lblAssicurazione,
      prezzoPerPersona: this.getPrezzoPerPersona(dataPreventivo),
      pacchettoVacanza: dataPreventivo.totale,
      risparmioVantaggiPrime: this.destSrv.getSommaVantaggiPrime(this.dataP, this.dataPreventivo),
      codicePartenza: dataPreventivo.idPartenza,
      destinazione: this.dataP.dettaglioPartenza.destinazione,
      struttura: this.dataP.dettaglioPartenza.nomeStrutturaOfferta,
      luogoPartenza: this.dataP.dettaglioPartenza.luogoNome,
      dataPartenza: this.dataP.dettaglioPartenza.lblDataPartenza,
      nazione: this.dataP.dettaglioPartenza.nazione,
      dataRientro: this.dataP.dettaglioPartenza.lblDataRientro,
      soggiorno: this.dataP.dettaglioPartenza.lblGiorniNotti,
      trattamento: this.dataP.dettaglioPartenza.lblTrattamento,
      sistemazione: dataPreventivo.pax, // ex: Camera 1-2 Pax
      pratica: this.parsePrice(this.dataP.dettaglioPartenza.lblPratica),
      oneri: this.parsePrice(this.dataP.dettaglioPartenza.lblOneri),
      tasseAeroportuali: this.parsePrice(this.dataP.dettaglioPartenza.lblTaxAero),
      carbonTax: this.parsePrice(this.dataP.dettaglioPartenza.lblCarbonTax),
      supplementiObbligatori: this.dataP.supplementiObbligatori,
      visti: this.parsePrice(this.dataP.dettaglioPartenza.lblVisti),
      adeguamentoValutario: this.parsePrice(this.dataP.dettaglioPartenza.lblAdValutario),
      addizionaleVoli: this.parsePrice(this.dataP.dettaglioPartenza.lblAddVolo),
      adeguamentoCarburante: this.parsePrice(this.dataP.dettaglioPartenza.lblAdeguamento),
      contributoNave: this.parsePrice(this.dataP.dettaglioPartenza.lblNave),
      tassePortuali: this.parsePrice(this.dataP.dettaglioPartenza.lblTaxPort),
      tipoGestionePratica: this.dataP.dettaglioPartenza.lblTipoGestionePratica
    };
  }

  public onChangeCheckboxPrime(event) {
    this.showPrezzoTotaleConPrime = event.checked;
    if (this.showPrezzoTotaleConPrime && this.showPrezzoTotaleConAssicurazione && this.assicurazionePrime) {
      this.showPrezzoTotaleConAssicurazione = false;
    }else if(!this.showPrezzoTotaleConPrime && this.assicurazionePrime ){
      this.formGroupPrenotazione.get('checkBoxAssicurazione').setValue(false);
    }

    if (!this.showPrezzoTotaleConPrime && this.isPrimeObbligatoriaSconto) {
      this.sconto = null;
      this.formGroupPrenotazione.controls['buonoSconto'].setValue(null);
    }

    this.calcolaCostoTotale();
  }

  public onChangeCheckboxAssicurazione(event) {
    this.showPrezzoTotaleConAssicurazione = event.checked;
    this.calcolaCostoTotale();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.buoniSconto && this.buoniSconto.length ? this.buoniSconto.filter((option: any) =>
      option.Causale.toLowerCase().indexOf(filterValue) === 0) : [];
  }

  ngOnDestroy() {
    this.formGroupPrenotazione.controls['buonoSconto'].setValue(null);
    this.sconto = null;
    this.erroreSconto = null;
    this.arrSubscriber.map(
      (sub) => {
        sub.unsubscribe();
      }
    );

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
