<ng-container>
  <div class="readmore d-flex align-items-center" (click)="readLess()" [hidden]="hideDescription">
    <mat-icon>expand_less</mat-icon>
    <span class="d-none d-sm-inline">Riduci</span>
    <span class="d-inline d-sm-none">Nascondi la descrizione</span>
  </div>
  <div class="readmore d-flex align-items-center" (click)="readMore()" [hidden]="!hideDescription">
    <mat-icon>expand_more</mat-icon>
    <span class="d-none d-sm-inline">Leggi tutto</span>
    <span class="d-inline d-sm-none">Leggi la descrizione</span>
  </div>
  <!--<div #targetView [innerHTML]="content" [ngClass]="(hideDescription) ? 'short-content' : 'normal-content'"></div>-->
  <div #targetView
       [innerHTML]="content | safeHtml"
       [ngClass]="(hideDescription) ? 'short-content' : 'normal-content'"></div>
  <div class="readmore d-flex align-items-center" (click)="readLess()" [hidden]="hideDescription">
    <!-- readLess(targetView)-->
    <mat-icon>expand_less</mat-icon>
    <span class="d-none d-sm-inline">Riduci</span>
    <span class="d-inline d-sm-none">Nascondi la descrizione</span>
  </div>
</ng-container>
