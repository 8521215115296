<div class="container border-r-2px padding-20px box-promo mt-20px mb-20px text-align-center">
  <img class="mb-15px prime-banner" src="../../../assets/img/prime/Prime-Banner.jpg" alt="prime banner">
  <div class="row mb-20px mt-20px">
    <mat-accordion class="col-12">
      <mat-expansion-panel class="xps-panel mat-elevation-z8" [expanded]="false">
        <mat-expansion-panel-header class="height-40 color-red" [collapsedHeight]="''" [expandedHeight]="''">
          <mat-panel-title class="fs-1_2rem txt-white">Filtra per tipologia di servizio</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let servizi of datiOffertaPrime.listaServizi">
          <mat-checkbox class="float-r mt-3 ml-20 mr-5" (change)="getOffertePrime($event, servizi.idServizio)"></mat-checkbox>
          <img class="float-l mr-20 ml-8 w-60" [src]="'https://www.borsaviaggi.it/images/prime/'+servizi.banner" alt="icona prime">
          <h3 class="text-align-left">{{servizi.nomeServizio}}</h3>
          <p class="text-align-left">{{servizi.descrizioneServizio}}<br>
            <a *ngIf="servizi.docCondizioniPolizza" href="https://www.borsaviaggi.it//Assicurazione-Annullamento-EuropAssistance.pdf" target="_blank">Leggi le Condizioni della polizza</a></p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="row mb-20px mt-20px">
    <div class="col-12 ">
      <div class="title-divider text-align-center  border-r-2px background-orange-borsaviaggi p-1px-0">
        <span class="fw-400 fs-1-6rem mb-30px color-white">Le Destinazioni Disponibili</span>
      </div>
    </div>
  </div>
  <div class="row">
    <borsaviaggi-macro-box *ngFor="let boxDestinazioni of listSelezioniDestinazioni"
                           [configObj]="boxDestinazioni"
                           (click)="openModaleDestinazioniPrime(boxDestinazioni)"
                           class="pl-0 col-lg-3 col-md-4 col-sm-6 mb-20px d-flex align-items-center justify-content-center macro-box-destinazione">
    </borsaviaggi-macro-box>
  </div>
</div>
