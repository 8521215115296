import {Injectable} from '@angular/core';
import {RicercaVacanzeService} from "../ricerca-vacanze.service";
import {ActivatedRouteSnapshot} from "@angular/router";
import {UtilsStringService} from "../../common/utils-string.service";
import {CacheService} from "../../common/cache.service";

@Injectable({
  providedIn: 'root'
})
export class RicercaVacanzeResolveService {
  constructor(
    private ricercaVacanzeSrv: RicercaVacanzeService,
    private cacheSrv: CacheService) {
  }

  resolve(routeSnap: ActivatedRouteSnapshot) {
    let req = routeSnap.queryParams;
    let reqObj = {
      dataPartenza: req && req.dataPartenza || null,
      listaDestinazioni: req && UtilsStringService.makeDestinazioniArray(req.destinazione, req.destinazioneNazione) || null,
      listaLuoghiPartenza: req && UtilsStringService.makeIdArray(req.partenzaDa) || null,
      supplementi: req && {
        isPrime: req.isPrime,
        numeroAdulti: req.adulti,
        numeroBambini: req.bambini,
        etaBambini: req && req.bambini && req.etaBambini ? UtilsStringService.makeIdArray(req.etaBambini) : null || null
      } || null,
      tolleranza: 3,
      isAgenzia: 0,
      filtri: req && {
        lastminute: req && req.lastminute ? UtilsStringService.strToBool(req.lastminute) : null,
        single: req && req.single ? UtilsStringService.strToBool(req.single) : null,
        bimbiGratis: req && req.bimbiGratis ? UtilsStringService.strToBool(req.bimbiGratis) : null,
        prenotaPrima: req && req.prenotaPrima ? UtilsStringService.strToBool(req.prenotaPrima) : null,
        natale: req && req.natale ? UtilsStringService.strToBool(req.natale) : null,
        capodanno: req && req.capodanno ? UtilsStringService.strToBool(req.capodanno) : null,
        zeroPensieri: req && req.zeroPensieri ? UtilsStringService.strToBool(req.zeroPensieri) : null,
        famigliaNumerosa: req && req.famigliaNumerosa ? UtilsStringService.strToBool(req.famigliaNumerosa) : null,
        animali: req && req.animali ? UtilsStringService.strToBool(req.animali) : null,
        isWlAgenzia: req && req.isWlAgenzia ? UtilsStringService.strToBool(req.isWlAgenzia) : 0,
        idTo: req && req.idTo ? Number(req.idTo) : -1,
        idSelezione: req && req.idSelezione ? Number(req.idSelezione) : -1,
        idDestinazioneTop: req && req.idDestinazioneTop ? Number(req.idDestinazioneTop) : -1
      } || null
    };
    console.dir(routeSnap);
    let cacheSearch = routeSnap['_routerState'] && routeSnap['_routerState'].url ? this.cacheSrv.getCacheData(routeSnap['_routerState'].url) : null;
    return cacheSearch ? cacheSearch : this.ricercaVacanzeSrv.ricercaVacanze(reqObj)
      .catch(err => {
        return err;
      });
  }


}
