import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SearchResultsViewMode} from "../../model/search-results/searchResultsViewMode.model";
import {ResultCard} from "../../model/search-results/resultCard.model";
import {ResultCardConfig} from "../../model/search-results/resultCardConfig.model";
import {ResponseCheckerService} from "../../services/common/response.checker.service";
import {LabelType, Options} from "ng5-slider";
import {LoaderService} from "../../components/loader/loader.service";
import {UtilsStringService} from "../../services/common/utils-string.service";
import {RicercaVacanzeService} from "../../services/gate/ricerca-vacanze.service";
import {Subscription} from "rxjs";
import {ModalService} from "../../services/common/modal.service";
import {SearchEngineService} from "../../components/search-egine/search-engine.service";
import {ImageUtilService} from "../../services/common/image.util.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {RESPONSIVE_VALUE} from "../../constants/const.service";
import {GmapService} from "../../services/common/gmap.service";
import {AggregaPuntiService} from "../../services/common/aggregaPunti.service";
import Timeout = NodeJS.Timeout;
import {LatLng, LatLngBounds} from "@agm/core";
import {CacheService} from "../../services/common/cache.service";
import * as moment from "moment";

const MAX_MAP_ZOOM = 19;
declare var google: any;

@Component({
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() mode: SearchResultsViewMode;

  public resultCardConfig: ResultCardConfig;
  public searchResults: Array<any>;
  public showFilters: boolean;

  // mock
  public searchResultsVacanze: any;
  public queryParams: any;
  public destFilters: any;
  public depFilters: any;
  public nightFilters: any;
  public selectedNights = [];
  public selectedDest = [];
  public selectedDep = [];
  public initialResults;
  public showTipologia: {
    isTrasferimentiCollettivi: boolean,
    isZeroPensieri: boolean,
    isBimbiGratis: boolean,
    isFamigliaNumerosa: boolean,
    isAnimali: boolean,
    isSoloCoppie: boolean,
    isSingle: boolean,
    isNoFuel: boolean,
    isLastMinute: boolean
  };
  public zeroPensieriSelected = false;
  public offertaPrimeSelected = false;
  public offertaBimbiSelected = false;
  public famigliaExtraSelected = false;
  public animaliAccettatiSelected = false;
  public soloCoppieSelected = false;
  public singleSelected = false;
  public lastMinuteSelected = false;
  public categoryFilters: any;
  public selectedCategories = [];
  public tourOperatorFilters: any;
  public selectedTourOperators = [];
  public serviceFilters: any;
  public selectedService = [];
  public subscription: Subscription;
  public subscriptionEngine: Subscription;
  public cardReduce = false;
  public isMobileDevice: boolean;
  public minPrice = 0;
  public maxPrice = 0;
  public toggleSelected;
  public sliderOptions: Options;
  public showTestBtns: boolean = false;
  public showSearchEngine: boolean = false;
  public desktopRiepilogo;
  public stopFixed;
  public mobileStickyHeader: boolean;
  public dBlock: boolean;
  private windowWidth: any;
  private eleTopponeWidth: any;
  private eleContainerWidth: any;
  public map: {
    show: boolean, bounds: any,
    zoom: number,
    markerSelected: any,
    streetViewInstance: any,
    idTimeout1: Timeout,
    idTimeout2: Timeout,
    markers: Array<any>,
    markersNotInBounds: any,
    googleMapInstance: any,
    options: any
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = this.deviceService.isMobile() || window.innerWidth <= RESPONSIVE_VALUE.MOBILE_DEVICE;
    this.showFilters = !this.isMobileDevice;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.isMobileDevice && !this.map.show) {
      let eleToppone = this.elementRef.nativeElement.querySelector('.sidebar-left');
      let eleContainer = this.elementRef.nativeElement.querySelector('.search-results');
      const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.windowWidth = window.innerWidth;
      this.eleTopponeWidth = this.elementRef.nativeElement.querySelector('.sidebar-left').offsetWidth;
      this.eleContainerWidth = this.elementRef.nativeElement.querySelector('.main-container').offsetWidth;
      if ((this.eleContainerWidth + this.eleTopponeWidth) <= this.windowWidth) {
        this.desktopRiepilogo = scrollOffset >= 700 && scrollOffset <= (eleContainer.scrollHeight - eleToppone.offsetHeight);
        this.stopFixed = scrollOffset >= (eleContainer.scrollHeight - eleToppone.offsetHeight);
        if (!this.desktopRiepilogo && !this.stopFixed) {
          this.dBlock = false;
        } else {
          this.dBlock = true;
        }
      } else {
        this.dBlock = false;
      }
      if (this.searchResults.length < 8) {
        this.filterStopFixed();
      }
    }
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.mobileStickyHeader = scrollOffset >= 60;
  }

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private ricercaVacanzeSrv: RicercaVacanzeService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private searchEngineSrv: SearchEngineService,
    private aggregaPuntiSrv: AggregaPuntiService,
    private modalSrv: ModalService,
    private gMapSrv: GmapService,
    private cacheSrv: CacheService,
    private loaderSrv: LoaderService
  ) {

    this.map = {
      show: false,
      zoom: 14,
      bounds: undefined,
      markerSelected: {data: {}, infoWindowIsOpen: false},
      streetViewInstance: {},
      markersNotInBounds: {},
      googleMapInstance: {},
      idTimeout1: null,
      idTimeout2: null,
      markers: [],
      options: GmapService.getDefaultOptionsMap()
    };

    this.mode = this.mode ? this.mode : new SearchResultsViewMode({
      isVacanze: true,
      isCrociere: false,
    });

    if (this.route) {
      if (ResponseCheckerService.isSuccessResponse(this.route.snapshot.data.listRisultati.body)) {
        this.cacheSrv.setCacheData(this.route.snapshot['_routerState'].url, this.route.snapshot.data.listRisultati);
        this.initComponent(this.route.snapshot.data.listRisultati.body.data, true);
      } else {
        this.modalSrv.openErrorDialog();
      }
    }
    this.isMobileDevice = this.deviceService.isMobile();
    this.stopFixed = false;
    this.desktopRiepilogo = false;
    this.dBlock = false;
  }


  ngOnInit() {
    this.init();
    this.toggleSelected = "list";
    this.subscriptionEngine = this.searchEngineSrv.searchState.subscribe(
      (state) => {
        if (state) {
          if (this.isMobileDevice) {
            this.showSearchEngine = false;
            this.showFilters = false;
          }
        }
      }
    )
    this.loaderSrv.setSearchVacanza();
  }

  private filterStopFixed() {
    this.desktopRiepilogo = false;
    this.stopFixed = false;
    this.dBlock = false;
  }

  public actionMarker(markerCliked) {
    let zoomIn = false;
    this.map.markerSelected.infoWindowIsOpen = false;
    this.map.markerSelected.data = {};
    if (markerCliked.arrIdxElem.length > 1) {
      let tempZoom = this.aggregaPuntiSrv.getZoomMapToBlastPointer(markerCliked, this.map.googleMapInstance.zoom, MAX_MAP_ZOOM);
      tempZoom = tempZoom >= MAX_MAP_ZOOM ? MAX_MAP_ZOOM : tempZoom;
      this.map.googleMapInstance.setZoom(tempZoom);

    } else {
      clearTimeout(this.map.idTimeout1);
      clearTimeout(this.map.idTimeout2);
      if (this.map.googleMapInstance.zoom < this.aggregaPuntiSrv.getZoomMapShowIcon()) {
        this.map.googleMapInstance.setZoom(this.aggregaPuntiSrv.getZoomMapShowIcon());
        zoomIn = true;
      }
      let markerTemp = this.aggregaPuntiSrv.getEdrData(markerCliked.arrIdxElem);
      markerTemp[0].linkParteza = '/offerta/' + UtilsStringService.removeSpecialChar(markerTemp[0].nazione || "nazione") + '/' + UtilsStringService.removeSpecialChar(markerTemp[0].destinazione || "destinazione") + '/' +
        UtilsStringService.removeSpecialChar(markerTemp[0].nomeStruttura || "struttura") + '/' + UtilsStringService.removeSpecialChar(markerTemp[0].luogoPartenza || "partenza-da") + '/' + this.getDataPUrl(markerTemp[0].dataPartenza)
        + '/p/' + markerTemp[0].idPartenza;
      /*In caso di zoom in imposto un timeout cosi da aprire l'infowindow dopo l'animazione di zoom*/
      if (zoomIn) {
        this.map.idTimeout1 = setTimeout(() => {
          /*Recupero l'istanza del marker dopo lo zoom e il riposizionamento dei marker con icona singola (non cluster)*/
          this.map.markerSelected = this.getMarkerInstanceFromMarkerCliked(markerCliked);
          this.map.markerSelected.data = markerTemp[0];
          this.map.markerSelected.infoWindowIsOpen = false;
        }, 350);

        this.map.idTimeout2 = setTimeout(() => {
          this.map.markerSelected.infoWindowIsOpen = true;
        }, 700);
      } else {
        this.map.idTimeout1 = setTimeout(() => {
          this.map.markerSelected = markerCliked;
          this.map.markerSelected.data = markerTemp[0];
          this.map.markerSelected.infoWindowIsOpen = true;
        }, 100);

      }
    }
    this.map.googleMapInstance.setCenter({lat: markerCliked.coords.latitude, lng: markerCliked.coords.longitude});
  }

  public eventZoomChange(zoom) {
    this.eventChange(zoom);
  }


  public actionCloseInfoWindow($event) {
    this.map.markerSelected.infoWindowIsOpen = false;
  }

  private eventChange(zoom) {
    this.map.markers = this.aggregaPuntiSrv.visualizzaMarkersGrouped(zoom);
  }


  public getDataPUrl(paramDate) {
    return moment(paramDate).format('DD-MMMM');
  }

  private getMarkerInstanceFromMarkerCliked(markerCliked) {
    let ret = undefined;
    this.map.markers.map((currentMarker) => {
      if (currentMarker.idGroup == markerCliked.idGroup) {
        ret = currentMarker;
      }
    });
    return ret;
  }


  private onFitContents(markers: Array<any>) {
    let bounds: LatLngBounds = new google.maps.LatLngBounds();
    for (let marker of markers) {
      let point: LatLng = new google.maps.LatLng(marker.coords.latitude, marker.coords.longitude);
      bounds.extend(point);
    }
    this.map.bounds = bounds;
  }

  public mapReady(event) {
    this.map.googleMapInstance = event;
    this.map.streetViewInstance = this.map.googleMapInstance.getStreetView();
    this.onFitContents(this.map.markers);
    // this.loaderSrv.hide();
    this.loaderSrv.hideSearchVacanza();
    setTimeout(() => {
      google.maps.event.addListener(this.map.streetViewInstance, 'visible_changed', () => {
        if (this.map.streetViewInstance.getVisible()) {
          let sv = new google.maps.StreetViewService();
          sv.getPanorama({
            location: new google.maps.LatLng(this.map.markerSelected.coords.latitude, this.map.markerSelected.coords.longitude),
            radius: 50
          }, (data, status) => {
            if (status === google.maps.StreetViewStatus.OK) {
              let markerLan = {
                lat: this.map.markerSelected.coords.latitude,
                lng: this.map.markerSelected.coords.longitude
              };

              this.map.streetViewInstance.setPov({
                heading: google.maps.geometry.spherical.computeHeading(data.location.latLng, new google.maps.LatLng(markerLan.lat, markerLan.lng)),
                pitch: 0
              });
            }
          });
        }
      });

    }, 0);
  }


  private initComponent(paramData, firstLoad) {
    this.searchResultsVacanze = paramData || null;
    this.map.show = false;
    this.toggleSelected = "list";
    if (this.resultCardConfig) {
      this.resultCardConfig.vertical = false;
    }
    this.queryParams = this.route.snapshot.queryParams;
    this.searchResults = [];
    this.searchResultsVacanze.forEach((el) => {
      this.searchResults.push(this.makeResultCardVacanze(el));
    });
    this.destFilters = this.generateDestArray();
    this.depFilters = this.generateDepArray();
    this.nightFilters = this.generateNightArray();
    this.categoryFilters = this.getCategoryFilters();
    this.tourOperatorFilters = this.getTourOperatorsFilters();
    this.serviceFilters = this.getServiceTypeFilters();
    this.initialResults = Object.assign([], this.searchResults);
    this.getMinMaxPrice();
    this.showTipologia = {
      isAnimali: this.showFilter('isAnimali'),
      isBimbiGratis: this.showFilter('isBimbiGratis'),
      isFamigliaNumerosa: this.showFilter('isFamigliaNumerosa'),
      isSingle: this.showFilter('isSingle'),
      isLastMinute: this.showFilter('isLastMinute'),
      isNoFuel: this.showFilter('isNoFuel'),
      isTrasferimentiCollettivi: this.showFilter('isTrasferimentiCollettivi'),
      isSoloCoppie: this.showFilter('isSoloCoppie'),
      isZeroPensieri: this.showFilter('isZeroPensieri'),
    };
    if (firstLoad) {
      setTimeout(() => {
          this.subscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
              this.rqRicerca().then(
                (resp) => {
                  if (resp.body && resp.body.data) {
                    this.initComponent(resp.body.data, false);
                  }
                },
                () => {
                }
              ).finally(
                () => {
                  // this.loaderSrv.hide();
                  this.loaderSrv.hideSearchVacanza();
                }
              );
            }
          });
        }, 0
      )
    }
  }

  public init() {
    this.eleTopponeWidth = this.elementRef.nativeElement.querySelector('.sidebar-left').offsetWidth;
    this.eleContainerWidth = this.elementRef.nativeElement.querySelector('.main-container').offsetWidth;
    this.windowWidth = window.innerWidth;
    if ((this.eleContainerWidth + this.eleTopponeWidth) <= this.windowWidth) {
      this.dBlock = true;
    }
    this.mode = this.mode ? this.mode : new SearchResultsViewMode({
      isVacanze: true,
      isCrociere: false,
    });

    this.resultCardConfig = new ResultCardConfig({
      vertical: false,
      isVacanze: this.mode.isVacanze,
      isCrociere: this.mode.isCrociere,
      isListaPrenotazioni: false,
      isListaDesideri: false
    });

    this.showFilters = !this.isMobileDevice;
  }

  private rqRicerca() {
    setTimeout(
      () => {
        this.loaderSrv.showSearchVacanza();
        // this.loaderSrv.show();
      }, 0
    );
    let req = this.route.snapshot.queryParams;
    let reqObj = {
      dataPartenza: req && req.dataPartenza || null,
      listaDestinazioni: req && UtilsStringService.makeDestinazioniArray(req.destinazione, req.destinazioneNazione) || null,
      listaLuoghiPartenza: req && UtilsStringService.makeIdArray(req.partenzaDa) || null,
      supplementi: req && {
        isPrime: req.isPrime,
        numeroAdulti: req.adulti,
        numeroBambini: req.bambini,
        etaBambini: req && req.bambini && req.etaBambini ? UtilsStringService.makeIdArray(req.etaBambini) : null || null
      } || null,
      tolleranza: 3,
      isAgenzia: 0,
      filtri: req && {
        lastminute: req && req.lastminute ? UtilsStringService.strToBool(req.lastminute) : null,
        single: req && req.single ? UtilsStringService.strToBool(req.single) : null,
        bimbiGratis: req && req.bimbiGratis ? UtilsStringService.strToBool(req.bimbiGratis) : null,
        prenotaPrima: req && req.prenotaPrima ? UtilsStringService.strToBool(req.prenotaPrima) : null,
        natale: req && req.natale ? UtilsStringService.strToBool(req.natale) : null,
        capodanno: req && req.capodanno ? UtilsStringService.strToBool(req.capodanno) : null,
        zeroPensieri: req && req.zeroPensieri ? UtilsStringService.strToBool(req.zeroPensieri) : null,
        famigliaNumerosa: req && req.famigliaNumerosa ? UtilsStringService.strToBool(req.famigliaNumerosa) : null,
        animali: req && req.animali ? UtilsStringService.strToBool(req.animali) : null,
        isWlAgenzia: req && req.isWlAgenzia ? UtilsStringService.strToBool(req.isWlAgenzia) : 0,
        idTo: req && req.idTo ? parseInt(req.idTo) : -1,
        idSelezione: req && req.idSelezione ? parseInt(req.idSelezione) : -1,
        idDestinazioneTop: req && req.idDestinazioneTop ? parseInt(req.idDestinazioneTop) : -1
      } || null
    };

    return this.ricercaVacanzeSrv.ricercaVacanze(reqObj);
  }

  public toggleShowFilters(event) {
    this.showFilters = !this.showFilters;
    if (this.showFilters && this.isMobileDevice) {
      document.querySelector('body').classList.add("overflow-hidden");
    } else if (this.isMobileDevice) {
      document.querySelector('body').classList.remove("overflow-hidden");
    }
    let chatElement = document.querySelector('#chat-widget-container');
    if (chatElement) {
      chatElement['style'].display = this.showFilters ? 'none' : 'block';
    }
  }

  public makeResultCardVacanze(obj: any) {
    this.isMobileDevice = this.deviceService.isMobile();
    let fotoOrdered = obj.fotoOfferta ? ImageUtilService.ordinaFotoArray(obj.fotoOfferta) : [];
    let note = obj.noteTitolo ? obj.noteTitolo : null;
    return new ResultCard({
      title: (obj && obj.nomeStruttura) + (note ? ' - ' + note : '') || null,
      subtitle: obj && obj.destinazione || null,
      nomeStruttura: obj && obj.nomeStruttura || null,
      categoria: obj && obj.categoria || null,
      destinazione: obj && obj.destinazione || null,
      price: obj && obj.costoMinimo || null,
      totalPrice: obj && obj.costoMinimo || null,
      luogoPartenza: obj && obj.luogoPartenza || null,
      dataPartenza: obj && obj.dataPartenza || null,
      nazione: obj && obj.nazione,
      tourOperator: obj && obj.tourOperator || null,
      notti: obj && obj.notti,
      tipoOfferta: obj && obj.trattamento,
      isTrasferimentiCollettivi: obj && obj.isTrasferimentiCollettivi,
      bonusIcons: '',
      isOffertaPrime: obj && (obj.isPartenzaPrime || obj.isPricingPrime) || false,
      photos: this.isMobileDevice && fotoOrdered.length > 4 ?
        fotoOrdered.splice(0, 4) : fotoOrdered.splice(0, 10),
      idPartenza: obj && obj.idPartenza,
      isPreferito: obj && obj.isPreferito,
      isAnimali: obj && obj.isAnimali,
      isBimbiGratis: obj && obj.isBimbiGratis,
      listaServiziPrime: obj && obj.listaServiziPrime,
      isFamigliaNumerosa: obj && obj.isFamigliaNumerosa,
      isFuoriTutto: obj && obj.isFuoriTutto,
      iconaPromozione: obj && obj.iconaPromozione,
      nomePromozione: obj && obj.nomePromozione,
      isInEvidenza: obj && obj.isInEvidenza,
      isLastMinute: obj && obj.isLastMinute,
      isNoFuel: obj && obj.isNoFuel,
      isOffSpeciale: obj && obj.isOffSpeciale,
      isOZeroPensieri: obj && obj.isOZeroPensieri,
      isPrenotaPrima: obj && obj.isPrenotaPrima,
      isPricingInEvidenza: obj && obj.isPricingInEvidenza,
      isPZeroPensieri: obj && obj.isPZeroPensieri,
      isPartenzaLive: obj && obj.isPartenzaLive,
      isSingle: obj && obj.isSingle,
      isSoloCoppie: obj && obj.isSoloCoppie,
      isZeroPensieri: obj && obj.isZeroPensieri,
      iconaLuogoPartenza: obj && obj.iconaLuogoPartenza,

      prezzoCabinaInterna: obj && obj.prezzoCabinaInterna,
      prezzoCabinaEsterna: obj && obj.prezzoCabinaEsterna,
      prezzoCabinaConBalcone: obj && obj.prezzoCabinaConBalcone,
      prezzoSuite: obj && obj.prezzoSuite,
      iconaPricing: obj && obj.iconaPricing,
      iconaPricingWebP: obj && obj.iconaPricingWebP
    })
  }

  public switchCardsLayout(flag) {
    this.resultCardConfig.vertical = flag;
    this.map.show = false;
  }


  public switchMapLayout() {
    this.loaderSrv.showSearchVacanza();
    // this.loaderSrv.show();
    let tempShowMap = !this.map.show;
    if (tempShowMap) {
      this.gMapSrv.geocodeLocation(this.searchResults, (location) => {
        if (this.aggregaPuntiSrv.setUpMarkerAggregation(location) !== "KO") {
          this.map.markers = this.aggregaPuntiSrv.visualizzaMarkersGrouped(this.map.zoom);
          this.filterStopFixed();
          this.map.show = !this.map.show;
        } else {
          this.toggleSelected = "list";
        }
        // this.loaderSrv.hide();
        this.loaderSrv.hideSearchVacanza();
      });
    }

  }

  public cardReduction() {
    this.cardReduce = !this.cardReduce;
  }

  private generateDestArray() {
    let destArray = [];
    this.searchResults.forEach((elem) => {
      if (destArray.indexOf(elem.destinazione) == -1) {
        destArray.push(elem.destinazione);
      }
    });

    let destFilters = [];
    destArray.forEach(category => {
      destFilters.push({
        value: category,
        label: category,
        isSelected: false
      })
    });
    return destFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  private generateDepArray() {
    let depArray = [];
    this.searchResults.forEach((elem) => {
      let dateTime = moment(elem.dataPartenza);
      let date = dateTime.format('YYYY-MM-DD');
      if (depArray.indexOf(date) == -1) {
        depArray.push(date);
      }
    });

    let depFilters = [];
    depArray.forEach(category => {
      depFilters.push({
        value: category,
        label: category,
        isSelected: false
      })
    });
    return depFilters.sort((dateA, dateB) => {
      return new Date(dateA.value).getTime() - new Date(dateB.value).getTime();
    });
    /*return depFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);*/
  }

  private generateNightArray() {
    let nightArray = [];
    this.searchResults.forEach((elem) => {
      if (nightArray.indexOf(elem.notti) == -1) {
        nightArray.push(elem.notti);
      }
    });

    let nightFilters = [];
    nightArray.forEach(night => {
      nightFilters.push({
        value: night,
        label: night,
        isSelected: false
      })
    });
    return nightFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }


  public actionSliderPrice() {
    let filteredResults = [];
    this.searchResultsVacanze.filter(item => (item.costoMinimo >= this.minPrice && item.costoMinimo <= this.maxPrice)).forEach(fEl => {
      filteredResults.push(this.makeResultCardVacanze(fEl));
    });
    this.searchResults = Object.assign([], filteredResults);
    if (this.map.show) {
      if (this.aggregaPuntiSrv.setUpMarkerAggregation(this.searchResults) !== "KO") {
        this.map.markers = this.aggregaPuntiSrv.visualizzaMarkersGrouped(this.map.zoom);
      }
    }
  }

  public toggleCheckBox() {
    this.loaderSrv.showSearchVacanza();
    // this.loaderSrv.show();

    //retrieve selected filters
    this.selectedDest = this.destFilters.filter(f => f.isSelected);
    this.selectedDep = this.depFilters.filter(f => f.isSelected);
    this.selectedCategories = this.categoryFilters.filter(f => f.isSelected);
    this.selectedNights = this.nightFilters.filter(f => f.isSelected);
    this.selectedTourOperators = this.tourOperatorFilters.filter(f => f.isSelected);
    this.selectedService = this.serviceFilters.filter(f => f.isSelected);

    //reset search results to initial results
    this.searchResults = Object.assign([], this.initialResults);

    //Destinations filters - if some filters are selected, it starts from initial results this.searchResults = this.initialResults
    if (this.selectedDest.length > 0) {
      let filteredResults = [];
      this.selectedDest.forEach((dest) => {
        this.searchResults.filter(item => item.destinazione === dest.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Departures filters - if some filters are selected, it starts from previously filtered list this.searchResults
    if (this.selectedDep.length > 0) {
      let filteredResults = [];
      this.selectedDep.forEach((dep) => {

        let filterDate = moment(dep.label);

        this.searchResults.filter(item => {
          let searchDate = moment(item.dataPartenza);
          return searchDate.format('YYYY-MM-DD') === filterDate.format('YYYY-MM-DD');
        }).forEach(fEl => {
          filteredResults.push(fEl);
        });
      });
      this.searchResults = Object.assign([], filteredResults);

    }

    //Category filters
    if (this.selectedCategories.length > 0) {
      let filteredResults = [];
      this.selectedCategories.forEach((cat) => {
        this.searchResults.filter(item => item.categoria === cat.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Nights filters
    if (this.selectedNights.length > 0) {
      let filteredResults = [];
      this.selectedNights.forEach((night) => {
        this.searchResults.filter(item => item.notti === night.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Tour Operator filters
    if (this.selectedTourOperators.length > 0) {
      let filteredResults = [];
      this.selectedTourOperators.forEach((to) => {
        this.searchResults.filter(item => item.tourOperator === to.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Service Type filters  (All Inclusive, Soft All Inclusive...)
    if (this.selectedService.length > 0) {
      let filteredResults = [];
      this.selectedService.forEach((service) => {
        this.searchResults.filter(item => item.tipoOfferta === service.label).forEach((fEl) => {
          filteredResults.push(fEl);
        });
      });

      this.searchResults = Object.assign([], filteredResults);
    }

    //Zero Pensieri filter
    if (this.zeroPensieriSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isZeroPensieri).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Offerta Prime filter
    if (this.offertaPrimeSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isOffertaPrime).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Offerta Bimbi filter
    if (this.offertaBimbiSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isBimbiGratis).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Famiglia Extra filter
    if (this.famigliaExtraSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isFamigliaNumerosa).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Animali Accettati filter
    if (this.animaliAccettatiSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isAnimali).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Solo Coppie filter
    if (this.soloCoppieSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isSoloCoppie).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Single filter
    if (this.singleSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isSingle).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Last Minute filter
    if (this.lastMinuteSelected) {
      let filteredResults = [];
      this.searchResults.filter(item => item.isLastMinute).forEach(fEl => {
        filteredResults.push(fEl);
      });
      this.searchResults = Object.assign([], filteredResults);
    }

    //Budget filter
    this.minPrice = Math.min.apply(null, this.searchResults.map(elem => elem.totalPrice));
    this.maxPrice = Math.max.apply(null, this.searchResults.map(elem => elem.totalPrice));
    // this.loaderSrv.hide();
    this.loaderSrv.hideSearchVacanza();

    if (this.searchResults.length < 8) {
      this.filterStopFixed();
    }
    if (this.map.show) {
      if (this.aggregaPuntiSrv.setUpMarkerAggregation(this.searchResults) !== "KO") {
        this.map.markers = this.aggregaPuntiSrv.visualizzaMarkersGrouped(this.map.zoom);
      }
    }

    window.scrollTo(0, 0);
  }

  private getMinMaxPrice() {
    this.minPrice = Math.min.apply(null, this.searchResults.map(elem => elem.totalPrice));
    this.maxPrice = Math.max.apply(null, this.searchResults.map(elem => elem.totalPrice));
    this.sliderOptions = {
      floor: this.minPrice,
      ceil: this.maxPrice,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '€' + value;
          case LabelType.High:
            return '€' + value;
          default:
            return "";
        }
      }
    };
  }

  private getCategoryFilters() {
    let categoryArray = [];
    this.searchResults.forEach((elem) => {
      if (categoryArray.indexOf(elem.categoria) == -1) {
        categoryArray.push(elem.categoria);
      }
    });

    let categoryFilters = [];
    categoryArray.forEach(category => {
      categoryFilters.push({
        value: category,
        label: category,
        isSelected: false
      })
    });
    return categoryFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  private getTourOperatorsFilters() {
    let opratorsArray = [];
    this.searchResults.forEach((elem) => {
      if (opratorsArray.indexOf(elem.tourOperator) == -1) {
        opratorsArray.push(elem.tourOperator);
      }
    });

    let tourOperatorsFilters = [];
    opratorsArray.forEach(to => {
      tourOperatorsFilters.push({
        value: to,
        label: to,
        isSelected: false
      })
    });
    return tourOperatorsFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  private getServiceTypeFilters() {
    let serviceArray = [];
    this.searchResults.forEach((elem) => {
      if (serviceArray.indexOf(elem.tipoOfferta) == -1) {
        serviceArray.push(elem.tipoOfferta);
      }
    });

    let serviceFilters = [];
    serviceArray.forEach(serviceType => {
      serviceFilters.push({
        value: serviceType,
        label: serviceType,
        isSelected: false
      })
    });
    return serviceFilters.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  public showFilter(filter) {
    return this.searchResults.find(currVacanza => currVacanza[filter]);
  }

  public toggleShowSearchEngine() {
    this.showSearchEngine = !this.showSearchEngine;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionEngine) {
      this.subscriptionEngine.unsubscribe();
    }
  }

}
