<div class="sidebar-left">
  <div class="head">
    Filtra la tua ricerca
  </div>
  <div class="body">

    <!-- NGFOR ELEM -->
    <div class="elem">
      <div class="col-12">
        <div class="title-wrapper">
          <div class="title"><!--{{data.filterCategory}}-->
            <!-- Luogo partenza -->
          </div>
          <div class="arrow rotate-90"><img class="arrow" src="assets/img/icons/result-card/next.svg"></div>
        </div>

        <!-- NGFOR ENTRY -->
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label"><!--{{data.filter}}-->
            <!-- Roma -->
          </div>
        </div>

        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Roma</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Milano</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Palermo</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Torino</div>
        </div>
      </div>
    </div>

    <div class="elem">
      <div class="col-12">
        <div class="title-wrapper">
          <div class="title">Data partenza</div>
          <div class="arrow">V</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">09 febbraio 2020</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">10 febbraio 2020</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">11 febbraio 2020</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">12 febbraio 2020</div>
        </div>
      </div>
    </div>

    <div class="elem">
      <div class="col-12">
        <div class="title-wrapper">
          <div class="title">Destinazione</div>
          <div class="arrow">V</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">New York</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Playa del Ca</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Cancun</div>
        </div>
        <div class="entry">
          <!-- da ripetere -->
          <div class="checkbox">
            <input type="checkbox">
          </div>
          <div class="label">Mauritius</div>
        </div>
      </div>
    </div>
  </div>
</div>
