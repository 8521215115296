<div class="container border-r-2px mt-50px pt-10px background-white mb-20px pb-20px">
  <div class="row">
    <div class="col">
      <h1 class="color-text-blue-borsaviaggi mt-5px mb-5px text-align-center">Lampada dei desideri</h1>
      <p class="subtitle">Il nostro “genio” cercherà per te la vacanza che desideri con le caratteristiche che ci
        indichi e,<br/>non appena sarà disponibile un'offerta corrispondente ai tuoi desideri, te la invierà per email.
      </p>
    </div>
  </div>
  <img class="mb-10px immagine-lampada" src="assets/img/testata-lampada.jpg" alt="lampada desideri">
  <!--div class="row">
    <div class="col sezione">
      <div class="mb-10px">
        <img class="immagine-lampada" src="assets/img/testata-lampada.jpg" alt="lampada desideri">
      </div>
    </div>
  </div-->
  <div class="row">
    <div class="col mt-20px">
      <h2 class="color-text-blue-borsaviaggi">Compila i campi qui di seguito:</h2>
    </div>
  </div>
  <div>
    <form [formGroup]="formGroup">
      <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Nome</mat-label>
        <input type="text" matInput formControlName="nome" required>
        <mat-error *ngIf="formGroup.get('nome').invalid">
          <div>Il campo nome è obbligatorio</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Cognome</mat-label>
        <input type="text" matInput formControlName="cognome" required>
        <mat-error *ngIf="formGroup.get('cognome').invalid">
          <div>Il campo cognome è obbligatorio</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>La tua email</mat-label>
        <input type="email" matInput formControlName="email" required>
        <mat-error *ngIf="formGroup.get('email').invalid">
          <div *ngIf="formGroup.get('email').hasError('required'); else errorEmail">
            Il campo email è obbligatorio
          </div>
          <ng-template #errorEmail>
            Email non valida
          </ng-template>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Numero cellulare</mat-label>
        <input type="text" matInput formControlName="cellulare">
        <mat-error *ngIf="formGroup.get('cellulare').invalid">
          <div>Numero cellulare non valido</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6" id="destinazione" appearance="outline">
        <mat-label>Destinazione</mat-label>
        <mat-select (selectionChange)="onChangeDestinazione($event)" formControlName="destinazione" required>
          <mat-option *ngFor="let destinazione of listaDestinazioni" [value]="destinazione.idDestinazione">
            {{destinazione.nomeDestinazione}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6" id="localita" appearance="outline">
        <mat-label>Localita</mat-label>
        <mat-select (selectionChange)="onChangeLocalita($event)" formControlName="localita">
          <mat-option *ngFor="let localita of listaLocalita" [value]="localita.idDestinazione">
            {{localita.nomeDestinazione}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6" appearance="outline" (click)="picker.open()">
        <mat-label>Periodo di Partenza Dal - Al</mat-label>
        <mat-date-range-input [rangePicker]="picker" [min]="minDate">
          <input matStartDate placeholder="Dal" formControlName="dataPartenzaDa">
          <input matEndDate placeholder="Al" formControlName="dataPartenzaAl">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="formGroup.get('dataPartenzaDa').invalid">
          <div>Il campo Data Partenza è obbligatorio</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Partenza da</mat-label>
        <mat-select multiple formControlName="partenzaDa" [(value)]="selectedOptions"
                    (selectionChange)="setSelectTextbox()" required>
          <mat-select-trigger>{{selectedOptionsView}}</mat-select-trigger>
          <mat-option *ngFor="let option of optionsForMSelect" [value]="option">
            <mat-icon>{{option.type}}</mat-icon>
            <span>{{option.name}}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('partenzaDa').invalid">
          <div>Il campo Partenza Da è obbligatorio</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6" id="budget" appearance="outline">
        <mat-label>Budget</mat-label>
        <mat-select (selectionChange)="onChangeBudget($event)" formControlName="budget">
          <mat-option *ngFor="let budget of budgetOptions" [value]="budget.id">
            {{budget.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--<mat-form-field class="col-md-6" id="frequenza" appearance="outline">
        <mat-label>Frequenza Ricezione</mat-label>
        <mat-select (selectionChange)="onChangeFrequenza($event)" formControlName="frequenza">
          <mat-option *ngFor="let frequenza of frequenzeOptions" [value]="frequenza.id">
            {{frequenza.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>-->
      <div class="row mt-20px">
        <div class="col">
          <mat-checkbox matInput formControlName="informativaPrivacy">
          </mat-checkbox>
          <span class="ml-5px">Ho letto ed accetto l'<a routerLink="/informativa-privacy">Informativa Privacy</a> in merito al trattamento dei miei dati personali.</span>
        </div>
      </div>
    </form>
  </div>
  <div class="row mt-40px">
    <div class="col mr-20px text-align-center">
      <borsaviaggi-promise-btn
        type="submit"
        [loading]="loading"
        buttonText="Crea Avviso"
        (buttonClick)="creaAvviso()"
        [disabled]="!formGroup.valid"
        buttonClass=" no-gutters padding-0 background-blue-borsaviaggi color-white">

      </borsaviaggi-promise-btn>
    </div>
  </div>
</div>

