<div class="float-right appearance-initial"
     *ngIf="onErrorUpdate"
     [mat-dialog-close]="onSuccessUpdate">
  <i class="material-icons">close</i>
</div>
<div>
  <div class="display-flex button-close">
    <div *ngIf="onSuccessUpdate"
         class="appearance-initial"
         [mat-dialog-close]="{action:'1'}">
      <i class="material-icons">close</i>
    </div>
  </div>
</div>

<div class="modal-wrapper">
  <div class="header-modal">
  </div>
  <mat-dialog-content [ngClass]="{'successUpdate':onSuccessUpdate || onErrorUpdate}">
    <span [hidden]="onSuccessUpdate || onErrorUpdate">{{confirmData.body_message}}</span>
    <div class="sweet-alert" [hidden]="!onSuccessUpdate ">
      <div class="sa-icon sa-success animate" style="display: block;"><span
        class="sa-line sa-tip animateSuccessTip"></span> <span class="sa-line sa-long animateSuccessLong"></span>
        <div class="sa-placeholder"></div>
        <div class="sa-fix"></div>
      </div>
      <div class="message-container text-align-center">
        <span>{{onSuccessUpdate}}</span>
      </div>
    </div>
    <div class="sweet-alert" [hidden]="!onErrorUpdate">
      <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
						<span class="sa-x-mark animateXMark"> <span
              class="sa-line sa-left"></span> <span class="sa-line sa-right"></span>
						</span>
      </div>
      <div class="message-error-container text-align-center">
        <span>{{onErrorUpdate}}</span>
      </div>
    </div>
  </mat-dialog-content>
</div>
