<div mat-dialog-close class="appearance-initial float-right">
  <i class="material-icons">close</i>
</div>
<div class="header-modal mb-10px">
  <div class="d-flex align-items-center">
    <span mat-dialog-title class="inline margin-0">Registrati</span>
  </div>
</div>
<div *ngIf="data && data.dataParam?.banner">
  <img class="full-width" [src]="data.dataParam?.banner">
</div>
<div class="mat-dialog-content">
  <p *ngIf="isPrimePage" class="testo-registrazione-prime">
    Con PRIME accedi ad un Nuovo Livello di Servizio!<br>
    Attiva subito il tuo abbonamento per avere il massimo dei vantaggi!
  </p>
</div>
<mat-dialog-content>
  <borsaviaggi-loader [(show)]="loading" typeMode='piker'></borsaviaggi-loader>
  <form [formGroup]="formGroup" [hidden]="loading">
    <div class="form-group row">
      <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Nome" required name="nome" formControlName="nome" autocomplete="off"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.nome"
                       [formControlParam]="formGroup.controls.nome"></bv-form-error>
      </mat-form-field>
      <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Cognome</mat-label>
        <input matInput placeholder="Cognome" required name="cognome" formControlName="cognome" autocomplete="off"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.cognome"
                       [formControlParam]="formGroup.controls.cognome"></bv-form-error>
      </mat-form-field>
    </div>
    <div class="form-group row">
      <mat-form-field class="col-md-6  col-sm-12" appearance="outline" (click)="picker.open()">
        <mat-label>Data di Nascita</mat-label>
        <input formControlName="dataNascita"
               autocomplete="off"
               required
               matInput
               [matDatepicker]="picker"
               class="mb-10px-impo">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <bv-form-error [formErrorMessage]="validation_messages.dataNascita"
                       [formControlParam]="formGroup.controls.dataNascita"></bv-form-error>
      </mat-form-field>

      <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" required type="email" name="email" formControlName="email"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.email"
                       [formControlParam]="formGroup.controls.email"></bv-form-error>
      </mat-form-field>
    </div>
    <div class="form-group row">
      <mat-form-field name="select" required class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Sesso</mat-label>
        <mat-select required formControlName="sesso" class="mb-8px-impo">
          <mat-option value="M">M</mat-option>
          <mat-option value="F">F</mat-option>
        </mat-select>
        <bv-form-error [formErrorMessage]="validation_messages.sesso"
                       [formControlParam]="formGroup.controls.sesso"></bv-form-error>
      </mat-form-field>
      <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Cellulare</mat-label>
        <input required matInput placeholder="Cellulare" type="text" name="cellulare" formControlName="cellulare"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.cellulare"
                       [formControlParam]="formGroup.controls.cellulare"></bv-form-error>
      </mat-form-field>

    </div>
    <div class="form-group row">
      <mat-form-field name="select" class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Regione</mat-label>
        <mat-select required (selectionChange)="changeRegioneEvent($event)" formControlName="idRegione"
                    class="mb-8px-impo">
          <mat-option *ngFor="let option of listRegioni" [value]="option.idRegione">{{option.regione}}</mat-option>
        </mat-select>
        <bv-form-error [formErrorMessage]="validation_messages.idRegione"
                       [formControlParam]="formGroup.controls.idRegione"></bv-form-error>
      </mat-form-field>
      <bv-select
        [data]="listComuni"
        disableClearBtn="true"
        formControlName="idComune"
        labelKey="Comune"
        ordered="Comune"
        placeholder="Comune"
        textAlign="left"
        valueKey="IdComune"
        class="col-md-6 col-sm-12"
        required
      >
      </bv-select>
    </div>
    <div class="form-group row  d-none">
      <mat-form-field class="col-6 " appearance="outline">
        <mat-label>Cap</mat-label>
        <input matInput placeholder="Cap" type="number" name="cap" formControlName="cap" class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.cap"
                       [formControlParam]="formGroup.controls.cap"></bv-form-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Indirizzo</mat-label>
        <input matInput placeholder="Indirizzo" name="indirizzo" formControlName="indirizzo"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.indirizzo"
                       [formControlParam]="formGroup.controls.indirizzo"></bv-form-error>
      </mat-form-field>
    </div>
    <div class="form-group row">
      <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput autocomplete="off" required placeholder="Password" type="password" name="password"
               formControlName="password"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.password"
                       [formControlParam]="formGroup.controls.password"></bv-form-error>
      </mat-form-field>
      <mat-form-field class="col-md-6 d-none" appearance="outline">
        <mat-label>Codice Fiscale</mat-label>
        <input matInput placeholder="CodiceFiscale" name="codiceFiscale" formControlName="codiceFiscale"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.codiceFiscale"
                       [formControlParam]="formGroup.controls.codiceFiscale"></bv-form-error>
      </mat-form-field>
    </div>
    <div class="form-group row">

      <mat-form-field class="col-md-6  d-none" appearance="outline">
        <mat-label>Telefono</mat-label>
        <input matInput placeholder="Telefono" type="number" name="telefono" formControlName="telefono"
               class="mb-8px-impo">
        <bv-form-error [formErrorMessage]="validation_messages.telefono"
                       [formControlParam]="formGroup.controls.telefono"></bv-form-error>
      </mat-form-field>
    </div>
    <div class="form-group row">
      <mat-checkbox formControlName="privacy" class="text-left"
                    (ngModelChange)="autoSetNewsLetter($event)">
        <span class="white-space-normal word-break-word text-left ">
          Ho letto ed
        accetto l'<a routerLink="/informativa-privacy">Informativa Privacy</a> in merito al
        trattamento dei miei
        dati personali
        </span>
      </mat-checkbox>
      <mat-checkbox formControlName="riceviNewsletter"
                    class="text-left">
        <span class="white-space-normal word-break-word text-left">
          Consenso per l'invio di materiale promozionale
        (facoltativo)
        </span>

      </mat-checkbox>
    </div>
  </form>
  <mat-dialog-actions align="center" [hidden]="loading">
    <button *ngIf="!successMessage" [disabled]="!(formGroup.controls['privacy'].value)"
            class="btn btn-primary p-0-5px btn-lg p-2"
            (click)="signUp(formGroup.valid)">
      <span>Registrati</span>
    </button>
  </mat-dialog-actions>
  <div class="alert alert-warning mt-2rem" role="alert" *ngIf="userExist">
    <mat-icon>warning</mat-icon>&nbsp;Attenzione, i dati da te inseriti risultano già presenti nel nostro sistema.<br/>Effettua
    ora il <a class="color-text-orange-borsaviaggi cursor-pointer" (click)="actionRecupero()">Recupero della
    Password</a>.
  </div>
  <div class="alert alert-danger mt-2rem" role="alert" *ngIf="errorRegistrazione">
    <mat-icon>warning</mat-icon>&nbsp;Attenzione, si è verificato un errore durante l'inserimento dei dati.
  </div>
  <p class="mt-4">Sei già registrato? <a class="color-text-orange-borsaviaggi cursor-pointer"
                                         (click)="openLoginModal()">Accedi ora >></a>
  </p>
</mat-dialog-content>
