export class DatiPersonaliModel {

  nome: any;
  cognome: any;
  dataNascita: any;
  idComune: any;
  idRegione: any;
  sesso: any;
  email: any;
  telefono: any;
  cellulare: any;
  indirizzo: any;
  codiceFiscale: any;
  password: any;
  cap: any;
  riceviNewsletter: any;


  constructor( obj : any){
  this.nome = obj && obj.nome || null;
  this.cognome = obj && obj.cognome || null;
  this.dataNascita = obj &&  obj.dataNascita || null;
  this.password = obj &&  obj.password || null;
  this.sesso = obj &&  obj.sesso || null;
  this.email = obj &&  obj.email || null;
  this.idComune = obj && obj.idComune || null;
  this.idRegione = obj && obj.idRegione || null;
  this.indirizzo = obj && obj.indirizzo || null;
  this.cap = obj &&  obj.cap || null;
  this.cellulare = obj && obj.cellulare || null;
  this.codiceFiscale = obj && obj.codiceFiscale || null;
  this.telefono = obj && obj.telefono || null;
  this.riceviNewsletter = obj &&  obj.riceviNewsletter;
  }

}
