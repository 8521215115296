<div *ngFor="let camera of camere; let i = index">
  <div class="bv-form-main-title-container col-12">
    <div class="title">Camera {{camera.numCamera}}</div>
  </div>
  <div *ngFor="let ospite of camera.ospiti; let j = index;">
    <div class="dettagli">
      <div class="title">
        <span *ngIf="ospite === 1 && camera.numCamera === '1'">{{ospite}}) Intestatario Pratica</span>
        <span class="ospite"
              *ngIf="camera.numCamera !== '1' || (camera.numCamera === '1' && ospite !== 1)">{{ospite}}) Ospite</span>
      </div>
    </div>
    <div class="dettagli">
      <form [formGroup]="camera.forms[0]" *ngIf="ospite === 1 && camera.numCamera === '1'">
        <!-- INTESTATARIO -->
        <div class="form-group">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nome</mat-label>
                <input required matInput placeholder="Nome" type="text"
                       formControlName="nome-{{camera.numCamera}}-{{ospite}}"
                >
                <bv-form-error
                  [formControlParam]="camera.forms[0].get('nome-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Cognome</mat-label>
                <input required matInput placeholder="Cognome" type="text"
                       formControlName="cognome-{{camera.numCamera}}-{{ospite}}">
                <bv-form-error
                  [formControlParam]="camera.forms[0].get('cognome-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline" (click)="dataDiNascita.open()">
                <mat-label>Data di nascita</mat-label>
                <input required matInput formControlName="dataNascita-{{camera.numCamera}}-{{ospite}}"
                       placeholder="Data di nascita" [matDatepicker]="dataDiNascita"
                       autocomplete="off">
                <mat-datepicker
                  #dataDiNascita></mat-datepicker>
                <bv-form-error
                  [formControlParam]="camera.forms[0].get('dataNascita-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Sesso</mat-label>
                <mat-select required
                            placeholder="Sesso"
                            formControlName="sesso-{{camera.numCamera}}-{{ospite}}">
                  <mat-option *ngFor="let sesso of listSesso" [value]="sesso.value">
                    {{sesso.label}}
                  </mat-option>
                </mat-select>
                <bv-form-error
                  [formControlParam]="camera.forms[0].get('sesso-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Cellulare</mat-label>
                <input required matInput placeholder="Cellulare" type="text"
                       formControlName="cellulare-{{camera.numCamera}}-{{ospite}}">
                <bv-form-error
                  [formControlParam]="camera.forms[0].get('cellulare-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input required matInput placeholder="Email" type="text"
                       formControlName="email-{{camera.numCamera}}-{{ospite}}">
                <bv-form-error
                  [formControlParam]="camera.forms[0].get('email-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- END OF: INTESTATARIO -->
      </form>
      <form [formGroup]="camera.forms[j]"
            class="ospite"
            *ngIf="camera.numCamera !== '1' || (camera.numCamera === '1' && ospite !== 1)">
        <!-- NON INTESTATARIO -->
        <div class="form-group">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nome</mat-label>
                <input required matInput placeholder="Nome" type="text"
                       formControlName="nome-{{camera.numCamera}}-{{ospite}}">
                <bv-form-error
                  [formControlParam]="camera.forms[j].get('nome-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Cognome</mat-label>
                <input required matInput placeholder="Cognome" type="text"
                       formControlName="cognome-{{camera.numCamera}}-{{ospite}}">
                <bv-form-error
                  [formControlParam]="camera.forms[j].get('cognome-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
              <mat-form-field class="full-width" appearance="outline" (click)="dataDiNascita.open()">
                <mat-label>Data di nascita</mat-label>
                <input required matInput formControlName="dataNascita-{{camera.numCamera}}-{{ospite}}"
                       placeholder="Data di nascita" [matDatepicker]="dataDiNascita"
                       autocomplete="off">
                <mat-datepicker
                  #dataDiNascita></mat-datepicker>
                <bv-form-error
                  [formControlParam]="camera.forms[j].get('dataNascita-'+camera.numCamera+'-'+ospite)"></bv-form-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- END OF: NON INTESTATARIO -->
      </form>
    </div>
  </div>
</div>

