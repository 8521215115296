<div class="header-modal mb-20px">
  <div mat-dialog-close class="appearance-initial float-right">
    <i class="material-icons">close</i>
  </div>
  <div class="d-flex align-items-center justify-content-left">
    <span mat-dialog-title class="inline margin-0">Note Legali</span>
  </div>
</div>
<mat-dialog-content align="left">
  <div class="w-100 p-2 mb-10px">
    Sede Società : Via del Serafico, 185 - 00142 Roma<br />
    Ufficio Registro Imprese: Roma<br />
    Nr. Iscrizione registro imprese REA: RM-1122245<br />
    P. IVA: 08842781000<br />
    Indirizzo PEC: <a href="mailto:borsaviaggi@pec.it">borsaviaggi@pec.it</a><br />
    Codice destinatario SDI: <b>SUBM70N</b><br />
    <br />
    <span class="fs-12px">BORSAVIAGGI è un marchio ed un´organizzazione di distribuzione
        e vendita di servizi di viaggi e turismo, che opera con regolare autorizzazione
        amministrativa n° 104/07 per l´esercizio al dettaglio dell´attività conformemente
        alle norme della Provincia di Roma ed in ottemperanza agli obblighi di legge opera
        dietro copertura assicurativa per la responsabilità civile professionale stipulata
        con la compagnia FILODIRETTO NOBIS ASSICURAZIONI SPA polizza num. <b>1505002100/E</b>
    </span><br />
    <br />
    <span class="fs-12px">
      Obblighi informativi per le erogazioni pubbliche: gli aiuti di Stato e gli aiuti de minimis
      ricevuti dalla nostra impresa sono contenuti nel Registro nazionale degli aiuti di Stato di
      cui all’art. 52 della L. 234/2012 a cui si rinvia e consultabili al <a href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx" target="_blank">seguente link</a>
    </span>
    <br />
    <br />
  </div>
</mat-dialog-content>
<!--mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Chiudi</button>
</mat-dialog-actions-->
