import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class PreferitiService {
  private preferitiSubject = new Subject<boolean>();
  public preferitiState = this.preferitiSubject.asObservable();

  constructor() {
  }

  togglePreferiti() {
    this.preferitiSubject.next(true);
  }

}
